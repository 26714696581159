/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import ReportHeader from 'components/ReportHeader';
import FilterButton from 'components/FilterButton';
import SiblingReportsSelect from 'components/SiblingReportsSelect/SiblingReportsSelect';
import { Container, GridContainer, ReportContainer } from 'pages/Reports/styles';
import { useTranslation } from 'react-i18next';
import Filter from './Filter';
import { ArtistChartFilter } from './utils/ArtistChartTypes';
import ArtistChartGrid from './ArtistChartGrid';

const ArtistChartReport: React.FC = () => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<ArtistChartFilter | undefined>();
  const [filterOpened, setFilterOpened] = useState(false);

  const onChangeFilter = (newFilter: ArtistChartFilter) => {
    setFilter(newFilter);
    setFilterOpened(!filterOpened);
  };

  return (
    <Container container direction="column" spacing={0}>
      <ReportHeader title={t('report.tab.chart')} subtitle={t('report.tab.sevenDay')} />
      <ReportContainer>
        <SiblingReportsSelect />
        <FilterButton onClickFilter={() => setFilterOpened(!filterOpened)} isOpened={filterOpened} />
      </ReportContainer>
      <Filter isOpened={filterOpened} changeFilter={onChangeFilter} />
      <GridContainer>
        <ArtistChartGrid filters={filter} />
      </GridContainer>
    </Container>
  );
};

export default ArtistChartReport;
