/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { DayByDaySpins } from 'types/DayByDaySpins';
import { get } from 'lodash';
import { DayByDayColumn } from 'types/Midnite';
import { IInnerTableGroup } from '../inner-table-group';
import InnerTable from '..';
import defaultTheme from '../../../themes';
import { TitleContainer, Title } from './styles';

export interface DayByDaySpinsCurrentData {
  DayByDaySpins: DayByDaySpins;
}

export interface DayByDaySpinsCurrentMetadata {
  DayByDayColumns: Array<DayByDayColumn>;
}

interface DayByDaySpinsCurrentProps {
  data: DayByDaySpinsCurrentData;
  reportMetadata: DayByDaySpinsCurrentMetadata;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const DayByDaySpinsCurrentInnerTable: React.FC<DayByDaySpinsCurrentProps> = ({
  data,
  reportMetadata,
}: DayByDaySpinsCurrentProps) => {
  const { t } = useTranslation();

  const getColor = (baseValue: number, actualValue: number) => {
    if (baseValue > actualValue) return defaultTheme.palette.error.main;
    if (baseValue < actualValue) return defaultTheme.palette.success.main;
    return defaultTheme.palette.text.primary;
  };

  const createSpan = (baseValue: number, actualValue: number) => {
    return <span style={{ color: getColor(baseValue, actualValue) }}>{actualValue}</span>;
  };

  const innerTableData: IInnerTableGroup[][] = reportMetadata.DayByDayColumns.map(item => {
    const header = item.DayName;

    const thisWeek = get(data.DayByDaySpins, `${item.DayIndex}.ThisWeek`);
    const lastWeek = get(data.DayByDaySpins, `${item.DayIndex}.LastWeek`);
    const move = get(data.DayByDaySpins, `${item.DayIndex}.Move`);

    return [
      {
        title: header,
        children: [
          {
            title: '',
            children: ['TW', 'LW', '+/-'],
          },
          {
            title: '',
            children: [createSpan(lastWeek, thisWeek), createSpan(thisWeek, lastWeek), createSpan(0, move)],
          },
        ],
      },
    ];
  });

  return (
    <>
      <TitleContainer>
        <Title>{t('grid.header.DayByDaySpins')}</Title>
      </TitleContainer>
      {innerTableData.map((innerTable: any) => (
        <InnerTable key={v4()} data={innerTable} />
      ))}
    </>
  );
};

export default DayByDaySpinsCurrentInnerTable;
