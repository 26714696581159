import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    tableContainer: {
      maxHeight: 240,
    },
    tableHeader: {
      '& .MuiTableCell-root': {
        padding: '6px',
        lineHeight: 'normal',
        verticalAlign: 'bottom',
      },
      '& .MuiTypography-body1': { fontWeight: 'bold', fontSize: '12px' },
      '& .MuiTypography-body2': { fontSize: '10px', textTransform: 'uppercase' },
    },
    tableBody: {
      '& .MuiTableCell-root': { padding: '6px', lineHeight: 'normal', verticalAlign: 'bottom' },
      '& .MuiTypography-body1': { fontWeight: 'bold', fontSize: '12px' },
      '& .MuiTypography-body2': { fontSize: '10px' },
      '& .MuiButton-label': { color: theme.palette.primary.main },
    },
  });
});
