import ReportResult from 'services/ReportResult';
import { DayPart } from 'types/DayPart';
import { GrcInfo } from 'types/GrcInfo';
import { PointsInfo } from 'types/PointsInfo';
import { SongInfo } from 'types/SongInfo';
import { SpinsInfo } from 'types/SpinsInfo';
import { Station } from 'types/Station';

export interface CurrentDayMonitorsFilter {
  ReportCycle: number;
  StationSelectionInfo?: Station;
  NumDays: number;
  GrcInfo?: GrcInfo;
  refreshedAt?: number;
  StationInfo?: Station;
  Stations: Station[];
}

export interface ExtendedCurrentDayMonitorsFilter extends CurrentDayMonitorsFilter {
  value?: Array<unknown>;
  callLetters?: string;
}

export interface StationCurrentDailyReport{
  Count: number;
  Items: Array<StationCurrentDailyRow>;
}

export interface StationCurrentDailyRow {
  EndDate: Date;
  StationName: string;
  StationDisplayName: string;
  Skim: number;
  Artist: string;
  ArtistByLastname: string;
  Title: string;
  GrcCode: string;
  ReleaseYear: number;
  TimePlayed: Date;
  Hour: number;
  HasCanadianContent: boolean;
  SongCode: string;
  SongCardAudioType: number;
}

export interface StationCurrentBuildingReport{
  Count: number;
  Items: Array<StationCurrentDayBuildingRow>;
}

export interface StationCurrentDayBuildingRow {
  RankLastWeek: number;
  RankThisWeek: number;
  Artist: string;
  ArtistByLastname: string;
  Title: string;
  Label: string;
  ReleaseYear: number;
  SpinsInfo: SpinsInfo;
  DaypartsInfo: DayPart;
  PointsInfo: PointsInfo;
  SpinsToDate: number;
  FirstPlayDate: Date;
  SongCode: string;
  SongCardAudioType: number;
}

export interface TransformedStationCurrentDayBuildingRow extends StationCurrentDayBuildingRow {
  ArtistInfo: SongInfo;
  HeaderStationCountry: boolean;
}

export interface TransformedStationCurrentDayBuildingResponse extends ReportResult<StationCurrentDayBuildingRow> {
  HeaderStationCountry: boolean;
}

// eslint-disable-next-line no-shadow
export enum StationReportTypeEnum {
  BuildingReport = 3,
  DailyReport = 7,
}
