import { Button } from '@material-ui/core';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { useStyles } from './styles';

interface FilterButtonProps {
  isOpened: boolean;
  onClickFilter: { (): void };
}

const FilterButton: React.FC<FilterButtonProps> = ({ isOpened, onClickFilter }) => {
  const classes = useStyles();
  return (
    <Button
      className={`${classes.filter} ${isOpened ? classes.active : ''}`}
      onClick={onClickFilter}
      data-testid="filter-button"
    >
      <FontAwesomeIcon className={classes.icon} icon={faFilter} />
    </Button>
  );
};

export default FilterButton;
