import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      padding: '12px 26px 12px 26px',
      zIndex: 1,
      width: '100%',
      '& .MuiTypography-body1': {
        fontSize: '12px',
      },
      '& .MuiTypography-body2': {
        fontSize: '10px',
      },
      '& .desktop-version': {
        fontWeight: 'bold',
        '& a':{
          color: '#E5412C'
        }
      },
      '& a': {
        textDecoration: 'none',
        color: theme.palette.primary.main,
      },
      '& a:visited': {
        color: '#8427aa',
      },
    },
    bottomText:{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center'
    },
    doNotShareText:{
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      alignContent: 'center',   
      '& p': {
        textAlign: 'center'
      },   
    }
  });
});
