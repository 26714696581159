/* eslint-disable @typescript-eslint/no-explicit-any */
import { DatesUrlEnum } from 'components/Filter/components/CalendarFilter/utils';
import FilterButton from 'components/FilterButton';
import ReportHeader from 'components/ReportHeader';
import SiblingReportsSelect from 'components/SiblingReportsSelect/SiblingReportsSelect';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateRangeGroup } from 'types/DateRange';
import Filter from './Filter';
import RollingChartReport from './RollingChartReport';
import { Container, GridContainer, ReportContainer } from './styles';
import { RollingAvailableTransformer } from './utils/RollingChartReportTransformer';
import { RollingAvailable, RollingFilterParams } from './utils/types';

const SevenDaysChart: React.FC = () => {
  const { t } = useTranslation();
  const [filterOpened, setFilterOpened] = useState(false);
  const [filters, setFilters] = useState<RollingFilterParams>();
  const [filterPreviousNextDate, setFilterPreviousNextDate] = useState<DateRangeGroup>();

  const { data: dateAvailability } = useRemoteApi<RollingAvailable>(DatesUrlEnum.ROLLING_AVAILABILITY, {
    method: 'GET',
    enabled: true,
    transformResponse: RollingAvailableTransformer,
  });

  const onChangeFilterOpened = () => {
    setFilterOpened(!filterOpened);
  };

  const changeFilterWeek = (params: DateRangeGroup) => {
    setFilterPreviousNextDate(params);
    setFilters({ ...filters, DateRange: params } as RollingFilterParams);
  };

  const onChangeFilter = (filtersParams: any | null) => {
    setFilterOpened(false);
    setFilters(filtersParams);
  };

  return (
    <>
      <Container container direction="column" spacing={0}>
        <ReportHeader title={t('report.tab.chart')} subtitle={t('report.tab.sevenDay')} />
        <ReportContainer>
          <SiblingReportsSelect />
          <FilterButton onClickFilter={onChangeFilterOpened} isOpened={filterOpened} />
        </ReportContainer>
        {dateAvailability && (
          <Filter
            PreviousNextFilterDate={filterPreviousNextDate}
            isOpened={filterOpened}
            changeFilter={onChangeFilter}
          />
        )}
        <GridContainer>
          {dateAvailability && filters && (
            <RollingChartReport dateRange={dateAvailability} onChangeFilter={changeFilterWeek} filters={filters} />
          )}
        </GridContainer>
      </Container>
    </>
  );
};

export default SevenDaysChart;
