import { AgGridColumn } from '@ag-grid-community/react';
import DayPartsCellRenderer from 'components/DetailGrid/CellRenderers/DayPartsCellRenderer';
import RankInfoCellRenderer from 'components/DetailGrid/CellRenderers/RankInfoCellRenderer/RankInfoCellRenderer';
import ThisLastMoveCellRenderer from 'components/DetailGrid/CellRenderers/ThisLastMoveCellRenderer';
import DayPartsHeaderComponent from 'components/DetailGrid/HeaderComponents/DayPartsHeaderComponent';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import ThisLastMoveHeaderComponent from 'components/DetailGrid/HeaderComponents/ThisLastMoveHeaderComponent';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import AppSettings from 'contexts/AppSettingsContext';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import ArtistSongByStationGrid from './ArtistSongsByStationGrid';
import { artistByStationFilterRequestTransformer } from './utils/artistByStationFilterRequestTransformer';
import { artistByStationResponseTransformer } from './utils/artistByStationResponseTransformer';
import { ArtistByStation, ArtistByStationFilter } from './utils/types';

interface ArtistByStationGridProps {
  filters?: ArtistByStationFilter;
}

const url = '/artist-by-station-reports';
const ArtistByStationGrid: FC<ArtistByStationGridProps> = ({ filters }) => {
  const { t } = useTranslation();
  const { allPanel: defaultPanelInfo } = useContext(AppSettings);
  const { sortModel, setSortModel: onSortChange } = useSortColumn({
    sortPath: 'RankThisWeek',
    sort: 'asc',
  });
  const { setSettings, settings, data: reportData, isLoading, isError } = useRemoteApi<ReportResult<ArtistByStation>>(
    url,
    {
      data: filters,
      method: 'POST',
      enabled: false,
      transformRequest: data => artistByStationFilterRequestTransformer(data, defaultPanelInfo),
      transformResponse: artistByStationResponseTransformer,
    },
  );

  const songsGrid = <ArtistSongByStationGrid filters={filters} />;
  return (
    <>
      <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
        <ServerSideReportGrid
          setSettings={setSettings}
          settings={settings}
          filters={filters}
          sortModel={sortModel}
          expandCellRendererProps={{
            footerElement: songsGrid,
          }}
        >
          <AgGridColumn
            headerName={t('grid.header.Rank')}
            minWidth={60}
            maxWidth={60}
            field="Rank"
            headerComponentParams={{
              sortModel,
              onSortChange,
              disposition: 'row',
              secondLine: [
                { label: 'grid.header.TW', sortPath: 'RankThisWeek' },
                { label: 'grid.header.LW', sortPath: 'RankLastWeek' },
              ],
            }}
            cellRendererParams={{
              fields: ['Rank.ThisWeek', 'Rank.LastWeek'],
            }}
            cellRendererFramework={RankInfoCellRenderer}
            headerComponentFramework={MultiColumnsHeaderComponent}
          />
          <AgGridColumn
            headerName={t('grid.header.Artist')}
            minWidth={120}
            pinned
            field="Artist"
            headerComponentParams={{
              sortModel,
              onSortChange,
              sortPath: 'Artist',
            }}
          />
          <AgGridColumn
            headerName={t('grid.header.TitlesCount')}
            minWidth={68}
            maxWidth={68}
            field="TitlesCount"
            headerComponentParams={{
              sortModel,
              onSortChange,
              sortPath: 'TitlesCount',
            }}
          />
          <AgGridColumn
            field="SpinsInfo"
            minWidth={95}
            maxWidth={95}
            headerName={t(`grid.header.SpinsTw`)}
            cellRendererFramework={ThisLastMoveCellRenderer}
            headerComponentFramework={ThisLastMoveHeaderComponent}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="DaypartsInfo"
            headerName={t(`grid.header.DaypartsInfo`)}
            minWidth={230}
            maxWidth={230}
            headerComponentFramework={DayPartsHeaderComponent}
            headerComponentParams={{ sortModel, onSortChange }}
            cellRendererFramework={DayPartsCellRenderer}
          />
        </ServerSideReportGrid>
      </GridVisibilityWrapper>
    </>
  );
};

export default ArtistByStationGrid;
