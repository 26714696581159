import {
  BuildingChartResponse,
  BuildingChartRow,
  TransformedBuildingChartResponse,
  TransformedBuildingChartRow,
} from './BuildingChartReportTypes';

export const BuildingChartTransformer = (response: BuildingChartResponse): TransformedBuildingChartResponse => {
  const transformItems = (row: BuildingChartRow): TransformedBuildingChartRow => {
    return {
      ...row,
      SongInfo: {
        Artist: row.Artist,
        Label: row.Label,
        Title: row.Title,
        SongCode: row.SongCode,
      },
      Rank: {
        ThisWeek: row.RankThisWeek,
        LastWeek: row.RankLastWeek,
      },
      SpinsInfo: {
        Peak: row.SpinsWithinDateRange.Peak,
        ThisWeek: row.SpinsWithinDateRange.ThisWeek,
        LastWeek: row.SpinsWithinDateRange.LastWeek,
        Move: row.SpinsWithinDateRange.Move,
      },
      DayByDaySpinsFlat: Object.entries(row.DayByDaySpins).map(i => `${i[1].ThisWeek}`),
      DayByDayImpressionsFlat: Object.entries(row.DayByDayImpressions).map(i => `${i[1].SpinsThisWeek}`),
    };
  };
  return {
    ...response,
    Items: response.Items.map(transformItems),
    DayByDaySpinsHeaderDynamic: response.DateData.map(o => {
      return {
        label: o.DayText.substring(0, 3),
        // eslint-disable-next-line radix
        sortPath: `DayByDaySpins/${parseInt(o.NumDay)}/ThisWeek`,
      };
    }),
    DayByDaySpinsFieldsDynamic: Object.entries(response.DateData).map(
      // eslint-disable-next-line radix
      (o, index) => `DayByDaySpins.${index + 1}.ThisWeek`,
    ),
    DayByDayImpressionsHeaderDynamic: response.DateData.map(o => {
      return {
        label: o.DayText.substring(0, 3),
        // eslint-disable-next-line radix
        sortPath: `DayByDayImpressions/${parseInt(o.NumDay)}/SpinsThisWeek`,
      };
    }),
    DayByDayImpressionsFieldsDynamic: Object.entries(response.DateData).map(
      // eslint-disable-next-line radix
      (o, index) => `DayByDayImpressions.${index + 1}.SpinsThisWeek`,
    ),
  };
};
