import { AgGridColumn } from '@ag-grid-community/react';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import StationRankMarketOwnerCellRenderer from 'components/DetailGrid/CellRenderers/StationRankMarketOwnerCellRenderer';
import StationRankMarketOwnerHeaderComponent from 'components/DetailGrid/HeaderComponents/StationRankMarketFormatHeaderComponent';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import { format, getMonth } from 'date-fns';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import { range } from 'lodash';
import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import { ExtendedArtistAnalysisFilter } from './utils/ArtistAnalysisFilter';
import {
  SevenDayArtistAnalysisReport,
  TransformedSevenDayArtistAnalysisReport,
} from './utils/SevenDayArtistAnalysisReports';

interface YearToDateReportGridProps {
  filters?: ExtendedArtistAnalysisFilter;
  detailGrid?: ReactElement;
}

const url = `/seven-day-artist-analysis-reports`;
const YearToDateReportGrid: FC<YearToDateReportGridProps> = ({ filters, detailGrid }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({ sortPath: 'Rank', sort: 'asc' });
  const { setSettings, settings, data: reportData, isLoading, isError } = useRemoteApi<
    ReportResult<TransformedSevenDayArtistAnalysisReport>
  >(url, {
    method: 'POST',
    enabled: false,
    transformResponse: data => {
      const transform = (v: SevenDayArtistAnalysisReport): TransformedSevenDayArtistAnalysisReport => {
        return {
          ...v,
          ThisWeekImpressionSpin: v?.ImpressionsInfo?.SpinsThisWeek,
        };
      };
      const items = data.YearToDateItems;
      return { ...data, Items: items.map((v: SevenDayArtistAnalysisReport) => transform(v)) };
    },
  });

  const getBaseDatesToMonth = (): Date[] => {
    const today = new Date();
    return range(0, getMonth(today) + 1).map(m => new Date(2020, m, 1));
  };

  return (
    <>
      <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
        <ServerSideReportGrid
          setSettings={setSettings}
          settings={settings}
          filters={filters}
          sortModel={sortModel}
          expandCellRendererProps={{ footerElement: detailGrid }}
        >
          <AgGridColumn
            field="Rank"
            headerName={t('grid.header.Rank')}
            minWidth={60}
            maxWidth={60}
            wrapText
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="StationDisplayName"
            headerName={t('grid.header.Station')}
            minWidth={150}
            wrapText
            headerComponentParams={{ sortModel, onSortChange }}
            headerComponentFramework={StationRankMarketOwnerHeaderComponent}
            cellRendererFramework={StationRankMarketOwnerCellRenderer}
          />
          <AgGridColumn
            field="FormatName"
            headerName={t('grid.header.FormatName')}
            minWidth={80}
            maxWidth={120}
            wrapText
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="TitlesCount"
            headerName={t('grid.header.TitlesCount')}
            minWidth={75}
            maxWidth={75}
            wrapText
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="TotalSpins"
            headerName={t('grid.header.Spins')}
            minWidth={80}
            maxWidth={80}
            wrapText
            headerComponentParams={{
              sortModel,
              onSortChange,
            }}
          />

          {getBaseDatesToMonth().map(m => {
            return (
              <AgGridColumn
                field={`YearToDateInfo.${format(m, 'MMMM')}`}
                headerName={format(m, 'MMM')}
                maxWidth={60}
                minWidth={60}
                headerComponentParams={{ sortModel, onSortChange }}
              />
            );
          })}

          <AgGridColumn
            headerName={t('grid.header.impression.ImpressionMill')}
            field="ThisWeekImpressionSpin"
            maxWidth={120}
            minWidth={120}
            headerComponentParams={{ sortModel, onSortChange }}
            valueFormatter={({ value }: ValueFormatterParams) => (value || 0.0).toFixed(3)}
          />
        </ServerSideReportGrid>
      </GridVisibilityWrapper>
    </>
  );
};

export default YearToDateReportGrid;
