import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
`;
export const FilterGridContainer = styled(Grid)`
  display: flex;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px;
`;
export const OwnerContainer = styled(Grid)`
  display: flex;
  justify-content: flex-start;
  width: 43%;
  max-width: 43%;
`;
export const FormatContainer = styled(Grid)`
  display: flex;
  justify-content: flex-start;
  width: 33%;
  max-width: 33%;
`;
export const SongsContainer = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
`;
