import { isSameDay } from 'date-fns';
import { Range } from 'react-date-range';
import { DateOptionParameters } from '../DefaultDateOptions';

export function optionFromDateRangeResolver(
  dates?: Range,
  options?: DateOptionParameters[],
): DateOptionParameters | null {
  if (!dates || !options?.length) return null;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const customOption = options.find(o => o.label === 'custom')!;
  const { startDate, endDate } = dates ?? {};
  if (startDate && endDate) {
    const option = options.find(o => isSameDay(o.start, startDate) && isSameDay(o.end, endDate)) ?? customOption;
    if (option?.label === 'custom') {
      option.end = endDate;
      option.start = startDate;
    }
    return option;
  }
  return options[0];
}
