import React from 'react';
import { FormatComparisonInfo } from 'types/FormatComparisonInfo';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { formatComparisonInfoRank, formatComparisonInfoDeviations } from 'utils/reports/FormatComparisonInfoFormatters';
import { getStationDisplayName } from 'utils/reports/GetStationDisplayName';
import InnerTable from '..';
import { IInnerTableGroup } from '../inner-table-group';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface StationFormatComparisonInfoInnerTableProps {
  data: FormatComparisonInfo;
  name?: string;
  reportQuery: any;
}

const StationFormatComparisonInfoInnerTable: React.FC<StationFormatComparisonInfoInnerTableProps> = ({
  data,
  name = 'grid.header.FormatComparison',
  reportQuery,
}: StationFormatComparisonInfoInnerTableProps) => {
  const { t } = useTranslation();

  const innerTableData: IInnerTableGroup[] = [
    {
      title: t(name),
      children: [
        {
          title: '',
          children: [
            t('grid.header.formatComparison.Rank'),
            t('grid.header.formatComparison.Avg'),
            getStationDisplayName(reportQuery?.StationInfo),
          ],
        },
        {
          title: '',
          children: [
            formatComparisonInfoRank(data, reportQuery) as string,
            data.AveragePlays != null ? data.AveragePlays.toString() : '',
            formatComparisonInfoDeviations(data) as string,
          ],
        },
      ],
    },
  ];

  return <InnerTable key={v4()} data={innerTableData} />;
};

StationFormatComparisonInfoInnerTable.defaultProps = {
  name: 'grid.header.FormatComparison',
};

export default StationFormatComparisonInfoInnerTable;
