import { AgGridColumn } from '@ag-grid-community/react';
import BooleanCanadaCheckMarkCellRenderer from 'components/DetailGrid/CellRenderers/BooleanCanadaCheckMarkCellRenderer';
import RankInfoCellRenderer from 'components/DetailGrid/CellRenderers/RankInfoCellRenderer/RankInfoCellRenderer';
import SongCellRenderer from 'components/DetailGrid/CellRenderers/SongCellRenderer/SongCellRenderer';
import ThisLastMoveCellRenderer from 'components/DetailGrid/CellRenderers/ThisLastMoveCellRenderer';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import ThisLastMoveHeaderComponent from 'components/DetailGrid/HeaderComponents/ThisLastMoveHeaderComponent';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import { DailyCurrentChartUrl } from 'enums/urls';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import { UseApiSettings } from 'hooks/useApi/ApiTypes';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import queryString from 'query-string';
import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import { isCountryFilter, showCanconGrid } from 'utils/reports';
import { v4 as uuidv4 } from 'uuid';
import { CurrentChartReportTransformer } from './utils/CurrentChartTransformer';
import { CurrentChartFilter, CurrentChartResponse } from './utils/CurrentChartTypes';

interface CurrentChartGridProps {
  filters: CurrentChartFilter | undefined;
  reportName: string;
}

const url = DailyCurrentChartUrl.realTimeCurrentChartReports;

const CurrentChartGrid: React.FC<CurrentChartGridProps> = ({ filters, reportName = 'currentChart' }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({ sortPath: 'RankThisWeek', sort: 'asc' });
  const { data: reportData, isLoading, setSettings, settings, isError } = useRemoteApi<CurrentChartResponse>(url, {
    data: filters,
    method: 'POST',
    enabled: false,
    transformResponse: CurrentChartReportTransformer,
  });

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const songClickHandler = (data: any, reportQuery: CurrentChartFilter | undefined) => {
    const href = getReportSpec(ReportBaseEnum.CurrentSongsReport).path;
    const params = {
      songCode: data.SongInfo?.SongCode,
      'PanelInfo.Id': reportQuery?.PanelInfo.Id,
      'FormatInfo.FormatCode': reportQuery?.FormatInfo.FormatCode,
      'GrcInfo.Id': reportQuery?.GrcInfo?.Id,
      NumDays: reportQuery?.NumDays,
      deepLink: true,
    };
    window.open(`${href}?${queryString.stringify(params)}`);
  };

  const getReportMetadata = () => {
    const metadata = {
      DayByDayColumns: reportData?.DayByDayColumns,
    };
    return metadata;
  };

  const renderColumns = () => {
    const result: JSX.Element[] = [];

    result.push(
      <AgGridColumn
        field="RankInfo"
        headerName={t(`grid.header.Rank`)}
        minWidth={60}
        key={uuidv4()}
        headerComponentFramework={MultiColumnsHeaderComponent}
        headerComponentParams={{
          sortModel,
          onSortChange,
          disposition: 'row',
          separator: '/',
          secondLine: [
            { sortPath: 'RankThisWeek', label: 'grid.header.TW' },
            { sortPath: 'RankLastWeek', label: 'grid.header.LW' },
          ],
        }}
        cellRendererFramework={RankInfoCellRenderer}
        cellRendererParams={{ showArrow: true }}
      />,
    );

    result.push(
      <AgGridColumn
        field="SongInfo"
        headerName={t(`grid.header.Title`)}
        minWidth={180}
        key={uuidv4()}
        pinned
        headerComponentFramework={MultiColumnsHeaderComponent}
        headerComponentParams={{
          sortModel,
          sortPath: 'Title',
          onSortChange,
          disposition: 'row',
          secondLine: [
            { sortPath: 'Artist', label: 'grid.header.Artist' },
            { sortPath: 'Label', label: 'grid.header.Label' },
          ],
        }}
        cellRendererFramework={SongCellRenderer}
        cellRendererParams={{
          reportQuery: filters,
          onClickHandler: (data: any, reportQuery: any) => songClickHandler(data, reportQuery),
        }}
      />,
    );

    result.push(
      <AgGridColumn
        field="HasCanadianContent"
        headerName="CanCon"
        key={uuidv4()}
        minWidth={70}
        cellRendererFramework={BooleanCanadaCheckMarkCellRenderer}
        headerComponentParams={{ sortModel, onSortChange, sortPath: 'HasCanadianContent' }}
        hide={!showCanconGrid(filters) || reportName === 'midnite'}
      />,
    );

    result.push(
      <AgGridColumn
        field="SpinsInfo"
        key={uuidv4()}
        minWidth={80}
        headerName={t(`grid.header.SpinsInfoTotal`)}
        headerComponentFramework={MultiColumnsHeaderComponent}
        headerComponentParams={{
          sortModel,
          onSortChange,
          disposition: 'row',
          secondLine: [
            { sortPath: 'SpinsInfo/ThisWeek', label: ['grid.header.TW'] },
            { sortPath: 'SpinsInfo/LastWeek', label: ['grid.header.LW'] },
            { sortPath: 'SpinsInfo/Move', label: ['grid.header.Move'] },
          ],
        }}
        cellRendererFramework={ThisLastMoveCellRenderer}
      />,
    );

    (reportData?.DayByDayColumns ?? []).forEach(dayBayDayColumn =>
      result.push(
        <AgGridColumn
          key={uuidv4()}
          field={`DayByDaySpinsCurrent.${dayBayDayColumn.DayIndex}`}
          minWidth={80}
          headerName={dayBayDayColumn.DayName}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            disposition: 'row',
            secondLine: [
              { sortPath: `DayByDaySpins/${dayBayDayColumn.DayIndex}/ThisWeek`, label: ['grid.header.TW'] },
              { sortPath: `DayByDaySpins/${dayBayDayColumn.DayIndex}/LastWeek`, label: ['grid.header.LW'] },
              { sortPath: `DayByDaySpins/${dayBayDayColumn.DayIndex}/Move`, label: ['grid.header.Move'] },
            ],
          }}
          cellRendererFramework={ThisLastMoveCellRenderer}
        />,
      ),
    );

    result.push(
      <AgGridColumn
        field="PointsInfo"
        key={uuidv4()}
        minWidth={95}
        headerName={t(`grid.header.PointsInfoTW`)}
        cellRendererFramework={ThisLastMoveCellRenderer}
        headerComponentFramework={ThisLastMoveHeaderComponent}
        headerComponentParams={{ sortModel, onSortChange }}
        hide={!isCountryFilter(filters) || reportName === 'midnite'}
      />,
    );

    result.push(
      <AgGridColumn
        field="ImpressionsTotal"
        key={uuidv4()}
        minWidth={100}
        headerName={t(`grid.header.ImpressionTotal`)}
        headerComponentFramework={MultiColumnsHeaderComponent}
        headerComponentParams={{
          sortModel,
          onSortChange,
          disposition: 'row',
          secondLine: [
            { sortPath: 'ImpressionsInfo/ThisWeek', label: [t('grid.header.TW')] },
            { sortPath: 'ImpressionsInfo/LastWeek', label: [t('grid.header.LW')] },
            { sortPath: 'ImpressionsInfo/Move', label: [t('grid.header.Move')] },
          ],
        }}
        cellRendererFramework={ThisLastMoveCellRenderer}
        hide={reportName === 'midnite'}
      />,
    );

    return result;
  };

  return (
    <>
      <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
        <ServerSideReportGrid
          setSettings={(setSettings as unknown) as Dispatch<UseApiSettings<ReportResult<unknown>>>}
          settings={(settings as unknown) as UseApiSettings<ReportResult<unknown>>}
          filters={filters}
          sortModel={sortModel}
          reportMetadata={getReportMetadata()}
        >
          {reportData?.DayByDayColumns ? renderColumns() : []}
        </ServerSideReportGrid>
      </GridVisibilityWrapper>
    </>
  );
};

export default CurrentChartGrid;
