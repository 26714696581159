import { RegionMarketTrendingAnalysis } from 'types/RegionMarketTrendingAnalysis';
import { STATION_PORTFOLIO, STATION_PORTFOLIO_GROUP } from 'utils/Constants';
import { ExtendedTrending } from '..';
import { TrendingAnalysisReport } from './TrendingAnalysisReport';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const TrendingAnalysisTransformer = (reportData: TrendingAnalysisReport): TrendingAnalysisReport => {
  const normalize = [
    { ...reportData.NationalSummary, DateRange: reportData.DateRange },
  ] as RegionMarketTrendingAnalysis[];
  return {
    ...reportData,
    Items: reportData.Items.map((item: any) => ({ ...item, DateRange: reportData.DateRange })),
    FormatItems: reportData.FormatItems.map((item: any) => ({ ...item, DateRange: reportData.DateRange })),
    Summary: normalize,
  };
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const normalize = (reportData: TrendingAnalysisReport, apiProperty: string) => {
  return {
    Count: reportData[apiProperty as keyof TrendingAnalysisReport]?.length ?? reportData.Count,
    Items: reportData[apiProperty as keyof TrendingAnalysisReport].map((item: any) => ({
      ...item,
      DateRange: reportData.DateRange,
    })),
    DateRange: reportData.DateRange,
  };
};

export const FormatItemsTransformer = (reportData: TrendingAnalysisReport): TrendingAnalysisReport => {
  return normalize(reportData, 'FormatItems');
};

export const DetailsItemsTransformer = (reportData: TrendingAnalysisReport): TrendingAnalysisReport => {
  return normalize(reportData, 'Items');
};

export const transformRegionMarketsTrendingAnalysisByStationFilters = (
  filter: ExtendedTrending | undefined,
): unknown => {
  return {
    ...filter,
    ...{
      StationPortfolio: filter?.stationGroup === STATION_PORTFOLIO ? filter?.StationPortfolio : undefined,
      StationPortfolioGroup:
        filter?.stationGroup === STATION_PORTFOLIO_GROUP ? filter?.StationPortfolioGroup : undefined,
    },
  };
};

export default TrendingAnalysisTransformer;
