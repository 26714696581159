import { MarketChartReport, MarketReportStation, StationReportResult, TransformedMarketChartReport } from './Types';

export const MarketChartReportTransformer = (
  v: StationReportResult<MarketChartReport>,
): StationReportResult<TransformedMarketChartReport> => {
  const transformedItems = (item: MarketChartReport): TransformedMarketChartReport => {
    return {
      ...item,
      RankInfo: { ThisWeek: item.RankThisWeek, LastWeek: item.RankLastWeek },
      SongInfo: {
        Artist: item.Artist,
        Label: item.Label,
        Title: item.Title,
        Year: item.ReleaseDate,
      },
      StationsMap: {
        ...Object.values(item.Stations).reduce(
          (stations, { StationIndex, SpinsCount }: MarketReportStation) => ({
            ...stations,
            [StationIndex]: SpinsCount,
          }),
          {},
        ),
      },
    };
  };
  return { ...v, Items: v.Items.map(transformedItems) };
};
