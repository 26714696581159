import { AgGridColumn } from '@ag-grid-community/react/lib/agGridColumn';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import MultiColumnsCellRenderer from 'components/DetailGrid/CellRenderers/MultiColumnsCellRenderer/MultiColumnsCellRenderer';
import RankInfoCellRenderer from 'components/DetailGrid/CellRenderers/RankInfoCellRenderer/RankInfoCellRenderer';
import SongCellRenderer from 'components/DetailGrid/CellRenderers/SongCellRenderer/SongCellRenderer';
import ThisLastMoveCellRenderer from 'components/DetailGrid/CellRenderers/ThisLastMoveCellRenderer';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import { convertToPercentage } from 'utils/maths/maths';
import { OnNewUpReport } from './OnNewUpDownReport';
import OnNewUpTransformer from './OnNewUpDownTransformer';

export interface OnNewUpDownGridProps {
  filters?: unknown;
}

const url = `/stations-playing-activity-reports`;
const OnNewUpDownGrid: React.FC<OnNewUpDownGridProps> = ({ filters }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({ sortPath: 'RankThisWeek', sort: 'asc' });
  const { setSettings, settings, data: reportData, isLoading, isError } = useRemoteApi<ReportResult<OnNewUpReport>>(
    url,
    {
      data: filters,
      method: 'POST',
      enabled: !!filters,
      transformResponse: OnNewUpTransformer,
    },
  );

  return (
    <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
      <ServerSideReportGrid sortModel={sortModel} settings={settings} setSettings={setSettings} filters={filters}>
        <AgGridColumn
          headerName={t('grid.header.Rank')}
          minWidth={60}
          width={60}
          field="Rank"
          headerComponentParams={{
            sortModel,
            onSortChange,
            disposition: 'row',
            secondLine: [
              { label: 'grid.header.TW', sortPath: 'RankThisWeek' },
              { label: 'grid.header.LW', sortPath: 'RankLastWeek' },
            ],
          }}
          cellRendererParams={{
            fields: ['Rank.ThisWeek', 'Rank.LastWeek'],
          }}
          cellRendererFramework={RankInfoCellRenderer}
          headerComponentFramework={MultiColumnsHeaderComponent}
        />
        <AgGridColumn
          field="SongInfo"
          headerName={t(`grid.header.Title`)}
          minWidth={150}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            sortPath: 'Title',
            disposition: 'row',
            secondLine: [
              { label: 'grid.header.Artist', sortPath: 'Artist' },
              { label: 'grid.header.Label', sortPath: 'Label' },
            ],
          }}
          cellRendererFramework={SongCellRenderer}
        />
        <AgGridColumn
          field="SpinsInfo"
          minWidth={80}
          headerName={t(`grid.header.SpinsInfoTW`)}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{ secondLine: ['grid.header.LWMove'] }}
          cellRendererFramework={ThisLastMoveCellRenderer}
        />
        <AgGridColumn
          field="Stations"
          minWidth={150}
          headerName={`${t(`grid.header.Stations`)}`}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            sortPath: 'Title',
            disposition: 'row',
            secondLine: [
              { label: 'grid.header.stations.StationsOn', sortPath: 'StationsOn' },
              { label: 'grid.header.stations.StationsNew', sortPath: 'StationsNew' },
              { label: 'grid.header.stations.StationsUp', sortPath: 'StationsUp' },
              { label: 'grid.header.stations.StationsSteady', sortPath: 'StationsSteady' },
              { label: 'grid.header.stations.StationsDown', sortPath: 'StationsDown' },
            ],
          }}
          cellRendererParams={{
            fields: [
              'Stations.StationsOn',
              'Stations.StationsNew',
              'Stations.StationsUp',
              'Stations.StationsSteady',
              'Station.StationsDown',
            ],
          }}
          cellRendererFramework={MultiColumnsCellRenderer}
        />
        <AgGridColumn
          field="Percentages"
          minWidth={250}
          width={250}
          headerName={`${t(`grid.header.Percentages`)}`}
          valueFormatter={({ value }: ValueFormatterParams) => `${convertToPercentage(value)}%`}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            sortPath: 'StationsOn',
            disposition: 'column',
            secondLine: [
              { label: 'grid.header.stations.StationsOn', sortPath: 'StationsOn' },
              { label: 'grid.header.stations.StationsNew', sortPath: 'StationsNew' },
              { label: 'grid.header.stations.StationsUp', sortPath: 'StationsUp' },
              { label: 'grid.header.stations.StationsSteady', sortPath: 'StationsSteady' },
              { label: 'grid.header.stations.StationsDown', sortPath: 'StationsDown' },
            ],
          }}
          cellRendererParams={{
            fields: [
              'Percentages.StationsOnPercentage',
              'Percentages.StationsNewPercentage',
              'Percentages.StationsUpPercentage',
              'Percentages.StationsSteadyPercentage',
              'Percentages.StationsDownPercentage',
            ],
          }}
          cellRendererFramework={MultiColumnsCellRenderer}
        />
      </ServerSideReportGrid>
    </GridVisibilityWrapper>
  );
};
export default OnNewUpDownGrid;
