import React, { Dispatch } from 'react';
import FilterBase from 'components/FilterBase';
import FilterDataEvent from 'types/FilterDataEvent';
import InputNumber from 'components/InputNumber';
import PanelFilter from 'components/Filter/components/PanelFilter';
import MarketSelect from 'components/Filter/components/MarketSelect';
import { useTranslation } from 'react-i18next';
import useStickyFilter from 'hooks/useStickyFilter/useStickyFilter';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import { MarketChartFilter } from './utils/MarketChartReportInterfaces';

interface MarketChartReport {
  changeFilter: Dispatch<MarketChartFilter>;
  isOpened: boolean;
}

const MarketChartReportFilter: React.FC<MarketChartReport> = ({ changeFilter, isOpened }) => {
  const { t } = useTranslation();
  const pathname = getReportSpec(ReportBaseEnum.MarketChartReport).stickyKey;
  const { savedFilters: filter = {} as MarketChartFilter, saveFilter } = useStickyFilter<MarketChartFilter>(pathname);

  const handleFilterChange = (event: FilterDataEvent<MarketChartFilter>) => {
    const { value } = event;
    // Clean Market info when changing PanelInfo.
    if (value?.PanelInfo && value?.PanelInfo?.Id !== filter?.PanelInfo?.Id) {
      value.MarketInfo = undefined;
    }
    saveFilter({ ...filter, ...value });
  };

  return (
    <FilterBase onSubmit={() => changeFilter(filter)} isOpened={isOpened}>
      <PanelFilter onChange={handleFilterChange} initialValue={filter.PanelInfo} />
      <MarketSelect
        onChange={handleFilterChange}
        panelCode={filter.PanelInfo?.PanelCode}
        initialValue={filter.MarketInfo}
      />
      <InputNumber
        onChange={handleFilterChange}
        name={t('filter.title.minSpins')}
        valueToApi="MinSpins"
        initialValue={filter.MinSpins}
      />
    </FilterBase>
  );
};

export default MarketChartReportFilter;
