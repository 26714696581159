import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  height: 100vw;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
`;

const NotFound: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <h2>{t('page.pageNotFound.title')}</h2>
      <p>{t('page.pageNotFound.message')}</p>
      <p>
        <Link to="/">{t('page.pageNotFound.goHome')}</Link>
      </p>
    </Container>
  );
};

export default NotFound;
