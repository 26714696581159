import { Button, Divider, Grid, List, RadioGroup } from '@material-ui/core';
import AccordionReports from 'components/AccordionReports';
import { noop } from 'lodash';
import React, { useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilterDataEvent from 'types/FilterDataEvent';
import FilterDataProps from 'types/FilterDataProps';
import { ComponentGroupItemProps } from './ComponentGroupItem';

type ChildrenType = React.ReactElement<ComponentGroupItemProps>;

interface ComponentGroupProps extends FilterDataProps<string> {
  title?: string;
  children: ChildrenType | ChildrenType[];
}

const ComponentGroup: React.FC<ComponentGroupProps> = ({
  title = '',
  children = [],
  onChange = noop,
  initialValue,
}) => {
  const { t } = useTranslation();

  const getChildren = (): ChildrenType[] => {
    return Array.isArray(children) ? (children as ChildrenType[]) : [children];
  };

  const [expanded, setExpanded] = useState(false);
  const [value, setValue] = useState<string>('');

  // const [selectedTextRef, setSelectedTextRef] = useState(new Map<string, string>());
  const [selectedTextRef, setSelectedTextRef] = useReducer((map: Map<string, string>, kvp: string[]) => {
    const nMap = new Map(map);
    nMap.set(kvp[0], kvp[1]);
    return nMap;
  }, new Map<string, string>());

  useEffect(() => {
    if (initialValue) {
      setValue(initialValue as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  useEffect(() => {
    onChange({ value });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  function tapOnChange(childrenListItem: ChildrenType[]): JSX.Element[] {
    return childrenListItem.map(childListItem => {
      const getLabel = childListItem.props.getLabel ?? (() => '');
      const tappedGetLabel = (event: FilterDataEvent<unknown>) => {
        const childValue = childListItem.props.labelValue ?? childListItem.props.label ?? '';
        const valueLabel = getLabel(event);

        setSelectedTextRef([childValue, valueLabel]);
        return valueLabel;
      };

      return React.cloneElement(childListItem, {
        ...childListItem,
        value,
        key: childListItem.props.label,
        getLabel: tappedGetLabel,
      });
    });
  }

  const handleRadioChange = (ev: React.ChangeEvent<{ value: unknown }>) => {
    const v = ev.target.value as string;
    setValue(v);
  };

  return (
    <AccordionReports
      expanded={expanded}
      onChangeAccordion={() => setExpanded(!expanded)}
      firstTitle={title}
      secondTitle={selectedTextRef.get(value ?? '')}
    >
      <Grid container direction="column">
        <Grid item>
          <RadioGroup aria-label="filter component group" value={value} onChange={handleRadioChange}>
            <List>{tapOnChange(getChildren())}</List>
          </RadioGroup>
          <Divider />
        </Grid>
        <Grid item xs>
          <Button
            style={{ justifyContent: 'flex-end', width: '100%' }}
            color="primary"
            onClick={() => setExpanded(false)}
          >
            {t('filter.title.apply')}
          </Button>
        </Grid>
      </Grid>
    </AccordionReports>
  );
};

export default ComponentGroup;
