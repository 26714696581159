import { AgGridColumn, AgGridReact, AgGridReactProps } from '@ag-grid-community/react';
import { AllModules, Column, ColumnApi, FirstDataRenderedEvent, GridApi } from '@ag-grid-enterprise/all-modules';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-material.css';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ExpandDetailCellRenderer, { ExpandDetailCellRendererConfig } from './ExpandDetailCellRenderer';
import DefaultHeaderComponent from './HeaderComponents/DefaultHeaderComponent/DefaultHeaderComponent';
import './index.scss';

// Problem with module missing = https://stackoverflow.com/questions/58821634/trouble-setting-up-sample-table-could-not-find-matching-row-model-for-rowmodel
import { useStyles } from './styles';

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface DetailGridProps extends AgGridReactProps {
  reportQuery?: any;
  reportMetadata?: any;
  reportData?: any[];
  hideDetails?: boolean;
  expandCellRendererProps?: ExpandDetailCellRendererConfig;
}

const DetailGrid: React.FC<DetailGridProps> = props => {
  const {
    reportData = [],
    children,
    hideDetails = false,
    expandCellRendererProps = {},
    reportQuery = null,
    reportMetadata = null,
  } = props;
  const classes = useStyles();
  const idValue = uuidv4();
  const GRID_WIDTH_FIX = 0;
  const [invisibleColumns, setInvisibleColumns] = useState([] as Column[]);

  const onFirstDataRendered = (ev: FirstDataRenderedEvent) => {
    ev.api.sizeColumnsToFit();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onGridSizeChanged = (params: any) => {
    (params.api as GridApi).sizeColumnsToFit();
    const invisibleCols: Column[] = [];
    const gridWrapper = document.getElementById('grid-wrapper');
    const gridWidth = (gridWrapper?.offsetWidth ?? 640) - GRID_WIDTH_FIX;
    let totalColumnsWidth = 0;

    const allColumns = (params.columnApi as ColumnApi).getAllColumns();
    allColumns
      .filter((col: Column) => !col.isPinned())
      .filter((col: Column) => !col.getColDef().hide)
      .forEach((col: Column) => {
        totalColumnsWidth += col.getActualWidth();
        if (totalColumnsWidth > gridWidth) {
          invisibleCols.push(col);
        }
      });

    setInvisibleColumns(invisibleCols);
    (params.api as GridApi).redrawRows();
  };

  const hideExpandable = (): boolean => {
    const { linksElement, footerElement } = expandCellRendererProps;
    if (linksElement || footerElement) {
      return false;
    }
    return !invisibleColumns?.length || hideDetails;
  };

  return (
    <div id="grid-wrapper" className={classes.root}>
      <div id={`grid-${idValue}`} className="ag-theme-material">
        <AgGridReact
          rowData={reportData}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          // eslint-disable-next-line react/destructuring-assignment
          containerStyle={{ width: '100%', height: '50vh', ...props.containerStyle }}
          rowHeight={40}
          masterDetail
          modules={AllModules}
          suppressHorizontalScroll
          onGridSizeChanged={onGridSizeChanged}
          detailCellRendererFramework={ExpandDetailCellRenderer}
          detailCellRendererParams={{
            ...expandCellRendererProps,
            invisibleCols: invisibleColumns,
            reportQuery,
            reportMetadata,
          }}
          detailRowAutoHeight
          onFirstDataRendered={onFirstDataRendered}
          suppressContextMenu
          defaultColDef={{
            sortable: true,
            resizable: false,
            filter: false,
            suppressMenu: true,
            suppressMovable: true,
            minWidth: 42,
            headerComponentFramework: DefaultHeaderComponent,
          }}
        >
          {children}
          <AgGridColumn
            minWidth={42}
            maxWidth={42}
            pinned="right"
            cellRenderer="agGroupCellRenderer"
            hide={hideExpandable()}
            valueGetter={() => ''}
            cellStyle={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
        </AgGridReact>
      </div>
    </div>
  );
};

export default DetailGrid;
