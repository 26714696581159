import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      margin: '0',
      border: 0,
      '&::before': {
        top: '-1px',
        left: '0',
        right: '0',
        height: '1px',
        content: "''",
        opacity: '1',
        position: 'absolute',
        transition:
          'opacity 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        backgroundColor: 'rgba(0, 0, 0, 0.12)',
      },
      backgroundColor: '#ffffff',
      outline: 0,
      'box-shadow':
        '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
      width: '100%',
      color: 'inherit',
      cursor: 'pointer',
      padding: 0,
      borderColor: 'rgba(0, 0, 0, 0.54)',
      'border-radius': 0,
      position: 'relative',
    },
    Input: {
      textAlign: 'right',
    },
    margin: {
      width: '100%',
      margin: theme.spacing(1),
    },
  }),
);
