/* eslint-disable @typescript-eslint/no-explicit-any */
import { AgGridReact } from '@ag-grid-community/react';
import { Column, ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import { Box, Grid } from '@material-ui/core';
import { get } from 'lodash';
import React from 'react';
import { useStyles } from './styles';

interface IMultiFieldCellRenderer {
  field: string;
  fieldCellFormatter: (rowData: any, reportQuery: any, value: any) => any;
}

interface MultiColumnsCellRendererProps {
  fields?: string[];
  data: any;
  reportQuery?: any;
  column?: Column;
  agGridReact?: AgGridReact;
  fieldCellRenderers?: IMultiFieldCellRenderer[] | undefined;
}

const CELL_WIDTH_FIX = 40;

const MultiColumnsCellRenderer: React.FC<MultiColumnsCellRendererProps> = ({
  fields = [],
  data,
  column = null,
  agGridReact = null,
  reportQuery = null,
  fieldCellRenderers = [],
}) => {
  const classes = useStyles();
  if (!column) return <></>;

  const fullSize = column.getActualWidth() - CELL_WIDTH_FIX;
  const colWidth = fullSize / (fields.length || 1);
  const rowStyle = get(agGridReact, 'gridOptions.rowStyle');

  const valueGetter = (rowData: any, field: string) => {
    const value = get(rowData, field);
    const colDef = column.getColDef();
    const { valueFormatter } = colDef;

    if (fieldCellRenderers.length > 0) {
      // attempt to find a cell renderer if nothing is found for that field,
      // then use the default value formater if one is specified
      const renderer = fieldCellRenderers.find(item => {
        return item.field === field;
      });
      if (renderer) {
        const result: any = renderer.fieldCellFormatter(rowData, reportQuery, value);
        return result;
      }
    }

    if (valueFormatter instanceof Function) {
      return valueFormatter({ ...colDef, value } as ValueFormatterParams);
    }
    return value;
  };

  return (
    <Grid container justify="flex-start" className={classes.root}>
      <Grid item xs>
        <Box style={{ width: fullSize }}>
          {fields.map((field, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <span key={index} style={{ width: colWidth, ...rowStyle }}>
                {valueGetter(data, field)}
              </span>
            );
          })}
        </Box>
      </Grid>
    </Grid>
  );
};

export default MultiColumnsCellRenderer;
