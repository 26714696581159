import { get, set } from 'lodash';
import ReportResult from 'services/ReportResult';
import Impressions from 'types/Impressions';
import SpinsInfo from 'types/SpinsInfo';
import { SummaryItems } from './SummaryItem';

export interface SummarizedReportResult<T> extends ReportResult<T> {
  FormatDataTotals: T;
}

interface ApiResponse {
  StationSummaryItems?: SummaryItems[];
  FormatSummaryItems?: SummaryItems[];
}

function transform(property: string, data?: ApiResponse) {
  const values = get(data, property);
  const transformedData = {
    Count: values.length,
    Items: values,
  };
  return transformedData;
}

function summarizeFormatData(data: ReportResult<SummaryItems>): SummarizedReportResult<SummaryItems> {
  const zeroImpression: Impressions = { SpinsLastWeek: 0, SpinsThisWeek: 0, Move: 0, RankLastWeek: 0, RankThisWeek: 0 };
  const zeroSpins: SpinsInfo = { LastWeek: 0, ThisWeek: 0, Move: 0, Peak: 0 };

  const sumImpressions = (add: Impressions, curr: Impressions) => {
    const base = { ...zeroImpression };
    Object.keys(curr).forEach((key: string) => {
      set(base, key, get(add, key, 0) + get(curr, key, 0));
    });
    return base;
  };

  const sumSpins = (add: SpinsInfo, curr: SpinsInfo) => {
    const base = { ...zeroSpins };
    Object.keys(curr).forEach((key: string) => {
      set(base, key, get(add, key, 0) + get(curr, key, 0));
    });
    return base;
  }; 

  const reduced = data.Items.reduce(
    (add, curr) => {
      return {
        ...add,
        FirstSongSpinsInfo: sumSpins(add.FirstSongSpinsInfo, curr.FirstSongSpinsInfo),
        SecondSongSpinsInfo: sumSpins(add.SecondSongSpinsInfo, curr.SecondSongSpinsInfo),
        FirstSongImpressionsInfo: sumImpressions(add.FirstSongImpressionsInfo, curr.FirstSongImpressionsInfo),
        SecondSongImpressionsInfo: sumImpressions(add.SecondSongImpressionsInfo, curr.SecondSongImpressionsInfo),
        SpinDifferential: add.SpinDifferential + curr.SpinDifferential ,
      };
    },
    ({
      SpinDifferential: 0,
      FirstSongSpinsInfo: {...zeroSpins},
      FirstSongImpressionsInfo: { ...zeroImpression },
      SecondSongSpinsInfo: {...zeroSpins},
      SecondSongImpressionsInfo: { ...zeroImpression },
    } as unknown) as SummaryItems,
  );

  return {
    ...data,
    FormatDataTotals: reduced,
  };
}

export function stationSummaryResponseTransformer(data?: ApiResponse): ReportResult<SummaryItems> {
  return transform('StationSummaryItems', data);
}

export function formatSummaryResponseTransformer(data?: ApiResponse): SummarizedReportResult<SummaryItems> {
  return summarizeFormatData(transform('FormatSummaryItems', data));
}
