import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import { Routes } from 'types/Routes';

export const menuEntries: Routes[] = [
  {
    path: getReportSpec(ReportBaseEnum.PublishedChartReport).path,
    component: getReportSpec(ReportBaseEnum.PublishedChartReport).component,
  },
  {
    path: getReportSpec(ReportBaseEnum.BuildingChartReport).path,
    component: getReportSpec(ReportBaseEnum.BuildingChartReport).component,
  },
  {
    path: getReportSpec(ReportBaseEnum.RollingChartReport).path,
    component: getReportSpec(ReportBaseEnum.RollingChartReport).component,
  },
  {
    path: getReportSpec(ReportBaseEnum.BigPictureChartReport).path,
    component: getReportSpec(ReportBaseEnum.BigPictureChartReport).component,
  },
  {
    path: getReportSpec(ReportBaseEnum.ArtistChartReport).path,
    component: getReportSpec(ReportBaseEnum.ArtistChartReport).component,
  },
  {
    path: getReportSpec(ReportBaseEnum.MarketChartReport).path,
    component: getReportSpec(ReportBaseEnum.MarketChartReport).component,
  },
  {
    path: getReportSpec(ReportBaseEnum.ActivatorChartReport).path,
    component: getReportSpec(ReportBaseEnum.ActivatorChartReport).component,
  },
  {
    path: getReportSpec(ReportBaseEnum.IndieChartReport).path,
    component: getReportSpec(ReportBaseEnum.IndieChartReport).component,
  },
];
