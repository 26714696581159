import { AgGridColumn } from '@ag-grid-community/react';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import React from 'react';
import { TrendingPropsGrid } from '.';
import Daily from './Daily';
import { FormatItemsTransformer } from './utils/TrendingAnalysisTransformer';
import Weekly from './Weekly';

const FormatSummary: React.FC<TrendingPropsGrid> = ({ report, translate, setSettings, settings, filters }) => {
  const { sortModel, setSortModel: onSortChange } = useSortColumn({ sortPath: 'ReportName', sort: 'asc' });

  return (
    <>
      <ServerSideReportGrid
        sortModel={sortModel}
        settings={settings}
        setSettings={setSettings}
        filters={filters}
        transformResponse={FormatItemsTransformer}
      >
        <AgGridColumn
          field="FormatShort"
          headerName={translate('grid.header.FormatShort')}
          minWidth={80}
          width={80}
          headerComponentParams={{
            sortPath: 'FormatShort',
            onSortChange,
          }}
        />
        {Daily({ name: 'DAILY', range: report.DateRange, field: 'DailySpinTrends', onSortChange })}
        {Weekly({ name: 'WEEKLY', field: 'WeeklySpinTrends', onSortChange })}
        <AgGridColumn
          field="SpinsToDate"
          headerName={translate('grid.header.SpinsToDay')}
          minWidth={100}
          headerComponentParams={{
            sortModel,
            sortPath: 'SpinsToDate',
            onSortChange,
          }}
        />
      </ServerSideReportGrid>
    </>
  );
};

export default FormatSummary;
