import { AgGridColumn } from '@ag-grid-community/react';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import BooleanCanadaCheckMarkCellRenderer from 'components/DetailGrid/CellRenderers/BooleanCanadaCheckMarkCellRenderer';
import MultiColumnsCellRenderer from 'components/DetailGrid/CellRenderers/MultiColumnsCellRenderer/MultiColumnsCellRenderer';
import RankInfoCellRendererRec from 'components/DetailGrid/CellRenderers/RankInfoCellRendererRec/RankInfoCellRendererRec';
import SongCellRenderer from 'components/DetailGrid/CellRenderers/SongCellRenderer/SongCellRenderer';
import ThisLastMoveCellRenderer from 'components/DetailGrid/CellRenderers/ThisLastMoveCellRenderer';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import ThisLastMoveHeaderComponent from 'components/DetailGrid/HeaderComponents/ThisLastMoveHeaderComponent';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import { UseApiSettings } from 'hooks/useApi/ApiTypes';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import queryString from 'query-string';
import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import { CountryFormatCodeEnum } from 'types/Format';
import { PanelCodeEnum } from 'types/Panel';
import { ReportCycleIdEnum } from 'types/ReportCycle';
import { BuildingChartFilter, BuildingChartResponse } from './utils/BuildingChartReportTypes';
import { BuildingChartTransformer } from './utils/BuildingChartTransformer';

interface BuildingChartProps {
  filters: BuildingChartFilter | undefined;
}

const url = 'seven-day-building-chart-reports';
const countryFormatsCode = Object.values(CountryFormatCodeEnum) as string[];

const BuildingChartGrid: React.FC<BuildingChartProps> = ({ filters }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({
    sortPath: 'RankThisWeek',
    sort: 'asc',
  });

  const { setSettings, settings, data: reportData, isLoading, isError } = useRemoteApi<BuildingChartResponse>(url, {
    data: filters,
    method: 'POST',
    enabled: false,
    transformResponse: BuildingChartTransformer,
    transformRequest: data => {
      return {
        ...data,
        TopX: 150,
      };
    },
  });

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const songClickHandler = (data: any, reportQuery: BuildingChartFilter | undefined) => {
    const href = getReportSpec(ReportBaseEnum.SongAnalysisReport).path;
    const params = {
      songCode: data.SongInfo?.SongCode,
      'Formats.FormatCode': [reportQuery?.FormatInfo.FormatCode],
      'PanelInfo.Id': reportQuery?.PanelInfo.Id,
      NumDays: reportQuery?.NumDays,
      ReportCycle: ReportCycleIdEnum.building,
      deepLink: true,
    };
    window.open(`${href}?${queryString.stringify(params)}`);
  };

  return (
    <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
      <ServerSideReportGrid
        setSettings={(setSettings as unknown) as Dispatch<UseApiSettings<ReportResult<unknown>>>}
        settings={(settings as unknown) as UseApiSettings<ReportResult<unknown>>}
        filters={filters}
        sortModel={sortModel}
      >
        <AgGridColumn
          headerName={t('grid.header.Rank')}
          minWidth={60}
          width={60}
          field="Rank"
          headerComponentParams={{
            sortModel,
            onSortChange,
            disposition: 'row',
            secondLine: [
              { label: 'grid.header.LW', sortPath: 'RankLastWeek' },
              { label: 'grid.header.TW', sortPath: 'RankThisWeek' },
            ],
          }}
          cellRendererParams={{
            fields: ['Rank.ThisWeek', 'Rank.LastWeek'],
          }}
          cellRendererFramework={RankInfoCellRendererRec}
          headerComponentFramework={MultiColumnsHeaderComponent}
        />
        <AgGridColumn
          field="SongInfo"
          headerName={t(`grid.header.Title`)}
          minWidth={200}
          width={200}
          pinned
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            sortPath: 'Title',
            disposition: 'row',
            secondLine: [
              { label: 'grid.header.Artist', sortPath: 'Artist' },
              { label: 'grid.header.Label', sortPath: 'Label' },
            ],
          }}
          cellRendererFramework={SongCellRenderer}
          cellRendererParams={{
            reportQuery: filters,
            /* eslint-disable @typescript-eslint/no-explicit-any */
            onClickHandler: (data: any, reportQuery: any) => songClickHandler(data, reportQuery),
          }}
        />
        <AgGridColumn
          field="WeeksOnChart"
          minWidth={80}
          width={80}
          headerName={t(`grid.header.WeeksOnChart`)}
          headerComponentParams={{ sortModel, onSortChange }}
          valueFormatter={({ value }: ValueFormatterParams) => (value === 0 ? '--' : value)}
        />
        <AgGridColumn
          field="HasCanadianContent"
          headerName="CanCon"
          minWidth={70}
          cellRendererFramework={BooleanCanadaCheckMarkCellRenderer}
          headerComponentParams={{ sortModel, onSortChange, sortPath: 'HasCanadianContent' }}
          hide={
            !(
              filters?.PanelInfo?.PanelCode === PanelCodeEnum.AllCanadianStations ||
              filters?.PanelInfo?.PanelCode === PanelCodeEnum.PublishedCanadaPanel
            )
          }
        />
        <AgGridColumn
          field="PointsInfo"
          minWidth={95}
          headerName={t(`grid.header.PointsInfoTW`)}
          cellRendererFramework={ThisLastMoveCellRenderer}
          headerComponentFramework={ThisLastMoveHeaderComponent}
          headerComponentParams={{ sortModel, onSortChange }}
          hide={!countryFormatsCode.includes(filters?.FormatInfo?.FormatCode.toString() ?? '')}
        />
        <AgGridColumn
          field="SpinsWithinDateRange"
          minWidth={80}
          headerName={t(`grid.header.Spins`)}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            disposition: 'row',
            secondLine: [
              { sortPath: 'SpinsWithinDateRange/ThisWeek', label: ['grid.header.TW'] },
              { sortPath: 'SpinsWithinDateRange/LastWeek', label: ['grid.header.LW'] },
              { sortPath: 'SpinsWithinDateRange/Move', label: ['grid.header.Move'] },
            ],
          }}
          cellRendererFramework={ThisLastMoveCellRenderer}
        />
        <AgGridColumn
          field="ImpressionsInfo"
          headerName={t('grid.header.ImpressionsInfo')}
          width={400}
          minWidth={400}
          valueFormatter={({ value }: ValueFormatterParams) => `${+parseFloat(value).toFixed(3)}`}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            secondLine: [
              { label: `grid.header.TW`, sortPath: 'ImpressionsInfo/SpinsThisWeek' },
              { label: `grid.header.LW`, sortPath: 'ImpressionsInfo/SpinsLastWeek' },
              { label: `grid.header.plusMinus`, sortPath: 'ImpressionsInfo/Move' },
              { label: `grid.header.RTW`, sortPath: 'ImpressionsInfo/RankThisWeek' },
              { label: `grid.header.RLW`, sortPath: 'ImpressionsInfo/RankLastWeek' },
            ],
          }}
          cellRendererFramework={MultiColumnsCellRenderer}
          cellRendererParams={{
            fields: [
              'ImpressionsInfo.SpinsThisWeek',
              'ImpressionsInfo.SpinsLastWeek',
              'ImpressionsInfo.Move',
              'ImpressionsInfo.RankThisWeek',
              'ImpressionsInfo.RankLastWeek',
            ],
          }}
        />
        <AgGridColumn
          field="DayByDaySpinsDynamic"
          headerName={t('grid.header.DayByDaySpins')}
          width={250}
          minWidth={250}
          valueFormatter={({ value }: ValueFormatterParams) => `${+parseFloat(value).toFixed(3)}`}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            secondLine: reportData?.DayByDaySpinsHeaderDynamic,
          }}
          cellRendererFramework={MultiColumnsCellRenderer}
          cellRendererParams={{
            fields: reportData?.DayByDaySpinsFieldsDynamic,
          }}
        />
        <AgGridColumn
          field="DayByDayImpressionsDynamic"
          headerName={t('grid.header.DayByDayImpressions')}
          width={250}
          minWidth={250}
          valueFormatter={({ value }: ValueFormatterParams) => `${+parseFloat(value).toFixed(3)}`}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            secondLine: reportData?.DayByDayImpressionsHeaderDynamic,
          }}
          cellRendererFramework={MultiColumnsCellRenderer}
          cellRendererParams={{
            fields: reportData?.DayByDayImpressionsFieldsDynamic,
          }}
        />
      </ServerSideReportGrid>
    </GridVisibilityWrapper>
  );
};

export default BuildingChartGrid;
