import { Button, List, ListItem, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { SongInfo } from 'types/SongInfo';
import { v4 as uuidv4 } from 'uuid';
import { useStyles } from './styles';

interface Props {
  songs?: SongInfo[];
  onRemove?: (song: SongInfo) => void;
}

const SelectedSongs: React.FC<Props> = ({ songs = [], onRemove }) => {
  const classes = useStyles();

  const onClick = (song: SongInfo) => {
    if (onRemove) onRemove(song);
  };

  return (
    <List className={classes.selectedList}>
      {songs.map(song => {
        return (
          <ListItem divider key={uuidv4()} aria-label="Selected Song">
            <Button onClick={() => onClick(song)}>
              <CloseIcon color="primary" />
            </Button>
            <Typography variant="body1">{song.Title}</Typography>
            <Typography variant="body2">{`${song.Artist}, ${song.Label}`}</Typography>
          </ListItem>
        );
      })}
    </List>
  );
};

export default SelectedSongs;
