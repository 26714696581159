import { AgGridColumn } from '@ag-grid-community/react';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import DayPartsCellRenderer from 'components/DetailGrid/CellRenderers/DayPartsCellRenderer';
import ThisLastMoveCellRenderer from 'components/DetailGrid/CellRenderers/ThisLastMoveCellRenderer';
import DayPartsHeaderComponent from 'components/DetailGrid/HeaderComponents/DayPartsHeaderComponent';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import ThisLastMoveHeaderComponent from 'components/DetailGrid/HeaderComponents/ThisLastMoveHeaderComponent';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import { UseApiSettings } from 'hooks/useApi/ApiTypes';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import React, { Dispatch, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import { FilterParams } from 'types/FilterParams';
import { CountryFormatCodeEnum } from 'types/Format';
import { formatDate } from 'utils/dates';
import { normalizeFilters } from './utils/ActivactorChartReportTransform';
import { Items, ItemsDetails } from './utils/ActivactorChartTypes';

interface ActivactorChartGridProps {
  filters: FilterParams;
  detailGrid?: ReactElement;
  rowData?: ItemsDetails;
}

const url = 'seven-day-activator-chart-detail-reports';
const countryFormatsCode = Object.values(CountryFormatCodeEnum) as string[];

const ActivactorChartGrid: React.FC<ActivactorChartGridProps> = ({ filters, rowData }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({ sortPath: 'SpinsInfo', sort: 'asc' });
  const [newFilter, setNewFilter] = useState<FilterParams>({});
  const { data: reportData, isLoading, setSettings, settings, isError } = useRemoteApi<ReportResult<Items>>(url, {
    data: { ...filters, SongCode: rowData?.SongCode },
    params: { $top: 1000, $skip: 0, $inlinecount: 'allpages' },
    method: 'POST',
    enabled: !!rowData?.SongCode,
    transformRequest: data => normalizeFilters(data),
  });
  useEffect(() => {
    setNewFilter({ ...filters, SongCode: rowData?.SongCode });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData]);

  return (
    <>
      <GridVisibilityWrapper filters={newFilter} isLoading={isLoading} reportData={reportData} isError={isError}>
        <ServerSideReportGrid
          setSettings={(setSettings as unknown) as Dispatch<UseApiSettings<ReportResult<unknown>>>}
          settings={(settings as unknown) as UseApiSettings<ReportResult<unknown>>}
          filters={newFilter}
          sortModel={sortModel}
        >
          <AgGridColumn
            field="Station"
            minWidth={100}
            width={100}
            headerName={t(`grid.header.station`)}
            headerComponentParams={{
              sortModel,
              onSortChange,
              disposition: 'row',
            }}
          />
          <AgGridColumn
            field="MarketName"
            minWidth={120}
            width={120}
            headerName={t(`grid.header.Market`)}
            headerComponentParams={{
              sortModel,
              onSortChange,
              disposition: 'row',
            }}
          />
          <AgGridColumn
            field="FormatName"
            minWidth={80}
            width={80}
            headerName={t(`grid.header.FormatName`)}
            headerComponentParams={{
              sortModel,
              onSortChange,
              disposition: 'row',
            }}
          />
          <AgGridColumn
            field="FirstPlayed"
            minWidth={100}
            width={100}
            headerName={t(`grid.header.firstPlayed`)}
            valueFormatter={({ value }: ValueFormatterParams) => (value ? formatDate(value) : '--')}
            headerComponentParams={{
              sortModel,
              onSortChange,
              disposition: 'row',
            }}
          />
          <AgGridColumn
            field="PointsInfo"
            minWidth={95}
            headerName={t(`grid.header.PointsInfoTW`)}
            cellRendererFramework={ThisLastMoveCellRenderer}
            headerComponentFramework={ThisLastMoveHeaderComponent}
            headerComponentParams={{ sortModel, onSortChange }}
            hide={!countryFormatsCode.includes(filters?.FormatInfo?.FormatCode.toString() ?? '')}
          />
          <AgGridColumn
            field="SpinsInfo"
            minWidth={100}
            headerName={t(`grid.header.SpinsInfoTW`)}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{ secondLine: ['grid.header.LWMove'] }}
            cellRendererFramework={ThisLastMoveCellRenderer}
          />
          <AgGridColumn
            field="DaypartsInfo"
            headerName={t(`grid.header.DaypartsInfo`)}
            width={230}
            minWidth={230}
            valueFormatter={({ value }: ValueFormatterParams) => (value === 0 || !value ? '--' : value)}
            headerComponentFramework={DayPartsHeaderComponent}
            headerComponentParams={{ sortModel, onSortChange }}
            cellRendererFramework={DayPartsCellRenderer}
          />
          <AgGridColumn
            field="SpinsToDate"
            minWidth={100}
            width={100}
            headerName={t(`grid.header.SpinsToDate`)}
            headerComponentParams={{
              sortModel,
              onSortChange,
              disposition: 'row',
            }}
          />
        </ServerSideReportGrid>
      </GridVisibilityWrapper>
    </>
  );
};

export default ActivactorChartGrid;
