import ReportResult from 'services/ReportResult';
import { TwelveWeekReport } from './TwelveWeekReport';

export const TwelveWeekTransformer = (reportData: ReportResult<TwelveWeekReport>): ReportResult<TwelveWeekReport> => {
  const normalize = reportData.Items.map(item => {
    return {
      ...item,
      SongInfo: {
        Artist: item.Artist,
        Label: item.Label,
        Title: item.Title,
      },
    };
  });
  return { ...reportData, Items: normalize };
};

export default TwelveWeekTransformer;
