import FilterButton from 'components/FilterButton';
import ReportHeader from 'components/ReportHeader';
import SiblingReportsSelect from 'components/SiblingReportsSelect/SiblingReportsSelect';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Station } from 'types/Station';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import queryString from 'query-string';
import { formatDate, formatDateTypes } from 'utils/dates';
import { getStationDisplayName } from 'utils/reports/GetStationDisplayName';
import SelectInput from '../../../../../components/SelectInput';
import { BodyReportContainer, Container, GridContainer, ReportContainer, SongsContainer } from '../../../styles';
import Filter from './Filter';
import SpinsGrid from './SpinsGrid';
import { StationSpinGridFilter, TransformStationSpinGridRow } from './utils/StationSpinGridTypes';

const StationSpinGridReport: React.FC = () => {
  const { t } = useTranslation();

  const [filterOpened, setFilterOpened] = useState(false);
  const [filter, setFilter] = useState<StationSpinGridFilter>();

  const onChangeFilter = (filtersParams?: StationSpinGridFilter) => {
    const StationInfo = filtersParams?.Stations?.length ? filtersParams.Stations[0] : undefined;
    const newFilter = { ...filtersParams, StationInfo };
    setFilter(newFilter);
    setFilterOpened(false);
  };

  const onChangeStation = (station: Station) => {
    setFilter({ ...filter, StationInfo: station });
  };

  const onSpinDateClickHandler = (rowData: TransformStationSpinGridRow) => {
    const href = getReportSpec(ReportBaseEnum.DailyLogsReport).path;
    const params = {
      callLetters: rowData.StationName,
      endDate: formatDate(rowData.SpinDate, formatDateTypes.yyyyMMdd),
      deepLink: true,
    };
    window.open(`${href}?${queryString.stringify(params)}`);
  };

  return (
    <>
      <Container container direction="column" spacing={0}>
        <ReportHeader title={t('report.label.station')} subtitle="7-Day" />
        <ReportContainer>
          <SiblingReportsSelect />
          <FilterButton onClickFilter={() => setFilterOpened(!filterOpened)} isOpened={filterOpened} />
        </ReportContainer>
        <Filter isOpened={filterOpened} changeFilter={onChangeFilter} />
        <BodyReportContainer hidden={!filter}>
          <SongsContainer>
            <SelectInput
              normalizeLabel={option => `${getStationDisplayName(option)}`}
              optionLabel={t('filter.title.station')}
              options={filter?.Stations ?? []}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              value={filter?.StationInfo ?? ''}
              onChangeValue={onChangeStation}
            />
          </SongsContainer>
          <GridContainer>
            <SpinsGrid filters={filter} onSpinDateClickHandler={onSpinDateClickHandler} />
          </GridContainer>
        </BodyReportContainer>
      </Container>
    </>
  );
};

export default StationSpinGridReport;
