import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      '& p, & span': {
        fontSize: '.9em',
        fontWeight: 'normal',
      },
      '& .ag-theme-material .ag-cell': {
        paddingLeft: '7px',
        paddingRight: '7px',
      },
      '& .ag-theme-material .ag-header-cell': {
        paddingLeft: '8px',
        paddingRight: '8px',
      },
      '& .ag-header-container .ag-react-container': {
        display: 'flex',
        width: '100%',
      },
      '& .ag-group-expanded': {
        marginRight: '0px',
      },
      '& .ag-icon': {
        fontSize: '16px',
        paddingLeft: '70%',
        color: '#197bba',
        fontWeight: 'bold',
      },
    },
  }),
);
