export const OWNER_ALL = {
  Id: -1,
  Name: 'All',
};

export const FORMAT_ALL = {
  Id: 0,
  Name: 'All',
  FormatCode: 'XX',
};

// eslint-disable-next-line no-shadow
export enum SongAnalysisTabEnum {
  SongAnalysis = 0,
  SongHistory = 1,
  ArtistHistory = 2,
}

export const SongAnalysisTabs = [
  {
    label: 'report.songAnalysis.tabs.songAnalysis',
    value: 'songAnalysis',
  },
  {
    label: 'report.songAnalysis.tabs.songHistory',
    value: 'songHistory',
  },
  {
    label: 'report.songAnalysis.tabs.artistHistory',
    value: 'artistHistory',
  },
];

export const SongAnalysisGrids = [
  {
    name: 'report.songAnalysis.grids.stationDetails',
    value: 'stationDetails',
  },
  {
    name: 'report.songAnalysis.grids.executiveSummary',
    value: 'executiveSummary',
  },
  {
    name: 'report.songAnalysis.grids.executiveVideoSummary',
    value: 'executiveVideoSummary',
  },
  {
    name: 'report.songAnalysis.grids.dayPartSummary',
    value: 'dayPartSummary',
  },
  {
    name: 'report.songAnalysis.grids.groupOwnerSummary',
    value: 'groupOwnerSummary',
  },
];

export const SongHistoryGrids = [
  {
    name: 'report.songAnalysis.grids.songHistory',
    value: 'songHistory',
  },
  {
    name: 'report.songAnalysis.grids.formatHistory',
    value: 'formatHistory',
  },
  {
    name: 'report.songAnalysis.grids.grouperOwnerHistory',
    value: 'groupOwnerHistory',
  },
];
