import { useAppApi } from "services/api";
import { useQuery, QueryClient, UseQueryResult } from "react-query";
import { AxiosError } from "axios";
import AppProblem from "types/AppProblem";
import { StationPortfolioGroup } from "types/StationPortfolio";

const useStationPorfolioGroupGetAll = ():UseQueryResult<Array<StationPortfolioGroup>, AxiosError<AppProblem>> =>{
    const api = useAppApi();   
    const url = '/station-portfolio-groups';    
    const cacheKey = 'station-portfolio-groups-all-cache';
    return useQuery<Array<StationPortfolioGroup>, AxiosError<AppProblem>>(cacheKey, () => {
        return api.get(url)
            .then((resp)=>
            { 
                return resp.data 
            });
    });
};

const useStationPorfolioGroupGetById = (id: number):UseQueryResult<StationPortfolioGroup, AxiosError<AppProblem>> =>
{
    const queryClient = new QueryClient();
    
    const api = useAppApi();   
    const url = `/station-portfolio-groups/${id}`;    
    const cacheKey = 'station-portfolio-groups-get';

    return useQuery<StationPortfolioGroup, AxiosError<AppProblem>>(cacheKey, () => {
        return api.get(url)
            .then((resp)=>
            { 
                queryClient.invalidateQueries(cacheKey);
                return resp.data 
            });
    });    
};

export {useStationPorfolioGroupGetAll, useStationPorfolioGroupGetById};