import useRemoteApi from 'hooks/useApi/useRemoteApi';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import FilterDataEvent from 'types/FilterDataEvent';
import FilterDataProps from 'types/FilterDataProps';
import { Market } from 'types/Market';
import { PanelCodeEnum } from 'types/Panel';
import GenericSelect from '../GenericSelect';

export interface MarketSelectFilterData {
  MarketInfo?: Market;
}

export interface MarketFilterDataProps<T> extends FilterDataProps<T> {
  panelCode?: PanelCodeEnum;
}

const ALL_OPTION = {
  Id: 0,
  Name: 'All',
  MarketCode: 'XX',
};

const url = '/markets';
const MarketSelect = <T extends MarketSelectFilterData>({
  onChange,
  panelCode = PanelCodeEnum.None,
  initialValue,
  showAll = false,
}: MarketFilterDataProps<T>): ReactElement => {
  const { t } = useTranslation();
  const { data: filterData } = useRemoteApi<Market[]>(url, {
    method: 'GET',
    params: { $force: true, panelCode },
    enabled: panelCode !== PanelCodeEnum.None,
    transformResponse: data => {
      if (showAll) {
        return [ALL_OPTION, ...data];
      }
      return data;
    },
  });

  const handleSelect = (evt: FilterDataEvent<{}>) => {
    if (onChange) {
      const v = evt.value as Market;
      onChange({ value: { MarketInfo: v } as T });
    }
  };
  return (
    <GenericSelect
      title={t('filter.title.Market')}
      data={filterData}
      onChange={handleSelect}
      initialValue={initialValue}
      disabled={panelCode === PanelCodeEnum.None}
    />
  );
};

export default MarketSelect;
