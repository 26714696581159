import { set } from 'lodash';

const getParsedValue = (v: string): unknown => {
  // only parse when dealing with a numeric value
  // alphanumeric values are ok to handle as strings
  if (/^\d+$/.test(v)) {
    return parseFloat(v);
  }
  return v;
 };

export function defaultFilterTransformer<T extends {}>(queryString: Map<string, string>, filter: T): T {
  let newFilter = { ...filter };
  Array.from((queryString ?? new Map()).entries()).forEach(([k, v]) => {
    const value = set(newFilter, k, getParsedValue(v));
    newFilter = { ...newFilter, ...value };
  });

  return newFilter as T;
}
