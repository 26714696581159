import { InputAdornment, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import SearchIcon from '@material-ui/icons/Search';

import FilterDataEvent from 'types/FilterDataEvent';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

interface SearchInputProps {
  onSearch: { (): void };
  onChange: { (filter: FilterDataEvent<{ SearchTerm: string }>): void };
}

const InputSearch: React.FC<SearchInputProps> = ({ onChange, onSearch }) => {
  const [searchText, setSearchText] = useState('');
  const classes = useStyles();
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    onChange({ value: { SearchTerm: event.target.value as string } });
  };

  return (
    <TextField
      placeholder={t('fields.Search')}
      className={classes.inputSearch}
      value={searchText}
      onChange={handleChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon className={classes.icon} aria-label="toggle search" onClick={() => onSearch()} />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default InputSearch;
