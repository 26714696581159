/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import AccordionFilter from 'components/Filter/components/AccordionFilter';
import CheckboxFilter from 'components/Filter/components/Checkbox/index';
import ComponentGroup from 'components/Filter/components/ComponentGroup';
import ComponentGroupItem from 'components/Filter/components/ComponentGroup/ComponentGroupItem';
import FormatMultipleFilter from 'components/Filter/components/FormatMultipleFilter';
import ReportCycle from 'components/Filter/components/ReportCycle';
import SongPortfolioSelect from 'components/Filter/components/SongPortfolioSelect';
import SpinModeCycle from 'components/Filter/components/SpinsModeCycle';
import StationPortfolioGroupSelect from 'components/Filter/components/StationPorfolioGroup';
import StationPortfolioSelect from 'components/Filter/components/StationPortfolioSelect';
import FilterBase from 'components/FilterBase';
import useStickyFilter from 'hooks/useStickyFilter/useStickyFilter';
import { get, set } from 'lodash';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FilterDataEvent from 'types/FilterDataEvent';
import { PanelCodeEnum } from 'types/Panel';
import { STATION_PORTFOLIO, STATION_PORTFOLIO_GROUP } from 'utils/Constants';
import _isEmpty from 'lodash/isEmpty';
import { ExtendedSongTrackingReportFilter } from './utils/SongTrackingReportTransform';
import { SongTrackingFilter } from './utils/SongTrackingType';

interface FilterSevenDaysSongTracking {
  changeFilter: { (filters?: SongTrackingFilter): any };
  isOpened: boolean;
}

const FilterSongTracking: React.FC<FilterSevenDaysSongTracking> = ({ changeFilter, isOpened }) => {
  const { t } = useTranslation();
  const pathname = getReportSpec(ReportBaseEnum.SongsRegionsMarketsSongTracking).stickyKey;
  const { savedFilters: filter, saveFilter } = useStickyFilter<ExtendedSongTrackingReportFilter>(pathname);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleFilterChange = (event: FilterDataEvent<object>) => {
    const { value } = event;
    const filterValues = { ...filter } as ExtendedSongTrackingReportFilter;
    const filterEvent = { ...value } as ExtendedSongTrackingReportFilter;
    // is either a station portfolio or station portfolio group
    if (filterEvent && filterEvent.stationGroup === STATION_PORTFOLIO) {
      filterValues.StationPortfolioGroup = undefined;
      filterValues.stationGroup = STATION_PORTFOLIO;
    } else if (filterEvent && filterEvent.stationGroup === STATION_PORTFOLIO_GROUP) {
      filterValues.StationPortfolio = undefined;
      filterValues.stationGroup = STATION_PORTFOLIO_GROUP;
    }

    saveFilter({ ...filterValues, ...value } as ExtendedSongTrackingReportFilter);
  };

  const componentGroupChangeHandler = (prop: string, { value }: FilterDataEvent<string>) => {
    const v = set({}, prop, value);
    if (!_isEmpty(value)) {
      handleFilterChange({ value: v });
    }
  };

  const getStationSelectionLabel = () => {
    const selection = filter?.stationGroup ?? STATION_PORTFOLIO;
    return selection === STATION_PORTFOLIO
      ? t('filter.title.stationPortfolio')
      : t('filter.title.stationPortfolioGroup');
  };

  return (
    <FilterBase onSubmit={() => changeFilter(filter)} isOpened={isOpened}>
      <ComponentGroup
        title={getStationSelectionLabel()}
        onChange={v => componentGroupChangeHandler('stationGroup', v)}
        initialValue={filter?.stationGroup ?? STATION_PORTFOLIO}
      >
        <ComponentGroupItem
          label={t('filter.title.stationPortfolio')}
          getLabel={({ value }) => get(value, 'StationPortfolio.Name')}
          labelValue={STATION_PORTFOLIO}
        >
          <StationPortfolioSelect onChange={handleFilterChange} initialValue={filter?.StationPortfolio} />
        </ComponentGroupItem>
        <ComponentGroupItem
          label={t('filter.title.stationPortfolioGroup')}
          getLabel={({ value }) => get(value, 'StationPortfolioGroup.Name')}
          labelValue={STATION_PORTFOLIO_GROUP}
        >
          <StationPortfolioGroupSelect onChange={handleFilterChange} initialValue={filter?.StationPortfolioGroup} />
        </ComponentGroupItem>
      </ComponentGroup>
      <AccordionFilter
        title="Song Portfolio"
        getLabel={({ value }) => get(value, 'SongPortfolio.Name')}
        closeOnChangeSelection
      >
        <SongPortfolioSelect onChange={handleFilterChange} initialValue={filter?.SongPortfolio} />
      </AccordionFilter>
      <FormatMultipleFilter
        panelCode={PanelCodeEnum.AllUsStations}
        onChange={handleFilterChange}
        values={filter?.Formats}
        apiPropertyName="Formats"
      />
      <SpinModeCycle onChange={handleFilterChange} initialValue={filter?.SpinMode} />
      <ReportCycle onChange={handleFilterChange} initialValue={filter?.Cycle} />
      <CheckboxFilter
        onChange={handleFilterChange}
        name={t('filter.title.IsPublishedStationsOnly')}
        valueToApi="IsPublishedStationsOnly"
        initialValue={filter?.IsPublishedStationsOnly || false}
      />
    </FilterBase>
  );
};

export default FilterSongTracking;
