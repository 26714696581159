import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { SpinsInfo } from 'types/SpinsInfo';
import { BuildingDayInfo } from 'types/BuildingDayInfo';
import { get } from 'lodash';
import { daysAdd, daysMinus, getNameOfTheWeekWithDate } from 'utils/dates';
import InnerTable from '..';
import { IInnerTableGroup } from '../inner-table-group';

export interface DayByDaySpinsData {
  DayByDaySpins?: Record<number, SpinsInfo>;
}

export interface DayByDaySpinsMetadata {
  DateData: Array<BuildingDayInfo>;
}

interface DayByDaySpinsProps {
  data: DayByDaySpinsData;
  reportMetadata?: DayByDaySpinsMetadata;
  showDaysAsNumber: boolean;
}

const DayByDaySpinsInnerTable: React.FC<DayByDaySpinsProps> = ({
  data,
  reportMetadata,
  showDaysAsNumber,
}: DayByDaySpinsProps) => {
  const { t } = useTranslation();
  const createSpan = (item: string) => {
    // eslint-disable-next-line react/jsx-one-expression-per-line
    return <span>{item} </span>;
  };

  const getDefaultReportMetadata = () => {
    const start = daysMinus(new Date(), 7);

    const dateData = [1, 2, 3, 4, 5, 6, 7].map(idx => {
      const colDate = daysAdd(start, idx);
      return {
        NumDay: idx.toString(),
        DayText: getNameOfTheWeekWithDate(colDate),
        BuildingDate: colDate,
      };
    });
    const result = { DateData: dateData };
    return result;
  };

  const metadata = reportMetadata != null ? reportMetadata : getDefaultReportMetadata();

  const headers: Array<JSX.Element> = [];
  const values: Array<JSX.Element> = [];
  metadata.DateData.forEach(item => {
    const numDay = parseInt(item.NumDay, 10);

    const label = showDaysAsNumber === true ? `-${numDay}` : item.DayText.substring(0, 3);
    headers.push(createSpan(label));

    const value = get(data.DayByDaySpins, `${numDay}.ThisWeek`);
    const formattedValue = value == null ? '-' : value.toString();
    values.push(createSpan(formattedValue));
  });

  const innerTableData: IInnerTableGroup[] = [
    {
      title: t('grid.header.DayByDaySpins'),
      children: [
        {
          title: '',
          children: headers,
        },
        {
          title: '',
          children: values,
        },
      ],
    },
  ];

  return <InnerTable key={v4()} data={innerTableData} />;
};

DayByDaySpinsInnerTable.defaultProps = {
  reportMetadata: undefined,
};

export default DayByDaySpinsInnerTable;
