import ComponentGroup from 'components/Filter/components/ComponentGroup';
import ComponentGroupItem from 'components/Filter/components/ComponentGroup/ComponentGroupItem';
import FormatFilter from 'components/Filter/components/FormatFilter';
import PanelFilter from 'components/Filter/components/PanelFilter';
import PastDays, { getCurrentBuildingCycle } from 'components/Filter/components/PastDays';
import SongPortfolioSelect from 'components/Filter/components/SongPortfolioSelect';
import SongSelection from 'components/Filter/components/SongSelection';
import StationPortfolioGroupSelect from 'components/Filter/components/StationPorfolioGroup';
import StationPortfolioSelect from 'components/Filter/components/StationPortfolioSelect';
import StationSelect from 'components/Filter/components/StationSelect/SelectedStation';
import FilterBase from 'components/FilterBase';
import useDeepLink from 'hooks/useDeepLink/useDeepLink';
import { defaultFilterTransformer } from 'hooks/useDeepLink/utils/defaultFilterTransformer';
import useFilterState from 'hooks/useFilterState/useFilterState';
import useStickyFilter from 'hooks/useStickyFilter/useStickyFilter';
import { get } from 'lodash';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FilterDataEvent from 'types/FilterDataEvent';
import { SongInfo } from 'types/SongInfo';
import { SongPortfolio } from 'types/SongPortfolio';
import { Station } from 'types/Station';
import {
  SONG_PORTFOLIO,
  SONG_SELECTION,
  STATION_BY_FORMATS,
  STATION_PORTFOLIO,
  STATION_PORTFOLIO_GROUP,
  STATION_SELECTION,
} from 'utils/Constants';
import { getStationDisplayName } from 'utils/reports/GetStationDisplayName';
import { songPortfolioFilterTransformer } from '../../../../../utils/songPortfolioFilterTransformer';
import { ExtendedRealTimeSongAnalysisFilter, RealTimeSongAnalysisFilter } from './types/RealTimeSongAnalysis';
import { searchModeResolver } from './utils/searchModeResolver';

interface FilterCurrentSongsReport {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeFilter: { (filters: RealTimeSongAnalysisFilter): any };
  isOpened: boolean;
}

const Filter: React.FC<FilterCurrentSongsReport> = ({ changeFilter, isOpened }) => {
  const { t } = useTranslation();
  const pathname = getReportSpec(ReportBaseEnum.CurrentSongsReport).stickyKey;
  const { savedFilters: filter, saveFilter } = useStickyFilter<ExtendedRealTimeSongAnalysisFilter>(pathname);
  const { isReady: isFilterReady, changeState } = useFilterState();

  // eslint-disable-next-line no-shadow,@typescript-eslint/no-explicit-any
  const handleOnSubmit = (filter: any) => {
    if (filter) {
      const transformedFilter = songPortfolioFilterTransformer(filter);
      changeFilter(transformedFilter);
    }
  };

  // eslint-disable-next-line no-shadow
  const { hasDeepLinkInfo } = useDeepLink(filter, saveFilter, isFilterReady, {
    onReady: handleOnSubmit,
    // eslint-disable-next-line no-shadow
    filterTransformer: (queryString: Map<string, string>, filter: ExtendedRealTimeSongAnalysisFilter) => {
      const newFilter = defaultFilterTransformer(queryString, filter);
      return { ...newFilter, stationGroup: STATION_BY_FORMATS, songGroup: SONG_SELECTION };
    },
  });

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleFilterChange = (event: FilterDataEvent<object>) => {
    const { value } = event;
    saveFilter({ ...value } as ExtendedRealTimeSongAnalysisFilter);
  };

  const handleSongGroupChange = ({ value }: FilterDataEvent<string>) => {
    handleFilterChange({
      value: {
        SongSelectionType: value === SONG_PORTFOLIO ? 1 : 2,
        songGroup: value,
      },
    });
  };

  const handleStationGroupChange = ({ value }: FilterDataEvent<string>) => {
    if (value) {
      const newValues = searchModeResolver(value);
      handleFilterChange({ value: { ...newValues, ...{ stationGroup: value } } });
    }
  };

  const handleSongSectionFilterChange = ({ value }: FilterDataEvent<{ songInfos?: SongInfo[] }>) => {
    const songInfos = value?.songInfos;
    saveFilter({ songInfos, songSelectionSongInfos: songInfos } as ExtendedRealTimeSongAnalysisFilter);
  };

  const handleSongPortfolioFilterChange = ({
    value,
  }: FilterDataEvent<{ songInfos?: SongInfo[]; SongPortfolio?: SongPortfolio }>) => {
    const songInfos = value?.songInfos;
    saveFilter({
      songInfos,
      songPortfolioSongInfos: songInfos,
      SongPortfolio: value?.SongPortfolio,
    } as ExtendedRealTimeSongAnalysisFilter);
  };

  const getSelectedSongsText = ({ value }: FilterDataEvent<unknown>) => {
    const songInfos = get(value, 'songInfos') as SongInfo[] | undefined;
    if (songInfos && songInfos.length) {
      return songInfos.length === 1 ? songInfos[0]?.Title : t('filter.label.various');
    }
    return '';
  };

  const getStationsLabel = ({ value }: FilterDataEvent<unknown>): string => {
    const stations = get(value, 'Stations') as Station[] | undefined;
    if (stations && stations.length) {
      return stations.length === 1 ? getStationDisplayName(stations[0]) ?? '' : t('filter.label.various');
    }
    return '';
  };

  return (
    <>
      <FilterBase onSubmit={() => handleOnSubmit(filter)} isOpened={isOpened} isFilterReady={isFilterReady}>
        <PanelFilter
          onChange={handleFilterChange}
          initialValue={filter?.PanelInfo}
          changeState={changeState}
          required
        />
        <ComponentGroup
          title={t('filter.title.songs')}
          onChange={handleSongGroupChange}
          initialValue={filter?.songGroup ?? SONG_SELECTION}
        >
          <ComponentGroupItem
            label={t('filter.label.SelectedSongs')}
            getLabel={getSelectedSongsText}
            labelValue={SONG_SELECTION}
          >
            <SongSelection
              onChange={handleSongSectionFilterChange}
              initialValue={filter?.songSelectionSongInfos}
              songCode={hasDeepLinkInfo ? filter?.songCode : undefined}
              changeState={changeState}
              required
            />
          </ComponentGroupItem>
          <ComponentGroupItem
            labelValue={SONG_PORTFOLIO}
            label={t('filter.label.SongPortfolios')}
            getLabel={({ value }) => get(value, 'SongPortfolio.Name') ?? ''}
          >
            <SongPortfolioSelect
              onChange={handleSongPortfolioFilterChange}
              initialValue={filter?.SongPortfolio}
              stateControl={changeState}
            />
          </ComponentGroupItem>
        </ComponentGroup>
        <ComponentGroup
          title={t('filter.title.SearchBy')}
          onChange={evt => handleStationGroupChange(evt)}
          initialValue={filter?.stationGroup ?? STATION_BY_FORMATS}
        >
          <ComponentGroupItem
            label={t('filter.title.formats')}
            getLabel={({ value }) => get(value, 'FormatInfo.Name')}
            labelValue={STATION_BY_FORMATS}
          >
            <FormatFilter
              panelCode={filter?.PanelInfo?.PanelCode}
              onChange={handleFilterChange}
              title=""
              initialValue={filter?.FormatInfo}
              changeState={changeState}
              required
            />
          </ComponentGroupItem>
          <ComponentGroupItem
            label={t('filter.title.stations')}
            getLabel={getStationsLabel}
            labelValue={STATION_SELECTION}
          >
            <StationSelect title="Station Select" onChange={handleFilterChange} initialValue={filter?.Stations} />
          </ComponentGroupItem>
          <ComponentGroupItem
            labelValue={STATION_PORTFOLIO}
            label={t('filter.title.stationPortfolio')}
            getLabel={({ value }) => get(value, 'StationPortfolio.Name')}
          >
            <StationPortfolioSelect onChange={handleFilterChange} initialValue={filter?.StationPortfolio} />
          </ComponentGroupItem>
          <ComponentGroupItem
            labelValue={STATION_PORTFOLIO_GROUP}
            label={t('filter.title.stationPortfolioGroup')}
            getLabel={({ value }) => get(value, 'StationPortfolioGroup.Name')}
          >
            <StationPortfolioGroupSelect onChange={handleFilterChange} initialValue={filter?.StationPortfolioGroup} />
          </ComponentGroupItem>
        </ComponentGroup>
        <PastDays
          hasTodayOption
          lowerBound={1}
          upperBound={7}
          onChange={handleFilterChange}
          initialValue={getCurrentBuildingCycle()}
        />
      </FilterBase>
    </>
  );
};

export default Filter;
