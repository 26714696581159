import { Grid, ThemeProvider } from '@material-ui/core';
import AppMenu from 'pages/Menu';
import PageFooter from 'components/PageFooter/PageFooter';
import AuthSettings from 'contexts/AuthContext';
import { NavigationMenuProvider } from 'contexts/NavigationMenuContext';
import { UserPreferencesProvider } from 'contexts/UserPreferencesContext';
import React, { useContext } from 'react';
import Theme from '../themes';

const DefaultViewLayout: React.FC = ({ children }) => {
  const { isAuthenticated } = useContext(AuthSettings);

  return (
    <>
      <ThemeProvider theme={Theme}>
        <NavigationMenuProvider>
          <UserPreferencesProvider>
            <Grid>
              <Grid item xs={12} className="content">
                {isAuthenticated() && <AppMenu />}
                {children}
              </Grid>
              <Grid item xs={12} container className="theme-background-color">
                <PageFooter />
              </Grid>
            </Grid>
          </UserPreferencesProvider>
        </NavigationMenuProvider>
      </ThemeProvider>
    </>
  );
};

export default DefaultViewLayout;
