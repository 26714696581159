import ReportResult from 'services/ReportResult';
import { ArtistSongByStation, TransformedArtistSongByStation } from './types';

function convertItem(data: ArtistSongByStation): TransformedArtistSongByStation {
  return {
    ...data,
    // data here is not mapped 1:! because the label infor mation must
    // display on cell renderer
    SongInfo: {
      Artist: data?.Label ?? '',
      Label: data?.ReleaseYear?.toString() ?? '',
      Title: data?.Title ?? '',
    },
  };
}

export function artistSongByStationResponseTransformer(
  data: ReportResult<ArtistSongByStation>,
): ReportResult<TransformedArtistSongByStation> {
  return {
    ...data,
    Items: data.Items.map(convertItem),
  };
}
