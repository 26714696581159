import GenericSelect from 'components/Filter/components/GenericSelect';
import { usePrevious } from 'hooks/usePrevious/usePrevious';
import { isEqual, noop } from 'lodash';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilterDataProps from 'types/FilterDataProps';

// eslint-disable-next-line no-shadow
export enum ReportModeIndexes {
  SEVEN_DAY = 1,
  YEAR_TO_DATE = 2,
  FIFTY_TWO_WEEKS = 3,
  HISTORY = 4,
}

interface ReportMode {
  Id: number;
  Name: string;
}

export interface ReportModeFilterData {
  ReportMode?: number;
}

const ReportModeSelect = <T extends ReportModeFilterData>({
  initialValue,
  onChange = noop,
}: FilterDataProps<T>): ReactElement => {
  const { t } = useTranslation();

  const reportModes: ReportMode[] = [
    {
      Name: t('filter.reportmode.sevenday'),
      Id: ReportModeIndexes.SEVEN_DAY,
    },
    {
      Name: t('filter.reportmode.yeartodate'),
      Id: ReportModeIndexes.YEAR_TO_DATE,
    },
    {
      Name: t('filter.reportmode.fiftytwoweeks'),
      Id: ReportModeIndexes.FIFTY_TWO_WEEKS,
    },
    {
      Name: t('filter.reportmode.hystory'),
      Id: ReportModeIndexes.HISTORY,
    },
  ];

  const [selectedValue, setSelectedValue] = useState<ReportMode>();
  const previousSelected = usePrevious(selectedValue);

  useEffect(() => {
    if (!isEqual(previousSelected, selectedValue)) {
      onChange({ value: { ReportMode: selectedValue?.Id } as T });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  useEffect(() => {
    const find = reportModes.find(v => v.Id === initialValue);
    if (find) {
      setSelectedValue(find);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const handleChange = (event: { value: unknown }) => {
    const { value } = event;
    setSelectedValue(value as ReportMode);
  };

  return (
    <>
      <GenericSelect
        title={t('filter.label.ReportMode')}
        data={reportModes}
        onChange={handleChange}
        initialValue={selectedValue}
      />
    </>
  );
};

export default ReportModeSelect;
