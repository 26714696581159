import { createStyles } from '@material-ui/core/styles';
import { Grid, makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Grid)`
  display: flex;
  max-height: 400px;
  overflow-y: auto;
  flex: 1;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  width: 100%;
  padding: 10px;
`;

export const useFormLabelStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '45%',
    },
  }),
);
