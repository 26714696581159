/* eslint-disable @typescript-eslint/no-explicit-any */
import CheckboxFilter from 'components/Filter/components/Checkbox/index';
import ComponentGroup from 'components/Filter/components/ComponentGroup';
import ComponentGroupItem from 'components/Filter/components/ComponentGroup/ComponentGroupItem';
import FormatMultipleFilter from 'components/Filter/components/FormatMultipleFilter';
import ReportCycle from 'components/Filter/components/ReportCycle';
import SongPortfolioSelect, { SongPortfolioFilterData } from 'components/Filter/components/SongPortfolioSelect';
import SongSelection from 'components/Filter/components/SongSelection';
import SpinModeCycle from 'components/Filter/components/SpinsModeCycle';
import StationPortfolioGroupSelect from 'components/Filter/components/StationPorfolioGroup';
import StationPortfolioSelect from 'components/Filter/components/StationPortfolioSelect';
import FilterBase from 'components/FilterBase';
import useStickyFilter from 'hooks/useStickyFilter/useStickyFilter';
import { get, set } from 'lodash';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FilterDataEvent from 'types/FilterDataEvent';
import { SongInfo } from 'types/SongInfo';
import { SONG_PORTFOLIO, SONG_SELECTION, STATION_PORTFOLIO, STATION_PORTFOLIO_GROUP } from 'utils/Constants';
import { songPortfolioFilterTransformer } from 'utils/songPortfolioFilterTransformer';
import { ExtendedTrending, Trending } from '.';

interface FilterTrendingAnalysisReport {
  changeFilter: { (filters?: Trending): any };
  isOpened: boolean;
}

const Filter: React.FC<FilterTrendingAnalysisReport> = ({ changeFilter, isOpened }) => {
  const pathname = getReportSpec(ReportBaseEnum.SongsRegionsMarketsTrendingAnalysis).stickyKey;
  const { savedFilters: filter, saveFilter } = useStickyFilter<ExtendedTrending>(pathname);
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleFilterChange = (event: FilterDataEvent<object>) => {
    const { value } = event;
    const filterValues = { ...filter } as ExtendedTrending;
    const filterEvent = { ...value } as ExtendedTrending;
    // is either a station portfolio or station portfolio group
    if (filterEvent && filterEvent.stationGroup === STATION_PORTFOLIO) {
      filterValues.StationPortfolioGroup = undefined;
      filterValues.stationGroup = STATION_PORTFOLIO;
    } else if (filterEvent && filterEvent.stationGroup === STATION_PORTFOLIO_GROUP) {
      filterValues.StationPortfolio = undefined;
      filterValues.stationGroup = STATION_PORTFOLIO_GROUP;
    }

    saveFilter({ ...filterValues, ...value } as ExtendedTrending);
  };

  const getSelectedSongsText = ({ value }: FilterDataEvent<unknown>) => {
    const songInfos = get(value, 'songInfos') as SongInfo[] | undefined;
    if (songInfos && songInfos.length) {
      return songInfos.length === 1 ? songInfos[0]?.Title : t('filter.label.various');
    }
    return '';
  };

  const componentGroupChangeHandler = (prop: string, { value }: FilterDataEvent<string>) => {
    if (value) {
      const v = set({}, prop, value);
      handleFilterChange({ value: v });
    }
  };

  const handleSongSectionFilterChange = ({ value }: FilterDataEvent<{ songInfos?: SongInfo[] }>) => {
    const songInfos = value?.songInfos;
    saveFilter(({ ...filter, songInfos, songSelectionSongInfos: songInfos } as unknown) as ExtendedTrending);
  };

  const handleSongPortfolioFilterChange = ({ value }: FilterDataEvent<SongPortfolioFilterData>) => {
    const songInfos = value?.songInfos;
    const SongPortfolio = value?.SongPortfolio;
    saveFilter(({
      ...filter,
      SongPortfolio,
      songInfos,
      songPortfolioSongInfos: songInfos,
    } as unknown) as ExtendedTrending);
  };

  const getStationSelectionLabel = () => {
    const selection = filter?.stationGroup ?? STATION_PORTFOLIO;
    return selection === STATION_PORTFOLIO
      ? t('filter.title.stationPortfolio')
      : t('filter.title.stationPortfolioGroup');
  };

  const getSongSelectionLabel = () => {
    const selection = filter?.songGroup ?? SONG_SELECTION;
    return selection === SONG_SELECTION ? t('filter.title.songs') : t('filter.title.songPortfolios');
  };

  return (
    <FilterBase
      onSubmit={() => changeFilter(songPortfolioFilterTransformer(filter as ExtendedTrending))}
      isOpened={isOpened}
    >
      <ComponentGroup
        title={getStationSelectionLabel()}
        onChange={v => componentGroupChangeHandler('stationGroup', v)}
        initialValue={filter?.stationGroup ?? STATION_PORTFOLIO}
      >
        <ComponentGroupItem
          label={t('filter.title.stationPortfolio')}
          getLabel={({ value }) => get(value, 'StationPortfolio.Name')}
          labelValue={STATION_PORTFOLIO}
        >
          <StationPortfolioSelect onChange={handleFilterChange} initialValue={filter?.StationPortfolio} />
        </ComponentGroupItem>
        <ComponentGroupItem
          label={t('filter.title.stationPortfolioGroup')}
          getLabel={({ value }) => get(value, 'StationPortfolioGroup.Name')}
          labelValue={STATION_PORTFOLIO_GROUP}
        >
          <StationPortfolioGroupSelect onChange={handleFilterChange} initialValue={filter?.StationPortfolioGroup} />
        </ComponentGroupItem>
      </ComponentGroup>
      <ComponentGroup
        title={getSongSelectionLabel()}
        onChange={v => componentGroupChangeHandler('songGroup', v)}
        initialValue={filter?.songGroup ?? SONG_SELECTION}
      >
        <ComponentGroupItem
          label={t('filter.title.selectedSongs')}
          getLabel={getSelectedSongsText}
          labelValue={SONG_SELECTION}
        >
          <SongSelection onChange={handleSongSectionFilterChange} initialValue={filter?.songSelectionSongInfos} />
        </ComponentGroupItem>
        <ComponentGroupItem
          label={t('filter.title.songPortfolios')}
          getLabel={({ value }) => get(value, 'SongPortfolio.Name')}
          labelValue={SONG_PORTFOLIO}
        >
          <SongPortfolioSelect onChange={handleSongPortfolioFilterChange} initialValue={filter?.SongPortfolio} />
        </ComponentGroupItem>
      </ComponentGroup>
      <FormatMultipleFilter onChange={handleFilterChange} apiPropertyName="Formats" values={filter?.Formats} />
      <ReportCycle onChange={handleFilterChange} initialValue={filter?.Cycle} />
      <SpinModeCycle onChange={handleFilterChange} initialValue={filter?.SpinMode} />
      <CheckboxFilter
        onChange={handleFilterChange}
        name={t('filter.title.IsPublishedStationsOnly')}
        valueToApi="IsPublishedStationsOnly"
        initialValue={filter?.IsPublishedStationsOnly || false}
      />
      <CheckboxFilter
        onChange={handleFilterChange}
        name={t('filter.title.showImpressions')}
        valueToApi="ShowImpressions"
        initialValue={filter?.ShowImpressions || false}
      />
    </FilterBase>
  );
};

export default Filter;
