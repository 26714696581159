import { AgGridColumn } from '@ag-grid-community/react';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import DayPartsCellRenderer from 'components/DetailGrid/CellRenderers/DayPartsCellRenderer';
import DualValueCellRenderer from 'components/DetailGrid/CellRenderers/DualValueCellRenderer/DualValueCellRenderer';
import DayPartsHeaderComponent from 'components/DetailGrid/HeaderComponents/DayPartsHeaderComponent';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import { formatDate } from 'utils/dates';
import { ReportModeIndexes } from './ReportModeSelect';
import {
  SevenDayArtistAnalysisDetailReport,
  TransformedSevenDayArtistAnalysisDetailReport,
} from './utils/SevenDayArtistAnalysisDetailReport';
import { SevenDayArtistAnalysisReport } from './utils/SevenDayArtistAnalysisReports';

interface DefaultSongDataGridReportProps {
  reportMode?: number;
  rowData?: SevenDayArtistAnalysisReport;
}

const url = `/seven-day-artist-analysis-detail-reports`;
const DefaultSongDataGridReport: FC<DefaultSongDataGridReportProps> = ({ rowData: filters, reportMode = 1 }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({ sortPath: 'Rank', sort: 'asc' });
  const { setSettings, settings, data: reportData, isLoading, isError } = useRemoteApi<
    ReportResult<TransformedSevenDayArtistAnalysisDetailReport>
  >(url, {
    method: 'POST',
    enabled: false,
    transformRequest: (data: SevenDayArtistAnalysisReport) => {
      return {
        ReportMode: reportMode,
        StationName: data.StationName,
        ArtistName: data.ArtistName,
      };
    },
    transformResponse: (data: ReportResult<SevenDayArtistAnalysisDetailReport>) => {
      const transform = (v: SevenDayArtistAnalysisDetailReport): TransformedSevenDayArtistAnalysisDetailReport => {
        return {
          ...v,
          SongInfo: {
            Title: v.Title,
            Year: v.ReleaseYear,
            Artist: v.Artist,
            Label: '',
          },
          ThisWeekImpressionSpin: v?.ImpressionsInfo?.SpinsThisWeek,
        };
      };
      return {
        ...data,
        Items: data.Items.map(v => transform(v)),
      };
    },
  });

  return (
    <>
      <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
        <ServerSideReportGrid setSettings={setSettings} settings={settings} filters={filters} sortModel={sortModel}>
          <AgGridColumn
            field="Rank"
            headerName={t('grid.header.Rank')}
            minWidth={60}
            maxWidth={60}
            wrapText
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="SongInfo"
            headerName={t(`grid.header.Title`)}
            minWidth={240}
            width={240}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              sortPath: 'Title',
              disposition: 'row',
              secondLine: [{ label: 'grid.header.ReleaseYear', sortPath: 'ReleaseYear' }],
            }}
            cellRendererFramework={DualValueCellRenderer}
            cellRendererParams={{ topValue: 'Title', bottomValue: 'Year' }}
          />
          <AgGridColumn
            field="FirstMonitoredDate"
            headerName={t(`grid.header.FirstDetect`)}
            minWidth={120}
            maxWidth={120}
            headerComponentParams={{
              sortModel,
              onSortChange,
              sortPath: 'FirstMonitoredDate',
            }}
            valueFormatter={({ value }) => formatDate(value)}
          />
          <AgGridColumn
            field="SpinsInfo"
            colId="SpinsInfoOnly"
            headerName={t('grid.header.Spins')}
            minWidth={80}
            maxWidth={80}
            wrapText
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              disposition: 'row',
              secondLine: [
                { sortPath: 'SpinsInfo/ThisWeek', label: t('grid.header.TW') },
                { sortPath: 'SpinsInfo/LastWeek', label: t('grid.header.LW') },
              ],
            }}
            cellRendererFramework={DualValueCellRenderer}
            cellRendererParams={{ topValue: 'ThisWeek', bottomValue: 'LastWeek' }}
            hide={reportMode !== ReportModeIndexes.SEVEN_DAY}
          />
          <AgGridColumn
            field="SpinsInfo.ThisWeek"
            headerName={t('grid.header.Spins')}
            minWidth={80}
            maxWidth={80}
            wrapText
            hide={![ReportModeIndexes.HISTORY, ReportModeIndexes.YEAR_TO_DATE].includes(reportMode)}
          />
          <AgGridColumn
            headerName={t('grid.header.DaypartsInfo')}
            field="DaypartsInfo"
            maxWidth={230}
            minWidth={230}
            headerComponentFramework={DayPartsHeaderComponent}
            headerComponentParams={{ sortModel, onSortChange }}
            cellRendererFramework={DayPartsCellRenderer}
          />
          <AgGridColumn
            headerName={t('grid.header.impression.ImpressionMill')}
            field="ThisWeekImpressionSpin"
            width={120}
            minWidth={120}
            headerComponentParams={{ sortModel, onSortChange }}
            valueFormatter={({ value }: ValueFormatterParams) => (value || 0.0).toFixed(3)}
          />
        </ServerSideReportGrid>
      </GridVisibilityWrapper>
    </>
  );
};

export default DefaultSongDataGridReport;
