/* eslint-disable react/jsx-wrap-multilines */
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import FilterDataProps from 'types/FilterDataProps';
import { useStyles } from './styles';

interface FilterData {
  value?: string | undefined;
}
interface Props extends FilterDataProps<FilterData> {
  name?: string;
  valueToApi?: string;
  isDisabled?: boolean;
  hide?: boolean;
}

const CheckBoxFilter: React.FC<Props> = ({
  name = 'check',
  valueToApi = 'value',
  onChange,
  isDisabled = false,
  hide = false,
  initialValue,
}) => {
  const [isChecked, setChecked] = useState(false);
  const classes = useStyles();

  const handleChange = (value: string) => {
    setChecked(!isChecked);
    onChange({ value: { [value]: !isChecked || false } });
  };

  useEffect(() => {
    setChecked(initialValue);
  }, [initialValue]);

  return !hide ? (
    <FormGroup row classes={{ root: classes.root }}>
      <FormControlLabel
        control={
          <Checkbox
            disabled={isDisabled}
            checked={isChecked}
            onChange={() => handleChange(valueToApi)}
            name={name}
            color="primary"
          />
        }
        label={name}
      />
    </FormGroup>
  ) : null;
};

export default CheckBoxFilter;
