import SortableColumnText from 'components/DetailGrid/SortableColumnText/SortableColumnText';
import React from 'react';
import { HeaderComponentParams } from 'types/HeaderComponentParams';

const DefaultHeaderComponent: React.FC<HeaderComponentParams> = props => {
  const { column, sortModel, sortPath, onSortChange } = props;
  const { field, headerName, sortable } = column.getColDef();
  return (
    <>
      <SortableColumnText
        currentSortModel={sortModel}
        sortPath={sortPath ?? field}
        onSortChange={onSortChange}
        sortable={sortable}
      >
        {headerName}
      </SortableColumnText>
    </>
  );
};

export default DefaultHeaderComponent;
