/* eslint-disable no-shadow */

export const STATION_BY_FORMATS = 'FormatInfo';
export const STATION_BY_MULTIPLE_FORMATS = 'Formats';
export const STATION_SELECTION = 'Stations';
export const STATION_PORTFOLIO = 'StationPortfolio';
export const STATION_PORTFOLIO_GROUP = 'StationPortfolioGroup';

export const SONG_SELECTION = 'SongSelection';
export const SONG_PORTFOLIO = 'SongPortfolio';

export const ImagesEnum = {
  CanadianLeafFlag: {
    image: 'canadian-leaf-small.gif',
    alt: 'Canada Flag',
  },
};
