import { get } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import InnerTable from '..';
import defaultTheme from '../../../themes';
import { IInnerTableGroup } from '../inner-table-group';

interface ThisLastMoveType {
  ThisWeek: number;
  LastWeek: number;
  Move: number;
}

export interface SpinsInfoGroupData {
  SpinsInfo?: ThisLastMoveType;
  FormatSpins?: ThisLastMoveType;
  MarketSpins?: ThisLastMoveType;
}

interface SpinsInfoGroupProps {
  propKey?: string;
  showMove?: boolean;
  data: SpinsInfoGroupData;
}

const SpinsInfoInnerTable: React.FC<SpinsInfoGroupProps> = ({ data, propKey = 'SpinsInfo', showMove = true }) => {
  const { t } = useTranslation();
  const getColor = (baseValue: number, actualValue: number) => {
    if (baseValue > actualValue) return defaultTheme.palette.error.main;
    if (baseValue < actualValue) return defaultTheme.palette.success.main;
    return defaultTheme.palette.text.primary;
  };

  const getValue = () => {
    return get(data, propKey);
  };

  const createSpan = (baseValue: number, actualValue: number) => {
    return <span style={{ color: getColor(baseValue, actualValue) }}>{actualValue}</span>;
  };

  const filterChildren = <T extends Array<unknown>>(arr: T): T => {
    if (!showMove) {
      return arr.splice(0, 2) as T;
    }
    return arr;
  };

  const innerTableData: IInnerTableGroup[] = [
    {
      title: t(`grid.header.Spins`),
      children: [
        {
          title: '',
          children: filterChildren(['TW', 'LW', '+/-']),
        },
        {
          title: '',
          children: filterChildren([
            createSpan(getValue().LastWeek, getValue().ThisWeek),
            createSpan(getValue().ThisWeek, getValue().LastWeek),
            createSpan(0, getValue().Move),
          ]),
        },
      ],
    },
  ];

  return <InnerTable key={v4()} data={innerTableData} />;
};

export default SpinsInfoInnerTable;
