import { AgGridColumn } from '@ag-grid-community/react';
import { GridOptions } from '@ag-grid-enterprise/all-modules';
import DetailGrid from 'components/DetailGrid';
import MultiColumnsCellRenderer from 'components/DetailGrid/CellRenderers/MultiColumnsCellRenderer/MultiColumnsCellRenderer';
import SpinDifferentialCellRenderer from 'components/DetailGrid/CellRenderers/SpinDifferentialCellRenderer';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SongComparisonReportFilter } from './Filter';
import { filterRequestTransformer } from './utils/filterRequestTransformer';
import { formatSummaryResponseTransformer, SummarizedReportResult } from './utils/responseTransformers';
import { SummaryItems } from './utils/SummaryItem';

export interface OverallGridProps {
  filters?: SongComparisonReportFilter;
}

const TopOptions: GridOptions = {
  alignedGrids: [],
  defaultColDef: {
    editable: true,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth: 100,
  },
  suppressHorizontalScroll: true,
};
const BottomOptions: GridOptions = {
  alignedGrids: [],
  defaultColDef: {
    editable: true,
    sortable: true,
    resizable: true,
    filter: true,
    flex: 1,
    minWidth: 100,
  },
};
((TopOptions as GridOptions).alignedGrids ?? []).push(BottomOptions);
((BottomOptions as GridOptions).alignedGrids ?? []).push(TopOptions);

/* eslint-disable @typescript-eslint/no-explicit-any */
const songImpressionsFormatter = (rowData: any, reportQuery: any, value: any) => {
  return `${(value ?? 0).toFixed(3)}`;
};

const url = `/seven-day-song-comparison-chart-reports`;

const FormatSummaryGrid: React.FC<OverallGridProps> = ({ filters }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({ sortPath: 'SpinDifferential', sort: 'desc' });
  const { data: reportData, isLoading, setSettings, settings, isError } = useRemoteApi<
    SummarizedReportResult<SummaryItems>
  >(url, {
    method: 'POST',
    enabled: false,
    transformResponse: formatSummaryResponseTransformer,
    transformRequest: filterRequestTransformer,
  });

  return (
    <>
      <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
        <ServerSideReportGrid
          setSettings={setSettings}
          settings={settings}
          filters={filters}
          sortModel={sortModel}
          detailProps={{
            gridOptions: TopOptions,
            domLayout: 'autoHeight',
            containerStyle: {
              height: '100%',
            },
          }}
        >
          <AgGridColumn
            field="FormatName"
            headerName={t('grid.header.FormatName')}
            minWidth={100}
            headerComponentParams={{
              sortModel,
              onSortChange,
              sortPath: 'FormatName',
            }}
          />

          <AgGridColumn
            field="FirstSongInfo"
            headerName={`${filters?.FirstSongInfo?.Title} - ${filters?.FirstSongInfo?.Artist}`}
            minWidth={200}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              secondLine: [
                { sortPath: 'FirstSongSpinsInfo/ThisWeek', label: 'grid.header.TW' },
                { sortPath: 'FirstSongSpinsInfo/LastWeek', label: 'grid.header.LW' },
                { sortPath: 'FirstSongSpinsInfo/Move', label: 'grid.header.Move' },
                { sortPath: 'FirstSongImpressionsInfo/SpinsThisWeek', label: 'grid.header.ImpressionsPerMill' },
              ],
            }}
            cellRendererFramework={MultiColumnsCellRenderer}
            cellRendererParams={{
              fields: [
                'FirstSongSpinsInfo.ThisWeek',
                'FirstSongSpinsInfo.LastWeek',
                'FirstSongSpinsInfo.Move',
                'FirstSongImpressionsInfo.SpinsThisWeek',
              ],
              fieldCellRenderers: [
                {
                  field: 'FirstSongImpressionsInfo.SpinsThisWeek',
                  fieldCellFormatter: songImpressionsFormatter,
                },
              ],
            }}
          />

          <AgGridColumn
            field="SpinDifferential"
            headerName={t('grid.header.SpinDifferential')}
            minWidth={180}
            maxWidth={180}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              disposition: 'row',
              secondLine: [
                { sortPath: 'LeftSpinDifferential', label: 'grid.header.SpinsDifferential.FirstSong' },
                { sortPath: 'SpinDifferential', label: 'grid.header.Spins' },
                { sortPath: 'RightSpinDifferential', label: 'grid.header.SpinsDifferential.SecondSong' },
              ],
            }}
            cellRendererFramework={SpinDifferentialCellRenderer}
          />

          <AgGridColumn
            field="SecondSongInfo"
            headerName={`${filters?.SecondSongInfo?.Title} - ${filters?.SecondSongInfo?.Artist}`}
            minWidth={200}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              secondLine: [
                { sortPath: 'SecondSongSpinsInfo/ThisWeek', label: 'grid.header.TW' },
                { sortPath: 'SecondSongSpinsInfo/LastWeek', label: 'grid.header.LW' },
                { sortPath: 'SecondSongSpinsInfo/Move', label: 'grid.header.Move' },
                { sortPath: 'SecondSongImpressionsInfo/SpinsThisWeek', label: 'grid.header.ImpressionsPerMill' },
              ],
            }}
            cellRendererFramework={MultiColumnsCellRenderer}
            cellRendererParams={{
              fields: [
                'SecondSongSpinsInfo.ThisWeek',
                'SecondSongSpinsInfo.LastWeek',
                'SecondSongSpinsInfo.Move',
                'SecondSongImpressionsInfo.SpinsThisWeek',
              ],
              fieldCellRenderers: [
                {
                  field: 'SecondSongImpressionsInfo.SpinsThisWeek',
                  fieldCellFormatter: songImpressionsFormatter,
                },
              ],
            }}
          />
        </ServerSideReportGrid>

        <DetailGrid
          reportData={[reportData?.FormatDataTotals] ?? []}
          headerHeight={0}
          gridOptions={BottomOptions}
          domLayout="autoHeight"
          rowStyle={{ fontWeight: 'bold' }}
          containerStyle={{
            height: '100%',
          }}
        >
          <AgGridColumn field="0" minWidth={100} valueGetter={() => ``} />

          <AgGridColumn
            field="FirstSongInfo"
            minWidth={200}
            cellRendererFramework={MultiColumnsCellRenderer}
            cellRendererParams={{
              fields: [
                'FirstSongSpinsInfo.ThisWeek',
                'FirstSongSpinsInfo.LastWeek',
                'FirstSongSpinsInfo.Move',
                'FirstSongImpressionsInfo.SpinsThisWeek',
              ],
              fieldCellRenderers: [
                {
                  field: 'FirstSongImpressionsInfo.SpinsThisWeek',
                  fieldCellFormatter: songImpressionsFormatter,
                },
              ],
            }}
          />

          <AgGridColumn
            field="SpinDifferential"
            minWidth={180}
            maxWidth={180}
            cellRendererFramework={SpinDifferentialCellRenderer}
            cellRendererParams={{ showArrows: false }}
          />

          <AgGridColumn
            field="SecondSongInfo"
            minWidth={200}
            cellRendererFramework={MultiColumnsCellRenderer}
            cellRendererParams={{
              fields: [
                'SecondSongSpinsInfo.ThisWeek',
                'SecondSongSpinsInfo.LastWeek',
                'SecondSongSpinsInfo.Move',
                'SecondSongImpressionsInfo.SpinsThisWeek',
              ],
              fieldCellRenderers: [
                {
                  field: 'SecondSongImpressionsInfo.SpinsThisWeek',
                  fieldCellFormatter: songImpressionsFormatter,
                },
              ],
            }}
          />
        </DetailGrid>
      </GridVisibilityWrapper>
    </>
  );
};

export default FormatSummaryGrid;
