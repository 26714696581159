import { Grid, createStyles, makeStyles, Theme } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Grid)`
  display: flex;
  width: 100%;
  max-width: 100%;
  justify-content: left;
  align-items: center;
`;
export const FormatLabel = styled.div`
  color: rgba(0, 0, 0, 0.54);
  font-size: 10px;
  margin-right: 5px;
`;

export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    Input: {
      textAlign: 'right',
      fontSize: '0.8em',
      '& p': {
        fontSize: '0.8em',
      },
    },
    icon: {
      color: theme.palette.primary.main,
    },
  });
});
