import { Theme, createStyles, withTheme } from '@material-ui/core/styles';
import { Grid, Typography, Button, makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const FooterContainer = styled(Grid)`
  display: flex;
  flex: 1;
  height: 60px;
  justify-content: flex-end;
  align-items: flex-end;
`;
export const FooterButton = styled(Button)`
  background-color: transparent;
`;
export const FooterText = withTheme(styled(Typography)`
  color: ${props => props.theme.palette.primary.main};
  font-weight: normal;
`);
export const ListStationContainer = styled(Grid)`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
export const ListStationHeader = styled(Grid)`
  display: flex;
  flex: 1;
  flex-direction: row;
`;
export const SelectedStationRow = styled(Grid)`
  display: flex;
  flex: 1;
  padding: 3px;
  width: 100%;
  flex-direction: row;
  border-bottom: 1px solid #d5d5d5;
  margin-bottom: 3px;
`;
export const SelectedStationText = styled(Grid)`
  display: flex;
  flex: 4;
  flex-direction: column;
`;
export const ListSelectedStationContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 170px;
  margin-bottom: 10px;
  overflow-y: scroll;
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableContainer: {
      marginTop: 10,
      maxHeight: 325,
    },
    table: {
      minWidth: '100%',
    },
    primaryText: {
      color: theme.palette.primary.main,
      fontSize: '12px',
    },
    labelText: {
      fontSize: '10px',
    },
    valueText: {
      fontSize: '12px',
    },
    link: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      fontSize: '12px',
    },
    icon: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      right: 12,
      userSelect: 'none',
      pointerEvents: 'none',
    },
  }),
);
