import { Column, ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import { Box, Typography } from '@material-ui/core';
import React, { ReactElement } from 'react';
import { useStyles } from './styles';

type availableColors = 'error' | 'textPrimary' | 'textSecondary' | 'success';

export interface DualValueCellRendererProps<T extends {}> {
  value?: T;
  topValue: keyof T;
  bottomValue: keyof T;
  biggerIsBetter?: boolean;
  goodColor?: availableColors;
  badColor?: availableColors;
  column?: Column;
}

const DualValueCellRenderer = <T extends {}>({
  value,
  topValue,
  bottomValue,
  biggerIsBetter = true,
  goodColor = 'success',
  badColor = 'error',
  column,
}: DualValueCellRendererProps<T>): ReactElement => {
  const classes = useStyles();

  if (!column || !value) return <></>;

  const getValue = (key: keyof T) => {
    const colDef = column.getColDef();
    const { valueFormatter } = colDef;

    if (valueFormatter instanceof Function) {
      return valueFormatter({ ...colDef, value: value[key] } as ValueFormatterParams);
    }
    return value[key];
  };

  const getColor = (base: keyof T, comparison: keyof T): availableColors => {
    if (value[base] > value[comparison]) {
      return biggerIsBetter ? goodColor : badColor;
    }
    if (value[base] < value[comparison]) {
      return biggerIsBetter ? badColor : goodColor;
    }

    return 'textPrimary';
  };

  return (
    <>
      <Box className={classes.root}>
        <Typography variant="body1" className={getColor(topValue, bottomValue)}>
          {getValue(topValue)}
        </Typography>
        <Typography variant="body1">{getValue(bottomValue)}</Typography>
      </Box>
    </>
  );
};

export default DualValueCellRenderer;
