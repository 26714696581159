import { ArtistInfoNormalize, RankNormalize } from 'utils/reports/normalizeTransform';
import { StationPlaylistBuildingDataItem, StationPlaylistSevenDayDataItem } from './StationPlaylistItem';
import { 
  StationPlaylistBuildingReport,
  StationPlaylistSevenDayReport, 
  TransformedStationPlaylistBuildingReport, 
  TransformedStationPlaylistSevenDayReport,
} 
from './StationPlaylistReport';
import { formatDate, formatDateTypes } from "utils/dates";

/* eslint-disable @typescript-eslint/no-explicit-any */
const transformSevenDayItems = (row: StationPlaylistSevenDayDataItem): any => {
  return {
    ...row,
    SongInfo: ArtistInfoNormalize(row),
    Rank: RankNormalize({ ThisWeek: row.RankThisWeek, LastWeek: row.RankLastWeek }),
    MarketInfo: {
      Share: row.MarketSpinsShare,
      MarketSpinsThisWeek: row.MarketSpinsThisWeek,
    },
    HistoricalInfo: {
      FirstPlayDate: row.FirstPlayDate,
      HistoricalSpins: row.HistoricalSpins,
    },
    FormatComparisonInfo: {
      ChartRankThisWeek: row.ChartRankThisWeek,
      ChartRankLastWeek: row.ChartRankLastWeek,
      AveragePlays: row.AveragePlays,
      Deviations: row.Deviations,
    },
    ImpressionCustomInfo: {
      ReachMill: row.ImpressionsInfo?.SpinsThisWeek,
      ImpRank: row.ImpressionsInfo?.RankThisWeek,
    },
  };
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const transformBuildingItems = (row: StationPlaylistBuildingDataItem): any => {
  return {
    ...row,
    SongInfo: ArtistInfoNormalize(row),
    Rank: RankNormalize({ ThisWeek: row.RankThisWeek, LastWeek: row.RankLastWeek }),
    HistoricalInfo: {
      FirstPlayedDate: formatDate(row.FirstPlayedDate || '', formatDateTypes.MMddyyyy),
      SpinsToDate: row.SpinsToDate,
    },
  };
};

export const StationPlaylistsSevenDayTransformer = 
  (reportResponse: StationPlaylistSevenDayReport): TransformedStationPlaylistSevenDayReport => 
{
  const result = {
    ...reportResponse,
    Items: reportResponse.Items.map(transformSevenDayItems)
  };
  return result;
};

export const StationPlaylistsBuildingTransformer = 
  (reportResponse: StationPlaylistBuildingReport): TransformedStationPlaylistBuildingReport => 
{
  const result = {
    ...reportResponse,
    Items: reportResponse.Items.map(transformBuildingItems)
  };
  return result;
};