import { Button, Typography } from '@material-ui/core';
import React from 'react';
import { Station } from 'types/Station';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';
import { getStationDisplayName } from 'utils/reports/GetStationDisplayName';
import { ListSelectedStationContainer, SelectedStationRow, SelectedStationText, useStyles } from './styles';

interface ListSelectedStationProps {
  selectedStations: Station[];
  onRemove: { (station: Station): void };
}

const ListSelectedStation: React.FC<ListSelectedStationProps> = ({ selectedStations, onRemove }) => {
  const classes = useStyles();

  return (
    <ListSelectedStationContainer>
      {selectedStations.map(station => (
        <SelectedStationRow key={uuidv4()}>
          <SelectedStationText>
            <Typography className={classes.primaryText}>{getStationDisplayName(station)}</Typography>
            <Typography className={classes.labelText}>{station?.MarketInfo?.Name}</Typography>
          </SelectedStationText>
          <Button onClick={() => onRemove(station)}>
            <FontAwesomeIcon className={classes.icon} icon={faTimes} />
          </Button>
        </SelectedStationRow>
      ))}
    </ListSelectedStationContainer>
  );
};

export default ListSelectedStation;
