import ReportResult from 'services/ReportResult';
import { SongInfo } from 'types/SongInfo';
import { formatDate } from 'utils/dates';

interface SongRanking {
  SongInfo: SongInfo;
  ChartWeek: string;
}

export interface SongRankingResponse {
  Count: number;
  Items: Items[];
}
export interface Items extends SongRanking {
  Artist: string;
  Title: string;
  Label: string;
  SongCode: string;
  StartDate: string;
  EndDate: string;
}

export const SongRankingChartTransformer = (response: SongRankingResponse): ReportResult<Items> => {
  const transformItems = (row: Items): Items => {
    return {
      ...row,
      ChartWeek: `${formatDate(row.StartDate)} - ${formatDate(row.EndDate)}`,
      SongInfo: {
        Artist: row.Artist,
        Label: row.Label,
        Title: row.Title,
      },
    };
  };
  return {
    ...response,
    Items: response.Items.map(transformItems),
  };
};
