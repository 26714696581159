import { sumBy } from 'lodash';
import { SevenDayFormatHistoryResponse, SevenDayFormatHistoryResponseTransformed } from './FormatHistoryTypes';

export const FormatHistoryTransformerResponse = (
  sevenDayHistorySongResponse: SevenDayFormatHistoryResponse,
): SevenDayFormatHistoryResponseTransformed => {
  return {
    ...sevenDayHistorySongResponse,
    Items: sevenDayHistorySongResponse.Items,
    TotalItems: {
      ...{
        FormatName: 'All Formats Combined',
        SpinsToDate: sumBy(sevenDayHistorySongResponse.Items, 'SpinsToDate'),
      },
    },
  };
};
