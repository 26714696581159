import { Grid, Typography, Button, createStyles, makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 2px solid #d3d3d3;
`;

export const AdsImage = styled.img`
  width: 100%;
`;

export const AdsContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px;
  background-color: white;
`;

export const AdsFooter = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 2px 5px;
  width: 100%;
`;

export const PdfButton = styled(Button)`
  border: none;
`;

export const PdfLink = styled(Typography)``;

export const useStyles = makeStyles(() =>
  createStyles({
    pdfLink: {
      fontSize: '12px',
      fontWeight: 600,
      cursor: 'pointer',
      marginLeft: '5px',
    },
  }),
);
