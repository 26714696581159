import AppSettings from 'contexts/AppSettingsContext';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { ImagesEnum } from 'utils/Constants';

const Container = styled.div`
  width: 100%;
  line-height: '18px';
`;

interface BooleanCanadaCheckMarkCellRendererProps {
  value?: boolean;
}

const BooleanCanadaCheckMarkCellRenderer: React.FC<BooleanCanadaCheckMarkCellRendererProps> = ({
  value,
}: BooleanCanadaCheckMarkCellRendererProps) => {
  const { imagesURL } = useContext(AppSettings);

  const getIcon = () => {
    if (value) {
      const srcURL = imagesURL + ImagesEnum.CanadianLeafFlag.image;
      return <img src={srcURL} alt={ImagesEnum.CanadianLeafFlag.alt} />;
    }
    return '';
  };

  return <Container>{getIcon()}</Container>;
};

BooleanCanadaCheckMarkCellRenderer.defaultProps = { value: false };
export default BooleanCanadaCheckMarkCellRenderer;
