import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import styled from 'styled-components';
import { PeakInfo } from 'types/PeakInfo';
import { formatDate } from 'utils/dates';
import InnerTable from '..';
import { IInnerTableGroup } from '../inner-table-group';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

export interface PeakInfoData {
  PeakInfo?: PeakInfo;
}

interface PeakInfoProps {
  data: PeakInfoData;
  name?: string | null;
}

const PeakInfoInnerTable: React.FC<PeakInfoProps> = ({ data, name }: PeakInfoProps) => {
  const { t } = useTranslation();
  const createSpan = (item: string) => {
    // eslint-disable-next-line react/jsx-one-expression-per-line
    return <Container key={v4()}>{item} </Container>;
  };
  const innerTableData: IInnerTableGroup[] = [
    {
      title: name ? t(name) : '',
      children: [
        {
          title: '',
          children: [
            t('grid.header.PeakInfo.SpinsDate'),
            t('grid.header.PeakInfo.Spins'),
            t('grid.header.PeakInfo.Chart'),
            t('grid.header.PeakInfo.ChartDate'),
          ],
        },
        {
          title: '',
          children: [
            createSpan(formatDate(data.PeakInfo?.PeakSpinsDate ?? '')),
            createSpan(data.PeakInfo?.PeakSpins.toString() ?? ' - '),
            createSpan(data.PeakInfo?.PeakChart.toString() ?? ' - '),
            createSpan(formatDate(data.PeakInfo?.PeakChartDate ?? '')),
          ],
        },
      ],
    },
  ];

  return <InnerTable key={v4()} data={innerTableData} />;
};

PeakInfoInnerTable.defaultProps = {
  name: null,
};

export default PeakInfoInnerTable;
