import styled from 'styled-components';

interface PropsItemGrid {
  width?: string;
  display?: string;
}
interface PropsContainerGrid {
  width?: string;
}

export const ItemGrid = styled.span<PropsItemGrid>`
  width: ${props => (props.width ? props.width : '35px')};
  display: ${props => (props.display ? props.display : 'inline-block')};
`;
export const ContainerGrid = styled.div<PropsContainerGrid>`
  width: ${props => (props.width ? props.width : '100%')};
`;
