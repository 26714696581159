import { useAppApi } from "services/api";
import { SongSearchType } from "types/Enums";
import { SongInfo } from "types/SongInfo";
import SongSearchRequest from "types/SongSearchRequest";
import { useQuery, QueryClient, UseQueryResult } from "react-query";
import { AxiosError } from "axios";
import AppProblem from "types/AppProblem";

const useSearchByTitle = (searchTerm:string, grcFilter:string, chartMode:string, minSpins?: number):UseQueryResult<Array<SongInfo>, AxiosError<AppProblem>> =>
{
    const queryClient = new QueryClient();
    const url = `/songs`;

    const request:SongSearchRequest = {
        searchType: SongSearchType.Title,
        searchTerm: searchTerm,
        grcFilter: grcFilter,
        chartMode: chartMode,
        minSpins: minSpins ?? 0
    };

    const api = useAppApi();
    const cacheKey = 'songs-by-title';
    return useQuery<Array<SongInfo>, AxiosError<AppProblem>>(cacheKey, () => 
    {
        return api.request({
            url: url,
            method: "get",
            data: request
        })
        .then((resp)=>
        { 
            queryClient.invalidateQueries(cacheKey);
            return resp.data;
        });
    });
};    


const useSearchByArtist = (searchTerm:string, grcFilter:string, chartMode:string, minSpins?: number):UseQueryResult<Array<SongInfo>, AxiosError<AppProblem>> =>
{
    const queryClient = new QueryClient();
    const url = `/songs`;

    const request:SongSearchRequest = {
        searchType: SongSearchType.Artist,
        searchTerm: searchTerm,
        grcFilter: grcFilter,
        chartMode: chartMode,
        minSpins: minSpins ?? 0
    };

    const api = useAppApi();
    const cacheKey = 'songs-by-artist';
    return useQuery<Array<SongInfo>, AxiosError<AppProblem>>(cacheKey, () => 
    {
        return api.request({
            url: url,
            method: "get",
            data: request
        })
        .then((resp)=>
        { 
            queryClient.invalidateQueries(cacheKey);
            return resp.data;
        });
    });
};

const useSearchBySongCodes = (songCodes:string[]):UseQueryResult<Array<SongInfo>, AxiosError<AppProblem>> =>
{
    const queryClient = new QueryClient();
    const url = `/songs/search-by-song-codes`;
    const cacheKey = 'songs-by-song-codes';
    const searchBySongCodesRequest = { SongCodes: songCodes };
    
    const api = useAppApi();
    return useQuery<Array<SongInfo>, AxiosError<AppProblem>>(cacheKey, () => 
    {
        return api.request({
            url: url,
            method: "post",
            data: {...searchBySongCodesRequest }
        })
        .then((resp)=>
        { 
            queryClient.invalidateQueries(cacheKey);
            return resp.data;
        });
    });
};

export { useSearchByArtist, useSearchByTitle, useSearchBySongCodes}