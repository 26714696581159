import React from 'react';
import { useTranslation } from 'react-i18next';
import { WithoutOvernight } from 'types/WithoutOvernight';
import { v4 } from 'uuid';
import InnerTable from '..';
import { IInnerTableGroup } from '../inner-table-group';

export interface WithoutOvernightData {
  WithoutOvernight?: WithoutOvernight;
}

interface WithoutOvernightProps {
  data: WithoutOvernightData;
  fieldName: string;
  name: string;
}

const WithoutOvernightInnerTable: React.FC<WithoutOvernightProps> = ({
  data,
  fieldName,
  name,
}: WithoutOvernightProps) => {
  const { t } = useTranslation();
  const createSpan = (item: number) => {
    // eslint-disable-next-line react/jsx-one-expression-per-line
    return <span>{item} </span>;
  };
  const innerTableData: IInnerTableGroup[] = [
    {
      title: t(name),
      children: [
        {
          title: '',
          children: [t('grid.header.Rank'), t('grid.header.TW')],
        },
        {
          title: '',
          children: [
            createSpan((data[fieldName as keyof WithoutOvernightData] as WithoutOvernight).Rank),
            createSpan((data[fieldName as keyof WithoutOvernightData] as WithoutOvernight).SpinsThisWeek),
          ],
        },
      ],
    },
  ];

  return <InnerTable key={v4()} data={innerTableData} />;
};

export default WithoutOvernightInnerTable;
