import { GrcInfo } from 'types/GrcInfo';
import { PanelInfo } from 'types/Panel';
import { ShowTopInfoType } from 'types/ShowTopInfoType';
import { Station } from 'types/Station';

export interface StationPlaylistsFilter {
  ReportCycle?: number;
  PanelInfo?: PanelInfo;
  StationSelectionType?: number;
  StationSelectionInfo?: Station;
  StationPortfolio?: string;
  StationPortfolioGroup?: string;
  GrcInfo?: GrcInfo;
  TopX?: ShowTopInfoType;
  NumDays?: number;
  StationInfo?: Station;
  callLetters?: string;
  songCode?: string;
}
// eslint-disable-next-line no-shadow
export enum StationTypeEnum {
  StationPortfolioSelected = 1,
  StationSelected,
  StationPortfolioGroupSelected,
}

export interface ExtendedStationPlaylistsFilter extends StationPlaylistsFilter {
  StationSelectionType?: StationTypeEnum;
  Stations?: Array<unknown>;
  stationGroup?: string;
}