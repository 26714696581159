import {
  Button,
  Collapse,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { debounce } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SongInfo } from 'types/SongInfo';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import { useStyles } from './styles';

interface SearchTableProps {
  searchedSongs: SongInfo[];
  multiSelect?: boolean;
  addSong?: (song: SongInfo) => void;
}

const SearchTable: React.FC<SearchTableProps> = ({ searchedSongs, multiSelect = true, addSong }) => {
  const classes = useStyles();

  const [selectedSong, setSelectedSong] = useState<SongInfo | undefined>();
  const { t } = useTranslation();

  const onClick = (song: SongInfo) => {
    if (addSong) addSong(song);
  };

  const onRadioChange = (ev: React.ChangeEvent<{ value: unknown }>) => {
    const s = ev.target.value as string;
    const song = searchedSongs.find(ss => `${ss?.Artist} - ${ss?.Title}` === s);
    debounce(() => {
      // this improves overall responsiveness for the radio component
      setSelectedSong(song);
      if (song && addSong) addSong(song);
    }, 15)();
  };

  const renderRow = ({ index, style }: ListChildComponentProps) => {
    const song = searchedSongs[index];
    return (
      <TableRow key={song.Id} style={style} component="div">
        <TableCell component="div" />
        <TableCell className={classes.fullWidth} component="div">
          <Typography variant="body1">{song.Title}</Typography>
          <Typography variant="body2">{`${song.Artist}, ${song.Label}`}</Typography>
        </TableCell>
        <TableCell component="div">
          <Typography variant="body2">{song.Year}</Typography>
        </TableCell>
        <TableCell component="div">
          {multiSelect && (
            <Button onClick={() => onClick(song)} color="primary">
              {t('filter.title.add')}
            </Button>
          )}
          {!multiSelect && <Radio value={`${song?.Artist} - ${song?.Title}`} />}
        </TableCell>
      </TableRow>
    );
  };

  const selectHeight = searchedSongs.length < 4 ? searchedSongs.length * 52 : 180;

  return (
    <Collapse in={searchedSongs && searchedSongs.length > 0} unmountOnExit>
      <RadioGroup value={`${selectedSong?.Artist} - ${selectedSong?.Title}`} onChange={onRadioChange}>
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell />
                <TableCell className={classes.tableHeaderMainColumn}>
                  <Typography variant="body1">{t('grid.header.Title')}</Typography>
                  <Typography variant="body2">{`${t('grid.header.Artist')},${t('grid.header.Label')}`}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{t('grid.header.yearBody')}</Typography>
                </TableCell>
                <TableCell className={classes.tableHeaderLeftSpacing} />
              </TableRow>
            </TableHead>
          </Table>
          <Table component="div">
            <TableBody component="div" className={classes.tableBody}>
              <List height={selectHeight} width="100%" itemSize={52} itemCount={searchedSongs.length}>
                {renderRow}
              </List>
            </TableBody>
          </Table>
        </TableContainer>
      </RadioGroup>
    </Collapse>
  );
};

export default SearchTable;
