// eslint-disable-next-line no-shadow
export enum ReportTypeEnum {
  EmptyReport = 0,
  DailyLogs = 1,
  CurrentMusicChart = 4,
  CurrentMonitors = 3,
  CurrentChart = 2,
  CurrentSong = 5,
  CurrentSongOneDay = 6,
  // seven day station
  StationPlaylists = 7,
  ArtistByStation = 8,
  StationPortfolios = 9,
  SpinsPerWeekAnalysis = 10,
  GrcAnalysis = 11,
  GrcAnalysisWithDaypart = 111,
  SpecialProgramming = 54,
  FormatSaturationCrossover = 12,
  StationSpinGridReport = 86,
  // seven day chart
  SevenDayPublishedChart = 14,
  SevenDayRollingChart = 16,
  SevenDayBuildingChart = 15,
  SevenDayAudienceChart = 17,
  SevenDayPowerPlaylistChart = 13,
  ArtistChart = 18,
  MarketChart = 19,
  GroupOwnerChart = 20,
  MultipleFormatChart = 21,
  IndieChart = 22,
  BigPicture = 77,
  ActivatorChart = 78,
  // seven day song
  SongAnalysis = 23,
  GreatestGainers = 24,
  SongPortfolio = 25,
  ArtistAnalysis = 26,
  HeadToHeadComparison = 27,
  // year to date
  YearToDateSongsByStation = 28,
  YearToDateArtistByStation = 29,
  YearToDateSongsByFormat = 30,
  YearToDateArtistByFormat = 31,
  YearToDateSongAnalysis = 32,
  // archived
  ArchivedWeekly = 33,
  ArchivedYearEnd = 34,
  ArchivedYearToDateHoliday = 35,
  ArchivedSevenDayHoliday = 76,
  PeakSongsOverall = 36,
  ArchivedYearEndSongsByStation = 79,
  ArchivedYearEndArtistsByStation = 80,
  ArchivedYearEndSongAnalysis = 81,
  ArchivedYearEndSongsByFormat = 82,
  ArchivedYearEndArtistsByFormat = 83,
  // shazam
  ShazamToUsa = 37,
  ShazamUsaMarkets = 38,
  ShazamInternational = 39,
  // custom
  CustomSongArtistCharts = 40,
  CustomPeerComparison = 41,
  CustomStationHeadToHead = 42,
  CustomGrcAnalysis = 43,
  CustomDateRangeStationPlaylist = 84,
  CustomDateRangeSongAnalysis = 85,
  CustomSharedPlaylist = 45,
  StreamingComparisonReport = 52,
  DmcaReport = 53,
  // video for usa, canada and latin
  VideoByNetwork = 46,
  // mis
  AddBoard = 109,
  ReportByFormat = 47,
  ReportBySong = 48,
  ReportByStation = 49,
  EmailNotificationReports = 61,
  ProjectedCharts = 62,
  // buzzangle
  BuzzAngleMarket = 90,
  BuzzAngleDaily = 51,
  BuzzAngleWeekly = 55,
  // retail reports
  HitsBuildingAlbumChart = 56,
  SalesPlusStreaming = 57,
  BuildingAlbum = 58,
  StreamingSongs = 59,
  StreetPulse = 60,
  // add board
  AddBoardAddsByStation = 63,
  AddBoardAddsBySong = 64,
  AddBoardAddsByFormat = 65,
  AddBoardAddsByTime = 66,
  AddBoardCustomReport = 67,
  AddBoardUserProfile = 68,
  AddBoardFormatCloseTimes = 69,
  AddBoardSongHistory = 70,
  AddBoardStationHistory = 71,
  AddBoardStationCallTimes = 72,
  // tuned in monitors
  TunedInMonitors = 73,
  TunedInMonitorDetail = 1073,
  // stations
  StationsMonitored = 74,
  // satellite video
  SatelliteVideo = 75,
  NationalCallout = 89,
  // regions markets
  RegionsMarketsSongTracking = 50,
  RegionsMarketsSideBySide = 87,
  RegionsMarketsTrendingAnalysis = 88,
  RegionsMarketsTrendingAnalysisByStation = 91,
  ActivatorChartReport = 78,
}
