import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
`;

export const ReportContainer = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
`;

export const GridContainer = styled(Grid)`
  width: 100%;
`;
