import React, { useState } from 'react';
import FilterDataEvent from 'types/FilterDataEvent';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import { UseApiSettings } from 'hooks/useApi/ApiTypes';
import ReportResult from 'services/ReportResult';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import { Divider } from '@material-ui/core';
import SelectInput from 'components/SelectInput';
import { ContainerGrid } from 'components/DetailGrid/CellRenderers/globalStylesCellRenderers';
import { SevenDaySongsUrl } from 'enums/urls';
import SummaryReport from './SummaryReport';
import DetailsReport from './DetailsReport';
import { ExtendedSongTrackingReportFilter, transformSongTrackingFilters } from './utils/SongTrackingReportTransform';
import { SongTrackinResult } from './types';

export interface SongTrackingProps {
  filters: FilterDataEvent<ExtendedSongTrackingReportFilter> | null;
}

export interface SongTrackingPropsGrid {
  setSettings: React.Dispatch<UseApiSettings<ReportResult<SongTrackinResult>>>;
  settings: UseApiSettings<ReportResult<SongTrackinResult>>;
  filters: SongTrackingProps['filters'];
}

const optionGrid = [
  {
    name: 'report.tab.songSummary',
    value: 'songSummary',
  },
  {
    name: 'report.tab.details',
    value: 'details',
  },
];

const url = SevenDaySongsUrl.regionsMarketsSongTrackingReports;

const SongTrackingReport: React.FC<SongTrackingProps> = ({ filters }) => {
  const [panel, setPanel] = useState('songSummary');
  const { setSettings, settings, data: reportData, isLoading, isError } = useRemoteApi<ReportResult<SongTrackinResult>>(
    url,
    {
      params: { $top: 1000, $skip: 0, $inlinecount: 'allpages', $orderBy: 'ReportName asc' },
      data: filters,
      method: 'POST',
      enabled: false,
      transformRequest: transformSongTrackingFilters,
    },
  );

  const changePanel = (typePanel: string) => {
    const isOpen = typePanel === panel ? '' : typePanel;
    return setPanel(isOpen);
  };

  return (
    <>
      <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
        <SelectInput
          optionValue="value"
          optionLabel="name"
          options={optionGrid}
          value={panel}
          onChangeValue={changePanel}
        />
        <Divider />
        <ContainerGrid>
          {panel === 'songSummary' && filters && reportData && (
            <SummaryReport settings={settings} setSettings={setSettings} filters={filters} />
          )}
          {panel === 'details' && filters && reportData && (
            <DetailsReport settings={settings} setSettings={setSettings} filters={filters} />
          )}
        </ContainerGrid>
      </GridVisibilityWrapper>
    </>
  );
};

export default SongTrackingReport;
