import { createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      width: '100%',
      '& .MuiInputBase-root, & .MuiInputBase-input': {
        lineHeight: '40px',
        height: 'auto',
      },
    },
  });
});
