import styled from 'styled-components';
import { createStyles, makeStyles, Grid } from '@material-ui/core';

export const Container = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  margin-top: 100px;
`;

export const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      backgroundColor: 'white',
      width: '100%',
      padding: '16px',
      '& h2': {
        fontSize: '20px',
        paddingBottom: '1em',
      },
      '& p': {
        fontSize: '16px',
        paddingBottom: '1em',
      },
    },
  });
});
