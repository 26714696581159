
export const resources = {
  en: {
    translation: {
      grid: {
        header: {
          NewThisWeek: 'New',
          Market: 'Market',
          HasPlayedToday: 'Has Played Today',
          SpinsToDate: 'Spins to date',
          Spins: 'Spins',
          History: 'History',
          FirstPlayedDate: 'First Played Date',
          firstPlayed: 'First Played',
          FormatName: 'Format',
          StationName: 'Station',
          FirstMonitored: 'First Monitored',
          FirstDetect: '1ST Detect',
          LastMonitored: 'Last Monitored',
          ImpressionsToDate: 'Impressions to Date',
          MarketRank: 'Market Rank',
          FormatCode: 'Market Add',
          MarketName: 'Market Name',
          FormatShort: 'Format',
          CallLetters: 'Station',
          DaypartImpressions: 'Day Part Impressions',
          DaypartsInfo: 'Dayparts',
          Label: 'Label',
          ReleaseYear: 'Rel Date',
          TotalSpins: 'Total Spins',
          YtdRank: 'Ytd Rank',
          LW: 'LW',
          TW: 'TW',
          Move: '+/-',
          SpinTrends: 'Spins',
          ImpressionsPerMill: "Imp/Mill",
          TWLW: 'TW/LW',
          TWLWMove: 'TW/LW/+/-',
          Rank: 'Rank',
          Title: 'Title',
          Artist: 'Artist',
          ArtistInfo: 'Title/Artist',
          ArtistTitleLabel: 'Title / Artist / Label',
          WeeksOnChart: 'WKS ON',
          Points: 'Points',
          PointsInfoTW: 'Points - TW',
          SpinsInfoTW: 'Spins - TW',
          SpinsInfoTotal: 'Spins Total',
          LWMove: 'LW,+/-',
          plusMinus: '+/-',
          stationsCount: 'Stations',
          station: 'Station',
          addsCount: 'Adds',
          RTW: 'RTW',
          RLW: 'RLW',
          ImpressionsInfo: 'Impressions',
          PointsInfo: 'Points Info',
          Stations: 'Stations',
          OnNew: 'On/New',
          New: 'New',
          AvgStationRotations: 'Avg. Station Rotations',
          On: 'On',
          WithoutOvernight: 'W/O OVN',
          AmDrive: 'AMD',
          PmDrive: 'PMD',
          Midday: 'MID',
          Overnight: 'OVN',
          Evening: 'EVE',
          TP: 'TP',
          TotalPlays: 'Total Plays',
          StationPlaying: 'Station Playing',
          StationPortfolio: 'Station Portfolio',
          StationPortfolioName: 'Station Portfolio Name',
          NotPlaying: 'Not Playing',
          Add: 'Add',
          Date: 'Date',
          DateAdded: 'Date Add',
          ThisWeekRank: 'STN.Rank',
          Plays: 'Plays',
          ShowTop: 'Show Top',
          ImpressionsM: 'Imp(M)',
          SpinDifferential: 'Spins Diff',
          ArtistLabel: 'Artist, Label',
          PeakRank: 'Peak',
          PointsThisWeek: 'PTW',
          PointsLastWeek: 'PLW',
          PointsMove: 'PMV',
          TD: 'Spins To Date',
          WithoutOvernightOVN: 'W/O OVN',
          AsOf: 'As of ',
          Songs: 'Songs',
          TitlesCount: '# Titles',
          OwnerName: 'Owner',
          StationRank: 'Station Rank',
          ChartRank: 'Chart Week',
          DayParts: 'DayParts',
          Total: 'Total',
          YearDataInfo: {
            January: 'January',
            February: 'February',
            March: 'March',
            April: 'April',
            May: 'May',
            June: 'June',
            July: 'July',
            August: 'August',
            September: 'September',
            October: 'October',
            November: 'November',
            December: 'December',
          },
          totalPlays: {
            Week1: 'TP',
            Week2: '-1P',
            Week3: '-2P',
            Week4: '-3P',
            Move: '+/-',
            TD: 'TD',
          },
          weekly: {
            Week1: 'TP',
            Week2: '-1P',
            Week3: '-2P',
            Week4: '-3P',
            Move: '+/-',
          },
          dayByDaySpinsDaily: {
            Day1: '-1',
            Day2: '-2',
            Day3: '-3',
            Day4: '-4',
            Day5: '-5',
            Day6: '-6',
            Day7: '-7',
          },
          impressions: {
            ImpressionsThisWeek: 'TP',
            ImpressionsLastWeek: '-1P',
            ImpressionsMove: '+/-',
          },
          stationPlaying: {
            PlayingThisWeek: 'TP',
            PlayingLastWeek: '-1P',
            Move: '+/-',
          },
          notPlaying: {
            NotPlayingThisWeek: 'TP',
            NotPlayingLastWeek: '-1P',
            NotPlayingMove: '+/-',
          },
          SpinsDaily: 'Spins Daily',
          SpinsWeekly: 'Spins Weekly',
          DaypartSpins: 'Daypart Spins',
          SpinsToDay: 'Spins To Date',
          ImpressionsDaily: 'Impressions Daily',
          ImpressionsWeekly: 'Impressions Weekly',
          WeeklySpinTrends: 'Spins Weekly',
          WeeklyImpressionsTrends: 'Weekly Impression Trends',
          DailyImpressionsTrends: 'Daily Impression Trends',
          DailySpinTrends: 'Daily Spin Trends',
          twelveWeekRankTrends: '12 Week Rank Trends',
          twelveWeekRankTrendsMillions: '12 Week Rank Trends (Millions)',
          LastWeekTrends: 'LW Trends',
          SpinGrid24h: 'Spin Grid - Last 24 Hours Monitored',
          minSpinsCuttOff: 'Min Spins Cut Off:',
          dayPartImpressions: 'Daypart Impressions',
          Percentages: 'Percentages',
          cancon: 'Cancon',
          stations: {
            StationsOn: 'On',
            StationsNew: 'New',
            StationsUp: 'Up',
            StationsSteady: 'STDY',
            StationsDown: 'DN',
          },
          twelveWeek: {
            Week12: '-12W',
            Week11: '-11W',
            Week10: '-10W',
            Week9: '-9W',
            Week8: '-8W',
            Week7: '-7W',
            Week6: '-6W',
            Week5: '-5W',
            Week4: '-4W',
            Week3: '-3W',
            Week2: '-2W',
            Week1: '-1W',
          },
          ImpressionTotal: 'Imp. Total',
          DayByDaySpins: 'Day By Day Spins',
          DayByDayRank: 'Day by Day Rank',
          ThisWeekDayByDayPlays: 'This Week Day by Day Plays',
          LastMonth: 'Last Mon',
          Year: 'Year',
          MarketInfo: 'Market Info',
          marketInfo: {
            Share: 'Share(%)',
            MarketSpinsTw: 'Market Spins TW',
          },
          Impressions: 'Impressions',
          ReachMillion: 'Reach/Mill',
          ImpRank: 'Imp Rank',
          impression: {
            ReachMill: 'ReachMill',
            ImpressionRank: 'Imp Rank',
            ImpressionMill: 'Imp./Mill',
          },
          HistoricalData: 'Historical Data Since:',
          historicalData: {
            HistoricalData: 'Historical Data',
            FirstPlayed: 'First Played',
            HistSpins: 'Hist Spins',
          },
          FormatComparison: 'Format Comparison',
          formatComparison: {
            Avg: 'Avg',
            Move: '+/-',
            Rank: 'Rank'
          },
          SpinsDifferential: {
            FirstSong: '1st Song',
            SecondSong: '2nd Song',
          },
          H6amTo7pm: '6AM to 7PM',
          NationalSummary: 'National Summary',
          FormatSummary: 'Format Summary',
          Details: 'Details',
          HistSpinToDate: 'HIST - Spins to Date',
          Name: 'Name',
          TimePlayed: 'Time Played',
          Grc: 'GRC',
          OverallRank: 'Overall Rank',
          SpinsWithinDateRangeSpecified: 'Spins Within Date Range Specified',
          DayByDayImpressions: 'Day by Day Impressions',
          FormatByFormatRank: 'Format By Format Rank',
          PlayPercent: '% Of Play',
          ImpressionsMill: 'Impressions(Mill)',
          Trend: 'Trend',
          PeakInfo: {
            SpinsDate: 'Spins Date',
            Spins: 'Spins',
            Chart: 'Chart',
            ChartDate: 'Chart Date',
          },
          Day: 'Day',
          SpinsGrid: {
            Overnight: 'Overnight',
            AmDrive: 'AM Drive',
            Midday: 'Midday',
            PmDrive: 'PM Drive',
            Evening: 'Evening',
            Nightime: 'Nightime',
          },
          AirPlayInformation: 'Mediabase 24/7 Current Airplay Information',
          SpinsTw: 'Spins TW',
          HistoricalInformation: 'Historical Information',
          HistoricalDayPartInformation: {
            Spins: 'Spins To Date',
            AmDrive: 'AMD To Date',
            PmDrive: 'PMD To Date',
            Midday: 'MID To Date',
            Overnight: 'OVN To Date',
            Evening: 'EVE To Date',
          },
          PercentOfTotalSpins: '% Of Total Spins',
          PercentOfTotalImpressions: '% Of Total Impressions',
          Group: 'Group',
          AverageSpinsPerStation: 'Avg. Spins Per Station',
          Nightime: 'Nightime',
          yearBody: 'year',
          TotalImpressions: 'Total Impressions (Mill)',
          PercentOfTotalPlay: 'Percent Of Total Play',
          PeakPlays: 'Peak Plays',
          PeakWeek: 'Peak Week',
          PeakChart: 'Peak Chart',
          GroupOwner: 'Group Owner',
          FormatSpins: 'Format Spins',
          StationSpins: 'Station Spins',
          MinSpins: 'Min Spins',
        },
        fields: {
          ChartRankRecurring: "-Rec-",
          ChartRankNa: "N/A",
          ChartRankGold: " g"
        },
        content: {
          missingSongInformation: 'No Song Information Provided',
          missingArtistInformation: 'No Artist Information Provided',
        },
      },
      filter: {
        title: {
          hideFrenchSongs: 'Hide French Songs',
          dayRange: 'Date',
          weekRange: 'Weekly Range',
          monthRange: 'Monthly Range',
          showAllStationsInPanel: 'Show All Stations In Panel',
          minSpins: 'Min.Spins Cut Off',
          songs: 'Songs',
          song: 'Song',
          formats: 'Formats',
          songSelection: 'Song Selection',
          FirstSongInfo: 'First Song',
          SecondSongInfo: 'Second Song',
          selectedSongs: 'Selected Songs',
          songPortfolios: 'Song Portfolio',
          IsPublishedStationsOnly: 'Published Stations Only',
          showImpressions: 'Show Impressions',
          stations: 'Stations',
          stationsSelection: 'Stations Selection',
          stationPortfolio: 'Station Portfolio',
          stationPortfolioGroup: 'Station Portfolio Group',
          Owner: 'Owner',
          Market: 'Market',
          SearchBy: 'Search By',
          PastDays: 'Past Days',
          ReportType: 'Report Type',
          NumberOfWeeks: 'Number of Weeks',
          Artist: 'Artist',
          Year: 'Year',
          ReportMode: 'Report Mode',
          submit: 'SUBMIT',
          loadingData: 'Loading Data',
          noDataFound: 'No Data Found',
          noRowToShow: 'There Are no Rows to Show',
          noAdditionalData: 'No additional data available to be displayed',
          apply: 'APPLY',
          daysOfWeek: 'Days of Week',
          titleFormats: 'Formats',
          displayTheLast: 'Display the last',
          cycle: {
            title: 'Cycle',
            rolling: 'Rolling',
            building: 'Building',
            currentDay: 'Current Day',
            published: 'Published',
            label: 'Report Cycle',
          },
          buildingReport: 'Building Report',
          dailyReport: 'Daily Report',
          showAll: 'Show All',
          showTop: {
            s20: 'Show Top 20',
            s30: 'Show Top 30',
            s40: 'Show Top 40',
            s50: 'Show Top 50',
            s100: 'Show Top 100',
            s150: 'Show Top 150',
            s200: 'Show Top 200',
            s500: 'Show Top 500',
            s1000: 'Show Top 1000',
          },
          add: 'ADD',
          format: 'FORMAT',
          stationMarket: 'Station MARKET',
          stationSelect: 'StationSelect',
          chartFilter: 'Chart Filter',
        },
        label: {
          today: 'Today',
          yesterday: 'Yesterday',
          thisWeek: 'This Week',
          lastWeek: 'Last Week',
          previousWeek: 'Previous Week',
          thisMonth: 'This Month',
          lastMonth: 'Last Month',
          custom: 'Custom',
          apply: 'Apply',
          SelectedSongs: 'Selected Songs',
          SongPortfolios: 'Song Portfolios',
          useCurrentBuildingCycle: 'Use Current Building Cycle',
          various: 'Various...',
          all: 'All',
          search: 'Search',
          updated: 'Updated',
          bigPicture: 'Big Picture',
          bigPictureWithHistory: 'Big Picture with History',
          bigPictureWithHistoryFrench: 'Big Picture with History French',
          ReportMode: 'Report Mode',
          default: 'Default Mode',
        },
        spinModeCycle: {
          title: 'Spin Mode Cycle:',
          options: {
            withSpinOnly: 'With Spins Only',
            noSpinsOnly: 'No Spins Only',
            allStation: 'All Station',
          },
        },
        dayPart: {
          hours: {
            '0': '12 AM',
            '1': '1 AM',
            '2': '2 AM',
            '3': '3 AM',
            '4': '4 AM',
            '5': '5 AM',
            '6': '6 AM',
            '7': '7 AM',
            '8': '8 AM',
            '9': '9 AM',
            '10': '10 AM',
            '11': '11 AM',
            '12': '12 PM',
            '13': '1 PM',
            '14': '2 PM',
            '15': '3 PM',
            '16': '4 PM',
            '17': '5 PM',
            '18': '6 PM',
            '19': '7 PM',
            '20': '8 PM',
            '21': '9 PM',
            '22': '10 PM',
            '23': '11 PM',
          },
          Overnight: 'Overnight',
          AmDrive: 'AM Drive',
          Midday: 'Midday',
          PmDrive: 'PM Drive',
          Evening: 'Evening',
        },
        reportmode: {
          sevenday: '7-Day',
          yeartodate: 'Year-to-Date',
          fiftytwoweeks: 'Fifty Two Weeks',
          hystory: 'history 4-98 - present',
        },
      },
      fields: {
        Select: 'Select...',
        Search: 'Search',
      },
      report: {
        tab: {
          songs: 'Songs',
          sevenDay: '7-Day',
          chart: 'Chart',
          overall: 'Overall',
          formatSummary: 'Format Summary',
          songSummary: 'Song Summary',
          details: 'Details',
          songAnalysis: 'SONG ANALYSIS',
          daypartSummary: 'DAYPART SUMMARY',
          rollingChart: 'Rolling Chart',
          twelveWeekRankTrend: '12 Week Rank Trend',
          twelveWeekSpinTrend: '12 Week Spin Trend',
          twelveWeekImpressionsTrend: '12 Week Impressions Trend',
          onNewUpDown: 'ON/New/Up/Down',
          activactorChart: 'Activator Chart',
        },
        label: {
          station: 'Station',
          dailyCurrent: 'Daily/Realtime',
          stationMonitor: 'Station/Monitor',
          sevenDay: '7-Day',
          songs: 'Songs',
          title: 'Title',
          currentDayChart: 'Realtime Chart',
          afterMidNite: 'After Mid Nite',
          dailyChart: 'Daily Chart',
          publishedChart: 'Published Chart',
          songRankingFirst: 'Song Ranking #1',
          classicView: 'Classic View'
        },
        songAnalysis: {
          songHistory: 'Song History',
          tabs: {
            songAnalysis: 'Song Analysis',
            songHistory: 'Song History',
            artistHistory: 'Artist History',
          },
          grids: {
            stationDetails: 'Station Details',
            executiveSummary: 'Executive Summary',
            executiveVideoSummary: 'Executive Video Summary',
            dayPartSummary: 'Daypart Summary',
            groupOwnerSummary: 'Group Owner Summary',
            songHistory: 'Song History',
            formatHistory: 'Format History',
            grouperOwnerHistory: 'Group Owner History',
          },
          songHistoryColumns: {
            SpinsDaypartsInfo: 'Total Spins:',
            ImpressionsDaypartsInfo: 'Imp .In Millions - TAs:',
            SpinsPercentDaypartsInfo: '% of Overall spin:',
            FirstPlayedDaypartsInfo: 'First Played',
            LastPlayedDaypartsInfo: 'Last Played',
          },
        },
        stationPlaylist: {
          tabs: {
            StationPlaylistsReport: 'Station Playlist',
            NotPlaying: 'Not Playing',
            Historical: 'Historical',
          },
        },
      },
      page: {
        dashboard: {
          announcement: 'Announcements',
        },
        footer: {
          desktopVersion: 'DESKTOP VERSION',
          termsOfUse: 'Terms of Use',
          privacyPolicy: 'Privacy Policy',
          disclaimer: '2021 Mediabase. All Rights Reserved.',
          doNotShareMyInfo: 'Do Not Share My Personal Information for Cross-Context Behavioral Advertising'
        },
        support: {
          title: 'Help',
          phone: 'To get any help or support, please call: <1/> Phone#: <3>{{phoneNumber}}</3>',
          email: 'Email: ',
        },
        pageNotFound: {
          title: 'Sorry we cannot find this page. ',
          message: 'Page not found',
          goHome: 'Go to Home ',
        },
        emptyPage: 'Empty page',
        menu: {
          dashboard: 'Dashboard',
        },
      },
      login: {
        loginSuccess: 'Login Successfully',
        loginFailed: 'Incorrect username or password',
        forgotPassword: 'Forgot Password',
        faqs: 'FAQs',
        login: 'LOG IN',
        title: 'Log In',
      },
    },
  },
};
