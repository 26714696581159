import useRemoteApi from 'hooks/useApi/useRemoteApi';
import { usePrevious } from 'hooks/usePrevious/usePrevious';
import { isEqual, noop } from 'lodash';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilterDataProps from 'types/FilterDataProps';
import GenericSelect from '../GenericSelect';
import { OwnerInfo } from 'types/OwnerTypes';

export interface OwnerFilterData {
  OwnerInfo?: OwnerInfo;
}

export interface OwnerFilterSelectionProps<T> extends FilterDataProps<T> {
  disabled?: boolean;
}

const url = '/owners';
const ALL_OPTION = {
  Id: -1,
  Name: 'All',
};

const OwnerFilterSelection = <T extends OwnerFilterData>({
  onChange = noop,
  initialValue,
  disabled = false,
}: OwnerFilterSelectionProps<T>): ReactElement => {
  const { t } = useTranslation();
  const { data: reportData } = useRemoteApi<OwnerInfo[]>(url, {
    method: 'GET',
    enabled: true,
    transformResponse: data => {
      return [ALL_OPTION, ...data];
    },
  });

  const [selectedValue, setSelectedValue] = useState<OwnerInfo>();
  const previousSelected = usePrevious(selectedValue);

  useEffect(() => {
    if (!reportData?.length) {
      return;
    }
    if (initialValue) {
      const find = reportData.find(v => v.Id === initialValue?.Id);
      if (find) {
        setSelectedValue(find);
      } else {
        setSelectedValue(reportData[0]);
      }
    }
  }, [initialValue, reportData]);

  useEffect(() => {
    if (!isEqual(selectedValue, previousSelected)) {
      onChange({ value: { OwnerInfo: selectedValue } as T });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  const handleChange = ({ value }: { value: unknown }) => {
    setSelectedValue(value as OwnerInfo);
  };

  return (
    <>
      <GenericSelect
        onChange={handleChange}
        data={reportData}
        disabled={disabled}
        initialValue={selectedValue}
        title={t('filter.title.Owner')}
      />
    </>
  );
};

export default OwnerFilterSelection;
