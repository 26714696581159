import styled from 'styled-components';
import { Typography, Grid } from '@material-ui/core';

export const TitleContainer = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Title = styled(Typography)`
  font-size: 14px;
  font-weight: bold;
`;
