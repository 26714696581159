import React from 'react';
import InnerTableBody from './inner-table-body';
import { IInnerTableGroup } from './inner-table-group';
import InnerTableHeaders from './inner-table-headers';
import { Container } from './styles';

interface InnerTableProps {
  data?: IInnerTableGroup[];
  direction?: string;
}

const InnerTable: React.FC<InnerTableProps> = ({ data, direction = 'column' }: InnerTableProps) => {
  if (data == null || data?.length < 1) {
    return <div aria-label="no-data">Oops... we could not find any data</div>;
  }
  return (
    <Container>
      <table>
        <InnerTableHeaders data={data} />
        <InnerTableBody direction={direction} data={data} />
      </table>
    </Container>
  );
};

InnerTable.defaultProps = { data: [], direction: 'column' };

export default InnerTable;
