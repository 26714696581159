import { Grid, Input } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Grid)`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
export const FormatLabel = styled.div`
  display: flex;
  flex: 3;
  color: rgba(0, 0, 0, 0.54);
  font-size: 10px;
  margin-right: 5px;
`;
export const InputNumber = styled(Input)`
  display: flex;
  flex: 1;
`;
