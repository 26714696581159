// import { DayByDaySpins } from 'types/DayByDaySpins';
// import { extractThisWeekDayByDaySpinsNonZeroValues } from 'utils/reports';
import {
  CurrentChartResponse,
  CurrentChartRow,
  TransformedCurrentChartRow,
  TransformedCurrentChartResponse,
} from './CurrentChartTypes';

// const convertArrayToObject = (array: number[], propertyKey = 'ThisWeek') => {
//   const initialValue = {};
//   return array.reduce((obj: any, item: number, idx) => {
//     return {
//       ...obj,
//       [idx]: { [propertyKey]: item },
//     };
//   }, initialValue);
// };

// const normalizeDayByDaySpins = (dayByDay: DayByDaySpins) => {
//   const filter = extractThisWeekDayByDaySpinsNonZeroValues(dayByDay);
//   return convertArrayToObject(filter);
// };

export const CurrentChartReportTransformer = (
  currentChartResponse: CurrentChartResponse,
): TransformedCurrentChartResponse => {
  const transformItems = (row: CurrentChartRow): TransformedCurrentChartRow => {
    return {
      ...row,
      RankInfo: { ThisWeek: row.RankThisWeek, LastWeek: row.RankLastWeek },
      SongInfo: {
        Artist: row.Artist,
        Label: row.Label,
        Title: row.Title,
        SongCode: row.SongCode,
      },
      ImpressionsTotal: row.ImpressionsInfo,
      DayByDaySpinsCurrent: row.DayByDaySpins
    };
  };
  return { ...currentChartResponse, Items: currentChartResponse.Items.map(transformItems) };
};
