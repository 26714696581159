import { Grid, createStyles, makeStyles, Theme } from '@material-ui/core';
import styled from 'styled-components';
import arrowDown from 'images/arrow_down.png';

export const Container = styled(Grid)`
  display: flex;
  width: 100%;
  max-width: 100%;
  font-size-adjust: 10px;
  justify-content: center;
  align-items: center;
`;
export const OwnerLabel = styled.div`
  color: rgba(0, 0, 0, 0.54);
  font-size: 10px;
  margin-right: 5px;
`;

export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      fontSize: '10px',
      paddingBottom: '2px',
      display: 'flex',
      flex: 1,
      width: '100%',
      'webkit-appearance': 'none',
      '-moz-appearance': 'none',
      background: `transparent url(${arrowDown}) no-repeat 100% center`,
      '& svg': {
        display: 'none',
      },
    },
    icon: {
      color: theme.palette.primary.main,
    },
  });
});
