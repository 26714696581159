import { replace } from 'lodash';
import ReportResult from 'services/ReportResult';
import { SongInfo } from 'types/SongInfo';
import { DailyLog, TransformedDailyLog } from './types';

function createSpotSongInfo(item: DailyLog, spotBreakTitlePattern: string): SongInfo {
  const duration = item.TimeDisplay;
  return {
    Title: item.Artist,
    Artist: replace(spotBreakTitlePattern, '__DURATION__', duration),
    Label: '',
  };
}

function createSongInfo(item: DailyLog, spotBreakTitlePattern: string): SongInfo {
  if (item.GrcCode === 'X') {
    return createSpotSongInfo(item, spotBreakTitlePattern);
  }
  return {
    Artist: item.Artist,
    Title: item.Title,
    Label: item.ReleaseYear.toString(),
    SongCode: item.SongCode,
  };
}

function transformDailyLog(item: DailyLog, spotBreakTitlePattern: string): TransformedDailyLog {
  return {
    ...item,
    SongInfo: createSongInfo(item, spotBreakTitlePattern),
    FormattedGrcCode: item.GrcCode === 'X' ? '' : item.GrcCode,
  };
}

export function dailyLogResponseTransformer(
  result: ReportResult<DailyLog>,
  spotBreakTitlePattern: string,
): ReportResult<TransformedDailyLog> {
  return {
    Count: result.Count,
    Items: result.Items.map(i => transformDailyLog(i, spotBreakTitlePattern)),
  };
}
