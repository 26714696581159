import { Button } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

interface ButtonFilterGridProps {
  onClick: { (): void };
  name?: 'string';
}

const ButtonFilterGrid: React.FC<ButtonFilterGridProps> = ({ onClick, name = 'filter.title.apply' }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Button color="primary" classes={{ root: classes.root }} onClick={onClick}>
      {t(name)}
    </Button>
  );
};

export default ButtonFilterGrid;
