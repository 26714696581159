/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-shadow */
import FormatFilter from 'components/Filter/components/FormatFilter';
import ReportCycle from 'components/Filter/components/ReportCycle';
import ReportMode from 'components/Filter/components/ReportMode';
import ShowTopSelect from 'components/Filter/components/ShowTopSelect';
import FilterBase from 'components/FilterBase';
import useStickyFilter from 'hooks/useStickyFilter/useStickyFilter';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FilterDataEvent from 'types/FilterDataEvent';
import { ReportCycleNameEnum, ReportCycleIdEnum } from 'types/ReportCycle';
import GRCFilter from 'components/Filter/components/Grc';
import InputNumberFilter from 'components/InputNumber';
import PastDays, { getCurrentBuildingCycle } from 'components/Filter/components/PastDays';
import { GrcInfoEnum } from 'types/GrcInfo';
import { MinSpins } from '../PublishedChartReport/utils/PublishedChartReportTypes';
import { IndieChartFilter } from './utils/IndieChartReportTypes';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface IndieChartReportProps {
  changeFilter: { (filters: IndieChartFilter): any };
  isOpened: boolean;
}

export enum ReportModeIdIndieEnum {
  defaultMode = 1,
  bigPicture = 2,
}

enum ReportModeIdIndieNameEnum {
  defaultMode = 'filter.label.default',
  bigPicture = 'filter.label.bigPicture',
}

const Filter: React.FC<IndieChartReportProps> = ({ changeFilter, isOpened }) => {
  const pathname = getReportSpec(ReportBaseEnum.IndieChartReport).stickyKey;
  const { savedFilters: filter, saveFilter } = useStickyFilter<IndieChartFilter>(pathname);
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleFilterChange = (event: FilterDataEvent<object>) => {
    const { value } = event;
    saveFilter({ ...filter, ...value } as IndieChartFilter);
  };

  const optionsReportCycle = [
    { Name: t(ReportCycleNameEnum.rolling), Id: ReportCycleIdEnum.rolling },
    { Name: t(ReportCycleNameEnum.building), Id: ReportCycleIdEnum.building },
  ];

  const optionsReportMode = [
    { Name: t(ReportModeIdIndieNameEnum.defaultMode), Id: ReportModeIdIndieEnum.defaultMode },
    { Name: t(ReportModeIdIndieNameEnum.bigPicture), Id: ReportModeIdIndieEnum.bigPicture },
  ];

  return (
    <FilterBase onSubmit={() => changeFilter(filter as IndieChartFilter)} isOpened={isOpened}>
      <ReportMode options={optionsReportMode} onChange={handleFilterChange} initialValue={filter?.ReportMode} />
      <ReportCycle
        options={optionsReportCycle}
        reportLabel={t('filter.title.chartFilter')}
        apiPropertyName="ReportCycle"
        onChange={handleFilterChange}
        initialValue={filter?.ReportCycle}
        disabled={filter?.ReportMode === ReportModeIdIndieEnum.bigPicture}
      />
      <PastDays
        onChange={handleFilterChange}
        initialValue={getCurrentBuildingCycle()}
        disabled={
          filter?.ReportMode === ReportModeIdIndieEnum.bigPicture || filter?.ReportCycle === ReportCycleIdEnum.rolling
        }
      />
      <FormatFilter
        hasAllOption={false}
        onChange={handleFilterChange}
        initialValue={filter?.FormatInfo}
        disabled={filter?.ReportMode === ReportModeIdIndieEnum.bigPicture}
      />
      <GRCFilter
        onChange={handleFilterChange}
        initialValue={filter?.GrcInfo}
        disabled={filter?.ReportMode === ReportModeIdIndieEnum.bigPicture}
        onlyShowThisElements={
          filter?.ReportCycle === ReportCycleIdEnum.rolling ? [GrcInfoEnum.Gold, GrcInfoEnum.CurrentsRecurrents] : []
        }
      />
      <ShowTopSelect
        onChange={handleFilterChange}
        apiPropertyName="TopX"
        initialValue={9999999}
        disabled={filter?.ReportMode === ReportModeIdIndieEnum.bigPicture}
      />
      <InputNumberFilter<MinSpins>
        minValue={1}
        onChange={handleFilterChange}
        name={t('grid.header.minSpinsCuttOff')}
        valueToApi="MinSpins"
        initialValue={filter?.MinSpins || 5}
      />
    </FilterBase>
  );
};

export default Filter;
