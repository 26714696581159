import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& p': {
        lineHeight: '18px',
      },
    },
  }),
);
