import styled from 'styled-components';

interface Props {
  direction?: string;
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const BodyTableElement = styled.div<Props>`
  display: flex;
  flex-direction: ${props => props.direction || 'column'};
  align-items: center;
  padding: 10px;
  > div:first-of-type {
    padding-right: ${props => (props.direction === 'row' ? '5px' : '0')};
  }
`;
