/*eslint-disable no-shadow*/
export enum SongCardAudioType
{
    None = 0,
    NoAudio = 1,
    DownloadOnly = 2,
    AudioOnly = 3,
    DownloadAndAudio = 4
}

export enum StationSelectionType 
{
    None = 0,
    StationPortfolio = 1,
    StationList = 2,
    StationPortfolioGroup = 3
}

export enum SongChooserType 
{
    None = 0,
    Song = 1,
    SongPortfolio = 2
}

export enum SongSearchMode 
{
    None = 0,
    ByFormat = 1, 
    ByStation = 2
}

export enum SongSelectionType 
{
    None = 0,
    SongPortfolio = 1,
    SongSelection = 2
}

export enum ReportCycleEnum 
{
    Published = 1,
    Rolling = 2,
    Building = 3,
    FiveDay = 4
}
  
export enum SongSearchType 
{
    None = 0,
    Title = 1,
    Artist = 2
}