import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles } from './styles';

interface AccordionReports {
  firstTitle?: string;
  secondTitle?: string;
  children?: JSX.Element | JSX.Element[];
  expanded: boolean;
  unmountOnExit?: boolean;
  isDisabled?: boolean;
  onChangeAccordion?(): void;
}

const AccordionReports: React.FC<AccordionReports> = ({
  firstTitle = 'Title',
  secondTitle,
  isDisabled = false,
  expanded = false,
  unmountOnExit = false,
  onChangeAccordion,
  children,
}) => {
  const classes = useStyles();

  return (
    <Accordion
      disabled={isDisabled}
      expanded={expanded}
      onChange={onChangeAccordion}
      TransitionProps={{ unmountOnExit }}
      className={classes.root}
    >
      <AccordionSummary
        className={classes.summary}
        expandIcon={<ExpandMoreIcon className={classes.icon} />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography data-testid="firstTitle" className={classes.heading}>
          {firstTitle}
        </Typography>
        <Typography data-testid="secondTitle" className={classes.secondaryHeading}>
          {secondTitle}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default AccordionReports;
