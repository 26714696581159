import { isArray, isEqual } from 'lodash';
import { FormatInfo } from 'types/Format';
import { ALL_OPTION } from '../FormatFilter';

function hasAllOptions(items: FormatInfo[] | FormatInfo): boolean {
  if (!items) {
    return false;
  }
  const itemArr = isArray(items) ? items : [items];
  return !!itemArr.find(v => v.Id === ALL_OPTION.Id);
}

export function calculateAllSelection(
  availableValues: FormatInfo[],
  previouslySelected: FormatInfo[],
  eventValues: FormatInfo[],
): FormatInfo[] {
  if (isEqual(previouslySelected, eventValues)) {
    return eventValues;
  }

  if (hasAllOptions(eventValues)) {
    if (!hasAllOptions(previouslySelected)) {
      // all values was clicked
      return availableValues;
    }
    if (eventValues?.length < previouslySelected?.length) {
      // something was deselected, but all was selected previously
      return eventValues.filter(v => v.Id !== ALL_OPTION.Id);
    }
  } else {
    if (hasAllOptions(previouslySelected)) {
      // all was deselected
      return [];
    }
    if (eventValues?.length === availableValues?.length - 1) {
      // everything but all selected
      return availableValues;
    }
  }

  return eventValues;
}
