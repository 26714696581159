import { AgGridColumn } from '@ag-grid-community/react/lib/agGridColumn';
import { GridOptions, ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import DetailGrid from 'components/DetailGrid';
import MultiLineCellRenderer from 'components/DetailGrid/CellRenderers/MultiLineCellRenderer/MultiLineCellRenderer';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import queryString from 'query-string';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReportCycleIdEnum } from 'types/ReportCycle';
import { formatDate } from 'utils/dates';
import { ArtistHistoryResponse, ArtistHistoryTransformed } from './utils/ArtistHistory';
import { SongAnalysisFilter } from './utils/SongAnalysisTypes';

export interface ArtistHistoryGridProps {
  filters?: SongAnalysisFilter;
}

const url = '/seven-day-song-analysis-song-artist-history-reports';
const ArtistHistoryGrid: React.FC<ArtistHistoryGridProps> = ({ filters }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({ sortPath: 'Rank', sort: 'asc' });
  const { data: reportData, isLoading, settings, setSettings, isError } = useRemoteApi<
    ArtistHistoryResponse<ArtistHistoryTransformed>
  >(url, {
    data: filters,
    method: 'POST',
    enabled: false,
  });

  const BottomOptions: GridOptions = {
    alignedGrids: [],
    defaultColDef: {
      editable: true,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 100,
    },
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onClickHandler = (data: any, reportQuery: SongAnalysisFilter) => {
    const href = getReportSpec(ReportBaseEnum.SongAnalysisReport).path;
    const params = {
      songCode: data.SongCode,
      'Formats.FormatCode': (reportQuery?.Formats ?? []).map(f => f.FormatCode).join(','),
      'PanelInfo.Id': reportQuery?.PanelInfo?.Id,
      NumDays: reportQuery?.NumDays,
      ReportCycle: ReportCycleIdEnum.published,
      deepLink: true,
    };
    window.open(`${href}?${queryString.stringify(params)}`);
  };

  return (
    <>
      <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
        <ServerSideReportGrid sortModel={sortModel} settings={settings} setSettings={setSettings} filters={filters}>
          <AgGridColumn
            field="Rank"
            headerName={t(`grid.header.Rank`)}
            minWidth={80}
            width={80}
            headerComponentParams={{ sortModel, onSortChange }}
          />

          <AgGridColumn
            field="Title"
            headerName={t(`grid.header.Title`)}
            minWidth={160}
            maxWidth={160}
            pinned
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              sortPath: 'Title',
              onSortChange,
              disposition: 'row',
              secondLine: [{ sortPath: 'ReleaseYear', label: 'grid.header.ReleaseYear' }],
            }}
            cellRendererFramework={MultiLineCellRenderer}
            cellRendererParams={{
              fields: ['ReleaseYear'],
              reportQuery: filters,
              onClickHandler,
            }}
          />

          <AgGridColumn
            field="TotalSpins"
            headerName={t(`grid.header.TotalSpins`)}
            minWidth={120}
            maxWidth={120}
            headerComponentParams={{ sortModel, onSortChange }}
          />

          <AgGridColumn
            field="FirstMonitored"
            valueFormatter={({ value }: ValueFormatterParams) => (value ? formatDate(value) : '--')}
            headerName={t('grid.header.FirstDetect')}
            headerComponentParams={{
              sortModel,
              sortPath: 'FirstMonitored',
              onSortChange,
            }}
            minWidth={120}
            maxWidth={120}
          />

          {(reportData?.FormatByFormatColumns ?? []).map(({ FormatIndex, DisplayName }) => {
            const fieldName = `FormatByFormatRanks.${FormatIndex}.FormatValue`;
            const sortPath = `FormatByFormatRanks/${FormatIndex}/FormatValue`;
            return (
              <AgGridColumn
                field={fieldName}
                headerName={DisplayName}
                minWidth={80}
                width={80}
                key={FormatIndex}
                headerComponentParams={{ sortModel, onSortChange, sortPath }}
                valueFormatter={({ value }: ValueFormatterParams) => (!value ? '-' : `${value}`)}
              />
            );
          })}
        </ServerSideReportGrid>
        <DetailGrid
          reportData={[reportData?.FormatByFormatTotal] ?? []}
          headerHeight={0}
          gridOptions={BottomOptions}
          domLayout="autoHeight"
          rowStyle={{ fontWeight: 'bold' }}
          containerStyle={{
            height: '100%',
          }}
        >
          <AgGridColumn field="0" minWidth={160} maxWidth={160} valueGetter={() => t('grid.header.Total')} />
          <AgGridColumn field="1" minWidth={80} maxWidth={80} valueGetter={() => `-`} />
          <AgGridColumn
            field="2"
            minWidth={120}
            maxWidth={120}
            valueGetter={() => reportData?.FormatByFormatPlayAllTotal}
          />
          <AgGridColumn field="3" minWidth={120} maxWidth={120} valueGetter={() => `-`} />
          {(reportData?.FormatByFormatColumns ?? []).map(({ FormatIndex, DisplayName }) => {
            const fieldName = `${FormatIndex}.FormatValue`;
            const sortPath = `${FormatIndex}/FormatValue`;
            return (
              <AgGridColumn
                field={fieldName}
                headerName={DisplayName}
                minWidth={80}
                width={80}
                key={FormatIndex}
                headerComponentParams={{ sortModel, onSortChange, sortPath }}
                valueFormatter={({ value }: ValueFormatterParams) => (!value ? '-' : `${value}`)}
              />
            );
          })}
        </DetailGrid>
      </GridVisibilityWrapper>
    </>
  );
};

export default ArtistHistoryGrid;
