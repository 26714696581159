import { isArray } from 'lodash';
import { FormatInfo } from 'types/Format';
import { ALL_OPTION } from '../FormatFilter';

export function resolveLabel(items?: FormatInfo[] | FormatInfo): string {
  if (!items || (isArray(items) && !items.length)) {
    return '';
  }

  const itemArr = isArray(items) ? items : [items];
  if (itemArr.find(v => v.Id === ALL_OPTION.Id)) {
    return 'filter.label.all';
  }

  if (itemArr.length > 1) {
    return 'filter.label.various';
  }

  return itemArr[0].Name;
}
