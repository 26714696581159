import ReportResult from 'services/ReportResult';
import { ArtistInfoNormalize, RankNormalize } from 'utils/reports/normalizeTransform';
import { formatDate } from '../../../../../../utils/dates/index';
import {
  StationPlaylistGenericItem,
  StationPlaylistGenericResponse,
  StationPlaylistGenericTransformedItem,
} from './StationGenericTypes';

export const StationPlaylistGenericTransformerResponse = (
  sevenDayHistorySongResponse: StationPlaylistGenericResponse,
): ReportResult<StationPlaylistGenericTransformedItem> => {
  const transformItems = (row: StationPlaylistGenericItem): StationPlaylistGenericTransformedItem => {
    return {
      ...row,
      Rank: RankNormalize({ ThisWeek: row.FormatRankThisWeek, LastWeek: row.FormatRankLastWeek }),
      HistoricalInfo: {
        FirstPlayDate: formatDate(row.FirstMonitored),
        HistoricalSpins: row.HistoricalSpins,
      },
      ArtistInfo: ArtistInfoNormalize(row),
      ImpressionsMill: {
        SpinsThisWeek: row.ImpressionsInfo?.SpinsThisWeek ?? 0,
        RankThisWeek: row.ImpressionsInfo?.RankThisWeek ?? 0,
      },
      Historical: {
        FirstMonitoredDate: row.FirstMonitoredDate ?? '',
        LastMonitoredDate: row.LastMonitoredDate ?? '',
      },
    };
  };
  return {
    ...sevenDayHistorySongResponse,
    Items: sevenDayHistorySongResponse.Items.map(Item => transformItems(Item)),
  };
};
