import React from 'react';

import { Container, Message } from './styles';

const RequestGridError: React.FC = () => {
  return (
    <Container>
      <Message>Failed to load server data.</Message>
    </Container>
  );
};

export default RequestGridError;
