import Star from '@material-ui/icons/Star';
import React from 'react';
import defaultTheme from '../../../themes';

interface NewThisWeekCellRendererProps {
  value?: boolean;
}

const NewThisWeekCellRenderer: React.FC<NewThisWeekCellRendererProps> = ({ value }: NewThisWeekCellRendererProps) => {
  const getIcon = () => {
    let color = defaultTheme.palette.error.main;

    if (value) {
      color = defaultTheme.palette.success.main;
      return <Star aria-label="New " style={{ color }} />;
    }
    return <></>;
  };

  return <div style={{ width: '100%' }}>{getIcon()}</div>;
};

NewThisWeekCellRenderer.defaultProps = { value: false };
export default NewThisWeekCellRenderer;
