import { createStyles, makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 0;
  width: 100%;
`;

export const useStyles = makeStyles(() =>
  createStyles({
    itemsList: {
      textAlign: 'right',
      paddingRight: '.5em',
    },
    emptyBox: {
      width: '1.25rem',
    },
  }),
);
