import { debounce, isEqual, noop } from 'lodash';
import { Dispatch, useEffect, useRef, useState } from 'react';
import { defaultFilterTransformer } from './utils/defaultFilterTransformer';

export interface DeepLinkResults {
  hasDeepLinkInfo: boolean;
  isReady: boolean;
}

export interface DeepLinkOptions<T extends {}> {
  filterTransformer?: (queryString: Map<string, string>, filter: T) => T;
  onReady?: (filter: T) => void;
}

function useDeepLink<T extends {}>(
  filter: T | undefined,
  saveFilter: Dispatch<T>,
  isFilterReady: boolean,
  options?: DeepLinkOptions<T>,
): DeepLinkResults {
  const queryString = new Map<string, string>(new URLSearchParams(window.location.search));
  const [submitted, setSubmitted] = useState(false);

  const hasDeepLinkInfo = (): boolean => {
    return queryString.get('deepLink') === 'true';
  };

  // eslint-disable-next-line no-shadow
  const getTransformer = (): ((queryString: Map<string, string>, filter: T) => T) => {
    return options?.filterTransformer ?? defaultFilterTransformer;
  };

  const hasSetFilterComparator = (originalFilter?: T) => {
    if (!originalFilter) return false;
    const changedFilter = getTransformer()(queryString, originalFilter);
    return isEqual(originalFilter, changedFilter);
  };

  const setDeepLinkInformation = useRef(
    // wait for filter to stabilize
    debounce((qs, f) => {
      const newFilter = getTransformer()(qs, { ...f });
      saveFilter({ ...newFilter });
    }, 500),
  );

  useEffect(() => {
    if (hasDeepLinkInfo() && filter && !submitted) {
      if (!hasSetFilterComparator(filter)) {
        setDeepLinkInformation.current(queryString, filter);
      } else if (isFilterReady && !submitted) {
        setTimeout(() => {
          setSubmitted(true);
          (options?.onReady ?? noop)(filter);
        }, 500);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, isFilterReady]);

  return {
    hasDeepLinkInfo: hasDeepLinkInfo(),
    isReady: !hasDeepLinkInfo() || submitted || (hasSetFilterComparator(filter) && isFilterReady),
  };
}

export default useDeepLink;
