/* eslint-disable react/jsx-one-expression-per-line */
import React, { useState } from 'react';
import './styles.css';

const CookieNotice: React.FC = () => {
  const KEY_NAME = 'CookieUseAccepted';
  const rawCookieAccepted = localStorage.getItem(KEY_NAME) ?? 'true';
  const [showConsent, setShowConsent] = useState(rawCookieAccepted === 'true');

  const onClick = () => {
    localStorage.setItem(KEY_NAME, 'false');
    setShowConsent(false);
  };

  return (
    <div id="cookie-consent" className={showConsent ? 'cookie-consent-visible' : 'cookie-consent-hidden'}>
      <p className="cookie-consent-text">
        We use cookies on our website to improve user experience. By giving your consent to use cookies you are enabling
        us to provide the best possible experience. By using our site, you acknowledge that you have read and understand
        our <a href="https://www2.mediabase.com/mbapp/Privacy">Privacy Policy and Cookie Notice</a>. We also record user
        sessions to allow our support staff to quickly troubleshoot issues for you and improve your experience. If you
        have questions or concerns, please email support@mediabase.com
      </p>
      <button type="button" id="cookie-consent-close" aria-label="Close" onClick={onClick}>
        ACCEPT
      </button>
    </div>
  );
};

export default CookieNotice;
