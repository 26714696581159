import React, { useEffect, useState } from 'react';
import FilterDataProps from 'types/FilterDataProps';
import GenericSelect from 'components/Filter/components/GenericSelect';
import { ShowTopInfoType } from 'types/ShowTopInfoType';
import FilterDataEvent from 'types/FilterDataEvent';
import { useTranslation } from 'react-i18next';

interface Props extends FilterDataProps<FilterData> {
  apiPropertyName?: string;
  disabled?: boolean;
}

interface FilterData {
  ShowTop?: ShowTopInfoType;
}

interface ShowInfo {
  value?: ShowTopInfoType | undefined;
}

export const SHOW_TOP_SHOW_ALL = 9999999;

const options = [
  { Name: 'filter.title.showAll', Id: SHOW_TOP_SHOW_ALL },
  { Name: 'filter.title.showTop.s20', Id: 20 },
  { Name: 'filter.title.showTop.s30', Id: 30 },
  { Name: 'filter.title.showTop.s40', Id: 40 },
  { Name: 'filter.title.showTop.s50', Id: 50 },
  { Name: 'filter.title.showTop.s100', Id: 100 },
  { Name: 'filter.title.showTop.s150', Id: 150 },
  { Name: 'filter.title.showTop.s200', Id: 200 },
  { Name: 'filter.title.showTop.s500', Id: 500 },
  { Name: 'filter.title.showTop.s1000', Id: 1000 },
];

const ShowTopSelect: React.FC<Props> = ({ onChange, apiPropertyName = 'ShowTop', initialValue, disabled = false }) => {
  const [items] = useState(options);
  const DEFAULT_VALUE = 150;
  const { t } = useTranslation();

  const updateState = (value: number) => {
    onChange({ value: { [apiPropertyName]: value } });
  };

  const handleSelect = (event: FilterDataEvent<ShowInfo>) => {
    const { value } = event;
    const val = (value as ShowTopInfoType).Id;
    updateState(val);
  };

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const getDefaultValue = (): number => {
    let val: any = items.find(v => v.Id === initialValue);
    if (!val) {
      val = items.find(v => v.Id === DEFAULT_VALUE);
    }
    return val;
  };

  useEffect(() => {
    updateState(initialValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  return (
    <GenericSelect
      title={t('grid.header.ShowTop')}
      data={items}
      onChange={handleSelect}
      initialValue={getDefaultValue()}
      disabled={disabled}
    />
  );
};

export default ShowTopSelect;
