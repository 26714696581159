import React, { useState } from 'react';
import ReportHeader from 'components/ReportHeader';
import FilterButton from 'components/FilterButton';
import { useTranslation } from 'react-i18next';
import { FilterParams } from 'types/FilterParams';
import SiblingReportsSelect from 'components/SiblingReportsSelect/SiblingReportsSelect';
import { Container, ReportContainer, GridContainer } from './styles';
import Filter from './Filters';
import ActivactorChartGrid from './ActivactorChartGrid';

const ActivactorChartReport: React.FC = () => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState<FilterParams | undefined>();
  const [filterOpened, setFilterOpened] = useState(false);

  const onChangeFilter = (newFilter: FilterParams) => {
    setFilter(newFilter);
    setFilterOpened(!filterOpened);
  };

  return (
    <Container container direction="column" spacing={0}>
      <ReportHeader title={t('report.tab.chart')} subtitle={t('report.tab.sevenDay')} />
      <ReportContainer>
        <SiblingReportsSelect />
        <FilterButton onClickFilter={() => setFilterOpened(!filterOpened)} isOpened={filterOpened} />
      </ReportContainer>
      <Filter isOpened={filterOpened} changeFilter={onChangeFilter} />
      <GridContainer>
        <ActivactorChartGrid filters={filter} />
      </GridContainer>
    </Container>
  );
};

export default ActivactorChartReport;
