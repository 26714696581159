import { AgGridReact } from '@ag-grid-community/react/lib/agGridReact';
import { Column } from '@ag-grid-enterprise/all-modules';
import React from 'react';
import { DayPartInfo } from 'types/DayPart';
import MultiColumnsCellRenderer from './MultiColumnsCellRenderer/MultiColumnsCellRenderer';

interface DayPartsCellRendererProps {
  value?: DayPartInfo;
  column?: Column;
  agGridReact?: AgGridReact;
}

const DayPartsCellRenderer: React.FC<DayPartsCellRendererProps> = ({
  value,
  column,
  agGridReact,
}: DayPartsCellRendererProps) => {
  if (!value) {
    return <></>;
  }
  const fields = ['Overnight', 'AmDrive', 'Midday', 'PmDrive', 'Evening'];
  return <MultiColumnsCellRenderer data={value} fields={fields} column={column} agGridReact={agGridReact} />;
};
DayPartsCellRenderer.defaultProps = { value: undefined, column: undefined, agGridReact: undefined };

export default DayPartsCellRenderer;
