/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import FilterDataEvent from 'types/FilterDataEvent';
import TabsGrid from 'components/TabsGrid';
import TabPanel from 'components/TabsGrid/TabPanel';
import { YearToDateSongsByFormatFilter } from './utils/YearToDateSongsByFormat';
import BigPictureByFormatGrid from './BigPictureByFormatGrid';
import BigPictureByMonthGrid from './BigPictureByMonthGrid';

const tabs = [
  { label: 'Big Picture by Format', value: 'bigPictureByFormat' },
  { label: 'Big Picture by Month', value: 'bigPictureByMonth' },
];

export interface YearToDateSongsByFormatProps {
  filters: FilterDataEvent<YearToDateSongsByFormatFilter> | null;
}

const YearToDateSongsByFormatReport: React.FC<YearToDateSongsByFormatProps> = ({ filters }) => {
  const [tabValue, setTabValue] = useState<number>(0);

  const onChangeTab = (value: number) => {
    setTabValue(value);
  };

  return (
    <>
      <TabsGrid tabValue={tabValue} onChangeTab={onChangeTab} tabs={tabs}>
        <TabPanel value={tabValue} index={0}>
          <BigPictureByFormatGrid filters={filters} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <BigPictureByMonthGrid filters={filters} />
        </TabPanel>
      </TabsGrid>
    </>
  );
};

export default YearToDateSongsByFormatReport;
