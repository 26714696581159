import React from 'react';
import { useTranslation } from 'react-i18next';
import { HistoricalInfoCustom } from 'types/HistoricalInfoCustom';
import { v4 } from 'uuid';
import { formatDate, formatDateTypes } from 'utils/dates';
import InnerTable from '..';
import { IInnerTableGroup } from '../inner-table-group';

interface HistoricalInfoCustomProps {
  data: HistoricalInfoCustom;
  name: string;
}

const HistoricalInfoCustomInnerTable: React.FC<HistoricalInfoCustomProps> = ({
  data,
  name,
}: HistoricalInfoCustomProps) => {
  const { t } = useTranslation();
  const createSpan = (item: string) => {
    // eslint-disable-next-line react/jsx-one-expression-per-line
    return <span>{item} </span>;
  };
  const innerTableData: IInnerTableGroup[] = [
    {
      title: t(name),
      children: [
        {
          title: '',
          children: [t('grid.header.firstPlayed'), t('grid.header.SpinsToDate')],
        },
        {
          title: '',
          children: [
            createSpan(formatDate(data.FirstPlayedDate || '', formatDateTypes.MMddyyyy)),
            createSpan(data.SpinsToDate.toString()),
          ],
        },
      ],
    },
  ];

  return <InnerTable key={v4()} data={innerTableData} />;
};

export default HistoricalInfoCustomInnerTable;
