import { AgGridColumn } from '@ag-grid-community/react';
import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import { GridOptions, ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import { UseApiSettings } from 'hooks/useApi/ApiTypes';
import ReportResult from 'services/ReportResult';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import AirPlayCellRenderer from 'components/DetailGrid/CellRenderers/AirPlayCellRenderer';
import { convertWithoutFloorToPercentage } from 'utils/maths/maths';
import { formatDate } from 'utils/dates';
import DetailGrid from 'components/DetailGrid';
import { SongAnalysisFilter } from './utils/SongAnalysisTypes';

import { SevenDayFormatHistoryResponseTransformed } from './utils/FormatHistoryTypes';
import { FormatHistoryTransformerResponse } from './utils/FormatHistoryTransformer';

export interface FormatHistoryGridProps {
  filters: SongAnalysisFilter;
}

const url = '/seven-day-song-analysis-song-format-history-reports';
const FormatHistoryGrid: React.FC<FormatHistoryGridProps> = ({ filters }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({ sortPath: 'SpinsInfo/ThisWeek', sort: 'asc' });
  const { data: reportData, isLoading, settings, setSettings, isError } = useRemoteApi<
    SevenDayFormatHistoryResponseTransformed
  >(url, {
    data: filters,
    method: 'POST',
    enabled: false,
    transformResponse: FormatHistoryTransformerResponse,
  });

  const TopOptions: GridOptions = {
    alignedGrids: [],
    defaultColDef: {
      editable: true,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 100,
    },
    suppressHorizontalScroll: true,
  };
  const BottomOptions: GridOptions = {
    alignedGrids: [],
    defaultColDef: {
      editable: true,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 100,
    },
  };
  ((TopOptions as GridOptions).alignedGrids ?? []).push(BottomOptions);
  ((BottomOptions as GridOptions).alignedGrids ?? []).push(TopOptions);
  return (
    <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
      <ServerSideReportGrid
        sortModel={sortModel}
        settings={(settings as unknown) as UseApiSettings<ReportResult<unknown>>}
        setSettings={(setSettings as unknown) as Dispatch<UseApiSettings<ReportResult<unknown>>>}
        filters={filters}
        detailProps={{
          gridOptions: TopOptions,
          domLayout: 'autoHeight',
          containerStyle: {
            height: '100%',
          },
        }}
      >
        <AgGridColumn
          field="FormatName"
          headerName={t(`grid.header.FormatName`)}
          minWidth={100}
          width={100}
          headerComponentParams={{ sortModel, onSortChange }}
        />
        <AgGridColumn
          field="TotalPlayPercent"
          headerName={t(`grid.header.PercentOfTotalPlay`)}
          valueFormatter={({ value }: ValueFormatterParams) => convertWithoutFloorToPercentage(value, 2)}
          minWidth={100}
          width={100}
          headerComponentParams={{ sortModel, onSortChange }}
        />
        <AgGridColumn
          field="DaypartsInfo"
          minWidth={400}
          width={400}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            displayName: '',
            usePercentual: true,
            sortModel,
            onSortChange,
            sortPath: 'SpinsToDate',
            secondLine: [
              { label: 'grid.header.SpinsToDay', sortPath: 'SpinsToDate' },
              { label: 'grid.header.Overnight', sortPath: 'DaypartsInfo/Overnight' },
              { label: 'grid.header.AmDrive', sortPath: 'DaypartsInfo/AmDrive' },
              { label: 'grid.header.Midday', sortPath: 'DaypartsInfo/Midday' },
              { label: 'grid.header.PmDrive', sortPath: 'DaypartsInfo/PmDrive' },
              { label: 'grid.header.Evening', sortPath: 'DaypartsInfo/Evening' },
            ],
          }}
          cellRendererParams={{
            totalField: 'SpinsToDate',
            isTotalRow: false,
            fixedValue: 2,
          }}
          cellRendererFramework={AirPlayCellRenderer}
        />
        <AgGridColumn
          field="PeakPlays"
          headerName={t(`grid.header.PeakPlays`)}
          minWidth={100}
          width={100}
          headerComponentParams={{ sortModel, onSortChange }}
        />
        <AgGridColumn
          field="PeakWeek"
          valueFormatter={({ value }: ValueFormatterParams) => formatDate(value)}
          headerName={t(`grid.header.PeakWeek`)}
          minWidth={100}
          width={100}
          headerComponentParams={{ sortModel, onSortChange }}
        />
        <AgGridColumn
          field="PeakChart"
          headerName={t(`grid.header.PeakChart`)}
          minWidth={100}
          width={100}
          headerComponentParams={{ sortModel, onSortChange }}
        />
      </ServerSideReportGrid>
      <DetailGrid
        reportData={[reportData?.TotalItems] ?? []}
        headerHeight={0}
        gridOptions={BottomOptions}
        domLayout="autoHeight"
        containerStyle={{ height: '100%' }}
        rowStyle={{ fontWeight: 'bold' }}
      >
        <AgGridColumn
          field="FormatName"
          headerName={t(`grid.header.FormatName`)}
          minWidth={100}
          width={100}
          headerComponentParams={{ sortModel, onSortChange }}
        />
        <AgGridColumn
          field="TotalPlayPercent"
          headerName={t(`grid.header.PercentOfTotalPlay`)}
          minWidth={100}
          width={100}
          headerComponentParams={{ sortModel, onSortChange }}
        />
        <AgGridColumn
          field="SpinsToDate"
          headerName={t(`grid.header.SpinsToDate`)}
          minWidth={100}
          width={100}
          headerComponentParams={{ sortModel, onSortChange }}
        />
        <AgGridColumn
          field="PeakPlays"
          headerName={t(`grid.header.PeakPlays`)}
          minWidth={100}
          width={100}
          headerComponentParams={{ sortModel, onSortChange }}
        />
        <AgGridColumn
          field="PeakWeek"
          valueFormatter={({ value }: ValueFormatterParams) => formatDate(value)}
          headerName={t(`grid.header.PeakWeek`)}
          minWidth={100}
          width={100}
          headerComponentParams={{ sortModel, onSortChange }}
        />
        <AgGridColumn
          field="PeakChart"
          headerName={t(`grid.header.PeakChart`)}
          minWidth={100}
          width={100}
          headerComponentParams={{ sortModel, onSortChange }}
        />
      </DetailGrid>
    </GridVisibilityWrapper>
  );
};

export default FormatHistoryGrid;
