import { useState } from 'react';
import { SortModel } from 'types/HeaderComponentParams';

export interface UseSortColumn {
  sortModel?: SortModel;
  setSortModel: (sortPath: string) => void;
}

function useSortColumn(defaultSortModel?: SortModel): UseSortColumn {
  const [sortModel, setSortModel] = useState<SortModel | undefined>(defaultSortModel);

  const onSortChange = (sortPath: string) => {
    const localModel = { sortPath, sort: 'asc' } as SortModel;
    if (sortModel?.sortPath === sortPath) {
      localModel.sort = sortModel?.sort === 'asc' ? 'desc' : 'asc';
    }
    setSortModel(localModel);
  };

  return {
    sortModel,
    setSortModel: (sortPath: string) => {
      onSortChange(sortPath);
    },
  };
}

export default useSortColumn;
