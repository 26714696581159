/* eslint-disable no-shadow */
export interface ReportCycle {
  Id: number;
  Name: string;
}

export enum ReportCycleNameEnum {
  published = 'filter.title.cycle.published',
  rolling = 'filter.title.cycle.rolling',
  building = 'filter.title.cycle.building',
}

export enum ReportCycleIdEnum {
  None = 0,
  published = 1,
  rolling = 2,
  building = 3,
  FiveDay = 4,
  CurrentDay = 5,
  CustomDateRange = 6,
  dailyReport = 7,
}