/* eslint-disable no-shadow */
import { addDays, eachDayOfInterval, format, setHours, subDays } from 'date-fns';

const daysInWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export enum formatDateTypes {
  MMdd = 'MM/dd',
  MMddyyyy = 'MM/dd/yyyy',
  yyyyMMdd = 'yyyy-MM-dd',
  iii = 'iii',
  MMMd = 'MMM d',
  EMMMdYHma = 'E, MMM d/Y, H:m a',
  EMMMdYhhmma = 'E, MMM d/Y, hh:mm a',
  MMddyyyyhhmma = 'MM/dd/yyyy hh:mm a'
}

export const convertToDate = (date: string | Date): Date => {
  if (date) {
    return date instanceof Date ? date : new Date(date);
  }
  return new Date();
};

export const getArrayDatesFromInterval = (startDate: string | Date, endDate: string | Date): Array<Date> => {
  if (startDate && endDate) {
    const start = convertToDate(startDate);
    const end = convertToDate(endDate);
    return eachDayOfInterval({ start, end });
  }
  return [new Date()];
};

export const getTheLastAnyDaysOfTheWeek = (day = 1, daysBefore = 6): string => {
  const curr = new Date();
  const firstday = new Date(curr.setDate(curr.getDate() - curr.getDay() - (daysBefore - day)));
  const formatFirstDay = firstday.getDate().toString().length < 2 ? `0${firstday.getDate()}` : firstday.getDate();
  return `${formatFirstDay}/${firstday.getMonth() + 1}`;
};

export const getNameOfTheWeek = (day = 1): string => {
  const curr = new Date();
  const firstday = new Date(curr.setDate(curr.getDate() - curr.getDay() - (6 - day)));
  return `${daysInWeek[firstday.getDay()]}`;
};

export const getNameOfTheWeekWithDate = (date: Date | string): string => {
  if (date) {
    const stringOrDate = date instanceof Date ? date : new Date(date);
    return daysInWeek[stringOrDate.getDay()];
  }
  return '';
};

export const formatDate = (date: string | Date, formatData = formatDateTypes.MMddyyyy as string): string => {
  if (date) {
    return format(date instanceof Date ? date : new Date(date), formatData);
  }
  return '';
};

export const formatTime = (date: string | Date, formatData = 'hh:mm a'): string => {
  if (date) {
    const parsed = new Date(date);
    return format(parsed instanceof Date ? parsed : new Date(), formatData);
  }
  return '';
};

export const daysAdd = (date: string | Date, daysToAdd = 1): Date => {
  if (date) {
    const stringOrDate = date instanceof Date ? date : new Date(date);
    return addDays(stringOrDate, daysToAdd);
  }
  return new Date();
};

export const daysMinus = (date: string | Date, daysToRemove = 1): Date => {
  if (date) {
    const stringOrDate = date instanceof Date ? date : new Date(date);
    return subDays(stringOrDate, daysToRemove);
  }
  return new Date();
};

export const getLastSundayPublishedAllowedAllowed = (today: Date): Date => {
  const lastSunday = today.getDay() === 0 ? subDays(today, 8) : subDays(today, today.getDay() + 1);
  return setHours(lastSunday, 0);
};
