/* eslint-disable @typescript-eslint/no-explicit-any */
import FormatFilter from 'components/Filter/components/FormatFilter';
import Grc from 'components/Filter/components/Grc';
import PanelFilter from 'components/Filter/components/PanelFilter';
import FilterBase from 'components/FilterBase';
import useStickyFilter from 'hooks/useStickyFilter/useStickyFilter';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import React from 'react';
import FilterDataEvent from 'types/FilterDataEvent';
import { GrcInfoEnum } from 'types/GrcInfo';
import { PanelCodeEnum } from 'types/Panel';
import { CurrentMusicChartFilter } from './utils/CurrentMusicChartTypes';

interface FilterCurrentMusicChartReport {
  changeFilter: { (filters?: CurrentMusicChartFilter): any };
  isOpened: boolean;
}

const Filter: React.FC<FilterCurrentMusicChartReport> = ({ changeFilter, isOpened }) => {
  const pathname = getReportSpec(ReportBaseEnum.CurrentMusicChartReport).stickyKey;
  const { savedFilters: filter, saveFilter } = useStickyFilter<CurrentMusicChartFilter>(pathname);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleFilterChange = (event: FilterDataEvent<CurrentMusicChartFilter>) => {
    const { value } = event as FilterDataEvent<CurrentMusicChartFilter>;
    saveFilter({ ...filter, ...value });
  };
  return (
    <FilterBase onSubmit={() => changeFilter(filter)} isOpened={isOpened}>
      <PanelFilter onChange={handleFilterChange} initialValue={filter?.PanelInfo} />
      <FormatFilter
        onChange={handleFilterChange}
        panelCode={filter?.PanelInfo?.PanelCode ?? PanelCodeEnum.None}
        hasAllOption={false}
        initialValue={filter?.FormatInfo}
      />
      <Grc
        onChange={handleFilterChange}
        onlyShowThisElements={[GrcInfoEnum.Gold, GrcInfoEnum.CurrentsRecurrents]}
        initialValue={filter?.GrcInfo}
      />
    </FilterBase>
  );
};

export default Filter;
