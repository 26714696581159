import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';

export const menuEntries = [
  {
    path: getReportSpec(ReportBaseEnum.StationPlaylistsReport).path,
    component: getReportSpec(ReportBaseEnum.StationPlaylistsReport).component,
  },
  {
    path: getReportSpec(ReportBaseEnum.ArtistByStationReport).path,
    component: getReportSpec(ReportBaseEnum.ArtistByStationReport).component,
  },
  {
    path: getReportSpec(ReportBaseEnum.StationSpinGridReport).path,
    component: getReportSpec(ReportBaseEnum.StationSpinGridReport).component,
  },
];
