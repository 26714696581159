import GenericSelect from 'components/Filter/components/GenericSelect';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FilterDataProps from 'types/FilterDataProps';
import { ReportCycle } from 'types/ReportCycle';

interface Props extends FilterDataProps<FilterData> {
  options?: Array<ReportCycle>;
  apiPropertyName?: string;
  reportLabel?: string;
  disabled?: boolean;
}
interface FilterData {
  Cycle?: number;
}

const ReportCycleComponent: React.FC<Props> = ({
  onChange,
  apiPropertyName = 'Cycle',
  options = [
    { Name: 'filter.title.cycle.rolling', Id: 1 },
    { Name: 'filter.title.cycle.building', Id: 2 },
    { Name: 'filter.title.cycle.currentDay', Id: 3 },
    { Name: 'filter.title.cycle.published', Id: 4 },
  ],
  reportLabel = 'filter.title.cycle.label',
  disabled = false,
  initialValue,
}) => {
  const { t } = useTranslation();

  const handleSelect = (event: { value: unknown }) => {
    const value = event.value as ReportCycle;
    if (value) {
      onChange({ value: { [apiPropertyName]: value.Id } });
    }
  };

  const translate = (titleValue: string) => {
    return titleValue === 'filter.title.cycle.label' ? `${t(titleValue)}: ` : titleValue;
  };

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const getSelectedValue = (value: number) => {
    let val = options.find((v: any) => v.Id === value);
    if (!val) {
      val = options[0];
    }
    return val;
  };

  return (
    <>
      <GenericSelect
        title={translate(reportLabel)}
        data={options}
        onChange={handleSelect}
        initialValue={getSelectedValue(initialValue)}
        disabled={disabled}
      />
    </>
  );
};

export default ReportCycleComponent;
