import { AgGridColumn } from '@ag-grid-community/react';
import DayPartsCellRenderer from 'components/DetailGrid/CellRenderers/DayPartsCellRenderer';
import DayPartsHeaderComponent from 'components/DetailGrid/HeaderComponents/DayPartsHeaderComponent';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import ReportResult from 'services/ReportResult';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import { formatDate } from 'utils/dates';
import { convertWithoutFloorToPercentage } from 'utils/maths/maths';
import { ExtendedSongAnalysisFilter } from './utils/SongAnalysisTypes';
import { SevenDayGroupOwnerHistoryItem } from './utils/GroupOwnerHistoryTypes';

export interface GroupOwnerHistoryGridProps {
  filters: ExtendedSongAnalysisFilter;
}

const url = '/seven-day-song-analysis-song-group-owner-history-reports';
const GroupOwnerHistoryGrid: React.FC<GroupOwnerHistoryGridProps> = ({ filters }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({ sortPath: 'SpinsToDate', sort: 'asc' });
  const { data: reportData, isLoading, settings, setSettings, isError } = useRemoteApi<
    ReportResult<SevenDayGroupOwnerHistoryItem>
  >(url, {
    data: filters,
    method: 'POST',
    enabled: false,
  });

  return (
    <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
      <ServerSideReportGrid sortModel={sortModel} settings={settings} setSettings={setSettings} filters={filters}>
        <AgGridColumn
          field="GroupOwnerName"
          minWidth={140}
          width={140}
          headerName={t(`grid.header.Group`)}
          headerComponentParams={{ sortModel, onSortChange }}
        />
        <AgGridColumn
          field="FormatName"
          minWidth={80}
          width={80}
          headerName={t(`grid.header.FormatName`)}
          headerComponentParams={{
            sortModel,
            onSortChange,
            disposition: 'row',
          }}
        />
        <AgGridColumn
          field="TotalImpressions"
          minWidth={100}
          width={100}
          valueFormatter={({ value }: ValueFormatterParams) => convertWithoutFloorToPercentage(value, 2)}
          headerName={t(`grid.header.PercentOfTotalPlay`)}
          headerComponentParams={{ sortModel, onSortChange }}
        />
        <AgGridColumn
          field="SpinsToDate"
          minWidth={100}
          width={100}
          headerName={t(`grid.header.SpinsToDate`)}
          headerComponentParams={{
            sortModel,
            onSortChange,
            disposition: 'row',
          }}
        />
        <AgGridColumn
          headerName="Day Parts"
          field="DaypartsInfo"
          width={230}
          minWidth={230}
          headerComponentFramework={DayPartsHeaderComponent}
          headerComponentParams={{ sortModel, onSortChange }}
          cellRendererFramework={DayPartsCellRenderer}
        />
        <AgGridColumn
          field="FirstPlayedDate"
          valueFormatter={({ value }: ValueFormatterParams) => formatDate(value)}
          headerName={t(`grid.header.FirstPlayedDate`)}
          minWidth={130}
          headerComponentParams={{ sortModel, onSortChange }}
        />
      </ServerSideReportGrid>
    </GridVisibilityWrapper>
  );
};

export default GroupOwnerHistoryGrid;
