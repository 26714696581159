import { AgGridColumn } from '@ag-grid-community/react';
import RankInfoCellRendererRec from 'components/DetailGrid/CellRenderers/RankInfoCellRendererRec/RankInfoCellRendererRec';
import SongCellRenderer from 'components/DetailGrid/CellRenderers/SongCellRenderer/SongCellRenderer';
import ThisLastMoveCellRenderer from 'components/DetailGrid/CellRenderers/ThisLastMoveCellRenderer';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import ThisLastMoveHeaderComponent from 'components/DetailGrid/HeaderComponents/ThisLastMoveHeaderComponent';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import { UseApiSettings } from 'hooks/useApi/ApiTypes';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import React, { Dispatch, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import { FilterParams } from 'types/FilterParams';
import { CountryFormatCodeEnum } from 'types/Format';
import ActivactorReportDetails from './ActivactorChartDetailsGrid';
import { ActivactorChartReportTransformer, normalizeFilters } from './utils/ActivactorChartReportTransform';
import { Items } from './utils/ActivactorChartTypes';

interface ActivactorChartGridProps {
  filters: FilterParams | undefined;
  detailGrid?: ReactElement;
}

const url = 'seven-day-activator-chart-reports';
const countryFormatsCode = Object.values(CountryFormatCodeEnum) as string[];

const ActivactorChartGrid: React.FC<ActivactorChartGridProps> = ({ filters }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({ sortPath: 'RankThisWeek', sort: 'asc' });
  const { data: reportData, isLoading, setSettings, settings, isError } = useRemoteApi<ReportResult<Items>>(url, {
    data: filters,
    params: { $top: 1000, $skip: 0, $inlinecount: 'allpages' },
    method: 'POST',
    enabled: !!filters,
    transformResponse: ActivactorChartReportTransformer,
    transformRequest: data => normalizeFilters(data),
  });

  return (
    <>
      <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
        <ServerSideReportGrid
          setSettings={(setSettings as unknown) as Dispatch<UseApiSettings<ReportResult<unknown>>>}
          settings={(settings as unknown) as UseApiSettings<ReportResult<unknown>>}
          filters={filters}
          sortModel={sortModel}
          expandCellRendererProps={{
            footerElement: filters && reportData && <ActivactorReportDetails filters={filters} />,
          }}
        >
          <AgGridColumn
            field="PeakRank"
            minWidth={50}
            width={50}
            headerName={t(`grid.header.PeakRank`)}
            headerComponentParams={{
              sortModel,
              onSortChange,
              disposition: 'row',
            }}
          />
          <AgGridColumn
            headerName={t('grid.header.Rank')}
            minWidth={60}
            width={60}
            field="RankInfo"
            headerComponentParams={{
              sortModel,
              onSortChange,
              disposition: 'row',
              secondLine: [
                { label: 'grid.header.TW', sortPath: 'RankThisWeek' },
                { label: 'grid.header.LW', sortPath: 'RankLastWeek' },
              ],
            }}
            cellRendererParams={{
              fields: ['Rank.ThisWeek', 'Rank.LastWeek'],
            }}
            cellRendererFramework={RankInfoCellRendererRec}
            headerComponentFramework={MultiColumnsHeaderComponent}
          />
          <AgGridColumn
            field="SongInfo"
            headerName={t(`grid.header.Title`)}
            minWidth={160}
            pinned
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              sortPath: 'Title',
              onSortChange,
              disposition: 'row',
              secondLine: [
                { sortPath: 'Artist', label: 'grid.header.Artist' },
                { sortPath: 'Label', label: 'grid.header.Label' },
              ],
            }}
            cellRendererFramework={SongCellRenderer}
          />
          <AgGridColumn
            field="WeeksOnChart"
            minWidth={60}
            width={60}
            headerName={t(`grid.header.WeeksOnChart`)}
            headerComponentParams={{
              sortModel,
              onSortChange,
              disposition: 'row',
            }}
          />
          <AgGridColumn
            field="PointsInfo"
            minWidth={95}
            headerName={t(`grid.header.PointsInfoTW`)}
            cellRendererFramework={ThisLastMoveCellRenderer}
            headerComponentFramework={ThisLastMoveHeaderComponent}
            headerComponentParams={{ sortModel, onSortChange }}
            hide={!countryFormatsCode.includes(filters?.FormatInfo?.FormatCode.toString() ?? '')}
          />
          <AgGridColumn
            field="SpinsInfo"
            minWidth={80}
            width={80}
            headerName={t(`grid.header.SpinsInfoTotal`)}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              disposition: 'row',
              secondLine: [
                { sortPath: 'SpinsInfo/ThisWeek', label: ['grid.header.TW'] },
                { sortPath: 'SpinsInfo/LastWeek', label: ['grid.header.LW'] },
                { sortPath: 'SpinsInfo/Move', label: ['grid.header.Move'] },
              ],
            }}
            cellRendererFramework={ThisLastMoveCellRenderer}
          />
          <AgGridColumn
            field="StationsCount"
            minWidth={60}
            width={60}
            headerName={t(`grid.header.stationsCount`)}
            headerComponentParams={{
              sortModel,
              onSortChange,
              disposition: 'row',
            }}
          />
          <AgGridColumn
            field="AddsCount"
            minWidth={60}
            width={60}
            headerName={t(`grid.header.addsCount`)}
            headerComponentParams={{
              sortModel,
              onSortChange,
              disposition: 'row',
            }}
          />
        </ServerSideReportGrid>
      </GridVisibilityWrapper>
    </>
  );
};

export default ActivactorChartGrid;
