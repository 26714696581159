/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import SelectInput from 'components/SelectInput';
import { UseApiSettings } from 'hooks/useApi/ApiTypes';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import { TFunction } from 'i18next';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import FilterDataEvent from 'types/FilterDataEvent';
import { FormatInfo } from 'types/Format';
import { SongInfo } from 'types/SongInfo';
import { SongPortfolio } from 'types/SongPortfolio';
import { StationPortfolio } from 'types/StationPortfolio';
import { ContainerGrid } from '../TrendingAnalysisByStationReport/styles';
import Details from './DetailsReport';
import FormatSummary from './FormatSummary';
import NationalSummary from './NationalSummary';
import { TrendingAnalysisReport } from './utils/TrendingAnalysisReport';
import TrendingAnalysisTransformer from './utils/TrendingAnalysisTransformer';

export interface Trending {
  Cycle?: number;
  IsPublishedStationsOnly?: boolean;
  ShowImpressions?: boolean;
  SearchMode?: number;
  StationPortfolio?: StationPortfolio | null;
  StationPortfolioGroup?: StationPortfolio | null;
  StationSelectionType?: number;
  SpinMode?: number;
  Formats?: FormatInfo[];
}

export interface ExtendedTrending extends Trending {
  stationGroup: string;
  songGroup: string;
  SongPortfolio: SongPortfolio;
  songSelectionSongInfos: SongInfo[];
  songPortfolioSongInfos: SongInfo[];
}

export interface TrendingPropsGrid {
  report: TrendingAnalysisReport;
  translate: TFunction;
  setSettings: React.Dispatch<UseApiSettings<ReportResult<TrendingAnalysisReport>>>;
  settings: UseApiSettings<ReportResult<TrendingAnalysisReport>>;
  filters: FilterDataEvent<Trending> | null;
}

export interface TrendingAnalysisProps {
  filters: FilterDataEvent<Trending> | null;
}

const url = 'regions-markets-trending-analysis-reports';

const TrendingAnalysisSummary: React.FC<TrendingAnalysisProps> = ({ filters }) => {
  const [panel, setPanel] = useState<string>('nationalSummary');
  const { t } = useTranslation();
  const { setSettings, settings, data: reportData, isLoading, isError } = useRemoteApi<
    ReportResult<TrendingAnalysisReport>
  >(url, {
    params: { $top: 1000, $skip: 0, $inlinecount: 'allpages', $orderBy: 'ReportName asc' },
    data: filters,
    method: 'POST',
    enabled: !!filters,
    transformResponse: TrendingAnalysisTransformer,
  });

  const selects = [
    { label: t('grid.header.NationalSummary'), value: 'nationalSummary' },
    { label: t('grid.header.FormatSummary'), value: 'formatSummary' },
    { label: t('grid.header.Details'), value: 'details' },
  ];

  const changePanel = (typePanel: string) => {
    const isOpen = typePanel === panel ? '' : typePanel;
    return setPanel(isOpen);
  };
  return (
    <>
      <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
        <SelectInput
          optionValue="value"
          optionLabel="label"
          options={selects}
          value={panel}
          onChangeValue={changePanel}
        />
        <ContainerGrid>
          {panel === 'nationalSummary' && NationalSummary(reportData as TrendingAnalysisReport, t)}
          {panel === 'formatSummary' && reportData && (
            <FormatSummary
              report={reportData as TrendingAnalysisReport}
              translate={t}
              setSettings={setSettings}
              settings={settings}
              filters={filters}
            />
          )}
          {panel === 'details' && reportData && (
            <Details
              report={reportData as TrendingAnalysisReport}
              translate={t}
              setSettings={setSettings}
              settings={settings}
              filters={filters}
            />
          )}
        </ContainerGrid>
      </GridVisibilityWrapper>
    </>
  );
};

export default TrendingAnalysisSummary;
