import { Collapse, List, ListItem, ListItemText } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import NavigationMenu from 'contexts/NavigationMenuContext';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { NavigationMenuApiResponse } from 'types/menu/NavigationMenuApiResponse';
import { menuEntries as reportMenuEntries } from 'routes/reports';
import { v4 as uuid } from 'uuid';
import { ReportMenuEnum, ReportExternalUrl } from 'pages/Reports/Report';
import { useStyles } from './styles';

interface NavigationMenuComponentProps {
  onLinkClick?: () => void;
}

const getListDevelopedReports = () => (reportMenuEntries ? reportMenuEntries.flatMap(i => i.path) : []);
const getReportsNotCreated = () => [
  ReportMenuEnum.ArchivedMenu,
  ReportMenuEnum.CustomMenu,
  ReportMenuEnum.StationMenu,
  ReportMenuEnum.SongsMenu,
  ReportMenuEnum.YearToDate,
  ReportMenuEnum.TunedInMonitorMenu,
  ReportMenuEnum.StationsMenu,
  ReportMenuEnum.Streaming,
];

const reportsWithExternalLink = [ReportExternalUrl.AddBoardReport];

/* eslint-disable @typescript-eslint/no-explicit-any */
const NavigationMenuComponent: React.FC<NavigationMenuComponentProps> = ({ onLinkClick }) => {
  const classes = useStyles();
  const { navigationMenus } = useContext(NavigationMenu);
  const [developedReports] = useState<string[] | any[]>(getListDevelopedReports());
  const [reportNotCreated] = useState<string[] | any[]>(getReportsNotCreated());
  const [openLevels, setOpenLevels] = useState<number[]>([]);

  const mapMenuItem = (item: NavigationMenuApiResponse) => {
    if (item.Items?.length) {
      return !reportNotCreated.some(r => r === item.Id) && mapBranch(item);
    }
    return (
      (developedReports.some(p => p === item.Url) || reportsWithExternalLink.some(i => i === item.Id)) && mapLeaf(item)
    );
  };

  const mapBranch = (item: NavigationMenuApiResponse) => {
    const id = item.Id;
    const isIn = openLevels.includes(id);
    return (
      <React.Fragment key={uuid()}>
        <ListItem button divider onClick={() => handleOpenItems(id)}>
          <ListItemText primary={item.Name} />
          {isIn ? <Remove color="primary" /> : <Add color="primary" />}
        </ListItem>
        <Collapse in={isIn} unmountOnExit className={classes.nested}>
          <List component="div">{item.Items?.map(mapMenuItem)}</List>
        </Collapse>
      </React.Fragment>
    );
  };
  const mapLeaf = (item: NavigationMenuApiResponse) => {
    // if it's a leaf without an url, there's nothing to do
    if (!item.Url && !item.ExternalUrl) return <></>;
    const isInternalLink = (itemMenu: NavigationMenuApiResponse) => {
      return { component: Link, to: itemMenu.Url };
    };

    return (
      <React.Fragment key={uuid()}>
        <ListItem
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...isInternalLink(item)}
          divider
          className={classes.menuLink}
          onClick={() => handleLinkClick(item)}
        >
          <ListItemText primary={item.Name} color="primary" />
        </ListItem>
      </React.Fragment>
    );
  };

  const handleOpenItems = (id: number) => {
    if (openLevels.includes(id)) {
      const newOpenLevels = [...openLevels].filter(i => i !== id);
      setOpenLevels(newOpenLevels);
    } else {
      setOpenLevels([...openLevels, id]);
    }
  };

  const handleLinkClick = (menuItem: NavigationMenuApiResponse) => {
    if (menuItem.ExternalUrl) {
      window.open(menuItem.ExternalUrl);
    }
    if (onLinkClick) onLinkClick();
    setOpenLevels([]);
  };

  return (
    <>
      <List component="nav" aria-label="navigation menu" className={classes.root}>
        {navigationMenus.map(mapMenuItem)}
      </List>
    </>
  );
};

export default NavigationMenuComponent;
