import { Box, Typography } from '@material-ui/core';
import AppSettings from 'contexts/AppSettingsContext';
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

const PageFooter: FC = () => {
  const { desktopLink, termsOfUseLink, privacyPolicyLink } = useContext(AppSettings);
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <Box className={classes.root}>
        <Box display="flex" justifyContent="center">
          <Typography variant="body1" className="desktop-version">
            <a href={desktopLink} target="_blank" rel="noopener noreferrer">
              {t('page.footer.desktopVersion')}
            </a>
          </Typography>
        </Box>
        <Box className={classes.bottomText}>
          <Box>
            <Typography variant="body2">
              <a href={termsOfUseLink} target="_blank" rel="noopener noreferrer">
                {t('page.footer.termsOfUse')}
              </a>
              <span> | </span>
              <a href={privacyPolicyLink} target="_blank" rel="noopener noreferrer">
                {t('page.footer.privacyPolicy')}
              </a>
            </Typography>
          </Box>
          <Box className={classes.doNotShareText}>
            <Typography variant="body2">
              <a href="https://rcssupport.com/en/privacy/?site=mediabase" target="_blank" rel="noopener noreferrer">
                {t('page.footer.doNotShareMyInfo')}
              </a>
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2">{t('page.footer.disclaimer')}</Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PageFooter;
