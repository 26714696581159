import { FormControlLabel, ListItem, Radio } from '@material-ui/core';
import React from 'react';
import FilterDataEvent from 'types/FilterDataEvent';

type ChildrenType = React.ReactElement<{ disabled?: boolean }>;

export interface ComponentGroupItemProps {
  label: string;
  labelValue?: string;
  children: ChildrenType;
  value?: string;
  getLabel?: (event: FilterDataEvent<unknown>) => string;
}

const ComponentGroupItem: React.FC<ComponentGroupItemProps> = props => {
  const { label, children, value, labelValue = label, getLabel } = props;

  function tapOnChange(func?: (evt: FilterDataEvent<unknown>) => void): (evt: FilterDataEvent<unknown>) => void {
    return (event: FilterDataEvent<unknown>) => {
      if (func) {
        func(event);
      }
      if (getLabel) {
        getLabel(event);
      }
    };
  }

  return (
    <ListItem divider style={{ display: 'block' }}>
      <FormControlLabel label={label} control={<Radio />} value={labelValue || label} />
      {React.cloneElement(children, {
        ...children.props,
        disabled: value !== (labelValue || label),
        onChange: tapOnChange(children.props.onChange),
      })}
    </ListItem>
  );
};

export default ComponentGroupItem;
