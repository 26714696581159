/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { HeaderComponentParams } from 'types/HeaderComponentParams';
import MultiColumnsHeaderComponent from './MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';

interface ThisLastMoveHeaderComponentProps extends HeaderComponentParams {
  displayName: string;
}

const ThisLastMoveHeaderComponent: React.FC<ThisLastMoveHeaderComponentProps> = props => {
  const { displayName, column } = props;
  const { field } = column.getColDef();
  // eslint-disable-next-line react/jsx-props-no-spreading
  return (
    <MultiColumnsHeaderComponent
      {...props}
      displayName={displayName}
      sortPath={`${field}/ThisWeek`}
      disposition="row"
      secondLine={[
        { label: 'grid.header.LW', sortPath: `${field}/LastWeek` },
        { label: 'grid.header.plusMinus', sortPath: `${field}/Move` },
      ]}
    />
  );
};

export default ThisLastMoveHeaderComponent;
