import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      justifyContent: 'flex-end',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
    },
    squarePaper: {
      borderRadius: `0`,
    },
    summary: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      color: theme.palette.primary.main,
    },
  }),
);
