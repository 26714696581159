import React from 'react';

const ClassicViewLayout: React.FC = ({ children }): JSX.Element => {
  const viewport = document.getElementById('meta-viewport');
  if (viewport) {
    viewport.setAttribute('content', 'width=1024');
  }

  return <>{children}</>;
};

export default ClassicViewLayout;
