/* eslint-disable react-hooks/exhaustive-deps */
import NavigationMenu from 'contexts/NavigationMenuContext';
import { MenuItem, TextField } from '@material-ui/core';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import FilterDataProps from 'types/FilterDataProps';
import { FormatInfoChecked } from 'types/Format';
import { ExpandMore } from '@material-ui/icons';
import { Container, FormatLabel, useStyles } from './styles';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props extends FilterDataProps<any> {
  disabled?: boolean;
  panelCode?: string;
  label?: string;
  apiPropertyName?: string;
  isArray?: boolean;
}

const url = `/formats/search`;
const ALL_OPTION = {
  Id: 0,
  Name: 'All',
  FormatCode: 'XX',
};

const FormatSelection: React.FC<Props> = ({
  disabled = false,
  panelCode = 'R',
  onChange = null,
  label = '',
  apiPropertyName = 'StationSearchFormats',
  initialValue = ALL_OPTION,
  isArray = true,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { currentNavigationMenu } = useContext(NavigationMenu);
  const [availableValues, setAvailableValues] = useState<FormatInfoChecked[]>([]);
  const [selectedValue, setSelectedValue] = useState<FormatInfoChecked>();

  const { data: reportData } = useRemoteApi<ReportResult<FormatInfoChecked[]>>(url, {
    data: {
      ReportType: currentNavigationMenu?.ReportType,
      PanelCode: panelCode,
      Year: '0',
    },
    method: 'POST',
    enabled: true,
    transformResponse: data => {
      const dataSort = data.sort((a: FormatInfoChecked, b: FormatInfoChecked) => (a.Name > b.Name ? 1 : -1));
      return [ALL_OPTION, ...dataSort];
    },
  });

  useEffect(() => {
    if (availableValues.length) {
      setSelectedValue(initialValue);
    }
  }, [initialValue, availableValues]);

  useEffect(() => {
    if (onChange && selectedValue && !disabled) {
      let newValues;
      if (isArray) {
        newValues = selectedValue === ALL_OPTION ? availableValues : [selectedValue];
      } else {
        newValues = selectedValue;
      }
      onChange({ value: { [apiPropertyName]: newValues } });
    }
  }, [selectedValue]);

  useEffect(() => {
    const data = (reportData || []) as FormatInfoChecked[];
    setAvailableValues(data);
  }, [reportData]);

  const handleChange = (ev: React.ChangeEvent<{ value: unknown }>): void => {
    const { value } = ev.target;
    const foundOption = availableValues.find(v => v.Id === value);
    setSelectedValue(foundOption);
  };

  const iconComponent = () => {
    return <ExpandMore className={classes.icon} />;
  };

  return (
    <Container>
      <FormatLabel>FORMAT: </FormatLabel>
      <TextField
        select
        label={label}
        value={selectedValue?.Id ?? ''}
        className={classes.root}
        InputProps={{
          classes: {
            input: classes.root,
          },
        }}
        SelectProps={{ IconComponent: iconComponent }}
        onChange={handleChange}
        disabled={disabled}
        aria-label={t('filter.title.Format')}
      >
        {availableValues.map(item => {
          return (
            <MenuItem key={item.Id} value={item.Id}>
              {item.Name}
            </MenuItem>
          );
        })}
      </TextField>
    </Container>
  );
};

export default FormatSelection;
