import styled from 'styled-components';

interface CellSpanProps {
  hourIndex: number;
  width: number;
}

const daypartColorMap = (hour: number): string => {
  if (hour <= 5) return '#9C54B9';
  if (hour >= 6 && hour <= 9) return '#FFC000';
  if (hour >= 15 && hour <= 18) return '#FF7B00';
  if (hour >= 19 && hour <= 23) return '#1441C8';
  return '#303030';
};

export const SpinsSpan = styled.span<CellSpanProps>`
  color: ${({ hourIndex }) => daypartColorMap(hourIndex)};
  display: inline-block;
  width: ${({ width }) => `${width}px`};
`;
