import { AgGridColumn } from '@ag-grid-community/react';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import DayPartsCellRenderer from 'components/DetailGrid/CellRenderers/DayPartsCellRenderer';
import DualValueCellRenderer from 'components/DetailGrid/CellRenderers/DualValueCellRenderer/DualValueCellRenderer';
import StationRankMarketOwnerCellRenderer from 'components/DetailGrid/CellRenderers/StationRankMarketOwnerCellRenderer';
import DayPartsHeaderComponent from 'components/DetailGrid/HeaderComponents/DayPartsHeaderComponent';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import StationRankMarketOwnerHeaderComponent from 'components/DetailGrid/HeaderComponents/StationRankMarketFormatHeaderComponent';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import { ReportModeIndexes } from './ReportModeSelect';
import { ExtendedArtistAnalysisFilter } from './utils/ArtistAnalysisFilter';
import {
  SevenDayArtistAnalysisReport,
  TransformedSevenDayArtistAnalysisReport,
} from './utils/SevenDayArtistAnalysisReports';

interface DefaultDataReportGridProps {
  filters?: ExtendedArtistAnalysisFilter;
  detailGrid?: ReactElement;
}

const url = `/seven-day-artist-analysis-reports`;
const DefaultDataReportGrid: FC<DefaultDataReportGridProps> = ({ filters, detailGrid }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({ sortPath: 'Rank', sort: 'asc' });
  const { setSettings, settings, data: reportData, isLoading, isError } = useRemoteApi<
    ReportResult<TransformedSevenDayArtistAnalysisReport>
  >(url, {
    method: 'POST',
    enabled: false,
    transformResponse: data => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const transform = (v: SevenDayArtistAnalysisReport): TransformedSevenDayArtistAnalysisReport => {
        return {
          ...v,
          ThisWeekImpressionSpin: v?.ImpressionsInfo?.SpinsThisWeek,
          ThisWeekTotalSpins: v?.SpinsInfo?.ThisWeek,
        };
      };
      const items = data.SevenDayItems ?? data.HistoryItems;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return { ...data, Items: items.map((v: SevenDayArtistAnalysisReport) => transform(v)) };
    },
  });

  return (
    <>
      <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
        <ServerSideReportGrid
          setSettings={setSettings}
          settings={settings}
          filters={filters}
          sortModel={sortModel}
          expandCellRendererProps={{ footerElement: detailGrid }}
        >
          <AgGridColumn
            field="Rank"
            headerName={t('grid.header.Rank')}
            minWidth={60}
            maxWidth={60}
            wrapText
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="StationDisplayName"
            headerName={t('grid.header.Station')}
            minWidth={150}
            wrapText
            headerComponentParams={{ sortModel, onSortChange }}
            headerComponentFramework={StationRankMarketOwnerHeaderComponent}
            cellRendererFramework={StationRankMarketOwnerCellRenderer}
          />
          <AgGridColumn
            field="FormatName"
            headerName={t('grid.header.FormatName')}
            minWidth={80}
            maxWidth={120}
            wrapText
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="TitlesCount"
            headerName={t('grid.header.TitlesCount')}
            minWidth={75}
            maxWidth={75}
            wrapText
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="SpinsInfo"
            colId="SpinsInfoOnly"
            headerName={t('grid.header.Spins')}
            minWidth={80}
            maxWidth={80}
            wrapText
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              disposition: 'row',
              secondLine: [
                { sortPath: 'SpinsInfo/ThisWeek', label: t('grid.header.TW') },
                { sortPath: 'SpinsInfo/LastWeek', label: t('grid.header.LW') },
              ],
            }}
            cellRendererFramework={DualValueCellRenderer}
            cellRendererParams={{ topValue: 'ThisWeek', bottomValue: 'LastWeek' }}
            hide={filters?.ReportMode !== ReportModeIndexes.SEVEN_DAY}
          />

          <AgGridColumn
            field="ThisWeekTotalSpins"
            headerName={t('grid.header.TotalSpins')}
            minWidth={80}
            maxWidth={80}
            wrapText
            hide={filters?.ReportMode !== ReportModeIndexes.HISTORY}
          />

          <AgGridColumn
            headerName={t('grid.header.DaypartsInfo')}
            field="DaypartsInfo"
            maxWidth={230}
            minWidth={230}
            headerComponentFramework={DayPartsHeaderComponent}
            headerComponentParams={{ sortModel, onSortChange }}
            cellRendererFramework={DayPartsCellRenderer}
          />
          <AgGridColumn
            headerName={t('grid.header.impression.ImpressionMill')}
            field="ThisWeekImpressionSpin"
            maxWidth={120}
            minWidth={120}
            headerComponentParams={{ sortModel, onSortChange }}
            valueFormatter={({ value }: ValueFormatterParams) => (value || 0.0).toFixed(3)}
          />
        </ServerSideReportGrid>
      </GridVisibilityWrapper>
    </>
  );
};

export default DefaultDataReportGrid;
