import AccordionFilter from 'components/Filter/components/AccordionFilter';
import Grc from 'components/Filter/components/Grc';
import PastDays, { getCurrentBuildingCycle } from 'components/Filter/components/PastDays';
import ReportCycleComponent from 'components/Filter/components/ReportCycle';
import StationSelect from 'components/Filter/components/StationSelect/SelectedStation';
import FilterBase from 'components/FilterBase';
import useDeepLink from 'hooks/useDeepLink/useDeepLink';
import { defaultFilterTransformer } from 'hooks/useDeepLink/utils/defaultFilterTransformer';
import useFilterState from 'hooks/useFilterState/useFilterState';
import useStickyFilter from 'hooks/useStickyFilter/useStickyFilter';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FilterDataEvent from 'types/FilterDataEvent';
import { Station } from 'types/Station';
import { getStationDisplayName } from 'utils/reports/GetStationDisplayName';
import {
  CurrentDayMonitorsFilter,
  ExtendedCurrentDayMonitorsFilter,
  StationReportTypeEnum,
} from './utils/CurrentDayMonitorTypes';

interface FilterCurrentDayMonitorsReport {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  changeFilter: { (filters: CurrentDayMonitorsFilter): any };
  isOpened: boolean;
}

const Filter: React.FC<FilterCurrentDayMonitorsReport> = ({ changeFilter, isOpened }) => {
  const pathname = getReportSpec(ReportBaseEnum.CurrentDayMonitorsReport).stickyKey;

  const { savedFilters: filter, saveFilter } = useStickyFilter<ExtendedCurrentDayMonitorsFilter>(pathname);
  const { isReady: isFilterReady } = useFilterState();

  // eslint-disable-next-line no-shadow
  const handleOnSubmit = (filter?: ExtendedCurrentDayMonitorsFilter) => {
    if (filter) {
      changeFilter(filter);
    }
  };

  // eslint-disable-next-line no-shadow
  const { hasDeepLinkInfo } = useDeepLink(filter, saveFilter, isFilterReady, {
    onReady: handleOnSubmit,
    // eslint-disable-next-line no-shadow
    filterTransformer: (queryString: Map<string, string>, filter: ExtendedCurrentDayMonitorsFilter) => {
      const newFilter = defaultFilterTransformer(queryString, filter);
      return {
        ...newFilter,
        Stations: [{ ...(newFilter?.StationInfo as Station) }],
      } as ExtendedCurrentDayMonitorsFilter;
    },
  });

  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleStationSelectionChanges = (event: FilterDataEvent<object>) => {
    const { value } = event;
    delete filter?.value;
    saveFilter({ ...filter, ...value } as ExtendedCurrentDayMonitorsFilter);
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleFilterChange = (event: FilterDataEvent<object>) => {
    const { value } = event;
    saveFilter({ ...filter, ...value } as ExtendedCurrentDayMonitorsFilter);
  };

  const options = [
    { Name: t('filter.title.buildingReport'), Id: StationReportTypeEnum.BuildingReport },
    { Name: t('filter.title.dailyReport'), Id: StationReportTypeEnum.DailyReport },
  ];

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const getStationsLabel = (value: any): string => {
    let result = '';
    if (value != null && value.Stations.length > 0) {
      result = value.Stations.length > 1 ? t('filter.label.various') : getStationDisplayName(value.Stations[0]);
    }
    return result;
  };

  return (
    <FilterBase onSubmit={() => handleOnSubmit(filter)} isOpened={isOpened}>
      <ReportCycleComponent
        onChange={handleFilterChange}
        initialValue={filter?.ReportCycle}
        apiPropertyName="ReportCycle"
        options={options}
        reportLabel={`${t('filter.title.ReportType')} :`}
      />
      <AccordionFilter title={t('filter.title.stations')} getLabel={({ value }) => getStationsLabel(value)}>
        <StationSelect
          title="Station Select"
          onChange={handleStationSelectionChanges}
          initialValue={filter?.Stations}
          key={`StationSelect${pathname}`}
          callLetters={hasDeepLinkInfo ? filter?.callLetters : undefined}
        />
      </AccordionFilter>

      <PastDays
        hasTodayOption
        lowerBound={1}
        upperBound={7}
        onChange={handleFilterChange}
        disabled={!(filter?.ReportCycle === StationReportTypeEnum.BuildingReport)}
        initialValue={getCurrentBuildingCycle()}
      />
      <Grc
        onChange={handleFilterChange}
        initialValue={filter?.GrcInfo}
        disabled={!(filter?.ReportCycle === StationReportTypeEnum.BuildingReport)}
        showAll
      />
    </FilterBase>
  );
};

export default Filter;
