/* eslint-disable react/no-danger */
import { Grid } from '@material-ui/core';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { IInnerTableGroup } from './inner-table-group';
import { BodyTableElement } from './styles';

type InnerTableBodyProps = {
  data: IInnerTableGroup[];
  direction: string;
};

const InnerTableBody: React.FC<InnerTableBodyProps> = ({ data, direction = 'column' }: InnerTableBodyProps) => {
  const transpose = <T extends unknown>(value: T[][]): T[][] => {
    return value[0].map((_, colIndex) => value.map(row => row[colIndex]));
  };

  const extractValues = (groups: IInnerTableGroup[], output: JSX.Element[][]): JSX.Element[][] => {
    groups.forEach(value => {
      const { children } = value;
      if (typeof children[0] === 'object' && 'children' in children[0]) {
        extractValues(children as IInnerTableGroup[], output);
      } else {
        output.push(children as JSX.Element[]);
      }
    });

    return output;
  };

  const getElement = (el: JSX.Element | string) => {
    if (typeof el === 'string') {
      return <div dangerouslySetInnerHTML={{ __html: el }} />;
    }
    return el;
  };
  return (
    <tbody>
      <tr style={{ width: '100%' }}>
        <td style={{ width: '100%' }}>
          <Grid container key={Math.random()}>
            {transpose(extractValues(data, [])).map(row => {
              return (
                <BodyTableElement direction={direction} key={uuidv4()}>
                  <div key={Math.random()}>
                    {row[0]}
                    {direction === 'row' && ':'}
                  </div>
                  <div key={Math.random()}>{getElement(row[1])}</div>
                </BodyTableElement>
              );
            })}
          </Grid>
        </td>
      </tr>
    </tbody>
  );
};

export default InnerTableBody;
