/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import FilterDataEvent from 'types/FilterDataEvent';
import ReportResult from 'services/ReportResult';
import SelectInput from 'components/SelectInput';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import { UseApiSettings } from 'hooks/useApi/ApiTypes';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import DetailsGrid from './DetailsGrid';
import SongSummaryGrid from './SongSummary';
import { ContainerGrid } from './styles';
import {
  RegionsMarketsTrendingAnalysis,
  RegionsMarketsTrendingAnalysisFilter,
} from './utils/RegionsMarketsTrendingAnalysisByStationReports';
import { transformRegionMarketsTrendingAnalysisByStationFilters } from './utils/RegionsMarketsTrendingAnalysisReportTransformer';

export interface TrendingAnalysisByStationProps {
  filters: FilterDataEvent<RegionsMarketsTrendingAnalysisFilter> | null;
}

export interface TrendingAnalysisByStationGridProps {
  report: RegionsMarketsTrendingAnalysis;
  filters: TrendingAnalysisByStationProps['filters'];
  settings: UseApiSettings<ReportResult<RegionsMarketsTrendingAnalysis>>;
  setSettings: React.Dispatch<UseApiSettings<ReportResult<RegionsMarketsTrendingAnalysis>>>;
}

const optionGrid = [
  {
    name: 'report.tab.songSummary',
    value: 'songSummary',
  },
  {
    name: 'report.tab.details',
    value: 'details',
  },
];

const url = 'regions-markets-trending-analysis-by-station-reports';

const TrendingAnalysisByStationReport: React.FC<TrendingAnalysisByStationProps> = ({ filters }) => {
  const [panel, setPanel] = useState('songSummary');

  const { settings, setSettings, data: reportData, isLoading, isError } = useRemoteApi<
    ReportResult<RegionsMarketsTrendingAnalysis>
  >(url, {
    data: filters,
    method: 'POST',
    enabled: false,
    transformRequest: transformRegionMarketsTrendingAnalysisByStationFilters,
  });

  const changePanel = (typePanel: string) => {
    const isOpen = typePanel === panel ? '' : typePanel;
    return setPanel(isOpen);
  };

  return (
    <>
      <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
        <SelectInput
          optionValue="value"
          optionLabel="name"
          options={optionGrid}
          value={panel}
          onChangeValue={changePanel}
        />
        <ContainerGrid>
          {panel === 'songSummary' && filters && reportData && (
            <SongSummaryGrid setSettings={setSettings} settings={settings} filters={filters} report={reportData} />
          )}
          {panel === 'details' && filters && reportData && (
            <DetailsGrid report={reportData} settings={settings} setSettings={setSettings} filters={filters} />
          )}
        </ContainerGrid>
      </GridVisibilityWrapper>
    </>
  );
};

export default TrendingAnalysisByStationReport;
