/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import FilterDataProps from 'types/FilterDataProps';
import { GrcInfo } from 'types/GrcInfo';
import GrcInfoService from '../../../../services/Filters/GrcInfoService';
import GenericSelect from '../GenericSelect';

interface Props<T extends GrcFilterData> extends FilterDataProps<T> {
  disabled?: boolean;
  required?: boolean;
}

export interface GrcFilterData {
  GrcInfo: GrcInfo | undefined;
}

const Grc = <T extends GrcFilterData>({
  onChange,
  onlyShowThisElements = [],
  initialValue,
  showAll = false,
  disabled = false,
  required = false,
}: Props<T>): ReactElement => {
  const service = new GrcInfoService();
  const { t } = useTranslation();

  const filterGrcCodes = (grcs: GrcInfo): boolean => {
    return !onlyShowThisElements?.length || onlyShowThisElements.includes(grcs.GrcCode);
  };

  const availableData = showAll
    ? service.getGrcInfoAllSync().filter(filterGrcCodes)
    : service.getGrcInfoSync().filter(filterGrcCodes);

  const handleSelect = (event: { value: unknown }) => {
    const value = event.value as GrcInfo;
    onChange({ value: { GrcInfo: value } as T });
  };

  const getDefaultValue = (): GrcInfo => {
    const result = initialValue != null ? initialValue : availableData[0];
    return result;
  };

  return (
    <GenericSelect
      title={t('grid.header.Grc')}
      data={availableData}
      onChange={handleSelect}
      initialValue={getDefaultValue()}
      disabled={disabled}
      required={required}
    />
  );
};

export default Grc;
