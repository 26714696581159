/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColDef, Column, ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import { get } from 'lodash';
import React from 'react';
import { formatDate } from 'utils/dates';
import { v4 as uuidv4 } from 'uuid';
import InnerTable from '..';
import { IInnerTableGroup } from '../inner-table-group';

interface NotGroupedInnerTableProps {
  columns: Column[];
  data: any;
}

const verifyRegexIfIsADate = (date: string): boolean => {
  // If the string has match as '2020-02-02'
  return new RegExp(/\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])*/g).test(date);
};

const NotGroupedInnerTable: React.FC<NotGroupedInnerTableProps> = ({ columns, data }: NotGroupedInnerTableProps) => {
  const transpose = <T extends unknown>(value: T[][]): T[][] => {
    return value[0].map((_, colIndex) => value.map(row => row[colIndex]));
  };

  const getValue = (value: any, colDef: ColDef): string => {
    if (verifyRegexIfIsADate(value)) {
      return formatDate(value as string);
    }

    const { valueFormatter } = colDef;
    if (valueFormatter instanceof Function) {
      return valueFormatter({ ...colDef, value } as ValueFormatterParams);
    }
    return value;
  };

  const getDataGrid = (cols: Column[]) => {
    const dataGrid = cols.map((col: Column) => {
      const fieldName = col.getColId();
      const colDef = col.getColDef();
      const translatedName = colDef.headerName;

      const value = getValue(get(data, fieldName), colDef);
      const cellRendererConfig = colDef.cellRendererFramework;

      if (!cellRendererConfig) {
        return [translatedName, <span key={uuidv4()}>{value}</span>];
      }
      return [translatedName, cellRendererConfig({ value, data })];
    });
    return transpose(dataGrid);
  };

  const innerTableData: IInnerTableGroup[] = [
    {
      title: '',
      children: getDataGrid(columns).map((d: any[]) => {
        return {
          title: '',
          children: d,
        };
      }),
    },
  ];
  return (
    <>
      <InnerTable direction="row" key={uuidv4()} data={innerTableData} />
    </>
  );
};

export default NotGroupedInnerTable;
