/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { HeaderComponentParams } from 'types/HeaderComponentParams';
import MultiColumnsHeaderComponent from './MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';

interface WeeklyHeaderComponentProps extends HeaderComponentParams {
  name: string;
}

const WeeklyHeaderComponent: React.FC<WeeklyHeaderComponentProps> = props => {
  const { name = 'Weekly', column } = props;
  const { field } = column.getColDef();

  const secondLine = ['Week1', 'Move', 'Week2', 'Week3', 'Week4'].map(v => ({
    label: `grid.header.weekly.${v}`,
    sortPath: `${field}/${v}`,
  }));

  return <MultiColumnsHeaderComponent {...props} displayName={name} secondLine={secondLine} usePercentual />;
};

export default WeeklyHeaderComponent;
