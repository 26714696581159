import ReportResult from "services/ReportResult";
import { 
    SevenDayAfterMidniteChartReport, 
    SevenDayAfterMidniteChartDataItem, 
    TransformedSevenDayAfterMidniteChartDataItem
} from "./types/SevenDayAfterMidniteChartReport";

const transformItems = (row: SevenDayAfterMidniteChartDataItem): TransformedSevenDayAfterMidniteChartDataItem => 
{
    return {
        ...row,
        SongInfo: {
            Artist: row.Artist,
            Label: row.Label,
            Title: row.Title,
        },
        RankInfo: {
            ThisWeek: row.RankThisWeek,
            LastWeek: row.RankLastWeek,
        },
    };
};

export const AfterMidniteChartTransformer = 
    (response: SevenDayAfterMidniteChartReport): ReportResult<TransformedSevenDayAfterMidniteChartDataItem> => 
{
    return {
        ...response,
        Items: response.Items.map(transformItems),
    };
};