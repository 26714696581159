import FilterButton from 'components/FilterButton';
import ReportHeader from 'components/ReportHeader';
import SelectInput from 'components/SelectInput';
import SiblingReportsSelect from 'components/SiblingReportsSelect/SiblingReportsSelect';
import { BodyReportContainer, Container, GridContainer, ReportContainer, SongsContainer } from 'pages/Reports/styles';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StationsInfo } from 'types/StationInfo';
import { getStationDisplayName } from 'utils/reports/GetStationDisplayName';
import ArtistByStationGrid from './ArtistByStationGrid';
import Filter from './Filter';
import { ExtendedArtistByStationFilter } from './utils/types';

const ArtistByStationReport: FC<{}> = () => {
  const { t } = useTranslation();

  const [filterOpened, setFilterOpened] = useState(false);
  const [filter, setFilter] = useState<ExtendedArtistByStationFilter>();

  const onChangeFilter = (filters: ExtendedArtistByStationFilter) => {
    if (filters.Stations?.length) {
      setFilter({ ...filters, StationInfo: filters.Stations[0] });
      setFilterOpened(false);
    }
  };

  const onChangeStation = (value: StationsInfo) => {
    setFilter({ ...filter, StationInfo: value });
  };

  return (
    <>
      <Container container direction="column" spacing={0}>
        <ReportHeader title={t('report.label.stationMonitor')} subtitle={t('report.label.sevenDay')} />
        <ReportContainer>
          <SiblingReportsSelect />
          <FilterButton onClickFilter={() => setFilterOpened(!filterOpened)} isOpened={filterOpened} />
        </ReportContainer>
        <Filter isOpened={filterOpened} changeFilter={onChangeFilter} />
        <BodyReportContainer hidden={!filter}>
          <SongsContainer>
            <SelectInput
              normalizeLabel={option => `${getStationDisplayName(option)}`}
              optionLabel={t('filter.title.station')}
              options={filter?.Stations ?? []}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              value={filter?.StationInfo ?? ''}
              onChangeValue={onChangeStation}
            />
          </SongsContainer>
          <GridContainer>
            <ArtistByStationGrid filters={filter} />
          </GridContainer>
        </BodyReportContainer>
      </Container>
    </>
  );
};

export default ArtistByStationReport;
