import React, { useState } from 'react';
import FilterDataEvent from 'types/FilterDataEvent';
import SelectInput from 'components/SelectInput';
import { Divider, Grid } from '@material-ui/core';
import ReportHeader from 'components/ReportHeader';
import { GridContainer, ReportContainer, SongsContainer, BodyReportContainer } from 'pages/Reports/styles';
import SiblingReportsSelect from 'components/SiblingReportsSelect/SiblingReportsSelect';
import FilterButton from 'components/FilterButton';
import { SongInfo } from 'types/SongInfo';
import { merge } from 'lodash';
import TabsGrid from 'components/TabsGrid';
import TabPanel from 'components/TabsGrid/TabPanel';
import Filter from './Filter';
import StationDetailsGrid from './StationDetailsGrid';
import ExecutiveSummaryGrid from './ExecutiveSummaryGrid';
import { ExtendedSongAnalysisFilter } from './utils/SongAnalysisTypes';
import { SongSelectionType } from 'types/Enums';
import { Container } from './styles';
import ExecutiveVideoSummaryGrid from './ExecutiveVideoSummaryGrid';
import DayPartSummaryGrid from './DayPartSummaryGrid';
import GroupOwnerSummaryGrid from './GroupOwnerSummaryGrid';
import {
  SongAnalysisGrids,
  SongAnalysisTabEnum,
  SongAnalysisTabs,
  SongHistoryGrids,
} from './utils/SongAnalysisContants';
import SongHistoryGrid from './SongHistoryGrid';
import FormatHistoryGrid from './FormatHistoryGrid';
import GroupOwnerHistoryGrid from './GroupOwnerHistoryGrid';
import ArtistHistoryGrid from './ArtistHistoryGrid';

export interface SongAnalysisProps {
  filters: FilterDataEvent<ExtendedSongAnalysisFilter> | null;
}

const SongAnalysisReport: React.FC<SongAnalysisProps> = () => {
  const [panel, setPanel] = useState('stationDetails');
  const [filterOpened, setFilterOpened] = useState(false);
  const [filters, setFilters] = useState<ExtendedSongAnalysisFilter | undefined>();
  const [songs, setSongs] = useState<SongInfo[]>([]);
  const [songSelected, setSongSelected] = useState<SongInfo | string>('');
  const [tabValue, setTabValue] = useState(SongAnalysisTabEnum.SongAnalysis);

  const onChangeTab = (value: number) => {
    if (value === SongAnalysisTabEnum.SongAnalysis) {
      setPanel(SongAnalysisGrids[0].value);
    } else {
      setPanel(SongHistoryGrids[0].value);
    }
    setTabValue(value);
  };

  const onChangeFilter = (filtersParams?: ExtendedSongAnalysisFilter) => {
    setFilterOpened(false);
    const isSongSelectionGroup = filtersParams?.SongSelectionType === SongSelectionType.SongSelection;
    const isSongPortfolioGroup = filtersParams?.SongSelectionType === SongSelectionType.SongPortfolio;
    const hasSongSelectionSongs = isSongSelectionGroup && filtersParams?.songSelectionSongInfos?.length;
    const hasSongPortfolioSongs = isSongPortfolioGroup && filtersParams?.songPortfolioSongInfos?.length;
    if (hasSongSelectionSongs || hasSongPortfolioSongs) {
      let filterSongs: SongInfo[] = [];
      if (hasSongSelectionSongs) {
        filterSongs = filtersParams?.songSelectionSongInfos || [];
      } else if (hasSongPortfolioSongs) {
        filterSongs = filtersParams?.songPortfolioSongInfos || [];
      }
      setSongs(filterSongs);
      const firstSong = filterSongs[0];
      setSongSelected(firstSong);
      setFilters(merge({}, filtersParams, { SongInfo: firstSong }));
    } else {
      setFilters(filtersParams);
    }
  };

  const onChangeFilterByGrid = (songAnalysisFilter: ExtendedSongAnalysisFilter) => {
    setFilters({
      ...filters,
      ...songAnalysisFilter,
    });
  };

  const onChangeSong = (song: SongInfo) => {
    setSongSelected(song);
    setFilters({
      ...filters,
      SongInfo: song,
    });
  };

  function changePanel(typePanel: string) {
    const isOpen = typePanel === panel ? '' : typePanel;
    onChangeFilterByGrid({
      OwnerInfo: undefined,
      StationSearchFormats: undefined,
    });
    return setPanel(isOpen);
  }

  return (
    <Container container direction="column" spacing={0}>
      <ReportHeader title="Songs" subtitle="7-Day" />
      <ReportContainer>
        <SiblingReportsSelect />
        <FilterButton onClickFilter={() => setFilterOpened(!filterOpened)} isOpened={filterOpened} />
      </ReportContainer>
      <Filter isOpened={filterOpened} changeFilter={onChangeFilter} />
      <BodyReportContainer hidden={!filters}>
        <SongsContainer>
          <SelectInput
            normalizeLabel={option => `${option.Artist}-${option.Title}`}
            optionLabel="Title"
            options={songs}
            value={songSelected}
            onChangeValue={onChangeSong}
          />
        </SongsContainer>
        <TabsGrid tabValue={tabValue} onChangeTab={onChangeTab} tabs={SongAnalysisTabs}>
          <TabPanel value={tabValue} index={SongAnalysisTabEnum.SongAnalysis}>
            <GridContainer>
              <SelectInput
                optionValue="value"
                optionLabel="name"
                options={SongAnalysisGrids}
                value={panel}
                onChangeValue={changePanel}
              />
              <Divider />
              <Grid>
                {panel === 'stationDetails' && filters && (
                  <StationDetailsGrid filters={filters} onChangeFilter={onChangeFilterByGrid} />
                )}
                {panel === 'executiveSummary' && filters && (
                  <ExecutiveSummaryGrid filters={filters} onChangeFilter={onChangeFilterByGrid} />
                )}
                {panel === 'executiveVideoSummary' && filters && (
                  <ExecutiveVideoSummaryGrid filters={filters} onChangeFilter={onChangeFilterByGrid} />
                )}
                {panel === 'dayPartSummary' && filters && (
                  <DayPartSummaryGrid filters={filters} onChangeFilter={onChangeFilterByGrid} />
                )}
                {panel === 'groupOwnerSummary' && filters && (
                  <GroupOwnerSummaryGrid filters={filters} onChangeFilter={onChangeFilterByGrid} />
                )}
              </Grid>
            </GridContainer>
          </TabPanel>
          <TabPanel value={tabValue} index={SongAnalysisTabEnum.SongHistory}>
            <GridContainer>
              <SelectInput
                optionValue="value"
                optionLabel="name"
                options={SongHistoryGrids}
                value={panel}
                onChangeValue={changePanel}
              />
              <Divider />
              <Grid>
                {panel === 'songHistory' && filters && (
                  <SongHistoryGrid filters={filters} onChangeFilter={onChangeFilterByGrid} />
                )}
                {panel === 'formatHistory' && filters && <FormatHistoryGrid filters={filters} />}
                {panel === 'groupOwnerHistory' && filters && <GroupOwnerHistoryGrid filters={filters} />}
              </Grid>
            </GridContainer>
          </TabPanel>
          <TabPanel value={tabValue} index={SongAnalysisTabEnum.ArtistHistory}>
            <GridContainer>
              <Grid>
                <ArtistHistoryGrid filters={filters} />
              </Grid>
            </GridContainer>
          </TabPanel>
        </TabsGrid>
      </BodyReportContainer>
    </Container>
  );
};

export default SongAnalysisReport;
