import React from 'react';

interface StationRankMarketFormatProps {
  data: {
    StationName: string;
    StationDisplayName: string;
    MarketName: string;
    OwnerName: string;
    MarketRank: number;
  };
}

const StationRankMarketOwnerCellRenderer: React.FC<StationRankMarketFormatProps> = ({
  data,
}: StationRankMarketFormatProps) => {
  const getComponent = () => {
    if (!data) {
      return <></>;
    }
    return (
      <div style={{ lineHeight: '18px', width: '100%' }}>
        <span>{data?.StationDisplayName}</span>
        <span>{`, ${data?.MarketRank}`}</span>
        <br />
        <span>{`${data.MarketName}, ${data?.OwnerName}`}</span>
      </div>
    );
  };
  return getComponent();
};

export default StationRankMarketOwnerCellRenderer;
