/* eslint-disable @typescript-eslint/no-explicit-any */
import ComponentGroup from 'components/Filter/components/ComponentGroup';
import ComponentGroupItem from 'components/Filter/components/ComponentGroup/ComponentGroupItem';
import Grc from 'components/Filter/components/Grc';
import PanelFilter from 'components/Filter/components/PanelFilter';
import PastDays, { getCurrentBuildingCycle } from 'components/Filter/components/PastDays';
import ReportCycle from 'components/Filter/components/ReportCycle';
import ShowTopSelect from 'components/Filter/components/ShowTopSelect';
import StationPortfolioGroupSelect from 'components/Filter/components/StationPorfolioGroup';
import StationPortfolioSelect from 'components/Filter/components/StationPortfolioSelect';
import StationSelect from 'components/Filter/components/StationSelect/SelectedStation';
import FilterBase from 'components/FilterBase';
import useStickyFilter from 'hooks/useStickyFilter/useStickyFilter';
import { get } from 'lodash';
import { StationReportTypeEnum } from 'pages/Reports/DailyCurrent/StationMonitor/CurrentDayMonitorsReport/utils/CurrentDayMonitorTypes';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FilterDataEvent from 'types/FilterDataEvent';
import { Station } from 'types/Station';
import { STATION_PORTFOLIO, STATION_PORTFOLIO_GROUP, STATION_SELECTION } from 'utils/Constants';
import { getStationDisplayName } from 'utils/reports/GetStationDisplayName';
import { ExtendedStationPlaylistsFilter, StationPlaylistsFilter, StationTypeEnum } from './utils/StationPlaylistsTypes';

interface FilterStationPlaylistReport {
  changeFilter: { (filters: StationPlaylistsFilter): any };
  isOpened: boolean;
}

const Filter: React.FC<FilterStationPlaylistReport> = ({ changeFilter, isOpened }) => {
  const pathname = getReportSpec(ReportBaseEnum.StationPlaylistsReport).stickyKey;

  const { savedFilters: filter, saveFilter } = useStickyFilter<ExtendedStationPlaylistsFilter>(pathname);
  const { t } = useTranslation();

  const handleOnSubmit = (f?: StationPlaylistsFilter) => {
    if (f) {
      changeFilter(f);
    }
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleFilterChange = (event: FilterDataEvent<object>) => {
    const { value } = event;
    saveFilter({ ...value } as ExtendedStationPlaylistsFilter);
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleStationChanges = (event: FilterDataEvent<object>) => {
    const { value } = event;
    saveFilter({ ...value } as ExtendedStationPlaylistsFilter);
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleFilterChangeReportCycle = (event: FilterDataEvent<object>) => {
    const { value } = event;

    if ((value as any).ReportCycle !== StationReportTypeEnum.BuildingReport) {
      delete filter?.NumDays;
    }
    saveFilter({ ...value } as ExtendedStationPlaylistsFilter);
  };

  const handleFilterChangeStationGroup = (event: FilterDataEvent<string>) => {
    const { value } = event;
    if (!value) return;

    let StationSelectionType = StationTypeEnum.StationSelected;
    if (value === STATION_PORTFOLIO) {
      StationSelectionType = StationTypeEnum.StationPortfolioSelected;
    } else if (value === STATION_PORTFOLIO_GROUP) {
      StationSelectionType = StationTypeEnum.StationPortfolioGroupSelected;
    }
    saveFilter({ ...{ stationGroup: value }, StationSelectionType });
  };

  const getStationsLabel = ({ value }: FilterDataEvent<unknown>): string => {
    const stations = get(value, 'Stations') as Station[] | undefined;
    if (stations && stations.length) {
      return stations.length === 1 ? getStationDisplayName(stations[0]) ?? '' : t('filter.label.various');
    }
    return '';
  };

  const options = [
    { Name: 'Published', Id: 1 },
    { Name: 'Rolling', Id: 2 },
    { Name: 'Building', Id: 3 },
    { Name: '5 Days', Id: 4 },
  ];

  return (
    <FilterBase onSubmit={() => handleOnSubmit(filter)} isOpened={isOpened}>
      <ReportCycle
        onChange={handleFilterChangeReportCycle}
        initialValue={filter?.ReportCycle}
        options={options}
        apiPropertyName="ReportCycle"
      />
      <PastDays
        lowerBound={1}
        upperBound={7}
        onChange={handleFilterChange}
        disabled={!(filter?.ReportCycle === StationReportTypeEnum.BuildingReport)}
        initialValue={getCurrentBuildingCycle()}
      />
      <PanelFilter onChange={handleFilterChange} initialValue={filter?.PanelInfo} />
      <ComponentGroup
        title={t('filter.title.stations')}
        onChange={handleFilterChangeStationGroup}
        initialValue={filter?.stationGroup ?? STATION_SELECTION}
      >
        <ComponentGroupItem
          label={t('filter.title.stationsSelection')}
          getLabel={getStationsLabel}
          labelValue={STATION_SELECTION}
        >
          <StationSelect
            title="Station Select"
            onChange={handleStationChanges}
            initialValue={filter?.Stations}
            callLetters={filter?.callLetters}
          />
        </ComponentGroupItem>
        <ComponentGroupItem
          label={t('filter.title.stationPortfolio')}
          getLabel={({ value }) => get(value, 'StationPortfolio.Name')}
          labelValue={STATION_PORTFOLIO}
        >
          <StationPortfolioSelect onChange={handleStationChanges} initialValue={filter?.StationPortfolio} />
        </ComponentGroupItem>
        <ComponentGroupItem
          label={t('filter.title.stationPortfolioGroup')}
          getLabel={({ value }) => get(value, 'StationPortfolioGroup.Name')}
          labelValue={STATION_PORTFOLIO_GROUP}
        >
          <StationPortfolioGroupSelect onChange={handleStationChanges} initialValue={filter?.StationPortfolioGroup} />
        </ComponentGroupItem>
      </ComponentGroup>
      <Grc onChange={handleFilterChange} initialValue={filter?.GrcInfo} showAll />
      <ShowTopSelect onChange={handleFilterChange} apiPropertyName="TopX" initialValue={filter?.TopX} />
    </FilterBase>
  );
};

export default Filter;
