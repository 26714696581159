import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      '& .MuiFormControl-root': {
        width: '100%',
      },
      '& .MuiInputBase-root, & .MuiInputBase-input': {
        lineHeight: '40px',
        height: 'auto',
      },
    },
    searchIcon: {
      color: theme.palette.primary.main,
    },
    tableHeaderLeftSpacing: { width: '42px' },
    tableHeaderMainColumn: { width: '74%' },
    tableContainer: {
      maxHeight: 240,
    },
    tableHeader: {
      '& .MuiTableCell-root': { padding: '6px', lineHeight: 'normal', verticalAlign: 'bottom' },
      '& .MuiTypography-body1': { fontWeight: 'bold', fontSize: '12px' },
      '& .MuiTypography-body2': { fontSize: '10px', textTransform: 'uppercase' },
    },
    fullWidth: { width: '100%' },
    tableBody: {
      '& .MuiTableCell-root': { padding: '6px', lineHeight: 'normal', verticalAlign: 'bottom' },
      '& .MuiTypography-body1': { fontWeight: 'bold', fontSize: '12px' },
      '& .MuiTypography-body2': { fontSize: '10px' },
      '& .MuiButton-label': { color: theme.palette.primary.main },
    },
    selectedList: {
      '& .MuiListItem-root': {
        display: 'block',
        '& button': { float: 'right', height: '24px' },
        '& .MuiTypography-body1': { fontWeight: 'bold', fontSize: '12px' },
        '& .MuiTypography-body2': { fontSize: '10px' },
      },
    },
  });
});
