import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { ContainerGrid, ItemGrid } from '../globalStylesCellRenderers';
import defaultTheme from '../../../../themes';

interface SpinDifferencialCellRendererProps {
  value: number;
  showArrows: boolean;
}

const SpinDifferentialCellRenderer: React.FC<SpinDifferencialCellRendererProps> = ({
  value,
  showArrows = true,
}: SpinDifferencialCellRendererProps) => {
  const getArrowUpIcon = () => {
    if (value) {
      return <FontAwesomeIcon icon={faArrowUp} color={defaultTheme.palette.success.main} size="1x" />;
    }
    return '';
  };
  const getArrowDownIcon = () => {
    if (value) {
      return <FontAwesomeIcon icon={faArrowDown} color={defaultTheme.palette.success.main} size="1x" />;
    }
    return '';
  };
  return (
    <ContainerGrid>
      <ItemGrid width="30%">{showArrows && value > 0 && getArrowUpIcon()}</ItemGrid>
      <ItemGrid width="30%">{value}</ItemGrid>
      <ItemGrid width="30%">{showArrows && value < 0 && getArrowDownIcon()}</ItemGrid>
    </ContainerGrid>
  );
};

export default SpinDifferentialCellRenderer;
