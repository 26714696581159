/* eslint-disable jsx-a11y/alt-text */
import AnnouncementCard from 'components/AnnouncementCard';
import React from 'react';
import AdvertiseComponent from 'components/Filter/components/AdvertiseComponent';
import { BodyContainer, Container } from './styles';

const Dashboard: React.FC = () => {
  return (
    <Container>
      <BodyContainer>
        <AdvertiseComponent />
        <AnnouncementCard />
      </BodyContainer>
    </Container>
  );
};

export default Dashboard;
