import { Box, Link, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SongInfo } from 'types/SongInfo';
import { useStyles } from './styles';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface ArtistCellRendererProps {
  value?: SongInfo;
  data?: any;
  reportQuery?: any;
  onClickHandler?: (rowData: any, reportQuery?: any) => void;
}

const ArtistCellRenderer: React.FC<ArtistCellRendererProps> = ({
  value = null,
  data,
  reportQuery = null,
  onClickHandler,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  if (!value) {
    return (
      <>
        <Typography variant="body1">{t(`grid.content.missingArtistInformation`)}</Typography>
      </>
    );
  }

  const getLinkedValue = (title: string, songCode?: string) => {
    if (!onClickHandler || !songCode) {
      return title;
    }
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Link
        onClick={(event: React.MouseEvent) => {
          event.preventDefault();
          onClickHandler(data, reportQuery);
        }}
      >
        {title}
      </Link>
    );
  };

  return (
    <Box className={classes.root}>
      <Typography variant="body1">{getLinkedValue(value.Title, value.SongCode)}</Typography>
      <Typography variant="body2">{value.Artist}</Typography>
    </Box>
  );
};

export default ArtistCellRenderer;
