import { AgGridColumn } from '@ag-grid-community/react';
import DayPartsCellRenderer from 'components/DetailGrid/CellRenderers/DayPartsCellRenderer';
import ThisLastMoveCellRenderer from 'components/DetailGrid/CellRenderers/ThisLastMoveCellRenderer';
import DayPartsHeaderComponent from 'components/DetailGrid/HeaderComponents/DayPartsHeaderComponent';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import { getCurrentSongsReportParams, groupSongHistoryByFormat } from 'services/Reports/CurrentSongsReportService';
import { RealTimeSongAnalysisFilter } from './types/RealTimeSongAnalysis';

interface DaypartSummaryProps {
  filters?: RealTimeSongAnalysisFilter;
}

const url = '/real-time-song-analysis-reports';

const DaypartSummaryGrid: React.FC<DaypartSummaryProps> = ({ filters }) => {
  const { t } = useTranslation();
  const { sortModel } = useSortColumn({ sortPath: 'SpinsInfo/ThisWeek', sort: 'asc' });
  const { setSettings, settings: settingsDayPart, data: reportData, isLoading, isError } = useRemoteApi<
    ReportResult<unknown>
  >(url, {
    method: 'POST',
    enabled: false,
    transformResponse: groupSongHistoryByFormat,
    transformRequest: getCurrentSongsReportParams,
  });

  return (
    <>
      <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
        <ServerSideReportGrid
          setSettings={setSettings}
          settings={settingsDayPart}
          filters={filters}
          sortModel={sortModel}
        >
          <AgGridColumn field="FormatName" headerName="Charts" minWidth={100} sortable={false} wrapText />
          <AgGridColumn
            field="SpinsInfo"
            minWidth={80}
            sortable={false}
            headerName={t(`grid.header.SpinsInfoTW`)}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{ secondLine: ['grid.header.LWMove'] }}
            cellRendererFramework={ThisLastMoveCellRenderer}
          />
          <AgGridColumn
            field="DaypartsInfo"
            headerName={t(`grid.header.DaypartsInfo`)}
            minWidth={230}
            sortable={false}
            headerComponentFramework={DayPartsHeaderComponent}
            cellRendererFramework={DayPartsCellRenderer}
          />
        </ServerSideReportGrid>
      </GridVisibilityWrapper>
    </>
  );
};

export default DaypartSummaryGrid;
