import { Range } from 'react-date-range';
import defaultTheme from 'themes/index';
import { convertToDate } from 'utils/dates';
import { CalendarFilterFilterData } from '..';
import { DateOptionParameters } from '../DefaultDateOptions';

const rangeColor = defaultTheme.palette.primary.main;

function rangeResolverOptionParams(data: DateOptionParameters): Range {
  return {
    startDate: data.start,
    endDate: data.end,
    color: rangeColor,
  };
}

function rangeResolverFilterData(data: CalendarFilterFilterData): Range {
  return {
    startDate: convertToDate(data.DateRange.Start),
    endDate: convertToDate(data.DateRange.End),
    color: rangeColor,
  };
}

export function rangeResolver(data: DateOptionParameters | CalendarFilterFilterData): Range {
  if ('DateRange' in data) {
    return rangeResolverFilterData(data);
  }
  return rangeResolverOptionParams(data);
}
