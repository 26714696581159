import { subDays, subMonths, addDays } from 'date-fns';
import { getLastSundayPublishedAllowedAllowed } from 'utils/dates';

export interface DateOptionParameters {
  label: string;
  start: Date;
  end: Date;
  anchoredWeekdaySelection?: boolean;
}

const today = new Date();
const lastSundayPublished = getLastSundayPublishedAllowedAllowed(today);
const dayOptions: DateOptionParameters[] = [
  {
    label: 'today',
    start: today,
    end: today,
  },
  {
    label: 'yesterday',
    start: subDays(today, 1),
    end: subDays(today, 1),
  },
  {
    label: 'custom',
    start: today,
    end: addDays(today, 1),
  },
];

const weekOptions: DateOptionParameters[] = [
  {
    label: 'thisWeek',
    start: subDays(today, 6),
    end: today,
  },
  {
    label: 'lastWeek',
    start: subDays(today, 13),
    end: subDays(today, 7),
  },
  {
    label: 'custom',
    start: subDays(today, 20),
    end: subDays(today, 14),
  },
];

const monthOptions: DateOptionParameters[] = [
  {
    label: 'thisMonth',
    start: subMonths(today, 1),
    end: today,
  },
  {
    label: 'lastMonth',
    start: subMonths(today, 2),
    end: subMonths(today, 1),
  },
  {
    label: 'custom',
    start: subMonths(today, 1),
    end: today,
  },
];

const weeklyDaySundayOptions: DateOptionParameters[] = [
  {
    label: 'thisWeek',
    start: subDays(lastSundayPublished, 6),
    end: lastSundayPublished,
    anchoredWeekdaySelection: false,
  },
  {
    label: 'previousWeek',
    start: subDays(lastSundayPublished, 13),
    end: subDays(lastSundayPublished, 7),
    anchoredWeekdaySelection: false,
  },
  {
    label: 'custom',
    start: subDays(lastSundayPublished, 20),
    end: subDays(lastSundayPublished, 14),
    anchoredWeekdaySelection: false,
  },
];

const dailyLogOptions: DateOptionParameters[] = [
  {
    label: 'yesterday',
    start: subDays(today, 1),
    end: subDays(today, 1),
  },
  {
    label: 'custom',
    start: subDays(today, 2),
    end: subDays(today, 2),
  },
];

export const defaultOptions = {
  day: dayOptions,
  week: weekOptions,
  month: monthOptions,
  weeklyDaySunday: weeklyDaySundayOptions,
  dailyLog: dailyLogOptions,
};
