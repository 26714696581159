import { get } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import InnerTable from '../index';
import { IInnerTableGroup } from '../inner-table-group';

interface TwelveWeekTableProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  name: string;
  field: string;
}

const weeks = Array.from({ length: 12 }, (v, k) => k + 1).map(v => `Week${v}`);

const TwelveWeekInnerTable: React.FC<TwelveWeekTableProps> = ({ data, name, field }) => {
  const { t } = useTranslation();
  const labels = weeks.map(w => t(`grid.header.twelveWeek.${w}`));
  const values = weeks.map(w => get(data, `${field}.${w}`));

  const innerTableData: IInnerTableGroup[] = [
    {
      title: t(name),
      children: [
        {
          title: '',
          children: labels,
        },
        {
          title: '',
          children: values,
        },
      ],
    },
  ];

  return <InnerTable key={v4()} data={innerTableData} />;
};

export default TwelveWeekInnerTable;
