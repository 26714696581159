import { AgGridColumn } from '@ag-grid-community/react/lib/agGridColumn';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import { Typography } from '@material-ui/core';
import DayPartsInfoColumn from 'components/ColumnsGrid/DayPartsInfoColumn';
import RankInfoCellRenderer from 'components/DetailGrid/CellRenderers/RankInfoCellRenderer/RankInfoCellRenderer';
import ThisLastMoveCellRenderer from 'components/DetailGrid/CellRenderers/ThisLastMoveCellRenderer';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import { numberFormat } from 'utils/currency';
import { SevenDayArtistExecutiveDetailChartTransformer } from './utils/ArtistChartTransformer';
import { ArtistChartFilter, SevenDayArtistChartItem } from './utils/ArtistChartTypes';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface ArtistSongChartGridProps {
  filters?: ArtistChartFilter;
  rowData?: any;
}

const url = '/seven-day-artist-chart-reports/execute-detail-report';
const ArtistSongChartGrid: FC<ArtistSongChartGridProps> = ({ filters, rowData }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({
    sortPath: 'RankThisWeek',
    sort: 'asc',
  });
  const { setSettings, settings, data: reportData, isLoading, isError } = useRemoteApi<
    ReportResult<SevenDayArtistChartItem>
  >(url, {
    data: filters,
    method: 'POST',
    enabled: false,
    transformRequest: data => {
      return {
        ...data,
        Artist: rowData?.Artist,
        TopX: 150,
        MinSpins: data?.MinSpins ?? 1,
      };
    },
    transformResponse: SevenDayArtistExecutiveDetailChartTransformer,
  });

  return (
    <>
      <Typography variant="subtitle1" align="center">
        {t('grid.header.Songs')}
      </Typography>
      <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
        <ServerSideReportGrid
          setSettings={setSettings}
          settings={settings}
          filters={filters}
          sortModel={sortModel}
          detailProps={{
            domLayout: 'autoHeight',
            containerStyle: {
              height: '100%',
              paddingBottom: '30px',
            },
          }}
          hideDetails
        >
          <AgGridColumn
            headerName={t('grid.header.Rank')}
            minWidth={60}
            maxWidth={60}
            field="RankInfo"
            headerComponentParams={{
              sortModel,
              onSortChange,
              disposition: 'row',
              secondLine: [
                { label: 'grid.header.TW', sortPath: 'RankThisWeek' },
                { label: 'grid.header.LW', sortPath: 'RankLastWeek' },
              ],
            }}
            cellRendererParams={{
              fields: ['RankInfo.ThisWeek', 'RankInfo.LastWeek'],
            }}
            cellRendererFramework={RankInfoCellRenderer}
            headerComponentFramework={MultiColumnsHeaderComponent}
          />
          <AgGridColumn
            headerName={t('grid.header.Title')}
            minWidth={160}
            pinned
            field="Title"
            headerComponentParams={{
              sortModel,
              onSortChange,
              sortPath: 'Title',
            }}
          />
          <AgGridColumn
            field="SpinsInfo"
            minWidth={85}
            width={85}
            headerName={t(`grid.header.SpinsInfoTW`)}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              disposition: 'row',
              onSortChange,
              sortPath: 'SpinsInfo/ThisWeek',
              secondLine: [
                { label: 'grid.header.LW', sortPath: 'SpinsInfo/LastWeek' },
                { label: 'grid.header.plusMinus', sortPath: 'SpinsInfo/Move' },
              ],
            }}
            cellRendererFramework={ThisLastMoveCellRenderer}
          />
          {DayPartsInfoColumn({ sortModel, onSortChange })}
          <AgGridColumn
            field="ImpressionsThisWeek"
            minWidth={100}
            width={100}
            valueFormatter={({ value }: ValueFormatterParams) => numberFormat(value)}
            headerName={t(`grid.header.ImpressionsInfo`)}
            headerComponentParams={{ sortModel, onSortChange, sortPath: 'ImpressionsInfo/SpinsThisWeek' }}
          />
        </ServerSideReportGrid>
      </GridVisibilityWrapper>
    </>
  );
};

export default ArtistSongChartGrid;
