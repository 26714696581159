/* eslint-disable react/jsx-one-expression-per-line */
import { Box } from '@material-ui/core';
import SortableColumnText from 'components/DetailGrid/SortableColumnText/SortableColumnText';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderComponentParams } from 'types/HeaderComponentParams';
import { useStyles } from './styles';

interface SortableColumnProps {
  label: string;
  subtitle?: string;
  sortPath?: string;
}

interface MultiColumnsHeaderComponentProps extends HeaderComponentParams {
  displayName: string;
  sortPath?: string;
  secondLine?: string[] | SortableColumnProps[];
  disposition?: 'column' | 'row';
  usePercentual?: boolean;
  separator?: string;
}

const CELL_WIDTH_FIX = 40;
const MultiColumnsHeaderComponent: React.FC<MultiColumnsHeaderComponentProps> = ({
  column,
  displayName,
  sortPath,
  secondLine = [],
  sortModel,
  onSortChange,
  disposition = 'column',
  usePercentual = false,
  separator = ', ',
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { sortable } = column.getColDef();
  const fullSize = column.getActualWidth() - CELL_WIDTH_FIX;
  const colWidth = fullSize / (secondLine.length || 1);
  const colWidthPercentual = 100 / (secondLine.length || 1);

  const defineWidthColumn = usePercentual ? `${colWidthPercentual}%` : `${colWidth}px`;
  const spanWidth = disposition === 'row' ? 'fit-content' : defineWidthColumn;

  const normalizedSecondLine: SortableColumnProps[] = (secondLine as unknown[]).map((item: unknown) => {
    if (typeof item === 'string') {
      return { label: item } as SortableColumnProps;
    }
    return item as SortableColumnProps;
  });

  return (
    <Box width="100%" className={classes.root}>
      <SortableColumnText
        sortPath={sortPath}
        sortable={sortable}
        currentSortModel={sortModel}
        onSortChange={onSortChange}
      >
        {displayName}
      </SortableColumnText>

      <Box className={`${classes.secondLine} ${disposition}`}>
        {normalizedSecondLine.map((col, index) => {
          return (
            <span key={col.label} style={{ width: spanWidth }}>
              <SortableColumnText
                sortPath={col.sortPath}
                sortable={sortable}
                currentSortModel={sortModel}
                onSortChange={onSortChange}
              >
                {index > 0 && disposition === 'row' ? separator : ''}
                {t(col.label)}
              </SortableColumnText>
              <br /> {col.subtitle && col.subtitle}
            </span>
          );
        })}
      </Box>
      <Box className="ag-header-cell-resize" />
      <Box className="ag-header-select-all" />
      <Box className="ag-header-component" />
    </Box>
  );
};

export default MultiColumnsHeaderComponent;
