/* eslint-disable no-nested-ternary */
import { AgGridColumn } from '@ag-grid-community/react';
import DayPartsCellRenderer from 'components/DetailGrid/CellRenderers/DayPartsCellRenderer';
import DayOfTheWeekCellRenderer from 'components/DetailGrid/CellRenderers/DaysOfTheWeekRenderer';
import DayPartsHeaderComponent from 'components/DetailGrid/HeaderComponents/DayPartsHeaderComponent';
import WeeklyCellRenderer from 'components/DetailGrid/CellRenderers/WeekCellRenderer';
import DaysOfTheWeekComponent from 'components/DetailGrid/HeaderComponents/DaysOfTheWeekComponent';
import WeeklyHeaderComponent from 'components/DetailGrid/HeaderComponents/WeeklyHeaderComponent';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ArtistCellRenderer from 'components/DetailGrid/CellRenderers/ArtistCellRenderer/ArtistCellRenderer';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import MultiColumnsCellRenderer from 'components/DetailGrid/CellRenderers/MultiColumnsCellRenderer/MultiColumnsCellRenderer';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import { numberFormat } from 'utils/currency';
import { SongSummaryGridTransformer } from './utils/RegionsMarketsTrendingAnalysisReportTransformer';
import { TrendingAnalysisByStationGridProps } from './TrendingAnalysisByStationReport';
import { TrendingAnalysisReport } from '../TrendingAnalysisReport/utils/TrendingAnalysisReport';

const SongSummaryGrid: React.FC<TrendingAnalysisByStationGridProps> = ({ filters, settings, setSettings, report }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({ sortPath: 'ReportName', sort: 'asc' });
  const { ShowImpressions } = filters as TrendingAnalysisReport;
  return (
    <>
      <ServerSideReportGrid
        sortModel={sortModel}
        settings={settings}
        setSettings={setSettings}
        filters={filters}
        transformResponse={SongSummaryGridTransformer}
      >
        <AgGridColumn
          field="ArtistInfo"
          pinned
          headerName={t(`grid.header.Title`)}
          minWidth={150}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            sortPath: 'Title',
            onSortChange,
            secondLine: [{ label: 'grid.header.Artist', sortPath: 'Artist' }],
          }}
          cellRendererFramework={ArtistCellRenderer}
        />
        <AgGridColumn
          cellRendererFramework={DayOfTheWeekCellRenderer}
          headerComponentFramework={DaysOfTheWeekComponent}
          field="DailySpinTrends"
          headerComponentParams={{
            name: t('grid.header.SpinsDaily'),
            rangeDate: report.DateRange,
            sortModel,
            onSortChange,
          }}
          minWidth={300}
          width={300}
          wrapText
        />
        <AgGridColumn
          cellRendererFramework={WeeklyCellRenderer}
          headerComponentFramework={WeeklyHeaderComponent}
          field="WeeklySpinTrends"
          headerComponentParams={{ name: t('grid.header.SpinsWeekly'), sortModel, onSortChange }}
          minWidth={250}
          wrapText
        />
        <AgGridColumn
          cellRendererFramework={DayPartsCellRenderer}
          headerComponentFramework={DayPartsHeaderComponent}
          headerComponentParams={{ name: t('grid.header.DaypartSpins'), sortModel, onSortChange }}
          field="DaypartSpins"
          minWidth={200}
          wrapText
        />
        <AgGridColumn
          field="SpinsToDate"
          headerName={t('grid.header.SpinsToDate')}
          minWidth={80}
          headerComponentParams={{
            sortModel,
            sortPath: 'SpinsToDate',
            onSortChange,
          }}
        />
        <AgGridColumn
          field="ImpressionsToDate"
          hide={!ShowImpressions}
          headerName={t('grid.header.ImpressionsToDate')}
          minWidth={120}
          valueFormatter={({ value: v }: ValueFormatterParams) => (v ? numberFormat(v) : '--')}
          headerComponentParams={{
            sortModel,
            sortPath: 'ImpressionsToDate',
            onSortChange,
          }}
        />
        <AgGridColumn
          hide={!ShowImpressions}
          cellRendererFramework={DayOfTheWeekCellRenderer}
          headerComponentFramework={DaysOfTheWeekComponent}
          field="DailyImpressionsTrends"
          headerComponentParams={{
            name: t('grid.header.ImpressionsDaily'),
            rangeDate: report.DateRange,
            sortModel,
            onSortChange,
          }}
          minWidth={300}
          width={300}
          wrapText
        />
        <AgGridColumn
          hide={!ShowImpressions}
          cellRendererFramework={MultiColumnsCellRenderer}
          headerComponentFramework={MultiColumnsHeaderComponent}
          field="WeeklyImpressionsTrends"
          minWidth={300}
          width={300}
          wrapText
          headerComponentParams={{
            name: t('grid.header.ImpressionsWeekly'),
            rangeDate: report.DateRange,
            secondLine: [
              t(`grid.header.totalPlays.Week1`),
              t(`grid.header.totalPlays.Move`),
              t(`grid.header.totalPlays.Week2`),
              t(`grid.header.totalPlays.Week3`),
              t(`grid.header.totalPlays.Week4`),
            ],
          }}
          cellRendererParams={{
            fields: [
              'WeeklyImpressionsTrends.Week1',
              'WeeklyImpressionsTrends.Move',
              'WeeklyImpressionsTrends.Week2',
              'WeeklyImpressionsTrends.Week3',
              'WeeklyImpressionsTrends.Week4',
            ],
          }}
        />
        <AgGridColumn
          hide={!ShowImpressions}
          cellRendererFramework={DayPartsCellRenderer}
          headerComponentFramework={DayPartsHeaderComponent}
          headerComponentParams={{ name: t('grid.header.dayPartImpressions') }}
          field="DaypartImpressions"
          minWidth={350}
          wrapText
        />
        <AgGridColumn
          field="FirstMonitored"
          headerName={t('grid.header.FirstDetect')}
          minWidth={80}
          valueFormatter={({ value }: ValueFormatterParams) => value || '--'}
          headerComponentParams={{
            sortModel,
            sortPath: 'FirstMonitored',
            onSortChange,
          }}
        />
      </ServerSideReportGrid>
    </>
  );
};

export default SongSummaryGrid;
