/* eslint-disable no-shadow */
export enum ReportModeNameEnum {
  bigPicture = 'filter.label.bigPicture',
  bigPictureWithHistory = 'filter.label.bigPictureWithHistory',
  bigPictureWithHistoryFrench = 'filter.label.bigPictureWithHistoryFrench',
}

export enum ReportModeIdEnum {
  bigPicture = 1,
  bigPictureWithHistory = 2,
  bigPictureWithHistoryFrench = 3,
}
