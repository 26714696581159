import React from 'react';

interface TabPanelProps {
  children: JSX.Element[] | JSX.Element;
  index: number;
  value: number;
}

const TabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, index } = props;

  return (
    <div key={index} hidden={value !== index} id={`tabpanel-${index}`}>
      {value === index && children}
    </div>
  );
};

export default TabPanel;
