/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import {
  ClientSideRowModelModule,
  GridApi,
  IServerSideGetRowsParams,
  MasterDetailModule,
  ServerSideRowModelModule,
} from '@ag-grid-enterprise/all-modules';
import DetailGrid from 'components/DetailGrid';
import { ExpandDetailCellRendererConfig } from 'components/DetailGrid/ExpandDetailCellRenderer';
import { UseApiSettings } from 'hooks/useApi/ApiTypes';
import { usePrevious } from 'hooks/usePrevious/usePrevious';
import { isEqual } from 'lodash';
import React, { Dispatch, PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import ReportResult from 'services/ReportResult';
import { SortModel } from 'types/HeaderComponentParams';

interface ServerSideReportGridProps<T> {
  setSettings: Dispatch<UseApiSettings<T>>;
  settings: UseApiSettings<T>;
  filters?: unknown;
  sortModel?: SortModel;
  transformResponse?: any;
  hideDetails?: boolean;
  expandCellRendererProps?: ExpandDetailCellRendererConfig;
  detailProps?: any;
  reportMetadata?: any;
}

const DEFAULT_RECORDS_LOAD_BATCH = 1000;
const ServerSideReportGrid = <T extends ReportResult<any>>({
  setSettings,
  settings,
  children,
  filters,
  sortModel,
  transformResponse,
  hideDetails,
  expandCellRendererProps,
  detailProps,
  reportMetadata,
}: PropsWithChildren<ServerSideReportGridProps<T>>): ReactElement => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const lastFilters = usePrevious(filters);
  const lastSortModel = usePrevious(sortModel);

  useEffect(() => {
    if (gridApi && (!isEqual(lastFilters, filters) || !isEqual(sortModel, lastSortModel))) {
      gridApi.setServerSideDatasource({ getRows });
    }
  }, [filters, sortModel]);

  const getRows = function getRows({ request, successCallback, failCallback }: IServerSideGetRowsParams) {
    const { startRow: $skip, endRow } = request;
    const $top = endRow - $skip;

    setSettings({
      ...settings,
      params: { ...settings.params, $top, $skip, $orderby: `${sortModel?.sortPath} ${sortModel?.sort}` },
      data: filters,
      onSuccess: d => successCallback(d.Items, d.Count),
      onError: () => failCallback,
      enabled: !!filters,
      ...(transformResponse && { transformResponse }),
    });
  };

  return (
    <DetailGrid
      {...detailProps}
      reportQuery={filters}
      reportMetadata={reportMetadata}
      modules={[ServerSideRowModelModule, MasterDetailModule, ClientSideRowModelModule]}
      rowModelType="serverSide"
      cacheBlockSize={DEFAULT_RECORDS_LOAD_BATCH}
      hideDetails={hideDetails}
      expandCellRendererProps={expandCellRendererProps}
      onGridReady={({ api }) => {
        setGridApi(api);
        api.setServerSideDatasource({ getRows });
      }}
    >
      {children}
    </DetailGrid>
  );
};

export default ServerSideReportGrid;
