import { AgGridColumn } from '@ag-grid-community/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import ReportResult from 'services/ReportResult';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ThisLastMoveCellRenderer from 'components/DetailGrid/CellRenderers/ThisLastMoveCellRenderer';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import FilterDataEvent from 'types/FilterDataEvent';
import MultiColumnsCellRenderer from 'components/DetailGrid/CellRenderers/MultiColumnsCellRenderer/MultiColumnsCellRenderer';
import FormatSelection from 'components/Filter/components/FormatSelection';
import { Button } from '@material-ui/core';
import RankInfoCellRenderer from 'components/DetailGrid/CellRenderers/RankInfoCellRenderer/RankInfoCellRenderer';
import ArtistCellRenderer from 'components/DetailGrid/CellRenderers/ArtistCellRenderer/ArtistCellRenderer';
import MinSpins from 'components/Filter/components/MinSpins';
import { SevenDayChartUrl } from 'enums/urls';
import { FilterGridContainer, FormatContainer, MinSpinsContainer } from './styles';
import { StationPlaylistGenericTransformerResponse } from './utils/StationPlaylistGenericTransformer';
import { GenericFilter, StationPlaylistGenericTransformedItem } from './utils/StationGenericTypes';
import { FORMAT_ALL } from '../../Songs/SongAnalysisReport/utils/SongAnalysisContants';

export interface NotPlayingGridProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filters: any;
}

const url = SevenDayChartUrl.stationPlaylistNotPlayingReports;
const NotPlayingGrid: React.FC<NotPlayingGridProps> = ({ filters }) => {
  const [notPlayingGridFilterTemporary, setNotPlayingGridFilterTemporary] = useState<GenericFilter | undefined>();
  const [notPlayingGridFilter, setNotPlayingGridFilter] = useState<GenericFilter | undefined>();

  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({ sortPath: 'FormatRankThisWeek', sort: 'asc' });

  const { data: reportData, isLoading, settings, setSettings, isError } = useRemoteApi<
    ReportResult<StationPlaylistGenericTransformedItem>
  >(url, {
    data: filters,
    method: 'POST',
    enabled: false,
    transformResponse: StationPlaylistGenericTransformerResponse,
  });

  const onChangeNotPlayingGridFilter = (event: FilterDataEvent<GenericFilter>) => {
    const { value } = event;
    setNotPlayingGridFilterTemporary({
      ...notPlayingGridFilter,
      ...value,
    });
  };

  return (
    <>
      <FilterGridContainer>
        <FormatContainer>
          <FormatSelection
            onChange={onChangeNotPlayingGridFilter}
            panelCode={filters?.PanelInfo?.PanelCode}
            apiPropertyName="FormatInfo"
            isArray={false}
            initialValue={FORMAT_ALL}
          />
        </FormatContainer>
        <MinSpinsContainer>
          <MinSpins onChange={onChangeNotPlayingGridFilter} defaultValue={5} />
        </MinSpinsContainer>
        <Button
          style={{ justifyContent: 'flex-end', fontSize: '10px' }}
          color="primary"
          onClick={() => setNotPlayingGridFilter(notPlayingGridFilterTemporary as GenericFilter)}
        >
          {t('filter.title.apply')}
        </Button>
      </FilterGridContainer>
      <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
        <ServerSideReportGrid
          sortModel={sortModel}
          settings={settings}
          setSettings={setSettings}
          filters={{
            ...filters,
            FormatInfo: notPlayingGridFilter?.FormatInfo ?? FORMAT_ALL,
            MinSpins: notPlayingGridFilter?.MinSpins ?? 5,
          }}
        >
          <AgGridColumn
            headerName={t('grid.header.Rank')}
            minWidth={60}
            width={60}
            field="Rank"
            headerComponentParams={{
              sortModel,
              onSortChange,
              disposition: 'row',
              secondLine: [
                { label: 'grid.header.TW', sortPath: 'RankThisWeek' },
                { label: 'grid.header.LW', sortPath: 'RankLastWeek' },
              ],
            }}
            cellRendererParams={{
              fields: ['Rank.ThisWeek', 'Rank.LastWeek'],
            }}
            cellRendererFramework={RankInfoCellRenderer}
            headerComponentFramework={MultiColumnsHeaderComponent}
          />
          <AgGridColumn
            field="ArtistInfo"
            headerName={t(`grid.header.Title`)}
            minWidth={120}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              sortPath: 'Title',
              onSortChange,
              secondLine: [{ label: 'grid.header.Artist', sortPath: 'Artist' }],
            }}
            cellRendererFramework={ArtistCellRenderer}
          />
          <AgGridColumn
            field="MarketSpins.ThisWeek"
            valueFormatter={({ value }: ValueFormatterParams) => (value ? `${+parseFloat(value).toFixed(0)}` : '-')}
            headerName={t(`grid.header.marketInfo.MarketSpinsTw`)}
            minWidth={80}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="FormatSpins"
            minWidth={95}
            headerName={t(`grid.header.FormatSpins`)}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              sortPath: 'FormatSpins/ThisWeek',
              secondLine: [
                { label: 'grid.header.TW', sortPath: 'FormatSpins/LastWeek' },
                { label: 'grid.header.LW', sortPath: 'FormatSpins/Move' },
              ],
            }}
            cellRendererFramework={ThisLastMoveCellRenderer}
            cellRendererParams={{ showMove: false }}
          />
          <AgGridColumn
            field="StationSpins"
            minWidth={95}
            headerName={t(`grid.header.StationSpins`)}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              sortPath: 'StationSpins/ThisWeek',
              secondLine: [
                { label: 'grid.header.TW', sortPath: 'StationSpins/LastWeek' },
                { label: 'grid.header.LW', sortPath: 'StationSpins/Move' },
              ],
            }}
            cellRendererFramework={ThisLastMoveCellRenderer}
            cellRendererParams={{ showMove: false }}
          />
          <AgGridColumn
            field="HistoricalInfo"
            minWidth={200}
            width={200}
            headerName={t(`grid.header.historicalData.HistoricalData`)}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              disposition: 'row',
              secondLine: [
                { label: ['grid.header.historicalData.FirstPlayed'], sortPath: 'FirstPlayDate' },
                { label: ['grid.header.historicalData.HistSpins'], sortPath: 'HistoricalSpins' },
              ],
            }}
            cellRendererParams={{
              fields: ['HistoricalInfo.FirstPlayDate', 'HistoricalInfo.HistoricalSpins'],
            }}
            cellRendererFramework={MultiColumnsCellRenderer}
          />
        </ServerSideReportGrid>
      </GridVisibilityWrapper>
    </>
  );
};

export default NotPlayingGrid;
