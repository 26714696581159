import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const Container = styled(Grid)`
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  justify-content: flex-start;
  align-items: center;
  min-height: inherit;
`;
export const ContainerBody = styled(Grid)`
  display: flex;
  flex-direction: column;
  max-width: 1500px;
  justify-content: center;
  align-items: center;
`;
export const LogoContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
export const Title = styled.span`
  margin-top: 13px;
  font-size: 32px;
  color: #000000;
  font-family: 'Gill Sans';
`;
export const FormContainer = styled(Grid)`
  display: flex;
  margin-top: 0;
  flex-direction: column;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5px 10px;
  margin-bottom: 8px;
`;
export const FooterContainer = styled(Grid)`
  display: block;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 60px;
`;
export const LinksContainer = styled(Grid)`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: flex-start;
  justify-content: center;
`;
export const Links = styled(Typography)`
  font-size: 12px;
  color: #197bba;
  margin-top: 5px;
  cursor: pointer;
  display: inline-flex;
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loginMenu: {
      backgroundColor: '#E5412C',
      height: '36px',
      borderRadius: '7px',
      boxShadow: 'none',
      padding: '8px 10px',
      fontSize: '16px',
      color: 'white',
      border: 0,
      "&:hover": {
        backgroundColor: '#E5412C',
      }
    },
    loginBtn: {
      backgroundColor: '#E5412C',
      flex: 1,
      height: '26px',
      display: 'block',
      borderRadius: '7px',
      width: '100%',
      boxShadow: 'none',
      fontSize: '16px',
      padding: '0px',
      marginTop: '10px',
      color: 'white',
      border: 0,
    },    
    logo: {
      textAlign: 'center',
      maxWidth: '100%',
      maxHeight: '70%',
      width: '200px'
    },
    card: {
      marginTop: 0,
    },
    textField: {
      marginTop: '5px',
      width: '100%'
    },
    loginArea:{
      display: 'flex',
    },
    link: {
      fontSize: '14px',
      color: theme.palette.primary.main,
      marginTop: 0,
      cursor: 'pointer',
    },
    menuButton: {
      color: 'white',
    },
    hide: {
      display: 'none',
    },
    toolBar:{
      position: 'absolute',
      right: '0',
      left: '0'
    },
    loginTop : {
      width: '93%',
      justifyContent: 'space-between'
    },
    loginContainer:{
      width: '93%',
    },
    separator: {
      padding: '0 5px;',
      color: '#197bba',
      marginTop: '3px'
    }
  }),
);

export default useStyles;