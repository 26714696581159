export interface PanelInfo {
  Id: number;
  PanelCode: PanelCodeEnum;
  Name: string;
  ShortName: string;
  DisplayOrder: number;
  IsPublished: boolean;
}

// eslint-disable-next-line no-shadow
export enum PanelCodeEnum {
  None = '',
  AllUsStations = 'M',
  PublishedPanel = 'R',
  AllCanadianStations = 'C',
  PublishedCanadaPanel = 'K',
  LatinPanel = 'L',
  PublishedLatinPanel = 'N',
  PublishedSmoothAcPanel = 'J',
  PublishedChristianAcPanel = 'X',
  OverallChristianPanel = 'E',
  PublishedChristianAudiencePanel = 'A',
  ActivatorPanel = 'S',
}

// this data does not change very often
export class PanelLocalData 
{
  static list(): PanelInfo[] 
  {
    return [
      {
          "Id": 1,
          "PanelCode": "M" as PanelCodeEnum,
          "Name": "Mediabase - All Stations (U.S)",
          "ShortName": "Mediabase All (U.S)",
          "DisplayOrder": 1,
          "IsPublished": false
      },
      {
          "Id": 2,
          "PanelCode": "R" as PanelCodeEnum,
          "Name": "Mediabase - Published Panel",
          "ShortName": "Published (U.S)",
          "DisplayOrder": 2,
          "IsPublished": true
      },
      {
          "Id": 6,
          "PanelCode": "C" as PanelCodeEnum,
          "Name": "Mediabase - All Canadian Stations",
          "ShortName": "Canada All",
          "DisplayOrder": 3,
          "IsPublished": false
      },
      {
          "Id": 9,
          "PanelCode": "K" as PanelCodeEnum,
          "Name": "Mediabase - Published Canada Panel",
          "ShortName": "Canada Published",
          "DisplayOrder": 4,
          "IsPublished": true
      },
      {
          "Id": 5,
          "PanelCode": "L" as PanelCodeEnum,
          "Name": "Mediabase - Latin Panel",
          "ShortName": "Latin All",
          "DisplayOrder": 5,
          "IsPublished": false
      },
      {
          "Id": 15,
          "PanelCode": "N" as PanelCodeEnum,
          "Name": "Mediabase - Published Latin Panel",
          "ShortName": "Latin Published",
          "DisplayOrder": 6,
          "IsPublished": true
      },
      {
          "Id": 8,
          "PanelCode": "J" as PanelCodeEnum,
          "Name": "Mediabase - Published Smooth AC Panel",
          "ShortName": "Smooth AC",
          "DisplayOrder": 7,
          "IsPublished": true
      },
      {
          "Id": 7,
          "PanelCode": "X" as PanelCodeEnum,
          "Name": "Mediabase - Published Christian AC Panel",
          "ShortName": "Christian AC",
          "DisplayOrder": 8,
          "IsPublished": true
      },
      {
          "Id": 10,
          "PanelCode": "E" as PanelCodeEnum,
          "Name": "Mediabase - All Christian Stations",
          "ShortName": "Christian All",
          "DisplayOrder": 9,
          "IsPublished": false
      },
      {
          "Id": 11,
          "PanelCode": "A" as PanelCodeEnum,
          "Name": "Mediabase - Published Christian Impressions Panel",
          "ShortName": "Christian Impressions",
          "DisplayOrder": 10,
          "IsPublished": true
      }
    ];
  }

  static find(panelCode:string):PanelInfo | undefined
  {
    const panelInfo = PanelLocalData.list().find(p => p.PanelCode?.trim() === panelCode?.trim() );
    return panelInfo;
  }
} 