/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-types */
import { Grid } from '@material-ui/core';
import { FilterState } from 'hooks/useFilterState/useFilterState';
import { usePrevious } from 'hooks/usePrevious/usePrevious';
import useStationResolver, { SearchStationData } from 'hooks/useStationResolver/useStationResolver';
import { differenceBy, isEqual, noop } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilterDataEvent from 'types/FilterDataEvent';
import FilterDataProps from 'types/FilterDataProps';
import { FormatInfo } from 'types/Format';
import { PanelInfo, PanelCodeEnum } from 'types/Panel';
import { Station } from 'types/Station';
import FormatFilter from '../FormatFilter';
import InputSearch from '../InputSearch';
import PanelFilter from '../PanelFilter';
import { InnerGrid } from '../styles';
import ListSelectedStation from './ListSelectedStation';
import ListStations from './ListStations';

interface Props extends FilterDataProps<FilterData> {
  title: string;
  disabled?: boolean;
  callLetters?: string;
  changeState?: (key: string, state: FilterState) => void;
  required?: boolean;
}

interface FilterData {
  Stations: Station[] | undefined;
}

interface PanelInfoType {
  PanelInfo: PanelInfo | undefined;
}

interface FormatInfoType {
  FormatInfo: FormatInfo;
}

const FILTER_COMPONENT_KEY = 'StationSelectionComponent';

const StationSelect: React.FC<Props> = ({
  onChange,
  disabled = false,
  initialValue,
  callLetters,
  changeState = noop,
  required,
}) => {
  const { searchStations, findByCallLetters } = useStationResolver();

  const [filter, setFilter] = useState({} as SearchStationData);
  const { t } = useTranslation();

  const [stationSelected, setStationSelected] = useState<Station[]>();
  const [searchedStations, setSearchedStations] = useState<Station[]>();
  const lastStationSelected = usePrevious(stationSelected);

  const onSearchButtonClick = () => {
    searchStations(filter, data => {
      setSearchedStations(data);
    });
  };

  const handleFilterChange = (stationFilter: FilterDataEvent<{ SearchTerm: string }>) => {
    const { value } = stationFilter;
    setFilter({ ...filter, ...value });
  };

  const handleFilterChangeFormat = (event: FilterDataEvent<FormatInfoType>) => {
    const { FormatInfo: val } = event.value as FormatInfoType;
    setFilter({ ...filter, ...{ FormatId: val?.Id } });
  };

  const handleFilterChangePanel = (event: FilterDataEvent<PanelInfoType>) => {
    const { PanelInfo: val } = event.value as PanelInfoType;
    setFilter({ ...filter, ...{ PanelCode: val?.PanelCode ?? PanelCodeEnum.None } });
  };

  const onSelectStation = (station: Station) => {
    const newStationsSelected = [...(stationSelected ?? []), station];
    setStationSelected(newStationsSelected);
  };

  const filterStations = (): Station[] => {
    return differenceBy(searchedStations, stationSelected ?? [], 'Id');
  };

  const onRemoveStation = (stationRemoved: Station) => {
    const newStations = (stationSelected ?? []).filter(station => station.Id !== stationRemoved.Id);
    setStationSelected(newStations);
  };

  const hasCallLetterSelected = (): boolean => {
    return !!(stationSelected ?? []).find(v => v.Name === callLetters);
  };

  useEffect(() => {
    if (callLetters && !hasCallLetterSelected()) {
      changeState(FILTER_COMPONENT_KEY, FilterState.INVALID);
      findByCallLetters(callLetters, stations => {
        setStationSelected(stations);
      });
    } else if (initialValue?.length && !stationSelected?.length) {
      changeState(FILTER_COMPONENT_KEY, FilterState.INVALID);
      setStationSelected(initialValue);
    }
  }, [initialValue, callLetters]);

  useEffect(() => {
    if (!required || (stationSelected?.length && (!callLetters || hasCallLetterSelected()))) {
      changeState(FILTER_COMPONENT_KEY, FilterState.VALID);
    } else {
      changeState(FILTER_COMPONENT_KEY, FilterState.INVALID);
    }

    if (!isEqual(lastStationSelected, stationSelected)) {
      onChange({ value: { Stations: stationSelected } });
    }
  }, [stationSelected]);

  return (
    <InnerGrid container>
      <Grid item xs={12} style={{ display: disabled ? 'none' : '' }}>
        <ListSelectedStation selectedStations={stationSelected ?? []} onRemove={onRemoveStation} />
        <PanelFilter onChange={handleFilterChangePanel} />
        <FormatFilter
          panelCode={filter.PanelCode}
          onChange={handleFilterChangeFormat}
          title={t('grid.header.FormatName')}
          searchWithoutReportType
        />
        <InputSearch onSearch={onSearchButtonClick} onChange={handleFilterChange} />

        {!!filterStations()?.length && <ListStations stationList={filterStations} selectStation={onSelectStation} />}
      </Grid>
    </InnerGrid>
  );
};

export default StationSelect;
