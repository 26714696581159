/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import { AgGridColumn } from '@ag-grid-community/react';
import WeeklyHeaderComponent from 'components/DetailGrid/HeaderComponents/WeeklyHeaderComponent';
import React from 'react';
import WeeklyCellRenderer from 'components/DetailGrid/CellRenderers/WeekCellRenderer';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import { numberFormat } from 'utils/currency';

interface WeeklyRep {
  name?: string;
  field?: string;
  onSortChange?: (sortPath: string) => void;
  hide?: boolean;
  toFixed?: number;
  formatNumber?: boolean;
}

const WeeklyReport = ({
  name = 'Weekly',
  field = 'WeeklyImpressionsTrends',
  onSortChange,
  hide = false,
  toFixed = 0,
  formatNumber = false,
}: WeeklyRep): JSX.Element => {
  return (
    <AgGridColumn
      field={field}
      cellRendererFramework={WeeklyCellRenderer}
      cellRendererParams={{ toFixed }}
      headerComponentFramework={WeeklyHeaderComponent}
      valueFormatter={({ value }: ValueFormatterParams) =>
        value ? (formatNumber ? numberFormat(value) : value) : '--'
      }
      minWidth={350}
      width={350}
      headerComponentParams={{ name, onSortChange }}
      wrapText
      hide={hide}
    />
  );
};

WeeklyReport.defaultProps = {
  name: 'Weekly',
};

export default WeeklyReport;
