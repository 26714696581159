import React from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderComponentParams } from 'types/HeaderComponentParams';
import SortableColumnText from '../SortableColumnText/SortableColumnText';

export interface StationRankMarketFormatHeaderProps extends HeaderComponentParams {
  showFormat?: boolean;
}

const StationRankMarketOwnerHeaderComponent: React.FC<StationRankMarketFormatHeaderProps> = props => {
  const { t } = useTranslation();
  const { sortModel, onSortChange, showFormat = false } = props;

  const getAdditionalHeader = () => {
    if (showFormat) {
      return (
        <SortableColumnText currentSortModel={sortModel} onSortChange={onSortChange} sortable sortPath="FormatName">
          {`, ${t('grid.header.FormatName').toUpperCase()}`}
        </SortableColumnText>
      );
    }
    return (
      <SortableColumnText currentSortModel={sortModel} onSortChange={onSortChange} sortable sortPath="OwnerName">
        {`, ${t('grid.header.OwnerName').toUpperCase()}`}
      </SortableColumnText>
    );
  };

  return (
    <div>
      <span>
        <SortableColumnText
          currentSortModel={sortModel}
          onSortChange={onSortChange}
          sortable
          sortPath="StationDisplayName"
        >
          {t('grid.header.StationName')}
        </SortableColumnText>
        <SortableColumnText currentSortModel={sortModel} onSortChange={onSortChange} sortable sortPath="MarketRank">
          {`, ${t('grid.header.Rank')}`}
        </SortableColumnText>
      </span>
      <br />
      <span>
        <SortableColumnText currentSortModel={sortModel} onSortChange={onSortChange} sortable sortPath="MarketName">
          {t('grid.header.Market').toUpperCase()}
        </SortableColumnText>
        {getAdditionalHeader()}
      </span>
    </div>
  );
};

export default StationRankMarketOwnerHeaderComponent;
