import { Column } from '@ag-grid-enterprise/all-modules';
import { format, subDays } from 'date-fns';
import React from 'react';
import { v4 } from 'uuid';
import InnerTable from '..';
import { IInnerTableGroup } from '../inner-table-group';

export interface PlaysData {
  YesterdayPlays: number;
  BeforeYesterdayPlays: number;
  PlaysMove: number;
}

interface PlaysProps {
  data: PlaysData;
  invisibleColumns: Array<Column>;
}

const PlaysInnerTable: React.FC<PlaysProps> = ({ data, invisibleColumns }: PlaysProps) => {
  const composeHeader = () => {
    return invisibleColumns[0].getColDef().headerName || '';
  };

  const innerTableData: IInnerTableGroup[] = [
    {
      title: composeHeader(),
      children: [
        {
          title: '',
          children: [
            format(subDays(new Date(), 1), 'EEE').toUpperCase(),
            format(subDays(new Date(), 2), 'EEE').toUpperCase(),
            '+/-',
          ],
        },
        {
          title: '',
          children: [data.YesterdayPlays.toString(), data.BeforeYesterdayPlays.toString(), data.PlaysMove.toString()],
        },
      ],
    },
  ];

  return <InnerTable key={v4()} data={innerTableData} />;
};

export default PlaysInnerTable;
