/* eslint-disable react/jsx-no-undef */

import FormatFilter from 'components/Filter/components/FormatFilter';
import Grc from 'components/Filter/components/Grc';
import PanelFilter from 'components/Filter/components/PanelFilter';
import PastDays, { getCurrentBuildingCycle } from 'components/Filter/components/PastDays';
import InputNumberFilter from 'components/InputNumber';
import FilterBase from 'components/FilterBase';
import useStickyFilter from 'hooks/useStickyFilter/useStickyFilter';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FilterDataEvent from 'types/FilterDataEvent';
import { PanelCodeEnum } from 'types/Panel';
import { FilterParams } from 'types/FilterParams';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import { BuildingChartFilter } from './utils/BuildingChartReportTypes';

interface FilterBuildingChartReportProps {
  changeFilter: { (filters: BuildingChartFilter): void };
  isOpened: boolean;
}

interface MinSpins {
  MinSpins: number;
}

const Filter: React.FC<FilterBuildingChartReportProps> = ({ changeFilter, isOpened }) => {
  const { t } = useTranslation();
  const pathname = getReportSpec(ReportBaseEnum.BuildingChartReport).stickyKey;
  const { savedFilters: filter, saveFilter } = useStickyFilter<BuildingChartFilter>(pathname);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleFilterChange = (event: FilterDataEvent<MinSpins | FilterParams>) => {
    const { value } = event as FilterDataEvent<FilterParams>;
    saveFilter({ ...filter, ...value } as BuildingChartFilter);
  };

  const handleOnSubmit = () => {
    if (filter) {
      changeFilter(filter);
    }
  };

  return (
    <FilterBase onSubmit={handleOnSubmit} isOpened={isOpened}>
      <PanelFilter onChange={handleFilterChange} initialValue={filter?.PanelInfo} />
      <FormatFilter
        onChange={handleFilterChange}
        panelCode={filter?.PanelInfo?.PanelCode ?? PanelCodeEnum.AllUsStations}
        hasAllOption={false}
        initialValue={filter?.FormatInfo}
      />
      <PastDays hasTodayOption={false} onChange={handleFilterChange} initialValue={getCurrentBuildingCycle()} />
      <Grc onChange={handleFilterChange} initialValue={filter?.GrcInfo} />
      <InputNumberFilter<MinSpins>
        minValue={1}
        onChange={handleFilterChange}
        name={t('grid.header.minSpinsCuttOff')}
        valueToApi="MinSpins"
        initialValue={filter?.MinSpins}
      />
    </FilterBase>
  );
};

export default Filter;
