import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      justifyContent: 'flex-end',
      fontSize: '0.8em',
    },
  });
});
