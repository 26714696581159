import { Column } from '@ag-grid-enterprise/all-modules';
import { get } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import InnerTable from '..';
import { IInnerTableGroup } from '../inner-table-group';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface SongPlaysImpressionInnerTableProps {
  field: string;
  columns?: Column[];
  data: any;
  playsField: string;
  impressionsField: string;
}

const SongPlaysImpressionInnerTable: React.FC<SongPlaysImpressionInnerTableProps> = ({
  field,
  playsField,
  impressionsField,
  columns = [],
  data,
}) => {
  const { t } = useTranslation();
  const column = columns.find((c: Column) => c.getColId() === field);
  const title = column?.getColDef().headerName;
  const innerTableData = {
    title,
    children: [
      {
        title: t('grid.header.Plays'),
        children: [get(data, playsField)],
      },
      {
        title: t('grid.header.ImpressionsM'),
        children: [get(data, impressionsField)],
      },
    ],
  } as IInnerTableGroup;
  return <InnerTable data={[innerTableData]} />;
};

export default SongPlaysImpressionInnerTable;
