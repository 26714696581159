/* eslint-disable */
import React, { createContext, FC, useEffect, useState } from 'react';
import { AuthContext } from './types';
import { createBrowserHistory } from 'history';

const AuthSettings = createContext<AuthContext>({
  expires: null,
  token: null,
  isAuthenticated: () => {},
  onLogin: (token: string, expires: string) => {},
  onLogout: () => {},
} as AuthContext);

const AuthSettingsProvider: FC = ({ children }) => {
  const [token, setToken] = useState<string | null>(null);
  const [expires, setExpires] = useState<number | null>(null);
  const [isReady, setIsReady] = useState<boolean>(false);
  const history = createBrowserHistory();

  const onLogin = (tokenStorage: string, expiresStorage: string) => {
    const authData = {
      token: tokenStorage,
      expiresToken: expiresStorage,
    };
    localStorage.setItem('authData', JSON.stringify(authData));
    if (tokenStorage && expiresStorage) {
      const expireDate = new Date(expiresStorage).getTime();
      setToken(tokenStorage);
      setExpires(expireDate);
    }
  };

  const onLogout = () => {
    localStorage.removeItem('auth');
    localStorage.removeItem('authData');
    setToken(null);
    setExpires(null);
    history.push('/login');
    //window.location.href = '/login';
  };

  const isAuthenticated = (): boolean => {
    const now = new Date().getTime();
    const result = !!token && !!expires && now < expires;
    return result;
  };

  useEffect(() => {
    const locaStorageData = localStorage.getItem('authData');
    if (locaStorageData) {
      const authData = JSON.parse(locaStorageData);
      if (authData.token && authData.expiresToken) {
        const expireDate = new Date(authData.expiresToken).getTime();
        setToken(authData.token);
        setExpires(expireDate);
      }
    }
    setIsReady(true);
  }, []);

  return isReady ? (
    <AuthSettings.Provider
      value={{
        token,
        expires,
        onLogout,
        isAuthenticated,
        onLogin,
      }}
    >
      {children}
    </AuthSettings.Provider>
  ) : null;
};

export { AuthSettings as default, AuthSettingsProvider };
