import { useAppApi } from "services/api";
import { useQuery, QueryClient, UseQueryResult } from "react-query";
import { AxiosError } from "axios";
import AppProblem from "types/AppProblem";
import { StationPortfolio } from "types/StationPortfolio";

const useStationPorfolioGetAll = ():UseQueryResult<Array<StationPortfolio>, AxiosError<AppProblem>> =>{
    const api = useAppApi();   
    const url = '/station-portfolios';    
    const cacheKey = 'station-portfolios-all-cache';
    return useQuery<Array<StationPortfolio>, AxiosError<AppProblem>>(cacheKey, () => {
        return api.get(url)
            .then((resp)=>
            { 
                return resp.data 
            });
    });
};

const useStationPorfolioGetById = (id: number):UseQueryResult<StationPortfolio, AxiosError<AppProblem>> =>
{
    const queryClient = new QueryClient();
    const api = useAppApi();   
    const url = `/station-portfolios/${id}`;    
    const cacheKey = 'station-portfolios-get';

    return useQuery<StationPortfolio, AxiosError<AppProblem>>(cacheKey, () => {
        return api.get(url)
            .then((resp)=>
            { 
                queryClient.invalidateQueries(cacheKey);
                return resp.data 
            });
    });    
};

export {useStationPorfolioGetAll, useStationPorfolioGetById};