/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { AgGridColumn } from '@ag-grid-community/react';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import DetailGrid from 'components/DetailGrid';
import LoadDataGrid from 'components/LoadDataGrid';
import NoDataGrid from 'components/NoDataGrid/indx';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import { useTranslation } from 'react-i18next';
import FilterDataEvent from 'types/FilterDataEvent';
import { PanelCodeEnum } from 'types/Panel';
import { YearToDateSongsByFormatFilter } from './utils/YearToDateSongsByFormat';

interface BigPictureByMonthGridProps {
  filters: FilterDataEvent<YearToDateSongsByFormatFilter> | null;
}

const url = `/year-to-date-songs-by-format-big-picture-monthly-reports?%24top=1000&%24skip=0&%24inlinecount=allpages&%24orderby=TotalSpins%20asc`;

const BigPictureByMonthGrid: React.FC<BigPictureByMonthGridProps> = ({ filters }) => {
  const { t } = useTranslation();

  const [filterApi, setFilterApi] = useState({
    IsArchived: 'false',
    PanelInfo: {
      Id: 2,
      PanelCode: PanelCodeEnum.PublishedPanel,
      Name: 'Mediabase+-+Published+Panel',
      ShortName: 'Published+(U.S)',
      DisplayOrder: 2,
      IsPublished: true,
    },
    FormatInfo: { Id: 0, Name: 'All', FormatCode: 'XX' },
    GrcInfo: { GrcCode: 'GRC', Name: 'Show+All' },
    TopX: '150',
  });
  useEffect(() => {
    setFilterApi({
      ...filterApi,
      ...filters,
    });
  }, [filters]);
  const { data: reportData, isLoading } = useRemoteApi<any>(url, {
    data: filterApi,
    method: 'POST',
    enabled: !!filterApi,
  });

  if (isLoading) return <LoadDataGrid />;
  if (!reportData?.Items.length) return <NoDataGrid />;

  return (
    <>
      <DetailGrid reportData={reportData.Items} modules={AllModules}>
        <AgGridColumn minWidth={80} field="Rank" headerName={t(`grid.header.YtdRank`)} />
        <AgGridColumn minWidth={200} field="Artist" headerName={t(`grid.header.Artist`)} />
        <AgGridColumn minWidth={200} field="Title" headerName={t(`grid.header.Title`)} />
        <AgGridColumn minWidth={200} field="Label" headerName={t(`grid.header.Label`)} />
        <AgGridColumn minWidth={80} field="ReleaseYear" headerName={t(`grid.header.ReleaseYear`)} />
        <AgGridColumn minWidth={80} field="TotalSpins" headerName={t(`grid.header.TotalSpins`)} />
        <AgGridColumn minWidth={80} field="YearDataInfo.January" headerName={t(`grid.header.YearDataInfo.January`)} />
        <AgGridColumn minWidth={80} field="YearDataInfo.February" headerName={t(`grid.header.YearDataInfo.February`)} />
        <AgGridColumn minWidth={80} field="YearDataInfo.March" headerName={t(`grid.header.YearDataInfo.March`)} />
        <AgGridColumn minWidth={80} field="YearDataInfo.April" headerName={t(`grid.header.YearDataInfo.April`)} />
        <AgGridColumn minWidth={80} field="YearDataInfo.May" headerName={t(`grid.header.YearDataInfo.May`)} />
        <AgGridColumn minWidth={80} field="YearDataInfo.June" headerName={t(`grid.header.YearDataInfo.June`)} />
        <AgGridColumn minWidth={80} field="YearDataInfo.July" headerName={t(`grid.header.YearDataInfo.July`)} />
        <AgGridColumn minWidth={80} field="YearDataInfo.August" headerName={t(`grid.header.YearDataInfo.August`)} />
        <AgGridColumn
          minWidth={80}
          field="YearDataInfo.September"
          headerName={t(`grid.header.YearDataInfo.September`)}
        />
        <AgGridColumn minWidth={80} field="YearDataInfo.October" headerName={t(`grid.header.YearDataInfo.October`)} />
        <AgGridColumn minWidth={80} field="YearDataInfo.November" headerName={t(`grid.header.YearDataInfo.November`)} />
        <AgGridColumn minWidth={80} field="YearDataInfo.December" headerName={t(`grid.header.YearDataInfo.December`)} />
      </DetailGrid>
    </>
  );
};

export default BigPictureByMonthGrid;
