import ArtistSection from 'components/Filter/components/ArtistSelection/ArtistSelection';
import FormatFilter from 'components/Filter/components/FormatFilter';
import MarketSelect from 'components/Filter/components/MarketSelect';
import OwnerFilterSelection from 'components/Filter/components/OwnerFilterSelection/OwnerFilterSelection';
import PanelFilter from 'components/Filter/components/PanelFilter';
import FilterBase from 'components/FilterBase';
import useStickyFilter from 'hooks/useStickyFilter/useStickyFilter';
import { noop } from 'lodash';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import React, { FC } from 'react';
import FilterDataEvent from 'types/FilterDataEvent';
import ReportModeSelect, { ReportModeIndexes } from './ReportModeSelect';
import { ExtendedArtistAnalysisFilter } from './utils/ArtistAnalysisFilter';

interface ArtistAnalysisFilterProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  changeFilter: (filters: ExtendedArtistAnalysisFilter) => void;
  isOpened: boolean;
}

const Filter: FC<ArtistAnalysisFilterProps> = ({ isOpened, changeFilter = noop }) => {
  const { stickyKey } = getReportSpec(ReportBaseEnum.SongArtistAnalysisReport);
  const { savedFilters: filter, saveFilter } = useStickyFilter<ExtendedArtistAnalysisFilter>(stickyKey);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleFilterChange = (event: FilterDataEvent<object>) => {
    const { value } = event;
    saveFilter({ ...filter, ...value } as ExtendedArtistAnalysisFilter);
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleArtistSelection = (event?: object) => {
    saveFilter({ ...filter, ArtistInfo: event } as ExtendedArtistAnalysisFilter);
  };

  return (
    <>
      <FilterBase onSubmit={() => changeFilter(filter)} isOpened={isOpened}>
        <ReportModeSelect
          onChange={handleFilterChange}
          initialValue={filter?.ReportMode ?? ReportModeIndexes.SEVEN_DAY}
        />
        <PanelFilter onChange={handleFilterChange} initialValue={filter?.PanelInfo} />
        <FormatFilter
          onChange={handleFilterChange}
          initialValue={filter?.FormatInfo}
          panelCode={filter?.PanelInfo?.PanelCode}
        />
        <MarketSelect
          onChange={handleFilterChange}
          initialValue={filter?.MarketInfo}
          panelCode={filter?.PanelInfo?.PanelCode}
          showAll
        />
        <OwnerFilterSelection onChange={handleFilterChange} initialValue={filter?.OwnerInfo} />
        <ArtistSection onChange={handleArtistSelection} initialValue={filter?.ArtistInfo} />
      </FilterBase>
    </>
  );
};

export default Filter;
