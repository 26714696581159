import { DateRangeGroup } from 'types/DateRange';
import { STATION_PORTFOLIO, STATION_PORTFOLIO_GROUP } from 'utils/Constants';
import {
  RegionsMarketsTrendingAnalysis,
  ExtendedRegionsMarketsTrendingAnalysisFilter,
} from './RegionsMarketsTrendingAnalysisByStationReports';

export const transformRegionMarketsTrendingAnalysisByStationFilters = (
  filter: ExtendedRegionsMarketsTrendingAnalysisFilter | undefined,
): unknown => {
  return {
    ...filter,
    ...{
      StationPortfolio: filter?.stationGroup === STATION_PORTFOLIO ? filter?.StationPortfolio : undefined,
      StationPortfolioGroup:
        filter?.stationGroup === STATION_PORTFOLIO_GROUP ? filter?.StationPortfolioGroup : undefined,
    },
  };
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const transformItems = (i: any, DateRange: DateRangeGroup): any => {
  return {
    ...i,
    ArtistInfo: {
      Title: i.Title,
      Artist: i.Artist,
    },
    DateRange,
  };
};

export const SongSummaryGridTransformer = (v: RegionsMarketsTrendingAnalysis): RegionsMarketsTrendingAnalysis => {
  return {
    Count: v.SongSummaryItems.length,
    DateRange: v.DateRange,
    Items: v.SongSummaryItems.map((item: any) => transformItems(item, v.DateRange as DateRangeGroup)),
  };
};

export const DetailsGridTransformer = (v: RegionsMarketsTrendingAnalysis): RegionsMarketsTrendingAnalysis => {
  return {
    Count: v.Items.length,
    DateRange: v.DateRange,
    Items: v.Items.map((item: any) => transformItems(item, v.DateRange as DateRangeGroup)),
  };
};
