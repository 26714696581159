import LastSiteUpdate from 'components/LastSiteUpdate';
import AppSettings from 'contexts/AppSettingsContext';
import React, { useContext } from 'react';
import Styles from './styles';

const ClassicViewHeader = (): JSX.Element => {
  const classes = Styles();
  const { logoURL } = useContext(AppSettings);
  return (
    <>
      <div className={classes.logoContainer}>
        <img src={logoURL} alt="logo" className={classes.logo} />
        <LastSiteUpdate />
      </div>
    </>
  );
};

export default ClassicViewHeader;
