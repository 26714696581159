/* eslint-disable no-shadow */
import { ReportTypeEnum } from 'types/ReportType';
import ActivatorChartReport from 'pages/Reports/7Day/Chart/ActivactorChart/ActivactorChartReport';
import CurrentChartReport from './DailyCurrent/Chart/CurrentChartReport/CurrentChartReport';
import CurrentMusicChartReport from './DailyCurrent/Chart/CurrentMusicChartReport/CurrentMusicChartReport';
import CurrentOneDayReport from './DailyCurrent/Songs/CurrentOneDayReport';
import CurrentSongsReport from './DailyCurrent/Songs/CurrentSongsReport';
import CurrentDayMonitorsReport from './DailyCurrent/StationMonitor/CurrentDayMonitorsReport/CurrentDayMonitorsReport';
import DailyLogsReport from './DailyCurrent/StationMonitor/DailyLogs';
import BuildingChartReport from './7Day/Chart/BuildingChartReport/BuildingChartReport';
import MarketChartReport from './7Day/Chart/MarketChartReport';
import RollingChartReport from './7Day/Chart/RollingChartReport';
import PublishedChartReport from './7Day/Chart/PublishedChartReport/PublishedChartReport';
import BigPictureChartReport from './7Day/Chart/BigPictureChartReport/BigPictureChartReport';
import ArtistChartReport from './7Day/Chart/ArtistChartReport/ArtistChartReport';
import SongComparisonReport from './7Day/Songs/SongComparisonReport/SongComparisonReport';
import SongAnalysisReport from './7Day/Songs/SongAnalysisReport/SongAnalysisReport';
import Songs from './7Day/Songs';
import ArtistByStationReport from './7Day/StationMonitor/ArtistByStation/ArtistByStationReport';
import StationPlaylistsReport from './7Day/StationMonitor/StationPlaylistsReport/StationPlaylistsReport';
import StationSpinGridReport from './7Day/StationMonitor/StationSpinGridReport/StationSpinGridReport';
import ArtistAnalysisReport from './7Day/Songs/ArtistAnalysisReport/ArtistAnalysisReport';
import IndieChartReport from './7Day/Chart/IndieChartReport/IndieChartReport';

export enum ReportMenuEnum {
  ArchivedMenu = 37,
  YearToDate = 42,
  StationMenu = 43,
  SongsMenu = 49,
  CustomMenu = 51,
  TunedInMonitorMenu = 60,
  StationsMenu = 61,
  MisMenu = 108,
  Streaming = 89,
}

export enum ReportBaseEnum {
  EmptyReport = 0,
  DailyLogsReport = 3,
  CurrentDayMonitorsReport = 4,
  CurrentChartReport = 6,
  CurrentMusicChartReport = 7,
  CurrentSongsReport = 9,
  CurrentOneDayReport = 10,
  StationPlaylistsReport = 13,
  ArtistByStationReport = 14,
  PublishedChartReport = 22,
  BuildingChartReport = 23,
  RollingChartReport = 24,
  ArtistChartReport = 26,
  MarketChartReport = 27,
  IndieChartReport = 30,
  SongAnalysisReport = 32,
  SongArtistAnalysisReport = 35,
  SongComparisonReport = 36,
  BigPictureChartReport = 128,
  SongsRegionsMarketsSongTracking = 135,
  SongsRegionsMarketsTrendingAnalysis = 137,
  StationSpinGridReport = 140,
  SongsRegionsMarketsTrendingAnalysisByStation = 143,
  ActivatorChartReport = 129,
  MiscAddBoardReport = 108,
}

export enum ReportExternalUrl {
  AddBoardReport = 109,
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface ReportSpec {
  key: number;
  type?: number;
  component?: any;
  path: string;
  stickyKey: string;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const createSpecElement = (key: number, stickyKey: string, component: any): ReportSpec => {
  const path = `/mbapp/${stickyKey}`;
  return { key, path, stickyKey, component };
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const create = (key: number, stickyKey: string, component: any = null, type: number): ReportSpec => {
  const path = `/mbapp/${stickyKey}`;
  return { key, path, stickyKey, component, type };
};

export const filterSearchAllFormat = (reportTypeEnum: ReportTypeEnum): boolean => {
  switch (reportTypeEnum) {
    case ReportTypeEnum.RegionsMarketsSongTracking:
      return true;
    case ReportTypeEnum.RegionsMarketsSideBySide:
      return true;
    case ReportTypeEnum.RegionsMarketsTrendingAnalysis:
      return true;
    case ReportTypeEnum.RegionsMarketsTrendingAnalysisByStation:
      return true;
    default:
      return false;
  }
};

export const getReportSpec = (reportBaseEnum: ReportBaseEnum): ReportSpec => {
  const R = ReportBaseEnum;
  const T = ReportTypeEnum;

  switch (reportBaseEnum) {
    // DAILY-CURRENT:Charts
    case R.CurrentChartReport:
      return create(R.CurrentChartReport, 'CurrentChartReport', CurrentChartReport, T.CurrentChart);
    case R.CurrentMusicChartReport:
      return create(R.CurrentMusicChartReport, 'CurrentMusicChartReport', CurrentMusicChartReport, T.CurrentMusicChart);
    // DAILY-CURRENT:Songs
    case R.CurrentSongsReport:
      return create(R.CurrentSongsReport, 'CurrentSongsReport', CurrentSongsReport, T.CurrentSong);
    case R.CurrentOneDayReport:
      return create(R.CurrentOneDayReport, 'CurrentOneDayReport', CurrentOneDayReport, T.CurrentSongOneDay);

    // DAILY-CURRENT:Station and Monitor
    case R.CurrentDayMonitorsReport:
      return create(
        R.CurrentDayMonitorsReport,
        'CurrentDayMonitorsReport',
        CurrentDayMonitorsReport,
        T.CurrentMonitors,
      );
    case R.DailyLogsReport:
      return create(R.DailyLogsReport, 'DailyLogsReport', DailyLogsReport, T.DailyLogs);

    // 7-DAYs:Charts
    case R.PublishedChartReport:
      return create(R.PublishedChartReport, 'PublishedChartReport', PublishedChartReport, T.SevenDayPublishedChart);
    case R.BuildingChartReport:
      return create(R.BuildingChartReport, 'BuildingChartReport', BuildingChartReport, T.SevenDayBuildingChart);
    case R.RollingChartReport:
      return create(R.RollingChartReport, 'RollingChartReport', RollingChartReport, T.SevenDayRollingChart);
    case R.BigPictureChartReport:
      return create(R.BigPictureChartReport, 'BigPictureChartReport', BigPictureChartReport, T.BigPicture);
    case R.ArtistChartReport:
      return create(R.ArtistChartReport, 'ArtistChartReport', ArtistChartReport, T.ArtistChart);
    case R.MarketChartReport:
      return create(R.MarketChartReport, 'MarketChartReport', MarketChartReport, T.MarketChart);
    case R.ActivatorChartReport:
      return create(R.ActivatorChartReport, 'ActivatorChartReport', ActivatorChartReport, T.ActivatorChartReport);
    case R.IndieChartReport:
      return create(R.IndieChartReport, 'IndieChartReport', IndieChartReport, T.IndieChart);

    // 7-DAYs:Songs
    case R.SongAnalysisReport:
      return create(R.SongAnalysisReport, 'SongAnalysisReport', SongAnalysisReport, T.SongAnalysis);
    case R.SongsRegionsMarketsTrendingAnalysis:
      return create(
        R.SongsRegionsMarketsTrendingAnalysis,
        'RegionsMarketsTrendingAnalysis',
        Songs,
        T.RegionsMarketsTrendingAnalysis,
      );
    case R.SongsRegionsMarketsTrendingAnalysisByStation:
      return create(
        R.SongsRegionsMarketsTrendingAnalysisByStation,
        'RegionsMarketsTrendingAnalysisByStation',
        Songs,
        T.RegionsMarketsTrendingAnalysisByStation,
      );
    case R.SongsRegionsMarketsSongTracking:
      return create(
        R.SongsRegionsMarketsSongTracking,
        'RegionsMarketsSongTracking',
        Songs,
        T.RegionsMarketsSongTracking,
      );
    case R.SongComparisonReport:
      return create(R.SongComparisonReport, 'SongComparisonReport', SongComparisonReport, T.HeadToHeadComparison);
    case R.SongArtistAnalysisReport:
      return createSpecElement(R.SongArtistAnalysisReport, 'ArtistAnalysisReport', ArtistAnalysisReport);

    // 7-DAYs:StationMonitor
    case R.StationPlaylistsReport:
      return create(R.SongArtistAnalysisReport, 'StationPlaylistsReport', StationPlaylistsReport, T.StationPlaylists);
    case R.ArtistByStationReport:
      return create(R.ArtistByStationReport, 'ArtistByStationReport', ArtistByStationReport, T.ArtistByStation);
    case R.StationSpinGridReport:
      return create(R.StationSpinGridReport, 'StationSpinGridReport', StationSpinGridReport, T.StationSpinGridReport);

    // 7-DAYs:StationMonitor
    case R.MiscAddBoardReport:
      return create(R.MiscAddBoardReport, 'navigation-menus', undefined, T.AddBoard);

    // default
    default:
      return { key: ReportBaseEnum.EmptyReport, path: '', stickyKey: '', component: null };
  }
};
