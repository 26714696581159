/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
import { Column, ColumnApi, ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import InnerTable from '..';
import { IInnerTableGroup } from '../inner-table-group';

interface DynamicHeaderInnerTableProps {
  data: any;
  title: string;
  field: string;
  invisibleColumns: Array<Column>;
  translateHeader?: boolean;
  dynamicTitle?: boolean;
  columnApi: ColumnApi;
  colId?: string;
}

const DynamicHeaderInnerTable: React.FC<DynamicHeaderInnerTableProps> = ({
  data,
  title,
  field,
  invisibleColumns,
  translateHeader = false,
  dynamicTitle = false,
  columnApi,
  colId = field,
}: DynamicHeaderInnerTableProps) => {
  const { t } = useTranslation();

  const composeHeader = () => {
    return (
      invisibleColumns[0].getColDef().headerComponentParams.secondLine ?? []
    ).map((x: { label: string; sortPath: string }) => (translateHeader ? t(x.label) : x.label));
  };

  const composeTitle = () => {
    if (dynamicTitle) {
      const { headerName } = invisibleColumns[0].getColDef();
      return headerName === undefined ? t(title) : headerName;
    }
    return t(title);
  };

  const getValue = (value: any): string => {
    const colDef = columnApi
      ?.getAllColumns()
      .find(c => c.getColId() === colId)
      ?.getColDef();
    const valueFormatter = colDef?.valueFormatter;

    if (valueFormatter instanceof Function) {
      try {
        return valueFormatter({ ...colDef, value } as ValueFormatterParams);
      } catch {
        return value;
      }
    }
    return value;
  };

  const innerTableData: IInnerTableGroup[] = [
    {
      title: composeTitle(),
      children: [
        {
          title: '',
          children: composeHeader(),
        },
        {
          title: '',
          children: data[field] ? Object.values(data[field]).map(getValue) : [],
        },
      ],
    },
  ];

  return <InnerTable key={v4()} data={innerTableData} />;
};

export default DynamicHeaderInnerTable;
