import React from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderComponentParams } from 'types/HeaderComponentParams';
import SortableColumnText from '../SortableColumnText/SortableColumnText';

const StationRankMarketFormatHeaderComponent: React.FC<HeaderComponentParams> = props => {
  const { t } = useTranslation();
  const { sortModel, onSortChange } = props;
  return (
    <div>
      <span>
        <SortableColumnText
          currentSortModel={sortModel}
          onSortChange={onSortChange}
          sortable
          sortPath="StationDisplayName"
        >
          {t('grid.header.StationName')}
        </SortableColumnText>
        <SortableColumnText currentSortModel={sortModel} onSortChange={onSortChange} sortable sortPath="MarketRank">
          {`, ${t('grid.header.Rank')}`}
        </SortableColumnText>
      </span>
      <br />
      <span>
        <SortableColumnText currentSortModel={sortModel} onSortChange={onSortChange} sortable sortPath="MarketName">
          {t('grid.header.Market').toUpperCase()}
        </SortableColumnText>
        <SortableColumnText currentSortModel={sortModel} onSortChange={onSortChange} sortable sortPath="FormatName">
          {`, ${t('grid.header.FormatName').toUpperCase()}`}
        </SortableColumnText>
      </span>
    </div>
  );
};

export default StationRankMarketFormatHeaderComponent;
