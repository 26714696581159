/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-indent */
import { Grid, Typography } from '@material-ui/core';
import AppSettings from 'contexts/AppSettingsContext';
import React, { FC, useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Container, useStyles } from './styles';

const Support: FC = () => {
  const { t } = useTranslation();
  const { phoneNumber, email } = useContext(AppSettings);
  const styles = useStyles();
  return (
    <>
      <Container container direction="column" spacing={0} alignContent="stretch" alignItems="flex-start">
        <Grid className={styles.root} item xs={12}>
          <Typography variant="h2">{t('page.support.title')}</Typography>

          <Typography variant="body1">
            <Trans i18nKey="page.support.phone">
              To get any help or support please call:
              <br />
              Phone#: {phoneNumber}
            </Trans>
          </Typography>

          <Typography variant="body1">
            {t('page.support.email')}
            <a href={`mailto:${email}`}>{email}</a>
          </Typography>
        </Grid>
      </Container>
    </>
  );
};

export default Support;
