import {
  SevenDayArtistChartResponse,
  SevenDayArtistChartItem,
  SevenDayArtistChartTransformedItem,
  SevenDayArtistExecutiveDetailChartItemTransformedItem,
  SevenDayArtistExecutiveDetailChartItem,
  SevenDayArtistExecutiveDetailChartResponse,
} from './ArtistChartTypes';

export const SevenDayArtistChartTransformer = (
  sevenDayArtistChartResponse: SevenDayArtistChartResponse,
): SevenDayArtistChartResponse => {
  const transformItems = (row: SevenDayArtistChartItem): SevenDayArtistChartTransformedItem => {
    return {
      ...row,
      RankInfo: { ThisWeek: row.RankThisWeek, LastWeek: row.RankLastWeek },
      ImpressionsThisWeek: row.ImpressionsInfo.SpinsThisWeek,
    };
  };
  return {
    ...sevenDayArtistChartResponse,
    Items: sevenDayArtistChartResponse.Items.map(transformItems),
  };
};

export const SevenDayArtistExecutiveDetailChartTransformer = (
  sevenDayArtistChartResponse: SevenDayArtistExecutiveDetailChartResponse,
): SevenDayArtistExecutiveDetailChartResponse => {
  const transformItems = (
    row: SevenDayArtistExecutiveDetailChartItem,
  ): SevenDayArtistExecutiveDetailChartItemTransformedItem => {
    return {
      ...row,
      RankInfo: { ThisWeek: row.RankThisWeek, LastWeek: row.RankLastWeek },
      ImpressionsThisWeek: row.ImpressionsInfo.SpinsThisWeek,
    };
  };
  return {
    ...sevenDayArtistChartResponse,
    Items: sevenDayArtistChartResponse.Items.map(transformItems),
  };
};
