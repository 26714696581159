import GenericSelect from 'components/Filter/components/GenericSelect';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilterDataProps from 'types/FilterDataProps';
import { SpinModeCycleType } from 'types/SpinModeCycle';

interface FilterData {
  SpinMode: number;
}

const options = [
  { Name: 'filter.spinModeCycle.options.withSpinOnly', Id: 1 },
  { Name: 'filter.spinModeCycle.options.noSpinsOnly', Id: 2 },
  { Name: 'filter.spinModeCycle.options.allStation', Id: 0 },
];

const SpinModeCycle: React.FC<FilterDataProps<FilterData>> = ({
  onChange,
  initialValue,
}: FilterDataProps<FilterData>) => {
  const { t } = useTranslation();
  const [items] = useState(options);

  const handleSelect = (event: { value: unknown }) => {
    const value = event.value as SpinModeCycleType;
    onChange({ value: { SpinMode: value.Id } });
  };

  const selectedValue = () => {
    return options.find(v => v.Id === initialValue);
  };

  return (
    <GenericSelect
      title={t('filter.spinModeCycle.title')}
      data={items}
      onChange={handleSelect}
      initialValue={selectedValue()}
    />
  );
};

export default SpinModeCycle;
