/* eslint-disable @typescript-eslint/no-explicit-any */
import { MenuItem, Select } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Styles from './styles';
import './index.scss';

interface SelectInputProps {
  normalizeLabel?: { (item: any): string } | null;
  optionLabel?: string;
  optionValue?: string;
  value: string | any;
  options: any[];
  onChangeValue: (value: any) => void;
}

const SelectInput: React.FC<SelectInputProps> = ({
  normalizeLabel,
  optionLabel,
  optionValue,
  value,
  options,
  onChangeValue,
}: SelectInputProps) => {
  const { t } = useTranslation();
  const classes = Styles();
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChangeValue(event.target.value);
  };

  const iconComponent = (propsIcon: any) => {
    return <ExpandMoreIcon className={`${propsIcon.className}  ${classes.icon}`} />;
  };

  return (
    <Select
      data-testid="select-input"
      className={classes.select}
      value={value}
      disabled={!options?.length}
      IconComponent={iconComponent}
      onChange={handleChange}
    >
      {options.map((option: any, index: number) => (
        <MenuItem
          key={option[optionValue ?? 'id'] ?? index}
          value={optionValue ? option[optionValue] : option}
          className={classes.textSelect}
        >
          {normalizeLabel ? normalizeLabel(option) : t(option[optionLabel ?? 'name'])}
        </MenuItem>
      ))}
    </Select>
  );
};

SelectInput.defaultProps = {
  normalizeLabel: null,
  optionLabel: 'name',
  optionValue: '',
};

export default SelectInput;
