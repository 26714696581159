import ReportResult from 'services/ReportResult';
import { FilterParams } from 'types/FilterParams';
import { formatDate, formatDateTypes } from 'utils/dates';
import { ActivactorChartResponse, ActivatorChart, Items } from './ActivactorChartTypes';

export const ActivactorChartReportTransformer = (currentChartResponse: ActivatorChart): ReportResult<Items> => {
  const transformItems = (row: Items): ActivactorChartResponse => {
    return {
      ...row,
      RankInfo: { ThisWeek: row.RankThisWeek, LastWeek: row.RankLastWeek },
      SongInfo: {
        Artist: row.Artist,
        Label: row.Label,
        Title: row.Title,
      },
    };
  };
  return {
    Count: currentChartResponse.Count,
    Items: currentChartResponse.Items.map(transformItems),
  };
};

export const normalizeFilters = (filters: FilterParams): FilterParams => {
  const data = {
    DateRange: {
      Start: filters.DateRange ? formatDate(filters.DateRange.Start, formatDateTypes.yyyyMMdd) : new Date(),
      End: filters.DateRange ? formatDate(filters.DateRange.End, formatDateTypes.yyyyMMdd) : new Date(),
    },
    FormatInfo: filters.FormatInfo,
    ...(filters.GrcInfo && { GrcInfo: { GrcCode: filters.GrcInfo.GrcCode, Name: filters.GrcInfo.Name } }),
    MinSpins: filters.MinSpins ? filters.MinSpins : 1,
    TopX: filters.TopX,
    ...(filters.SongCode && { SongCode: filters.SongCode }),
  };
  return data;
};
