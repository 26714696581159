/* eslint-disable react-hooks/exhaustive-deps */
import AccordionReports from 'components/AccordionReports';
import NavigationMenu from 'contexts/NavigationMenuContext';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import { FilterState } from 'hooks/useFilterState/useFilterState';
import { noop } from 'lodash';
import { filterSearchAllFormat } from 'pages/Reports/Report';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import FilterDataEvent from 'types/FilterDataEvent';
import FilterDataProps from 'types/FilterDataProps';
import { FormatInfo } from 'types/Format';
import { PanelCodeEnum } from 'types/Panel';
import GenericMultipleSelect from '../GenericMultipleSelect';
import { calculateAllSelection } from './calculateAllSelection';
import { resolveLabel } from './labelResolver';

interface Props extends FilterDataProps<FilterData> {
  panelCode?: PanelCodeEnum;
  disabled?: boolean;
  title?: string;
  values?: FormatInfo[];
  apiPropertyName?: string;
  changeState?: (key: string, state: FilterState) => void;
  filterKey?: string;
  required?: boolean;
}
interface FilterData {
  FormatInfos?: FormatInfo[] | undefined;
}

interface FormatInfoFilterDataEventType {
  value?: FormatInfo[] | undefined;
}

const ALL_OPTION: FormatInfo = {
  Id: 0,
  Name: 'All',
  FormatCode: 'XX',
  IsGold: false,
};
const url = `/formats`;
const searchUrl = `${url}/search`;

const FormatMultipleFilter: React.FC<Props> = ({
  onChange,
  disabled = false,
  values = [],
  panelCode = PanelCodeEnum.AllUsStations,
  title = 'Formats: ',
  apiPropertyName = 'FormatInfos',
  changeState = noop,
  filterKey = 'Formats',
  required = false,
}) => {
  const { t } = useTranslation();
  const [formatsSelected, setFormatsSelected] = useState<Array<FormatInfo>>(values);
  const [formatItems, setFormatItems] = useState<Array<FormatInfo>>([]);

  const { pathname } = useLocation();
  const [isExpanded, setExpanded] = useState(false);
  const [descriptionValue, setDescriptionValue] = useState('');
  const { currentNavigationMenu } = useContext(NavigationMenu);
  const getAllFormats = filterSearchAllFormat(currentNavigationMenu?.ReportType ?? 0);
  const { data: reportData } = useRemoteApi<Array<FormatInfo>>(getAllFormats ? url : searchUrl, {
    data: !getAllFormats
      ? {
          ReportType: currentNavigationMenu?.ReportType,
          PanelCode: panelCode,
          Year: '0',
        }
      : {
          ReportType: currentNavigationMenu?.ReportType,
        },
    method: getAllFormats ? 'GET' : 'POST',
    enabled: pathname === currentNavigationMenu?.Url,
    transformResponse: data => {
      const dataSort = data.sort((a: FormatInfo, b: FormatInfo) => (a.Name > b.Name ? 1 : -1));
      return [ALL_OPTION, ...dataSort];
    },
  });

  useEffect(() => {
    setDescriptionValue(resolveLabel(values));
  }, [values]);

  const onChangeValue = (event: FilterDataEvent<FormatInfoFilterDataEventType>) => {
    if (formatItems.length > 0) {
      const { value: eventValues } = event;
      const calculatedValues = calculateAllSelection(formatItems, formatsSelected, (eventValues ?? []) as FormatInfo[]);
      updateState(calculatedValues);
    }
  };

  const controlAccordion = () => {
    const condition = !isExpanded;
    setExpanded(condition);
  };

  const translate = (tValue: string) => {
    return tValue === 'filter.title.titleFormats' ? `${t(tValue)}: ` : tValue;
  };

  const updateState = (formats: FormatInfo[]) => {
    setFormatsSelected(formats);
    onChange({ value: { FormatInfos: formats, [apiPropertyName]: formats } });
  };

  const getDefaultFormats = (data: Array<FormatInfo>) => {
    let defaultFormats: Array<FormatInfo> = [];
    data.forEach(item => {
      formatsSelected.forEach(selected => {
        if (item.FormatCode === selected.FormatCode) {
          defaultFormats.push(item);
        }
      });
    });
    const hasAllOption = defaultFormats.some(v => v.Id === ALL_OPTION.Id);
    defaultFormats = defaultFormats.length === 0 || hasAllOption ? data : defaultFormats;
    return defaultFormats;
  };

  useEffect(() => {
    if (reportData?.length) {
      setFormatItems(reportData);

      const formats = getDefaultFormats(reportData);
      updateState(formats);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData]);

  return (
    <AccordionReports
      expanded={isExpanded}
      isDisabled={disabled}
      onChangeAccordion={controlAccordion}
      firstTitle={translate(title)}
      secondTitle={t(descriptionValue)}
    >
      <GenericMultipleSelect
        onChange={onChangeValue}
        data={formatItems}
        optionValue="FormatCode"
        values={formatsSelected}
        initialValue={formatsSelected}
        changeState={(state: FilterState) => changeState(filterKey, state)}
        comparator={(v1: FormatInfo, v2: FormatInfo) =>
          (v1?.FormatCode ?? '').substring(0, 2) === (v2?.FormatCode ?? '').substring(0, 2) ? 0 : -1
        }
        required={required}
      />
    </AccordionReports>
  );
};

export default FormatMultipleFilter;
