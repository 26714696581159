import { subDays } from 'date-fns';
import { convertToDate } from 'utils/dates';
import { DateOptionParameters } from '../DefaultDateOptions';
import { DateRangeResponse } from '../utils';

function resolveApiData(v: DateRangeResponse | string): Date {
  try {
    switch (typeof v) {
      case 'string':
        return convertToDate(v);
      case 'object':
        return convertToDate(v.ThisWeekRange.End);
      default:
        return new Date();
    }
  } catch {
    return new Date();
  }
}

export function mostRecentSaturdayTransformer(v: DateRangeResponse | string): DateOptionParameters[] {
  const value = resolveApiData(v);
  const mostRecentSaturday = convertToDate(value);
  return [
    {
      label: 'thisWeek',
      start: subDays(mostRecentSaturday, 6),
      end: mostRecentSaturday,
      anchoredWeekdaySelection: true
    },
    {
      label: 'previousWeek',
      start: subDays(mostRecentSaturday, 13),
      end: subDays(mostRecentSaturday, 7),
      anchoredWeekdaySelection: true
    },
    {
      label: 'custom',
      start: subDays(mostRecentSaturday, 20),
      end: subDays(mostRecentSaturday, 14),
      anchoredWeekdaySelection: true
    },
  ];
}

export function rollingAvailabilityTransformer(v: DateRangeResponse): DateOptionParameters[] {
  const rollingEndDate = convertToDate(v.ThisWeekRange.End);
  return [
    {
      label: 'thisWeek',
      start: convertToDate(v.ThisWeekRange.Start),
      end: convertToDate(v.ThisWeekRange.End),
      anchoredWeekdaySelection: false
    },
    {
      label: 'previousWeek',
      start: convertToDate(v.LastWeekRange.Start),
      end: convertToDate(v.LastWeekRange.End),
      anchoredWeekdaySelection: false
    },
    {
      label: 'custom',
      start: subDays(rollingEndDate, 20),
      end: subDays(rollingEndDate, 14),
      anchoredWeekdaySelection: false
    },
  ];
}
