/* eslint-disable jsx-a11y/anchor-is-valid */
import { Column } from '@ag-grid-enterprise/all-modules';
import { Box, Link } from '@material-ui/core';
import { get, isNumber } from 'lodash';
import React from 'react';
import { useStyles } from './styles';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface StationMarketFormatCellRendererProps {
  column?: Column;
  data?: any;
  fields?: string[];
  reportQuery?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClickHandler?: (data: any, reportQuery: any) => void;
}

const MultiLineCellRenderer: React.FC<StationMarketFormatCellRendererProps> = ({
  column,
  fields = [],
  data,
  reportQuery = null,
  onClickHandler,
}) => {
  const classes = useStyles();
  if (!column) return <></>;

  const getData = (field: string) => {
    const value = get(data, field);
    if (isNumber(value) && value % 1 !== 0) {
      return value.toFixed(3);
    }
    return value;
  };

  const getLink = (el: JSX.Element) => {
    if (!onClickHandler) {
      return el;
    }
    return (
      <Link
        onClick={(evt: React.MouseEvent) => {
          evt.preventDefault();
          onClickHandler(data, reportQuery);
        }}
      >
        {el}
      </Link>
    );
  };

  const { field = '' } = column.getColDef();
  return (
    <Box className={classes.root}>
      <Box>{getLink(get(data, field))}</Box>
      <Box>{fields.map(f => getData(f)).join(', ')}</Box>
    </Box>
  );
};

export default MultiLineCellRenderer;
