import React from 'react';
import { useStationPorfolioGetById } from 'hooks/stationPortfolioHooks/stationPortfolioHooks';
import { StationPortfolio } from 'types/StationPortfolio';

const formatStationPortfolio = (stationPortfolio: StationPortfolio | undefined): string => {
  if (stationPortfolio) {
    return `${stationPortfolio.Name}`;
  }
  return '';
};

export type StationPorfolioDescriptionProps = { portfolioId: number };

export const StationPorfolioDescription = (args: StationPorfolioDescriptionProps): JSX.Element => {
  const { data: stationPortfolio } = useStationPorfolioGetById(args.portfolioId);
  if (stationPortfolio) {
    return <>{`Station Portfolio: ${formatStationPortfolio(stationPortfolio)}`}</>;
  }
  return <></>;
};
