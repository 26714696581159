import React from 'react';
import defaultTheme from '../../../themes/index';

interface ThisLastMonthCellRendererProps {
  value?: {
    Position: number;
    Move?: number;
  };
  showZeroMove?: boolean;
}

const ThisLastMonthCellRenderer: React.FC<ThisLastMonthCellRendererProps> = ({
  value,
  showZeroMove = false,
}: ThisLastMonthCellRendererProps) => {
  const getColor = (baseValue: number, actualValue: number) => {
    if (baseValue > actualValue) return defaultTheme.palette.error.main;
    if (baseValue < actualValue) return defaultTheme.palette.success.main;
    return defaultTheme.palette.text.primary;
  };

  const createSpan = (baseValue: number, actualValue: number, addPlus = false) => {
    return (
      <span style={{ color: getColor(baseValue, actualValue) }}>
        {(addPlus && actualValue > 0 ? ' +' : ' ') + actualValue}
      </span>
    );
  };

  const getElements = (): JSX.Element => {
    if (!value) return <></>;
    return (
      <div aria-label="Last Month" style={{ width: '100%' }}>
        <span style={{ width: 35, display: 'inline-block' }}>{createSpan(value.Position, value.Position)}</span>
        <span style={{ width: 35, display: 'inline-block' }}>
          {(!!value.Move || showZeroMove) && createSpan(0, value.Move ?? 0, true)}
        </span>
      </div>
    );
  };

  return getElements();
};

ThisLastMonthCellRenderer.defaultProps = { value: undefined };

export default ThisLastMonthCellRenderer;
