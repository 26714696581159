import { AgGridColumn } from '@ag-grid-community/react/lib/agGridColumn';
import DayPartsCellRenderer from 'components/DetailGrid/CellRenderers/DayPartsCellRenderer';
import DayPartsHeaderComponent from 'components/DetailGrid/HeaderComponents/DayPartsHeaderComponent';
import React from 'react';
import { SortModel } from 'types/HeaderComponentParams';
import { useTranslation } from 'react-i18next';

interface ColumnProps {
  sortModel: SortModel | undefined;
  onSortChange: (value: string) => void;
  maxWidth?: number;
  minWidth?: number;
}

const DayPartsInfoColumn: React.FC<ColumnProps> = ({ sortModel, onSortChange, maxWidth = 230, minWidth = 230 }) => {
  const { t } = useTranslation();
  return (
    <AgGridColumn
      headerName={t('grid.header.DayParts')}
      field="DaypartsInfo"
      maxWidth={maxWidth}
      minWidth={minWidth}
      headerComponentFramework={DayPartsHeaderComponent}
      headerComponentParams={{ sortModel, onSortChange }}
      cellRendererFramework={DayPartsCellRenderer}
    />
  );
};

export default DayPartsInfoColumn;
