import { AgGridColumn } from '@ag-grid-community/react/lib/agGridColumn';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import BooleanCanadaCheckMarkCellRenderer from 'components/DetailGrid/CellRenderers/BooleanCanadaCheckMarkCellRenderer';
import DayPartsCellRenderer from 'components/DetailGrid/CellRenderers/DayPartsCellRenderer';
import MultiColumnsCellRenderer from 'components/DetailGrid/CellRenderers/MultiColumnsCellRenderer/MultiColumnsCellRenderer';
import RankInfoCellRenderer from 'components/DetailGrid/CellRenderers/RankInfoCellRenderer/RankInfoCellRenderer';
import SongCellRenderer from 'components/DetailGrid/CellRenderers/SongCellRenderer/SongCellRenderer';
import ThisLastMoveCellRenderer from 'components/DetailGrid/CellRenderers/ThisLastMoveCellRenderer';
import DayPartsHeaderComponent from 'components/DetailGrid/HeaderComponents/DayPartsHeaderComponent';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import { UseApiSettings } from 'hooks/useApi/ApiTypes';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import { PanelCodeEnum } from 'types/Panel';
import { ReportCycleIdEnum } from 'types/ReportCycle';
import { ReportModeIdEnum } from 'types/ReportMode';
import { numberFormat } from 'utils/currency';
import { Link } from '@material-ui/core';
import { SevenDayChartUrl } from 'enums/urls';
import _ from 'lodash';
import { formatDate, formatDateTypes } from 'utils/dates';
import { BigPictureChartTransformer } from './utils/BigPictureChartTransformer';
import { BigPictureFiltersProps, BigPictureGridProps, ReportResultItem } from './utils/BigPictureChartTypes';

const BigPictureChartGrid: React.FC<BigPictureGridProps> = ({ filters, onTotalSpinsClickHandler }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({
    sortPath: 'Rank',
    sort: 'asc',
  });

  const url =
    filters.ReportMode === ReportModeIdEnum.bigPicture
      ? SevenDayChartUrl.bigPictureChartReports
      : SevenDayChartUrl.bigPictureWithHistoryChartReports;

  const { setSettings, settings, data: reportData, isLoading, isError } = useRemoteApi<ReportResultItem>(url, {
    params: { $top: 1000, $inlinecount: 'allpages', skip: 0 },
    data: filters,
    method: 'POST',
    enabled: false,
    transformRequest: (data: BigPictureFiltersProps) => {
      const copy = _.cloneDeep<BigPictureFiltersProps>(data);
      const isReportCycleBuilding = data.ReportCycle === ReportCycleIdEnum.building;
      if (isReportCycleBuilding) {
        delete copy.DateRange;
        copy.NumDays = 1;
      } else if (data.DateRange) {
        copy.DateRange = {
          Start: formatDate(data.DateRange.Start, formatDateTypes.yyyyMMdd),
          End: formatDate(data.DateRange.End, formatDateTypes.yyyyMMdd),
        };
      }
      return copy;
    },
  });

  const hideBigPictureSelectMode = () => {
    return filters?.ReportMode !== ReportModeIdEnum.bigPicture;
  };

  const hideBigPicturesHistoryAndFrench = () => {
    return (
      filters?.ReportMode !== ReportModeIdEnum.bigPictureWithHistory &&
      filters?.ReportMode !== ReportModeIdEnum.bigPictureWithHistoryFrench
    );
  };

  const formatTotalSpins = ({ value, data }: ValueFormatterParams, reportQuery: BigPictureFiltersProps) => {
    const formattedValue = numberFormat(value, undefined, 0);
    const onClick = (event: React.MouseEvent) => {
      event.preventDefault();
      onTotalSpinsClickHandler(data, { ...reportQuery });
    };
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Link style={{ textDecoration: 'underline' }} onClick={onClick}>
        {formattedValue}
      </Link>
    );
  };

  return (
    <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
      <ServerSideReportGrid
        setSettings={(setSettings as unknown) as Dispatch<UseApiSettings<ReportResult<unknown>>>}
        settings={(settings as unknown) as UseApiSettings<ReportResult<unknown>>}
        filters={filters}
        sortModel={sortModel}
        transformResponse={BigPictureChartTransformer}
      >
        <AgGridColumn
          hide={hideBigPictureSelectMode()}
          headerName={t('grid.header.OverallRank')}
          minWidth={60}
          width={60}
          field="Rank"
          headerComponentParams={{
            sortModel,
            onSortChange,
            disposition: 'row',
          }}
        />
        <AgGridColumn
          field="RankInfo"
          hide={hideBigPicturesHistoryAndFrench()}
          headerName={t(`grid.header.Rank`)}
          minWidth={60}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            disposition: 'row',
            secondLine: [
              { label: 'grid.header.TW', sortPath: 'RankThisWeek' },
              { label: 'grid.header.LW', sortPath: 'RankLastWeek' },
            ],
          }}
          cellRendererFramework={RankInfoCellRenderer}
          cellRendererParams={{ showArrow: true }}
        />
        <AgGridColumn
          field="SongInfo"
          headerName={t(`grid.header.Title`)}
          minWidth={200}
          width={200}
          pinned
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            sortPath: 'Title',
            disposition: 'row',
            secondLine: [
              { label: 'grid.header.Artist', sortPath: 'Artist' },
              { label: 'grid.header.Label', sortPath: 'Label' },
            ],
          }}
          cellRendererFramework={SongCellRenderer}
        />
        <AgGridColumn
          field="HasCanadianContent"
          headerName={t('grid.header.cancon')}
          minWidth={70}
          cellRendererFramework={BooleanCanadaCheckMarkCellRenderer}
          headerComponentParams={{ sortModel, onSortChange, sortPath: 'HasCanadianContent' }}
          hide={
            !(
              filters?.PanelInfo?.PanelCode === PanelCodeEnum.AllCanadianStations ||
              filters?.PanelInfo?.PanelCode === PanelCodeEnum.PublishedCanadaPanel
            )
          }
        />
        <AgGridColumn
          headerName={t('grid.header.TotalSpins')}
          hide={hideBigPictureSelectMode()}
          minWidth={120}
          width={120}
          cellRendererFramework={(value: ValueFormatterParams) => formatTotalSpins(value, filters)}
          field="TotalSpins"
          headerComponentParams={{
            sortModel,
            onSortChange,
            disposition: 'row',
          }}
        />
        {(reportData?.FormatByFormatColumns ?? []).map(formatByFormat => (
          <AgGridColumn
            key={formatByFormat.FormatIndex}
            field={`FormatByFormatRanks.${formatByFormat.FormatIndex}`}
            minWidth={100}
            headerName={formatByFormat.DisplayName}
            headerComponentFramework={MultiColumnsHeaderComponent}
            valueFormatter={({ value }: ValueFormatterParams) => value || '--'}
            headerComponentParams={{
              sortModel,
              onSortChange,
              disposition: 'row',
              sortPath: `FormatByFormatRanks/${formatByFormat.FormatIndex}/FormatValue`,
            }}
            cellRendererFramework={MultiColumnsCellRenderer}
            cellRendererParams={{
              fields: [`FormatByFormatRanks[${formatByFormat.FormatIndex}].FormatValue`],
            }}
          />
        ))}
        <AgGridColumn
          hide={hideBigPicturesHistoryAndFrench()}
          field="SpinsInfo"
          minWidth={110}
          headerName={t(`grid.header.SpinsInfoTW`)}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            sortPath: 'SpinsInfo/ThisWeek',
            secondLine: [
              { label: 'grid.header.LW', sortPath: 'SpinsInfo/LastWeek' },
              { label: 'grid.header.plusMinus', sortPath: 'SpinsInfo/Move' },
            ],
          }}
          cellRendererFramework={ThisLastMoveCellRenderer}
        />
        <AgGridColumn
          hide={hideBigPicturesHistoryAndFrench()}
          headerName={t('grid.header.DaypartsInfo')}
          field="DaypartsInfo"
          width={230}
          minWidth={230}
          headerComponentFramework={DayPartsHeaderComponent}
          headerComponentParams={{ sortModel, onSortChange }}
          cellRendererFramework={DayPartsCellRenderer}
        />
        <AgGridColumn
          field="ImpressionsInfo"
          hide={hideBigPicturesHistoryAndFrench()}
          minWidth={380}
          headerName={t(`grid.header.ImpressionsMill`)}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            sortPath: 'SpinsInfo/ThisWeek',
            secondLine: [
              { sortPath: 'ImpressionsInfo/SpinsThisWeek', label: ['grid.header.TW'] },
              { sortPath: 'ImpressionsInfo/SpinsLastWeek', label: ['grid.header.LW'] },
              { sortPath: 'ImpressionsInfo/Move', label: ['grid.header.Move'] },
              { sortPath: 'ImpressionsInfo/RankThisWeek', label: ['grid.header.RTW'] },
              { sortPath: 'ImpressionsInfo/RankLastWeek', label: ['grid.header.RLW'] },
            ],
          }}
          cellRendererParams={{
            fields: [
              'ImpressionsInfo.SpinsThisWeek',
              'ImpressionsInfo.SpinsLastWeek',
              'ImpressionsInfo.Move',
              'ImpressionsInfo.RankThisWeek',
              'ImpressionsInfo.RankLastWeek',
            ],
          }}
          cellRendererFramework={MultiColumnsCellRenderer}
        />
        <AgGridColumn
          hide={hideBigPictureSelectMode()}
          field="ReleaseDate"
          minWidth={80}
          width={80}
          headerName={t(`grid.header.Year`)}
          headerComponentParams={{ sortModel, onSortChange }}
        />
        <AgGridColumn
          hide={hideBigPictureSelectMode()}
          field="ImpressionsSingle"
          minWidth={120}
          width={120}
          headerName={t(`grid.header.ImpressionsMill`)}
          headerComponentParams={{ sortModel, onSortChange }}
        />
        <AgGridColumn
          hide={hideBigPicturesHistoryAndFrench()}
          field="SpinsToDate"
          minWidth={100}
          width={100}
          valueFormatter={({ value }: ValueFormatterParams) => numberFormat(value, undefined, 0)}
          headerName={t(`grid.header.TD`)}
          headerComponentParams={{ sortModel, onSortChange }}
        />
      </ServerSideReportGrid>
    </GridVisibilityWrapper>
  );
};

export default BigPictureChartGrid;
