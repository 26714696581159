import { MenuItem, TextField } from '@material-ui/core';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import FilterDataProps from 'types/FilterDataProps';
import { ExpandMore } from '@material-ui/icons';
import { OwnerInfo } from 'types/OwnerTypes';
import { Container, OwnerLabel, useStyles } from './styles';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props extends FilterDataProps<any> {
  disabled?: boolean;
  label?: string;
  apiPropertyName?: string;
}

const url = '/owners';
const ALL_OPTION = {
  Id: -1,
  Name: 'All',
};

const OwnerSelection: React.FC<Props> = ({
  disabled = false,
  onChange = null,
  label = '',
  initialValue = ALL_OPTION,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const nameSelect = t('fields.Select');
  const [availableValues, setAvailableValues] = useState<OwnerInfo[]>([]);
  const [selectedValue, setSelectedValue] = useState<OwnerInfo>();

  const { data: reportData } = useRemoteApi<ReportResult<OwnerInfo[]>>(url, {
    method: 'GET',
    enabled: true,
    transformResponse: data => {
      return [ALL_OPTION, ...data];
    },
  });

  useEffect(() => {
    if (availableValues.length) {
      setSelectedValue(initialValue);
    }
  }, [initialValue, availableValues]);

  useEffect(() => {
    if (onChange && selectedValue && !disabled) {
      onChange({ value: { OwnerInfo: selectedValue } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  useEffect(() => {
    const data = (reportData || []) as OwnerInfo[];
    setAvailableValues(data);
  }, [reportData]);

  const handleChange = (ev: React.ChangeEvent<{ value: unknown }>): void => {
    const { value } = ev.target;
    const foundOption = availableValues.find(v => v.Id === value);
    setSelectedValue(foundOption ?? ALL_OPTION);
  };

  const iconComponent = () => {
    return <ExpandMore className={classes.icon} />;
  };

  return (
    <Container>
      <OwnerLabel>OWNER: </OwnerLabel>
      <TextField
        select
        label={label}
        InputProps={{
          classes: {
            input: classes.root,
          },
        }}
        className={classes.root}
        SelectProps={{ IconComponent: iconComponent }}
        value={selectedValue?.Id ?? ''}
        onChange={handleChange}
        disabled={disabled}
        aria-label={t('filter.title.Owner')}
      >
        <MenuItem key="item-1" value="1">
          {nameSelect}
        </MenuItem>
        {availableValues.map(item => {
          return (
            <MenuItem key={item.Id} value={item.Id}>
              {item.Name}
            </MenuItem>
          );
        })}
      </TextField>
    </Container>
  );
};

export default OwnerSelection;
