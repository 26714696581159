import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      width: '100%',
      '& ul li': {
        justifyContent: 'flex-end',
        cursor: 'default',
      },

      '& ul li .MuiBox-root': {
        textAlign: 'center',
        width: '40px',
        lineHeight: 'inherit',
      },
      '& .rdrCalendarWrapper': {},
    },
    header: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeader: {
      fontSize: theme.typography.pxToRem(15),
      justifyContent: 'flex-end',
      display: 'flex',
      width: '100%',
    },
  });
});
