import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import { Routes } from 'types/Routes';

export const menuEntries: Routes[] = [
  {
    path: getReportSpec(ReportBaseEnum.CurrentDayMonitorsReport).path,
    component: getReportSpec(ReportBaseEnum.CurrentDayMonitorsReport).component,
  },
  {
    path: getReportSpec(ReportBaseEnum.DailyLogsReport).path,
    component: getReportSpec(ReportBaseEnum.DailyLogsReport).component,
  },
];
