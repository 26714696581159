/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Collapse,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import { usePrevious } from 'hooks/usePrevious/usePrevious';
import { noop } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Range } from 'react-date-range';
import { useTranslation } from 'react-i18next';
import FilterDataProps from 'types/FilterDataProps';
import { formatDateTypes } from 'utils/dates';
import DateOptions from './DateOptions';
import DateRangeSelector from './DateRangeSelector';
import { DateOptionParameters, defaultOptions } from './DefaultDateOptions';
import { useStyles } from './styles';
import { DatesUrlEnum } from './utils';
import { isSameDayRange } from './utils/isSameDayRange';
import { labelResolver } from './utils/labelResolver';
import { mostRecentSaturdayTransformer, rollingAvailabilityTransformer } from './utils/mostRecentSaturdayTransformer';
import { optionFromDateRangeResolver } from './utils/optionFromDateRangeResolver';
import { rangeResolver } from './utils/rangeResolver';

interface DataRangeFlags {
  IsThisWeek?: boolean;
  IsLastWeek?: boolean;
  IsCustom?: boolean;
  MaxDate?: Date;
  MinDate?: Date;
}

export interface CalendarFilterFilterData extends DataRangeFlags {
  DateRange: {
    Start: Date;
    End: Date;
  };
  EndDate: Date;
}

interface CalendarFilterProps extends FilterDataProps<CalendarFilterFilterData> {
  dateOptionsParameters?: 'day' | 'week' | 'month' | 'weeklyDaySunday' | 'dailyLog';
  dateFormat?: formatDateTypes | string;
  maxDate?: Date | undefined;
  getDateFromApi?: boolean;
  disabled?: boolean;
  dateEnd?: string | Date;
  url?: DatesUrlEnum;
}

const titleMap = new Map([
  ['day', 'filter.title.dayRange'],
  ['week', 'filter.title.weekRange'],
  ['weeklyDaySunday', 'filter.title.weekRange'],
  ['month', 'filter.title.monthRange'],
  ['dailyLog', 'filter.title.dayRange'],
]);

/**
 * A date picker component, this can be used to select a fixed range of dates or a single date
 * @param dateOptionsParameters defines the range for this picker
 * @param dateFormat defines the returned format for this component
 * @param onChange function event for every change for this component
 */
const CalendarFilter: React.FC<CalendarFilterProps> = ({
  dateOptionsParameters = 'week',
  dateFormat = formatDateTypes.MMddyyyy,
  onChange = noop,
  initialValue,
  disabled = false,
  getDateFromApi = false,
  maxDate = undefined,
  dateEnd,
  url = DatesUrlEnum.MOST_RECENT_SATURDAY,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const [options, setOptions] = useState<DateOptionParameters[]>(() => defaultOptions[dateOptionsParameters]);
  const [expanded, setExpanded] = useState(false);
  const [dates, setDates] = useState<Range | undefined>();
  const previousDates = usePrevious(dates);
  const previousInitialValue = usePrevious(initialValue);

  const { data: apiData } = useRemoteApi<DateOptionParameters[]>(url, {
    method: 'GET',
    enabled: getDateFromApi,
    params: url,
    transformResponse:
      url === DatesUrlEnum.MOST_RECENT_SATURDAY ? mostRecentSaturdayTransformer : rollingAvailabilityTransformer,
  });

  const getMaxDate = (): Date | undefined => {
    if (maxDate) {
      return maxDate;
    }
    if (apiData && apiData.length > 0) {
      return apiData[0].end;
    }
    return undefined;
  };

  useEffect(() => {
    if (getDateFromApi && apiData && apiData.length) {
      setOptions(apiData);
      setDates(rangeResolver(apiData[0]));
    }
  }, [apiData, dateEnd]);

  // const delayedEvent = useRef(debounce(value => onChange(value), 10));

  useEffect(() => {
    if (!dates || isSameDayRange(dates, previousDates)) return;
    const selectedOption = optionFromDateRangeResolver(dates, options)?.label;
    const { startDate: Start, endDate: End } = dates;
    const value = {
      DateRange: {
        Start: Start ?? new Date(),
        End: End ?? new Date(),
      },
      EndDate: End ?? new Date(),
      IsThisWeek: selectedOption === 'thisWeek',
      IsLastWeek: selectedOption === 'previousWeek',
      IsCustom: selectedOption === 'custom',
      MaxDate: getMaxDate(),
    };
    onChange({ value });
    // delayedEvent.current({ value });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dates]);

  useEffect(() => {
    if (!initialValue || isSameDayRange(previousInitialValue, initialValue)) return;

    const initialValueRange = rangeResolver({ DateRange: initialValue } as CalendarFilterFilterData);
    setDates(initialValueRange);
  }, [initialValue]);

  const handleCustom = (range: Range) => {
    if (range.startDate && range.endDate) {
      setDates(range);
    }
  };

  const handleSelectedOptionChange = (id: string) => {
    const selOpt = options.find(o => o.label === id);
    if (selOpt) {
      setDates(rangeResolver(selOpt));
      if (selOpt.label !== 'custom') {
        setExpanded(false);
      }
    }
  };

  // I wanted to name this effect as "delayedChoiceQuantumEraser" but
  // it wouldn't make many sense to most of people
  // const delayedDefaults = useRef(
  //   debounce((dates, options, initialValue) => {
  //     if (!dates && !!options?.length && !initialValue) {
  //       setDates(rangeResolver(options[0]));
  //     }
  //   }, 1000),
  // );

  // useEffect(() => {
  //   delayedDefaults.current(dates, options, initialValue);
  // }, [options]);

  const isCustomOptions = () => {
    const result = optionFromDateRangeResolver(dates, options)?.label === 'custom';
    return result;
  };
  const getCustomAnchoredWeekdaySelection = () => {
    const result = (options ?? []).find(v => v.label === 'custom')?.anchoredWeekdaySelection;
    return result;
  };

  return (
    <>
      <Accordion aria-label="Date Picker" expanded={expanded} disabled={disabled} className={classes.root}>
        <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" />} onClick={() => setExpanded(!expanded)}>
          <Typography variant="body1" className={classes.header}>
            {t(titleMap.get(dateOptionsParameters as string) ?? '')}
          </Typography>
          <Typography variant="body1" className={classes.secondaryHeader}>
            {labelResolver(dates, dateFormat)}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container direction="column">
            <Grid item xs>
              <DateOptions dateOptions={options} onChange={handleSelectedOptionChange} dates={dates} />
            </Grid>
            <Collapse in={isCustomOptions()}>
              <Grid item xs>
                <Box display="flex" justifyContent="flex-end">
                  <DateRangeSelector
                    dates={dates}
                    anchoredWeekDay={getCustomAnchoredWeekdaySelection()}
                    onChange={handleCustom}
                    maxDate={getMaxDate()}
                  />
                </Box>
              </Grid>
              <Grid item xs>
                <Divider />
                <Typography variant="body1" align="right">
                  <Button onClick={() => setExpanded(false)} color="primary">
                    {t(`filter.label.apply`)}
                  </Button>
                </Typography>
              </Grid>
            </Collapse>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default CalendarFilter;
