import { Grid } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

export const Container = styled(Grid)`
  display: flex;
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputSearch: {
      marginTop: '10px',
      width: '100%',
      borderRadius: `0`,
    },
    icon: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  }),
);
