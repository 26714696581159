import { AppSettingsProvider } from 'contexts/AppSettingsContext';
import { AuthSettingsProvider } from 'contexts/AuthContext';
import 'i18n/translations';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppFullStory from 'components/Analytics/AppFullStory';
import AppRoutes from './routes';

const App: React.FC = () => (
  <AppSettingsProvider>
    <AuthSettingsProvider>
      <BrowserRouter>
        <AppFullStory />
        <AppRoutes />
      </BrowserRouter>
    </AuthSettingsProvider>
  </AppSettingsProvider>
);

export default App;
