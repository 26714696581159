/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */
import { AgGridColumn } from '@ag-grid-community/react/lib/agGridColumn';
import React from 'react';
import { formatDate, formatDateTypes } from 'utils/dates';
import {
  ValueFormatterParams,
  ClientSideRowModelModule,
  MasterDetailModule,
  ServerSideRowModelModule,
} from '@ag-grid-enterprise/all-modules';
import MultiColumnsCellRenderer from 'components/DetailGrid/CellRenderers/MultiColumnsCellRenderer/MultiColumnsCellRenderer';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import DetailGrid from 'components/DetailGrid';
import AccordionGrid from 'components/AccordionGrid';
import { v4 as uuid } from 'uuid';
import { Link } from '@material-ui/core';
import { StationSpinGridRow, TransformStationSpinGridRow } from './utils/StationSpinGridTypes';

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
function CreateDetailGrid(
  index: number,
  chunkReportDataItem: StationSpinGridRow[],
  t: any,
  onSpinDateClickHandler?: any,
): JSX.Element {
  const fv = (value: number) => {
    return value > 0 ? value : '-';
  };
  const transformItems = (row: StationSpinGridRow): TransformStationSpinGridRow => {
    return {
      ...row,
      OverNightInfo: [
        fv(row.HourDataItem[0]),
        fv(row.HourDataItem[1]),
        fv(row.HourDataItem[2]),
        fv(row.HourDataItem[3]),
        fv(row.HourDataItem[4]),
        fv(row.HourDataItem[5]),
      ],
      AmDriveInfo: [fv(row.HourDataItem[6]), fv(row.HourDataItem[7]), fv(row.HourDataItem[8]), fv(row.HourDataItem[9])],
      MidDayInfo: [
        fv(row.HourDataItem[10]),
        fv(row.HourDataItem[11]),
        fv(row.HourDataItem[12]),
        fv(row.HourDataItem[13]),
        fv(row.HourDataItem[14]),
      ],
      PmDriveInfo: [
        fv(row.HourDataItem[15]),
        fv(row.HourDataItem[16]),
        fv(row.HourDataItem[17]),
        fv(row.HourDataItem[18]),
      ],
      NightTimeInfo: [
        fv(row.HourDataItem[19]),
        fv(row.HourDataItem[20]),
        fv(row.HourDataItem[21]),
        fv(row.HourDataItem[22]),
        fv(row.HourDataItem[23]),
      ],
    };
  };

  const formatSpinDate = ({ value, data }: ValueFormatterParams) => {
    const formattedValue = value ? formatDate(value, formatDateTypes.MMMd) : '--';
    const onClick = (event: React.MouseEvent) => {
      event.preventDefault();
      onSpinDateClickHandler(data);
    };
    /* eslint-disable jsx-a11y/anchor-is-valid */
    return value ? (
      <Link style={{ textDecoration: 'underline' }} onClick={onClick}>
        {formattedValue}
      </Link>
    ) : (
      formattedValue
    );
  };

  return (
    <AccordionGrid
      key={uuid()}
      title={index < 1 ? 'Seven Day' : `${index} Week Ago`}
      expanded={index < 1}
      complement={` Total spins: ${chunkReportDataItem.reduce((a, b) => a + (b.TotalSpins || 0), 0)}`}
    >
      <DetailGrid
        reportData={chunkReportDataItem.map(transformItems)}
        key={uuid()}
        modules={[ServerSideRowModelModule, MasterDetailModule, ClientSideRowModelModule]}
      >
        <AgGridColumn
          field="SpinDate"
          minWidth={60}
          width={60}
          pinned
          headerName={t(`grid.header.Day`)}
          valueFormatter={({ value }: ValueFormatterParams) => (value ? formatDate(value, formatDateTypes.iii) : '--')}
        />
        <AgGridColumn
          field="SpinDate"
          minWidth={60}
          width={60}
          pinned
          headerName={t(`grid.header.Date`)}
          cellRendererFramework={formatSpinDate}
        />
        <AgGridColumn field="TotalSpins" minWidth={40} width={40} headerName={t(`grid.header.TotalSpins`)} />
        <AgGridColumn
          field="OverNightInfo"
          headerName={t('grid.header.SpinsGrid.Overnight')}
          width={300}
          minWidth={300}
          maxWidth={300}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            secondLine: [
              { label: `12am` },
              { label: `1am` },
              { label: `2am` },
              { label: `3am` },
              { label: `4am` },
              { label: `5am` },
            ],
          }}
          cellRendererFramework={MultiColumnsCellRenderer}
          cellRendererParams={{
            fields: [
              'OverNightInfo[0]',
              'OverNightInfo[1]',
              'OverNightInfo[2]',
              'OverNightInfo[3]',
              'OverNightInfo[4]',
              'OverNightInfo[5]',
            ],
          }}
        />
        <AgGridColumn
          field="AmDriveInfo"
          headerName={t('grid.header.SpinsGrid.AmDrive')}
          width={180}
          minWidth={180}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            secondLine: [{ label: `6am` }, { label: `7am` }, { label: `8am` }, { label: `9am` }],
          }}
          cellRendererFramework={MultiColumnsCellRenderer}
          cellRendererParams={{
            usePercentual: true,
            fields: ['AmDriveInfo[0]', 'AmDriveInfo[1]', 'AmDriveInfo[2]', 'AmDriveInfo[3]'],
          }}
        />
        <AgGridColumn
          field="MidDayInfo"
          headerName={t('grid.header.SpinsGrid.Midday')}
          width={220}
          minWidth={220}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            secondLine: [{ label: `10am` }, { label: `11am` }, { label: `12pm` }, { label: `1pm` }, { label: `2pm` }],
          }}
          cellRendererFramework={MultiColumnsCellRenderer}
          cellRendererParams={{
            fields: ['MidDayInfo[0]', 'MidDayInfo[1]', 'MidDayInfo[2]', 'MidDayInfo[3]', 'MidDayInfo[4]'],
          }}
        />
        <AgGridColumn
          field="PmDriveInfo"
          headerName={t('grid.header.SpinsGrid.PmDrive')}
          width={200}
          minWidth={200}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            secondLine: [{ label: `3pm` }, { label: `4pm` }, { label: `5pm` }, { label: `6pm` }],
          }}
          cellRendererFramework={MultiColumnsCellRenderer}
          cellRendererParams={{
            fields: ['PmDriveInfo[0]', 'PmDriveInfo[1]', 'PmDriveInfo[2]', 'PmDriveInfo[3]'],
          }}
        />
        <AgGridColumn
          field="NightTimeInfo"
          headerName={t('grid.header.SpinsGrid.Nightime')}
          width={220}
          minWidth={220}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            secondLine: [{ label: `7pm` }, { label: `8pm` }, { label: `9pm` }, { label: `10pm` }, { label: `11pm` }],
          }}
          cellRendererFramework={MultiColumnsCellRenderer}
          cellRendererParams={{
            fields: [
              'NightTimeInfo[0]',
              'NightTimeInfo[1]',
              'NightTimeInfo[2]',
              'NightTimeInfo[3]',
              'NightTimeInfo[4]',
            ],
          }}
        />
      </DetailGrid>
    </AccordionGrid>
  );
}

export default CreateDetailGrid;
