import {
  SevenDayIndieChartBigPictureResponse,
  TransformedSevenDayIndieChartBigPictureResponse,
  SevenDayIndieChartBigPictureRow,
  TransformedSevenDayIndieChartBigPictureRow,
} from './SevenDayIndieChartBigPictureType';

export const SevenDayIndieChartBigPictureTransformer = (
  response: SevenDayIndieChartBigPictureResponse,
): TransformedSevenDayIndieChartBigPictureResponse => {
  const transformItems = (row: SevenDayIndieChartBigPictureRow): TransformedSevenDayIndieChartBigPictureRow => {
    return {
      ...row,
      SongInfo: {
        Artist: row.Artist,
        Label: row.Label,
        Title: row.Title,
      },
    };
  };
  return {
    ...response,
    Items: response.Items.map(transformItems),
  };
};
