/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
import AccordionReports from 'components/AccordionReports';
import { FilterState } from 'hooks/useFilterState/useFilterState';
import { usePrevious } from 'hooks/usePrevious/usePrevious';
import { isEqual } from 'lodash';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilterDataEvent from 'types/FilterDataEvent';
import FilterDataProps from 'types/FilterDataProps';
import { SongInfo } from 'types/SongInfo';
import SongSelection from '../SongSelection';

export interface SingleSongSelectionFilterData {
  SongInfo?: SongInfo;
}
interface SingleSongSelectionProps<T extends SingleSongSelectionFilterData> extends FilterDataProps<T> {
  title?: string;
  disabled?: boolean;
  songCode?: string;
  changeState?: (key: string, state: FilterState) => void;
  required?: boolean;
}

const SingleSongSelection = <T extends SingleSongSelectionFilterData>(
  props: SingleSongSelectionProps<T>,
): ReactElement => {
  const { t } = useTranslation();
  const { title = t('filter.title.song'), disabled, onChange, initialValue, songCode, changeState, required } = props;
  const [expanded, setExpanded] = useState(false);
  const [selectedValue, setSelectedValue] = useState<SongInfo>();
  const previousValue = usePrevious(selectedValue);

  const onSongSelection = (event: FilterDataEvent<{ songInfos: SongInfo[] | undefined }>) => {
    const songInfos = event.value?.songInfos;
    const selected = songInfos ? songInfos[0] : undefined;
    if (selected && !isEqual(selectedValue, selected)) {
      setSelectedValue(selected);
    }
    setExpanded(false);
  };

  useEffect(() => {
    if (initialValue && !selectedValue) {
      setSelectedValue(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    if (selectedValue && !isEqual(selectedValue, previousValue)) {
      onChange({ value: { SongInfo: selectedValue } as T });
    }
  }, [selectedValue]);

  return (
    <>
      <AccordionReports
        firstTitle={title}
        secondTitle={selectedValue?.Title}
        isDisabled={disabled}
        expanded={expanded}
        onChangeAccordion={() => setExpanded(!expanded)}
      >
        <SongSelection
          onChange={onSongSelection}
          multiSelect={false}
          changeState={changeState}
          required={required}
          songCode={songCode}
        />
      </AccordionReports>
    </>
  );
};

export default SingleSongSelection;
