import {
  SevenDayIndieChartBuildingResponse,
  TransformSevenDayIndieChartBuildingResponse,
  BuildingRow,
  TransformBuildingRow,
  SevenDayIndieChartRollingResponse,
  TransformSevenDayIndieChartRollingResponse,
  RollingRow,
  TransformRollingRow,
} from './SevenDayIndieChartType';

export const SevenDayIndieChartBuildingTransformer = (
  response: SevenDayIndieChartBuildingResponse,
): TransformSevenDayIndieChartBuildingResponse => {
  const transformItems = (row: BuildingRow): TransformBuildingRow => {
    return {
      ...row,
      SongInfo: {
        Artist: row.Artist,
        Label: row.Label,
        Title: row.Title,
      },
      Rank: {
        ThisWeek: row.RankThisWeek,
        LastWeek: row.RankLastWeek,
      },
      DayByDaySpinsFlat: Object.entries(row.DayByDaySpins).map(i => `${i[1].ThisWeek}`),
    };
  };
  return {
    ...response,
    Items: response.BuildingItems.map(transformItems),
    DayByDaySpinsHeaderDynamic: response.DayByDaySpinsColumns.map(o => {
      return {
        label: o.DayName.substring(0, 3),
        // eslint-disable-next-line radix
        sortPath: `DayByDaySpins/${parseInt(o.SpinsIndex.toString())}/ThisWeek`,
      };
    }),
    DayByDaySpinsFieldsDynamic: Object.entries(response.DayByDaySpinsColumns).map(
      // eslint-disable-next-line radix
      (o, index) => `DayByDaySpins.${index + 1}.ThisWeek`,
    ),
  };
};

export const SevenDayIndieChartRollingTransformer = (
  response: SevenDayIndieChartRollingResponse,
): TransformSevenDayIndieChartRollingResponse => {
  const transformItems = (row: RollingRow): TransformRollingRow => {
    return {
      ...row,
      SongInfo: {
        Artist: row.Artist,
        Label: row.Label,
        Title: row.Title,
      },
      Rank: {
        ThisWeek: row.RankThisWeek,
        LastWeek: row.RankLastWeek,
      },
      ThisWeekImpressionSpin: row?.ImpressionsInfo?.SpinsThisWeek,
      AverageStationRotation: {
        On: row.StationsPlayingTitle,
        ThisWeek: row.AveragePlayInfo.ThisWeek,
        LastWeek: row.AveragePlayInfo.LastWeek,
        Move: row.AveragePlayInfo.Move,
        Peak: row.AveragePlayInfo.Peak,
      },
    };
  };
  return {
    ...response,
    Items: response.RollingItems.map(transformItems),
  };
};
