/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { debounce } from 'lodash';
import { Dispatch, useEffect, useReducer, useRef } from 'react';

export interface StickyFilterContext<T> {
  savedFilters: T | undefined;
  saveFilter: Dispatch<T>;
}

function getDataFromLocalStorage(key: string): any {
  try {
    const content = localStorage.getItem(key);
    if (!content) {
      return {};
    }

    return JSON.parse(content);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn('Unable to parse filter content', error);
  }
  return {};
}

function useStickyFilter<T extends {}>(key: string): StickyFilterContext<T> {
  const [stateFilter, dispatch] = useReducer(filterReducer, {}, () => getDataFromLocalStorage(key));
  const savedFilters = useRef(
    debounce(newValue => {
      localStorage.setItem(key, JSON.stringify(newValue));
    }, 50),
  );

  function filterReducer(oldFilter: T, data: T): T {
    const newFilter = { ...oldFilter, ...data };
    return newFilter;
  }

  useEffect(() => savedFilters.current(stateFilter), [stateFilter]);

  return {
    savedFilters: stateFilter,
    saveFilter: (data: T) => {
      dispatch(data);
    },
  };
}

export { useStickyFilter as default };
