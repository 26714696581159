/* eslint-disable react/jsx-one-expression-per-line */
import { RowNode } from '@ag-grid-enterprise/all-modules';
import { Link } from '@material-ui/core';
import React from 'react';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface StationRankMarketFormatProps {
  data: {
    StationName: string;
    StationDisplayName: string;
    MarketName: string;
    FormatName: string;
    MarketRank: number;
  };
  node?: RowNode;
  reportQuery?: any;
  onClickHandler?: (data?: any, node?: RowNode, reportQuery?: any) => void;
}

const formatRank = (rank: number): string | number => {
  return rank !== 99999 ? rank : '-';
};

const StationRankMarketFormat: React.FC<StationRankMarketFormatProps> = ({
  data,
  node,
  reportQuery = null,
  onClickHandler,
}: StationRankMarketFormatProps) => {
  const getComponent = () => {
    if (!data) {
      return <></>;
    }

    const getLink = (value: string | number) => {
      if (!onClickHandler) {
        return value;
      }
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          onClick={(event: React.MouseEvent) => {
            event.preventDefault();
            onClickHandler(data, node, reportQuery);
          }}
        >
          <span>{value}</span>
        </Link>
      );
    };
    return (
      <div style={{ lineHeight: '18px', width: '100%' }}>
        {getLink(data?.StationDisplayName)}, {formatRank(data?.MarketRank)}
        <br />
        {data.MarketName}, {data?.FormatName}
      </div>
    );
  };
  return getComponent();
};

export default StationRankMarketFormat;
