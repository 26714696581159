/* eslint-disable no-param-reassign */
import { AgGridColumn } from '@ag-grid-community/react';
import FormatMoveCellRenderer from 'components/DetailGrid/CellRenderers/FormatMoveCellRenderer';
import SongCellRenderer from 'components/DetailGrid/CellRenderers/SongCellRenderer/SongCellRenderer';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import { UseApiSettings } from 'hooks/useApi/ApiTypes';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import { IndieChartFilter } from './utils/IndieChartReportTypes';
import { SevenDayIndieChartBigPictureTransformer } from './utils/SevenDayIndieChartBigPictureTransformer';
import { SevenDayIndieChartBigPictureResponse } from './utils/SevenDayIndieChartBigPictureType';

interface IndieChartProps {
  filters: IndieChartFilter | undefined;
}

const url = 'seven-day-indie-chart-big-picture-reports';

const SevenDayIndieChartBigPictureGrid: React.FC<IndieChartProps> = ({ filters }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({
    sortPath: 'RankThisWeek',
    sort: 'asc',
  });

  const { setSettings, settings, data: reportData, isLoading, isError } = useRemoteApi<
    SevenDayIndieChartBigPictureResponse
  >(url, {
    data: filters,
    method: 'POST',
    enabled: false,
    transformRequest: data => {
      data.MinSpins = data.MinSpins || 5;
      return data;
    },
    transformResponse: SevenDayIndieChartBigPictureTransformer,
  });

  return (
    <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
      <ServerSideReportGrid
        setSettings={(setSettings as unknown) as Dispatch<UseApiSettings<ReportResult<unknown>>>}
        settings={(settings as unknown) as UseApiSettings<ReportResult<unknown>>}
        filters={filters}
        sortModel={sortModel}
      >
        <AgGridColumn
          field="Rank"
          minWidth={80}
          width={80}
          headerName={t(`grid.header.Rank`)}
          headerComponentParams={{ sortModel, onSortChange }}
        />
        <AgGridColumn
          field="SongInfo"
          headerName={t(`grid.header.Title`)}
          minWidth={160}
          pinned
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            sortPath: 'Title',
            disposition: 'row',
            secondLine: [
              { label: 'grid.header.Artist', sortPath: 'Artist' },
              { label: 'grid.header.Label', sortPath: 'Label' },
            ],
          }}
          cellRendererFramework={SongCellRenderer}
        />
        <AgGridColumn
          field="ReleaseYear"
          minWidth={80}
          width={80}
          headerName={t(`grid.header.Year`)}
          headerComponentParams={{ sortModel, onSortChange }}
        />
        <AgGridColumn
          field="TotalSpins"
          minWidth={80}
          width={80}
          headerName={t(`grid.header.TotalSpins`)}
          headerComponentParams={{ sortModel, onSortChange }}
        />
        {(reportData?.FormatByFormatColumns ?? []).map(FormatByFormatColumn => (
          <AgGridColumn
            key={FormatByFormatColumn.FormatIndex}
            field={`FormatByFormatRanks.${FormatByFormatColumn.FormatIndex}`}
            minWidth={80}
            width={80}
            headerName={` `}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              disposition: 'row',
              secondLine: [
                {
                  sortPath: `FormatByFormatRanks/${FormatByFormatColumn.FormatIndex}/FormatValue`,
                  label: [FormatByFormatColumn.DisplayName],
                },
              ],
            }}
            cellRendererFramework={FormatMoveCellRenderer}
          />
        ))}
      </ServerSideReportGrid>
    </GridVisibilityWrapper>
  );
};

export default SevenDayIndieChartBigPictureGrid;
