/* eslint-disable react-hooks/exhaustive-deps */
import axios, { AxiosRequestConfig, AxiosResponse, AxiosTransformer } from 'axios';
import AppSettings from 'contexts/AppSettingsContext';
import { usePrevious } from 'hooks/usePrevious/usePrevious';
import { concat, isEqual } from 'lodash';
import { useContext, useEffect, useReducer, useState } from 'react';
import AuthSettings from 'contexts/AuthContext';
import queryString from 'query-string';
import { UseApiResponse, UseApiSettings, UseApiState } from './ApiTypes';

type ApiAction<T> = { type: 'FETCH_INIT' } | { type: 'FETCH_SUCCESS'; payload: T } | { type: 'FETCH_ERROR' };

interface ConfigRequest {
  headers: unknown;
}

const CONFIG_AXIOS = {
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
};

function useRemoteApiReducer<T>(state: UseApiState<T>, action: ApiAction<T>): UseApiState<T> {
  switch (action.type) {
    case 'FETCH_INIT':
      return { ...state, isLoading: true, isError: false };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
      };
    case 'FETCH_ERROR':
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    default:
      throw new Error();
  }
}

async function fetchRemoteData<T>(
  url: string,
  requestConfig: AxiosRequestConfig,
  configParams: ConfigRequest,
): Promise<AxiosResponse<T>> {
  return axios.request<T>({
    ...configParams,
    ...requestConfig,
    timeout: 60000,
    paramsSerializer: queryString.stringify,
    baseURL: requestConfig.baseURL,
    url,
    transformResponse:
      requestConfig.transformResponse &&
      (concat(axios.defaults.transformResponse, requestConfig.transformResponse) as AxiosTransformer[]),
    transformRequest:
      requestConfig.transformRequest &&
      (concat(requestConfig.transformRequest, axios.defaults.transformRequest) as AxiosTransformer[]),
  });
}

function useRemoteApi<T>(url: string, settings?: UseApiSettings<T>): UseApiResponse<T> {
  const { isAuthenticated, onLogout, token } = useContext(AuthSettings);
  const { endpointURL } = useContext(AppSettings);

  // #region control states
  function transformSettings(): UseApiSettings<T> {
    return { ...settings, baseURL: settings?.baseURL ?? endpointURL, url } as UseApiSettings<T>;
  }
  const [firstRun, setFirstRun] = useState(true);
  const [internalSettings, setInternalSettings] = useState<UseApiSettings<T>>(transformSettings());
  const { enabled = false, data, params } = internalSettings || {};

  const lastSettings = usePrevious(internalSettings);
  const lastParams = usePrevious(params);
  const lastData = usePrevious(data);

  function hasAnyChange(dataCompare: unknown, paramsCompare: unknown, enabledCompare: boolean) {
    return (
      firstRun || !isEqual(dataCompare, lastData) || !isEqual(paramsCompare, lastParams) || !enabledCompare === enabled
    );
  }

  const [state, dispatch] = useReducer(useRemoteApiReducer, {
    data: internalSettings?.initialData ?? [],
    isLoading: false,
    isError: false,
  } as UseApiState<T>);

  // #region bootstrapping effects (external state changed)
  useEffect(() => {
    if (hasAnyChange(settings?.data, settings?.params, !!settings?.enabled)) {
      setInternalSettings(transformSettings());
    }
  }, [settings?.data, settings?.params, settings?.enabled]);

  function getConfig() {
    return {
      headers: {
        ...CONFIG_AXIOS.headers,
        Authorization: `Bearer ${token}`,
      },
    };
  }

  // #endregion
  // #endregion

  useEffect(() => {
    setFirstRun(false);

    if (!enabled || !hasAnyChange(data, params, enabled) || isEqual(lastSettings, internalSettings)) {
      return; // disabled
    }

    if (!isAuthenticated()) {
      onLogout();
      return;
    }

    const responsePromise = fetchRemoteData<T>(url, internalSettings, getConfig());
    dispatch({ type: 'FETCH_INIT' });
    responsePromise.then(
      (d: AxiosResponse<T>) => {
        dispatch({ type: 'FETCH_SUCCESS', payload: d.data });
        if (internalSettings.onSuccess) {
          internalSettings.onSuccess(d.data);
        }
      },
      error => {
        dispatch({ type: 'FETCH_ERROR' });
        if (error?.response?.status === 401) {
          onLogout();
        }
        if (internalSettings.onError) {
          internalSettings.onError();
        }
      },
    );
  }, [internalSettings, enabled, data, params]);

  return {
    ...state,
    settings: internalSettings,
    setSettings: (s: UseApiSettings<T>) => {
      if (!isEqual(s, lastSettings)) {
        setInternalSettings({ ...internalSettings, ...s });
      }
    },
  } as UseApiResponse<T>;
}

export default useRemoteApi;
