import { AgGridColumn } from '@ag-grid-community/react/lib/agGridColumn';
import { Typography } from '@material-ui/core';
import DayPartsCellRenderer from 'components/DetailGrid/CellRenderers/DayPartsCellRenderer';
import SongCellRenderer from 'components/DetailGrid/CellRenderers/SongCellRenderer/SongCellRenderer';
import ThisLastMoveCellRenderer from 'components/DetailGrid/CellRenderers/ThisLastMoveCellRenderer';
import DayPartsHeaderComponent from 'components/DetailGrid/HeaderComponents/DayPartsHeaderComponent';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import AppSettings from 'contexts/AppSettingsContext';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import { artistByStationFilterRequestTransformer } from './utils/artistByStationFilterRequestTransformer';
import { artistSongByStationResponseTransformer } from './utils/artistSongByStationResponseTransformer';
import { ArtistByStationFilter, TransformedArtistSongByStation } from './utils/types';

interface ArtistSongsByStationGridProps {
  filters?: ArtistByStationFilter;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rowData?: any;
}

const url = '/artist-songs-by-station-reports';
const ArtistSongByStationGrid: FC<ArtistSongsByStationGridProps> = ({ filters, rowData }) => {
  const { t } = useTranslation();
  const { allPanel: defaultPanelInfo } = useContext(AppSettings);
  const { sortModel, setSortModel: onSortChange } = useSortColumn({
    sortPath: 'RankThisWeek',
    sort: 'asc',
  });
  const { setSettings, settings, data: reportData, isLoading, isError } = useRemoteApi<
    ReportResult<TransformedArtistSongByStation>
  >(url, {
    data: filters,
    method: 'POST',
    enabled: false,
    transformRequest: data => {
      const d = artistByStationFilterRequestTransformer(data, defaultPanelInfo);
      return { ...d, Artist: rowData?.ArtistByLastname };
    },
    transformResponse: artistSongByStationResponseTransformer,
  });

  return (
    <>
      <Typography variant="subtitle1" align="center">
        {t('grid.header.Songs')}
      </Typography>
      <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
        <ServerSideReportGrid
          setSettings={setSettings}
          settings={settings}
          filters={filters}
          sortModel={sortModel}
          detailProps={{
            domLayout: 'autoHeight',
            containerStyle: {
              height: '100%',
              paddingBottom: '30px',
            },
          }}
          hideDetails
        >
          <AgGridColumn
            headerName={t('grid.header.Rank')}
            minWidth={50}
            maxWidth={50}
            field="Rank"
            headerComponentParams={{
              sortModel,
              onSortChange,
            }}
          />
          <AgGridColumn
            headerName={t('grid.header.Title')}
            minWidth={120}
            pinned
            field="SongInfo"
            headerComponentParams={{
              sortModel,
              onSortChange,
              disposition: 'row',
              sortPath: 'Title',
              secondLine: [
                { label: 'grid.header.Label', sortPath: 'Label' },
                { label: 'grid.header.ReleaseYear' /* , sortPath: 'ReleaseYear' */ },
              ],
            }}
            headerComponentFramework={MultiColumnsHeaderComponent}
            cellRendererFramework={SongCellRenderer}
          />
          <AgGridColumn
            field="SpinsInfo"
            minWidth={95}
            maxWidth={95}
            headerName={t(`grid.header.SpinsInfoTW`)}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              sortPath: 'SpinsInfo/ThisWeek',
              secondLine: [
                { label: 'grid.header.LW', sortPath: 'SpinsInfo/LastWeek' },
                { label: 'grid.header.plusMinus', sortPath: 'SpinsInfo/Move' },
              ],
            }}
            cellRendererFramework={ThisLastMoveCellRenderer}
          />

          <AgGridColumn
            field="DaypartsInfo"
            headerName={t(`grid.header.DaypartsInfo`)}
            minWidth={230}
            maxWidth={230}
            headerComponentFramework={DayPartsHeaderComponent}
            headerComponentParams={{ sortModel, onSortChange }}
            cellRendererFramework={DayPartsCellRenderer}
          />
        </ServerSideReportGrid>
      </GridVisibilityWrapper>
    </>
  );
};

export default ArtistSongByStationGrid;
