import React, { useReducer, useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import AppSettings from 'contexts/AppSettingsContext';
import Button from '@material-ui/core/Button';
import AuthSettings from 'contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import LoginAdvertiseComponent from 'components/Filter/components/LoginAdvertiseComponent';
import CookieNotice from 'components/CookieNotice';
import Toolbar from '@material-ui/core/Toolbar';
import Collapse from '@material-ui/core/Collapse';
import LoginService from '../../services/Login/LoginService';
import Styles, { Container, FormContainer, FooterContainer, LinksContainer, Links, ContainerBody } from './styles';

type State = {
  username: string;
  password: string;
  isButtonDisabled: boolean;
  helperText: string;
  isError: boolean;
};

const initialState: State = {
  username: '',
  password: '',
  isButtonDisabled: true,
  helperText: '',
  isError: false,
};

type Action =
  | { type: 'setUsername'; payload: string }
  | { type: 'setPassword'; payload: string }
  | { type: 'setIsButtonDisabled'; payload: boolean }
  | { type: 'loginSuccess'; payload: string }
  | { type: 'loginFailed'; payload: string }
  | { type: 'setIsError'; payload: boolean };

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'setUsername':
      return {
        ...state,
        username: action.payload,
      };
    case 'setPassword':
      return {
        ...state,
        password: action.payload,
      };
    case 'setIsButtonDisabled':
      return {
        ...state,
        isButtonDisabled: action.payload,
      };
    case 'loginSuccess':
      return {
        ...state,
        helperText: action.payload,
        isError: false,
      };
    case 'loginFailed':
      return {
        ...state,
        helperText: action.payload,
        isError: true,
      };
    case 'setIsError':
      return {
        ...state,
        isError: action.payload,
      };
    default:
      return {
        ...state,
        isError: true,
      };
  }
};

const Login: React.FC = () => {
  const classes = Styles();
  const { onLogin } = useContext(AuthSettings);
  const { endpointURL, logoURL } = useContext(AppSettings);
  const [state, dispatch] = useReducer(reducer, initialState);
  const { forgoPaswordURL, faqsURL } = useContext(AppSettings);
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (state.username.trim() && state.password.trim()) {
      dispatch({
        type: 'setIsButtonDisabled',
        payload: false,
      });
    } else {
      dispatch({
        type: 'setIsButtonDisabled',
        payload: true,
      });
    }
  }, [state.username, state.password]);

  const handleLogin = async () => {
    const result = await LoginService(endpointURL, state.username, state.password);
    if (result?.status === 200) {
      dispatch({
        type: 'loginSuccess',
        payload: t('login.loginSuccess'),
      });
      const token = result?.data.Token;
      const expires = result?.data.Expires;
      onLogin(token, expires);
      history.push('/');
    } else {
      dispatch({
        type: 'loginFailed',
        payload: t('login.loginFailed'),
      });
    }
  };

  const handleUsernameChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    dispatch({
      type: 'setUsername',
      payload: event.target.value,
    });
  };

  const handlePasswordChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    dispatch({
      type: 'setPassword',
      payload: event.target.value,
    });
  };

  const redirectToForgotPassword = () => {
    window.open(forgoPaswordURL, 'blank');
  };

  const redirectToFaqs = () => {
    window.open(faqsURL, 'blank');
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const history = useHistory();

  return (
    <Container>
      <ContainerBody>
        <Toolbar className={classes.loginTop}>
          <div className={classes.logo}>
            <img src={logoURL} alt="logo" />
          </div>
          <Button variant="contained" color="secondary" className={classes.loginMenu} onClick={handleExpandClick}>
            {t('login.login')}
          </Button>
        </Toolbar>

        <Collapse
          in={expanded}
          timeout="auto"
          unmountOnExit
          classes={{
            container: classes.loginContainer,
            wrapperInner: classes.loginArea,
          }}
        >
          <FormContainer>
            <TextField
              error={state.isError}
              className={classes.textField}
              id="username"
              type="email"
              label="USERNAME"
              placeholder="Username"
              onChange={handleUsernameChange}
              InputLabelProps={{
                shrink: true,
                style: { color: '#E5412C', fontWeight: 'bold' },
              }}
            />
            <TextField
              error={state.isError}
              className={classes.textField}
              id="password"
              type="password"
              label="PASSWORD"
              placeholder="Password"
              helperText={state.helperText}
              onChange={handlePasswordChange}
              InputLabelProps={{
                shrink: true,
                style: { color: '#E5412C', fontWeight: 'bold' },
              }}
            />
            <FooterContainer>
              <Button variant="contained" color="secondary" className={classes.loginBtn} onClick={handleLogin}>
                {t('login.login')}
              </Button>

              <LinksContainer>
                <Links className={classes.link} onClick={redirectToForgotPassword}>
                  {t('login.forgotPassword')}
                </Links>
                <span className={classes.separator}>|</span>
                <Links className={classes.link} onClick={redirectToFaqs}>
                  {t('login.faqs')}
                </Links>
              </LinksContainer>
            </FooterContainer>
          </FormContainer>
        </Collapse>

        <LoginAdvertiseComponent />
        <CookieNotice />
      </ContainerBody>
    </Container>
  );
};

export default Login;
