/* eslint-disable react/require-default-props */
import { Column, ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import InnerTable from '../index';
import { IInnerTableGroup } from '../inner-table-group';

interface Weekies {
  Move: string | number;
  Week1: string | number;
  Week2: string | number;
  Week3: string | number;
  Week4: string | number;
}

export interface WeekImpressionsType {
  WeeklyImpressionsTrends?: Weekies;
  WeeklySpinTrends?: Weekies;
}

interface WeekImpressionsTableProps {
  data: WeekImpressionsType;
  name: string;
  field: string;
  columns?: Column[];
}

const WeekImpressionsInnerTable: React.FC<WeekImpressionsTableProps> = ({
  data,
  name,
  field,
  columns,
}: WeekImpressionsTableProps) => {
  const { t } = useTranslation();

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const valueGetter = (v: any) => {
    if (columns && columns[0].getColDef()) {
      const colDef = columns[0].getColDef();
      const { valueFormatter } = colDef;

      if (valueFormatter instanceof Function) {
        return valueFormatter({ ...colDef, value: v } as ValueFormatterParams);
      }
    }
    return v;
  };

  const values = ['Week1', 'Move', 'Week2', 'Week3', 'Week4'];

  const renderValues = values.map((item: string) => {
    const result = valueGetter((data[field as keyof WeekImpressionsType] as Weekies)[item as keyof Weekies] as string);
    const output = result === 0 ? '--' : result;
    return output;
  });
  const innerTableData: IInnerTableGroup[] = [
    {
      title: t(name),
      children: [
        {
          title: '',
          children: [
            t('grid.header.totalPlays.Week1'),
            t('grid.header.totalPlays.Move'),
            t('grid.header.totalPlays.Week2'),
            t('grid.header.totalPlays.Week3'),
            t('grid.header.totalPlays.Week4'),
          ],
        },
        {
          title: '',
          children: renderValues,
        },
      ],
    },
  ];

  return <InnerTable key={v4()} data={innerTableData} />;
};

export default WeekImpressionsInnerTable;
