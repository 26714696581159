import { Column } from '@ag-grid-enterprise/all-modules';
import { format } from 'date-fns';
import React from 'react';
import { v4 } from 'uuid';
import InnerTable from '..';
import { IInnerTableGroup } from '../inner-table-group';
import { SpinsSpan } from './styles';

export interface HourDataItem {
  HourDataItem: Record<number, number>;
}

interface PlaysProps {
  data: HourDataItem;
  invisibleColumns: Array<Column>;
}

const HourDataItemInnerTable: React.FC<PlaysProps> = ({ data, invisibleColumns }: PlaysProps) => {
  const composeHeader = () => {
    return invisibleColumns[0].getColDef().headerName || '';
  };

  const innerTableData: IInnerTableGroup[] = [
    {
      title: composeHeader(),
      children: [
        {
          title: '',
          children: [...Array(24).keys()].map(i => format(new Date(`1/1/1 ${i}:0`), 'ha')),
        },
        {
          title: '',
          children: [...Array(24).keys()].map(index => (
            <SpinsSpan hourIndex={index}>{data.HourDataItem[index].toString()}</SpinsSpan>
          )),
        },
      ],
    },
  ];

  return <InnerTable key={v4()} data={innerTableData} />;
};

export default HourDataItemInnerTable;
