import {
  SevenDaySongAnalysisGroupOwnerSummaryResponse,
  SevenDaySongAnalysisGroupOwnerSummaryItem,
  SevenDaySongAnalysisGroupOwnerSummaryTransformedItem,
} from './SongAnalysisGroupOwnerSummaryTypes';
import {
  SevenDaySongAnalysisDayPartSummaryResponse,
  SevenDaySongAnalysisDayPartSummaryItem,
  SevenDaySongAnalysisDayPartSummaryTranformedItem,
} from './SongAnalysisDayPartSummaryTypes';
import { SevenDaySongAnalysisExecutiveVideoSummaryResponse } from './SongAnalysisExecutiveVideoSummaryTypes';
import {
  SevenDaySongAnalysisExecutiveSummaryResponse,
  SevenDaySongAnalysisExecutiveSummaryItem,
  SevenDaySongAnalysisExecutiveSummaryTransformedItem,
  SevenDaySongAnalysisExecutiveSummaryTransformedResponse,
} from './SongAnalysisExecutiveSummaryTypes';
import {
  SevenDaySongAnalysisStationDetailsResponse,
  SevenDaySongAnalysisStationDetailsItem,
  SevenDaySongAnalysisStationDetailsTransformedItem,
  ExtendedSongAnalysisFilter,
  SongAnalysisFilter,
} from './SongAnalysisTypes';
import { FORMAT_ALL, OWNER_ALL } from './SongAnalysisContants';

const MAX_NUM_DAYS = 7;
const BUILDING_REPORT_CYCLE = 3;

export const SongAnalysisStationDetailRequestTransformer = (
  songAnalysisFilter: ExtendedSongAnalysisFilter,
): SongAnalysisFilter => {
  return {
    ReportCycle: songAnalysisFilter?.ReportCycle,
    Formats: songAnalysisFilter?.Formats,
    StationSearchFormats: songAnalysisFilter?.StationSearchFormats,
    MinSpins: songAnalysisFilter?.MinSpins,
    SearchMode: songAnalysisFilter?.SearchMode,
    StationPortfolio: songAnalysisFilter?.StationPortfolio,
    StationPortfolioGroup: songAnalysisFilter?.StationPortfolioGroup,
    StationSelectionType: songAnalysisFilter?.StationSelectionType,
    ShowAllStationsInPanel: songAnalysisFilter?.ShowAllStationsInPanel,
    PanelInfo: songAnalysisFilter?.PanelInfo,
    SongInfo: songAnalysisFilter?.SongInfo,
    Stations: songAnalysisFilter?.Stations,
    NumDays: songAnalysisFilter?.ReportCycle === BUILDING_REPORT_CYCLE ? songAnalysisFilter?.NumDays : MAX_NUM_DAYS,
    OwnerInfo: songAnalysisFilter?.OwnerInfo ?? OWNER_ALL,
  };
};

export const SongAnalysisStationDetailsTransformer = (
  sevenDaySongAnalysisStationDetailsResponse: SevenDaySongAnalysisStationDetailsResponse,
): SevenDaySongAnalysisStationDetailsResponse => {
  const transformItems = (
    row: SevenDaySongAnalysisStationDetailsItem,
  ): SevenDaySongAnalysisStationDetailsTransformedItem => {
    return {
      ...row,
      ImpressionsThisWeek: row.ImpressionsInfo.SpinsThisWeek,
      DayByDaySpinsDaily: row.DayByDaySpins,
      FormatComparisonInfo: {
        FormatComparisonAverage: row.FormatComparisonAverage,
        FormatComparisonMove: row.FormatComparisonMove,
      },
    };
  };
  return {
    ...sevenDaySongAnalysisStationDetailsResponse,
    Items: sevenDaySongAnalysisStationDetailsResponse.Items.map(transformItems),
  };
};

export const SongAnalysisExecutiveSummaryTransformer = (
  sevenDaySongAnalysisExecutiveSummaryResponse: SevenDaySongAnalysisExecutiveSummaryResponse,
): SevenDaySongAnalysisExecutiveSummaryTransformedResponse => {
  const transformItems = (
    row: SevenDaySongAnalysisExecutiveSummaryItem,
  ): SevenDaySongAnalysisExecutiveSummaryTransformedItem => {
    return {
      ...row,
      StationPlayingInfo: `${row.StationsPlayingInfo.OnNew} / ${row.StationsPlayingInfo.StationsPlayingTitle}`,
      Rank: {
        ThisWeek: row.RankThisWeek,
        LastWeek: row.RankLastWeek,
      },
      PeakInfo: {
        PeakSpinsDate: row.PeakSpinsDate,
        PeakSpins: row.PeakSpins,
        PeakChart: row.PeakChart,
        PeakChartDate: row.PeakChartDate,
      },
    };
  };
  return {
    ...sevenDaySongAnalysisExecutiveSummaryResponse,
    Items: sevenDaySongAnalysisExecutiveSummaryResponse.Items.map(transformItems),
    TotalItems: {
      ...sevenDaySongAnalysisExecutiveSummaryResponse.TotalItems,
      ...{ FormatName: 'Totals' },
      ...{
        StationPlayingInfo: `${sevenDaySongAnalysisExecutiveSummaryResponse.TotalItems.StationsPlayingInfo.OnNew} / ${sevenDaySongAnalysisExecutiveSummaryResponse.TotalItems.StationsPlayingInfo.StationsPlayingTitle}`,
      },
    },
  };
};

export const SongAnalysisExecutiveSummaryRequestTransformer = (
  songAnalysisFilter: ExtendedSongAnalysisFilter,
): SongAnalysisFilter => {
  return {
    PanelInfo: songAnalysisFilter?.PanelInfo,
    SongInfo: songAnalysisFilter?.SongInfo,
    NumDays: songAnalysisFilter?.ReportCycle === BUILDING_REPORT_CYCLE ? songAnalysisFilter?.NumDays : MAX_NUM_DAYS,
    ReportCycle: songAnalysisFilter?.ReportCycle,
  };
};

export const SongAnalysisExecutiveVideoSummaryTransformer = (
  sevenDaySongAnalysisExecutiveVideoSummaryResponse: SevenDaySongAnalysisExecutiveVideoSummaryResponse,
): SevenDaySongAnalysisExecutiveVideoSummaryResponse => {
  return {
    ...sevenDaySongAnalysisExecutiveVideoSummaryResponse,
    TotalItems: {
      ...sevenDaySongAnalysisExecutiveVideoSummaryResponse.TotalItems,
      ...{ FormatName: 'Totals' },
    },
  };
};

export const SongAnalysisExecutiveVideoSummaryRequestTransformer = (
  songAnalysisFilter: ExtendedSongAnalysisFilter,
): SongAnalysisFilter => {
  return {
    PanelInfo: songAnalysisFilter?.PanelInfo,
    SongInfo: songAnalysisFilter?.SongInfo,
    NumDays: songAnalysisFilter?.ReportCycle === BUILDING_REPORT_CYCLE ? songAnalysisFilter?.NumDays : MAX_NUM_DAYS,
  };
};

export const SongAnalysisDayPartSummaryTransformer = (
  sevenDaySongAnalysisDayPartSummaryResponse: SevenDaySongAnalysisDayPartSummaryResponse,
): SevenDaySongAnalysisDayPartSummaryResponse => {
  const transformItems = (
    row: SevenDaySongAnalysisDayPartSummaryItem,
  ): SevenDaySongAnalysisDayPartSummaryTranformedItem => {
    return {
      ...row,
      DaypartsPercentageInfo: row.DaypartsInfo,
      TotalSpinsTw: row.SpinsInfo.ThisWeek,
    };
  };
  return {
    ...sevenDaySongAnalysisDayPartSummaryResponse,
    Items: sevenDaySongAnalysisDayPartSummaryResponse.Items.map(transformItems),
    TotalItems: {
      ...sevenDaySongAnalysisDayPartSummaryResponse.TotalItems,
      ...{
        FormatName: 'Totals',
        DaypartsPercentageInfo: sevenDaySongAnalysisDayPartSummaryResponse.TotalItems.DaypartsInfo,
        TotalSpinsTw: sevenDaySongAnalysisDayPartSummaryResponse.TotalItems.SpinsInfo.ThisWeek,
      },
    },
  };
};

export const SongAnalysisGroupOwnerSummaryRequestTransformer = (
  songAnalysisFilter: ExtendedSongAnalysisFilter,
): SongAnalysisFilter => {
  return {
    OwnerInfo: songAnalysisFilter?.OwnerInfo ?? OWNER_ALL,
    PanelInfo: songAnalysisFilter?.PanelInfo,
    SongInfo: songAnalysisFilter?.SongInfo,
    NumDays: songAnalysisFilter?.ReportCycle === BUILDING_REPORT_CYCLE ? songAnalysisFilter?.NumDays : MAX_NUM_DAYS,
    FormatInfo: songAnalysisFilter?.FormatInfo ?? FORMAT_ALL,
  };
};

export const SongAnalysisGroupOwnerSummaryTransformer = (
  sevenDaySongAnalysisDayPartSummaryResponse: SevenDaySongAnalysisGroupOwnerSummaryResponse,
): SevenDaySongAnalysisGroupOwnerSummaryResponse => {
  const transformItems = (
    row: SevenDaySongAnalysisGroupOwnerSummaryItem,
  ): SevenDaySongAnalysisGroupOwnerSummaryTransformedItem => {
    return {
      ...row,
      DayByDaySpinsDaily: row.DayByDaySpins,
      ImpressionsThisWeek: row.ImpressionsInfo.SpinsThisWeek,
      StationPlayingInfo: `${row.StationsPlayingInfo.OnNew} / ${row.StationsPlayingInfo.StationsPlayingTitle}`,
    };
  };
  return {
    ...sevenDaySongAnalysisDayPartSummaryResponse,
    Items: sevenDaySongAnalysisDayPartSummaryResponse.Items.map(transformItems),
    TotalItems: {
      ...sevenDaySongAnalysisDayPartSummaryResponse.TotalItems,
      ...{
        FormatName: 'Totals',
        DayByDaySpinsDaily: sevenDaySongAnalysisDayPartSummaryResponse.TotalItems.DayByDaySpins,
        ImpressionsThisWeek: sevenDaySongAnalysisDayPartSummaryResponse.TotalItems.ImpressionsInfo.SpinsThisWeek,
        StationPlayingInfo: `${sevenDaySongAnalysisDayPartSummaryResponse.TotalItems.StationsPlayingInfo.OnNew} / ${sevenDaySongAnalysisDayPartSummaryResponse.TotalItems.StationsPlayingInfo.StationsPlayingTitle}`,
      },
    },
  };
};
