import React from 'react';
import styled from 'styled-components';
import defaultTheme from '../../../themes/index';

const Container = styled.div`
  width: 100%;
`;

interface FormatMoveCellRendererProps {
  value?: {
    FormatValue: number;
    Move: number;
  };
}

const FormatMoveCellRenderer: React.FC<FormatMoveCellRendererProps> = ({ value }: FormatMoveCellRendererProps) => {
  const getColor = (move: number) => {
    if (move < 0) return defaultTheme.palette.error.main;
    if (move > 0) return defaultTheme.palette.success.main;
    return defaultTheme.palette.text.primary;
  };

  const createSpan = (move: number, formatValue: number | string) => {
    return <span style={{ color: getColor(move) }}>{formatValue}</span>;
  };

  const formatData = (data: number, setPluSignal?: boolean) => {
    if (data === 0 || data === null) return '-';
    if (data > 0 && setPluSignal) return `+${data}`;
    return data;
  };

  const getElements = (): JSX.Element => {
    if (!value) return <></>;
    return <Container>{createSpan(value.Move, formatData(value.FormatValue))}</Container>;
  };

  return getElements();
};

FormatMoveCellRenderer.defaultProps = { value: undefined };

export default FormatMoveCellRenderer;
