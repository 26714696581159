/* eslint-disable @typescript-eslint/ban-types */
import { Collapse, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container, FiltersContainer, SubmitButton, useStyles } from './styles';

interface FilterBaseProps {
  children: JSX.Element[] | JSX.Element;
  onSubmit: { (filters: object): void };
  isOpened: boolean;
  isFilterReady?: boolean;
}

const FilterBase: React.FC<FilterBaseProps> = ({ children, onSubmit, isOpened, isFilterReady = true }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Collapse className={classes.collapse} in={isOpened}>
      <Container>
        <FiltersContainer>{children}</FiltersContainer>
        <SubmitButton className={classes.submitButton} onClick={onSubmit} disabled={!isFilterReady}>
          <Typography className={classes.submitText}>{t('filter.title.submit')}</Typography>
        </SubmitButton>
      </Container>
    </Collapse>
  );
};

export default FilterBase;
