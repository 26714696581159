/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { GrcInfoEnum } from "types/GrcInfo";
import i18n from '../../i18n/translations';

export const formatComparisonInfoRank = (rowData:any, reportQuery: any):string=>
{
    let result = null;
    const val = rowData.FormatComparisonInfo;
    const chartRankThisWeek = val != null && val.ChartRankThisWeek != null
        ? val.ChartRankThisWeek 
        : 0;
        const chartRankLastWeek = val != null && val.ChartRankLastWeek != null
        ? val.ChartRankLastWeek 
        : 0;
    if (chartRankThisWeek === 2000000 && chartRankLastWeek === 2000000)
    {
        result = i18n.t(`grid.fields.ChartRankRecurring`);
    }
    else if (chartRankThisWeek > 10000)
    {
        result = i18n.t(`grid.fields.ChartRankNa`);
    }
    else
    {
        result = chartRankThisWeek;
        if (reportQuery?.GrcInfo?.GrcCode === GrcInfoEnum.All && rowData.GrcInfo?.GrcCode === GrcInfoEnum.Gold)
        {
            result = `${result}${i18n.t(`grid.fields.ChartRankGold`)}`;
        }
    }
  
    return result;
};
  
export const formatComparisonInfoDeviations = (rowData:any):string =>
{
    const result = rowData.FormatComparisonInfo;
    const val = result != null && result.Deviations != null
        ? result.Deviations
        : 0;
    return val > 0 ? `+${val}` : `${val}`;
}; 
  