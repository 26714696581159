import { DayByDaySpins } from 'types/DayByDaySpins';
import { FilterParams } from 'types/FilterParams';
import { CountryFormatCodeEnum } from 'types/Format';
import { PanelCodeEnum } from 'types/Panel';

const countryFormatsCode = Object.values(CountryFormatCodeEnum) as string[];

export const isCountryFilter = (filters: FilterParams | undefined): boolean => {
  return filters ? countryFormatsCode.includes(filters?.FormatInfo?.FormatCode.toString() ?? '') : false;
};

export const showCanconGrid = (filters: FilterParams | undefined): boolean => {
  return (
    filters?.PanelInfo?.PanelCode === PanelCodeEnum.AllCanadianStations ||
    filters?.PanelInfo?.PanelCode === PanelCodeEnum.PublishedCanadaPanel
  );
};

export const extractThisWeekDayByDaySpinsNonZeroValues = (dayByDay: DayByDaySpins): Array<number> => {
  return Object.values(dayByDay)
    .filter(item => item.ThisWeek !== 0)
    .map(j => j.ThisWeek);
};
