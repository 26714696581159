import React, { useState } from 'react';
import ReportHeader from 'components/ReportHeader';
import SiblingReportsSelect from 'components/SiblingReportsSelect/SiblingReportsSelect';
import FilterButton from 'components/FilterButton';
import { useTranslation } from 'react-i18next';
import SelectInput from 'components/SelectInput';
import { SongInfo } from 'types/SongInfo';
import { SongSelectionType } from 'types/Enums';
import { Container, ReportContainer, GridContainer, SongsContainer } from './styles';
import CurrentOneDayReportGrid from './CurrentOneDayReportGrid';
import CurrentOneDayReportFilter from './CurrentOneDayReportFilter';
import { CurrentOneDayFilter } from './utils/CurrentOneDayReportInterfaces';

const CurrentOneDayReport: React.FC = () => {
  const { t } = useTranslation();
  const [filterOpened, setFilterOpened] = useState(false);
  const [songInfos, setSongInfos] = useState<SongInfo[]>();
  const [filters, setFilters] = useState<CurrentOneDayFilter>();

  const onChangeFilter = ({ songInfos: filterSongInfos, ...filtersParams }: CurrentOneDayFilter) => {
    setFilterOpened(false);
    setSongInfos(filterSongInfos);
    const defaultSongInfo = filterSongInfos && filterSongInfos[0];
    setFilters({
      ...(filtersParams as CurrentOneDayFilter),
      ...(filtersParams.SongSelectionType === SongSelectionType.SongPortfolio && { SongInfo: defaultSongInfo }),
    });
  };

  const onChangeSong = (song: SongInfo) => {
    setFilters({ ...(filters as CurrentOneDayFilter), SongInfo: song });
  };

  return (
    <Container container direction="column" spacing={0}>
      <ReportHeader title={t('report.tab.songs')} subtitle={t('report.label.dailyCurrent')} />
      <ReportContainer>
        <SiblingReportsSelect />
        <FilterButton onClickFilter={() => setFilterOpened(!filterOpened)} isOpened={filterOpened} />
      </ReportContainer>
      <CurrentOneDayReportFilter isOpened={filterOpened} changeFilter={onChangeFilter} />
      <SongsContainer>
        {filters?.SongSelectionType === SongSelectionType.SongPortfolio && songInfos?.length ? (
          <SelectInput
            normalizeLabel={option => `${option.Artist}-${option.Title}`}
            optionLabel="Title"
            options={songInfos}
            value={filters?.SongInfo}
            onChangeValue={onChangeSong}
          />
        ) : null}
      </SongsContainer>
      <GridContainer>
        <CurrentOneDayReportGrid filters={filters} />
      </GridContainer>
    </Container>
  );
};

export default CurrentOneDayReport;
