import { createStyles, Theme } from '@material-ui/core/styles';
import { Grid, Button, makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Grid)`
  flex-direction: column;
  flex: 1;
  width: 100%;
  margin-bottom: 5px;
`;
export const FiltersContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;
export const SubmitButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin-bottom: 12px;
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    collapse: {
      width: '100%',
    },
    submitButton: {
      width: '100%',
      borderRadius: '0px',
      backgroundColor: `${theme.palette.primary.main}`,
      height: '40px',
      '&.Mui-disabled': {
        backgroundColor: `${theme.palette.action.disabled}`,
      },
      '&:hover': {
        opacity: '0.9',
        backgroundColor: `${theme.palette.primary.main}`,
      },
    },
    submitText: {
      color: '#ffffff',
    },
  }),
);
