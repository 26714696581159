import AccordionFilter from 'components/Filter/components/AccordionFilter';
import Grc from 'components/Filter/components/Grc';
import StationSelect from 'components/Filter/components/StationSelect/SelectedStation';
import FilterBase from 'components/FilterBase';
import useStickyFilter from 'hooks/useStickyFilter/useStickyFilter';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FilterDataEvent from 'types/FilterDataEvent';
import { getStationDisplayName } from 'utils/reports/GetStationDisplayName';
import { ExtendedArtistByStationFilter } from './utils/types';

interface ArtistByStationReportFilterProps {
  changeFilter: { (filters: ExtendedArtistByStationFilter): void };
  isOpened: boolean;
}

const Filter: FC<ArtistByStationReportFilterProps> = ({ changeFilter, isOpened }) => {
  const pathname = getReportSpec(ReportBaseEnum.ArtistByStationReport).stickyKey;
  const { t } = useTranslation();
  const { savedFilters: filter, saveFilter } = useStickyFilter<ExtendedArtistByStationFilter>(pathname);

  const handleOnSubmit = () => {
    if (filter) {
      changeFilter(filter);
    }
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleStationChanges = (value?: object) => {
    saveFilter({ ...filter, ...value } as ExtendedArtistByStationFilter);
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleStationSelectionChanges = (event: FilterDataEvent<object>) => {
    const { value } = event;
    delete filter?.Stations;
    handleStationChanges(value);
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleFilterChange = (event: FilterDataEvent<object>) => {
    const { value } = event;
    saveFilter({ ...filter, ...value } as ExtendedArtistByStationFilter);
  };

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const getStationsLabel = (value: any): string => {
    let result = '';
    if (value != null && value.Stations.length > 0) {
      result = value.Stations.length > 1 ? t('filter.label.various') : getStationDisplayName(value.Stations[0]);
    }
    return result;
  };

  return (
    <>
      <FilterBase onSubmit={handleOnSubmit} isOpened={isOpened}>
        <AccordionFilter title={t('filter.title.stations')} getLabel={({ value }) => getStationsLabel(value)}>
          <StationSelect
            title={t('filter.label.stationSelect')}
            onChange={handleStationSelectionChanges}
            initialValue={filter?.Stations}
          />
        </AccordionFilter>
        <Grc onChange={handleFilterChange} initialValue={filter?.GrcInfo} showAll />
      </FilterBase>
    </>
  );
};

export default Filter;
