import { AgGridColumn } from '@ag-grid-community/react';
import StationRankMarketOwnerCellRenderer from 'components/DetailGrid/CellRenderers/StationRankMarketOwnerCellRenderer';
import StationRankMarketOwnerHeaderComponent from 'components/DetailGrid/HeaderComponents/StationRankMarketFormatHeaderComponent';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import { ExtendedArtistAnalysisFilter } from './utils/ArtistAnalysisFilter';
import { TransformedSevenDayArtistAnalysisReport } from './utils/SevenDayArtistAnalysisReports';

interface FiftyTwoWeeksDataReportGridProps {
  filters?: ExtendedArtistAnalysisFilter;
}

const url = `/seven-day-artist-analysis-reports`;
const FiftyTwoWeeksDataReportGrid: FC<FiftyTwoWeeksDataReportGridProps> = ({ filters }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({ sortPath: 'Rank', sort: 'asc' });
  const { setSettings, settings, data: reportData, isLoading, isError } = useRemoteApi<
    ReportResult<TransformedSevenDayArtistAnalysisReport>
  >(url, {
    method: 'POST',
    enabled: false,
    transformResponse: data => {
      const items = data.FiftyTwoWeeksItems;
      return { ...data, Items: items };
    },
  });
  return (
    <>
      <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
        <ServerSideReportGrid setSettings={setSettings} settings={settings} filters={filters} sortModel={sortModel}>
          <AgGridColumn
            field="Rank"
            headerName={t('grid.header.Rank')}
            minWidth={60}
            maxWidth={60}
            wrapText
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="StationDisplayName"
            headerName={t('grid.header.Station')}
            minWidth={150}
            wrapText
            headerComponentParams={{ sortModel, onSortChange }}
            headerComponentFramework={StationRankMarketOwnerHeaderComponent}
            cellRendererFramework={StationRankMarketOwnerCellRenderer}
          />
          <AgGridColumn
            field="FormatName"
            headerName={t('grid.header.FormatName')}
            minWidth={80}
            maxWidth={120}
            wrapText
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="TitlesCount"
            headerName={t('grid.header.TitlesCount')}
            minWidth={75}
            maxWidth={75}
            wrapText
            headerComponentParams={{ sortModel, onSortChange }}
          />

          <AgGridColumn field="TotalSpins" headerName={t('grid.header.Spins')} minWidth={80} maxWidth={80} wrapText />

          <AgGridColumn
            headerName={t('grid.header.impression.ImpressionMill')}
            field="AqhShare"
            maxWidth={120}
            minWidth={120}
            headerComponentParams={{ sortModel, onSortChange }}
          />
        </ServerSideReportGrid>
      </GridVisibilityWrapper>
    </>
  );
};

export default FiftyTwoWeeksDataReportGrid;
