/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import {
  daysMinus,
  formatDateTypes,
  formatDate,
  getNameOfTheWeekWithDate,
  getArrayDatesFromInterval,
} from 'utils/dates';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { DateRangeGroup } from 'types/DateRange';
import { Column, ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import InnerTable from '../index';
import { IInnerTableGroup } from '../inner-table-group';

interface Dailies {
  Daily1: string | number;
  Daily2: string | number;
  Daily3: string | number;
  Daily4: string | number;
  Daily5: string | number;
  Daily6: string | number;
  Daily7: string | number;
}

interface DailyTableTypes {
  WeeklyImpressionsTrends?: Dailies;
  DailyImpressionsTrends?: Dailies;
  DailySpinTrends?: Dailies;
  DateRange: DateRangeGroup;
}

interface DailyTableProps {
  data: DailyTableTypes;
  name: string;
  field: string;
  column?: Column[];
}

const DailyInnerTable: React.FC<DailyTableProps> = ({ data, name, field, column }: DailyTableProps) => {
  const { t } = useTranslation();
  const [ranges, setRanges] = useState<Date[]>([]);

  useEffect(() => {
    const { DateRange } = data;
    const end = DateRange ? DateRange.End : new Date();
    const start = daysMinus(end, 6);
    const arrayDates = getArrayDatesFromInterval(start, end);
    setRanges(arrayDates);
  }, [data]);

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const valueGetter = (v: any) => {
    if (column && column[0].getColDef()) {
      const colDef = column[0].getColDef();
      const { valueFormatter } = colDef;

      if (valueFormatter instanceof Function) {
        return valueFormatter({ ...colDef, value: v } as ValueFormatterParams);
      }
    }
    return v;
  };

  const normalizeLines = ranges.map(
    item => `${formatDate(item, formatDateTypes.MMdd)} - ${getNameOfTheWeekWithDate(item)}`,
  );

  const values = ['Daily7', 'Daily6', 'Daily5', 'Daily4', 'Daily3', 'Daily2', 'Daily1'];

  const renderValues = values.map((item: string) => {
    const result = valueGetter((data[field as keyof DailyTableTypes] as Dailies)[item as keyof Dailies] as string);
    const output = result === 0 ? '--' : result;
    return output;
  });

  const innerTableData: IInnerTableGroup[] = [
    {
      title: t(name),
      children: [
        {
          title: '',
          children: normalizeLines,
        },
        {
          title: '',
          children: [
            {
              title: '',
              children: renderValues,
            },
          ],
        },
      ],
    },
  ];

  return <InnerTable key={v4()} data={innerTableData} />;
};

export default DailyInnerTable;
