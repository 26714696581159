import React, { Dispatch } from 'react';
import FilterBase from 'components/FilterBase';
import FilterDataEvent from 'types/FilterDataEvent';
import GRCFilter from 'components/Filter/components/Grc';
import PanelFilter from 'components/Filter/components/PanelFilter';
import { useTranslation } from 'react-i18next';
import useStickyFilter from 'hooks/useStickyFilter/useStickyFilter';
import FormatFilter from 'components/Filter/components/FormatFilter';
import { PanelCodeEnum } from 'types/Panel';
import SingleSongSelection from 'components/Filter/components/SingleSongSelection/SingleSongSelection';
import ComponentGroup from 'components/Filter/components/ComponentGroup';
import ComponentGroupItem from 'components/Filter/components/ComponentGroup/ComponentGroupItem';
import SongPortfolioSelect from 'components/Filter/components/SongPortfolioSelect';
import { SONG_PORTFOLIO, SONG_SELECTION } from 'utils/Constants';
import { SongSelectionType } from 'types/Enums';
import { get } from 'lodash';
import { FormatInfoChecked } from 'types/Format';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import useFilterState from 'hooks/useFilterState/useFilterState';
import useDeepLink from 'hooks/useDeepLink/useDeepLink';
import { defaultFilterTransformer } from 'hooks/useDeepLink/utils/defaultFilterTransformer';
import { CurrentOneDayFilter } from './utils/CurrentOneDayReportInterfaces';

interface CurrentOneDayReport {
  changeFilter: Dispatch<CurrentOneDayFilter>;
  isOpened: boolean;
}

const CurrentOneDayReportFilter: React.FC<CurrentOneDayReport> = ({ changeFilter, isOpened }) => {
  const { t } = useTranslation();
  const pathname = getReportSpec(ReportBaseEnum.CurrentOneDayReport).stickyKey;
  const { savedFilters: filter = {} as CurrentOneDayFilter, saveFilter } = useStickyFilter<CurrentOneDayFilter>(
    pathname,
  );

  const { isReady: isFilterReady, changeState } = useFilterState();
  const { hasDeepLinkInfo } = useDeepLink(filter, saveFilter, isFilterReady, {
    onReady: () => changeFilter(filter),
    // eslint-disable-next-line no-shadow
    filterTransformer: (queryString: Map<string, string>, filter: CurrentOneDayFilter) => {
      const newFilter = defaultFilterTransformer(queryString, filter);
      return { ...newFilter, songGroup: SONG_SELECTION };
    },
  });

  const handleFilterChange = (event: FilterDataEvent<CurrentOneDayFilter>) => {
    const { value } = event;
    // Clean Format info when changing PanelInfo.
    if (value?.PanelInfo && value?.PanelInfo?.Id !== filter?.PanelInfo?.Id) {
      value.FormatInfo = {} as FormatInfoChecked;
    }
    saveFilter({ ...filter, ...value });
  };

  const handleFilterChangeSelectedGroupItem = ({ value }: FilterDataEvent<string>) => {
    const songSelection =
      value === SONG_PORTFOLIO
        ? { SongSelectionType: SongSelectionType.SongPortfolio }
        : { SongSelectionType: SongSelectionType.SongSelection, songInfos: [] };
    saveFilter({ ...filter, ...(songSelection as CurrentOneDayFilter) });
  };

  return (
    <FilterBase onSubmit={() => changeFilter(filter)} isOpened={isOpened}>
      <PanelFilter onChange={handleFilterChange} initialValue={filter.PanelInfo} />
      <FormatFilter
        onChange={handleFilterChange}
        panelCode={filter?.PanelInfo?.PanelCode ?? PanelCodeEnum.AllUsStations}
        hasAllOption
        initialValue={filter?.FormatInfo}
        changeState={changeState}
        required
      />
      <ComponentGroup
        initialValue={
          (filter?.SongSelectionType === SongSelectionType.SongPortfolio ? SONG_PORTFOLIO : SONG_SELECTION) ??
          SONG_SELECTION
        }
        title={t('filter.title.songs')}
        onChange={handleFilterChangeSelectedGroupItem}
      >
        <ComponentGroupItem
          label={t('filter.title.songSelection')}
          getLabel={({ value }) => get(value, 'SongInfo.Title', '')}
          labelValue={SONG_SELECTION}
        >
          <SingleSongSelection
            title={t(`filter.title.songSelection`)}
            onChange={handleFilterChange}
            initialValue={filter?.SongInfo}
            songCode={hasDeepLinkInfo ? filter?.songCode : undefined}
            changeState={changeState}
            required
          />
        </ComponentGroupItem>
        <ComponentGroupItem
          label={t('filter.title.songPortfolios')}
          getLabel={({ value }) => get(value, 'SongPortfolio.Name', '')}
          labelValue={SONG_PORTFOLIO}
        >
          <SongPortfolioSelect onChange={handleFilterChange} initialValue={filter?.SongPortfolio} />
        </ComponentGroupItem>
      </ComponentGroup>
      <GRCFilter onChange={handleFilterChange} initialValue={filter?.GrcInfo} required />
    </FilterBase>
  );
};

export default CurrentOneDayReportFilter;
