import { concat } from 'lodash';
import { Routes } from 'types/Routes';
import { menuEntries as dailyCurrentMenuEntries } from './daily-current/index';
import { menuEntries as sevenDayMenuEntries } from './seven-day/index';
import { menuEntries as yearToDateMenuEntries } from './year-to-date/index';
import { menuEntries as misMenuEntries } from './mis/index';

export const menuEntries: Routes[] = concat(
  dailyCurrentMenuEntries,
  sevenDayMenuEntries,
  yearToDateMenuEntries,
  misMenuEntries,
);
