/* eslint-disable no-param-reassign */
import {
  CurrentMusicChartResponse,
  CurrentMusicChartRow,
  TransformedCurrentChartResponse,
} from './CurrentMusicChartTypes';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const CurrentMusicChartTransformer = (
  currentChartResponse: CurrentMusicChartResponse,
): TransformedCurrentChartResponse => {
  const transformItems = (row: CurrentMusicChartRow): any => {
    return {
      ...row,
      ArtistInfo: {
        Artist: row.Artist,
        Title: row.Title,
        SongCode: row.SongCode,
      },
      LastMonthInfo: {
        Move: row.LastSpinsInfo.Move,
        Position: row.LastSpinsInfo.LastWeek,
      },
      // DayByDayRanks show the data in a inverser order of DayByDayColumns
      DayByDayRanksFlat: Object.entries(row.DayByDayRanks).map(i => {
        if (i[1].ThisWeek > 0) {
          return `${i[1].ThisWeek}`;
        }
        return i[1].IsRecurring ? '-REC-' : '--';
      }),
      DayByDaySpinsFlat: Object.entries(row.DayByDaySpins)
        .map(i => `${i[1].ThisWeek}`)
        .reverse(),
    };
  };
  return {
    ...currentChartResponse,
    Items: currentChartResponse.Items.map(transformItems),
    HeaderDayByDayRanksFlat: currentChartResponse.DayByDayColumns.map(o => {
      return {
        label: o.DayName.substring(0, 2),
        sortPath: `DayByDayRanks/${o.DayIndex}/ThisWeek`,
      };
    }).reverse(),
    HeaderThisWeekDayByDayRank: currentChartResponse.DayByDayColumns.map(o => {
      return {
        label: o.DayName.substring(0, 3),
        sortPath: `DayByDaySpins/${o.DayIndex}/ThisWeek`,
      };
    }),
    HeaderLastSpin: `Last ${currentChartResponse.DayByDayColumns.find((o, index) => index === 0)?.DayName.substr(
      0,
      2,
    )}`,
  };
};
