/* eslint-disable import/no-duplicates */
/* eslint-disable no-case-declarations */
import React, { useEffect, useState } from 'react';
import { daysAdd, daysMinus, formatDate } from 'utils/dates';
import ArrowForwardIosIcon from '@material-ui/icons/ChevronRight';
import ArrowBackIosIcon from '@material-ui/icons/ChevronLeft';
import addDays from 'date-fns/addDays';
import { differenceInDays, subDays } from 'date-fns';
import { DateRangeGroup } from 'types/DateRange';
import { Container } from './styles';

interface DatePrev {
  group: DateRangeGroup;
  onChange: (range: DateRangeGroup) => void;
  dateMax?: Date | null;
  stepInDays?: number;
}

const PreviousNextWeekSelect: React.FC<DatePrev> = ({ group, onChange, dateMax = null, stepInDays = 6 }) => {
  const [date, setDate] = useState<DateRangeGroup>({ Start: new Date(), End: addDays(new Date(), stepInDays) });

  useEffect(() => {
    setDate(group);
  }, [group]);

  const renderDate = (valueDate: string | Date) => {
    return <span>{formatDate(valueDate || new Date())}</span>;
  };

  const controlDate = (data: string | Date, type: string) => {
    const firstDate = type === 'next' ? daysAdd(data) : daysMinus(data);
    const lastDate = type === 'next' ? addDays(firstDate, stepInDays) : subDays(firstDate, stepInDays);
    const groupData = {
      startDate: type === 'next' ? firstDate : lastDate,
      endDate: type === 'next' ? lastDate : firstDate,
    };
    const DateRange = {
      Start: groupData.startDate || new Date(),
      End: groupData.endDate || new Date(),
    };
    if (DateRange) {
      onChange(DateRange);
    }
    setDate(DateRange);
  };

  const showNextButton = () => {
    if ((dateMax && date.End < subDays(dateMax, 1)) || dateMax === null)
      return <ArrowForwardIosIcon fontSize="small" onClick={() => controlDate(date.End || new Date(), 'next')} />;
    return <></>;
  };

  return (
    <Container>
      <ArrowBackIosIcon fontSize="small" onClick={() => controlDate(date.Start || new Date(), 'prev')} />
      {renderDate(date.Start)}
      {differenceInDays(date.Start as Date, date.End as Date) !== 0 && (
        <>
          <span>-</span>
          {renderDate(date.End)}
        </>
      )}
      {showNextButton()}
    </Container>
  );
};

export default PreviousNextWeekSelect;
