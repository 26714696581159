/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
import { MenuItem, TextField } from '@material-ui/core';
import AppSettings from 'contexts/AppSettingsContext';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import { FilterState } from 'hooks/useFilterState/useFilterState';
import { usePrevious } from 'hooks/usePrevious/usePrevious';
import { isEqual, noop } from 'lodash';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createAppApi } from 'services/api';
import FilterDataProps from 'types/FilterDataProps';
import { SongInfo } from 'types/SongInfo';
import { SongPortfolio } from 'types/SongPortfolio';
import { useStyles } from './styles';

interface Props<T extends SongPortfolioFilterData> extends FilterDataProps<T> {
  disabled?: boolean;
  label?: string;
  stateControl?: (key: string, state: FilterState) => void;
}

export interface SongPortfolioFilterData {
  songInfos?: SongInfo[];
  SongPortfolio?: SongPortfolio;
}

const url = '/song-portfolios';
const FILTER_COMPONENT_KEY = 'SongPortfolioSelect';
const SongPortfolioSelect = <T extends SongPortfolioFilterData>({
  disabled = false,
  onChange = noop,
  label = '',
  initialValue,
  stateControl = noop,
}: Props<T>): ReactElement => {
  const { t } = useTranslation();
  const placeholder = t('fields.Select');
  const classes = useStyles();
  const lastDisabled = usePrevious(disabled);

  const { data: availableValues } = useRemoteApi<SongPortfolio[]>(url, {
    method: 'GET',
    enabled: true,
  });

  const [selectedPortfolio, setSelectedPortfolio] = useState<SongPortfolio | undefined>({} as SongPortfolio);
  const lastSelectedPortfolio = usePrevious(selectedPortfolio);
  const [portfolioSongs, setPortfolioSongs] = useState<SongInfo[] | undefined>([] as SongInfo[]);
  const { endpointURL } = useContext(AppSettings);

  useEffect(() => {
    stateControl(FILTER_COMPONENT_KEY, !disabled ? FilterState.INVALID : FilterState.VALID);
    if (
      (selectedPortfolio?.Id && !isEqual(lastSelectedPortfolio, selectedPortfolio)) ||
      (selectedPortfolio?.Id && !isEqual(disabled, lastDisabled))
    ) {
      // only hit the api if there is a valid portfolio id
      const api = createAppApi({ baseURL: endpointURL });
      api.get(`song-portfolios/${selectedPortfolio?.Id}/songs`).then(resp => {
        const songs = resp.data as SongInfo[];
        setPortfolioSongs(songs);
      });
    }
  }, [selectedPortfolio, disabled]);

  useEffect(() => {
    onChange({ value: { songInfos: portfolioSongs, SongPortfolio: selectedPortfolio } as T });
    stateControl(FILTER_COMPONENT_KEY, FilterState.VALID);
  }, [portfolioSongs]);

  useEffect(() => {
    if (initialValue?.Id && !isEqual(selectedPortfolio, initialValue)) {
      setSelectedPortfolio(initialValue);
    }
  }, [initialValue]);

  const handleChange = (ev: React.ChangeEvent<{ value: unknown }>) => {
    const value = ev.target.value as number;
    setSelectedPortfolio(availableValues?.find(v => v.Id === value));
  };

  return (
    <TextField
      select
      label={label}
      value={selectedPortfolio?.Id ?? ''}
      onChange={handleChange}
      disabled={disabled}
      aria-label={t('grid.filter.title.songPortfolios')}
      className={classes.root}
      placeholder={placeholder}
    >
      {(availableValues ?? []).map(item => {
        return (
          <MenuItem key={item.Id} value={item.Id}>
            {item.Name}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default SongPortfolioSelect;
