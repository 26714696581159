import { AgGridColumn } from '@ag-grid-community/react';
import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import { GridOptions } from '@ag-grid-enterprise/all-modules';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import { UseApiSettings } from 'hooks/useApi/ApiTypes';
import ReportResult from 'services/ReportResult';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import DetailGrid from 'components/DetailGrid';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import AirPlayCellRenderer from 'components/DetailGrid/CellRenderers/AirPlayCellRenderer';
import { SongAnalysisFilter, StationDetailFilter } from './utils/SongAnalysisTypes';
import { SevenDaySongAnalysisDayPartSummaryTransformedResponse } from './utils/SongAnalysisDayPartSummaryTypes';
import {
  SongAnalysisDayPartSummaryTransformer,
  SongAnalysisExecutiveSummaryRequestTransformer,
} from './utils/SongAnalysisTransformer';

export interface DayPartSummaryGridProps {
  onChangeFilter: { (value: StationDetailFilter): void };
  filters: SongAnalysisFilter;
}

const url = '/seven-day-song-analysis-daypart-summary-reports';
const DayPartSummaryGrid: React.FC<DayPartSummaryGridProps> = ({ filters }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({ sortPath: 'SpinsInfo/ThisWeek', sort: 'asc' });
  const { data: reportData, isLoading, settings, setSettings, isError } = useRemoteApi<
    SevenDaySongAnalysisDayPartSummaryTransformedResponse
  >(url, {
    data: filters,
    method: 'POST',
    enabled: false,
    transformRequest: SongAnalysisExecutiveSummaryRequestTransformer,
    transformResponse: SongAnalysisDayPartSummaryTransformer,
  });

  const TopOptions: GridOptions = {
    alignedGrids: [],
    defaultColDef: {
      editable: true,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 100,
    },
    suppressHorizontalScroll: true,
  };
  const BottomOptions: GridOptions = {
    alignedGrids: [],
    defaultColDef: {
      editable: true,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 100,
    },
  };
  ((TopOptions as GridOptions).alignedGrids ?? []).push(BottomOptions);
  ((BottomOptions as GridOptions).alignedGrids ?? []).push(TopOptions);
  return (
    <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
      <ServerSideReportGrid
        sortModel={sortModel}
        settings={(settings as unknown) as UseApiSettings<ReportResult<unknown>>}
        setSettings={(setSettings as unknown) as Dispatch<UseApiSettings<ReportResult<unknown>>>}
        filters={filters}
        detailProps={{
          gridOptions: TopOptions,
          domLayout: 'autoHeight',
          containerStyle: {
            height: '100%',
          },
        }}
      >
        <AgGridColumn
          field="FormatName"
          headerName={t(`grid.header.FormatName`)}
          minWidth={100}
          width={100}
          headerComponentParams={{ sortModel, onSortChange }}
        />
        <AgGridColumn
          field="DaypartsPercentageInfo"
          minWidth={400}
          width={400}
          headerName={t(`grid.header.AirPlayInformation`)}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            sortPath: 'SpinsInfo/ThisWeek',
            secondLine: [
              { label: 'grid.header.SpinsTw', sortPath: 'SpinsInfo/ThisWeek' },
              { label: 'grid.header.Overnight', sortPath: 'DaypartsInfo/Overnight' },
              { label: 'grid.header.AmDrive', sortPath: 'DaypartsInfo/AmDrive' },
              { label: 'grid.header.Midday', sortPath: 'DaypartsInfo/Midday' },
              { label: 'grid.header.PmDrive', sortPath: 'DaypartsInfo/PmDrive' },
              { label: 'grid.header.Evening', sortPath: 'DaypartsInfo/Evening' },
            ],
          }}
          cellRendererParams={{
            totalField: 'TotalSpinsTw',
            isTotalRow: false,
          }}
          cellRendererFramework={AirPlayCellRenderer}
        />
        <AgGridColumn
          field="DaypartsInfoToDate"
          minWidth={500}
          width={500}
          headerName={t(`grid.header.HistoricalInformation`)}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            sortPath: 'SpinsToDate',
            secondLine: [
              { label: 'grid.header.HistoricalDayPartInformation.Spins', sortPath: 'SpinsToDate' },
              { label: 'grid.header.HistoricalDayPartInformation.Overnight', sortPath: 'DaypartsInfoToDate/Overnight' },
              { label: 'grid.header.HistoricalDayPartInformation.AmDrive', sortPath: 'DaypartsInfoToDate/AmDrive' },
              { label: 'grid.header.HistoricalDayPartInformation.Midday', sortPath: 'DaypartsInfoToDate/Midday' },
              { label: 'grid.header.HistoricalDayPartInformation.PmDrive', sortPath: 'DaypartsInfoToDate/PmDrive' },
              { label: 'grid.header.HistoricalDayPartInformation.Evening', sortPath: 'DaypartsInfoToDate/Evening' },
            ],
          }}
          cellRendererParams={{
            totalField: 'SpinsToDate',
            isTotalRow: false,
          }}
          cellRendererFramework={AirPlayCellRenderer}
        />
      </ServerSideReportGrid>
      <DetailGrid
        reportData={[reportData?.TotalItems] ?? []}
        headerHeight={0}
        gridOptions={BottomOptions}
        domLayout="autoHeight"
        rowStyle={{ fontWeight: 'bold' }}
        containerStyle={{
          height: '100%',
        }}
      >
        <AgGridColumn
          field="FormatName"
          headerName={t(`grid.header.FormatName`)}
          minWidth={100}
          width={100}
          headerComponentParams={{ sortModel, onSortChange }}
        />
        <AgGridColumn
          field="DaypartsPercentageInfo"
          minWidth={400}
          width={400}
          headerName={t(`grid.header.AirPlayInformation`)}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            usePercentual: true,
            sortModel,
            onSortChange,
            sortPath: 'SpinsInfo/ThisWeek',
            secondLine: [
              { label: 'grid.header.SpinsTw', sortPath: 'SpinsInfo/ThisWeek' },
              { label: 'grid.header.Overnight', sortPath: 'DaypartsInfo/Overnight' },
              { label: 'grid.header.AmDrive', sortPath: 'DaypartsInfo/AmDrive' },
              { label: 'grid.header.Midday', sortPath: 'DaypartsInfo/Midday' },
              { label: 'grid.header.PmDrive', sortPath: 'DaypartsInfo/PmDrive' },
              { label: 'grid.header.Evening', sortPath: 'DaypartsInfo/Evening' },
            ],
          }}
          cellRendererParams={{
            totalField: 'TotalSpinsTw',
            isTotalRow: true,
          }}
          cellRendererFramework={AirPlayCellRenderer}
        />
        <AgGridColumn
          field="DaypartsInfoToDate"
          minWidth={500}
          width={500}
          headerName={t(`grid.header.HistoricalInformation`)}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            sortPath: 'SpinsToDate',
            secondLine: [
              { label: 'grid.header.HistoricalDayPartInformation.Spins', sortPath: 'SpinsToDate' },
              { label: 'grid.header.HistoricalDayPartInformation.Overnight', sortPath: 'DaypartsInfoToDate/Overnight' },
              { label: 'grid.header.HistoricalDayPartInformation.AmDrive', sortPath: 'DaypartsInfoToDate/AmDrive' },
              { label: 'grid.header.HistoricalDayPartInformation.Midday', sortPath: 'DaypartsInfoToDate/Midday' },
              { label: 'grid.header.HistoricalDayPartInformation.PmDrive', sortPath: 'DaypartsInfoToDate/PmDrive' },
              { label: 'grid.header.HistoricalDayPartInformation.Evening', sortPath: 'DaypartsInfoToDate/Evening' },
            ],
          }}
          cellRendererParams={{
            totalField: 'SpinsToDate',
            isTotalRow: true,
          }}
          cellRendererFramework={AirPlayCellRenderer}
        />
      </DetailGrid>
    </GridVisibilityWrapper>
  );
};

export default DayPartSummaryGrid;
