/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import SelectInput from 'components/SelectInput';
import ReportHeader from 'components/ReportHeader';
import FilterButton from 'components/FilterButton';
import { isEmpty, merge } from 'lodash';
import TabsGrid from 'components/TabsGrid';
import TabPanel from 'components/TabsGrid/TabPanel';
import SiblingReportsSelect from 'components/SiblingReportsSelect/SiblingReportsSelect';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import { BodyReportContainer } from 'pages/Reports/styles';
import { ReportCycleIdEnum } from 'types/ReportCycle';
import { getStationDisplayName } from 'utils/reports/GetStationDisplayName';
import { Container, ReportContainer, GridContainer, StationContainer } from './styles';
import Filter from './Filter';
import { StationPlaylistsFilter, StationTypeEnum } from './utils/StationPlaylistsTypes';
import StationPlaylistsGrid from './StationPlaylistsGrid';
import StationPlaylistsBuildingGrid from './StationPlaylistsBuildingGrid';
import NotPlayingGrid from './NotPlayingGrid';
import HistoricalGrid from './HistoricalGrid';

const optionTab = [
  {
    label: 'report.stationPlaylist.tabs.StationPlaylistsReport',
    value: 'StationPlaylistsReport',
  },
  {
    label: 'report.stationPlaylist.tabs.NotPlaying',
    value: 'NotPlaying',
  },
  {
    label: 'report.stationPlaylist.tabs.Historical',
    value: 'Historical',
  },
];

const StationPlaylistsReport: React.FC = () => {
  const [stationFilter, setStationFilterSelected] = useState({});
  const [filterValues, setFilterValues] = useState<StationPlaylistsFilter | undefined>({});
  const [station, setStation] = useState([]);
  const [stationSelected, setStationSelected] = useState('');
  const [tabValue, setTabValue] = useState<number>(0);
  const [filters, setFilters] = useState<StationPlaylistsFilter | undefined>();
  const [filterOpened, setFilterOpened] = useState(false);

  const url = 'stations-playlist-summary-reports';

  const { data: reportData } = useRemoteApi<any>(url, {
    params: { $top: 1000, $skip: 0, $inlinecount: 'allpages', $orderby: 'Name asc' },
    data: stationFilter,
    method: 'POST',
    enabled: !isEmpty(stationFilter),
    transformResponse: response => {
      return response.Stations.sort((a: any, b: any) => (a.Name > b.Name ? 1 : -1));
    },
  });

  useEffect(() => {
    if (!isEmpty(stationFilter)) {
      const stationSearch = reportData;
      setStation(stationSearch);
      const firstStation = stationSearch[0];
      setStationSelected(firstStation);
      setFilters(merge({}, filterValues, { StationInfo: firstStation }));
    }
  }, [reportData]);

  const onChangeTab = (value: number) => {
    setTabValue(value);
  };

  const onChangeFilter = (filtersParams: any | null) => {
    setFilterOpened(false);

    if (filtersParams?.StationSelectionType === StationTypeEnum.StationSelected) {
      const stationSearch = filtersParams?.Stations;
      if (filtersParams?.Stations) {
        setStation(stationSearch);
        const firstStation = stationSearch[0];
        setStationSelected(firstStation);
        setFilters(merge({}, filtersParams, { StationInfo: firstStation }));
      }
    } else if (
      filtersParams?.StationSelectionType === StationTypeEnum.StationPortfolioSelected ||
      filtersParams?.StationSelectionType === StationTypeEnum.StationPortfolioGroupSelected
    ) {
      const filterStation: StationPlaylistsFilter = {};
      filterStation.ReportCycle = filtersParams?.ReportCycle;
      if (filtersParams?.StationSelectionType === StationTypeEnum.StationPortfolioSelected) {
        filterStation.StationPortfolio = filtersParams?.StationPortfolio;
        filterStation.StationSelectionType = StationTypeEnum.StationPortfolioSelected;
      } else if (filtersParams?.StationSelectionType === StationTypeEnum.StationPortfolioGroupSelected) {
        filterStation.StationPortfolioGroup = filtersParams?.StationPortfolioGroup;
        filterStation.StationSelectionType = StationTypeEnum.StationPortfolioGroupSelected;
      }
      filterStation.PanelInfo = filtersParams?.PanelInfo;
      filterStation.GrcInfo = filtersParams?.GrcInfo;
      filterStation.TopX = filtersParams?.TopX;
      setStationFilterSelected(merge({}, filterStation));
      setFilterValues(filtersParams);
    }
  };

  const onChangeStation = (stationSelect: any) => {
    setStationSelected(stationSelect);
    setFilters(merge({}, filters, { StationInfo: stationSelect }));
  };

  return (
    <Container container direction="column" spacing={0}>
      <ReportHeader title="Stations" subtitle="7-Day" />
      <ReportContainer>
        <SiblingReportsSelect />
        <FilterButton onClickFilter={() => setFilterOpened(!filterOpened)} isOpened={filterOpened} />
      </ReportContainer>
      <Filter isOpened={filterOpened} changeFilter={onChangeFilter} />
      <BodyReportContainer hidden={!filters}>
        <StationContainer>
          <SelectInput
            normalizeLabel={option => `${getStationDisplayName(option)}`}
            optionLabel="Title"
            options={station}
            value={stationSelected}
            onChangeValue={onChangeStation}
          />
        </StationContainer>
        <GridContainer>
          <TabsGrid tabValue={tabValue} onChangeTab={onChangeTab} tabs={optionTab}>
            <TabPanel value={tabValue} index={0}>
              {filters?.ReportCycle === ReportCycleIdEnum.building ? (
                <StationPlaylistsBuildingGrid filters={filters} />
              ) : (
                <StationPlaylistsGrid filters={filters} />
              )}
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <NotPlayingGrid filters={filters} />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <HistoricalGrid filters={filters} />
            </TabPanel>
          </TabsGrid>
        </GridContainer>
      </BodyReportContainer>
    </Container>
  );
};

export default StationPlaylistsReport;
