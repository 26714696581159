/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
import { DateRangeGroup } from 'types/DateRange';

export interface DateRangeResponse {
  ThisWeekRange: DateRangeGroup;
  LastWeekRange: DateRangeGroup;
}

export enum DatesUrlEnum {
  MOST_RECENT_SATURDAY = '/dates/most-recent-saturday',
  ROLLING_AVAILABILITY = '/dates/rolling-availability',
  LAST_UPDATED = 'dates/last-update-date',
}
