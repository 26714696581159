import { AgGridColumn } from '@ag-grid-community/react';
import DayPartsCellRenderer from 'components/DetailGrid/CellRenderers/DayPartsCellRenderer';
import DayPartsHeaderComponent from 'components/DetailGrid/HeaderComponents/DayPartsHeaderComponent';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import ReportResult from 'services/ReportResult';
import { intersection } from 'lodash';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import StationRankMarketFormatHeaderComponent from 'components/DetailGrid/HeaderComponents/StationRankMarketOwnerHeaderComponent';
import ThisLastMoveCellRenderer from 'components/DetailGrid/CellRenderers/ThisLastMoveCellRenderer';
import ThisLastMoveHeaderComponent from 'components/DetailGrid/HeaderComponents/ThisLastMoveHeaderComponent';
import NewThisWeekCellRenderer from 'components/DetailGrid/CellRenderers/NewThisWeekCellRenderer';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import OwnerSelection from 'components/Filter/components/OwnerSelection';
import FilterDataEvent from 'types/FilterDataEvent';
import MultiColumnsCellRenderer from 'components/DetailGrid/CellRenderers/MultiColumnsCellRenderer/MultiColumnsCellRenderer';
import { formatDate } from 'utils/dates';
import { numberFormat } from 'utils/currency';
import FormatSelection from 'components/Filter/components/FormatSelection';
import { Button } from '@material-ui/core';
import { CountryFormatCodeEnum } from 'types/Format';
import StationRankMarketFormatCellRenderer from '../../../../../components/DetailGrid/CellRenderers/StationRankMarketFormatCellRenderer';
import {
  SevenDaySongAnalysisStationDetailsItem,
  StationDetailFilter,
  ExtendedSongAnalysisFilter,
} from './utils/SongAnalysisTypes';
import { FilterGridContainer, OwnerContainer, FormatContainer } from './styles';
import {
  SongAnalysisStationDetailRequestTransformer,
  SongAnalysisStationDetailsTransformer,
} from './utils/SongAnalysisTransformer';

export interface SongAnalysisGridProps {
  onChangeFilter: { (value: StationDetailFilter): void };
  filters: ExtendedSongAnalysisFilter;
}

const countryFormatsCode = Object.values(CountryFormatCodeEnum) as string[];
const SEARCH_BY_FORMAT = 1;
const url = '/seven-day-song-analysis-station-details-reports';
const StationDetailsGrid: React.FC<SongAnalysisGridProps> = ({ filters, onChangeFilter }) => {
  const [stationDetailFilter, setStationDetailFilter] = useState<StationDetailFilter | undefined>();

  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({ sortPath: 'StationDisplayName', sort: 'asc' });
  const { data: reportData, isLoading, settings, setSettings, isError } = useRemoteApi<
    ReportResult<SevenDaySongAnalysisStationDetailsItem>
  >(url, {
    data: filters,
    method: 'POST',
    enabled: false,
    transformRequest: SongAnalysisStationDetailRequestTransformer,
    transformResponse: SongAnalysisStationDetailsTransformer,
  });

  const onChangeStationDetailFilter = (event: FilterDataEvent<StationDetailFilter>) => {
    const { value } = event;
    setStationDetailFilter({
      ...stationDetailFilter,
      ...value,
    });
  };

  const hasCountryFormat = () => {
    const formats = filters?.Formats?.map(format => format.FormatCode) ?? [];
    const equalsFormat = intersection(countryFormatsCode, formats);
    return equalsFormat?.length > 0;
  };

  return (
    <>
      <FilterGridContainer>
        <OwnerContainer>
          <OwnerSelection onChange={onChangeStationDetailFilter} />
        </OwnerContainer>
        {filters?.SearchMode !== SEARCH_BY_FORMAT && (
          <FormatContainer>
            <FormatSelection onChange={onChangeStationDetailFilter} panelCode={filters?.PanelInfo?.PanelCode} />
          </FormatContainer>
        )}
        <Button
          style={{ justifyContent: 'flex-end', fontSize: '10px' }}
          color="primary"
          onClick={() => onChangeFilter(stationDetailFilter as StationDetailFilter)}
        >
          {t('filter.title.apply')}
        </Button>
      </FilterGridContainer>
      <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
        <ServerSideReportGrid sortModel={sortModel} settings={settings} setSettings={setSettings} filters={filters}>
          <AgGridColumn
            field="IsNewThisWeek"
            headerName={t('grid.header.NewThisWeek')}
            minWidth={42}
            wrapText
            cellRendererFramework={NewThisWeekCellRenderer}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="StationDisplayName"
            minWidth={200}
            pinned
            cellRendererFramework={StationRankMarketFormatCellRenderer}
            headerComponentFramework={StationRankMarketFormatHeaderComponent}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="PointsInfo"
            minWidth={95}
            headerName={t(`grid.header.PointsInfoTW`)}
            cellRendererFramework={ThisLastMoveCellRenderer}
            headerComponentFramework={ThisLastMoveHeaderComponent}
            headerComponentParams={{ sortModel, onSortChange }}
            hide={!hasCountryFormat()}
          />
          <AgGridColumn
            field="SpinsInfo"
            minWidth={95}
            headerName={t(`grid.header.SpinsInfoTW`)}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              sortPath: 'SpinsInfo/ThisWeek',
              secondLine: [
                { label: 'grid.header.LW', sortPath: 'SpinsInfo/LastWeek' },
                { label: 'grid.header.plusMinus', sortPath: 'SpinsInfo/Move' },
              ],
            }}
            cellRendererFramework={ThisLastMoveCellRenderer}
          />
          <AgGridColumn
            field="ImpressionsThisWeek"
            valueFormatter={({ value }: ValueFormatterParams) => numberFormat(value)}
            headerName={t(`grid.header.impression.ImpressionMill`)}
            minWidth={80}
            headerComponentParams={{ sortModel, onSortChange, sortPath: 'ImpressionsInfo/SpinsThisWeek' }}
          />
          <AgGridColumn
            headerName="Day Parts"
            field="DaypartsInfo"
            width={230}
            minWidth={230}
            headerComponentFramework={DayPartsHeaderComponent}
            headerComponentParams={{ sortModel, onSortChange }}
            cellRendererFramework={DayPartsCellRenderer}
          />
          <AgGridColumn
            field="DayByDaySpinsDaily"
            minWidth={200}
            width={200}
            headerName={t(`grid.header.DayByDaySpins`)}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              secondLine: [
                { label: ['grid.header.dayByDaySpinsDaily.Day1'], sortPath: 'DayByDaySpins/Day1' },
                { label: ['grid.header.dayByDaySpinsDaily.Day2'], sortPath: 'DayByDaySpins/Day2' },
                { label: ['grid.header.dayByDaySpinsDaily.Day3'], sortPath: 'DayByDaySpins/Day3' },
                { label: ['grid.header.dayByDaySpinsDaily.Day4'], sortPath: 'DayByDaySpins/Day4' },
                { label: ['grid.header.dayByDaySpinsDaily.Day5'], sortPath: 'DayByDaySpins/Day5' },
                { label: ['grid.header.dayByDaySpinsDaily.Day6'], sortPath: 'DayByDaySpins/Day6' },
                { label: ['grid.header.dayByDaySpinsDaily.Day7'], sortPath: 'DayByDaySpins/Day7' },
              ],
            }}
            cellRendererParams={{
              fields: [
                'DayByDaySpinsDaily.Day1',
                'DayByDaySpinsDaily.Day2',
                'DayByDaySpinsDaily.Day3',
                'DayByDaySpinsDaily.Day4',
                'DayByDaySpinsDaily.Day5',
                'DayByDaySpinsDaily.Day6',
                'DayByDaySpinsDaily.Day7',
              ],
            }}
            cellRendererFramework={MultiColumnsCellRenderer}
          />
          <AgGridColumn
            field="FirstPlayedDate"
            valueFormatter={({ value }: ValueFormatterParams) => formatDate(value)}
            headerName={t(`grid.header.FirstPlayedDate`)}
            minWidth={130}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="SpinsToDate"
            minWidth={100}
            width={100}
            headerName={t(`grid.header.TD`)}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="FormatComparisonInfo"
            minWidth={200}
            width={200}
            headerName={t(`grid.header.FormatComparison`)}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              secondLine: [
                { label: ['grid.header.formatComparison.Avg'], sortPath: 'FormatComparisonAverage' },
                { label: ['grid.header.formatComparison.Move'], sortPath: 'FormatComparisonMove' },
              ],
            }}
            cellRendererParams={{
              fields: ['FormatComparisonAverage', 'FormatComparisonMove'],
            }}
            cellRendererFramework={MultiColumnsCellRenderer}
          />
        </ServerSideReportGrid>
      </GridVisibilityWrapper>
    </>
  );
};

export default StationDetailsGrid;
