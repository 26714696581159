import { AgGridColumn } from '@ag-grid-community/react/lib/agGridColumn';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import { Typography } from '@material-ui/core';
import DayPartsCellRenderer from 'components/DetailGrid/CellRenderers/DayPartsCellRenderer';
import DayPartsHeaderComponent from 'components/DetailGrid/HeaderComponents/DayPartsHeaderComponent';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { InnerSongHistoryTransformerResponse } from './utils/SongHistoryTransformer';
import {
  SevenDaySongHistoryFilter,
  SevenDaySongHistoryItem,
  SevenDaySongHistoryResponseTransformed,
} from './utils/SongHistoryTypes';

interface SongHistoryInnerGridProps {
  filters?: SevenDaySongHistoryFilter;
  rowData?: SevenDaySongHistoryItem;
}

const url = '/seven-day-song-analysis-song-history-station-daypart-reports';
const SongHistoryInnerGrid: FC<SongHistoryInnerGridProps> = ({ filters, rowData }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({
    sortPath: 'RankThisWeek',
    sort: 'asc',
  });
  const { setSettings, settings, data: reportData, isLoading, isError } = useRemoteApi<
    SevenDaySongHistoryResponseTransformed
  >(url, {
    data: filters,
    method: 'POST',
    enabled: false,
    transformRequest: data => {
      return {
        StationInfo: { Name: rowData?.StationName },
        MinSpins: data?.MinSpins ?? 1,
        SongInfo: data.SongInfo,
      };
    },
    transformResponse: InnerSongHistoryTransformerResponse,
  });

  return (
    <>
      <Typography variant="subtitle1" align="center">
        {t('report.songAnalysis.songHistory')}
      </Typography>
      <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
        <ServerSideReportGrid
          setSettings={setSettings}
          settings={settings}
          filters={filters}
          sortModel={sortModel}
          detailProps={{
            domLayout: 'autoHeight',
            containerStyle: {
              height: '100%',
              paddingBottom: '30px',
            },
          }}
          hideDetails
        >
          <AgGridColumn
            headerName={t('grid.header.Title')}
            minWidth={100}
            width={100}
            field="DaypartName"
            valueFormatter={({ value }: ValueFormatterParams) => t(value)}
          />
          <AgGridColumn
            headerName="Day Parts"
            field="DaypartsInfo"
            maxWidth={360}
            minWidth={360}
            headerComponentFramework={DayPartsHeaderComponent}
            headerComponentParams={{ sortModel, onSortChange, usePercentual: true }}
            cellRendererFramework={DayPartsCellRenderer}
          />
        </ServerSideReportGrid>
      </GridVisibilityWrapper>
    </>
  );
};

export default SongHistoryInnerGrid;
