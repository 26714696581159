import { Column, ICellRendererParams } from '@ag-grid-enterprise/all-modules';
import { Grid } from '@material-ui/core';
import { LinkToListProps } from 'components/LinkToList/LinkToList';
import React, { ReactElement } from 'react';
import InnerTableFactory from './InnerTableFactory';

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface ExpandDetailCellRendererConfig {
  linksElement?: (data?: any) => ReactElement<LinkToListProps> | undefined;
  footerElement?: ReactElement<{ rowData: any }>;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface ExpandDetailCellRendererParams extends ICellRendererParams, ExpandDetailCellRendererConfig {
  invisibleCols: Column[];
  reportQuery: any;
  reportMetadata: any;
}

const ExpandDetailCellRenderer: React.FC<ExpandDetailCellRendererParams> = (params: ExpandDetailCellRendererParams) => {
  const {
    columnApi,
    data,
    api: gridApi,
    node,
    invisibleCols,
    footerElement,
    linksElement = () => undefined,
    reportQuery,
    reportMetadata,
  } = params;

  const getSupportElement = (element?: ReactElement<any>) => {
    if (!element) return undefined;
    return React.cloneElement(element, { ...element.props, rowData: data });
  };

  const getComponents = () => {
    return InnerTableFactory({
      invisibleColumns: invisibleCols,
      data,
      gridApi,
      columnApi,
      node,
      reportQuery,
      reportMetadata,
    });
  };

  return (
    <>
      {getSupportElement(linksElement(data))}
      <Grid
        container
        wrap="nowrap"
        spacing={2}
        direction="column"
        justify="center"
        alignItems="stretch"
        style={{ padding: '15px' }}
      >
        {getComponents()}
      </Grid>
      {getSupportElement(footerElement)}
    </>
  );
};

export default ExpandDetailCellRenderer;
