import { AgGridColumn } from '@ag-grid-community/react';
import React from 'react';
import MultiColumnsCellRenderer from 'components/DetailGrid/CellRenderers/MultiColumnsCellRenderer/MultiColumnsCellRenderer';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import { useTranslation } from 'react-i18next';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import { formatDate } from 'utils/dates';
import { SongTrackingPropsGrid } from '.';
import { normalizeSongTracking } from './utils/SongTrackingReportTransform';

const SummaryReport: React.FC<SongTrackingPropsGrid> = ({ filters, settings, setSettings }) => {
  const { t } = useTranslation();
  const { sortModel } = useSortColumn({ sortPath: 'ReportName', sort: 'asc' });
  return (
    <>
      <ServerSideReportGrid
        sortModel={sortModel}
        settings={settings}
        setSettings={setSettings}
        filters={filters}
        transformResponse={normalizeSongTracking}
      >
        <AgGridColumn
          field="SpinTrends"
          headerName={t('grid.header.TotalPlays')}
          minWidth={350}
          maxWidth={350}
          valueFormatter={({ value }: ValueFormatterParams) => (value ? `${+parseFloat(value).toFixed(4)}` : '--')}
          headerComponentParams={{
            secondLine: [
              t(`grid.header.totalPlays.Week1`),
              t(`grid.header.totalPlays.Week2`),
              t(`grid.header.totalPlays.Move`),
              t(`grid.header.totalPlays.Week3`),
              t(`grid.header.totalPlays.Week4`),
              `${t(`grid.header.totalPlays.TD`)} ${formatDate(new Date(), 'MM/dd')}`,
            ],
          }}
          cellRendererParams={{
            fields: [
              'SpinTrends.Week1',
              'SpinTrends.Week2',
              'SpinTrends.Move',
              'SpinTrends.Week3',
              'SpinTrends.Week4',
              'SpinTrends.SpinsToDate',
            ],
          }}
          cellRendererFramework={MultiColumnsCellRenderer}
          headerComponentFramework={MultiColumnsHeaderComponent}
        />
        <AgGridColumn
          field="ImpressionsTotalInfo"
          headerName={t('grid.header.ImpressionsInfo')}
          minWidth={250}
          maxWidth={250}
          headerComponentParams={{
            secondLine: [
              { label: t(`grid.header.impressions.ImpressionsThisWeek`) },
              { label: t(`grid.header.impressions.ImpressionsLastWeek`) },
              { label: t(`grid.header.impressions.ImpressionsMove`) },
            ],
          }}
          cellRendererParams={{
            fields: [
              'ImpressionsTotalInfo.ImpressionsThisWeek',
              'ImpressionsTotalInfo.ImpressionsLastWeek',
              'ImpressionsTotalInfo.ImpressionsMove',
            ],
          }}
          cellRendererFramework={MultiColumnsCellRenderer}
          headerComponentFramework={MultiColumnsHeaderComponent}
        />
        <AgGridColumn
          field="StationPlaying"
          headerName={t('grid.header.StationPlaying')}
          minWidth={200}
          maxWidth={200}
          headerComponentParams={{
            secondLine: [
              {
                label: t(`grid.header.stationPlaying.PlayingThisWeek`),
              },
              { label: t(`grid.header.stationPlaying.PlayingLastWeek`) },
              { label: t(`grid.header.stationPlaying.Move`) },
            ],
          }}
          cellRendererParams={{
            fields: ['StationPlaying.PlayingThisWeek', 'StationPlaying.PlayingLastWeek', 'StationPlaying.PlayingMove'],
          }}
          cellRendererFramework={MultiColumnsCellRenderer}
          headerComponentFramework={MultiColumnsHeaderComponent}
        />
        <AgGridColumn
          field="NotPlaying"
          headerName={t('grid.header.NotPlaying')}
          minWidth={200}
          maxWidth={200}
          valueFormatter={({ value }: ValueFormatterParams) => (value ? `${+parseFloat(value).toFixed(4)}` : '--')}
          headerComponentParams={{
            secondLine: [
              { label: t(`grid.header.notPlaying.NotPlayingThisWeek`) },
              { label: t(`grid.header.notPlaying.NotPlayingLastWeek`) },
              { label: t(`grid.header.notPlaying.NotPlayingMove`) },
            ],
          }}
          cellRendererParams={{
            fields: ['NotPlaying.NotPlayingThisWeek', 'NotPlaying.NotPlayingLastWeek', 'NotPlaying.NotPlayingMove'],
          }}
          cellRendererFramework={MultiColumnsCellRenderer}
          headerComponentFramework={MultiColumnsHeaderComponent}
        />
      </ServerSideReportGrid>
    </>
  );
};

export default SummaryReport;
