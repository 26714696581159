import { AgGridColumn } from '@ag-grid-community/react';
import DayPartsCellRenderer from 'components/DetailGrid/CellRenderers/DayPartsCellRenderer';
import DayPartsHeaderComponent from 'components/DetailGrid/HeaderComponents/DayPartsHeaderComponent';
import WeeklyCellRenderer from 'components/DetailGrid/CellRenderers/WeekCellRenderer';
import WeeklyHeaderComponent from 'components/DetailGrid/HeaderComponents/WeeklyHeaderComponent';
import React from 'react';
import { useTranslation } from 'react-i18next';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import ArtistCellRenderer from 'components/DetailGrid/CellRenderers/ArtistCellRenderer/ArtistCellRenderer';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import { formatDate } from 'utils/dates';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import DayOfTheWeekCellRenderer from 'components/DetailGrid/CellRenderers/DaysOfTheWeekRenderer';
import DaysOfTheWeekComponent from 'components/DetailGrid/HeaderComponents/DaysOfTheWeekComponent';
import MultiColumnsCellRenderer from 'components/DetailGrid/CellRenderers/MultiColumnsCellRenderer/MultiColumnsCellRenderer';
import { numberFormat } from 'utils/currency';
import { DetailsGridTransformer } from './utils/RegionsMarketsTrendingAnalysisReportTransformer';
import { TrendingAnalysisByStationGridProps } from './TrendingAnalysisByStationReport';
import { TrendingAnalysisReport } from '../TrendingAnalysisReport/utils/TrendingAnalysisReport';

const DetailsGrid: React.FC<TrendingAnalysisByStationGridProps> = ({ report, filters, settings, setSettings }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({ sortPath: 'StationDisplayName', sort: 'asc' });
  const { ShowImpressions } = filters as TrendingAnalysisReport;
  return (
    <ServerSideReportGrid
      sortModel={sortModel}
      settings={settings}
      setSettings={setSettings}
      filters={filters}
      transformResponse={DetailsGridTransformer}
    >
      <AgGridColumn
        field="StationPortfolioName"
        headerName={t('grid.header.StationPortfolioName')}
        minWidth={100}
        headerComponentParams={{
          sortModel,
          sortPath: 'StationPortfolioName',
          onSortChange,
        }}
      />
      <AgGridColumn
        headerName={t('grid.header.StationName')}
        field="StationDisplayName"
        minWidth={100}
        wrapText
        headerComponentParams={{
          sortModel,
          sortPath: 'StationDisplayName',
          onSortChange,
        }}
      />
      <AgGridColumn
        headerName={t('grid.header.FormatName')}
        field="FormatShort"
        minWidth={80}
        wrapText
        headerComponentParams={{
          sortModel,
          sortPath: 'FormatShort',
          onSortChange,
        }}
      />
      <AgGridColumn
        field="ArtistInfo"
        headerName={t(`grid.header.Title`)}
        minWidth={120}
        pinned
        headerComponentFramework={MultiColumnsHeaderComponent}
        headerComponentParams={{
          sortModel,
          sortPath: 'Title',
          onSortChange,
          secondLine: [{ label: 'grid.header.Artist', sortPath: 'Artist' }],
        }}
        cellRendererFramework={ArtistCellRenderer}
      />
      <AgGridColumn
        headerName={t('grid.header.MarketName')}
        field="MarketName"
        minWidth={100}
        wrapText
        headerComponentParams={{
          sortModel,
          sortPath: 'MarketName',
          onSortChange,
        }}
      />
      <AgGridColumn
        headerName={t('grid.header.MarketRank')}
        field="MarketRank"
        minWidth={100}
        wrapText
        headerComponentParams={{
          sortModel,
          sortPath: 'MarketRank',
          onSortChange,
        }}
      />
      <AgGridColumn
        headerName={t('grid.header.ThisWeekRank')}
        field="ThisWeekRank"
        minWidth={100}
        wrapText
        valueFormatter={({ value }: ValueFormatterParams) => (value && value !== 0 ? value : '--')}
        headerComponentParams={{
          sortModel,
          sortPath: 'ThisWeekRank',
          onSortChange,
        }}
      />
      <AgGridColumn
        headerName={t('grid.header.FormatCode')}
        field="IsSongAdd"
        valueFormatter={({ value }: ValueFormatterParams) => (value ? 'A' : '---')}
        minWidth={100}
        wrapText
        headerComponentParams={{
          sortModel,
          sortPath: 'IsSongAdd',
          onSortChange,
        }}
      />
      <AgGridColumn
        cellRendererFramework={DayOfTheWeekCellRenderer}
        headerComponentFramework={DaysOfTheWeekComponent}
        field="DailySpinTrends"
        headerComponentParams={{ name: t('grid.header.SpinsDaily'), rangeDate: report?.DateRange }}
        minWidth={250}
        wrapText
      />
      <AgGridColumn
        cellRendererFramework={WeeklyCellRenderer}
        headerComponentFramework={WeeklyHeaderComponent}
        field="WeeklySpinTrends"
        headerComponentParams={{ name: t('grid.header.SpinsWeekly') }}
        minWidth={250}
        wrapText
      />
      <AgGridColumn
        cellRendererFramework={DayPartsCellRenderer}
        headerComponentFramework={DayPartsHeaderComponent}
        headerComponentParams={{ name: t('grid.header.DaypartSpins') }}
        field="DaypartSpins"
        minWidth={200}
        wrapText
      />
      <AgGridColumn field="SpinsToDate" headerName={t('grid.header.SpinsToDate')} minWidth={80} />
      <AgGridColumn
        field="FirstMonitored"
        valueFormatter={({ value }: ValueFormatterParams) => (value ? formatDate(value) : '--')}
        headerName={t('grid.header.FirstDetect')}
        headerComponentParams={{
          sortModel,
          sortPath: 'FirstMonitored',
          onSortChange,
        }}
        minWidth={120}
      />

      <AgGridColumn
        field="ImpressionsToDate"
        hide={!ShowImpressions}
        headerName={t('grid.header.ImpressionsToDate')}
        minWidth={120}
        valueFormatter={({ value: v }: ValueFormatterParams) => (v ? numberFormat(v) : '--')}
        headerComponentParams={{
          sortModel,
          sortPath: 'ImpressionsToDate',
          onSortChange,
        }}
      />

      <AgGridColumn
        hide={!ShowImpressions}
        cellRendererFramework={DayOfTheWeekCellRenderer}
        headerComponentFramework={DaysOfTheWeekComponent}
        field="DailyImpressionsTrends"
        headerComponentParams={{
          name: t('grid.header.ImpressionsDaily'),
          rangeDate: report.DateRange,
          sortModel,
          onSortChange,
        }}
        minWidth={300}
        width={300}
        wrapText
      />
      <AgGridColumn
        hide={!ShowImpressions}
        cellRendererFramework={MultiColumnsCellRenderer}
        headerComponentFramework={MultiColumnsHeaderComponent}
        field="WeeklyImpressionsTrends"
        minWidth={300}
        width={300}
        wrapText
        headerComponentParams={{
          name: t('grid.header.ImpressionsWeekly'),
          rangeDate: report.DateRange,
          secondLine: [
            t(`grid.header.totalPlays.Week1`),
            t(`grid.header.totalPlays.Week2`),
            t(`grid.header.totalPlays.Week3`),
            t(`grid.header.totalPlays.Week4`),
          ],
        }}
        cellRendererParams={{
          fields: [
            'WeeklyImpressionsTrends.Week1',
            'WeeklyImpressionsTrends.Week2',
            'WeeklyImpressionsTrends.Week3',
            'WeeklyImpressionsTrends.Week4',
          ],
        }}
      />
      <AgGridColumn
        hide={!ShowImpressions}
        cellRendererFramework={DayPartsCellRenderer}
        headerComponentFramework={DayPartsHeaderComponent}
        headerComponentParams={{ name: t('grid.header.dayPartImpressions') }}
        field="DaypartImpressions"
        minWidth={300}
        wrapText
      />
    </ServerSideReportGrid>
  );
};

export default DetailsGrid;
