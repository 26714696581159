import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const InfoContainer = styled(Grid)`
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
`;
