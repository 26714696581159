import ReportResult from 'services/ReportResult';
import { StationCurrentDayBuildingRow, TransformedStationCurrentDayBuildingRow } from './CurrentDayMonitorTypes';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const StationCurrentDailyTransformer = (
  response: ReportResult<StationCurrentDayBuildingRow>,
): ReportResult<TransformedStationCurrentDayBuildingRow> => {
  const transformItems = (row: StationCurrentDayBuildingRow): any => {
    return {
      ...row,
      SongInfo: {
        Artist: row.Artist,
        Title: row.Title,
        Label: row.ReleaseYear,
        SongCode: row.SongCode,
      },
    };
  };
  return {
    ...response,
    Items: response.Items.map(transformItems),
  };
};

export const StationCurrentDayBuildingTransformer = (
  response: ReportResult<StationCurrentDayBuildingRow>,
): ReportResult<TransformedStationCurrentDayBuildingRow> => {
  const transformItems = (row: StationCurrentDayBuildingRow): any => {
    return {
      ...row,
      SongInfo: {
        Artist: row.Artist,
        Label: row.Label,
        Title: row.Title,
        SongCode: row.SongCode,
      },
      Rank: {
        ThisWeek: row.RankThisWeek,
        LastWeek: row.RankLastWeek,
      },
    };
  };
  return {
    ...response,
    Items: response.Items.map(transformItems),
  };
};
