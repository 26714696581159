import ReportResult from 'services/ReportResult';
import DayPartInfo from 'types/DayPart';
import { convertWithoutFloorToPercentage } from '../../../../../../utils/maths/maths';
import { formatDate } from '../../../../../../utils/dates/index';

import {
  SevenDayInnerSongHistoryItem,
  SevenDaySongHistoryFilter,
  SevenDaySongHistoryResponse,
  SevenDaySongHistoryResponseTransformed,
  SevenDayInnerSongHistoryTransformedItem,
} from './SongHistoryTypes';
import { ExtendedSongAnalysisFilter } from './SongAnalysisTypes';
import { FORMAT_ALL } from './SongAnalysisContants';

export const SongHistoryRequestTransformer = (
  songHistoryFilter: ExtendedSongAnalysisFilter,
): SevenDaySongHistoryFilter => {
  return {
    PanelInfo: songHistoryFilter?.PanelInfo,
    SongInfo: songHistoryFilter?.SongInfo,
    FormatInfo: songHistoryFilter?.FormatInfo ?? FORMAT_ALL,
    MinSpins: songHistoryFilter?.MinSpins ?? 1,
  };
};

export const SongHistoryTransformerResponse = (
  sevenDayHistorySongResponse: SevenDaySongHistoryResponse,
): SevenDaySongHistoryResponseTransformed => {
  return {
    ...sevenDayHistorySongResponse,
    Items: sevenDayHistorySongResponse.Items,
    TotalItems: {
      ...{
        GroupOwnerName: 'Totals',
        DaypartsInfo: sevenDayHistorySongResponse.DaypartsInfo,
        SpinsToDate: sevenDayHistorySongResponse.TotalPlays,
        TotalImpressions: sevenDayHistorySongResponse.TotalAqh,
      },
    },
  };
};

const innerFields: Array<keyof SevenDayInnerSongHistoryItem> = [
  'SpinsDaypartsInfo',
  'ImpressionsDaypartsInfo',
  'SpinsPercentDaypartsInfo',
  'FirstPlayedDaypartsInfo',
  'LastPlayedDaypartsInfo',
];

const TransformDayPart = (
  item: SevenDayInnerSongHistoryItem,
  field: keyof SevenDayInnerSongHistoryItem,
): DayPartInfo => {
  const daypartValue = item[field] as DayPartInfo;
  switch (field) {
    case 'ImpressionsDaypartsInfo': {
      return {
        AmDrive: ((daypartValue.AmDrive as number) / 1000).toFixed(3),
        Evening: ((daypartValue.Evening as number) / 1000).toFixed(3),
        Midday: ((daypartValue.Midday as number) / 1000).toFixed(3),
        Overnight: ((daypartValue.Overnight as number) / 1000).toFixed(3),
        PmDrive: ((daypartValue.PmDrive as number) / 1000).toFixed(3),
      };
    }
    case 'SpinsPercentDaypartsInfo': {
      return {
        AmDrive: `${convertWithoutFloorToPercentage(daypartValue.AmDrive as number)}%`,
        Evening: `${convertWithoutFloorToPercentage(daypartValue.Evening as number)}%`,
        Midday: `${convertWithoutFloorToPercentage(daypartValue.Midday as number)}%`,
        Overnight: `${convertWithoutFloorToPercentage(daypartValue.Overnight as number)}%`,
        PmDrive: `${convertWithoutFloorToPercentage(daypartValue.PmDrive as number)}%`,
      };
    }
    case 'FirstPlayedDaypartsInfo':
    case 'LastPlayedDaypartsInfo': {
      return {
        AmDrive: formatDate(daypartValue.AmDrive as string),
        Evening: formatDate(daypartValue.Evening as string),
        Midday: formatDate(daypartValue.Midday as string),
        Overnight: formatDate(daypartValue.Overnight as string),
        PmDrive: formatDate(daypartValue.PmDrive as string),
      };
    }
    default: {
      return daypartValue;
    }
  }
};

export const InnerSongHistoryTransformerResponse = (
  sevenDayInnerHistorySongResponse: ReportResult<SevenDayInnerSongHistoryItem>,
): ReportResult<SevenDayInnerSongHistoryTransformedItem> => {
  return {
    Count: innerFields.length,
    Items: innerFields.map(innerField => ({
      DaypartName: `report.songAnalysis.songHistoryColumns.${innerField}`,
      DaypartsInfo: TransformDayPart(sevenDayInnerHistorySongResponse.Items[0], innerField),
    })),
  };
};
