/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import useRefreshInterval from 'hooks/useRefreshInterval/useRefreshInterval';
import { format } from 'date-fns';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { InfoContainer } from './styles';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
  onChange: (arg0: any) => void;
  filters: any;
}

const LatestRefreshDate: React.FC<Props> = ({ filters, onChange }) => {
  const { t } = useTranslation();
  const renderDateLastRequest = () => {
    return filters ? format(new Date(lastRefreshTimestamp || new Date().getTime()), 'MM/dd hh:mm a') : '';
  };
  const { lastRefreshTimestamp } = useRefreshInterval();
  useEffect(() => {
    if (filters) {
      onChange({ ...filters, refreshedAt: lastRefreshTimestamp });
    }
  }, [lastRefreshTimestamp]);

  return renderDateLastRequest() ? (
    <InfoContainer>
      <Typography>{`${t('grid.header.AsOf')}: ${renderDateLastRequest()}`}</Typography>
    </InfoContainer>
  ) : null;
};

export default LatestRefreshDate;
