/* eslint-disable @typescript-eslint/no-explicit-any */

import AccordionFilter from 'components/Filter/components/AccordionFilter';
import CalendarFilter from 'components/Filter/components/CalendarFilter';
import SingleSongSelection from 'components/Filter/components/SingleSongSelection/SingleSongSelection';
import StationSelect from 'components/Filter/components/StationSelect/SelectedStation';
import FilterBase from 'components/FilterBase';
import InputNumberFilter from 'components/InputNumber';
import { parseISO, subDays } from 'date-fns';
import useDeepLink from 'hooks/useDeepLink/useDeepLink';
import { defaultFilterTransformer } from 'hooks/useDeepLink/utils/defaultFilterTransformer';
import useFilterState from 'hooks/useFilterState/useFilterState';
import useStickyFilter from 'hooks/useStickyFilter/useStickyFilter';
import { get, set } from 'lodash';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FilterDataEvent from 'types/FilterDataEvent';
import { SongInfo } from 'types/SongInfo';
import { Station } from 'types/Station';
import { daysMinus, formatDateTypes } from 'utils/dates';
import { getStationDisplayName } from 'utils/reports/GetStationDisplayName';
import { MaxWeeks, StationSpinGridFilter } from './utils/StationSpinGridTypes';

interface StationSpinGridReportProps {
  changeFilter: { (filters: StationSpinGridFilter): void };
  isOpened: boolean;
}

const Filter: React.FC<StationSpinGridReportProps> = ({ changeFilter, isOpened }) => {
  const { t } = useTranslation();
  const pathname = getReportSpec(ReportBaseEnum.StationSpinGridReport).stickyKey;
  const { savedFilters: filter, saveFilter } = useStickyFilter<StationSpinGridFilter>(pathname);
  const { isReady: isFilterReady, changeState } = useFilterState();
  const DEFAULT_DATE = { Start: daysMinus(new Date(), 1), End: daysMinus(new Date(), 1) };

  const { hasDeepLinkInfo } = useDeepLink(filter, saveFilter, isFilterReady, {
    onReady: f => changeFilter(f as StationSpinGridFilter),
    filterTransformer: (queryString: Map<string, string>, f) => {
      const newFilter = defaultFilterTransformer(queryString, f);

      newFilter.DateRange = {
        Start: parseISO(queryString.get('EndDate') || ''),
        End: parseISO(queryString.get('EndDate') || ''),
      };

      const songCode = queryString.get('songCode') || '';
      newFilter.SongInfo = { SongCode: songCode, Artist: '', Title: '' };

      return { ...newFilter };
    },
  });

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleFilterChange = (event: FilterDataEvent<object>) => {
    const { value } = event;
    saveFilter({ ...value } as StationSpinGridFilter);
  };

  const handleStationChange = (event: FilterDataEvent<{ Stations?: Station[] }>) => {
    const { value } = event;
    saveFilter({ ...value } as StationSpinGridFilter);
  };

  const getStationsLabel = ({ value }: FilterDataEvent<unknown>): string => {
    const stations = get(value, 'Stations') as Station[] | undefined;
    if (stations && stations.length) {
      return stations.length === 1 ? getStationDisplayName(stations[0]) ?? '' : t('filter.label.various');
    }
    return '';
  };

  const changeSong = (ev: FilterDataEvent<{ SongInfo?: SongInfo }>, property: string) => {
    const value = ev?.value?.SongInfo;
    const transformed = set({}, property, value);
    handleFilterChange({ value: transformed });
  };

  return (
    <FilterBase onSubmit={() => changeFilter(filter as StationSpinGridFilter)} isOpened={isOpened}>
      <SingleSongSelection
        title={t(`filter.title.song`)}
        onChange={ev => changeSong(ev, 'SongInfo')}
        initialValue={filter?.SongInfo}
        changeState={changeState}
        songCode={hasDeepLinkInfo ? filter?.SongInfo?.SongCode : undefined}
        required
      />
      <AccordionFilter title={t('filter.title.stations')} getLabel={getStationsLabel}>
        <StationSelect
          key="StationStationSelect"
          onChange={handleStationChange}
          title={t('filter.title.stations')}
          initialValue={filter?.Stations}
          changeState={changeState}
          callLetters={hasDeepLinkInfo ? filter?.callLetters : undefined}
          required
        />
      </AccordionFilter>
      <InputNumberFilter<MaxWeeks>
        onChange={handleFilterChange}
        name={t('filter.title.NumberOfWeeks')}
        valueToApi="MaxWeeks"
        initialValue={filter?.MaxWeeks || 4}
        minValue={1}
        maxValue={24}
      />

      <CalendarFilter
        onChange={handleFilterChange}
        dateOptionsParameters="dailyLog"
        dateFormat={formatDateTypes.MMddyyyy}
        maxDate={subDays(new Date(), 1)}
        initialValue={hasDeepLinkInfo ? filter?.DateRange : DEFAULT_DATE}
      />
    </FilterBase>
  );
};

export default Filter;
