/* eslint-disable react/jsx-one-expression-per-line */
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import { useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Dehaze from '@material-ui/icons/Dehaze';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import NavigationMenuComponent from 'pages/NavigationMenu/NavigationMenu';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AppSettings from 'contexts/AppSettingsContext';
import { useHistory } from 'react-router-dom';
import LastSiteUpdate from 'components/LastSiteUpdate';
import MenuProfile from '../../components/Menu-profile/index';
import Styles from './styles';

const AppMenu: React.FC = () => {
  const classes = Styles();
  const theme = useTheme();
  const history = useHistory();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { logoURL } = useContext(AppSettings);

  const goToDashboard = () => {
    setOpen(false);
    history.push('/');
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            data-testid="openDrawer"
            aria-label="open drawer"
            onClick={() => setOpen(!open)}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>
          <div className={classes.logoContainer}>
            <img src={logoURL} alt="logo" className={classes.logo} />
            <LastSiteUpdate />
          </div>
          <MenuProfile />
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        data-testid="opened"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton color="primary" data-testid="closeDrawer" onClick={() => setOpen(!open)}>
            {theme.direction === 'ltr' ? <Dehaze /> : <ChevronRightIcon />}
          </IconButton>
        </div>
        <Divider />
        <div className={classes.drawerHeader}>
          <Typography variant="h5" color="primary" onClick={goToDashboard}>
            {t('page.menu.dashboard')}
          </Typography>
        </div>
        <Divider />
        <NavigationMenuComponent onLinkClick={() => setOpen(!open)} />
      </Drawer>
    </div>
  );
};

export default AppMenu;
