import React from 'react';
import { useTranslation } from 'react-i18next';
import { AgGridColumn } from '@ag-grid-community/react';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import NewThisWeekCellRenderer from 'components/DetailGrid/CellRenderers/NewThisWeekCellRenderer';
import MultiColumnsCellRenderer from 'components/DetailGrid/CellRenderers/MultiColumnsCellRenderer/MultiColumnsCellRenderer';
import { format, subDays } from 'date-fns';
import HourlySpinsCellRenderer from 'components/DetailGrid/CellRenderers/HourlySpinsCellRenderer/HourlySpinsCellRenderer';
import { CurrentOneDayFilter, CurrentOneDayReportResult } from './utils/CurrentOneDayReportInterfaces';

export interface CurrentOneDayReportProps {
  filters?: CurrentOneDayFilter;
}

const url = 'real-time-one-day-song-analysis-reports';

const CurrentOneDayReport: React.FC<CurrentOneDayReportProps> = ({ filters }) => {
  const { t } = useTranslation();
  const { settings, setSettings, data: reportData, isLoading, isError } = useRemoteApi<CurrentOneDayReportResult>(url, {
    data: filters,
    method: 'POST',
    enabled: !!filters,
  });

  const { sortModel, setSortModel: onSortChange } = useSortColumn({ sortPath: 'YesterdayPlays', sort: 'asc' });

  return (
    <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
      <ServerSideReportGrid sortModel={sortModel} settings={settings} setSettings={setSettings} filters={filters}>
        <AgGridColumn
          field="NewThisWeek"
          headerName={t('grid.header.NewThisWeek')}
          minWidth={42}
          wrapText
          cellRendererFramework={NewThisWeekCellRenderer}
          headerComponentParams={{ sortModel, onSortChange }}
        />
        <AgGridColumn
          field="StationDisplayName"
          headerName={t(`grid.header.StationName`)}
          minWidth={80}
          pinned
          headerComponentParams={{ sortModel, onSortChange }}
        />
        <AgGridColumn
          headerName={t('grid.header.MarketName')}
          field="MarketName"
          minWidth={100}
          wrapText
          headerComponentParams={{
            sortModel,
            sortPath: 'MarketName',
            onSortChange,
          }}
        />
        <AgGridColumn
          headerName={t('grid.header.MarketRank')}
          field="MarketRank"
          minWidth={100}
          wrapText
          headerComponentParams={{
            sortModel,
            sortPath: 'MarketRank',
            onSortChange,
          }}
        />
        <AgGridColumn
          field="FormatName"
          headerName={t(`grid.header.FormatName`)}
          minWidth={100}
          width={100}
          headerComponentParams={{ sortModel, onSortChange }}
        />
        <AgGridColumn
          field="Plays"
          minWidth={150}
          headerName={t(`grid.header.Plays`)}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            disposition: 'row',
            secondLine: [
              { label: `${format(subDays(new Date(), 1), 'EEE').toUpperCase()}`, sortPath: 'YesterdayPlays' },
              { label: `${format(subDays(new Date(), 2), 'EEE').toUpperCase()}`, sortPath: 'BeforeYesterdayPlays' },
              { label: '+/-', sortPath: 'PlaysMove' },
            ],
          }}
          cellRendererFramework={MultiColumnsCellRenderer}
          cellRendererParams={{
            fields: ['YesterdayPlays', 'BeforeYesterdayPlays', 'PlaysMove'],
          }}
        />
        <AgGridColumn
          field="LastWeekTrends"
          minWidth={140}
          headerName={t(`grid.header.LastWeekTrends`)}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            disposition: 'row',
            secondLine: [
              { label: `Last ${format(subDays(new Date(), 1), 'EEE').toUpperCase()}`, sortPath: 'YesterdayLastWeek' },
              { label: '+/-', sortPath: 'LastWeekMove' },
            ],
          }}
          cellRendererFramework={MultiColumnsCellRenderer}
          cellRendererParams={{
            fields: ['YesterdayLastWeek', 'LastWeekMove'],
          }}
        />
        <AgGridColumn
          field="HourDataItem"
          minWidth={1000}
          headerName={t(`grid.header.SpinGrid24h`)}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            secondLine: [...Array(24).keys()].map(i => ({ label: format(new Date(`1/1/1 ${i}:0`), 'ha') })),
          }}
          cellStyle={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          cellRendererFramework={HourlySpinsCellRenderer}
        />
      </ServerSideReportGrid>
    </GridVisibilityWrapper>
  );
};

export default CurrentOneDayReport;
