import { AgGridColumn } from '@ag-grid-community/react';
import { CellClassParams } from '@ag-grid-enterprise/all-modules';
import SongCellRenderer from 'components/DetailGrid/CellRenderers/SongCellRenderer/SongCellRenderer';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import AppSettings from 'contexts/AppSettingsContext';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import queryString from 'query-string';
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import { ReportCycleIdEnum } from 'types/ReportCycle';
import { formatDate, formatTime } from 'utils/dates';
import { useStyles } from './styles';
import { dailyLogResponseTransformer } from './utils/dailyLogResponseTransformer';
import { DailyLog, ExtendedDalyLogsFilter } from './utils/types';

interface HourByHourGridReport {
  filters?: ExtendedDalyLogsFilter;
}

const url = '/station-daily-log-reports';
const TOP_40_FORMAT = 'H1';
const ALL_US_PANEL = 1;

const HourByHourGrid: FC<HourByHourGridReport> = ({ filters }) => {
  const styles = useStyles();
  const { spotBreakTitlePattern } = useContext(AppSettings);
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({ sortPath: 'TimePlayed', sort: 'asc' });
  const { setSettings, settings, data: reportData, isLoading, isError } = useRemoteApi<ReportResult<DailyLog>>(url, {
    method: 'POST',
    enabled: false,
    transformRequest: ({ StationInfo, EndDate }) => {
      return { StationInfo, EndDate: formatDate(EndDate) };
    },
    transformResponse: result => dailyLogResponseTransformer(result, spotBreakTitlePattern),
  });

  const getTimeCellClass = ({ data }: CellClassParams) => {
    if (data?.GrcCode === 'X') {
      return styles['spot-break-time'];
    }
    return '';
  };

  const getSpotTitleCellClass = ({ data }: CellClassParams) => {
    if (data?.GrcCode === 'X') {
      return styles['spot-break-title'];
    }
    return '';
  };

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const songClickRenderer = (data: any) => {
    const href = getReportSpec(ReportBaseEnum.SongAnalysisReport).path;
    const params = {
      songCode: data.SongInfo?.SongCode,
      'PanelInfo.Id': ALL_US_PANEL,
      'Formats.FormatCode': TOP_40_FORMAT,
      ReportCycle: ReportCycleIdEnum.rolling,
      deepLink: true,
    };
    window.open(`${href}?${queryString.stringify(params)}`);
  };

  return (
    <>
      <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
        <ServerSideReportGrid setSettings={setSettings} settings={settings} filters={filters} sortModel={sortModel}>
          <AgGridColumn
            field="StationDisplayName"
            headerName={t('grid.header.StationName')}
            minWidth={90}
            maxWidth={90}
            wrapText
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="TimePlayed"
            headerName={t('grid.header.TimePlayed')}
            minWidth={90}
            maxWidth={90}
            pinned
            wrapText
            valueFormatter={({ value }) => (value ? formatTime(new Date(value)) : '')}
            headerComponentParams={{ sortModel, onSortChange }}
            cellClass={getTimeCellClass}
          />
          <AgGridColumn
            field="SongInfo"
            headerName={t(`grid.header.Title`)}
            minWidth={240}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              sortPath: 'Title',
              disposition: 'row',
              secondLine: [
                { label: 'grid.header.Artist', sortPath: 'Artist' },
                { label: 'grid.header.ReleaseYear', sortPath: 'ReleaseYear' },
              ],
            }}
            cellRendererFramework={SongCellRenderer}
            cellRendererParams={{
              onClickHandler: songClickRenderer,
            }}
            cellClass={getSpotTitleCellClass}
          />
          <AgGridColumn
            field="FormattedGrcCode"
            headerName={t('grid.header.Grc')}
            minWidth={42}
            maxWidth={90}
            headerComponentParams={{ sortModel, onSortChange }}
          />
        </ServerSideReportGrid>
      </GridVisibilityWrapper>
    </>
  );
};

export default HourByHourGrid;
