import {
  Collapse,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import { noop } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ArtistInfo } from 'types/ArtistInfo';
import { v4 as uuidv4 } from 'uuid';
import { useStyles } from './styles';

interface SearchTableProps {
  searchTerm?: string;
  selectedArtist?: ArtistInfo;
  onChange?: (ev: ArtistInfo) => void;
}

const CONTAINS = 3;

const url = 'artists';
const SearchTable: React.FC<SearchTableProps> = ({ searchTerm, selectedArtist, onChange = noop }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { data: searchedArtists } = useRemoteApi<ArtistInfo[]>(url, {
    method: 'GET',
    enabled: !!searchTerm,
    params: {
      searchType: CONTAINS,
      searchTerm,
    },
  });

  const onRadioChange = (ev: React.ChangeEvent<{ value: unknown }>) => {
    const artistName = ev.target.value as string;
    const artist = searchedArtists?.find(v => v.Name === artistName);
    onChange(artist);
  };

  return (
    <Collapse in={!!searchedArtists?.length} unmountOnExit>
      <RadioGroup value={selectedArtist?.Name} onChange={onRadioChange}>
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader>
            <TableHead className={classes.tableHeader}>
              <TableRow>
                <TableCell />
                <TableCell>
                  <Typography variant="body1">{t('filter.title.Artist')}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{t('filter.title.Year')}</Typography>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              {(searchedArtists ?? []).map((artist: ArtistInfo) => {
                return (
                  <TableRow key={uuidv4()}>
                    <TableCell />
                    <TableCell>
                      <Typography variant="body1">{artist.Name}</Typography>
                    </TableCell>
                    <TableCell>
                      <Radio color="primary" value={`${artist?.Name}`} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </RadioGroup>
    </Collapse>
  );
};

export default SearchTable;
