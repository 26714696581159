/* eslint-disable @typescript-eslint/no-explicit-any */
import DateRange from 'components/Filter/components/CalendarFilter';
import { DatesUrlEnum } from 'components/Filter/components/CalendarFilter/utils';
import PanelFilter from 'components/Filter/components/PanelFilter';
import ReportCycle from 'components/Filter/components/ReportCycle';
import ReportMode from 'components/Filter/components/ReportMode';
import ShowTopSelect from 'components/Filter/components/ShowTopSelect';
import FilterBase from 'components/FilterBase';
import InputNumberFilter from 'components/InputNumber';
import useStickyFilter from 'hooks/useStickyFilter/useStickyFilter';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FilterDataEvent from 'types/FilterDataEvent';
import { PanelCodeEnum } from 'types/Panel';
import { ReportCycleIdEnum, ReportCycleNameEnum } from 'types/ReportCycle';
import { ReportModeIdEnum, ReportModeNameEnum } from 'types/ReportMode';
import { MinSpins } from '../PublishedChartReport/utils/PublishedChartReportTypes';
import { BigPictureFiltersProps } from './utils/BigPictureChartTypes';

interface Props {
  changeFilter: { (filters: any): any };
  isOpened: boolean;
}

const Filters: React.FC<Props> = ({ changeFilter, isOpened }) => {
  const pathname = getReportSpec(ReportBaseEnum.BigPictureChartReport).stickyKey;
  const { savedFilters: filter, saveFilter } = useStickyFilter<any>(pathname);
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleFilterChange = (event: FilterDataEvent<any | BigPictureFiltersProps>) => {
    const { value } = event;
    if (value?.ReportCycle && value?.ReportCycle === ReportCycleIdEnum.building) {
      value.ReportMode = ReportModeIdEnum.bigPictureWithHistory;
    }
    saveFilter({ ...filter, ...value });
  };

  const optionsReportCycle = [
    { Name: t(ReportCycleNameEnum.published), Id: ReportCycleIdEnum.published },
    { Name: t(ReportCycleNameEnum.rolling), Id: ReportCycleIdEnum.rolling },
    { Name: t(ReportCycleNameEnum.building), Id: ReportCycleIdEnum.building },
  ];

  const showBigPictureInfo = () => {
    return (
      filter?.PanelInfo?.PanelCode === PanelCodeEnum.AllCanadianStations ||
      filter?.PanelInfo?.PanelCode === PanelCodeEnum.PublishedCanadaPanel
    );
  };

  const optionsReportMode = [
    { Name: t(ReportModeNameEnum.bigPicture), Id: ReportModeIdEnum.bigPicture },
    { Name: t(ReportModeNameEnum.bigPictureWithHistory), Id: ReportModeIdEnum.bigPictureWithHistory },
  ];
  if (showBigPictureInfo()) {
    optionsReportMode.push({
      Name: t(ReportModeNameEnum.bigPictureWithHistoryFrench),
      Id: ReportModeIdEnum.bigPictureWithHistoryFrench,
    });
  }

  return (
    <FilterBase onSubmit={() => changeFilter(filter)} isOpened={isOpened}>
      <ReportCycle
        options={optionsReportCycle}
        apiPropertyName="ReportCycle"
        onChange={handleFilterChange}
        initialValue={filter?.ReportCycle}
      />
      <PanelFilter
        isPublished={filter?.ReportCycle === ReportCycleIdEnum.published}
        onChange={handleFilterChange}
        initialValue={filter?.PanelInfo}
      />
      <ReportMode
        options={optionsReportMode}
        onChange={handleFilterChange}
        initialValue={filter?.ReportMode}
        disabled={filter?.ReportCycle === ReportCycleIdEnum.building}
      />
      <DateRange
        dateOptionsParameters={filter?.ReportCycle === ReportCycleIdEnum.rolling ? 'week' : 'weeklyDaySunday'}
        getDateFromApi
        url={
          filter?.ReportCycle === ReportCycleIdEnum.rolling
            ? DatesUrlEnum.ROLLING_AVAILABILITY
            : DatesUrlEnum.MOST_RECENT_SATURDAY
        }
        disabled={filter?.ReportCycle === ReportCycleIdEnum.building}
        onChange={handleFilterChange}
        initialValue={filter?.DateRange}
      />
      <InputNumberFilter<MinSpins>
        onChange={handleFilterChange}
        minValue={1}
        name={t('grid.header.minSpinsCuttOff')}
        valueToApi="MinSpins"
        initialValue={filter?.MinSpins || 1}
      />
      <ShowTopSelect apiPropertyName="TopX" onChange={handleFilterChange} initialValue={filter?.TopX} />
    </FilterBase>
  );
};

export default Filters;
