import { AgGridColumn } from '@ag-grid-community/react';
import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import { GridOptions } from '@ag-grid-enterprise/all-modules';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import { UseApiSettings } from 'hooks/useApi/ApiTypes';
import ReportResult from 'services/ReportResult';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import MultiColumnsCellRenderer from 'components/DetailGrid/CellRenderers/MultiColumnsCellRenderer/MultiColumnsCellRenderer';
import DetailGrid from 'components/DetailGrid';
import DayPartsHeaderComponent from 'components/DetailGrid/HeaderComponents/DayPartsHeaderComponent';
import DayPartsCellRenderer from 'components/DetailGrid/CellRenderers/DayPartsCellRenderer';
import { SongAnalysisFilter, StationDetailFilter } from './utils/SongAnalysisTypes';
import {
  SongAnalysisExecutiveVideoSummaryRequestTransformer,
  SongAnalysisExecutiveVideoSummaryTransformer,
} from './utils/SongAnalysisTransformer';
import { SevenDaySongAnalysisExecutiveVideoSummaryResponse } from './utils/SongAnalysisExecutiveVideoSummaryTypes';

export interface ExecutiveVideoSummaryGridProps {
  onChangeFilter: { (value: StationDetailFilter): void };
  filters: SongAnalysisFilter;
}

const url = '/seven-day-song-analysis-executive-video-summary-reports';
const ExecutiveVideoSummaryGrid: React.FC<ExecutiveVideoSummaryGridProps> = ({ filters }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({ sortPath: 'StationDisplayName', sort: 'asc' });
  const { data: reportData, isLoading, settings, setSettings, isError } = useRemoteApi<
    SevenDaySongAnalysisExecutiveVideoSummaryResponse
  >(url, {
    data: filters,
    method: 'POST',
    enabled: false,
    transformRequest: SongAnalysisExecutiveVideoSummaryRequestTransformer,
    transformResponse: SongAnalysisExecutiveVideoSummaryTransformer,
  });

  const TopOptions: GridOptions = {
    alignedGrids: [],
    defaultColDef: {
      editable: true,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 100,
    },
    suppressHorizontalScroll: true,
  };
  const BottomOptions: GridOptions = {
    alignedGrids: [],
    defaultColDef: {
      editable: true,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 100,
    },
  };
  ((TopOptions as GridOptions).alignedGrids ?? []).push(BottomOptions);
  ((BottomOptions as GridOptions).alignedGrids ?? []).push(TopOptions);

  return (
    <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
      <ServerSideReportGrid
        sortModel={sortModel}
        settings={(settings as unknown) as UseApiSettings<ReportResult<unknown>>}
        setSettings={(setSettings as unknown) as Dispatch<UseApiSettings<ReportResult<unknown>>>}
        filters={filters}
        detailProps={{
          gridOptions: TopOptions,
          domLayout: 'autoHeight',
          containerStyle: {
            height: '100%',
          },
        }}
      >
        <AgGridColumn
          field="StationDisplayName"
          headerName={t(`grid.header.StationName`)}
          minWidth={80}
          headerComponentParams={{ sortModel, onSortChange }}
        />
        <AgGridColumn
          field="FormatName"
          headerName={t(`grid.header.FormatName`)}
          minWidth={100}
          headerComponentParams={{ sortModel, onSortChange }}
        />
        <AgGridColumn
          field="SpinsInfo"
          minWidth={150}
          headerName={t(`grid.header.Spins`)}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            sortPath: 'SpinsInfo/ThisWeek',
            secondLine: [
              { label: 'grid.header.LW', sortPath: 'SpinsInfo/LastWeek' },
              { label: 'grid.header.TW', sortPath: 'SpinsInfo/ThisWeek' },
              { label: 'grid.header.Trend', sortPath: 'ImpressionsInfo/Move' },
            ],
          }}
          cellRendererParams={{
            fields: ['SpinsInfo.ThisWeek', 'SpinsInfo.LastWeek', 'SpinsInfo.Move'],
          }}
          cellRendererFramework={MultiColumnsCellRenderer}
        />
        <AgGridColumn
          field="DaypartsInfo"
          headerName={t(`grid.header.DaypartsInfo`)}
          width={230}
          minWidth={230}
          headerComponentFramework={DayPartsHeaderComponent}
          headerComponentParams={{ sortModel, onSortChange }}
          cellRendererFramework={DayPartsCellRenderer}
        />
      </ServerSideReportGrid>
      <DetailGrid
        reportData={[reportData?.TotalItems] ?? []}
        headerHeight={0}
        gridOptions={BottomOptions}
        domLayout="autoHeight"
        rowStyle={{ fontWeight: 'bold' }}
        containerStyle={{
          height: '100%',
        }}
      >
        <AgGridColumn
          field="StationDisplayName"
          headerName={t(`grid.header.StationName`)}
          minWidth={80}
          headerComponentParams={{ sortModel, onSortChange }}
        />
        <AgGridColumn
          field="FormatName"
          headerName={t(`grid.header.FormatName`)}
          minWidth={100}
          headerComponentParams={{ sortModel, onSortChange }}
        />
        <AgGridColumn
          field="SpinsInfo"
          minWidth={150}
          headerName={t(`grid.header.Spins`)}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            sortPath: 'SpinsInfo/ThisWeek',
            secondLine: [
              { label: 'grid.header.LW', sortPath: 'SpinsInfo/LastWeek' },
              { label: 'grid.header.TW', sortPath: 'SpinsInfo/ThisWeek' },
              { label: 'grid.header.Trend', sortPath: 'ImpressionsInfo/Move' },
            ],
          }}
          cellRendererParams={{
            fields: ['SpinsInfo.ThisWeek', 'SpinsInfo.LastWeek', 'SpinsInfo.Move'],
          }}
          cellRendererFramework={MultiColumnsCellRenderer}
        />
        <AgGridColumn
          field="DaypartsInfo"
          headerName={t(`grid.header.DaypartsInfo`)}
          width={230}
          minWidth={230}
          headerComponentFramework={DayPartsHeaderComponent}
          headerComponentParams={{ sortModel, onSortChange }}
          cellRendererFramework={DayPartsCellRenderer}
        />
      </DetailGrid>
    </GridVisibilityWrapper>
  );
};

export default ExecutiveVideoSummaryGrid;
