import React, { useContext, useEffect, useState } from 'react';
import { AxiosError } from 'axios';
import AppSettings from 'contexts/AppSettingsContext';
import { SongSearchMode, StationSelectionType } from 'types/Enums';
import { ClassicSong, ClassicIdInfo, ClassicStation, ClassicFormat, ClassicPanel } from '../ClassicTypes';
import { useTranslation } from 'react-i18next';
import { QueryClient, useQuery } from 'react-query';
import AppProblem from 'types/AppProblem';
import { useAppApi } from 'services/api';
import { v4 as uuidv4 } from 'uuid';
import ApiStatus from '../ApiStatus';
import {
  CurrentDaySongsReport,
  CurrentDaySongsReportRow,
} from 'pages/Reports/DailyCurrent/Songs/CurrentSongsReport/types/RealTimeSongAnalysis';
import ClassicViewHeader from '../ClassicViewHeader/ClassicViewHeader';
import { format } from 'date-fns';
import { FormatSearchRequest } from 'types/FormatSearchRequest';
import { PanelLocalData } from 'types/Panel';
import { ReportTypeEnum } from 'types/ReportType';
import { useFormatsSearch } from 'hooks/formatHooks/formatHooks';
import { useSearchBySongCodes } from 'hooks/songHooks/songHooks';
import { SongInfo } from 'types/SongInfo';
import { StationPorfolioDescription } from '../ClassicViewDescriptions/StationPorfolioDescription';
import { StationPorfolioGroupDescription } from '../ClassicViewDescriptions/StationPorfolioGroupDescription';
import { CountryFormatCodeEnum } from 'types/Format';
import { formatDateTypes } from 'utils/dates';

type ClassicViewCurrentDaySongsQuery = {
  PanelInfo: ClassicPanel;
  SongInfo: ClassicSong;
  SearchMode: number;
  FormatInfo?: ClassicFormat;
  StationSelectionType?: number;
  Stations?: ClassicStation[];
  StationPortfolio?: ClassicIdInfo;
  StationPortfolioGroup?: ClassicIdInfo;
  NumDays: number;
  LastRefresh: Date;
};

type ClassicViewCurrentDaySongsTableProps = {
  reportQuery: ClassicViewCurrentDaySongsQuery;
};

const useGetReportData = (reportQuery: ClassicViewCurrentDaySongsQuery) => {
  const queryClient = new QueryClient();
  const api = useAppApi();
  const url = 'real-time-song-analysis-reports';
  const cacheKey = 'real-time-song-analysis-reports';
  return useQuery<CurrentDaySongsReport, AxiosError<AppProblem>>(cacheKey, () => {
    return api.post(`${url}`, reportQuery).then(resp => {
      queryClient.invalidateQueries(cacheKey);
      return resp.data;
    });
  });
};

const ClassicViewCurrentDaySongsTable = (args: ClassicViewCurrentDaySongsTableProps) => {
  const { t } = useTranslation();
  const { data, status, isSuccess } = useGetReportData(args.reportQuery);
  if (!isSuccess) return <ApiStatus status={status} />;

  const hasPortfolio = args.reportQuery.StationPortfolio?.Id || args.reportQuery.StationPortfolioGroup?.Id;
  const showPoints = args.reportQuery.FormatInfo?.FormatCode === CountryFormatCodeEnum.Country;
  return (
    <table className={`classic-table`}>
      <thead>
        <tr>
          {hasPortfolio && (
            <th colSpan={1} rowSpan={2}>
              {t(`grid.header.StationPortfolio`)}
            </th>
          )}
          <th colSpan={1} rowSpan={2}>
            {t(`grid.header.NewThisWeek`)}
          </th>
          <th colSpan={1} rowSpan={2}>
            {t(`grid.header.StationName`)}
          </th>
          <th colSpan={1} rowSpan={2}>
            {t(`grid.header.MarketName`)}
          </th>
          <th colSpan={1} rowSpan={2}>
            {t(`grid.header.MarketRank`)}
          </th>
          <th colSpan={1} rowSpan={2}>
            {t(`grid.header.FormatName`)}
          </th>
          <th colSpan={1} rowSpan={2}>
            {t(`grid.header.HasPlayedToday`)}
          </th>

          <th colSpan={3} rowSpan={1}>
            {t(`grid.header.Spins`)}
          </th>
          <th colSpan={5} rowSpan={1}>
            {t(`grid.header.DaypartsInfo`)}
          </th>

          {showPoints && (
            <th colSpan={3} rowSpan={1}>
              {t(`grid.header.Points`)}
            </th>
          )}

          <th colSpan={1} rowSpan={2}>
            {t(`grid.header.FirstPlayedDate`)}
          </th>
          <th colSpan={1} rowSpan={2}>
            {t(`grid.header.SpinsToDate`)}
          </th>
        </tr>
        <tr>
          <th>{t(`grid.header.TW`)}</th>
          <th>{t(`grid.header.LW`)}</th>
          <th>{t(`grid.header.Move`)}</th>

          <th>{t(`grid.header.Overnight`)}</th>
          <th>{t(`grid.header.AmDrive`)}</th>
          <th>{t(`grid.header.Midday`)}</th>
          <th>{t(`grid.header.PmDrive`)}</th>
          <th>{t(`grid.header.Evening`)}</th>

          {showPoints && <th>{t(`grid.header.TW`)}</th>}
          {showPoints && <th>{t(`grid.header.LW`)}</th>}
          {showPoints && <th>{t(`grid.header.Move`)}</th>}
        </tr>
      </thead>
      <tbody>
        {data &&
          data.Items.map((item: CurrentDaySongsReportRow) => (
            <tr key={uuidv4()}>
              {hasPortfolio && <td>{item.StationPortfolioName}</td>}
              <td>{item.NewThisWeek}</td>
              <td>{item.StationDisplayName}</td>
              <td>{item.MarketName}</td>
              <td>{item.MarketRank}</td>
              <td>{item.FormatName}</td>
              <td>{item.HasPlayedToday}</td>

              <td>{item.SpinsInfo.ThisWeek}</td>
              <td>{item.SpinsInfo.LastWeek}</td>
              <td>{item.SpinsInfo.Move}</td>

              <td>{item.DaypartsInfo.Overnight}</td>
              <td>{item.DaypartsInfo.AmDrive}</td>
              <td>{item.DaypartsInfo.Midday}</td>
              <td>{item.DaypartsInfo.PmDrive}</td>
              <td>{item.DaypartsInfo.Evening}</td>

              {showPoints && <td>{item.PointsInfo.ThisWeek}</td>}
              {showPoints && <td>{item.PointsInfo.LastWeek}</td>}
              {showPoints && <td>{item.PointsInfo.Move}</td>}

              <td>{item.FirstPlayedDate}</td>
              <td>{item.SpinsToDate}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

type ReportDescriptionProps = {
  reportQuery: ClassicViewCurrentDaySongsQuery;
};

const formatSong = (songInfo: SongInfo | undefined): string => {
  if (songInfo) {
    return `${songInfo?.Artist} - ${songInfo?.Title}`;
  }
  return '';
};

const formatStationList = (stations: ClassicStation[] | undefined) => {
  if (stations && stations.length > 0) {
    const list = stations.map(s => s?.Name).join(',');
    return list;
  }
  return '';
};

const ClassicViewReportDescriptions = (args: ReportDescriptionProps) => {
  // panel info
  const panelCode = args.reportQuery.PanelInfo?.PanelCode || 'R';
  const panelInfo = PanelLocalData.find(panelCode);

  // format info
  const formatSearchRequest: FormatSearchRequest = {
    Year: 0,
    OwnerId: 0,
    ReportType: ReportTypeEnum.CurrentChart,
    PanelCode: panelCode,
  };
  const { data: formats } = useFormatsSearch(formatSearchRequest);
  const formatCode = (args.reportQuery.FormatInfo?.FormatCode || '').trim();
  const formatInfo = formats?.find(f => f.FormatCode?.trim() === formatCode);

  // song info
  const songCode = (args.reportQuery.SongInfo?.SongCode || '').trim();
  const { data: songs } = useSearchBySongCodes([songCode]);
  const songInfo = songs?.find(f => f.SongCode?.trim() === songCode);

  // station searches
  const hasStations =
    args.reportQuery.StationSelectionType === StationSelectionType.StationList &&
    args.reportQuery.Stations &&
    args.reportQuery.Stations?.length > 0;
  const portfolioId = args.reportQuery.StationPortfolio?.Id || 0;
  const portfolioGroupId = args.reportQuery.StationPortfolioGroup?.Id || 0;
  const isSearchByStation = args.reportQuery.SearchMode === SongSearchMode.ByStation;
  const isSearchByFormat = args.reportQuery.SearchMode === SongSearchMode.ByFormat;

  return (
    <>
      <div>{`Song: ${formatSong(songInfo)}`}</div>
      <div>{`Panel: ${panelInfo?.Name}`}</div>
      <div>{`Past Days: ${args.reportQuery.NumDays}`}</div>
      <div>{isSearchByFormat && `Format: ${formatInfo?.Name || 'All'}`}</div>
      <div>{hasStations && `Stations: ${formatStationList(args.reportQuery.Stations)}`}</div>
      <div>{isSearchByStation && portfolioId !== 0 && <StationPorfolioDescription portfolioId={portfolioId} />}</div>
      <div>
        {isSearchByStation && portfolioGroupId !== 0 && (
          <StationPorfolioGroupDescription portfolioGroupId={portfolioGroupId} />
        )}
      </div>
    </>
  );
};

const ClassicViewCurrentDaySongs: React.FC = () => {
  const [asOfNowValue, setAsOfNowValue] = useState(new Date());
  const { reportRefreshTimeout } = useContext(AppSettings);

  useEffect(() => {
    const interval = setInterval(() => {
      setAsOfNowValue(new Date());
    }, reportRefreshTimeout);

    return () => {
      clearInterval(interval);
    };
  }, [reportRefreshTimeout]);

  const sp = new URLSearchParams(window.location.search);

  const panelCodeParam = sp.get('panelCode');
  if (!panelCodeParam) return <div>No panel info provided</div>;
  const panelInfo: ClassicPanel = { PanelCode: panelCodeParam };

  const numDaysParam = sp.get('numDays');
  if (!numDaysParam) return <div>Number of days missing</div>;
  const numDays: number = parseInt(numDaysParam, 10);

  const songCodeParam = sp.get('songCode');
  if (!songCodeParam) return <div>No songs info provided</div>;
  const songInfo: ClassicSong = { SongCode: songCodeParam };

  const searchModeParam = sp.get('searchMode');
  if (!searchModeParam) return <div>No search mode info provided</div>;
  const searchModeType = parseInt(searchModeParam, 10);

  let formatInfo: ClassicFormat = { FormatCode: 'XX' };
  let stations: ClassicStation[] = [];
  let stationPortfolio: ClassicIdInfo;
  let stationPortfolioGroup: ClassicIdInfo;
  let stationSelectionType: number = StationSelectionType.None;
  if (searchModeType === SongSearchMode.ByFormat) {
    const formatCodeParam = sp.get('formatCode');
    if (!formatCodeParam) return <div>No format code info provided</div>;
    formatInfo = { FormatCode: formatCodeParam };
  } else {
    const stationSelectionTypeParams = sp.get('ssType');
    if (!stationSelectionTypeParams) return <div>No station selection type info provided</div>;
    stationSelectionType = parseInt(stationSelectionTypeParams, 10);

    if (stationSelectionType === StationSelectionType.StationList) {
      const stationsParams = sp.get('stations');
      if (!stationsParams) return <div>No stations provided</div>;
      stations = stationsParams.split(',').map(s => {
        return { Name: s };
      });
    } else if (stationSelectionType === StationSelectionType.StationPortfolio) {
      const stationPortfolioParam = sp.get('spId');
      if (!stationPortfolioParam) return <div>No station portfolio info provided</div>;
      stationPortfolio = { Id: parseInt(stationPortfolioParam, 10) };
    } else if (stationSelectionType === StationSelectionType.StationPortfolioGroup) {
      const stationPortfolioGroupParam = sp.get('spgId');
      if (!stationPortfolioGroupParam) return <div>No station portfolio group info provided</div>;
      stationPortfolioGroup = { Id: parseInt(stationPortfolioGroupParam, 10) };
    }
  }

  const query: ClassicViewCurrentDaySongsQuery = {
    PanelInfo: panelInfo,
    NumDays: numDays,
    SongInfo: songInfo,
    SearchMode: searchModeType,
    FormatInfo: formatInfo,
    StationSelectionType: stationSelectionType,
    Stations: stations,
    StationPortfolio: stationPortfolio,
    StationPortfolioGroup: stationPortfolioGroup,
    LastRefresh: asOfNowValue,
  };

  return (
    <>
      <ClassicViewHeader />
      <div className={`classic-view-report-header`}>
        <h1>Realtime Songs</h1>
        <div>{`As of: ${format(asOfNowValue, formatDateTypes.MMddyyyyhhmma)}`}</div>
      </div>
      <div className={`classic-view-report-descriptions`}>
        <ClassicViewReportDescriptions reportQuery={query} />
      </div>
      <div className={`classic-view-report-data`}>
        <ClassicViewCurrentDaySongsTable reportQuery={query} />
      </div>
    </>
  );
};

export default ClassicViewCurrentDaySongs;
