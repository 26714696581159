/* eslint-disable no-shadow */
export interface GrcInfo {
  Id?: string | undefined;
  GrcCode: string;
  Name: string;
}

export enum GrcInfoEnum {
  All = 'GRC',
  CurrentsRecurrents = 'CR',
  CurrentsOnly = 'C',
  RecurrentsOnly = 'R',
  Gold = 'G',
}
