import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      '& p.success': {
        color: theme.palette.success.main,
      },
      '& p.error': {
        color: theme.palette.error.main,
      },
      '& p.textPrimary': {
        color: theme.palette.text.primary,
      },
      '& p.textSecondary': {
        color: theme.palette.text.secondary,
      },
    },
  });
});
