import { GrcInfo } from 'types/GrcInfo';

/**
 * Resolves operations for the GRC info
 */
class GrcInfoService {
  private grcInfos: GrcInfo[] = [
    {
      Id: 'CR',
      GrcCode: 'CR',
      Name: 'Currents and Recurrents',
    },
    {
      Id: 'C',
      GrcCode: 'C',
      Name: 'Currents Only',
    },
    {
      Id: 'R',
      GrcCode: 'R',
      Name: 'Recurrents Only',
    },
    {
      Id: 'G',
      GrcCode: 'G',
      Name: 'Gold',
    },
  ];
  
  public getGrcInfoSync(): GrcInfo[] {
    return [...this.grcInfos];
  }

  public getGrcInfoAllSync(): GrcInfo[] {
    return [...[{ Id: 'GRC', GrcCode: 'GRC', Name: 'Show All' }], ...this.grcInfos];
  }
}

export default GrcInfoService;
