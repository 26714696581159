import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { IInnerTableGroup } from './inner-table-group';

interface InnerTableHeader {
  content: string | JSX.Element;
  colSpan: number;
  rowSpan: number;
}

export type InnerTableGroupsProp = {
  data: IInnerTableGroup[];
};

const InnerTableHeaders: React.FC<InnerTableGroupsProp> = ({ data }: InnerTableGroupsProp) => {
  const getMaxDepth = (tableGroups: IInnerTableGroup[], depth = 1): number => {
    const withChildren = tableGroups
      .flatMap(v => v.children)
      .filter(v => typeof v === 'object' && 'children' in v)
      .map(v => v as IInnerTableGroup);

    if (withChildren.length < 1) {
      return depth;
    }

    return getMaxDepth(withChildren, depth + 1);
  };

  const getColspan = (group: IInnerTableGroup): number => {
    const withChildren = group.children.filter(v => typeof v === 'object' && 'children' in v) as IInnerTableGroup[];

    if (withChildren.length < 1) {
      return 1;
    }

    return withChildren.map(v => getColspan(v)).reduce((acc, v) => acc + v, 0);
  };

  const mapInnerTableGroup = (group: IInnerTableGroup, maxDepth: number, layer: number): InnerTableHeader => {
    return {
      content: group.title,
      colSpan: getColspan(group),
      rowSpan: maxDepth - (getMaxDepth([group]) + layer) + 1,
    } as InnerTableHeader;
  };

  const splitHeaders = (
    tableGroups: IInnerTableGroup[],
    maxDepth: number,
    layer = 0,
    output: InnerTableHeader[][] = [],
  ): InnerTableHeader[][] => {
    if (tableGroups.length < 1) {
      return output;
    }

    output.push(tableGroups.map(v => mapInnerTableGroup(v, maxDepth, layer)));
    const children = tableGroups
      .flatMap(v => v.children)
      .filter(v => typeof v === 'object' && 'children' in v) as IInnerTableGroup[];

    splitHeaders(children, maxDepth, layer + 1, output);
    return output;
  };

  return (
    <thead>
      {splitHeaders(data, getMaxDepth(data)).map(row => {
        return (
          <tr key={uuidv4()}>
            {row
              .filter(r => r.content)
              .map(column => {
                return (
                  <th key={uuidv4()} colSpan={column.colSpan} rowSpan={column.rowSpan}>
                    {column.content}
                  </th>
                );
              })}
          </tr>
        );
      })}
    </thead>
  );
};

export default InnerTableHeaders;
