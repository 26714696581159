import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => {
  return createStyles({
    root: {},
    secondLine: {
      '& span': {
        display: 'inline-block',
        textTransform: 'uppercase',
        '&.column': {
          padding: '0 3px 0 3px',
          marginRight: '5px',
        },
      },
      '& span:last-child': {
        borderRight: 'none',
      },
    },
  });
});
