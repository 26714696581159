import { LicenseManager } from '@ag-grid-enterprise/all-modules';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { QueryClient, QueryClientProvider } from 'react-query';

LicenseManager.setLicenseKey(
  'iHeartMedia_Management_Services_Inc_Multi_Multiple_Applications_5_Devs__18_December_2020_[v2]_MTYwODI0OTYwMDAwMA==ad1f32bf632f703ec520979e4fbec4f5',
);

const queryClient = new QueryClient();
ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <App />
  </QueryClientProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
