import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& .MuiListItem-root': {
        backgroundColor: theme.palette.background.paper,
      },
      '& span': {
        fontSize: '1.6em',
      },
    },
    nested: {
      '& span': {
        fontSize: '1em',
      },
      padding: theme.spacing(0.2),
    },
    menuLink: {
      textDecoration: 'none',
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.primary.dark,
      },
    },
  }),
);
