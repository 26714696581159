import { Column } from '@ag-grid-enterprise/all-modules';
import React from 'react';
import { v4 } from 'uuid';
import InnerTable from '..';
import defaultTheme from '../../../themes';
import { IInnerTableGroup } from '../inner-table-group';

export interface LastMonthGroupData {
  LastMonthInfo: {
    Position: number;
    Move: number;
  };
}

interface LastMonthGroupProps {
  data: LastMonthGroupData;
  invisibleColumns: Array<Column>;
}

const LastMonthInnerTable: React.FC<LastMonthGroupProps> = ({ data, invisibleColumns }: LastMonthGroupProps) => {
  const getColor = (baseValue: number, actualValue: number) => {
    if (baseValue > actualValue) return defaultTheme.palette.error.main;
    if (baseValue < actualValue) return defaultTheme.palette.success.main;
    return defaultTheme.palette.text.primary;
  };

  const createSpan = (baseValue: number, actualValue: number) => {
    return <span style={{ color: getColor(baseValue, actualValue) }}>{actualValue}</span>;
  };

  const composeHeader = () => {
    return invisibleColumns[0].getColDef().headerName || '';
  };

  const innerTableData: IInnerTableGroup[] = [
    {
      title: composeHeader(),
      children: [
        {
          title: '',
          children: ['Spins', '+/-'],
        },
        {
          title: '',
          children: [
            createSpan(data.LastMonthInfo.Position, data.LastMonthInfo.Position),
            createSpan(0, data.LastMonthInfo.Move),
          ],
        },
      ],
    },
  ];

  return <InnerTable key={v4()} data={innerTableData} />;
};

export default LastMonthInnerTable;
