/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable no-nested-ternary */
import { Column, ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import React from 'react';
import { Weekly } from 'types/Weekly';
import { ContainerGrid, ItemGrid } from './globalStylesCellRenderers';

interface WeekCellRendererProps {
  value?: {
    Move: number;
    Week1: number;
    Week2: number;
    Week3: number;
    Week4: number;
  };
}

interface Props extends WeekCellRendererProps {
  column?: Column;
}

const normalizeData = (data: Weekly) => {
  const arrayItens = Object.values(data);
  const indexs = [1, 0, 2, 3, 4];
  return indexs.map(item => {
    return arrayItens[item];
  });
};

const WeekCellRenderer: React.FC<Props> = ({ value, column }: Props) => {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const valueGetter = (v: any) => {
    if (column) {
      const colDef = column.getColDef();
      const { valueFormatter } = colDef;

      if (valueFormatter instanceof Function) {
        return valueFormatter({ ...colDef, value: v } as ValueFormatterParams);
      }
    }
    return v;
  };

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const formatValue = (v: any) => {
    const result = valueGetter(v);
    const output = result === 0 ? '--' : result;
    return output;
  };

  const renderCell = (daily: number, idx: number) => {
    return (
      <ItemGrid width="20%" key={idx}>
        {formatValue(daily)}
      </ItemGrid>
    );
  };

  const getContent = () => {
    if (!value) {
      return <></>;
    }
    const data = normalizeData(value);
    return <ContainerGrid aria-label="Week">{data.map((item, idx) => renderCell(item, idx))}</ContainerGrid>;
  };

  return getContent();
};

WeekCellRenderer.defaultProps = { value: undefined };
export default WeekCellRenderer;
