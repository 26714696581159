import { STATION_BY_FORMATS, STATION_BY_MULTIPLE_FORMATS, STATION_PORTFOLIO, STATION_SELECTION } from 'utils/Constants';

export interface StationSearchMode {
  SearchMode: number;
  StationSelectionType: number;
}

export function searchModeResolver(stationGroup: string): StationSearchMode {
  switch (stationGroup) {
    case STATION_BY_FORMATS:
      return {
        SearchMode: 1,
        StationSelectionType: 0,
      };
    case STATION_BY_MULTIPLE_FORMATS:
      return {
        SearchMode: 1,
        StationSelectionType: 0,
      };
    case STATION_SELECTION:
      return {
        SearchMode: 2,
        StationSelectionType: 2,
      };
    case STATION_PORTFOLIO:
      return {
        SearchMode: 2,
        StationSelectionType: 1,
      };
    default:
      // STATION_PORFOLIO_GROUP
      return {
        SearchMode: 2,
        StationSelectionType: 3,
      };
  }
}
