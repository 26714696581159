import { createMuiTheme } from '@material-ui/core';
import './styles.css';

const font = "'Gill Sans', sans-serif";

const defaultTheme = createMuiTheme({
  typography: {
    fontFamily: font,
    body1: {
      fontWeight: 300,
    },
    h5: {
      fontWeight: 300,
    },
  },
  palette: {
    primary: {
      main: '#197bba',
    },
    secondary: {
      main: '#000000',
    },
    background: {
      default: '#f5f5f5',
    },
  },
});

export default defaultTheme;
