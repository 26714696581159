import { Tab, Tabs, makeStyles, AppBar } from '@material-ui/core';
import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface TabProps {
  children: JSX.Element[];
  tabValue: number;
  tabs: Array<any>;
  onChangeTab: { (tabValue: number): void };
}

const useStyles = makeStyles({
  root: {
    flex: 1,
    overflowX: 'scroll',
    selected: {
      borderNottom: 'none',
    },
  },
  tabs: {
    display: 'flex',
    overflowX: 'scroll',
  },
});

const TabsGrid: React.FC<TabProps> = ({ children, onChangeTab, tabValue, tabs }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    onChangeTab(newValue);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs value={tabValue} onChange={handleChange} indicatorColor="primary" data-testid="tab-component">
          {tabs.map(tab => (
            <Tab key={tab.value} label={t(tab.label)} />
          ))}
        </Tabs>
        {children?.length && children.map((child: JSX.Element) => child)}
      </AppBar>
    </div>
  );
};

export default TabsGrid;
