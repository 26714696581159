import { AgGridColumn } from '@ag-grid-community/react';
import BooleanCanadaCheckMarkCellRenderer from 'components/DetailGrid/CellRenderers/BooleanCanadaCheckMarkCellRenderer';
import DayPartsCellRenderer from 'components/DetailGrid/CellRenderers/DayPartsCellRenderer';
import MultiColumnsCellRenderer from 'components/DetailGrid/CellRenderers/MultiColumnsCellRenderer/MultiColumnsCellRenderer';
import NewThisWeekCellRenderer from 'components/DetailGrid/CellRenderers/NewThisWeekCellRenderer';
import RankInfoCellRenderer from 'components/DetailGrid/CellRenderers/RankInfoCellRenderer/RankInfoCellRenderer';
import SongCellRenderer from 'components/DetailGrid/CellRenderers/SongCellRenderer/SongCellRenderer';
import ThisLastMoveCellRenderer from 'components/DetailGrid/CellRenderers/ThisLastMoveCellRenderer';
import DayPartsHeaderComponent from 'components/DetailGrid/HeaderComponents/DayPartsHeaderComponent';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import ThisLastMoveHeaderComponent from 'components/DetailGrid/HeaderComponents/ThisLastMoveHeaderComponent';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import { UseApiSettings } from 'hooks/useApi/ApiTypes';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import queryString from 'query-string';
import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import { GrcInfoEnum } from 'types/GrcInfo';
import { PanelCodeEnum } from 'types/Panel';
import { v4 as uuidv4 } from 'uuid';
import { ReportCycleIdEnum } from 'types/ReportCycle';
import { daysMinus, formatDate, formatDateTypes } from 'utils/dates';
import { StationPlaylistsBuildingTransformer } from './utils/StationPlaylistsTransformer';
import { StationPlaylistsFilter } from './utils/StationPlaylistsTypes';
import { StationPlaylistBuildingReport } from './utils/StationPlaylistReport';
import { TransformedStationPlaylistSevenDayDataItem } from './utils/StationPlaylistItem';

interface StationPlaylistProps {
  filters: StationPlaylistsFilter | undefined;
}

const urls = new Map([
  [ReportCycleIdEnum.published, 'stations-playlist-published-reports'],
  [ReportCycleIdEnum.rolling, 'stations-playlist-seven-day-reports'],
  [ReportCycleIdEnum.building, 'stations-playlist-building-reports'],
  [ReportCycleIdEnum.FiveDay, 'stations-playlist-five-day-reports'],
]);
const COUNTRY_FORMAT_ID = 1003;

const StationPlaylistsBuildingGrid: React.FC<StationPlaylistProps> = ({ filters }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({
    sortPath: 'RankThisWeek',
    sort: 'asc',
  });

  const cycle = filters?.ReportCycle ?? ReportCycleIdEnum.published;
  const url = urls.get(cycle) || '';

  const { setSettings, settings, data: reportData, isLoading, isError } = useRemoteApi<StationPlaylistBuildingReport>(
    url,
    {
      data: filters,
      method: 'POST',
      enabled: false,
      transformResponse: StationPlaylistsBuildingTransformer,
    },
  );

  const songClickHandler = (
    data: TransformedStationPlaylistSevenDayDataItem,
    reportQuery: StationPlaylistsFilter | undefined,
  ) => {
    const href = getReportSpec(ReportBaseEnum.SongAnalysisReport).path;
    const params = {
      ReportCycle: reportQuery?.ReportCycle,
      songCode: data.SongInfo?.SongCode,
      'Formats.FormatCode': [reportQuery?.StationInfo?.FormatInfo?.FormatCode],
      'PanelInfo.Id': reportQuery?.PanelInfo?.Id,
      NumDays: reportQuery?.NumDays,
      deepLink: true,
    };
    window.open(`${href}?${queryString.stringify(params)}`);
  };

  const spinsThisWeekClickHandler = (
    data: TransformedStationPlaylistSevenDayDataItem,
    reportQuery: StationPlaylistsFilter | undefined,
  ) => {
    const href = getReportSpec(ReportBaseEnum.StationSpinGridReport).path;
    const dt = daysMinus(new Date(), 1);
    const endDate = formatDate(dt, formatDateTypes.yyyyMMdd);
    const params = {
      songCode: data.SongInfo?.SongCode,
      callLetters: reportQuery?.StationInfo?.Name,
      EndDate: endDate,
      MaxWeeks: 4,
      deepLink: true,
    };
    window.open(`${href}?${queryString.stringify(params)}`);
  };

  const hasCanadianPanel = (panelCode: string | undefined) => {
    return panelCode === PanelCodeEnum.AllCanadianStations || panelCode === PanelCodeEnum.PublishedCanadaPanel;
  };

  const renderCommonColumns = () => {
    return [
      <AgGridColumn
        field="NewThisWeek"
        key={uuidv4()}
        headerName={t('grid.header.NewThisWeek')}
        minWidth={42}
        width={42}
        wrapText
        cellRendererFramework={NewThisWeekCellRenderer}
        headerComponentParams={{ sortModel, onSortChange }}
        hide={filters?.GrcInfo?.GrcCode === GrcInfoEnum.Gold}
      />,
      <AgGridColumn
        headerName={t('grid.header.Rank')}
        key={uuidv4()}
        minWidth={60}
        width={60}
        field="Rank"
        headerComponentParams={{
          sortModel,
          onSortChange,
          disposition: 'row',
          secondLine: [
            { label: 'grid.header.TW', sortPath: 'RankThisWeek' },
            { label: 'grid.header.LW', sortPath: 'RankLastWeek' },
          ],
        }}
        cellRendererParams={{
          fields: ['Rank.ThisWeek', 'Rank.LastWeek'],
        }}
        cellRendererFramework={RankInfoCellRenderer}
        headerComponentFramework={MultiColumnsHeaderComponent}
      />,
      <AgGridColumn
        field="SongInfo"
        key={uuidv4()}
        pinned
        headerName={t(`grid.header.Title`)}
        minWidth={180}
        headerComponentFramework={MultiColumnsHeaderComponent}
        headerComponentParams={{
          sortModel,
          sortPath: 'Title',
          onSortChange,
          disposition: 'row',
          secondLine: [
            { sortPath: 'Artist', label: 'grid.header.Artist' },
            { sortPath: 'Label', label: 'grid.header.Label' },
          ],
        }}
        cellRendererFramework={SongCellRenderer}
        cellRendererParams={{
          reportQuery: filters,
          /* eslint-disable @typescript-eslint/no-explicit-any */
          onClickHandler: (data: any, reportQuery: any) => songClickHandler(data, reportQuery),
        }}
      />,
      <AgGridColumn
        field="HasCanadianContent"
        key={uuidv4()}
        headerName="CanCon"
        minWidth={70}
        cellRendererFramework={BooleanCanadaCheckMarkCellRenderer}
        headerComponentParams={{ sortModel, onSortChange, sortPath: 'HasCanadianContent' }}
        hide={!hasCanadianPanel(filters?.PanelInfo?.PanelCode)}
      />,
      <AgGridColumn
        field="ReleaseYear"
        key={uuidv4()}
        minWidth={80}
        width={80}
        headerName={t(`grid.header.Year`)}
        headerComponentParams={{ sortModel, onSortChange }}
        hide={!(filters?.GrcInfo?.GrcCode === GrcInfoEnum.All || filters?.GrcInfo?.GrcCode === GrcInfoEnum.Gold)}
      />,
      <AgGridColumn
        field="PointsInfo"
        key={uuidv4()}
        minWidth={95}
        headerName={t(`grid.header.PointsInfoTW`)}
        cellRendererFramework={ThisLastMoveCellRenderer}
        headerComponentFramework={ThisLastMoveHeaderComponent}
        headerComponentParams={{ sortModel, onSortChange }}
        hide={!(filters?.StationInfo?.FormatInfo?.Id === COUNTRY_FORMAT_ID)}
      />,
      <AgGridColumn
        field="SpinsInfo"
        key={uuidv4()}
        minWidth={80}
        width={80}
        headerName={t(`grid.header.SpinsInfoTotal`)}
        headerComponentFramework={MultiColumnsHeaderComponent}
        headerComponentParams={{
          sortModel,
          onSortChange,
          disposition: 'row',
          secondLine: [
            { sortPath: 'SpinsInfo/ThisWeek', label: ['grid.header.TW'] },
            { sortPath: 'SpinsInfo/LastWeek', label: ['grid.header.LW'] },
            { sortPath: 'SpinsInfo/Move', label: ['grid.header.Move'] },
          ],
        }}
        cellRendererFramework={ThisLastMoveCellRenderer}
        cellRendererParams={{
          reportQuery: filters,
          thisWeekClickHandler: spinsThisWeekClickHandler,
        }}
      />,
      <AgGridColumn
        field="DaypartsInfo"
        key={uuidv4()}
        headerName={t(`grid.header.DaypartsInfo`)}
        width={230}
        minWidth={230}
        headerComponentFramework={DayPartsHeaderComponent}
        headerComponentParams={{ sortModel, onSortChange }}
        cellRendererFramework={DayPartsCellRenderer}
      />,
    ];
  };

  // only building
  const renderBuildingColumns = () => {
    const result = renderCommonColumns();

    // day by day spins
    const secondLine = (reportData?.DateData ?? []).map((item, idx: number) => {
      return {
        label: [`-${idx + 1}`],
        sortPath: `DayByDaySpins/${item.NumDay.replace('0', '')}/ThisWeek`,
      };
    });
    const cellRenderers = (reportData?.DateData ?? []).map(item => {
      return `DayByDaySpins.${item.NumDay.replace('0', '')}.ThisWeek`;
    });
    result.push(
      <AgGridColumn
        field="DayByDaySpins"
        colId="DayByDaySpinsBuilding"
        key={uuidv4()}
        minWidth={200}
        width={200}
        headerName={t(`grid.header.DayByDaySpins`)}
        headerComponentFramework={MultiColumnsHeaderComponent}
        headerComponentParams={{
          sortModel,
          onSortChange,
          secondLine,
        }}
        cellRendererParams={{
          fields: cellRenderers,
        }}
        cellRendererFramework={MultiColumnsCellRenderer}
      />,
    );

    // historical info
    result.push(
      <AgGridColumn
        field="HistoricalInfoCustom"
        key={uuidv4()}
        minWidth={200}
        width={200}
        headerName="History"
        headerComponentFramework={MultiColumnsHeaderComponent}
        headerComponentParams={{
          sortModel,
          onSortChange,
          disposition: 'row',
          secondLine: [
            { label: ['grid.header.firstPlayed'], sortPath: 'FirstPlayedDate' },
            { label: ['grid.header.SpinsToDate'], sortPath: 'SpinsToDate' },
          ],
        }}
        cellRendererParams={{
          fields: ['HistoricalInfo.FirstPlayedDate', 'HistoricalInfo.SpinsToDate'],
        }}
        cellRendererFramework={MultiColumnsCellRenderer}
      />,
    );

    return result;
  };

  const getReportMetadata = () => {
    const metadata = {
      DateData: reportData?.DateData,
    };
    return metadata;
  };

  return (
    <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
      <ServerSideReportGrid
        setSettings={(setSettings as unknown) as Dispatch<UseApiSettings<ReportResult<unknown>>>}
        settings={(settings as unknown) as UseApiSettings<ReportResult<unknown>>}
        filters={filters}
        sortModel={sortModel}
        reportMetadata={getReportMetadata()}
      >
        {renderBuildingColumns()}
      </ServerSideReportGrid>
    </GridVisibilityWrapper>
  );
};

export default StationPlaylistsBuildingGrid;
