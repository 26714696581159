/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Support from 'pages/Support/Support';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import NotFound from '../pages/404Page';
import Dashboard from '../pages/Dashboard';
import Login from '../pages/Login';
import { PrivateRoute } from './PrivateRoute';
import { menuEntries as reportMenuEntries } from './reports/index';
import { Container } from './styles';
import DefaultViewLayout from 'layouts/DefaultViewLayout';
import ClassicViewLayout from 'layouts/ClassicViewLayout';
import ClassicViewCurrentDayMonitorsDaily from 'components/ClassicView/Reports/ClassicViewCurrentDayMonitorsDaily';
import ClassicViewCurrentDayMonitorsBuilding from 'components/ClassicView/Reports/ClassicViewCurrentDayMonitorsBuilding';
import ClassicViewCurrentDayCharts from 'components/ClassicView/Reports/ClassicViewCurrentDayCharts';
import ClassicViewCurrentDaySongs from 'components/ClassicView/Reports/ClassicViewCurrentDaySongs';

const AppRoutes: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path={['/classic-view*']}>
          <ClassicViewLayout>
            <PrivateRoute
              path="/classic-view-current-day-monitors-daily*"
              component={ClassicViewCurrentDayMonitorsDaily}
            />
            <PrivateRoute
              path="/classic-view-current-day-monitors-building*"
              component={ClassicViewCurrentDayMonitorsBuilding}
            />
            <PrivateRoute path="/classic-view-current-day-charts*" component={ClassicViewCurrentDayCharts} />
            <PrivateRoute path="/classic-view-current-day-songs*" component={ClassicViewCurrentDaySongs} />
          </ClassicViewLayout>
        </Route>
        <Route path={['*']}>
          <DefaultViewLayout>
            <Switch>
              {reportMenuEntries.map(route => {
                return (
                  <PrivateRoute
                    path={route.path}
                    key={uuid()}
                    component={route.component || Dashboard}
                    container={Container}
                  />
                );
              })}
              <Route exact path="/login" component={Login} />
              <Route exact path="/support" component={Support} />
              <Route exact path="/404" component={NotFound} />
              <PrivateRoute path="/" component={Dashboard} container={Container} />
              <Redirect to="/404" />
            </Switch>
          </DefaultViewLayout>
        </Route>
      </Switch>
    </>
  );
};

export default AppRoutes;
