import { STATION_BY_FORMATS, STATION_PORTFOLIO, STATION_SELECTION, STATION_PORTFOLIO_GROUP } from 'utils/Constants';
import { groupBy, reduce } from 'lodash';
import { ALL_OPTION } from '../../components/Filter/components/FormatFilter/index';
import { ExtendedRealTimeSongAnalysisFilter } from '../../pages/Reports/DailyCurrent/Songs/CurrentSongsReport/types/RealTimeSongAnalysis';
import ReportResult from '../ReportResult';

export const getCurrentSongsReportParams = (filter: ExtendedRealTimeSongAnalysisFilter | undefined): unknown => {
  return {
    ...filter,
    ...{
      FormatInfo: filter?.stationGroup === STATION_BY_FORMATS ? filter?.FormatInfo : ALL_OPTION,
      Stations: filter?.stationGroup === STATION_SELECTION ? filter?.Stations : undefined,
      StationPortfolio: filter?.stationGroup === STATION_PORTFOLIO ? filter?.StationPortfolio : undefined,
      StationPortfolioGroup:
        filter?.stationGroup === STATION_PORTFOLIO_GROUP ? filter?.StationPortfolioGroup : undefined,
      songInfos: undefined,
    },
  };
};

/* eslint-disable @typescript-eslint/no-explicit-any */
export const groupSongHistoryByFormat = <T extends { SpinsInfo: any }>(
  reportData: ReportResult<T>,
): ReportResult<T> => {
  const sumSpinsInfo = (acc: any, val: any) => {
    acc.SpinsInfo = {
      Peak: acc.SpinsInfo.Peak + val.SpinsInfo.Peak,
      ThisWeek: acc.SpinsInfo.ThisWeek + val.SpinsInfo.ThisWeek,
      LastWeek: acc.SpinsInfo.LastWeek + val.SpinsInfo.LastWeek,
      Move: acc.SpinsInfo.Move + val.SpinsInfo.Move,
    };
    acc.DaypartsInfo = {
      Overnight: acc.DaypartsInfo.Overnight + val.DaypartsInfo.Overnight,
      AmDrive: acc.DaypartsInfo.AmDrive + val.DaypartsInfo.AmDrive,
      Midday: acc.DaypartsInfo.Midday + val.DaypartsInfo.Midday,
      PmDrive: acc.DaypartsInfo.PmDrive + val.DaypartsInfo.PmDrive,
      Evening: acc.DaypartsInfo.Evening + val.DaypartsInfo.Evening,
    };
    return acc;
  };

/* eslint-disable @typescript-eslint/no-explicit-any */
const byFormat = groupBy(reportData.Items, (r: any) => r.FormatName);
const result: T[] = [];
Object.keys(byFormat).forEach((key: any) => {
    const reduced = reduce(byFormat[key], sumSpinsInfo);
    reduced.FormatName = key;
    result.push(reduced);
  });

  return {
    Items: result,
  } as ReportResult<T>;
};
