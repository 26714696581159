import FilterButton from 'components/FilterButton';
import ReportHeader from 'components/ReportHeader';
import SiblingReportsSelect from 'components/SiblingReportsSelect/SiblingReportsSelect';
import { Container, GridContainer, ReportContainer } from 'pages/Reports/styles';
import React, { FC, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DefaultDataReportGrid from './DefaultDataReportGrid';
import DefaultSongDataGridReport from './DefaultSongDataGridReport';
import FiftyTwoWeeksDataReportGrid from './FiftyTwoWeeksDataReportGrid';
import Filter from './Filter';
import { ReportModeIndexes } from './ReportModeSelect';
import { ExtendedArtistAnalysisFilter } from './utils/ArtistAnalysisFilter';
import YearToDateReportGrid from './YearToDateReportGrid';
import YearToDateSongReportGrid from './YearToDateSongReportGrid';

const ArtistAnalysisReport: FC = () => {
  const { t } = useTranslation();
  const [filterOpened, setFilterOpened] = useState(false);
  const [filter, setFilter] = useState<ExtendedArtistAnalysisFilter>();

  const resolveGrid = (): ReactElement | undefined => {
    switch (filter?.ReportMode) {
      case ReportModeIndexes.SEVEN_DAY:
      case ReportModeIndexes.HISTORY:
        return (
          <DefaultDataReportGrid
            filters={filter}
            detailGrid={<DefaultSongDataGridReport reportMode={filter?.ReportMode} />}
          />
        );
      case ReportModeIndexes.FIFTY_TWO_WEEKS:
        return <FiftyTwoWeeksDataReportGrid filters={filter} />;
      case ReportModeIndexes.YEAR_TO_DATE:
        return <YearToDateReportGrid filters={filter} detailGrid={<YearToDateSongReportGrid />} />;
      default:
        return <></>;
    }
  };

  const handleChangeFilter = (f: ExtendedArtistAnalysisFilter) => {
    setFilterOpened(false);
    setFilter(f);
  };

  return (
    <>
      <Container container direction="column" spacing={0}>
        <ReportHeader title={t('report.tab.songs')} subtitle={t('report.tab.sevenDay')} />
        <ReportContainer>
          <SiblingReportsSelect />
          <FilterButton onClickFilter={() => setFilterOpened(!filterOpened)} isOpened={filterOpened} />
        </ReportContainer>
        <Filter isOpened={filterOpened} changeFilter={handleChangeFilter} />
        <GridContainer>{resolveGrid()}</GridContainer>
      </Container>
    </>
  );
};

export default ArtistAnalysisReport;
