export interface FormatInfo {
  Id: number;
  Name: string;
  FormatCode: string;
  IsGold?: boolean;
}

// eslint-disable-next-line no-shadow
export enum CountryFormatCodeEnum {
  All = 'XX',
  Country = 'C1',
}

export interface FormatInfoChecked extends FormatInfo {
  Checked?: boolean;
}