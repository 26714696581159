/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import AccordionFilter from 'components/Filter/components/AccordionFilter';
import CalendarFilter from 'components/Filter/components/CalendarFilter';
import StationSelect from 'components/Filter/components/StationSelect/SelectedStation';
import FilterBase from 'components/FilterBase';
import { parseISO, subDays } from 'date-fns';
import useDeepLink from 'hooks/useDeepLink/useDeepLink';
import useFilterState from 'hooks/useFilterState/useFilterState';
import useStickyFilter from 'hooks/useStickyFilter/useStickyFilter';
import { get, noop } from 'lodash';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import FilterDataEvent from 'types/FilterDataEvent';
import { Station } from 'types/Station';
import { getStationDisplayName } from 'utils/reports/GetStationDisplayName';
import { ExtendedDalyLogsFilter } from './utils/types';

interface FilterDailyLogsReport {
  changeFilter: { (filters: ExtendedDalyLogsFilter | undefined): void };
  isOpened: boolean;
}

const Filter: FC<FilterDailyLogsReport> = ({ changeFilter = noop, isOpened }) => {
  const { t } = useTranslation();
  const pathname = getReportSpec(ReportBaseEnum.DailyLogsReport).stickyKey;
  const { savedFilters: filter, saveFilter } = useStickyFilter<ExtendedDalyLogsFilter>(pathname);
  const { isReady: isFilterReady, changeState, stateMap } = useFilterState();

  const { hasDeepLinkInfo } = useDeepLink(filter, saveFilter, isFilterReady, {
    onReady: changeFilter,
    // eslint-disable-next-line no-shadow
    filterTransformer: (queryString: Map<string, string>, filter: ExtendedDalyLogsFilter) => {
      const dateRange = {
        Start: parseISO(queryString.get('endDate') || ''),
        End: parseISO(queryString.get('endDate') || ''),
      };
      const newFilter = { ...filter };
      newFilter.DateRange = dateRange;
      newFilter.EndDate = dateRange.End;
      newFilter.StationInfo = { Name: queryString.get('callLetters') };
      newFilter.callLetters = newFilter.StationInfo.Name;
      newFilter.Stations = [newFilter.StationInfo];
      return newFilter;
    },
  });

  const handleFilterChange = (event: FilterDataEvent<object>) => {
    const { value } = event;
    saveFilter({ ...value } as ExtendedDalyLogsFilter);
  };

  const handleStationChange = (event: FilterDataEvent<{ Stations?: Station[] }>) => {
    const { value } = event;
    saveFilter({ ...value } as ExtendedDalyLogsFilter);
  };

  const getStationsLabel = ({ value }: FilterDataEvent<unknown>): string => {
    const stations = get(value, 'Stations') as Station[] | undefined;
    if (stations && stations.length) {
      return stations.length === 1 ? getStationDisplayName(stations[0]) ?? '' : t('filter.label.various');
    }
    return '';
  };

  return (
    <>
      <FilterBase onSubmit={() => changeFilter(filter)} isOpened={isOpened}>
        <AccordionFilter title={t('filter.title.stations')} getLabel={getStationsLabel}>
          <StationSelect
            onChange={handleStationChange}
            title={t('filter.title.stations')}
            initialValue={hasDeepLinkInfo ? undefined : filter?.Stations}
            callLetters={hasDeepLinkInfo ? filter?.callLetters : undefined}
            changeState={changeState}
            required
          />
        </AccordionFilter>
        <CalendarFilter
          onChange={handleFilterChange}
          dateOptionsParameters="dailyLog"
          initialValue={hasDeepLinkInfo ? filter?.DateRange : undefined}
          maxDate={subDays(new Date(), 1)}
        />
      </FilterBase>
    </>
  );
};

export default Filter;
