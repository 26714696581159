import ReportResult from 'services/ReportResult';
import { ArtistByStation, TransformedArtistByStation } from './types';

function convertItem(data: ArtistByStation): TransformedArtistByStation {
  return {
    ...data,
    Rank: {
      ThisWeek: data.RankThisWeek,
      LastWeek: data.RankLastWeek,
    },
  };
}

export function artistByStationResponseTransformer(
  data: ReportResult<ArtistByStation>,
): ReportResult<TransformedArtistByStation> {
  return {
    ...data,
    Items: data.Items.map(convertItem),
  };
}
