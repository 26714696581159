import { Box, Link, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SongInfo } from 'types/SongInfo';
import { useStyles } from './styles';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface SongCellRendererProps {
  value?: SongInfo;
  data?: any;
  reportQuery?: any;
  onClickHandler?: (rowData: any, reportQuery: any) => void;
}

const SongCellRenderer: React.FC<SongCellRendererProps> = ({
  value = null,
  data,
  reportQuery = null,
  onClickHandler,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const getLowerValue = () => {
    return [value?.Artist, value?.Label].join(', ');
  };

  if (!value) {
    return (
      <>
        <Typography variant="body1">{t(`grid.content.missingSongInformation`)}</Typography>
      </>
    );
  }
  const getLinkedValue = (title: string, songCode?: string) => {
    if (!onClickHandler || !songCode) {
      return title;
    }
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Link
        onClick={(event: React.MouseEvent) => {
          event.preventDefault();
          onClickHandler(data, reportQuery);
        }}
      >
        {title}
      </Link>
    );
  };

  return (
    <Box className={classes.root}>
      <Typography variant="body1">{getLinkedValue(value.Title, value.SongCode)}</Typography>
      <Typography variant="body2" noWrap title={getLowerValue()}>
        {getLowerValue()}
      </Typography>
    </Box>
  );
};

export default SongCellRenderer;
