import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      '& span': {
        display: 'inline-block',
        textTransform: 'uppercase',
        padding: '0',
      },
      '& span:last-child': {
        borderRight: 'none',
      },
    },
  });
});
