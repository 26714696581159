import React, { useState } from 'react';
import SelectInput from 'components/SelectInput';
import ReportHeader from 'components/ReportHeader';
import FilterButton from 'components/FilterButton';
import TabsGrid from 'components/TabsGrid';
import TabPanel from 'components/TabsGrid/TabPanel';
import { useHistory } from 'react-router-dom';
import LatestRefreshDate from 'components/LatestRefreshDate';
import { useTranslation } from 'react-i18next';
import { isCountryFilter } from 'utils/reports';
import { SevenDayAfterMidniteChartQuery } from 'pages/Reports/7Day/Chart/AfterMidnite/types/SevenDayAfterMidniteChartQuery';
import { ReportCycleIdEnum } from 'types/ReportCycle';
import { Container, ReportContainer, GridContainer } from './styles';
import Filter from './Filter';
import { CurrentChartFilter } from './utils/CurrentChartTypes';
import CurrentChartGrid from './CurrentChartGrid';
import MidniteChartGrid from '../../../7Day/Chart/AfterMidnite/AfterMidniteChartGrid';
//import { generateCurrentDayChartsUrl } from 'components/ClassicView/ClassicViewUrlHelper';
//import ClassicViewLink from 'components/ClassicView/ClassicViewLink';

interface Tabs {
  label: string;
  value: string;
}

const optionGrid = [
  {
    name: 'report.label.currentDayChart',
    value: 'CurrentChartReport',
  },
  {
    name: 'report.label.dailyChart',
    value: 'CurrentMusicChartReport',
  },
];

const CurrentChartReport: React.FC = () => {
  const optionTab = [
    {
      label: 'report.label.currentDayChart',
      value: 'currentChart',
    },
    {
      label: 'report.label.afterMidNite',
      value: 'afterMidNite',
    },
  ];
  const [filter, setFilter] = useState<CurrentChartFilter | undefined>();
  const history = useHistory();
  const [panel] = useState('CurrentChartReport');
  const [tabValue, setTabValue] = useState<number>(0);
  const getFilterItemsTab = (options: Tabs[], filters: CurrentChartFilter | undefined) =>
    isCountryFilter(filters) ? options : [options[0]];
  const [tabsOptions, setTabsOptions] = useState<Tabs[]>(getFilterItemsTab(optionTab, filter));
  const [filterOpened, setFilterOpened] = useState(false);
  const { t } = useTranslation();

  const changePanel = (typePanel: string) => {
    history.push(typePanel);
  };

  const onChangeTab = (value: number) => {
    setTabValue(value);
  };

  const onChangeFilter = (newFilter: CurrentChartFilter) => {
    setFilter(newFilter);
    setTabsOptions(getFilterItemsTab(optionTab, newFilter));
    setFilterOpened(!filterOpened);
  };

  const getAfterMidniteReportQuery = (
    filters: CurrentChartFilter | undefined,
  ): SevenDayAfterMidniteChartQuery | undefined => {
    if (filters === undefined) {
      return undefined;
    }

    const result = {
      ReportCycle: ReportCycleIdEnum.CurrentDay,
      PanelInfo: filters.PanelInfo,
      FormatInfo: filters.FormatInfo,
      GrcInfo: filters.GrcInfo,
      NumDays: filters.NumDays,
      TopX: filters.TopX,
      // MinSpins: filters.MinSpins
    } as SevenDayAfterMidniteChartQuery;

    return result;
  };

  //const url = generateCurrentDayChartsUrl(filter);

  return (
    <Container container direction="column" spacing={0}>
      <ReportHeader title={t('report.tab.chart')} subtitle={t('report.label.dailyCurrent')} />
      <ReportContainer>
        <SelectInput
          optionLabel="name"
          optionValue="value"
          options={optionGrid}
          value={panel}
          onChangeValue={changePanel}
        />
        <FilterButton onClickFilter={() => setFilterOpened(!filterOpened)} isOpened={filterOpened} />
      </ReportContainer>
      <Filter isOpened={filterOpened} changeFilter={onChangeFilter} />
      <LatestRefreshDate onChange={filters => onChangeFilter(filters)} filters={filter} />
      <GridContainer>
        <TabsGrid tabValue={tabValue} onChangeTab={onChangeTab} tabs={tabsOptions}>
          <TabPanel value={tabValue} index={0}>
            <CurrentChartGrid filters={filter} reportName="currentChart" />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <MidniteChartGrid filters={getAfterMidniteReportQuery(filter)} />
          </TabPanel>
        </TabsGrid>
      </GridContainer>
      {/* <ClassicViewLink url={url} /> */}
    </Container>
  );
};

export default CurrentChartReport;
