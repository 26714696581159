import { AgGridColumn } from '@ag-grid-community/react/lib/agGridColumn';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import MultiColumnsCellRenderer from 'components/DetailGrid/CellRenderers/MultiColumnsCellRenderer/MultiColumnsCellRenderer';
import RankInfoCellRenderer from 'components/DetailGrid/CellRenderers/RankInfoCellRenderer/RankInfoCellRenderer';
import SongCellRenderer from 'components/DetailGrid/CellRenderers/SongCellRenderer/SongCellRenderer';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import { TwelveWeekReport } from './TwelveWeekReport';
import TwelveWeekTransformer from './TwelveWeekTransformer';

export interface TwelveWeekGrid {
  filters?: unknown;
  twelveWeekType?: 'impression' | 'spin' | 'rank';
}

const weeks = Array.from({ length: 12 }, (v, k) => k + 1).map(v => `Week${v}`);
function calculateFields(objGroupName: string) {
  return weeks.map(v => `${objGroupName}.${v}`);
}
function calculateSecondLine(objGroupName: string) {
  return weeks
    .map(v => `${objGroupName}/${v}`)
    .map((v, i) => ({
      label: `-${i + 1}W`,
      sortPath: v,
    }));
}

const weeksConfig = {
  impressions: {
    // ImpressionTrends.Week1, ImpressionTrends.Week2 ... ImpressionTrends.Week12
    fields: calculateFields('ImpressionsTrends'),
    secondLine: calculateSecondLine('ImpressionsTrends'),
  },
  rank: {
    fields: calculateFields('RankTrends'),
    secondLine: calculateSecondLine('RankTrends'),
  },
  spin: {
    fields: calculateFields('SpinTrends'),
    secondLine: calculateSecondLine('SpinTrends'),
  },
};

const url = `/twelve-week-trend-reports`;
const TwelveWeekGrid: React.FC<TwelveWeekGrid> = ({ filters, twelveWeekType = 'rank' }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({ sortPath: 'RankTrends/Week1', sort: 'asc' });
  const { setSettings, settings, data: reportData, isLoading, isError } = useRemoteApi<ReportResult<TwelveWeekReport>>(
    url,
    {
      data: filters,
      method: 'POST',
      enabled: !!filters,
      transformResponse: TwelveWeekTransformer,
    },
  );
  return (
    <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
      <ServerSideReportGrid sortModel={sortModel} settings={settings} setSettings={setSettings} filters={filters}>
        <AgGridColumn
          field="SongInfo"
          headerName={t(`grid.header.Title`)}
          minWidth={150}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            sortPath: 'Title',
            disposition: 'row',
            secondLine: [
              { label: 'grid.header.Artist', sortPath: 'Artist' },
              { label: 'grid.header.Label', sortPath: 'Label' },
            ],
          }}
          cellRendererFramework={SongCellRenderer}
        />
        <AgGridColumn
          headerName={t('grid.header.Spins')}
          minWidth={92}
          field="SpinTrends"
          hide={twelveWeekType !== 'rank'}
          headerComponentParams={{
            sortModel,
            onSortChange,
            disposition: 'row',
            secondLine: [
              { label: 'grid.header.TW', sortPath: 'SpinTrends/Week1' },
              { label: 'grid.header.LW', sortPath: 'SpinTrends/Week2' },
            ],
          }}
          cellRendererParams={{
            fields: ['SpinTrends.Week1', 'SpinTrends.Week2'],
          }}
          cellRendererFramework={RankInfoCellRenderer}
          headerComponentFramework={MultiColumnsHeaderComponent}
        />
        <AgGridColumn
          headerName={t('grid.header.Rank')}
          minWidth={92}
          field="RankTrends"
          hide={twelveWeekType === 'rank'}
          headerComponentParams={{
            sortModel,
            onSortChange,
            disposition: 'row',
            secondLine: [
              { label: 'grid.header.TW', sortPath: 'RankTrends/Week1' },
              { label: 'grid.header.LW', sortPath: 'RankTrends/Week2' },
            ],
          }}
          cellRendererParams={{
            fields: ['RankTrends.Week1', 'RankTrends.Week2'],
          }}
          cellRendererFramework={RankInfoCellRenderer}
          headerComponentFramework={MultiColumnsHeaderComponent}
        />
        <AgGridColumn
          field="RankTrends"
          headerName={t('grid.header.twelveWeekRankTrends')}
          hide={twelveWeekType !== 'rank'}
          minWidth={650}
          valueFormatter={({ value }: ValueFormatterParams) => `${+parseFloat(value).toFixed(1)}`}
          cellRendererFramework={MultiColumnsCellRenderer}
          cellRendererParams={{ fields: weeksConfig.rank.fields }}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            secondLine: weeksConfig.rank.secondLine,
          }}
        />
        <AgGridColumn
          field="SpinTrends"
          headerName={t('grid.header.DailySpinTrends')}
          hide={twelveWeekType !== 'spin'}
          minWidth={650}
          cellRendererFramework={MultiColumnsCellRenderer}
          valueFormatter={({ value }: ValueFormatterParams) => `${+parseFloat(value).toFixed(1)}`}
          cellRendererParams={{ fields: weeksConfig.spin.fields }}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            secondLine: weeksConfig.spin.secondLine,
          }}
        />
        <AgGridColumn
          field="ImpressionsTrends"
          headerName={t('grid.header.twelveWeekRankTrendsMillions')}
          hide={twelveWeekType !== 'impression'}
          minWidth={650}
          cellRendererFramework={MultiColumnsCellRenderer}
          valueFormatter={({ value }: ValueFormatterParams) => `${+parseFloat(value).toFixed(1)}`}
          cellRendererParams={{ fields: weeksConfig.impressions.fields }}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            secondLine: weeksConfig.impressions.secondLine,
          }}
        />
      </ServerSideReportGrid>
    </GridVisibilityWrapper>
  );
};
export default TwelveWeekGrid;
