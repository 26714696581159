/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { formatDate, getNameOfTheWeekWithDate, daysMinus, formatDateTypes } from 'utils/dates';
import { DateRangeGroup } from 'types/DateRange';
import { eachDayOfInterval } from 'date-fns';
import { HeaderComponentParams } from 'types/HeaderComponentParams';
import MultiColumnsHeaderComponent from './MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';

const convertToDate = (date: string | Date) => {
  if (date) {
    return date instanceof Date ? date : new Date(date);
  }
  return new Date();
};

const getArrayDatesFromInterval = (startDate: string | Date, endDate: string | Date) => {
  const start = convertToDate(startDate);
  const end = convertToDate(endDate);
  return eachDayOfInterval({ start, end });
};

interface DaysOfTheWeekComponentProps extends HeaderComponentParams {
  name: string;
  rangeDate: DateRangeGroup;
}

const DaysOfTheWeekComponent: React.FC<DaysOfTheWeekComponentProps> = props => {
  const { name, rangeDate, column, onSortChange } = props;
  const [ranges, setRanges] = useState<Date[]>([]);
  const { field } = column.getColDef();

  useEffect(() => {
    const end = rangeDate ? rangeDate.End : new Date();
    const start = daysMinus(end, 6);
    const arrayDates = getArrayDatesFromInterval(start, end);
    setRanges(arrayDates);
  }, [rangeDate]);

  const secondLine = ranges.map(item => ({
    label: `${formatDate(item, formatDateTypes.MMdd)}`,
    subtitle: getNameOfTheWeekWithDate(item),
    sortPath: `${field}/${item}`,
  }));

  return (
    <MultiColumnsHeaderComponent
      {...props}
      displayName={name}
      secondLine={secondLine}
      disposition="column"
      onSortChange={onSortChange}
      usePercentual
    />
  );
};

export default DaysOfTheWeekComponent;
