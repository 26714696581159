import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
      '& div': {
        lineHeight: '1.5em',
      },
    },
  }),
);
