import { ColumnApi, ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import InnerTable from '..';
import { IInnerTableGroup } from '../inner-table-group';

interface AvgStation {
  StationsPlayingTitle: number;
  ThisWeek: number;
  LastWeek: number;
  Move: number;
}

export interface AvgStationRotationsGroupData {
  AveragePlay?: AvgStation;
}

interface AvgStationRotationProps {
  data: AvgStationRotationsGroupData;
  fieldName: string;
  name: string;
  columnApi: ColumnApi;
}

const AvgStationRotationInnerTable: React.FC<AvgStationRotationProps> = ({
  data,
  fieldName,
  name,
  columnApi,
}: AvgStationRotationProps) => {
  const { t } = useTranslation();
  const normalizeField = fieldName as keyof AvgStationRotationsGroupData;

  const formatValue = (value: number | string): string => {
    const colDef = columnApi.getColumn(fieldName).getColDef();
    const { valueFormatter } = colDef;

    if (valueFormatter instanceof Function) {
      return valueFormatter({ ...colDef, value } as ValueFormatterParams);
    }
    return value as string;
  };

  const children = (groupElements: AvgStationRotationsGroupData) => {
    const array = ['StationsPlayingTitle', 'ThisWeek', 'LastWeek', 'Move'];
    return array.map(item => <span key={v4()}>{formatValue(_.get(groupElements[normalizeField], item, ''))}</span>);
  };
  const innerTableData: IInnerTableGroup[] = [
    {
      title: t(name),
      children: [
        {
          title: '',
          children: [t('grid.header.On'), t('grid.header.TW'), t('grid.header.LW'), t('grid.header.plusMinus')],
        },
        {
          title: '',
          children: children(data),
        },
      ],
    },
  ];

  return <InnerTable key={v4()} data={innerTableData} />;
};

export default AvgStationRotationInnerTable;
