import i18n from 'i18next';
import { resources } from "./en.resources";
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources,
  keySeparator: '.', // we use content as keys
  interpolation: {
    escapeValue: false,
  },
  lng: 'en',
  fallbackLng: 'en',
  react: { 
    useSuspense: false 
  }
});

export default i18n;
