import { AgGridColumn } from '@ag-grid-community/react';
import { GridOptions, ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import DetailGrid from 'components/DetailGrid';
import MultiColumnsCellRenderer from 'components/DetailGrid/CellRenderers/MultiColumnsCellRenderer/MultiColumnsCellRenderer';
import PeakCellRenderer from 'components/DetailGrid/CellRenderers/PeakCellRenderer/PeakCellRenderer';
import RankInfoCellRenderer from 'components/DetailGrid/CellRenderers/RankInfoCellRenderer/RankInfoCellRenderer';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import { UseApiSettings } from 'hooks/useApi/ApiTypes';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import { ReportCycleIdEnum } from 'types/ReportCycle';
import { numberFormat } from 'utils/currency';
import { SevenDaySongAnalysisExecutiveSummaryTransformedResponse } from './utils/SongAnalysisExecutiveSummaryTypes';
import {
  SongAnalysisExecutiveSummaryRequestTransformer,
  SongAnalysisExecutiveSummaryTransformer,
} from './utils/SongAnalysisTransformer';
import { SongAnalysisFilter, StationDetailFilter } from './utils/SongAnalysisTypes';

export interface ExecutiveSummaryGridProps {
  onChangeFilter: { (value: StationDetailFilter): void };
  filters: SongAnalysisFilter;
}

const url = '/seven-day-song-analysis-executive-summary-reports';
const ExecutiveSummaryGrid: React.FC<ExecutiveSummaryGridProps> = ({ filters }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({ sortPath: 'RankThisWeek', sort: 'asc' });
  const { data: reportData, isLoading, settings, setSettings, isError } = useRemoteApi<
    SevenDaySongAnalysisExecutiveSummaryTransformedResponse
  >(url, {
    data: filters,
    method: 'POST',
    enabled: false,
    transformRequest: SongAnalysisExecutiveSummaryRequestTransformer,
    transformResponse: SongAnalysisExecutiveSummaryTransformer,
  });

  const TopOptions: GridOptions = {
    alignedGrids: [],
    defaultColDef: {
      editable: true,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 100,
    },
    suppressHorizontalScroll: true,
  };
  const BottomOptions: GridOptions = {
    alignedGrids: [],
    defaultColDef: {
      editable: true,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 100,
    },
  };
  ((TopOptions as GridOptions).alignedGrids ?? []).push(BottomOptions);
  ((BottomOptions as GridOptions).alignedGrids ?? []).push(TopOptions);

  return (
    <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
      <ServerSideReportGrid
        sortModel={sortModel}
        settings={(settings as unknown) as UseApiSettings<ReportResult<unknown>>}
        setSettings={(setSettings as unknown) as Dispatch<UseApiSettings<ReportResult<unknown>>>}
        filters={filters}
        detailProps={{
          gridOptions: TopOptions,
          domLayout: 'autoHeight',
          containerStyle: {
            height: '100%',
          },
        }}
      >
        <AgGridColumn
          headerName={t('grid.header.Rank')}
          minWidth={60}
          width={60}
          field="Rank"
          headerComponentParams={{
            sortModel,
            onSortChange,
            disposition: 'row',
            secondLine: [
              { label: 'grid.header.TW', sortPath: 'RankThisWeek' },
              { label: 'grid.header.LW', sortPath: 'RankLastWeek' },
            ],
          }}
          valueFormatter={({ value }: ValueFormatterParams) => {
            if (value === -1) return `NA`;
            if (value === 0) return `-Rec-`;
            return `${value}`;
          }}
          cellRendererFramework={RankInfoCellRenderer}
          headerComponentFramework={MultiColumnsHeaderComponent}
        />
        <AgGridColumn
          field="FormatName"
          headerName={t(`grid.header.FormatName`)}
          minWidth={100}
          headerComponentParams={{ sortModel, onSortChange }}
        />
        <AgGridColumn
          field="GrcCode"
          minWidth={40}
          width={40}
          headerName={t(`grid.header.Grc`)}
          headerComponentParams={{ sortModel, onSortChange }}
          hide={filters.ReportCycle === ReportCycleIdEnum.published}
        />
        <AgGridColumn
          field="SpinsInfo"
          minWidth={150}
          headerName={t(`grid.header.Spins`)}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            sortPath: 'SpinsInfo/ThisWeek',
            secondLine: [
              { label: 'grid.header.LW', sortPath: 'SpinsInfo/LastWeek' },
              { label: 'grid.header.TW', sortPath: 'SpinsInfo/ThisWeek' },
              { label: 'grid.header.Trend', sortPath: 'SpinsInfo/Move' },
            ],
          }}
          cellRendererParams={{
            fields: ['SpinsInfo.ThisWeek', 'SpinsInfo.LastWeek', 'SpinsInfo.Move'],
          }}
          cellRendererFramework={MultiColumnsCellRenderer}
        />
        <AgGridColumn
          field="PlayPercent"
          valueFormatter={({ value }: ValueFormatterParams) => `${(value * 100).toFixed(2)}`}
          headerName={t(`grid.header.PlayPercent`)}
          width={60}
          minWidth={60}
          headerComponentParams={{ sortModel, onSortChange }}
        />
        <AgGridColumn
          headerName={t('grid.header.StationPlaying')}
          width={80}
          minWidth={80}
          field="StationPlayingInfo"
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            sortPath: 'StationsPlayingInfo/StationsPlayingTitle',
          }}
        />
        <AgGridColumn
          field="ImpressionsInfoSpinsOnly"
          minWidth={190}
          headerName={t(`grid.header.ImpressionsMill`)}
          valueFormatter={({ value }: ValueFormatterParams) => `${+parseFloat(value).toFixed(3)}`}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            sortPath: 'ImpressionsInfo/ThisWeek',
            secondLine: [
              { sortPath: 'ImpressionsInfo/ThisWeek', label: ['grid.header.TW'] },
              { sortPath: 'ImpressionsInfo/LastWeek', label: ['grid.header.LW'] },
              { sortPath: 'ImpressionsInfo/Move', label: ['grid.header.Trend'] },
            ],
          }}
          cellRendererParams={{
            fields: ['ImpressionsInfo.SpinsThisWeek', 'ImpressionsInfo.SpinsLastWeek', 'ImpressionsInfo.Move'],
          }}
          cellRendererFramework={MultiColumnsCellRenderer}
        />
        <AgGridColumn
          field="SpinsToDate"
          minWidth={80}
          width={80}
          headerName={t(`grid.header.TD`)}
          hide={filters.ReportCycle === ReportCycleIdEnum.published}
          headerComponentParams={{ sortModel, onSortChange }}
        />
        <AgGridColumn
          field="ImpressionsToDate"
          headerName={t('grid.header.ImpressionsToDate')}
          hide={filters.ReportCycle === ReportCycleIdEnum.published}
          minWidth={120}
          valueFormatter={({ value: v }: ValueFormatterParams) => (v ? numberFormat(v) : '--')}
          headerComponentParams={{
            sortModel,
            sortPath: 'ImpressionsToDate',
            onSortChange,
          }}
        />
        <AgGridColumn
          field="PeakInfo"
          minWidth={350}
          headerName={t(`grid.header.PeakRank`)}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            sortPath: 'SpinsInfo/ThisWeek',
            secondLine: [
              { sortPath: 'PeakSpinsDate', label: ['grid.header.PeakInfo.SpinsDate'] },
              { sortPath: 'PeakSpins', label: ['grid.header.PeakInfo.Spins'] },
              { sortPath: 'PeakChart', label: ['grid.header.PeakInfo.Chart'] },
              { sortPath: 'PeakChartDate', label: ['grid.header.PeakInfo.ChartDate'] },
            ],
          }}
          cellRendererFramework={PeakCellRenderer}
        />
      </ServerSideReportGrid>
      <DetailGrid
        reportData={[reportData?.TotalItems] ?? []}
        headerHeight={0}
        gridOptions={BottomOptions}
        domLayout="autoHeight"
        rowStyle={{ fontWeight: 'bold' }}
        containerStyle={{
          height: '100%',
        }}
      >
        <AgGridColumn
          headerName={t('grid.header.Rank')}
          minWidth={60}
          width={60}
          field="Rank"
          headerComponentParams={{
            sortModel,
            onSortChange,
            disposition: 'row',
            secondLine: [
              { label: 'grid.header.LW', sortPath: 'RankLastWeek' },
              { label: 'grid.header.TW', sortPath: 'RankThisWeek' },
            ],
          }}
          cellRendererParams={{
            fields: ['Rank.ThisWeek', 'Rank.LastWeek'],
          }}
          cellRendererFramework={RankInfoCellRenderer}
          headerComponentFramework={MultiColumnsHeaderComponent}
        />
        <AgGridColumn
          field="FormatName"
          headerName={t(`grid.header.FormatName`)}
          minWidth={100}
          headerComponentParams={{ sortModel, onSortChange }}
        />
        <AgGridColumn
          field="GrcCode"
          minWidth={40}
          width={40}
          headerName={t(`grid.header.Grc`)}
          headerComponentParams={{ sortModel, onSortChange }}
          hide={filters.ReportCycle === ReportCycleIdEnum.published}
        />
        <AgGridColumn
          field="SpinsInfo"
          minWidth={150}
          headerName={t(`grid.header.Spins`)}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            sortPath: 'SpinsInfo/ThisWeek',
            secondLine: [
              { label: 'grid.header.LW', sortPath: 'SpinsInfo/LastWeek' },
              { label: 'grid.header.TW', sortPath: 'SpinsInfo/ThisWeek' },
              { label: 'grid.header.Trend', sortPath: 'ImpressionsInfo/Move' },
            ],
          }}
          cellRendererParams={{
            fields: ['SpinsInfo.ThisWeek', 'SpinsInfo.LastWeek', 'SpinsInfo.Move'],
          }}
          cellRendererFramework={MultiColumnsCellRenderer}
        />
        <AgGridColumn
          field="PlayPercent"
          valueFormatter={({ value }: ValueFormatterParams) => `${(value * 100).toFixed(2)}`}
          headerName={t(`grid.header.PlayPercent`)}
          width={60}
          minWidth={60}
          headerComponentParams={{ sortModel, onSortChange }}
        />
        <AgGridColumn
          headerName={t('grid.header.StationPlaying')}
          width={80}
          minWidth={80}
          field="StationPlayingInfo"
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            sortPath: 'StationsPlayingInfo/StationsPlayingTitle',
          }}
        />
        <AgGridColumn
          field="ImpressionsInfoSpinsOnly"
          minWidth={190}
          headerName={t(`grid.header.ImpressionsMill`)}
          headerComponentFramework={MultiColumnsHeaderComponent}
          valueFormatter={({ value }: ValueFormatterParams) => `${+parseFloat(value).toFixed(3)}`}
          headerComponentParams={{
            sortModel,
            onSortChange,
            sortPath: 'ImpressionsInfo/ThisWeek',
            secondLine: [
              { sortPath: 'ImpressionsInfo/ThisWeek', label: ['grid.header.TW'] },
              { sortPath: 'ImpressionsInfo/LastWeek', label: ['grid.header.LW'] },
              { sortPath: 'ImpressionsInfo/Move', label: ['grid.header.Trend'] },
            ],
          }}
          cellRendererParams={{
            fields: ['ImpressionsInfo.SpinsThisWeek', 'ImpressionsInfo.SpinsLastWeek', 'ImpressionsInfo.Move'],
          }}
          cellRendererFramework={MultiColumnsCellRenderer}
        />
        <AgGridColumn
          field="SpinsToDate"
          minWidth={80}
          width={80}
          headerName={t(`grid.header.TD`)}
          headerComponentParams={{ sortModel, onSortChange }}
          hide={filters.ReportCycle === ReportCycleIdEnum.published}
        />
        <AgGridColumn
          field="ImpressionsToDate"
          headerName={t('grid.header.ImpressionsToDate')}
          minWidth={120}
          valueFormatter={({ value: v }: ValueFormatterParams) => (v ? numberFormat(v) : '--')}
          hide={filters.ReportCycle === ReportCycleIdEnum.published}
          headerComponentParams={{
            sortModel,
            sortPath: 'ImpressionsToDate',
            onSortChange,
          }}
        />
        <AgGridColumn
          field="PeakInfo"
          minWidth={350}
          headerName={t(`grid.header.PeakRank`)}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            sortPath: 'SpinsInfo/ThisWeek',
            secondLine: [
              { sortPath: 'PeakSpinsDate', label: ['grid.header.PeakInfo.SpinsDate'] },
              { sortPath: 'PeakSpins', label: ['grid.header.PeakInfo.Spins'] },
              { sortPath: 'PeakChart', label: ['grid.header.PeakInfo.Chart'] },
              { sortPath: 'PeakChartDate', label: ['grid.header.PeakInfo.ChartDate'] },
            ],
          }}
          cellRendererFramework={PeakCellRenderer}
        />
      </DetailGrid>
    </GridVisibilityWrapper>
  );
};

export default ExecutiveSummaryGrid;
