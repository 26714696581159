/* eslint-disable no-param-reassign */
import { AgGridColumn } from '@ag-grid-community/react/lib/agGridColumn';
import SongCellRenderer from 'components/DetailGrid/CellRenderers/SongCellRenderer/SongCellRenderer';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import { SevenDayChartUrl } from 'enums/urls';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import { UseApiSettings } from 'hooks/useApi/ApiTypes';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import { CurrentChartResponse } from 'pages/Reports/DailyCurrent/Chart/CurrentChartReport/utils/CurrentChartTypes';
import React, { Dispatch, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import FilterDataEvent from 'types/FilterDataEvent';
import { FilterParams } from 'types/FilterParams';
import InputNumberSelection from 'components/Filter/components/InputNumberSelection';
import ButtonFilterGrid from 'components/Filter/components/ButtonFilterGrid';
import { ReportCycleIdEnum } from 'types/ReportCycle';
import { FilterGridContainer } from '../../Songs/SongAnalysisReport/styles';
import { PublishedChartFilter } from './utils/PublishedChartReportTypes';
import { FormatContainer } from './styles';
import { GenericFilter } from '../../StationMonitor/StationPlaylistsReport/utils/StationGenericTypes';
import { SongRankingChartTransformer } from './utils/SongRankingChartTransfomer';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface PublishedChartProps {
  filters: PublishedChartFilter | undefined;
  onChangeFilter?: (value: any) => void;
}

interface MaxWeeks {
  MaxWeeks: number;
}

const url = SevenDayChartUrl.songRankLeadersReports;
const defaultWeeks = 10;

const SongRankingChartGrid: React.FC<PublishedChartProps> = ({ filters, onChangeFilter }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({
    sortPath: 'StartDate',
    sort: 'desc',
  });
  const [weeksFilter, setWeeksFilter] = useState<GenericFilter | undefined>();

  const { setSettings, settings, data: reportData, isLoading, isError } = useRemoteApi<CurrentChartResponse>(url, {
    data: filters,
    method: 'POST',
    enabled: false,
    transformRequest: data => {
      data.ReportCycle = data.ReportCycle ? data.ReportCycle : ReportCycleIdEnum.building;
      data.MaxWeeks = data?.MaxWeeks ?? defaultWeeks;
      return data;
    },
    transformResponse: SongRankingChartTransformer,
  });

  const handleFilterChangeWeeks = (event: FilterDataEvent<FilterParams>) => {
    const { value } = event;
    setWeeksFilter({ ...filters, ...value });
  };

  const handleApply = () => {
    if (onChangeFilter) {
      onChangeFilter(weeksFilter);
    }
  };
  return (
    <>
      <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
        <FilterGridContainer>
          <FormatContainer>
            <InputNumberSelection<MaxWeeks>
              onChange={handleFilterChangeWeeks}
              valueToApi="MaxWeeks"
              name={t('filter.title.displayTheLast')}
              minValue={1}
              maxValue={52}
              initialValue={10}
            />
          </FormatContainer>
          <ButtonFilterGrid onClick={handleApply} />
        </FilterGridContainer>
        <ServerSideReportGrid
          setSettings={(setSettings as unknown) as Dispatch<UseApiSettings<ReportResult<unknown>>>}
          settings={(settings as unknown) as UseApiSettings<ReportResult<unknown>>}
          filters={filters}
          sortModel={sortModel}
        >
          <AgGridColumn
            headerName={t('grid.header.ChartRank')}
            minWidth={180}
            maxWidth={200}
            field="ChartWeek"
            headerComponentParams={{
              sortModel,
              onSortChange,
              sortPath: 'StartDate',
            }}
          />
          <AgGridColumn
            field="SongInfo"
            headerName={t(`grid.header.Title`)}
            minWidth={200}
            maxWidth={250}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              sortPath: 'Title',
              onSortChange,
              disposition: 'row',
              secondLine: [
                { sortPath: 'Artist', label: 'grid.header.Artist' },
                { sortPath: 'Label', label: 'grid.header.Label' },
              ],
            }}
            cellRendererFramework={SongCellRenderer}
          />
        </ServerSideReportGrid>
      </GridVisibilityWrapper>
    </>
  );
};

export default SongRankingChartGrid;
