import { AgGridColumn } from '@ag-grid-community/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import ReportResult from 'services/ReportResult';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import { SevenDayChartUrl } from 'enums/urls';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import DayPartsHeaderComponent from 'components/DetailGrid/HeaderComponents/DayPartsHeaderComponent';
import DayPartsCellRenderer from 'components/DetailGrid/CellRenderers/DayPartsCellRenderer';
import MultiColumnsCellRenderer from 'components/DetailGrid/CellRenderers/MultiColumnsCellRenderer/MultiColumnsCellRenderer';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import { formatDate } from 'utils/dates';
import SongCellRenderer from 'components/DetailGrid/CellRenderers/SongCellRenderer/SongCellRenderer';
import { StationPlaylistGenericTransformedItem } from './utils/StationGenericTypes';
import { StationPlaylistsFilter } from './utils/StationPlaylistsTypes';
import { StationPlaylistGenericTransformerResponse } from './utils/StationPlaylistGenericTransformer';

export interface HistoricalGridProps {
  filters: StationPlaylistsFilter | undefined;
}

const url = SevenDayChartUrl.stationPlaylistHistoricalReports;
const HistoricalGrid: React.FC<HistoricalGridProps> = ({ filters }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({ sortPath: 'SpinsToDate', sort: 'asc' });

  const { data: reportData, isLoading, settings, setSettings, isError } = useRemoteApi<
    ReportResult<StationPlaylistGenericTransformedItem>
  >(url, {
    data: filters,
    method: 'POST',
    enabled: false,
    transformResponse: StationPlaylistGenericTransformerResponse,
  });

  return (
    <>
      <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
        <ServerSideReportGrid sortModel={sortModel} settings={settings} setSettings={setSettings} filters={filters}>
          <AgGridColumn
            field="RankThisWeek"
            headerName={t(`grid.header.StationRank`)}
            minWidth={50}
            maxWidth={50}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="ArtistInfo"
            headerName={t(`grid.header.Title`)}
            minWidth={150}
            maxWidth={180}
            pinned
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              secondLine: [
                { sortPath: 'Artist', label: 'grid.header.Artist' },
                { sortPath: 'Label', label: 'grid.header.Label' },
              ],
              sortModel,
              disposition: 'row',
              sortPath: 'ArtistInfo',
              onSortChange,
            }}
            cellRendererFramework={SongCellRenderer}
          />
          <AgGridColumn
            field="ReleaseYear"
            headerName={t(`grid.header.Year`)}
            minWidth={80}
            maxWidth={80}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="SpinsToDate"
            headerName={t(`grid.header.SpinsToDate`)}
            minWidth={100}
            maxWidth={100}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            headerName="Day Parts"
            field="DaypartsInfo"
            maxWidth={240}
            minWidth={240}
            headerComponentFramework={DayPartsHeaderComponent}
            headerComponentParams={{ sortModel, onSortChange }}
            cellRendererFramework={DayPartsCellRenderer}
          />
          <AgGridColumn
            headerName={t('grid.header.Impressions')}
            minWidth={160}
            maxWidth={160}
            field="ImpressionsMill"
            headerComponentParams={{
              sortModel,
              onSortChange,
              secondLine: [
                { label: t('grid.header.ReachMillion'), sortPath: 'ReachMillion' },
                { label: t('grid.header.ImpRank'), sortPath: 'ImpRank' },
              ],
            }}
            cellRendererParams={{
              fields: ['ImpressionsMill.SpinsThisWeek', 'ImpressionsMill.RankThisWeek'],
            }}
            cellRendererFramework={MultiColumnsCellRenderer}
            headerComponentFramework={MultiColumnsHeaderComponent}
          />
          <AgGridColumn
            headerName={t('grid.header.historicalData.HistoricalData')}
            minWidth={200}
            maxWidth={200}
            field="Historical"
            valueFormatter={({ value }: ValueFormatterParams) =>
              typeof value === 'string' && value ? formatDate(value) : '-'
            }
            headerComponentParams={{
              sortModel,
              onSortChange,
              disposition: 'row',
              secondLine: [
                { label: t('grid.header.FirstMonitored'), sortPath: 'RankThisWeek' },
                { label: t('grid.header.LastMonitored'), sortPath: 'RankLastWeek' },
              ],
            }}
            cellRendererParams={{
              fields: ['Historical.FirstMonitoredDate', 'Historical.LastMonitoredDate'],
            }}
            cellRendererFramework={MultiColumnsCellRenderer}
            headerComponentFramework={MultiColumnsHeaderComponent}
          />
        </ServerSideReportGrid>
      </GridVisibilityWrapper>
    </>
  );
};

export default HistoricalGrid;
