import { AgGridColumn } from '@ag-grid-community/react/lib/agGridColumn';
import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import { UseApiSettings } from 'hooks/useApi/ApiTypes';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import ArtistCellRenderer from 'components/DetailGrid/CellRenderers/ArtistCellRenderer/ArtistCellRenderer';
import DayOfTheWeekCellRenderer from 'components/DetailGrid/CellRenderers/DaysOfTheWeekRenderer';
import ThisLastMonthCellRenderer from 'components/DetailGrid/CellRenderers/ThisLastMonthCellRenderer';
import BooleanCanadaCheckMarkCellRenderer from 'components/DetailGrid/CellRenderers/BooleanCanadaCheckMarkCellRenderer';
import { PanelCodeEnum } from 'types/Panel';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import ReportResult from 'services/ReportResult';
import { DailyCurrentChartUrl } from 'enums/urls';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import queryString from 'query-string';
import { CurrentMusicChartFilter, CurrentMusicChartResponse } from './utils/CurrentMusicChartTypes';
import { CurrentMusicChartTransformer } from './utils/CurrentMusicChartTransformer';

interface DailyMusicChartProps {
  filters: CurrentMusicChartFilter | undefined;
}

const url = DailyCurrentChartUrl.dailyMusicChartReports;

const CurrentMusicChartGrid: React.FC<DailyMusicChartProps> = ({ filters }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({
    sortPath: 'DayByDayRanks/1/ThisWeek',
    sort: 'asc',
  });
  const { setSettings, settings, data: reportData, isLoading, isError } = useRemoteApi<CurrentMusicChartResponse>(url, {
    data: filters,
    method: 'POST',
    enabled: false,
    transformResponse: CurrentMusicChartTransformer,
  });

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const songClickRenderer = (data: any, reportQuery: CurrentMusicChartFilter) => {
    const href = getReportSpec(ReportBaseEnum.CurrentOneDayReport).path;
    const params = {
      songCode: data.SongCode,
      'PanelInfo.Id': reportQuery?.PanelInfo?.Id,
      'FormatInfo.FormatCode': reportQuery?.FormatInfo?.FormatCode,
      'GrcInfo.Id': reportQuery?.GrcInfo?.Id,
      deepLink: true,
    };
    window.open(`${href}?${queryString.stringify(params)}`);
  };

  const transformToDateData = () => {
    const result = reportData?.DayByDayColumns?.map(item => {
      return { NumDay: item.DayIndex, DayText: item.DayName, BuildingDate: item.Date };
    });
    return result;
  };

  const getReportMetadata = () => {
    const metadata = {
      DateData: transformToDateData(),
    };
    return metadata;
  };

  return (
    <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
      <ServerSideReportGrid
        setSettings={(setSettings as unknown) as Dispatch<UseApiSettings<ReportResult<unknown>>>}
        settings={(settings as unknown) as UseApiSettings<ReportResult<unknown>>}
        filters={filters}
        sortModel={sortModel}
        reportMetadata={getReportMetadata()}
      >
        <AgGridColumn
          field="ArtistInfo"
          pinned
          headerName={t(`grid.header.Title`)}
          width={150}
          minWidth={150}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            sortPath: 'Title',
            secondLine: [{ label: 'grid.header.Artist', sortPath: 'Artist' }],
          }}
          cellRendererFramework={ArtistCellRenderer}
          cellRendererParams={{
            reportQuery: filters,
            onClickHandler: songClickRenderer,
          }}
        />
        <AgGridColumn
          field="HasCanadianContent"
          headerName="CanCon"
          width={60}
          minWidth={50}
          cellRendererFramework={BooleanCanadaCheckMarkCellRenderer}
          hide={
            !(
              filters?.PanelInfo?.PanelCode === PanelCodeEnum.AllCanadianStations ||
              filters?.PanelInfo?.PanelCode === PanelCodeEnum.PublishedCanadaPanel
            )
          }
          headerComponentParams={{ sortModel, onSortChange, sortPath: 'HasCanadianContent' }}
        />
        <AgGridColumn
          field="DayByDayRanksFlat"
          headerName={t(`grid.header.DayByDayRank`)}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            usePercentual: true,
            sortModel,
            onSortChange,
            secondLine: reportData?.HeaderDayByDayRanksFlat,
          }}
          width={250}
          minWidth={250}
          cellRendererFramework={DayOfTheWeekCellRenderer}
        />
        <AgGridColumn
          field="DayByDaySpinsFlat"
          colId="DayByDaySpins"
          cellRendererFramework={DayOfTheWeekCellRenderer}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerName={t(`grid.header.ThisWeekDayByDayPlays`)}
          headerComponentParams={{
            usePercentual: true,
            sortModel,
            onSortChange,
            secondLine: reportData?.HeaderThisWeekDayByDayRank,
          }}
          minWidth={300}
          width={300}
        />
        <AgGridColumn
          field="LastMonthInfo"
          minWidth={100}
          width={100}
          headerName={reportData?.HeaderLastSpin}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            usePercentual: true,
            sortModel,
            onSortChange,
            secondLine: [
              { label: 'Spins', sortPath: 'LastSpinsInfo/LastWeek' },
              { label: '+/-', sortPath: 'LastSpinsInfo/Move' },
            ],
          }}
          cellRendererFramework={ThisLastMonthCellRenderer}
          cellRendererParams={{ showZeroMove: true }}
        />
      </ServerSideReportGrid>
    </GridVisibilityWrapper>
  );
};

export default CurrentMusicChartGrid;
