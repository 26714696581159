/* eslint-disable react/jsx-first-prop-new-line */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import ReportHeader from 'components/ReportHeader';
import FilterButton from 'components/FilterButton';
import SiblingReportsSelect from 'components/SiblingReportsSelect/SiblingReportsSelect';
import PreviousNextSelect from 'components/PreviousNextWeekSelect';
import _ from 'lodash';
import { DateRangeGroup } from 'types/DateRange';
import TabsGrid from 'components/TabsGrid';
import TabPanel from 'components/TabsGrid/TabPanel';
import { isCountryFilter } from 'utils/reports';
import { Tabs } from 'types/Tabs';
import { useTranslation } from 'react-i18next';
import { CurrentChartFilter } from 'pages/Reports/DailyCurrent/Chart/CurrentChartReport/utils/CurrentChartTypes';
import { ReportCycleIdEnum } from 'types/ReportCycle';
import { PublishedChartFilter } from './utils/PublishedChartReportTypes';
import PublishedChartGrid from './PublishedChartGrid';
import AfterMidniteChartGrid from '../AfterMidnite/AfterMidniteChartGrid';
import SongRankingChartGrid from './SongRankingChartGrid';
import Filter from './Filter';
import { Container, ReportContainer, GridContainer } from './styles';
import { SevenDayAfterMidniteChartQuery } from '../AfterMidnite/types/SevenDayAfterMidniteChartQuery';

const PublishedChartReport: React.FC = () => {
  const { t } = useTranslation();
  const optionTab = [
    {
      label: 'report.label.publishedChart',
      value: 'currentChart',
    },
    {
      label: 'report.label.songRankingFirst',
      value: 'songRanking',
    },
    {
      label: 'report.label.afterMidNite',
      value: 'afterMidNite',
    },
  ];
  const [filterOpened, setFilterOpened] = useState(false);
  const [filters, setFilters] = useState<PublishedChartFilter | undefined>();
  const [date, setDate] = useState<DateRangeGroup>();
  const [tabValue, setTabValue] = useState<number>(0);
  const [filterPreviousNextDate, setFilterPreviousNextDate] = useState<DateRangeGroup>();
  const getFilterItemsTab = (options: Tabs[], filters: PublishedChartFilter | CurrentChartFilter | undefined) =>
    isCountryFilter(filters) ? options : [options[0], options[1]];
  const [tabsOptions, setTabsOptions] = useState<Tabs[]>(getFilterItemsTab(optionTab, filters));

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const onChangeFilter = (filtersParams: PublishedChartFilter | any) => {
    setFilterOpened(false);
    setTabsOptions(getFilterItemsTab(optionTab, filtersParams));
    setFilters(filtersParams);
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleFilterChange = (event: DateRangeGroup) => {
    setFilterOpened(false);
    setTabsOptions(getFilterItemsTab(optionTab, { ...filters, DateRange: event } as PublishedChartFilter));
    setFilterPreviousNextDate(event);
    setFilters({ ...filters, DateRange: event } as PublishedChartFilter);
  };

  const getAfterMidniteReportQuery = (
    filters: PublishedChartFilter | undefined,
  ): SevenDayAfterMidniteChartQuery | undefined => {
    if (filters === undefined) {
      return undefined;
    }

    const result = {
      ReportCycle: ReportCycleIdEnum.published,
      PanelInfo: filters.PanelInfo,
      FormatInfo: filters.FormatInfo,
      GrcInfo: filters.GrcInfo,
      NumDays: 7,
      TopX: filters.TopX,
      MinSpins: filters.MinSpins,
    } as SevenDayAfterMidniteChartQuery;

    return result;
  };

  const onChangeTab = (value: number) => {
    setTabValue(value);
  };

  useEffect(() => {
    const get = _.get(filters, ['DateRange']);
    setDate(get);
  }, [filters]);

  return (
    <Container container direction="column" spacing={0}>
      <ReportHeader title={t('report.tab.chart')} subtitle={t('report.tab.sevenDay')} />
      <ReportContainer>
        <SiblingReportsSelect />
        <FilterButton onClickFilter={() => setFilterOpened(!filterOpened)} isOpened={filterOpened} />
      </ReportContainer>
      <Filter isOpened={filterOpened} changeFilter={onChangeFilter} PreviousNextFilterDate={filterPreviousNextDate} />
      {filters && date && <PreviousNextSelect group={date} onChange={handleFilterChange} dateMax={filters.MaxDate} />}
      <GridContainer>
        <TabsGrid tabValue={tabValue} onChangeTab={onChangeTab} tabs={tabsOptions}>
          <TabPanel value={tabValue} index={0}>
            <PublishedChartGrid filters={filters} />
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <SongRankingChartGrid filters={filters} onChangeFilter={onChangeFilter} />
          </TabPanel>
          <TabPanel value={tabValue} index={2}>
            <AfterMidniteChartGrid filters={getAfterMidniteReportQuery(filters)} />
          </TabPanel>
        </TabsGrid>
      </GridContainer>
    </Container>
  );
};

export default PublishedChartReport;
