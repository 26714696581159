import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import InnerTable from '../index';
import { IInnerTableGroup } from '../inner-table-group';
import defaultTheme from '../../../themes';

export interface SpinsDifferentialInfoType {
  SpinDifferential?: number;
}

interface SpinsDifferentialInfoTableProps {
  data: SpinsDifferentialInfoType;
  name: string;
}

const SpinsDifferentialInnerTable: React.FC<SpinsDifferentialInfoTableProps> = ({
  data,
  name,
}: SpinsDifferentialInfoTableProps) => {
  const { t } = useTranslation();
  const createSpan = (baseValue: number) => {
    return <span>{baseValue}</span>;
  };

  const createIcon = (showIcon: boolean) => {
    if (showIcon) {
      return <FontAwesomeIcon icon={faArrowUp} color={defaultTheme.palette.success.main} size="1x" />;
    }
    return <></>;
  };

  const innerTableData: IInnerTableGroup[] = [
    {
      title: t(name),
      children: [
        {
          title: '',
          children: [
            t('grid.header.SpinsDifferential.FirstSong'),
            t('grid.header.Spins'),
            t('grid.header.SpinsDifferential.SecondSong'),
          ],
        },
        {
          title: '',
          children: [
            createIcon((data.SpinDifferential ?? 0) > 0),
            createSpan(data.SpinDifferential ?? 0),
            createIcon((data.SpinDifferential ?? 0) < 0),
          ],
        },
      ],
    },
  ];

  return <InnerTable key={v4()} data={innerTableData} />;
};

export default SpinsDifferentialInnerTable;
