/* eslint-disable react-hooks/exhaustive-deps */
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { menuEntries as reportMenuEntries } from 'routes/reports/index';
import { NavigationMenuApiResponse } from 'types/menu/NavigationMenuApiResponse';
import { NavigationMenuContext } from 'types/menu/NavigationMenuContext';
import AuthSettings from 'contexts/AuthContext';

function filterAvailableMenus(navigationMenus: NavigationMenuApiResponse[]) {
  const mappedReportsUrls = reportMenuEntries.map(me => me.path);
  return navigationMenus.filter(nm => !nm.Url || mappedReportsUrls.includes(nm.Url));
}

function filterCurrentMenu(
  navigationMenu: NavigationMenuApiResponse[],
  pathname: string,
): NavigationMenuApiResponse | undefined {
  const thisReport = navigationMenu.find(nm => !!nm.Url && nm.Url === pathname);
  if (!thisReport) {
    return navigationMenu.flatMap(nm => filterCurrentMenu(nm.Items ?? [], pathname)).find(v => !!v);
  }

  return thisReport;
}

const NavigationMenu = createContext<NavigationMenuContext>({ navigationMenus: [] } as NavigationMenuContext);

const NavigationMenuProvider: FC = ({ children }) => {
  const { token } = useContext(AuthSettings);
  const { pathname } = useLocation();
  const { data: navigationMenus } = useRemoteApi<NavigationMenuApiResponse[]>('navigation-menus', {
    enabled: true,
    data: { token },
    method: 'GET',
    params: { _force: true },
    transformResponse: data => {
      return data?.Items ?? [];
    },
  });

  const [navigationMenuData, setNavigationMenuData] = useState<NavigationMenuContext>({ navigationMenus: [] });

  useEffect(() => {
    if (navigationMenus) {
      const mappedNavigationMenus = filterAvailableMenus(navigationMenus);
      setNavigationMenuData({
        navigationMenus: mappedNavigationMenus,
        currentNavigationMenu: filterCurrentMenu(mappedNavigationMenus, pathname),
      });
    }
  }, [navigationMenus, pathname]);

  return <NavigationMenu.Provider value={navigationMenuData}>{children}</NavigationMenu.Provider>;
};

export { NavigationMenu as default, NavigationMenuProvider };
