import { Station } from "types/Station";

export const getStationDisplayName = (stationInfo:Station | undefined): string =>
{
    let result = "";
    if(stationInfo)
    {
        const val = stationInfo.DisplayName != null 
            ? stationInfo.DisplayName
            : stationInfo.Name;
        result = val != null ? val : "";
    }
    return result;
}; 