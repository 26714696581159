import FormatFilter from 'components/Filter/components/FormatFilter';
import PanelFilter from 'components/Filter/components/PanelFilter';
import PastDays, { getCurrentBuildingCycle } from 'components/Filter/components/PastDays';
import SingleSongSelection from 'components/Filter/components/SingleSongSelection/SingleSongSelection';
import FilterBase from 'components/FilterBase';
import useStickyFilter from 'hooks/useStickyFilter/useStickyFilter';
import { set } from 'lodash';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import React from 'react';
import { useTranslation } from 'react-i18next';
import FilterDataEvent from 'types/FilterDataEvent';
import { FormatInfo } from 'types/Format';
import { PanelInfo, PanelCodeEnum } from 'types/Panel';
import { ReportFilterProps } from 'types/ReportFilterProps';
import { SongInfo } from 'types/SongInfo';

export interface SongComparisonReportFilter {
  PanelInfo?: PanelInfo;
  FormatInfo?: FormatInfo;
  FirstSongInfo?: SongInfo;
  SecondSongInfo?: SongInfo;
  NumDays?: number;
}

const Filter: React.FC<ReportFilterProps<SongComparisonReportFilter>> = ({ isOpened, onChange: changeFilter }) => {
  const pathname = getReportSpec(ReportBaseEnum.SongComparisonReport).stickyKey;
  const { t } = useTranslation();
  const { savedFilters: filter, saveFilter } = useStickyFilter<SongComparisonReportFilter>(pathname);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFilterChange = (event: FilterDataEvent<SongComparisonReportFilter>) => {
    const { value } = event as FilterDataEvent<SongComparisonReportFilter>;
    saveFilter({ ...filter, ...value });
  };

  const changeSong = (ev: FilterDataEvent<{ SongInfo?: SongInfo }>, property: string) => {
    const value = ev?.value?.SongInfo;
    const transformed = set({}, property, value);
    handleFilterChange({ value: transformed });
  };

  return (
    <FilterBase onSubmit={() => changeFilter(filter ?? {})} isOpened={isOpened}>
      <PanelFilter onChange={handleFilterChange} initialValue={filter?.PanelInfo} />
      <FormatFilter
        onChange={handleFilterChange}
        panelCode={filter?.PanelInfo?.PanelCode ?? PanelCodeEnum.AllUsStations}
        initialValue={filter?.FormatInfo}
      />
      <SingleSongSelection
        title={t(`filter.title.FirstSongInfo`)}
        onChange={ev => changeSong(ev, 'FirstSongInfo')}
        initialValue={filter?.FirstSongInfo}
      />
      <SingleSongSelection
        title={t(`filter.title.SecondSongInfo`)}
        onChange={ev => changeSong(ev, 'SecondSongInfo')}
        initialValue={filter?.SecondSongInfo}
      />
      <PastDays onChange={handleFilterChange} initialValue={getCurrentBuildingCycle()} />
    </FilterBase>
  );
};

export default Filter;
