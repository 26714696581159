/* eslint-disable no-param-reassign */
import { AgGridColumn } from '@ag-grid-community/react';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import { UseApiSettings } from 'hooks/useApi/ApiTypes';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import React, { useState, Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import SongCellRenderer from 'components/DetailGrid/CellRenderers/SongCellRenderer/SongCellRenderer';
import ThisLastMoveCellRenderer from 'components/DetailGrid/CellRenderers/ThisLastMoveCellRenderer';
import RankInfoCellRendererRec from 'components/DetailGrid/CellRenderers/RankInfoCellRendererRec/RankInfoCellRendererRec';
import { SevenDayChartUrl } from 'enums/urls';
import PastDays from 'components/Filter/components/PastDays';
import FilterDataEvent from 'types/FilterDataEvent';
import { FilterParams } from 'types/FilterParams';
import { AfterMidniteChartTransformer } from './AfterMidniteChartTransformer';
import { FilterGridContainer } from '../../Songs/SongAnalysisReport/styles';
import { SevenDayAfterMidniteChartQuery } from './types/SevenDayAfterMidniteChartQuery';
import { SevenDayAfterMidniteChartReport } from './types/SevenDayAfterMidniteChartReport';

interface AfterMidniteChartProps {
  filters: SevenDayAfterMidniteChartQuery | undefined;
}

const url = SevenDayChartUrl.sevenDayAfterMidniteChartReports;

const AfterMidniteChartGrid: React.FC<AfterMidniteChartProps> = ({ filters }) => {
  const { t } = useTranslation();
  const [reportQuery, setReportQuery] = useState<SevenDayAfterMidniteChartQuery | undefined>(filters);

  const { sortModel, setSortModel: onSortChange } = useSortColumn({
    sortPath: 'RankThisWeek',
    sort: 'asc',
  });

  const { setSettings, settings, data: reportData, isLoading, isError } = useRemoteApi<SevenDayAfterMidniteChartReport>(
    url,
    {
      data: reportQuery,
      method: 'POST',
      enabled: false,
      transformRequest: data => {
        delete data.DateRange;
        delete data.EndDate;
        data.ReportCycle = data.ReportCycle ? data.ReportCycle : 1;
        return data;
      },
      transformResponse: AfterMidniteChartTransformer,
    },
  );

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleFilterChange = (event: FilterDataEvent<FilterParams>) => {
    const { value } = event;
    if (reportQuery !== undefined) {
      setReportQuery({ ...reportQuery, ...value });
    }
  };

  const getReportMetadata = () => {
    const metadata = {
      DateData: reportData?.DateData,
    };
    return metadata;
  };

  return (
    <>
      <FilterGridContainer>
        <PastDays lowerBound={1} upperBound={7} initialValue={7} onChange={handleFilterChange} />
      </FilterGridContainer>
      <GridVisibilityWrapper filters={reportQuery} isLoading={isLoading} reportData={reportData} isError={isError}>
        <ServerSideReportGrid
          setSettings={(setSettings as unknown) as Dispatch<UseApiSettings<ReportResult<unknown>>>}
          settings={(settings as unknown) as UseApiSettings<ReportResult<unknown>>}
          filters={reportQuery}
          sortModel={sortModel}
          reportMetadata={getReportMetadata()}
        >
          <AgGridColumn
            headerName={t('grid.header.Rank')}
            minWidth={60}
            width={60}
            field="RankInfo"
            headerComponentParams={{
              sortModel,
              onSortChange,
              disposition: 'row',
              secondLine: [
                { label: 'grid.header.TW', sortPath: 'RankThisWeek' },
                { label: 'grid.header.LW', sortPath: 'RankLastWeek' },
              ],
            }}
            cellRendererParams={{
              fields: ['Rank.ThisWeek', 'Rank.LastWeek'],
            }}
            cellRendererFramework={RankInfoCellRendererRec}
            headerComponentFramework={MultiColumnsHeaderComponent}
          />
          <AgGridColumn
            field="SongInfo"
            headerName={t(`grid.header.Title`)}
            minWidth={240}
            width={240}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              sortPath: 'Title',
              disposition: 'row',
              secondLine: [
                { label: 'grid.header.Artist', sortPath: 'Artist' },
                { label: 'grid.header.Label', sortPath: 'Label' },
              ],
            }}
            cellRendererFramework={SongCellRenderer}
            cellRendererParams={{ onClickHandler: null }}
          />
          <AgGridColumn
            field="SpinsInfo"
            minWidth={100}
            width={100}
            headerName={t(`grid.header.SpinsInfoTotal`)}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              disposition: 'row',
              secondLine: [
                { sortPath: 'SpinsInfo/ThisWeek', label: ['grid.header.TW'] },
                { sortPath: 'SpinsInfo/LastWeek', label: ['grid.header.LW'] },
                { sortPath: 'SpinsInfo/Move', label: ['grid.header.Move'] },
              ],
            }}
            cellRendererFramework={ThisLastMoveCellRenderer}
          />
          {(reportData?.DateData ?? []).map(dateDataColumn => (
            <AgGridColumn
              key={dateDataColumn.NumDay}
              field={`DayByDaySpins.${[parseInt(dateDataColumn.NumDay, 10)]}.ThisWeek`}
              minWidth={100}
              headerName={dateDataColumn.DayText}
              headerComponentFramework={MultiColumnsHeaderComponent}
              headerComponentParams={{
                sortModel,
                onSortChange,
                disposition: 'row',
              }}
            />
          ))}
        </ServerSideReportGrid>
      </GridVisibilityWrapper>
    </>
  );
};

export default AfterMidniteChartGrid;
