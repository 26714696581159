import React from 'react';
import { useTranslation } from 'react-i18next';
import { AgGridColumn } from '@ag-grid-community/react';
import { ICellRendererParams } from '@ag-grid-enterprise/all-modules';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import ThisLastMoveCellRenderer from 'components/DetailGrid/CellRenderers/ThisLastMoveCellRenderer';
import RankInfoCellRenderer from 'components/DetailGrid/CellRenderers/RankInfoCellRenderer/RankInfoCellRenderer';
import SongCellRenderer from 'components/DetailGrid/CellRenderers/SongCellRenderer/SongCellRenderer';
import { PanelCodeEnum } from 'types/Panel';
import BooleanCanadaCheckMarkCellRenderer from 'components/DetailGrid/CellRenderers/BooleanCanadaCheckMarkCellRenderer';
import { MarketChartReportTransformer } from './utils/MarketChartReportTransformer';
import { StationReportResult, TransformedMarketChartReport } from './utils/Types';
import { MarketChartFilter } from './utils/MarketChartReportInterfaces';

export interface MarketChartReportProps {
  filters?: MarketChartFilter;
}

const url = 'seven-day-market-chart-reports';

const MarketChartReport: React.FC<MarketChartReportProps> = ({ filters }) => {
  const { t } = useTranslation();
  const { settings, setSettings, data: reportData, isLoading, isError } = useRemoteApi<
    StationReportResult<TransformedMarketChartReport>
  >(url, {
    data: filters,
    method: 'POST',
    enabled: false,
    transformResponse: MarketChartReportTransformer,
  });

  const { sortModel, setSortModel: onSortChange } = useSortColumn({ sortPath: 'RankThisWeek', sort: 'asc' });

  return (
    <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
      <ServerSideReportGrid sortModel={sortModel} settings={settings} setSettings={setSettings} filters={filters}>
        <AgGridColumn
          headerName={t('grid.header.OverallRank')}
          minWidth={60}
          width={60}
          field="RankInfo"
          headerComponentParams={{
            sortModel,
            onSortChange,
            disposition: 'row',
            secondLine: [
              { label: 'grid.header.TW', sortPath: 'RankThisWeek' },
              { label: 'grid.header.LW', sortPath: 'RankLastWeek' },
            ],
          }}
          cellRendererFramework={RankInfoCellRenderer}
          headerComponentFramework={MultiColumnsHeaderComponent}
        />
        <AgGridColumn
          field="SongInfo"
          headerName={t(`grid.header.Title`)}
          minWidth={150}
          pinned
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            sortPath: 'Title',
            disposition: 'row',
            secondLine: [
              { label: 'grid.header.Artist', sortPath: 'Artist' },
              { label: 'grid.header.Label', sortPath: 'Label' },
            ],
          }}
          cellRendererFramework={SongCellRenderer}
        />
        <AgGridColumn
          field="HasCanadianContent"
          headerName="CanCon"
          minWidth={70}
          cellRendererFramework={BooleanCanadaCheckMarkCellRenderer}
          headerComponentParams={{ sortModel, onSortChange, sortPath: 'HasCanadianContent' }}
          hide={
            !(
              filters?.PanelInfo?.PanelCode === PanelCodeEnum.AllCanadianStations ||
              filters?.PanelInfo?.PanelCode === PanelCodeEnum.PublishedCanadaPanel
            )
          }
        />
        <AgGridColumn
          headerName={t(`grid.header.Year`)}
          field="ReleaseDate"
          minWidth={80}
          headerComponentParams={{ sortModel, onSortChange }}
        />
        <AgGridColumn
          field="SpinsInfo"
          minWidth={80}
          headerName={t(`grid.header.SpinsInfoTotal`)}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            disposition: 'row',
            secondLine: [
              { sortPath: 'SpinsInfo/ThisWeek', label: ['grid.header.TW'] },
              { sortPath: 'SpinsInfo/LastWeek', label: ['grid.header.LW'] },
              { sortPath: 'SpinsInfo/Move', label: ['grid.header.Move'] },
            ],
          }}
          cellRendererFramework={ThisLastMoveCellRenderer}
        />
        {(reportData?.StationColumns ?? []).map((stationColumn, index) => (
          <AgGridColumn
            key={stationColumn.StationIndex}
            headerName={stationColumn.CallLetters}
            field={`Stations[${stationColumn.StationIndex}]/SpinsCount`}
            minWidth={80}
            headerComponentParams={{
              sortModel,
              onSortChange,
            }}
            cellRendererFramework={({ data: { StationsMap } = {} }: ICellRendererParams) =>
              StationsMap ? StationsMap[stationColumn.StationIndex] ?? null : null
            }
            hide={index >= 25}
          />
        ))}
        <AgGridColumn
          headerName={t(`grid.header.On`)}
          field="StationsCount"
          minWidth={80}
          headerComponentParams={{ sortModel, onSortChange }}
        />
      </ServerSideReportGrid>
    </GridVisibilityWrapper>
  );
};

export default MarketChartReport;
