import FilterButton from 'components/FilterButton';
import ReportHeader from 'components/ReportHeader';
import { Container } from 'components/ReportHeader/styles';
import SiblingReportsSelect from 'components/SiblingReportsSelect/SiblingReportsSelect';
import TabsGrid from 'components/TabsGrid';
import TabPanel from 'components/TabsGrid/TabPanel';
import { GridContainer, ReportContainer } from 'pages/Reports/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Filter, { SongComparisonReportFilter } from './Filter';
import FormatSummaryGrid from './FormatSummaryGrid';
import OverallGrid from './OverallGrid';

const tabs = [
  {
    label: 'report.tab.overall',
    value: 'overall',
  },
  {
    label: 'report.tab.formatSummary',
    value: 'formatSummary',
  },
];

const SongComparisonReport: React.FC = () => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(0);
  const [filterOpened, setFilterOpened] = useState(false);
  const [filters, setFilters] = useState<SongComparisonReportFilter | undefined>();

  const onChangeTab = (value: number) => {
    setTabValue(value);
  };

  const onChangeFilter = (filtersParams: SongComparisonReportFilter) => {
    setFilterOpened(false);
    setFilters({ ...filtersParams });
  };

  return (
    <>
      <Container container direction="column" spacing={0}>
        <ReportHeader title="Songs" subtitle="7-Days" />
        <ReportContainer>
          <SiblingReportsSelect />
          <FilterButton onClickFilter={() => setFilterOpened(!filterOpened)} isOpened={filterOpened} />
        </ReportContainer>
        <Filter isOpened={filterOpened} onChange={onChangeFilter} />
        <GridContainer>
          <TabsGrid
            tabValue={tabValue}
            onChangeTab={onChangeTab}
            tabs={tabs.map(v => ({ label: t(v.label), value: v.value }))}
          >
            <TabPanel value={tabValue} index={0}>
              <OverallGrid filters={filters} />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <FormatSummaryGrid filters={filters} />
            </TabPanel>
          </TabsGrid>
        </GridContainer>
      </Container>
    </>
  );
};

export default SongComparisonReport;
