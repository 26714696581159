import { Grid } from '@material-ui/core';
import AccordionReports from 'components/AccordionReports';
import React, { useEffect, useState } from 'react';
import FilterDataEvent from 'types/FilterDataEvent';

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface ComponentGroupItemProps {
  label: string;
  children: ChildrenType;
  value?: any;
}

type ChildrenType = React.ReactElement<ComponentGroupItemProps>;

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props {
  title?: string;
  children: ChildrenType | ChildrenType[];
  getLabel?: (item: any) => string;
  closeOnChangeSelection?: boolean;
}
const AccordionFilter: React.FC<Props> = ({
  title = '',
  children = [],
  getLabel = null,
  closeOnChangeSelection = false,
}) => {
  const getChildren = (): ChildrenType[] => {
    return Array.isArray(children) ? (children as ChildrenType[]) : [children];
  };

  const [expanded, setExpanded] = useState(false);
  const [selectedText, setSelectedText] = useState('');

  useEffect(() => {
    if (closeOnChangeSelection) {
      setExpanded(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedText]);

  function tapOnChange(func?: (evt: FilterDataEvent<unknown>) => void): (evt: FilterDataEvent<unknown>) => void {
    return (event: FilterDataEvent<unknown>) => {
      if (func) {
        func(event);
      }
      if (getLabel) {
        const valueText = getLabel ? getLabel(event) : '';
        setSelectedText(valueText);
      }
    };
  }

  return (
    <AccordionReports
      expanded={expanded}
      onChangeAccordion={() => setExpanded(!expanded)}
      firstTitle={title}
      secondTitle={selectedText}
    >
      <Grid container direction="column">
        {getChildren().map((child: JSX.Element, index: number) => {
          return React.cloneElement(child, {
            ...child.props,
            key: child?.props?.label ?? index,
            onChange: tapOnChange(child.props.onChange),
          });
        })}
      </Grid>
    </AccordionReports>
  );
};

export default AccordionFilter;
