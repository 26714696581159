import { SongInfo } from 'types/SongInfo';
import { SONG_PORTFOLIO } from 'utils/Constants';

interface FilterProps {
  songGroup?: string;
  songInfos?: SongInfo[];
  songPortfolioSongInfos?: SongInfo[];
  songSelectionSongInfos?: SongInfo[];
}

export function songPortfolioFilterTransformer<T extends FilterProps>(filter: T): T {
  const { songGroup, songPortfolioSongInfos, songSelectionSongInfos } = filter;
  if (songGroup === SONG_PORTFOLIO) {
    return { ...filter, songInfos: songPortfolioSongInfos };
  }
  return { ...filter, songInfos: songSelectionSongInfos };
}
