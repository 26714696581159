/* eslint-disable @typescript-eslint/no-explicit-any */
//import ClassicViewLink from 'components/ClassicView/ClassicViewLink';
//import { generateCurrentDaySongsUrl } from 'components/ClassicView/ClassicViewUrlHelper';
import FilterButton from 'components/FilterButton';
import LatestRefreshDate from 'components/LatestRefreshDate';
import SiblingReportsSelect from 'components/SiblingReportsSelect/SiblingReportsSelect';
import TabPanel from 'components/TabsGrid/TabPanel';
import { merge } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReportHeader from '../../../../../components/ReportHeader';
import SelectInput from '../../../../../components/SelectInput';
import TabsGrid from '../../../../../components/TabsGrid';
import { BodyReportContainer, Container, GridContainer, ReportContainer, SongsContainer } from '../../../styles';
import DaypartSummaryGrid from './DaypartSummaryGrid';
import Filter from './Filter';
import { ExtendedRealTimeSongAnalysisFilter } from './types/RealTimeSongAnalysis';
import ViewSongHistoryGrid from './ViewSongHistoryGrid';

const tabs = [
  {
    label: 'report.tab.songAnalysis',
    value: 'songHistory',
  },
  {
    label: 'report.tab.daypartSummary',
    value: 'daypartSummary',
  },
];

const CurrentSongsReport: React.FC = () => {
  const [songs, setSongs] = useState([]);
  const [songSelected, setSongSelected] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const [filterOpened, setFilterOpened] = useState(false);
  const [filters, setFilters] = useState<ExtendedRealTimeSongAnalysisFilter>();
  const { t } = useTranslation();

  const onChangeTab = (value: number) => {
    setTabValue(value);
  };

  const onChangeFilterOpened = () => {
    setFilterOpened(!filterOpened);
  };

  const onChangeFilter = (filtersParams: any | null) => {
    setFilterOpened(false);
    if (filtersParams?.songInfos?.length) {
      setSongs(filtersParams.songInfos);
      const firstSong = filtersParams.songInfos[0];
      setSongSelected(firstSong);
      setFilters(merge({}, filtersParams, { SongInfo: firstSong }));
    } else {
      setFilters(filtersParams);
    }
  };

  const onChangeSong = (song: any) => {
    setSongSelected(song);
    setFilters(merge({}, filters, { SongInfo: song }));
  };

  //const url = generateCurrentDaySongsUrl(filters);

  return (
    <Container container direction="column" spacing={0}>
      <ReportHeader title={t('report.label.songs')} subtitle={t('report.label.dailyCurrent')} />
      <ReportContainer>
        <SiblingReportsSelect />
        <FilterButton onClickFilter={onChangeFilterOpened} isOpened={filterOpened} />
      </ReportContainer>
      <Filter isOpened={filterOpened} changeFilter={onChangeFilter} />
      <LatestRefreshDate onChange={filtersRequest => onChangeFilter(filtersRequest)} filters={filters} />
      <BodyReportContainer hidden={!filters}>
        <SongsContainer>
          <SelectInput
            normalizeLabel={option => `${option.Artist}-${option.Title}`}
            optionLabel={t('report.label.title')}
            options={songs}
            value={songSelected}
            onChangeValue={onChangeSong}
          />
        </SongsContainer>
        <GridContainer>
          <TabsGrid tabValue={tabValue} onChangeTab={onChangeTab} tabs={tabs}>
            <TabPanel value={tabValue} index={0}>
              <ViewSongHistoryGrid filters={filters} />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <DaypartSummaryGrid filters={filters} />
            </TabPanel>
          </TabsGrid>
        </GridContainer>
        {/* <ClassicViewLink url={url} /> */}
      </BodyReportContainer>
    </Container>
  );
};

export default CurrentSongsReport;
