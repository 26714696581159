import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

export const Message = styled(Typography)`
  color: red;
  font-size: 14px;
`;
