/* eslint-disable @typescript-eslint/no-explicit-any */
import FilterButton from 'components/FilterButton';
import ReportHeader from 'components/ReportHeader';
import SiblingReportsSelect from 'components/SiblingReportsSelect/SiblingReportsSelect';
import { GridContainer, ReportContainer } from 'pages/Reports/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import queryString from 'query-string';
import { Container } from './styles';
import BigPictureChartReportFilter from './Filters';
import BigPictureChartReportGrid from './BigPictureChartGrid';
import { BigPictureResponse, BigPictureFiltersProps } from './utils/BigPictureChartTypes';

const BigPictureChartReport: React.FC = () => {
  const { t } = useTranslation();
  const [filterOpened, setFilterOpened] = useState(false);
  const [filters, setFilters] = useState<BigPictureFiltersProps>();

  const onChangeFilter = (filtersParams: any | null) => {
    setFilterOpened(false);
    setFilters(filtersParams);
  };

  const onTotalSpinsClickHandler = (rowData: BigPictureResponse, gridFilters: BigPictureFiltersProps) => {
    const href = getReportSpec(ReportBaseEnum.SongAnalysisReport).path;
    const ALL_FORMAT_CODE = 'XX';
    const params = {
      ReportCycle: gridFilters.ReportCycle,
      songCode: rowData.SongCode,
      'PanelInfo.Id': gridFilters?.PanelInfo?.Id,
      'Formats.FormatCode': ALL_FORMAT_CODE,
      MinSpins: gridFilters?.MinSpins,
      deepLink: true,
    };
    window.open(`${href}?${queryString.stringify(params)}`);
  };

  return (
    <>
      <Container container direction="column" spacing={0}>
        <ReportHeader title={t('report.tab.chart')} subtitle={t('report.tab.sevenDay')} />
        <ReportContainer>
          <SiblingReportsSelect />
          <FilterButton onClickFilter={() => setFilterOpened(!filterOpened)} isOpened={filterOpened} />
        </ReportContainer>
        <BigPictureChartReportFilter isOpened={filterOpened} changeFilter={onChangeFilter} />
        <GridContainer>
          {filters && (
            <BigPictureChartReportGrid filters={filters} onTotalSpinsClickHandler={onTotalSpinsClickHandler} />
          )}
        </GridContainer>
      </Container>
    </>
  );
};

export default BigPictureChartReport;
