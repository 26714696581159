/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import FilterBase from 'components/FilterBase';
import FilterDataEvent from 'types/FilterDataEvent';
import PanelFilter from 'components/Filter/components/PanelFilter';
import FormatFilter from 'components/Filter/components/FormatFilter';
import Grc from 'components/Filter/components/Grc';
import ShowTopSelect from 'components/Filter/components/ShowTopSelect';
import { PanelCodeEnum } from 'types/Panel';
import { YearToDateSongsByFormatFilter } from './utils/YearToDateSongsByFormat';

interface FilterYearToDateSongsByFormatReport {
  changeFilter: { (filters: YearToDateSongsByFormatFilter): any };
  isOpened: boolean;
}

const Filter: React.FC<FilterYearToDateSongsByFormatReport> = ({ changeFilter, isOpened }) => {
  const [filter, setFilter] = useState({} as YearToDateSongsByFormatFilter);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleFilterChange = (event: FilterDataEvent<any | YearToDateSongsByFormatFilter>) => {
    const { value } = event;
    setFilter({ ...filter, ...value });
  };

  return (
    <FilterBase onSubmit={() => changeFilter(filter)} isOpened={isOpened}>
      <PanelFilter onChange={handleFilterChange} />
      <FormatFilter onChange={handleFilterChange} panelCode={PanelCodeEnum.PublishedPanel} />
      <Grc onChange={handleFilterChange} />
      <ShowTopSelect onChange={handleFilterChange} />
    </FilterBase>
  );
};

export default Filter;
