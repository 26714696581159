/* eslint-disable react/require-default-props */
import { Column, ColumnApi, GridApi, RowNode } from '@ag-grid-enterprise/all-modules';
import { Divider, Grid } from '@material-ui/core';
import AvgPlayInnerTable from 'components/InnerTable/AvgStationRotationsInnerTable';
import DailyInnerTable from 'components/InnerTable/DailyInnerTable';
import DayPartImpressionInnerTable from 'components/InnerTable/DayPartImpressionsInnerTable';
import DayPartsWithPercentageInnerTable from 'components/InnerTable/DayPartsWithPercentageInnerTable';
import DynamicHeaderInnerTable from 'components/InnerTable/DynamicHeaderInnerTable';
import FormatByFormatRankInnerTable from 'components/InnerTable/FormatByFormatRank/FormatByFormatRank';
import HourDataItemInnerTable from 'components/InnerTable/HourDataItemInnerTable';
import ImpressionInnerTable from 'components/InnerTable/ImpressionsInnerTable';
import LastMonthInnerTable from 'components/InnerTable/LastMonthInnerTable';
import LastWeekTrendsInnerTable from 'components/InnerTable/LastWeekTrendsTable';
import PeakInfoInnerTable from 'components/InnerTable/PeakInfoInnerTable';
import PlaysInnerTable from 'components/InnerTable/PlaysInnerTable';
import SongInfoInnerTable from 'components/InnerTable/SongInfoInnerTable';
import SongPlaysImpressionInnerTable from 'components/InnerTable/SongPlaysImpressionInnerTable/SongPlaysImpressionInnerTable';
import StationFormatComparisonInfoInnerTable from 'components/InnerTable/StationFormatComparisonInfoInnerTable';
import StationsInfoInnerTable from 'components/InnerTable/StationInfoInnerTable';
import WoOvnInnerTable from 'components/InnerTable/WithoutOvernightInnerTable';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import HistoricalInfoCustomInnerTable from 'components/InnerTable/HistoricalInfoCustomInnerTable';
import DayByDaySpinsDailyInnerTable from '../InnerTable/DayByDaySpinsDailyInnerTable';
import DayPartsInnerTable from '../InnerTable/DayPartsInnerTable';
import NotGroupedInnerTable from '../InnerTable/NotGroupedInnerTable';
import PointsInfoInnerTable from '../InnerTable/PointsInfoInnerTable';
import SpinsDifferentialInnerTable from '../InnerTable/SpinsDifferentialInnerTable';
import SpinsInfoInnerTable from '../InnerTable/SpinsInfoInnerTable';
import TwelveWeekInnerTable from '../InnerTable/TwelveWeekInnerTable';
import DynamicDataInnerTable from '../InnerTable/DynamicDataInnerTable';
import WeeklyImpressionInnerTable from '../InnerTable/WeeklyInnerTable';
import DayByDaySpinsInnerTable from '../InnerTable/DayByDaySpinsInnerTable';
import DayByDaySpinsCurrentInnerTable from '../InnerTable/DayByDaySpinsCurrentInnerTable';

const groupInnerTableTwelveWeek = () => {
  const arrayProperties = ['RankTrends', 'SpinTrends', 'ImpressionsTrends'];
  return new Map(
    arrayProperties.map(prop => {
      return [
        prop,
        (params: InnerTableFactoryParams) => (
          <TwelveWeekInnerTable
            field={prop}
            key={uuidv4()}
            data={params.data}
            name="grid.header.twelveWeekRankTrends"
          />
        ),
      ];
    }),
  );
};

const groupInnerTableDynamicHeader = () => {
  const arrayProperties = [
    { label: 'grid.header.StationPlaying', field: 'StationPlaying' },
    { label: 'grid.header.ImpressionsInfo', field: 'ImpressionsTotalInfo' },
    { label: 'grid.header.Impressions', field: 'ImpressionsMill' },
    { label: 'grid.header.NotPlaying', field: 'NotPlaying' },
    { label: 'grid.header.DayByDayRank', field: 'DayByDayRanksFlat' },
    { label: 'grid.header.ThisWeekDayByDayPlays', field: 'DayByDaySpinsFlat' },
    { label: 'grid.header.historicalData.HistoricalData', field: 'Historical' },
    { label: 'grid.header.FormatComparison', field: 'FormatComparisonInfo', translateHeader: true },
    { label: 'grid.header.MarketInfo', field: 'MarketInfo', translateHeader: true },
    { label: 'grid.header.Impressions', field: 'ImpressionCustomInfo', translateHeader: true },
    { label: 'grid.header.HistoricalData', field: 'HistoricalInfo', translateHeader: true },
    { label: 'grid.header.H6amTo7pm', field: 'AmToPmInfo', translateHeader: true },
    { label: 'grid.header.AvgStationRotations', field: 'AverageStationRotation', translateHeader: true },
    { label: 'grid.header.SpinsGrid.Nightime', field: 'NightTimeInfo', translateHeader: true },
    { label: 'grid.header.SpinsGrid.PmDrive', field: 'PmDriveInfo', translateHeader: true },
    { label: 'grid.header.SpinsGrid.Midday', field: 'MidDayInfo', translateHeader: true },
    { label: 'grid.header.SpinsGrid.AmDrive', field: 'AmDriveInfo', translateHeader: true },
    { label: 'grid.header.SpinsGrid.Overnight', field: 'OverNightInfo', translateHeader: true },
  ];
  return new Map(
    arrayProperties.map(prop => {
      return [
        prop.field,
        (params: InnerTableFactoryParams) => (
          <DynamicHeaderInnerTable
            field={prop.field}
            key={uuidv4()}
            invisibleColumns={params.invisibleColumns}
            data={params.data}
            title={prop.label}
            columnApi={params.columnApi}
            translateHeader={prop?.translateHeader}
          />
        ),
      ];
    }),
  );
};

const ComponentMap = new Map([
  [
    'NotGrouped',
    (params: InnerTableFactoryParams) => (
      <NotGroupedInnerTable key={uuidv4()} columns={params.invisibleColumns} data={params.data} />
    ),
  ],
  [
    'HistoricalInfoCustom',
    (params: InnerTableFactoryParams) => (
      <HistoricalInfoCustomInnerTable name="grid.header.History" key={uuidv4()} data={params.data} />
    ),
  ],
  [
    'ThreeWeekTrend',
    (params: InnerTableFactoryParams) => (
      <DynamicDataInnerTable
        key={uuidv4()}
        data={params.data}
        tableHeader="grid.header.SpinTrends"
        fieldHeaders={[
          'grid.header.totalPlays.Week1',
          'grid.header.totalPlays.Week2',
          'grid.header.totalPlays.Move',
          'grid.header.totalPlays.Week3',
        ]}
        fieldNames={['SpinTrends.Week1', 'SpinTrends.Week2', 'SpinTrends.Move', 'SpinTrends.Week3']}
      />
    ),
  ],
  [
    'DayByDaySpins',
    (params: InnerTableFactoryParams) => (
      <DayByDaySpinsInnerTable
        key={uuidv4()}
        data={params.data}
        showDaysAsNumber={false}
        reportMetadata={params.reportMetadata}
      />
    ),
  ],
  [
    'DayByDaySpinsBuilding',
    (params: InnerTableFactoryParams) => (
      <DayByDaySpinsInnerTable
        key={uuidv4()}
        data={params.data}
        showDaysAsNumber
        reportMetadata={params.reportMetadata}
      />
    ),
  ],
  [
    'DayByDaySpinsSevenDay',
    (params: InnerTableFactoryParams) => <DayByDaySpinsInnerTable key={uuidv4()} data={params.data} showDaysAsNumber />,
  ],
  [
    'DayByDaySpinsDaily',
    (params: InnerTableFactoryParams) => (
      <DayByDaySpinsDailyInnerTable
        name="grid.header.DayByDaySpins"
        key={uuidv4()}
        data={params.data.DayByDaySpinsDaily}
      />
    ),
  ],
  [
    'DayByDaySpinsCurrent',
    (params: InnerTableFactoryParams) => (
      <DayByDaySpinsCurrentInnerTable key={uuidv4()} data={params.data} reportMetadata={params.reportMetadata} />
    ),
  ],
  [
    'SpinsInfo',
    (params: InnerTableFactoryParams) => <SpinsInfoInnerTable key={uuidv4()} data={params.data} propKey="SpinsInfo" />,
  ],
  [
    'SpinsInfoOnly',
    (params: InnerTableFactoryParams) => (
      <SpinsInfoInnerTable key={uuidv4()} data={params.data} propKey="SpinsInfo" showMove={false} />
    ),
  ],
  [
    'FormatSpins',
    (params: InnerTableFactoryParams) => (
      <SpinsInfoInnerTable key={uuidv4()} data={params.data} propKey="FormatSpins" showMove={false} />
    ),
  ],
  [
    'StationSpins',
    (params: InnerTableFactoryParams) => (
      <SpinsInfoInnerTable key={uuidv4()} data={params.data} propKey="StationSpins" showMove={false} />
    ),
  ],
  [
    'SpinDifferential',
    (params: InnerTableFactoryParams) => (
      <SpinsDifferentialInnerTable name="grid.header.Spins" key={uuidv4()} data={params.data} />
    ),
  ],
  [
    'PointsInfo',
    (params: InnerTableFactoryParams) => (
      <PointsInfoInnerTable name="grid.header.PointsInfo" fieldName="PointsInfo" key={uuidv4()} data={params.data} />
    ),
  ],
  [
    'ImpressionsTotal',
    (params: InnerTableFactoryParams) => (
      <PointsInfoInnerTable
        name="grid.header.ImpressionsInfo"
        fieldName="ImpressionsInfo"
        key={uuidv4()}
        data={params.data}
      />
    ),
  ],
  [
    'LastMonthInfo',
    (params: InnerTableFactoryParams) => (
      <LastMonthInnerTable key={uuidv4()} invisibleColumns={params.invisibleColumns} data={params.data} />
    ),
  ],
  ['DaypartsInfo', (params: InnerTableFactoryParams) => <DayPartsInnerTable key={uuidv4()} data={params.data} />],
  [
    'DailyImpressionsTrends',
    (params: InnerTableFactoryParams) => (
      <DailyInnerTable
        column={params.invisibleColumns}
        field="DailyImpressionsTrends"
        key={uuidv4()}
        data={params.data}
        name="grid.header.DailyImpressionsTrends"
      />
    ),
  ],
  [
    'DailySpinTrends',
    (params: InnerTableFactoryParams) => (
      <DailyInnerTable field="DailySpinTrends" key={uuidv4()} data={params.data} name="grid.header.DailySpinTrends" />
    ),
  ],
  [
    'WeeklyImpressionsTrends',
    (params: InnerTableFactoryParams) => (
      <WeeklyImpressionInnerTable
        field="WeeklyImpressionsTrends"
        columns={params.invisibleColumns}
        key={uuidv4()}
        data={params.data}
        name="grid.header.WeeklyImpressionsTrends"
      />
    ),
  ],
  [
    'WeeklySpinTrends',
    (params: InnerTableFactoryParams) => (
      <WeeklyImpressionInnerTable
        field="WeeklySpinTrends"
        key={uuidv4()}
        data={params.data}
        name="grid.header.WeeklySpinTrends"
      />
    ),
  ],
  [
    'DaypartImpressions',
    (params: InnerTableFactoryParams) => (
      <DayPartImpressionInnerTable
        field="DaypartImpressions"
        key={uuidv4()}
        data={params.data}
        name="grid.header.DaypartImpressions"
        columns={params.invisibleColumns}
      />
    ),
  ],
  [
    'DaypartSpins',
    (params: InnerTableFactoryParams) => (
      <DayPartImpressionInnerTable
        field="DaypartSpins"
        key={uuidv4()}
        data={params.data}
        name="grid.header.DaypartSpins"
        columns={params.invisibleColumns}
      />
    ),
  ],
  [
    'FirstSongPlays',
    (params: InnerTableFactoryParams) => (
      <SongPlaysImpressionInnerTable
        field="FirstSongInfo"
        playsField="FirstSongPlays"
        impressionsField="FirstSongImpressionsInfo.SpinsThisWeek"
        key={uuidv4()}
        data={params.data}
        columns={params.invisibleColumns}
      />
    ),
  ],
  [
    'SecondSongPlays',
    (params: InnerTableFactoryParams) => (
      <SongPlaysImpressionInnerTable
        field="SecondSongInfo"
        playsField="SecondSongPlays"
        impressionsField="SecondSongImpressionsInfo.SpinsThisWeek"
        key={uuidv4()}
        data={params.data}
        columns={params.invisibleColumns}
      />
    ),
  ],
  [
    'StationsInfo',
    (params: InnerTableFactoryParams) => (
      <StationsInfoInnerTable field="StationsInfo" key={uuidv4()} data={params.data} name="grid.header.Stations" />
    ),
  ],
  [
    'AveragePlay',
    (params: InnerTableFactoryParams) => (
      <AvgPlayInnerTable
        fieldName="AveragePlay"
        key={uuidv4()}
        data={params.data}
        name="grid.header.AvgStationRotations"
        columnApi={params.columnApi}
      />
    ),
  ],
  [
    'WithoutOvernight',
    (params: InnerTableFactoryParams) => (
      <WoOvnInnerTable
        fieldName="WithoutOvernight"
        key={uuidv4()}
        data={params.data}
        name="grid.header.WithoutOvernightOVN"
      />
    ),
  ],
  [
    'SongInfo',
    (params: InnerTableFactoryParams) => <SongInfoInnerTable fieldName="SongInfo" key={uuidv4()} data={params.data} />,
  ],
  [
    'PeakInfo',
    (params: InnerTableFactoryParams) => (
      <PeakInfoInnerTable name="grid.header.PeakRank" key={uuidv4()} data={params.data} />
    ),
  ],
  [
    'DaypartsPercentageInfo',
    (params: InnerTableFactoryParams) => (
      <DayPartsWithPercentageInnerTable
        title="grid.header.AirPlayInformation"
        field="DaypartsPercentageInfo"
        totalField="TotalSpinsTw"
        invisibleColumns={params.invisibleColumns}
        key={uuidv4()}
        data={params.data}
      />
    ),
  ],
  [
    'DaypartsInfoToDate',
    (params: InnerTableFactoryParams) => (
      <DayPartsWithPercentageInnerTable
        title="grid.header.HistoricalInformation"
        field="DaypartsInfoToDate"
        totalField="SpinsToDate"
        invisibleColumns={params.invisibleColumns}
        key={uuidv4()}
        data={params.data}
      />
    ),
  ],
  [
    'SpinsWithinDateRange',
    (params: InnerTableFactoryParams) => <SpinsInfoInnerTable key={uuidv4()} data={params.data} />,
  ],
  [
    'Impressions',
    (params: InnerTableFactoryParams) => (
      <ImpressionInnerTable
        name="grid.header.ImpressionsInfo"
        colId="Impressions"
        fieldName="Impressions"
        key={uuidv4()}
        data={params.data}
        columnApi={params.columnApi}
        showRanking
      />
    ),
  ],
  [
    'ImpressionsInfo',
    (params: InnerTableFactoryParams) => (
      <ImpressionInnerTable
        name="grid.header.ImpressionsInfo"
        colId="ImpressionsInfo"
        fieldName="ImpressionsInfo"
        key={uuidv4()}
        data={params.data}
        columnApi={params.columnApi}
        showRanking
      />
    ),
  ],
  [
    'ImpressionsInfoForChart',
    (params: InnerTableFactoryParams) => (
      <ImpressionInnerTable
        name="grid.header.ImpressionsInfo"
        colId="ImpressionsInfo"
        fieldName="ImpressionsInfo"
        key={uuidv4()}
        data={params.data}
        columnApi={params.columnApi}
        showRanking
        reportQuery={params.reportQuery}
      />
    ),
  ],
  [
    'ImpressionsInfoSpinsOnly',
    (params: InnerTableFactoryParams) => (
      <ImpressionInnerTable
        name="grid.header.ImpressionsInfo"
        colId="ImpressionsInfoSpinsOnly"
        fieldName="ImpressionsInfo"
        key={uuidv4()}
        data={params.data}
        columnApi={params.columnApi}
        showRanking={false}
      />
    ),
  ],
  [
    'DayByDaySpinsDynamic',
    (params: InnerTableFactoryParams) => (
      <DynamicHeaderInnerTable
        title="grid.header.DayByDaySpins"
        field="DayByDaySpinsFlat"
        invisibleColumns={params.invisibleColumns}
        key={uuidv4()}
        data={params.data}
        columnApi={params.columnApi}
        colId="DayByDaySpinsDynamic"
        translateHeader
      />
    ),
  ],
  [
    'DayByDayImpressionsDynamic',
    (params: InnerTableFactoryParams) => (
      <DynamicHeaderInnerTable
        title="grid.header.DayByDayImpressions"
        field="DayByDayImpressionsFlat"
        invisibleColumns={params.invisibleColumns}
        key={uuidv4()}
        data={params.data}
        columnApi={params.columnApi}
        colId="DayByDayImpressionsDynamic"
        translateHeader
      />
    ),
  ],
  [
    'FormatByFormatRanks',
    (params: InnerTableFactoryParams) => (
      <FormatByFormatRankInnerTable
        columns={params.invisibleColumns}
        field="FormatByFormatRanks"
        key={uuidv4()}
        data={params.data}
      />
    ),
  ],
  [
    'LastWeekTrends',
    (params: InnerTableFactoryParams) => (
      <LastWeekTrendsInnerTable key={uuidv4()} invisibleColumns={params.invisibleColumns} data={params.data} />
    ),
  ],
  [
    'Plays',
    (params: InnerTableFactoryParams) => (
      <PlaysInnerTable key={uuidv4()} invisibleColumns={params.invisibleColumns} data={params.data} />
    ),
  ],
  [
    'HourDataItem',
    (params: InnerTableFactoryParams) => (
      <HourDataItemInnerTable key={uuidv4()} invisibleColumns={params.invisibleColumns} data={params.data} />
    ),
  ],
  [
    'StationFormatComparisonInfo',
    (params: InnerTableFactoryParams) => (
      <StationFormatComparisonInfoInnerTable
        name="grid.header.FormatComparison"
        key={uuidv4()}
        data={params.data}
        reportQuery={params.reportQuery}
      />
    ),
  ],
  ...groupInnerTableTwelveWeek(),
  ...groupInnerTableDynamicHeader(),
]);

/* eslint-disable @typescript-eslint/no-explicit-any */
interface InnerTableFactoryParams {
  invisibleColumns: Column[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  gridApi: GridApi;
  columnApi: ColumnApi;
  node: RowNode;
  reportQuery?: any;
  reportMetadata?: any;
}

const InnerTableFactory = ({
  invisibleColumns,
  data,
  gridApi,
  columnApi,
  node,
  reportQuery,
  reportMetadata,
}: InnerTableFactoryParams): JSX.Element => {
  const specialElementName = (groupId: string): string => {
    return Array.from(ComponentMap.keys()).filter(n => groupId === n)[0] ?? 'NotGrouped';
  };

  const splitIntoGroups = (cols: Column[]) => {
    const map: Map<string, Column[]> = new Map();
    cols.forEach(col => {
      if (!col?.getColDef()?.hide) {
        const colId = col.getColId();
        const groupName = specialElementName(colId.split('.')[0]);
        const values = map.get(groupName) ?? [];
        values.push(col);
        map.set(groupName, values);
      }
    });

    return map;
  };

  const groupedColumns = splitIntoGroups(invisibleColumns);
  const components = Array.from(groupedColumns.keys())
    .map(key => {
      const values = groupedColumns.get(key) ?? [];
      const componentName = ComponentMap.get(key);
      return componentName
        ? componentName({
            invisibleColumns: values,
            data,
            gridApi,
            columnApi,
            // this is never null, because this is always under the context of a detail in master detail
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            node: node.parent!,
            reportQuery,
            reportMetadata,
          } as InnerTableFactoryParams)
        : null;
    })
    .filter(v => v !== null);

  return (
    <>
      {components.map(c => {
        return (
          <Grid item key={uuidv4()} xs>
            <div>{c}</div>
            <Divider />
          </Grid>
        );
      })}
    </>
  );
};

export default InnerTableFactory;
