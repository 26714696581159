import { set, values } from 'lodash';
import { useReducer } from 'react';

// eslint-disable-next-line no-shadow
export enum FilterState {
  VALID,
  INVALID,
}
export interface FilterStateMap {
  stateMap: Map<string, FilterState>;
  isReady: boolean;
}

export interface UseFilterStateReturn extends FilterStateMap {
  changeState: (key: string, state: FilterState) => void;
}

function filterStateReducer(map: FilterStateMap, { key, state }: { key: string; state: FilterState }): FilterStateMap {
  set(map.stateMap, key, state);

  const vs = (values(map.stateMap) as unknown) as FilterState[];
  return {
    stateMap: map.stateMap,
    isReady: !vs?.length || vs.every(v => v === FilterState.VALID),
  };
}

function useFilterState(): UseFilterStateReturn {
  const [actions, dispatch] = useReducer(filterStateReducer, {
    stateMap: new Map<string, FilterState>(),
    isReady: true,
  } as FilterStateMap);

  return {
    stateMap: actions.stateMap,
    isReady: actions.isReady,
    changeState: (key: string, state: FilterState) => {
      dispatch({ key, state });
    },
  };
}

export default useFilterState;
