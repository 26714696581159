/* eslint-disable no-shadow */
export enum DailyCurrentChartUrl {
  realTimeCurrentChartReports = 'real-time-current-charts-reports',
  dailyMusicChartReports = 'daily-music-charts-reports',
}

export enum SevenDayChartUrl {
  publishedChartReports = 'published-chart-reports',
  stationPlaylistNotPlayingReports = 'stations-playlist-not-playing-reports',
  stationPlaylistHistoricalReports = 'stations-playlist-historical-reports',
  songAnalysisHistoryReports = 'seven-day-song-analysis-song-history-reports',
  bigPictureChartReports = 'seven-day-big-picture-chart-reports',
  bigPictureWithHistoryChartReports = 'seven-day-big-picture-chart-history-reports',
  songRankLeadersReports = 'song-rank-leader-reports',
  sevenDayAfterMidniteChartReports = 'seven-day-after-midnite-chart-reports',
}

export enum SevenDaySongsUrl {
  regionsMarketsSongTrackingReports = 'regions-markets-song-tracking-reports',
}
