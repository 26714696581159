import { Routes } from 'types/Routes';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';

export const menuEntries: Routes[] = [
  {
    path: getReportSpec(ReportBaseEnum.SongAnalysisReport).path,
    component: getReportSpec(ReportBaseEnum.SongAnalysisReport).component,
  },
  {
    path: getReportSpec(ReportBaseEnum.SongsRegionsMarketsTrendingAnalysis).path,
    component: getReportSpec(ReportBaseEnum.SongsRegionsMarketsTrendingAnalysis).component,
  },
  {
    path: getReportSpec(ReportBaseEnum.SongsRegionsMarketsTrendingAnalysisByStation).path,
    component: getReportSpec(ReportBaseEnum.SongsRegionsMarketsTrendingAnalysisByStation).component,
  },
  {
    path: getReportSpec(ReportBaseEnum.SongsRegionsMarketsSongTracking).path,
    component: getReportSpec(ReportBaseEnum.SongsRegionsMarketsSongTracking).component,
  },
  {
    path: getReportSpec(ReportBaseEnum.SongComparisonReport).path,
    component: getReportSpec(ReportBaseEnum.SongComparisonReport).component,
  },
  {
    path: getReportSpec(ReportBaseEnum.SongArtistAnalysisReport).path,
    component: getReportSpec(ReportBaseEnum.SongArtistAnalysisReport).component,
  },
];
