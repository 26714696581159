import React from 'react';
import { useTranslation } from 'react-i18next';
import PointsInfo from 'types/PointsInfo';
import { v4 } from 'uuid';
import InnerTable from '..';
import defaultTheme from '../../../themes';
import { IInnerTableGroup } from '../inner-table-group';

export interface PointsInfoGroupData {
  PointsInfo?: PointsInfo;
  ImpressionsInfo?: PointsInfo;
}

interface SpinsInfoGroupProps {
  data: PointsInfoGroupData;
  fieldName: string;
  name: string;
}

const PointsInfoInnerTable: React.FC<SpinsInfoGroupProps> = ({ data, fieldName, name }: SpinsInfoGroupProps) => {
  const { t } = useTranslation();
  const getColor = (baseValue: number, actualValue: number) => {
    if (baseValue > actualValue) return defaultTheme.palette.error.main;
    if (baseValue < actualValue) return defaultTheme.palette.success.main;
    return defaultTheme.palette.text.primary;
  };

  const createSpan = (baseValue: number, actualValue: number) => {
    return <span style={{ color: getColor(baseValue, actualValue) }}>{actualValue}</span>;
  };

  const innerTableData: IInnerTableGroup[] = [
    {
      title: t(name),
      children: [
        {
          title: '',
          children: ['TW', 'LW', '+/-'],
        },
        {
          title: '',
          children: [
            createSpan(
              (data[fieldName as keyof PointsInfoGroupData] as PointsInfo).LastWeek,
              (data[fieldName as keyof PointsInfoGroupData] as PointsInfo).ThisWeek,
            ),
            createSpan(
              (data[fieldName as keyof PointsInfoGroupData] as PointsInfo).ThisWeek,
              (data[fieldName as keyof PointsInfoGroupData] as PointsInfo).LastWeek,
            ),
            createSpan(0, (data[fieldName as keyof PointsInfoGroupData] as PointsInfo).Move),
          ],
        },
      ],
    },
  ];

  return <InnerTable key={v4()} data={innerTableData} />;
};

export default PointsInfoInnerTable;
