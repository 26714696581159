import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      width: '100%',
      background: 'white',
      border: 'none',
      color: theme.palette.secondary.main,
      paddingLeft: '24px',
      paddingRight: '24px',
      paddingTop: '14px',
      paddingBottom: '15px',
      '&:before': {
        borderBottom: `1px solid #f0f9f9`,
      },
      '&:active': {
        border: 'none',
      },
      '&:hover': {
        border: 'none',
      },
      '&:focus': {
        border: 'none',
        borderRadius: '4px',
        background: 'white',
      },
    },
    icon: {
      color: theme.palette.primary.main,
      right: 12,
      position: 'absolute',
      userSelect: 'none',
      pointerEvents: 'none',
    },
    textSelect: {
      marginRight: '35px',
    },
  }),
);
export default useStyles;
