import React from 'react';
import { useTranslation } from 'react-i18next';
import { StationsInfo } from 'types/StationInfo';
import { v4 } from 'uuid';
import InnerTable from '../index';
import { IInnerTableGroup } from '../inner-table-group';

export interface StationsInfoType {
  StationsInfo?: StationsInfo;
}

interface StationInfoTableProps {
  data: StationsInfoType;
  name: string;
  field: string;
}

const StationInfoInnerTable: React.FC<StationInfoTableProps> = ({ data, name, field }: StationInfoTableProps) => {
  const { t } = useTranslation();
  const innerTableData: IInnerTableGroup[] = [
    {
      title: t(name),
      children: [
        {
          title: '',
          children: [t('grid.header.On'), t('grid.header.New')],
        },
        {
          title: '',
          children: [
            (data[field as keyof StationsInfoType] as StationsInfo).StationsPlayingTitle as string,
            (data[field as keyof StationsInfoType] as StationsInfo).OnNew as string,
          ],
        },
      ],
    },
  ];

  return <InnerTable key={v4()} data={innerTableData} />;
};

export default StationInfoInnerTable;
