import CheckIcon from '@material-ui/icons/Check';
import React from 'react';
import defaultTheme from '../../../themes';

interface BooleanCheckMarkCellRendererProps {
  value?: boolean;
}

const BooleanCheckMarkCellRenderer: React.FC<BooleanCheckMarkCellRendererProps> = ({
  value,
}: BooleanCheckMarkCellRendererProps) => {
  const getIcon = () => {
    let color = defaultTheme.palette.error.main;

    if (value) {
      color = defaultTheme.palette.success.main;
      return <CheckIcon aria-label="Yes" style={{ color }} />;
    }
    return <></>;
  };

  return <div style={{ width: '100%' }}>{getIcon()}</div>;
};

BooleanCheckMarkCellRenderer.defaultProps = { value: false };
export default BooleanCheckMarkCellRenderer;
