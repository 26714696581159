/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import NavigationMenu from 'contexts/NavigationMenuContext';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import { FilterState } from 'hooks/useFilterState/useFilterState';
import { noop } from 'lodash';
import React, { ReactElement, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import FilterDataProps from 'types/FilterDataProps';
import { PanelInfo } from 'types/Panel';
import GenericSelect from '../GenericSelect';

export interface PanelFilterData {
  PanelInfo: PanelInfo | undefined;
  isPublished?: boolean;
}

interface PanelFilterProps<T extends PanelFilterData> extends FilterDataProps<T> {
  changeState?: (key: string, state: FilterState) => void;
  filterKey?: string;
  required?: boolean;
}

const url = `/panels`;

const PanelFilter = <T extends PanelFilterData>({
  initialValue,
  onChange = noop,
  isPublished,
  changeState = noop,
  filterKey = 'PanelInfo',
  required = false,
}: PanelFilterProps<T>): ReactElement => {
  const { pathname } = useLocation();
  const [panelInfo, setPanelInfo] = useState<PanelInfo>(initialValue);
  const [panelItems, setPanelItems] = useState<Array<PanelInfo>>([]);

  const { currentNavigationMenu } = useContext(NavigationMenu);
  const { data: reportData } = useRemoteApi<Array<PanelInfo>>(url, {
    params: { reportType: currentNavigationMenu?.ReportType },
    method: 'GET',
    enabled: pathname === currentNavigationMenu?.Url,
  });

  const handleChange = (event: { value: unknown }) => {
    let panel = panelItems[0];
    if (event != null) {
      const value = event.value as PanelInfo;
      panel = panelItems.filter(item => item.PanelCode === value?.PanelCode)[0];
    }
    updateState(panel);
  };

  const normalizeReportData = (data: PanelInfo[] | undefined) => {
    return isPublished && data ? data?.filter((item: any) => item.IsPublished) : data || [];
  };

  const updateState = (value: PanelInfo) => {
    setPanelInfo(value);
    onChange({ value: { PanelInfo: value } as T });
  };

  const getDefaultPanel = (data: Array<PanelInfo>) => {
    let defaultPanel = data[0];
    if (panelInfo != null && data.some(item => item.PanelCode === panelInfo.PanelCode)) {
      defaultPanel = panelInfo;
    }
    return defaultPanel;
  };

  useEffect(() => {
    if (reportData?.length) {
      const data = normalizeReportData(reportData);
      setPanelItems(data);

      const defaultPanel = getDefaultPanel(data);
      updateState(defaultPanel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData, isPublished]);

  return (
    <GenericSelect
      title="Panel:"
      data={panelItems}
      onChange={handleChange}
      initialValue={panelInfo}
      changeState={(state: FilterState) => changeState(filterKey, state)}
      required={required}
    />
  );
};

export default PanelFilter;
