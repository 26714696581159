import { AxiosError } from 'axios';
import AppSettings from 'contexts/AppSettingsContext';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { QueryClient, useQuery } from 'react-query';
import AppProblem from 'types/AppProblem';
import { useAppApi } from 'services/api';
import { v4 as uuidv4 } from 'uuid';
import ApiStatus from '../ApiStatus';
import '../ClassicView.css';
import { ClassicStation } from '../ClassicTypes';
import { format } from 'date-fns';
import {
  StationCurrentDailyReport,
  StationCurrentDailyRow,
} from 'pages/Reports/DailyCurrent/StationMonitor/CurrentDayMonitorsReport/utils/CurrentDayMonitorTypes';
import ClassicViewHeader from '../ClassicViewHeader/ClassicViewHeader';
import { formatDateTypes } from 'utils/dates';

type ClassicViewCurrentDayMonitorsDailyQuery = {
  StationInfo: ClassicStation;
  LastRefresh: Date;
};

type ClassicViewCurrentDayMonitorsDailyTableProps = {
  reportQuery: ClassicViewCurrentDayMonitorsDailyQuery;
};

const useGetReportData = (reportQuery: ClassicViewCurrentDayMonitorsDailyQuery) => {
  const queryClient = new QueryClient();
  const api = useAppApi();
  const url = '/station-current-day-daily-reports';
  const cacheKey = 'station-current-day-daily-reports';
  return useQuery<StationCurrentDailyReport, AxiosError<AppProblem>>(cacheKey, () => {
    return api.post(`${url}?$orderby=TimePlayed desc`, reportQuery).then(resp => {
      queryClient.invalidateQueries(cacheKey);
      return resp.data;
    });
  });
};

const ClassicViewCurrentDayMonitorsDailyTable = (args: ClassicViewCurrentDayMonitorsDailyTableProps) => {
  const { t } = useTranslation();
  const { data, status, isSuccess } = useGetReportData(args.reportQuery);
  if (!isSuccess) return <ApiStatus status={status} />;

  return (
    <>
      <table className={`classic-table`}>
        <thead>
          <tr>
            <th>{t(`grid.header.TimePlayed`)}</th>
            <th>{t(`grid.header.Artist`)}</th>
            <th>{t(`grid.header.Title`)}</th>
            <th>{t(`grid.header.ReleaseYear`)}</th>
            <th>{t(`grid.header.Grc`)}</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.Items.map((item: StationCurrentDailyRow) => (
              <tr key={uuidv4()}>
                <td>{format(new Date(item.TimePlayed), 'MM/dd/yyyy hh:mm a')}</td>
                <td>{item.Artist}</td>
                <td>{item.Title}</td>
                <td>{item.ReleaseYear}</td>
                <td>{item.GrcCode}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

type ReportDescriptionProps = {
  reportQuery: ClassicViewCurrentDayMonitorsDailyQuery;
};

const ClassicViewReportDescriptions = (args: ReportDescriptionProps) => {
  return (
    <>
      <div>{`Station: ${args.reportQuery.StationInfo?.Name}`}</div>
    </>
  );
};

const ClassicViewCurrentDayMonitorsDaily: React.FC = () => {
  const [asOfNowValue, setAsOfNowValue] = useState(new Date());
  const { reportRefreshTimeout } = useContext(AppSettings);

  useEffect(() => {
    const interval = setInterval(() => {
      setAsOfNowValue(new Date());
    }, reportRefreshTimeout);

    return () => {
      clearInterval(interval);
    };
  }, [reportRefreshTimeout]);

  const sp = new URLSearchParams(window.location.search);

  const station = sp.get('station');
  if (!station) return <div>No station info provided</div>;

  const query: ClassicViewCurrentDayMonitorsDailyQuery = {
    StationInfo: { Name: station },
    LastRefresh: asOfNowValue,
  };

  return (
    <>
      <ClassicViewHeader />
      <div className={`classic-view-report-header`}>
        <h1>Realtime Monitors - Daily</h1>
        <div>{`As of: ${format(asOfNowValue, formatDateTypes.MMddyyyyhhmma)}`}</div>
      </div>
      <div className={`classic-view-report-descriptions`}>
        <ClassicViewReportDescriptions reportQuery={query} />
      </div>
      <div className={`classic-view-report-data`}>
        <ClassicViewCurrentDayMonitorsDailyTable reportQuery={query} />
      </div>
    </>
  );
};

export default ClassicViewCurrentDayMonitorsDaily;
