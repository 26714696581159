import AppSettings from 'contexts/AppSettingsContext';
import { useContext, useEffect, useState } from 'react';

export interface RefreshIntervalResult {
  interval?: number;
  lastRefreshTimestamp?: number;
}

function useRefreshInterval(): RefreshIntervalResult {
  const { reportRefreshTimeout } = useContext(AppSettings);

  const [refresh, setRefresh] = useState<number | undefined>();
  const [intervalRef, setIntervalRef] = useState<number | undefined>();

  useEffect(() => {
    const i = setInterval(() => {
      const date = new Date();
      setRefresh(date.getTime());
    }, reportRefreshTimeout);
    setIntervalRef(i);
    return () => clearInterval(i);
  }, [reportRefreshTimeout]);

  return {
    lastRefreshTimestamp: refresh,
    interval: intervalRef,
  };
}

export default useRefreshInterval;
