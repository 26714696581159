/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import FilterButton from 'components/FilterButton';
import ReportHeader from 'components/ReportHeader';
import SiblingReportsSelect from 'components/SiblingReportsSelect/SiblingReportsSelect';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import FilterDataEvent from 'types/FilterDataEvent';
import { Container, GridContainer, ReportContainer } from './styles';
import FilterYearToDateSongsByFormat from './YearToDateSongsByFormatReport/Filter';
import YearToDateSongsByFormatReport from './YearToDateSongsByFormatReport/YearToDateSongsByFormatReport';

const YearToDateFormats: React.FC = () => {
  const { pathname } = useLocation();
  const [filterOpened, setFilterOpened] = useState(false);
  // eslint-disable-next-line @typescript-eslint/ban-types
  const [filters, setFilters] = useState(null as FilterDataEvent<object> | null);

  const onChangeFilterOpened = () => {
    setFilterOpened(!filterOpened);
  };

  const changeReportFilter = () => {
    switch (pathname) {
      case '/mbapp/YearToDateSongsByFormat':
        return <FilterYearToDateSongsByFormat isOpened={filterOpened} changeFilter={onChangeFilter} />;
      default:
        return 'Not created';
    }
  };

  const changeReport = (filtersReports: FilterDataEvent<object> | null) => {
    switch (pathname) {
      case '/mbapp/YearToDateSongsByFormat':
        return <YearToDateSongsByFormatReport filters={filtersReports} />;
      default:
        return 'Not created';
    }
  };

  const onChangeFilter = (filtersParams: any | null) => {
    setFilterOpened(false);
    setFilters(filtersParams);
  };

  return (
    <>
      <Container container direction="column" spacing={0}>
        <ReportHeader title="Year to Date" subtitle="Formats" />
        <ReportContainer>
          <SiblingReportsSelect />
          <FilterButton onClickFilter={onChangeFilterOpened} isOpened={filterOpened} />
        </ReportContainer>
        {changeReportFilter()}
        <GridContainer>{changeReport(filters)}</GridContainer>
      </Container>
    </>
  );
};

export default YearToDateFormats;
