/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import FilterButton from 'components/FilterButton';
import ReportHeader from 'components/ReportHeader';
import SelectInput from 'components/SelectInput';
import SiblingReportsSelect from 'components/SiblingReportsSelect/SiblingReportsSelect';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import FilterDataEvent from 'types/FilterDataEvent';
import SongTrackingReport from './SongTracking';
import FilterSongTracking from './SongTracking/Filter';
import { Container, GridContainer, ReportContainer, SongsContainer } from './styles';
import FilterTrendingAnalysisByStation from './TrendingAnalysisByStationReport/Filter';
import TrendingAnalysisByStationReport from './TrendingAnalysisByStationReport/TrendingAnalysisByStationReport';
import TrendingAnalysis from './TrendingAnalysisReport';
import FilterTrendingAnalysis from './TrendingAnalysisReport/Filter';

const SevenDaysSong: React.FC = () => {
  const { pathname } = useLocation();
  const [filterOpened, setFilterOpened] = useState(false);
  // eslint-disable-next-line @typescript-eslint/ban-types
  const [filters, setFilters] = useState(null as FilterDataEvent<object> | null);
  const [songs, setSongs] = useState([]);
  const [songSelected, setSongSelected] = useState('');
  const { t } = useTranslation();

  const onChangeFilterOpened = () => {
    setFilterOpened(!filterOpened);
  };

  const changeReportFilter = () => {
    switch (pathname) {
      case getReportSpec(ReportBaseEnum.SongsRegionsMarketsTrendingAnalysis).path:
        return <FilterTrendingAnalysis isOpened={filterOpened} changeFilter={onChangeFilter} />;
      case getReportSpec(ReportBaseEnum.SongsRegionsMarketsTrendingAnalysisByStation).path:
        return <FilterTrendingAnalysisByStation isOpened={filterOpened} changeFilter={onChangeFilter} />;
      case getReportSpec(ReportBaseEnum.SongsRegionsMarketsSongTracking).path:
        return <FilterSongTracking isOpened={filterOpened} changeFilter={onChangeFilter} />;
      default:
        return 'Not created';
    }
  };
  const changeReport = (filtersReports: FilterDataEvent<object> | null) => {
    switch (pathname) {
      case getReportSpec(ReportBaseEnum.SongsRegionsMarketsTrendingAnalysis).path:
        return <TrendingAnalysis filters={filtersReports} />;
      case getReportSpec(ReportBaseEnum.SongsRegionsMarketsTrendingAnalysisByStation).path:
        return <TrendingAnalysisByStationReport filters={filtersReports} />;
      case getReportSpec(ReportBaseEnum.SongsRegionsMarketsSongTracking).path:
        return <SongTrackingReport filters={filtersReports} />;
      default:
        return 'Not created';
    }
  };

  const onChangeSong = (song: any) => {
    setSongSelected(song);
    setFilters({
      ...filters,
      SongInfo: song,
    } as FilterDataEvent<any>);
  };

  const onChangeFilter = (filtersParams: any | null) => {
    setFilterOpened(false);
    if (filtersParams?.songInfos?.length) {
      setSongs(filtersParams.songInfos);
      const firstSong = filtersParams.songInfos[0];
      setSongSelected(firstSong);
      setFilters({
        ...filtersParams,
        SongInfo: firstSong,
      } as FilterDataEvent<any>);
    } else {
      setFilters(filtersParams);
    }
  };

  return (
    <>
      <Container container direction="column" spacing={0}>
        <ReportHeader title={t(`report.label.songs`)} subtitle={t(`report.label.sevenDay`)} />
        <ReportContainer>
          <SiblingReportsSelect />
          <FilterButton onClickFilter={onChangeFilterOpened} isOpened={filterOpened} />
        </ReportContainer>
        {changeReportFilter()}
        <SongsContainer>
          {songs?.length > 0 &&
            pathname !== getReportSpec(ReportBaseEnum.SongsRegionsMarketsTrendingAnalysisByStation).path && (
              <SelectInput
                normalizeLabel={option => `${option.Artist}-${option.Title}`}
                optionLabel={t(`report.label.title`)}
                options={songs}
                value={songSelected}
                onChangeValue={onChangeSong}
              />
            )}
        </SongsContainer>
        <GridContainer>{changeReport(filters)}</GridContainer>
      </Container>
    </>
  );
};

export default SevenDaysSong;
