import { AxiosResponse } from 'axios';
import { apiWithContext } from 'services/api';

const urlAuthenticateApi = `/authenticate`;

const LoginService = async (endpointURL: string, login: string, password: string): Promise<AxiosResponse | null> => {
  const axios = apiWithContext(endpointURL);
  try {
    const response = await axios.post(
      urlAuthenticateApi,
      {
        AuthKey: '5e3c6b8d-6088-425f-854b-04da4fc51585',
        Username: login,
        Password: password,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: '',
        },
      },
    );
    return response;
  } catch (error) {
    return null;
  }
};

export default LoginService;
