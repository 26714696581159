import FilterButton from 'components/FilterButton';
import LatestRefreshDate from 'components/LatestRefreshDate';
import ReportHeader from 'components/ReportHeader';
import SelectInput from 'components/SelectInput';
import SiblingReportsSelect from 'components/SiblingReportsSelect/SiblingReportsSelect';
import { merge } from 'lodash';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import { BodyReportContainer } from 'pages/Reports/styles';
import queryString from 'query-string';
import React, { useState } from 'react';
import { getStationDisplayName } from 'utils/reports/GetStationDisplayName';
import Filter from './Filter';
import StationCurrentDailyGrid from './StationCurrentDailyGrid';
import StationCurrentDayBuildingGrid from './StationCurrentDayBuildingGrid';
import { Container, GridContainer, ReportContainer, StationContainer } from './styles';
import { CurrentDayMonitorsFilter, StationReportTypeEnum } from './utils/CurrentDayMonitorTypes';
//import ClassicViewLink from 'components/ClassicView/ClassicViewLink';
//import { generateCurrentDayMonitorsClassicViewUrl } from 'components/ClassicView/ClassicViewUrlHelper';

const DEFAULT_PANEL_ID = 1;
const DEFAULT_FORMAT_CODE = 'XX';
const CurrentDayMonitorsReport: React.FC = () => {
  const [filterOpened, setFilterOpened] = useState(false);
  const [station, setStation] = useState([]);
  const [stationSelected, setStationSelected] = useState('');
  const [filters, setFilters] = useState<CurrentDayMonitorsFilter | undefined>();

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const onChangeFilter = (filtersParams: any | null) => {
    setFilterOpened(false);
    if (filtersParams?.Stations) {
      const stationSearch = filtersParams?.Stations;
      setStation(stationSearch);
      const firstStation = stationSearch[0];
      setStationSelected(firstStation);
      setFilters(merge({}, filtersParams, { StationInfo: firstStation }));
    }
  };

  const onChangeStation = (stationSelect: any) => {
    setStationSelected(stationSelect);
    setFilters(merge({}, filters, { StationInfo: stationSelect }));
  };

  const onSongClickHandler = (data: any, reportQuery: CurrentDayMonitorsFilter | undefined) => {
    const href = getReportSpec(ReportBaseEnum.CurrentSongsReport).path;
    const params = {
      songCode: data.SongInfo?.SongCode,
      'PanelInfo.Id': DEFAULT_PANEL_ID,
      'FormatInfo.FormatCode': reportQuery?.StationInfo?.FormatInfo?.FormatCode || DEFAULT_FORMAT_CODE,
      NumDays: reportQuery?.NumDays,
      deepLink: true,
    };
    window.open(`${href}?${queryString.stringify(params)}`);
  };

  const renderGrid = () => {
    if (filters?.ReportCycle === StationReportTypeEnum.BuildingReport) {
      return <StationCurrentDayBuildingGrid filters={filters} songClickHandler={onSongClickHandler} />;
    }
    return <StationCurrentDailyGrid filters={filters} songClickHandler={onSongClickHandler} />;
  };

  //const url = generateCurrentDayMonitorsClassicViewUrl(filters);

  return (
    <Container container direction="column" spacing={0}>
      <ReportHeader title="Stations" subtitle="Daily" />
      <ReportContainer>
        <SiblingReportsSelect />
        <FilterButton onClickFilter={() => setFilterOpened(!filterOpened)} isOpened={filterOpened} />
      </ReportContainer>
      <Filter isOpened={filterOpened} changeFilter={onChangeFilter} />
      <LatestRefreshDate onChange={filtersRequest => onChangeFilter(filtersRequest)} filters={filters} />
      <BodyReportContainer hidden={!filters}>
        <StationContainer>
          <SelectInput
            normalizeLabel={option => `${getStationDisplayName(option)}`}
            optionLabel="Title"
            options={station}
            value={stationSelected}
            onChangeValue={onChangeStation}
            key="CurrentDayMonitorsReportSelectInput"
          />
        </StationContainer>
        <GridContainer>{renderGrid()}</GridContainer>
        {/*<ClassicViewLink url={url} /> */}
      </BodyReportContainer>
    </Container>
  );
};

export default CurrentDayMonitorsReport;
