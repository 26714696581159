/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { Column, ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import InnerTable from '..';
import defaultTheme from '../../../themes';
import { IInnerTableGroup } from '../inner-table-group';
import { TitleContainer, Title } from './styles';

interface FormatByFormatRankInnerTableProps {
  data: any;
  field: string;
  // eslint-disable-next-line react/require-default-props
  columns?: Column[];
}

const FormatByFormatRankInnerTable: React.FC<FormatByFormatRankInnerTableProps> = ({
  data,
  field,
  columns,
}: FormatByFormatRankInnerTableProps) => {
  const { t } = useTranslation();

  const getColor = (move: number) => {
    if (move < 0) return defaultTheme.palette.error.main;
    if (move > 0) return defaultTheme.palette.success.main;
    return defaultTheme.palette.text.primary;
  };
  const valueGetter = (v: any) => {
    if (columns && columns[0].getColDef()) {
      const colDef = columns[0].getColDef();
      const { valueFormatter } = colDef;

      if (valueFormatter instanceof Function) {
        return valueFormatter({ ...colDef, value: v } as ValueFormatterParams);
      }
    }
    return v;
  };

  const createSpanValue = (displayName: string, move: number, actualValue: number) => {
    const formatActualValue = actualValue === null || actualValue === 0 ? '-' : actualValue;
    return (
      <>
        {`${displayName}: `}
        <span style={{ color: getColor(move) }}>{`${valueGetter(formatActualValue)}`}</span>
      </>
    );
  };

  const innerTableData: IInnerTableGroup[] = [
    {
      title: '',
      // children: createChildren(data, field),
      children: Object.values(data[field]).map((v: any) => {
        return createSpanValue(v.DisplayName, v.Move, v.FormatValue);
      }),
    },
  ];
  return (
    <>
      <TitleContainer>
        <Title>{t('grid.header.FormatByFormatRank')}</Title>
      </TitleContainer>
      <InnerTable key={v4()} data={innerTableData} />
    </>
  );
};

export default FormatByFormatRankInnerTable;
