import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  & svg {
    font-size: 1.5em;
    cursor: pointer;
    margin: 10px 10px 10px 10px;
  }
  span:nth-child(3) {
    margin: 10px;
  }
`;
