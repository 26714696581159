import FormatFilter from 'components/Filter/components/FormatFilter';
import Grc from 'components/Filter/components/Grc';
import PanelFilter from 'components/Filter/components/PanelFilter';
import PastDays, { getCurrentBuildingCycle } from 'components/Filter/components/PastDays';
import ShowTopSelect from 'components/Filter/components/ShowTopSelect';
import FilterBase from 'components/FilterBase';
import useStickyFilter from 'hooks/useStickyFilter/useStickyFilter';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import React from 'react';
import FilterDataEvent from 'types/FilterDataEvent';
import { PanelCodeEnum } from 'types/Panel';
import { CurrentChartFilter } from './utils/CurrentChartTypes';

interface FilterCurrentChartProps {
  changeFilter: { (filters: CurrentChartFilter): void };
  isOpened: boolean;
}

const Filter: React.FC<FilterCurrentChartProps> = ({ changeFilter, isOpened }) => {
  const pathname = getReportSpec(ReportBaseEnum.CurrentChartReport).stickyKey;
  const { savedFilters: filter, saveFilter } = useStickyFilter<CurrentChartFilter>(pathname);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleFilterChange = (event: FilterDataEvent<object>) => {
    const { value } = event;
    saveFilter({ ...filter, ...value } as CurrentChartFilter);
  };

  const handleOnSubmit = () => {
    if (filter) {
      changeFilter(filter);
    }
  };

  return (
    <FilterBase onSubmit={handleOnSubmit} isOpened={isOpened}>
      <PanelFilter onChange={handleFilterChange} initialValue={filter?.PanelInfo} />
      <FormatFilter
        onChange={handleFilterChange}
        panelCode={filter?.PanelInfo?.PanelCode ?? PanelCodeEnum.AllUsStations}
        hasAllOption={false}
        initialValue={filter?.FormatInfo}
      />
      <Grc onChange={handleFilterChange} initialValue={filter?.GrcInfo} />
      <PastDays hasTodayOption onChange={handleFilterChange} initialValue={getCurrentBuildingCycle()} />
      <ShowTopSelect onChange={handleFilterChange} apiPropertyName="TopX" initialValue={filter?.TopX} />
    </FilterBase>
  );
};

export default Filter;
