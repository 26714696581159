import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import { Routes } from 'types/Routes';

export const menuEntries: Routes[] = [
  {
    path: getReportSpec(ReportBaseEnum.CurrentChartReport).path,
    component: getReportSpec(ReportBaseEnum.CurrentChartReport).component,
  },
  {
    path: getReportSpec(ReportBaseEnum.CurrentMusicChartReport).path,
    component: getReportSpec(ReportBaseEnum.CurrentMusicChartReport).component,
  },
];
