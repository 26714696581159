import SelectInput from 'components/SelectInput';
import NavigationMenu from 'contexts/NavigationMenuContext';
import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { menuEntries as reportMenuEntries } from 'routes/reports/index';
import { NavigationMenuApiResponse } from 'types/menu/NavigationMenuApiResponse';

export interface SiblingReportsSelectProps {
  curentReportPath: string;
}

function filterSiblingByLocation(
  navigationMenu: NavigationMenuApiResponse[],
  pathname: string,
): NavigationMenuApiResponse[] {
  if (!navigationMenu?.length) {
    return [];
  }

  const thisReport = navigationMenu.find(nm => !!nm.Url && nm.Url === pathname);
  const flatReportMenu = reportMenuEntries.map(item => item.path);
  if (!thisReport) {
    const getNavigations = navigationMenu.flatMap(nm => filterSiblingByLocation(nm.Items ?? [], pathname));
    return getNavigations.filter(item => flatReportMenu.some(g => g === item.Url));
  }
  return navigationMenu.filter(item => flatReportMenu.some(r => r === item.Url));
}

const SiblingReportsSelect: React.FC = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { navigationMenus } = useContext(NavigationMenu);

  return (
    <>
      <SelectInput
        optionLabel="Name"
        optionValue="Url"
        options={filterSiblingByLocation(navigationMenus, pathname)}
        value={pathname}
        onChangeValue={report => history.push(report)}
        key={`${navigationMenus}${pathname}`}
      />
    </>
  );
};

export default SiblingReportsSelect;
