import { Box } from '@material-ui/core';
import ArrowDownward from '@material-ui/icons/ArrowDropDown';
import ArrowUpward from '@material-ui/icons/ArrowDropUp';
import React, { Dispatch } from 'react';
import { SortModel } from 'types/HeaderComponentParams';
import { useStyles } from './styles';

export interface SortableColumnTextProps {
  sortPath?: string;
  sortable?: boolean;
  onSortChange?: Dispatch<string>;
  currentSortModel?: SortModel;
}

const SortableColumnText: React.FC<SortableColumnTextProps> = props => {
  const { children, sortable = false, sortPath = null, onSortChange: onChange = null, currentSortModel = null } = props;
  const classes = useStyles();
  const isDisabled = !sortable || !sortPath ? 'disabled' : 'enabled';

  const handleClick = () => {
    if (onChange && sortPath && !!sortable) {
      onChange(sortPath);
    }
  };

  const getDisplay = (dir: 'asc' | 'desc') => {
    if (!sortPath || currentSortModel?.sortPath !== sortPath) {
      return 'none';
    }
    return dir === currentSortModel?.sort ? 'inline-block' : 'none';
  };

  return (
    <Box display="inline-block" className={classes.root}>
      <Box display="inline" className={`${isDisabled}`} onClick={handleClick}>
        {children}
      </Box>
      <Box display={getDisplay('asc')}>
        <ArrowUpward fontSize="inherit" className={classes.icon} />
      </Box>
      <Box display={getDisplay('desc')}>
        <ArrowDownward fontSize="inherit" className={classes.icon} />
      </Box>
    </Box>
  );
};

export default SortableColumnText;
