import ReportResult from 'services/ReportResult';
import { OnNewUpReport } from './OnNewUpDownReport';

export const OnNewUpTransformer = (reportData: ReportResult<OnNewUpReport>): ReportResult<OnNewUpReport> => {
  const normalize = reportData.Items.map(item => {
    return {
      ...item,
      SongInfo: {
        Artist: item.Artist,
        Label: item.Label,
        Title: item.Title,
      },
      Rank: {
        ThisWeek: item.RankThisWeek,
        LastWeek: item.RankLastWeek,
      },
      Stations: {
        StationsOn: item.StationsOn,
        StationsNew: item.StationsNew,
        StationsUp: item.StationsUp,
        StationsSteady: item.StationsSteady,
        StationsDown: item.StationsDown,
      },
      Percentages: {
        StationsOnPercentage: item.StationsOnPercentage,
        StationsNewPercentage: item.StationsNewPercentage,
        StationsUpPercentage: item.StationsUpPercentage,
        StationsSteadyPercentage: item.StationsSteadyPercentage,
        StationsDownPercentage: item.StationsDownPercentage,
      },
    };
  });
  return { ...reportData, Items: normalize };
};

export default OnNewUpTransformer;
