import React from 'react';
import { useTranslation } from 'react-i18next';
import { SongInfo } from 'types/SongInfo';
import { v4 } from 'uuid';
import styled from 'styled-components';
import InnerTable from '..';
import { IInnerTableGroup } from '../inner-table-group';

const Container = styled.div`
  display: flex;
  align-items: center;
`;

export interface SongInfoData {
  SongInfo?: SongInfo;
}

interface SongInfoProps {
  data: SongInfoData;
  fieldName: string;
  name?: string | null;
}

const SongInfoInnerTable: React.FC<SongInfoProps> = ({ data, fieldName, name }: SongInfoProps) => {
  const { t } = useTranslation();
  const createSpan = (item?: string) => {
    // eslint-disable-next-line react/jsx-one-expression-per-line
    return <Container key={v4()}>{item} </Container>;
  };
  const innerTableData: IInnerTableGroup[] = [
    {
      title: name ? t(name) : '',
      children: [
        {
          title: '',
          children: [t('grid.header.Title'), t('grid.header.Artist'), t('grid.header.Label')],
        },
        {
          title: '',
          children: [
            createSpan((data[fieldName as keyof SongInfoData] as SongInfo).Title),
            createSpan((data[fieldName as keyof SongInfoData] as SongInfo).Artist),
            createSpan((data[fieldName as keyof SongInfoData] as SongInfo).Label),
          ],
        },
      ],
    },
  ];

  return <InnerTable key={v4()} data={innerTableData} />;
};

SongInfoInnerTable.defaultProps = {
  name: null,
};

export default SongInfoInnerTable;
