/* eslint-disable react/jsx-wrap-multilines */
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import React, { ReactElement, useEffect } from 'react';
import FilterDataEvent from 'types/FilterDataEvent';
import FilterDataProps from 'types/FilterDataProps';
import { useStyles } from './styles';

interface Props<T> extends FilterDataProps<T> {
  name?: string;
  fullWidth?: boolean;
  valueToApi: keyof T;
  disabled?: boolean;
  initialValue?: number | null;
  minValue?: number;
  maxValue?: number;
}

const InputNumber = <T extends {}>({
  name = 'Title',
  onChange,
  fullWidth,
  disabled = false,
  valueToApi,
  initialValue = 1,
  minValue = Number.MIN_SAFE_INTEGER,
  maxValue = Number.MAX_SAFE_INTEGER,
}: Props<T>): ReactElement => {
  const DEFAULT_VALUE = 1;
  const classes = useStyles();
  const [values, setValues] = React.useState<number>(initialValue || DEFAULT_VALUE);

  const updateState = (val: number) => {
    let inputValue = val || 0;
    inputValue = Number(inputValue) > maxValue ? maxValue : inputValue;
    inputValue = Number(inputValue) < minValue ? minValue : inputValue;
    setValues(inputValue);
    onChange({ value: ({ [valueToApi]: inputValue } as unknown) as T } as FilterDataEvent<T>);
  };

  useEffect(() => {
    const inputValue = initialValue || DEFAULT_VALUE;
    updateState(inputValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  useEffect(() => {
    const inputValue = initialValue || DEFAULT_VALUE;
    updateState(inputValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = parseInt(event.target.value, 10);
    updateState(inputValue);
  };

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const handleOnBlur = () => (event: any) => {
    const inputValue = parseInt(event.target.value, 10);
    updateState(inputValue);
  };

  return (
    <div className={classes.root}>
      <FormControl className={classes.margin}>
        <Input
          id="standard-adornment-weight"
          disabled={disabled}
          data-testid="field-input-number"
          value={values}
          disableUnderline
          fullWidth={fullWidth}
          type="number"
          inputProps={{ 'data-testid': 'input-number', min: minValue, max: maxValue }}
          classes={{ input: classes.Input, colorSecondary: 'blue' }}
          onChange={handleChange()}
          onBlur={handleOnBlur()}
          startAdornment={
            <InputAdornment position="end" classes={{ root: classes.Input }}>
              {name}
            </InputAdornment>
          }
        />
      </FormControl>
    </div>
  );
};

InputNumber.defaultProps = {
  fullWidth: false,
  name: 'Title',
  disabled: false,
  initialValue: 1,
  minValue: Number.MIN_SAFE_INTEGER,
  maxValue: Number.MAX_SAFE_INTEGER,
};

export default InputNumber;
