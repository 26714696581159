import useRemoteApi from 'hooks/useApi/useRemoteApi';
import { Dispatch } from 'react';
import { SongInfo } from 'types/SongInfo';

export interface SearchSongsData {
  searchTerm: string;
  searchType: number;
  grcFilter: string;
}

export interface SongResolvers {
  searchSongs: (params: SearchSongsData, resolve: Dispatch<SongInfo[]>) => SongInfo[] | undefined;
  findByCode: (songCode: string, resolve: Dispatch<SongInfo[]>) => void;
  isLoading: boolean;
}

const url = '/songs';
const searchBySongCodeUrl = '/songs/search-by-song-codes';
function useSongResolver(): SongResolvers {
  const { data: searchedSongs, setSettings, isLoading: songLoading } = useRemoteApi<SongInfo[]>(url, {
    enabled: false,
  });
  const { setSettings: setSettingsBySongCode, isLoading: songCodeLoading } = useRemoteApi<SongInfo[]>(
    searchBySongCodeUrl,
    {
      enabled: false,
      method: 'POST',
    },
  );

  const findByCode = (songCode: string, onSuccess: Dispatch<SongInfo[]>) => {
    setSettingsBySongCode({
      enabled: true,
      // $refresh is used to force a refresh for each call
      data: { songCodes: [songCode], $refresh: new Date() },
      onSuccess,
    });
  };

  const searchSongs = (params: SearchSongsData, onSuccess: Dispatch<SongInfo[]>) => {
    setSettings({
      enabled: true,
      params,
      onSuccess,
    });
    return searchedSongs;
  };

  return { findByCode, searchSongs, isLoading: songLoading || songCodeLoading };
}

export default useSongResolver;
