/* eslint-disable @typescript-eslint/no-explicit-any */
import { AgGridColumn } from '@ag-grid-community/react';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import BooleanCanadaCheckMarkCellRenderer from 'components/DetailGrid/CellRenderers/BooleanCanadaCheckMarkCellRenderer';
import DayPartsCellRenderer from 'components/DetailGrid/CellRenderers/DayPartsCellRenderer';
import MultiColumnsCellRenderer from 'components/DetailGrid/CellRenderers/MultiColumnsCellRenderer/MultiColumnsCellRenderer';
import RankInfoCellRendererRec from 'components/DetailGrid/CellRenderers/RankInfoCellRendererRec/RankInfoCellRendererRec';
import SongCellRenderer from 'components/DetailGrid/CellRenderers/SongCellRenderer/SongCellRenderer';
import ThisLastMoveCellRenderer from 'components/DetailGrid/CellRenderers/ThisLastMoveCellRenderer';
import DayPartsHeaderComponent from 'components/DetailGrid/HeaderComponents/DayPartsHeaderComponent';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import ThisLastMoveHeaderComponent from 'components/DetailGrid/HeaderComponents/ThisLastMoveHeaderComponent';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import { isAfter, isBefore, isSameDay } from 'date-fns';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import { CountryFormatCodeEnum } from 'types/Format';
import { PanelCodeEnum } from 'types/Panel';
import { ReportCycleIdEnum } from 'types/ReportCycle';
import { formatDate, formatDateTypes } from 'utils/dates';
import queryString from 'query-string';
import { RollingAvailable, RollingFilterParams } from './utils/types';
import RollingChartReport from './utils/RollingChartReport';
import { RollingChartReportTransformer } from './utils/RollingChartReportTransformer';

const url = '/rolling-chart-reports?$inlinecount=allpages';
const countryFormatsCode = Object.values(CountryFormatCodeEnum) as string[];

interface RollingChartGridProps {
  filters?: RollingFilterParams | undefined;
  dateRange: RollingAvailable | undefined;
}

const RollingChartGrid: React.FC<RollingChartGridProps> = ({ filters, dateRange }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({ sortPath: 'RankThisWeek', sort: 'asc' });
  const { data: reportData, isLoading, settings, setSettings, isError } = useRemoteApi<
    ReportResult<RollingChartReport>
  >(url, {
    data: filters,
    method: 'POST',
    enabled: false,
    transformResponse: RollingChartReportTransformer,
    transformRequest: data => {
      return {
        ...data,
        DateRange: {
          Start: formatDate(data.DateRange.Start, formatDateTypes.yyyyMMdd),
          End: formatDate(data.DateRange.End, formatDateTypes.yyyyMMdd),
        },
        IsArchived: isArchived(data.DateRange.End, data.MaxDate),
        MinSpins: data?.MinSpins ?? 1,
        GrcInfo: data?.GrcInfo ?? { GrcCode: 'CR', Id: 'CR', Name: 'Currents and Recurrents' },
      };
    },
  });

  const hidePointsInfo = () => {
    return (
      filters?.PanelInfo?.PanelCode === PanelCodeEnum.PublishedPanel &&
      countryFormatsCode.includes(filters?.FormatInfo?.FormatCode.toString() ?? '')
    );
  };

  const isArchived = (dateRangeEnd: string, dateMax: string): boolean => {
    return !(
      isSameDay(new Date(dateRangeEnd), new Date(dateMax)) || isAfter(new Date(dateRangeEnd), new Date(dateMax))
    );
  };

  const validateDate = (dateEndRange: Date | string, dateEnd: Date | string) => {
    return isAfter(new Date(dateEndRange), new Date(dateEnd)) || isBefore(new Date(dateEndRange), new Date(dateEnd));
  };

  const IsThisWeekSelected = () => {
    if (filters?.DateRange && dateRange?.ThisWeekRange.End) {
      const { IsThisWeek, DateRange } = filters as RollingFilterParams;
      return IsThisWeek && !validateDate(dateRange.ThisWeekRange.End, DateRange?.End || new Date());
    }
    return !!filters;
  };

  const songClickHandler = (data: any, reportQuery: RollingFilterParams | undefined) => {
    const href = getReportSpec(ReportBaseEnum.SongAnalysisReport).path;
    const params = {
      songCode: data.SongInfo?.SongCode,
      'Formats.FormatCode': [reportQuery?.FormatInfo?.FormatCode],
      'PanelInfo.Id': reportQuery?.PanelInfo?.Id,
      MinSpins: reportQuery?.MinSpins ?? 1,
      ReportCycle: ReportCycleIdEnum.rolling,
      deepLink: true,
    };
    window.open(`${href}?${queryString.stringify(params)}`);
  };

  const getImpressionsHeaders = () => {
    const secondLines: any[] = [{ label: `grid.header.TW`, sortPath: 'ImpressionsInfo/SpinsThisWeek' }];
    if (IsThisWeekSelected()) {
      secondLines.push({ label: `grid.header.LW`, sortPath: 'ImpressionsInfo/SpinsLastWeek' });
      secondLines.push({ label: `grid.header.plusMinus`, sortPath: 'ImpressionsInfo/Move' });
      secondLines.push({ label: `grid.header.RTW`, sortPath: 'ImpressionsInfo/RankThisWeek' });
      secondLines.push({ label: `grid.header.RLW`, sortPath: 'ImpressionsInfo/RankLastWeek' });
    }

    return secondLines;
  };

  const getImpressionsFields = () => {
    const fields: string[] = ['ImpressionsInfo.SpinsThisWeek'];
    if (IsThisWeekSelected()) {
      fields.push('ImpressionsInfo.SpinsLastWeek');
      fields.push('ImpressionsInfo.Move');
      fields.push('ImpressionsInfo.RankThisWeek');
      fields.push('ImpressionsInfo.RankLastWeek');
    }

    return fields;
  };

  return (
    <>
      <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
        <ServerSideReportGrid settings={settings} sortModel={sortModel} setSettings={setSettings} filters={filters}>
          <AgGridColumn hide={!IsThisWeekSelected()} field="PeakRank" headerName={t(`grid.header.PeakRank`)} />
          <AgGridColumn
            field="RankInfo"
            headerName={t(`grid.header.Rank`)}
            minWidth={60}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              disposition: 'row',
              secondLine: [
                { label: 'grid.header.TW', sortPath: 'RankThisWeek' },
                { label: 'grid.header.LW', sortPath: 'RankLastWeek' },
              ],
            }}
            cellRendererFramework={RankInfoCellRendererRec}
            cellRendererParams={{ showArrow: true }}
          />
          <AgGridColumn
            field="SongInfo"
            pinned
            headerName={t(`grid.header.Title`)}
            minWidth={150}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              sortPath: 'Title',
              disposition: 'row',
              secondLine: [
                { label: 'grid.header.Artist', sortPath: 'Artist' },
                { label: 'grid.header.Label', sortPath: 'Label' },
              ],
            }}
            cellRendererFramework={SongCellRenderer}
            cellRendererParams={{
              reportQuery: filters,
              onClickHandler: (data: any, reportQuery: any) => songClickHandler(data, reportQuery),
            }}
          />
          <AgGridColumn
            field="WeeksOnChart"
            hide={!IsThisWeekSelected()}
            headerName={t(`grid.header.WeeksOnChart`)}
            valueFormatter={({ value }: ValueFormatterParams) => (value === 0 ? '--' : value)}
          />
          <AgGridColumn
            field="SpinsInfo"
            minWidth={95}
            headerName={t(`grid.header.SpinsInfoTW`)}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              sortPath: 'SpinsInfo/ThisWeek',
              secondLine: [
                { label: 'grid.header.LW', sortPath: 'SpinsInfo/LastWeek' },
                { label: 'grid.header.plusMinus', sortPath: 'SpinsInfo/Move' },
              ],
            }}
            cellRendererFramework={ThisLastMoveCellRenderer}
          />
          <AgGridColumn
            field="HasCanadianContent"
            headerName="CanCon"
            minWidth={70}
            cellRendererFramework={BooleanCanadaCheckMarkCellRenderer}
            headerComponentParams={{ sortModel, onSortChange, sortPath: 'HasCanadianContent' }}
            hide={
              !(
                filters?.PanelInfo?.PanelCode === PanelCodeEnum.AllCanadianStations ||
                filters?.PanelInfo?.PanelCode === PanelCodeEnum.PublishedCanadaPanel
              )
            }
          />
          <AgGridColumn
            field="PointsInfo"
            minWidth={95}
            headerName={t(`grid.header.PointsInfoTW`)}
            cellRendererFramework={ThisLastMoveCellRenderer}
            headerComponentFramework={ThisLastMoveHeaderComponent}
            headerComponentParams={{ sortModel, onSortChange }}
            hide={!hidePointsInfo() || !IsThisWeekSelected()}
          />
          <AgGridColumn
            field="DaypartsInfo"
            headerName={t(`grid.header.DaypartsInfo`)}
            width={230}
            minWidth={230}
            headerComponentFramework={DayPartsHeaderComponent}
            headerComponentParams={{ sortModel, onSortChange }}
            cellRendererFramework={DayPartsCellRenderer}
          />
          <AgGridColumn
            field="ImpressionsInfo"
            colId="ImpressionsInfoForChart"
            headerName={t('grid.header.ImpressionsInfo')}
            minWidth={260}
            width={260}
            maxWidth={260}
            valueFormatter={({ value }: ValueFormatterParams) => `${+parseFloat(value).toFixed(3)}`}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              secondLine: getImpressionsHeaders(),
            }}
            cellRendererFramework={MultiColumnsCellRenderer}
            cellRendererParams={{
              fields: getImpressionsFields(),
            }}
          />
          <AgGridColumn
            headerName={t('grid.header.Stations')}
            minWidth={90}
            field="StationsInfo"
            valueGetter={({ data }: any) => {
              return data && `${data.StationsInfo.StationsPlayingTitle} / ${data.StationsInfo.OnNew}`;
            }}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              secondLine: [{ label: 'grid.header.OnNew', sortPath: 'OnNew' }],
            }}
          />
          <AgGridColumn
            field="AveragePlay"
            headerName={t('grid.header.AvgStationRotations')}
            minWidth={230}
            headerComponentFramework={MultiColumnsHeaderComponent}
            valueFormatter={({ value }: ValueFormatterParams) => `${+parseFloat(value).toFixed(1)}`}
            headerComponentParams={{
              sortModel,
              onSortChange,
              secondLine: [
                { label: `grid.header.On`, sortPath: 'AveragePlay/StationsPlayingTitle' },
                { label: `grid.header.TW`, sortPath: 'AveragePlay/ThisWeek' },
                { label: `grid.header.LW`, sortPath: 'AveragePlay/LastWeek' },
                { label: `grid.header.plusMinus`, sortPath: 'AveragePlay/Move' },
              ],
            }}
            cellRendererFramework={MultiColumnsCellRenderer}
            cellRendererParams={{
              fields: [
                'AveragePlay.StationsPlayingTitle',
                'AveragePlay.ThisWeek',
                'AveragePlay.LastWeek',
                'AveragePlay.Move',
              ],
            }}
          />
          <AgGridColumn
            field="WithoutOvernight"
            hide={!IsThisWeekSelected()}
            headerName={t('grid.header.WithoutOvernight')}
            minWidth={120}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              secondLine: [
                { label: `grid.header.Rank`, sortPath: 'WithoutOvernight/Rank' },
                { label: `grid.header.TW`, sortPath: 'WithoutOvernight/SpinsThisWeek' },
              ],
            }}
            cellRendererFramework={MultiColumnsCellRenderer}
            cellRendererParams={{
              fields: ['WithoutOvernight.Rank', 'WithoutOvernight.SpinsThisWeek'],
            }}
          />
        </ServerSideReportGrid>
      </GridVisibilityWrapper>
    </>
  );
};

export default RollingChartGrid;
