/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import FilterButton from 'components/FilterButton';
import ReportHeader from 'components/ReportHeader';
import SelectInput from 'components/SelectInput';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import CurrentMusicChartGrid from './CurrentMusicChartGrid';
import Filter from './Filter';
import { Container, GridContainer, ReportContainer } from './styles';

const optionGrid = [
  {
    name: 'Current Chart Report',
    value: 'CurrentChartReport',
  },
  {
    name: 'Daily Chart',
    value: 'CurrentMusicChartReport',
  },
];

const CurrentMusicChartReport: React.FC = () => {
  const history = useHistory();
  const [panel] = useState('CurrentMusicChartReport');
  const [filter, setFilters] = useState();

  const [filterOpened, setFilterOpened] = useState(false);

  const changePanel = (typePanel: string) => {
    history.push(typePanel);
  };

  const onChangeFilterOpened = () => {
    setFilterOpened(!filterOpened);
  };

  const onChangeFilter = (filtersParams: any | null) => {
    setFilterOpened(false);
    setFilters(filtersParams);
  };

  return (
    <>
      <Container container direction="column" spacing={0}>
        <ReportHeader title="Chart" subtitle="Daily/Current" />
        <ReportContainer>
          <SelectInput
            optionValue="value"
            optionLabel="name"
            options={optionGrid}
            value={panel}
            onChangeValue={changePanel}
          />
          <FilterButton onClickFilter={onChangeFilterOpened} isOpened={filterOpened} />
        </ReportContainer>
        <Filter isOpened={filterOpened} changeFilter={onChangeFilter} />
        <GridContainer>
          <CurrentMusicChartGrid filters={filter} />
        </GridContainer>
      </Container>
    </>
  );
};

export default CurrentMusicChartReport;
