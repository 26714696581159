/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, InputAdornment, MenuItem, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { FilterState } from 'hooks/useFilterState/useFilterState';
import { usePrevious } from 'hooks/usePrevious/usePrevious';
import useSongResolver, { SearchSongsData } from 'hooks/useSongResolver/useSongResolver';
import { isEqual, noop, trim } from 'lodash';
import React, { useEffect, useState } from 'react';
import GrcInfoService from 'services/Filters/GrcInfoService';
import FilterDataProps from 'types/FilterDataProps';
import { SongInfo } from 'types/SongInfo';
import { v4 as uuidv4 } from 'uuid';
import SearchTable from './SearchTable';
import SelectedSongs from './SelectedSongs';
import { useStyles } from './styles';

interface Props extends FilterDataProps<SongInfoFilterData> {
  disabled?: boolean;
  multiSelect?: boolean;
  songCode?: string;
  changeState?: (key: string, state: FilterState) => void;
  required?: boolean;
}

export interface SongInfoFilterData {
  songInfos: SongInfo[] | undefined;
}

const FILTER_COMPONENT_KEY = 'SongSelectionComponent';
const SongSelection: React.FC<Props> = ({
  disabled = false,
  multiSelect = true,
  onChange,
  initialValue,
  songCode,
  changeState = noop,
  required = false,
}) => {
  const classes = useStyles();

  const grcInfoService = new GrcInfoService();
  const searchTypes = [
    { Id: 1, Name: 'Title' },
    { Id: 2, Name: 'Artist' },
  ];

  const grcFilter = grcInfoService.getGrcInfoAllSync();

  const [songs, setSongs] = useState<SongInfo[]>([]);
  const lastSongs = usePrevious(songs);
  const lastDisabled = usePrevious(disabled);

  const [filterData, setFilterData] = useState({
    searchTerm: '',
    searchType: 1,
    grcFilter: 'GRC',
  } as SearchSongsData);

  const [searchedSongs, setSearchedSongs] = useState<SongInfo[] | undefined>();
  const { searchSongs, findByCode } = useSongResolver();

  // eslint-disable-next-line no-shadow
  const hasSongByCode = (songCode?: string) => {
    if (!songCode) {
      return true;
    }
    if (!songs?.length) {
      return false;
    }
    return !!songs.map(s => trim(s.SongCode)).includes(trim(songCode)) && songs?.every(s => s.Title?.length);
  };

  useEffect(() => {
    if (songCode) {
      if (!hasSongByCode(songCode)) {
        changeState(FILTER_COMPONENT_KEY, FilterState.INVALID);
        findByCode(songCode, setSongs);
      }
    } else if (initialValue?.length && !songs.length) {
      changeState(FILTER_COMPONENT_KEY, FilterState.INVALID);
      setSongs(initialValue);
    }
  }, [initialValue, songCode]);

  useEffect(() => {
    if (!isEqual(lastSongs, songs) || !isEqual(disabled, lastDisabled)) {
      if (multiSelect) {
        onChange({ value: { songInfos: songs } });
      } else {
        onChange({ value: { songInfos: [songs[0]] } });
      }
    }

    if (disabled || !required) {
      changeState(FILTER_COMPONENT_KEY, FilterState.VALID);
    } else if (songs?.length && hasSongByCode(songCode)) {
      changeState(FILTER_COMPONENT_KEY, FilterState.VALID);
    }
  }, [songs, disabled]);

  const searchTypeOnChange = (ev: React.ChangeEvent<{ value: unknown }>) =>
    setFilterData({ ...filterData, searchType: parseInt(ev.target.value as string, 10) });

  const grcFilterOnChange = (ev: React.ChangeEvent<{ value: unknown }>) =>
    setFilterData({ ...filterData, grcFilter: ev.target.value as string });

  const handleSearchTerm = (ev: React.ChangeEvent<{ value: unknown }>) =>
    setFilterData({ ...filterData, searchTerm: ev.target.value as string });

  const filterSelected = (songInfos: SongInfo[]) => {
    const selectedIds = songs.map(song => `${song.Artist} - ${song.Title}`) ?? [];
    return songInfos.filter(song => !selectedIds.includes(`${song.Artist} - ${song.Title}`));
  };

  const addSong = (song: SongInfo) => {
    if (multiSelect) {
      const newSongs = [...songs, song];
      setSongs(newSongs);
    } else {
      setSongs([song]);
    }
  };

  const removeSong = (song: SongInfo) => {
    setSongs([...songs].filter(s => s.SongCode !== song.SongCode));
  };

  return (
    <>
      <Box display="flex" flexDirection="column" width="100%">
        <Box>{multiSelect && <SelectedSongs songs={songs} onRemove={removeSong} />}</Box>
        <Box>
          <TextField fullWidth select value={filterData.searchType} onChange={searchTypeOnChange} disabled={disabled}>
            {searchTypes.map(item => {
              return (
                <MenuItem key={uuidv4()} value={item.Id}>
                  {item.Name}
                </MenuItem>
              );
            })}
          </TextField>

          <TextField fullWidth select value={filterData.grcFilter} onChange={grcFilterOnChange} disabled={disabled}>
            {grcFilter.map(item => {
              return (
                <MenuItem key={uuidv4()} value={item.GrcCode}>
                  {item.Name}
                </MenuItem>
              );
            })}
          </TextField>

          <TextField
            placeholder="Search"
            fullWidth
            value={filterData.searchTerm}
            onChange={handleSearchTerm}
            disabled={disabled}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    onClick={() => searchSongs(filterData, setSearchedSongs)}
                    disabled={disabled || !filterData.searchTerm}
                  >
                    <SearchIcon className={classes.searchIcon} />
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box>
          <SearchTable
            searchedSongs={filterSelected([...(searchedSongs ?? [])])}
            addSong={addSong}
            multiSelect={multiSelect}
          />
        </Box>
      </Box>
    </>
  );
};

export default SongSelection;
