/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { FilterState } from 'hooks/useFilterState/useFilterState';
import { usePrevious } from 'hooks/usePrevious/usePrevious';
import { get, isEqual, noop } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilterDataProps from 'types/FilterDataProps';
import { Container, useFormLabelStyles } from './styles';

interface GenericMultipleSelect extends FilterDataProps<any> {
  title?: string;
  subtitle?: string;
  data?: any[];
  values?: any;
  disabled?: boolean;
  optionLabel?: string;
  optionValue?: string | undefined;
  changeState?: (state: FilterState) => void;
  required?: boolean;
  comparator?: (v1: any, v2: any) => number;
}

const GenericMultipleSelect: React.FC<GenericMultipleSelect> = ({
  onChange,
  data,
  initialValue,
  optionLabel = 'Name',
  optionValue = undefined,
  changeState = noop,
  required = false,
  comparator,
}) => {
  const [selectedValues, setSelectedValues] = useState<any[]>();
  const previousSelectedValues = usePrevious(selectedValues);
  const previousData = usePrevious(data);

  const formLabelClasses = useFormLabelStyles();
  const { t } = useTranslation();

  const valueMatches = (v1: unknown, v2: unknown) => {
    return get(v1, optionValue ?? '', v1) === get(v2, optionValue ?? '', v2);
  };

  useEffect(() => {
    changeState(!required || selectedValues?.length ? FilterState.VALID : FilterState.INVALID);
    if (!isEqual(selectedValues, previousSelectedValues)) {
      onChange({ value: selectedValues });
    }
  }, [selectedValues, required]);

  useEffect(() => {
    if (!isEqual(previousData, data)) {
      changeState(FilterState.INVALID);
    }

    if (initialValue || !isEqual(previousData, data)) {
      const find = (initialValue ?? [])
        .map((iv: unknown) => (data ?? []).find(vd => valueMatches(iv, vd)))
        .filter((v: unknown) => !!v);
      setSelectedValues(find);
    }
  }, [initialValue, data]);

  const onChangeValue = (item: any) => {
    if (!selectedValues) {
      return;
    }

    const existingItem = selectedValues.find(v => isEqual(v, item));
    if (existingItem) {
      const newValues = selectedValues.filter(v => !isEqual(v, existingItem));
      setSelectedValues([...newValues]);
    } else {
      const newValues = [...selectedValues, item];
      setSelectedValues(newValues);
    }
  };

  function isSelected(item: unknown): boolean {
    return !!(selectedValues ?? []).find(v => {
      if (comparator) {
        return comparator(item, v) === 0;
      }
      return valueMatches(item, v);
    });
  }

  return (
    <Container data-testid="multiple-select">
      {data?.map((item: any, index: number) => (
        <FormControlLabel
          data-testid={`multi-select-${index + 1}`}
          classes={formLabelClasses}
          key={get(item, 'Id', index)}
          control={<Checkbox color="primary" checked={isSelected(item)} onChange={() => onChangeValue(item)} />}
          label={t(item[optionLabel])}
        />
      ))}
    </Container>
  );
};

export default GenericMultipleSelect;
