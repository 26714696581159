/* eslint-disable jsx-a11y/anchor-is-valid */
import { AgGridColumn } from '@ag-grid-community/react';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import BooleanCheckMarkCellRenderer from 'components/DetailGrid/CellRenderers/BooleanCheckMarkCellRenderer';
import NewThisWeekCellRenderer from 'components/DetailGrid/CellRenderers/NewThisWeekCellRenderer';
import ThisLastMoveCellRenderer from 'components/DetailGrid/CellRenderers/ThisLastMoveCellRenderer';
import DayPartsHeaderComponent from 'components/DetailGrid/HeaderComponents/DayPartsHeaderComponent';
import StationRankMarketFormatHeaderComponent from 'components/DetailGrid/HeaderComponents/StationRankMarketFormatHeaderComponent';
import ThisLastMoveHeaderComponent from 'components/DetailGrid/HeaderComponents/ThisLastMoveHeaderComponent';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import queryString from 'query-string';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import { getCurrentSongsReportParams } from 'services/Reports/CurrentSongsReportService';
import { CountryFormatCodeEnum } from 'types/Format';
import { ReportCycleIdEnum } from 'types/ReportCycle';
import { formatDate } from 'utils/dates';
import DayPartsCellRenderer from '../../../../../components/DetailGrid/CellRenderers/DayPartsCellRenderer';
import StationRankMarketFormatCellRenderer from '../../../../../components/DetailGrid/CellRenderers/StationRankMarketFormatCellRenderer';
import { ExtendedRealTimeSongAnalysisFilter } from './types/RealTimeSongAnalysis';

interface ViewSongHistoryProps {
  filters?: ExtendedRealTimeSongAnalysisFilter;
}

const url = `/real-time-song-analysis-reports`;
const countryFormatsCode = Object.values(CountryFormatCodeEnum) as string[];

const ViewSongHistoryGrid: React.FC<ViewSongHistoryProps> = ({ filters }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({ sortPath: 'SpinsInfo/ThisWeek', sort: 'asc' });
  const { setSettings, settings, data: reportData, isLoading, isError } = useRemoteApi<ReportResult<unknown>>(url, {
    method: 'POST',
    enabled: false,
    transformRequest: getCurrentSongsReportParams,
  });

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const cellClickHandler = (
    data: any,
    reportQuery: ExtendedRealTimeSongAnalysisFilter,
    reportCycle: ReportCycleIdEnum,
  ) => {
    const href = getReportSpec(ReportBaseEnum.CurrentDayMonitorsReport).path;
    const params = {
      ReportCycle: reportCycle,
      'StationInfo.Name': data.StationName,
      NumDays: reportQuery?.NumDays,
      deepLink: true,
    };
    window.open(`${href}?${queryString.stringify(params)}`);
  };

  return (
    <>
      <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
        <ServerSideReportGrid setSettings={setSettings} settings={settings} filters={filters} sortModel={sortModel}>
          <AgGridColumn
            field="NewThisWeek"
            headerName={t('grid.header.NewThisWeek')}
            minWidth={42}
            wrapText
            cellRendererFramework={NewThisWeekCellRenderer}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="StationDisplayName"
            minWidth={120}
            maxWidth={200}
            pinned
            cellRendererFramework={StationRankMarketFormatCellRenderer}
            cellRendererParams={{
              reportQuery: filters,
              /* eslint-disable @typescript-eslint/no-explicit-any */
              onClickHandler: (data?: any, reportQuery?: any) =>
                cellClickHandler(data, reportQuery, ReportCycleIdEnum.dailyReport),
            }}
            headerComponentFramework={StationRankMarketFormatHeaderComponent}
            headerComponentParams={{ sortModel, onSortChange, showFormat: true }}
          />
          <AgGridColumn
            field="HasPlayedToday"
            headerName="Played Today"
            minWidth={60}
            cellRendererFramework={BooleanCheckMarkCellRenderer}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="SpinsInfo"
            minWidth={90}
            headerName={t(`grid.header.SpinsInfoTW`)}
            cellRendererFramework={ThisLastMoveCellRenderer}
            cellRendererParams={{
              thisWeekClickHandler: (data?: any, reportQuery?: any) =>
                /* eslint-disable @typescript-eslint/no-explicit-any */
                cellClickHandler(data, reportQuery, ReportCycleIdEnum.dailyReport),
            }}
            headerComponentFramework={ThisLastMoveHeaderComponent}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            headerName="Day Parts"
            field="DaypartsInfo"
            width={230}
            minWidth={230}
            headerComponentFramework={DayPartsHeaderComponent}
            headerComponentParams={{ sortModel, onSortChange }}
            cellRendererFramework={DayPartsCellRenderer}
          />
          <AgGridColumn
            field="PointsInfo"
            minWidth={95}
            headerName={t(`grid.header.PointsInfoTW`)}
            cellRendererFramework={ThisLastMoveCellRenderer}
            headerComponentFramework={ThisLastMoveHeaderComponent}
            headerComponentParams={{ sortModel, onSortChange }}
            hide={!countryFormatsCode.includes(`${filters?.FormatInfo?.FormatCode}`)}
          />
          <AgGridColumn
            field="FirstPlayedDate"
            valueFormatter={({ value }: ValueFormatterParams) => formatDate(value)}
            headerName={t(`grid.header.FirstPlayedDate`)}
            minWidth={130}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="SpinsToDate"
            headerName={t(`grid.header.SpinsToDate`)}
            minWidth={50}
            headerComponentParams={{ sortModel, onSortChange }}
          />
        </ServerSideReportGrid>
      </GridVisibilityWrapper>
    </>
  );
};

export default ViewSongHistoryGrid;
