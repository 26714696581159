import { AgGridColumn } from '@ag-grid-community/react';
import DayPartsCellRenderer from 'components/DetailGrid/CellRenderers/DayPartsCellRenderer';
import DayOfTheWeekCellRenderer from 'components/DetailGrid/CellRenderers/DaysOfTheWeekRenderer';
import DayPartsHeaderComponent from 'components/DetailGrid/HeaderComponents/DayPartsHeaderComponent';
import DaysOfTheWeekComponent from 'components/DetailGrid/HeaderComponents/DaysOfTheWeekComponent';
import WeeklyHeaderComponent from 'components/DetailGrid/HeaderComponents/WeeklyHeaderComponent';
import React from 'react';
import WeeklyCellRenderer from 'components/DetailGrid/CellRenderers/WeekCellRenderer';

import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import { formatDate } from 'utils/dates';
import { numberFormat } from 'utils/currency';
import Daily from './Daily';
import Weekly from './Weekly';
import { TrendingPropsGrid } from '.';
import { DetailsItemsTransformer } from './utils/TrendingAnalysisTransformer';

const Details: React.FC<TrendingPropsGrid> = ({ report, translate, setSettings, settings, filters }) => {
  const { sortModel, setSortModel: onSortChange } = useSortColumn({ sortPath: 'ReportName', sort: 'asc' });

  return (
    <>
      <ServerSideReportGrid
        sortModel={sortModel}
        settings={settings}
        setSettings={setSettings}
        filters={filters}
        transformResponse={DetailsItemsTransformer}
      >
        <AgGridColumn
          field="StationPortfolioName"
          headerName={translate('grid.header.StationPortfolioName')}
          minWidth={150}
          headerComponentParams={{
            sortModel,
            sortPath: 'StationPortfolioName',
            onSortChange,
          }}
        />
        <AgGridColumn
          headerName={translate('grid.header.StationName')}
          field="StationDisplayName"
          pinned
          minWidth={100}
          wrapText
          headerComponentParams={{
            sortModel,
            sortPath: 'StationDisplayName',
            onSortChange,
          }}
        />
        <AgGridColumn
          headerName={translate('grid.header.FormatShort')}
          field="FormatShort"
          minWidth={80}
          wrapText
          headerComponentParams={{
            sortModel,
            sortPath: 'FormatShort',
            onSortChange,
          }}
        />
        <AgGridColumn
          headerName={translate('grid.header.Name')}
          field="MarketName"
          minWidth={100}
          wrapText
          headerComponentParams={{
            sortPath: 'MarketName',
            onSortChange,
          }}
        />
        <AgGridColumn
          headerName={translate('grid.header.Rank')}
          field="MarketRank"
          minWidth={80}
          wrapText
          headerComponentParams={{
            sortPath: 'MarketRank',
            onSortChange,
          }}
        />
        <AgGridColumn
          headerName={translate('grid.header.Add')}
          field="IsSongAdd"
          valueFormatter={({ value }: ValueFormatterParams) => (value ? 'A' : '---')}
          minWidth={80}
          wrapText
          headerComponentParams={{
            sortModel,
            sortPath: 'IsSongAdd',
            onSortChange,
          }}
        />
        <AgGridColumn
          cellRendererFramework={DayOfTheWeekCellRenderer}
          headerComponentFramework={DaysOfTheWeekComponent}
          field="DailySpinTrends"
          headerComponentParams={{ name: 'SPINS DAILY', rangeDate: report.DateRange, onSortChange }}
          minWidth={350}
          wrapText
        />
        <AgGridColumn
          field="ThisWeekRank"
          headerName="STN.RANK"
          minWidth={100}
          wrapText
          valueFormatter={({ value }: ValueFormatterParams) => (value === 0 ? '--' : value)}
          headerComponentParams={{
            sortPath: 'ThisWeekRank',
            onSortChange,
          }}
        />
        <AgGridColumn
          cellRendererFramework={WeeklyCellRenderer}
          headerComponentFramework={WeeklyHeaderComponent}
          field="WeeklySpinTrends"
          headerComponentParams={{ name: translate('grid.header.SpinsWeekly') }}
          minWidth={400}
          width={400}
          wrapText
        />
        <AgGridColumn
          cellRendererFramework={DayPartsCellRenderer}
          headerComponentFramework={DayPartsHeaderComponent}
          headerComponentParams={{ name: translate('grid.header.DaypartSpins') }}
          field="DaypartSpins"
          minWidth={300}
          wrapText
        />
        {Daily({
          name: translate('grid.header.ImpressionsDaily'),
          formatNumber: true,
          range: report.DateRange,
          onSortChange,
          width: 450,
          minWidth: 450,
          /* eslint-disable @typescript-eslint/no-explicit-any */
          hide: !(filters as any).ShowImpressions,
        })}
        {Weekly({
          name: translate('grid.header.ImpressionsWeekly'),
          onSortChange,
          formatNumber: true,
          /* eslint-disable @typescript-eslint/no-explicit-any */
          hide: !(filters as any).ShowImpressions,
        })}
        <AgGridColumn
          cellRendererFramework={DayPartsCellRenderer}
          headerComponentFramework={DayPartsHeaderComponent}
          valueFormatter={({ value }: ValueFormatterParams) => (value ? numberFormat(value) : '--')}
          headerComponentParams={{ name: translate('grid.header.dayPartImpressions') }}
          field="DaypartImpressions"
          minWidth={400}
          wrapText
          hide={!(filters as any).ShowImpressions}
        />
        <AgGridColumn
          field="SpinsToDate"
          headerName={translate('grid.header.SpinsToDay')}
          minWidth={80}
          headerComponentParams={{
            sortPath: 'SpinsToDate',
            onSortChange,
          }}
        />
        <AgGridColumn
          field="ImpressionsToDate"
          headerName={translate('grid.header.ImpressionsToDate')}
          minWidth={120}
          valueFormatter={({ value: v }: ValueFormatterParams) => (v ? numberFormat(v) : '--')}
          headerComponentParams={{
            sortPath: 'ImpressionsToDate',
            onSortChange,
          }}
          /* eslint-disable @typescript-eslint/no-explicit-any */
          hide={!(filters as any).ShowImpressions}
        />
        <AgGridColumn
          field="FirstMonitored"
          headerName={translate('grid.header.FirstDetect')}
          minWidth={100}
          valueFormatter={({ value }: ValueFormatterParams) => (value ? formatDate(value) : '---')}
          headerComponentParams={{
            sortModel,
            sortPath: 'FirstMonitored',
            onSortChange,
          }}
        />
      </ServerSideReportGrid>
    </>
  );
};

export default Details;
