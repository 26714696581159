import { AgGridReact } from '@ag-grid-community/react/lib/agGridReact';
import { Typography } from '@material-ui/core';
import React from 'react';
import DayPartInfo from 'types/DayPart';
import { get } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { ContainerGrid, ItemGrid } from '../globalStylesCellRenderers';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface AirPlayCellRendererProps {
  value?: DayPartInfo;
  data?: any;
  totalField: keyof any;
  isTotalRow: boolean;
  fixedValue: number;
  agGridReact?: AgGridReact;
}

const AirPlayCellRenderer: React.FC<AirPlayCellRendererProps> = ({
  value,
  data,
  totalField,
  isTotalRow,
  fixedValue = 1,
  agGridReact = null,
}) => {
  if (!value || !data) {
    return <></>;
  }
  const rowStyle = get(agGridReact, 'gridOptions.rowStyle');
  const totalValue = data[totalField];

  const getPercentValue = (valueParam: number) => {
    return `${((valueParam * 100) / totalValue).toFixed(fixedValue)}%`;
  };
  const colWidthPercentual = 100 / 6;
  const fields = Object.keys(value);
  return (
    <ContainerGrid>
      <ItemGrid style={rowStyle} width={`${colWidthPercentual}%`}>
        {totalValue}
      </ItemGrid>
      {fields?.map(field => (
        <ItemGrid width={`${colWidthPercentual}%`} key={uuidv4()}>
          <Typography style={rowStyle}>{value[field as keyof DayPartInfo]}</Typography>
          {!isTotalRow && <Typography>{getPercentValue(value[field as keyof DayPartInfo] as number)}</Typography>}
        </ItemGrid>
      ))}
    </ContainerGrid>
  );
};

export default AirPlayCellRenderer;
