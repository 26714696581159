import { Box, makeStyles } from '@material-ui/core';
import LoadDataGrid from 'components/LoadDataGrid';
import NoDataGrid from 'components/NoDataGrid/indx';
import RequestGridError from 'components/RequestGridError';
import React from 'react';
import ReportResult from 'services/ReportResult';

const useStyles = makeStyles({
  visible: {
    display: 'block',
  },
  hidden: {
    display: 'none',
  },
});

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface GridVisibilityWrapperProps {
  isLoading?: boolean;
  reportData?: ReportResult<unknown>;
  filters?: any;
  isError?: boolean;
}

const GridVisibilityWrapper: React.FC<GridVisibilityWrapperProps> = ({
  isLoading,
  reportData,
  children,
  filters = null,
  isError = false,
}) => {
  const showError = isError;
  const showNoData = !reportData?.Items?.length && !isLoading && !isError;
  const showLoading = isLoading;
  const showData = !reportData?.Items?.length || isLoading;

  const classes = useStyles();

  return (
    <>
      <Box className={showError ? classes.visible : classes.hidden}>
        <RequestGridError />
      </Box>
      <Box className={showNoData ? classes.visible : classes.hidden}>{filters && <NoDataGrid />}</Box>
      <Box className={showLoading ? classes.visible : classes.hidden}>
        <LoadDataGrid />
      </Box>
      <Box className={showData ? classes.hidden : classes.visible}>{children}</Box>
    </>
  );
};

export default GridVisibilityWrapper;
