import TabsGrid from 'components/TabsGrid';
import TabPanel from 'components/TabsGrid/TabPanel';
import { Container, GridContainer } from 'pages/Reports/styles';
import React, { useEffect, useState } from 'react';
import PreviousNextSelect from 'components/PreviousNextWeekSelect';
import _ from 'lodash';
import { DateRangeGroup } from 'types/DateRange';
import { useTranslation } from 'react-i18next';
import RollingChartGrid from './RollingChartGrid';
import { RollingAvailable, RollingFilterParams } from './utils/types';
import TwelveWeekGrid from '../TwelveWeekReport/TwelveWeekGrid';
import OnNewUpDownGrid from '../OnNewUpReport/OnNewUpDownGrid';

export interface RollingChartProps {
  filters: RollingFilterParams | undefined;
  onChangeFilter: { (range: DateRangeGroup): void };
  dateRange?: RollingAvailable;
}

const RollingChartReport: React.FC<RollingChartProps> = ({ filters, onChangeFilter, dateRange }) => {
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState<number>(0);
  const [date, setDate] = useState<DateRangeGroup>();
  useEffect(() => {
    const get = _.get(filters, ['DateRange']);
    setDate(get);
  }, [filters]);
  const tabs = [
    { label: t('report.tab.rollingChart'), value: 'rollingChart' },
    // { label: t('report.tab.twelveWeekRankTrend'), value: 'twelveWeekRankTrend' },
    // { label: t('report.tab.twelveWeekSpinTrend'), value: 'twelveWeekSpinTrend' },
    // { label: t('report.tab.twelveWeekImpressionsTrend'), value: 'twelveWeekImpressionsTrend' },
    // { label: t('report.tab.onNewUpDown'), value: 'onNewUpDown' },
  ];
  return (
    <>
      <Container container direction="column" spacing={0}>
        {tabValue === 0 && filters && date && (
          <PreviousNextSelect
            dateMax={(dateRange?.ThisWeekRange.End as Date) ?? new Date()}
            group={date}
            onChange={onChangeFilter}
          />
        )}
        <GridContainer>
          <TabsGrid tabValue={tabValue} tabs={tabs} onChangeTab={(tab: number) => setTabValue(tab)}>
            <TabPanel value={tabValue} index={0}>
              <RollingChartGrid dateRange={dateRange} filters={filters} />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <TwelveWeekGrid filters={filters} twelveWeekType="rank" />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <TwelveWeekGrid filters={filters} twelveWeekType="spin" />
            </TabPanel>
            <TabPanel value={tabValue} index={3}>
              <TwelveWeekGrid filters={filters} twelveWeekType="impression" />
            </TabPanel>
            <TabPanel value={tabValue} index={4}>
              <OnNewUpDownGrid filters={filters} />
            </TabPanel>
          </TabsGrid>
        </GridContainer>
      </Container>
    </>
  );
};

export default RollingChartReport;
