/* eslint-disable react/jsx-one-expression-per-line */
import Input from '@material-ui/core/Input';
import React, { ReactElement, useEffect } from 'react';
import FilterDataEvent from 'types/FilterDataEvent';
import FilterDataProps from 'types/FilterDataProps';
import { Container, FormatLabel, useStyles } from './styles';

interface Props<T> extends FilterDataProps<T> {
  name?: string;
  fullWidth?: boolean;
  valueToApi: keyof T;
  disabled?: boolean;
  minValue?: number;
  maxValue?: number;
}

const InputNumberSelection = <T extends {}>({
  name = 'Title',
  onChange,
  fullWidth,
  disabled = false,
  valueToApi,
  initialValue,
  minValue = Number.MIN_SAFE_INTEGER,
  maxValue = Number.MAX_SAFE_INTEGER,
}: Props<T>): ReactElement => {
  const classes = useStyles();
  const [values, setValues] = React.useState<string | number>();

  useEffect(() => {
    setValues(initialValue as string);
  }, [initialValue]);

  const handleChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setValues(inputValue);
    onChange({ value: ({ [valueToApi]: inputValue } as unknown) as T } as FilterDataEvent<T>);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleOnBlur = () => (event: any) => {
    let inputValue = event.target.value;
    inputValue = Number(inputValue) > maxValue ? maxValue : inputValue;
    inputValue = Number(inputValue) < minValue ? minValue : inputValue;
    setValues(inputValue);
    onChange({ value: ({ [valueToApi]: inputValue } as unknown) as T } as FilterDataEvent<T>);
  };

  return (
    <Container>
      <FormatLabel>{name}:</FormatLabel>
      <Input
        id="standard-adornment-weight"
        disabled={disabled}
        data-testid="field-input-number"
        value={values}
        fullWidth={fullWidth}
        type="number"
        classes={{ input: classes.Input }}
        inputProps={{ 'data-testid': 'input-number', min: minValue, max: maxValue }}
        onChange={handleChange()}
        onBlur={handleOnBlur()}
      />
    </Container>
  );
};

InputNumberSelection.defaultProps = {
  fullWidth: false,
  name: 'Title',
  disabled: false,
  minValue: Number.MIN_SAFE_INTEGER,
  maxValue: Number.MAX_SAFE_INTEGER,
};

export default InputNumberSelection;
