import { Input } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FilterDataProps from 'types/FilterDataProps';

import { Container, FormatLabel } from './styles';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface Props extends FilterDataProps<any> {
  apiPropertyName?: string;
  initialValue?: number;
  defaultValue?: number;
}

const MinSpins: React.FC<Props> = ({
  onChange = null,
  apiPropertyName = 'MinSpins',
  initialValue = 1,
  defaultValue = 1,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (onChange) {
      onChange({ value: { [apiPropertyName]: initialValue } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  const handleChange = (ev: React.ChangeEvent<{ value: unknown }>): void => {
    const { value } = ev.target;
    if (onChange) {
      onChange({ value: { [apiPropertyName]: value } });
    }
  };

  return (
    <Container>
      <FormatLabel>{t('grid.header.MinSpins')}</FormatLabel>
      <Input type="number" onChange={handleChange} defaultValue={defaultValue} />
    </Container>
  );
};

export default MinSpins;
