import { AgGridColumn } from '@ag-grid-community/react';
import DetailGrid from 'components/DetailGrid';
import { TFunction } from 'i18next';
import React from 'react';
import Daily from './Daily';
import { TrendingAnalysisReport } from './utils/TrendingAnalysisReport';
import Weekly from './Weekly';

const NationalSummary = (reportSummary: TrendingAnalysisReport, translate: TFunction): JSX.Element => {
  if (!reportSummary.NationalSummary) {
    return <></>;
  }

  return (
    <DetailGrid reportData={[reportSummary.NationalSummary]}>
      {Daily({ name: 'DAILY', range: reportSummary.DateRange, field: 'DailySpinTrends' })}
      {Weekly({ name: 'WEEKLY', field: 'WeeklySpinTrends' })}
      <AgGridColumn field="SpinsToDate" headerName={translate('grid.header.SpinsToDay')} minWidth={80} />
    </DetailGrid>
  );
};

export default NationalSummary;
