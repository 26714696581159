import FilterButton from 'components/FilterButton';
import PreviousNextWeekSelect from 'components/PreviousNextWeekSelect';
import ReportHeader from 'components/ReportHeader';
import SiblingReportsSelect from 'components/SiblingReportsSelect/SiblingReportsSelect';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateRangeGroup } from 'types/DateRange';
import { Station } from 'types/Station';
import { getStationDisplayName } from 'utils/reports/GetStationDisplayName';
import SelectInput from '../../../../../components/SelectInput';
import { BodyReportContainer, Container, GridContainer, ReportContainer, SongsContainer } from '../../../styles';
import Filter from './Filter';
import HourByHourGrid from './HourByHourGrid';
import { ExtendedDalyLogsFilter } from './utils/types';

const DailyLogsReport: React.FC = () => {
  const { t } = useTranslation();

  const [filterOpened, setFilterOpened] = useState(false);
  const [filter, setFilter] = useState<ExtendedDalyLogsFilter>();

  const onChangeFilter = (filtersParams?: ExtendedDalyLogsFilter) => {
    const StationInfo = filtersParams?.Stations?.length ? filtersParams.Stations[0] : undefined;
    const newFilter = { ...filtersParams, StationInfo };
    setFilter(newFilter);
    setFilterOpened(false);
  };

  const onChangeStation = (station: Station) => {
    setFilter({ ...filter, StationInfo: station });
  };

  const moveDate = (dateRangeGroup: DateRangeGroup) => {
    const DateRange = { Start: new Date(dateRangeGroup.Start), End: new Date(dateRangeGroup.End) };
    setFilter({ ...filter, DateRange, EndDate: DateRange.End });
  };

  return (
    <>
      <Container container direction="column" spacing={0}>
        <ReportHeader title={t('report.label.station')} subtitle={t('report.label.dailyCurrent')} />
        <ReportContainer>
          <SiblingReportsSelect />
          <FilterButton onClickFilter={() => setFilterOpened(!filterOpened)} isOpened={filterOpened} />
        </ReportContainer>
        <Filter isOpened={filterOpened} changeFilter={onChangeFilter} />
        <BodyReportContainer hidden={!filter}>
          <SongsContainer>
            <SelectInput
              normalizeLabel={option => `${getStationDisplayName(option)}`}
              optionLabel={t('filter.title.station')}
              options={filter?.Stations ?? []}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              value={filter?.StationInfo ?? ''}
              onChangeValue={onChangeStation}
            />
          </SongsContainer>
          {filter?.DateRange && <PreviousNextWeekSelect group={filter?.DateRange} onChange={moveDate} stepInDays={0} />}
          <GridContainer>
            <HourByHourGrid filters={filter} />
          </GridContainer>
        </BodyReportContainer>
      </Container>
    </>
  );
};

export default DailyLogsReport;
