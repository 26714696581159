/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import { AgGridColumn } from '@ag-grid-community/react';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import DayOfTheWeekCellRenderer from 'components/DetailGrid/CellRenderers/DaysOfTheWeekRenderer';
import DaysOfTheWeekComponent from 'components/DetailGrid/HeaderComponents/DaysOfTheWeekComponent';
import React from 'react';
import { DateRangeGroup } from 'types/DateRange';
import { numberFormat } from 'utils/currency';

interface DailyRep {
  name?: string;
  range?: DateRangeGroup;
  field?: string;
  onSortChange?: (sortPath: string) => void;
  hide?: boolean;
  formatNumber?: boolean;
  minWidth?: number;
  width?: number;
}

const DailyReport = ({
  name = 'Daily',
  range,
  field = 'DailyImpressionsTrends',
  hide = false,
  formatNumber = false,
  minWidth = 290,
  width = 290,
  onSortChange,
}: DailyRep): JSX.Element | null => {
  return range?.Start ? (
    <AgGridColumn
      field={field}
      cellRendererFramework={DayOfTheWeekCellRenderer}
      headerComponentFramework={DaysOfTheWeekComponent}
      minWidth={minWidth}
      valueFormatter={({ value }: ValueFormatterParams) =>
        value ? (formatNumber ? numberFormat(value) : value) : '--'
      }
      width={width}
      headerComponentParams={{ name, rangeDate: range, onSortChange, formatNumber }}
      wrapText
      hide={hide}
    />
  ) : null;
};

DailyReport.defaultProps = {
  name: 'Daily',
};

export default DailyReport;
