import { AgGridColumn } from '@ag-grid-community/react';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import MultiColumnsCellRenderer from 'components/DetailGrid/CellRenderers/MultiColumnsCellRenderer/MultiColumnsCellRenderer';
import RankInfoCellRendererRec from 'components/DetailGrid/CellRenderers/RankInfoCellRendererRec/RankInfoCellRendererRec';
import SongCellRenderer from 'components/DetailGrid/CellRenderers/SongCellRenderer/SongCellRenderer';
import ThisLastMoveCellRenderer from 'components/DetailGrid/CellRenderers/ThisLastMoveCellRenderer';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import { UseApiSettings } from 'hooks/useApi/ApiTypes';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import { IndieChartFilter } from './utils/IndieChartReportTypes';
import { SevenDayIndieChartBuildingTransformer } from './utils/SevenDayIndieChartTransform';
import { SevenDayIndieChartBuildingResponse } from './utils/SevenDayIndieChartType';

interface IndieChartProps {
  filters: IndieChartFilter | undefined;
}

const url = 'seven-day-indie-chart-reports';

const SevenDayIndieChartBuildingGrid: React.FC<IndieChartProps> = ({ filters }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({
    sortPath: 'RankThisWeek',
    sort: 'asc',
  });

  const { setSettings, settings, data: reportData, isLoading, isError } = useRemoteApi<
    SevenDayIndieChartBuildingResponse
  >(url, {
    data: filters,
    method: 'POST',
    enabled: false,
    transformRequest: data => {
      return {
        ...data,
        PanelInfo: {
          Id: 1,
          PanelCode: 'M',
          Name: 'Mediabase - All Stations (U.S)',
          ShortName: 'Mediabase All (U.S)',
          DisplayOrder: 1,
          IsPublished: false,
        },
      };
    },
    transformResponse: SevenDayIndieChartBuildingTransformer,
  });

  return (
    <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
      <ServerSideReportGrid
        setSettings={(setSettings as unknown) as Dispatch<UseApiSettings<ReportResult<unknown>>>}
        settings={(settings as unknown) as UseApiSettings<ReportResult<unknown>>}
        filters={filters}
        sortModel={sortModel}
      >
        <AgGridColumn
          headerName={t('grid.header.Rank')}
          minWidth={60}
          width={60}
          field="Rank"
          headerComponentParams={{
            sortModel,
            onSortChange,
            disposition: 'row',
            secondLine: [
              { label: 'grid.header.TW', sortPath: 'RankThisWeek' },
              { label: 'grid.header.LW', sortPath: 'RankLastWeek' },
            ],
          }}
          cellRendererParams={{
            fields: ['Rank.ThisWeek', 'Rank.LastWeek'],
          }}
          cellRendererFramework={RankInfoCellRendererRec}
          headerComponentFramework={MultiColumnsHeaderComponent}
        />
        <AgGridColumn
          field="SongInfo"
          headerName={t(`grid.header.Title`)}
          minWidth={160}
          pinned
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            sortPath: 'Title',
            disposition: 'row',
            secondLine: [
              { label: 'grid.header.Artist', sortPath: 'Artist' },
              { label: 'grid.header.Label', sortPath: 'Label' },
            ],
          }}
          cellRendererFramework={SongCellRenderer}
        />
        <AgGridColumn
          field="SpinsInfo"
          minWidth={85}
          width={85}
          headerName={t(`grid.header.SpinsInfoTW`)}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            sortPath: 'SpinsInfo/ThisWeek',
            secondLine: [
              { label: 'grid.header.LW', sortPath: 'SpinsInfo/LastWeek' },
              { label: 'grid.header.plusMinus', sortPath: 'SpinsInfo/Move' },
            ],
          }}
          cellRendererFramework={ThisLastMoveCellRenderer}
        />
        <AgGridColumn
          field="DayByDaySpinsDynamic"
          headerName={t('grid.header.DayByDaySpins')}
          width={250}
          minWidth={250}
          valueFormatter={({ value }: ValueFormatterParams) => `${+parseFloat(value).toFixed(3)}`}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            secondLine: reportData?.DayByDaySpinsHeaderDynamic,
          }}
          cellRendererFramework={MultiColumnsCellRenderer}
          cellRendererParams={{
            fields: reportData?.DayByDaySpinsFieldsDynamic,
          }}
        />
      </ServerSideReportGrid>
    </GridVisibilityWrapper>
  );
};

export default SevenDayIndieChartBuildingGrid;
