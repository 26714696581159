import axios from 'axios';
import LoadDataGrid from 'components/LoadDataGrid';
import AppSettings from 'contexts/AppSettingsContext';
import React, { useContext, useEffect, useState } from 'react';
import DEFAULT_BANNER_ADVERTISE from '../../../../images/default_banner_advertise.jpg';
import DEFAULT_POSTER from '../../../../images/default_poster.gif';
import { AdsContainer, AdsImage, Container } from './styles';
import { LoginAdvertiseTypes } from './utils/LoginAdvertiseTypes';

const loginAdsUrl = '/login-page-advertisements';
const TOP40_FORMAT = 'H1';
const LoginAdvertiseComponent: React.FC = () => {
  const { baseUrl } = useContext(AppSettings);
  const { endpointURL } = useContext(AppSettings);
  const [advertise, setAdvertise] = useState<LoginAdvertiseTypes[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasError, setError] = useState(false);

  const fetchLoginAdvertise = async (defaultFormatCode: string) => {
    try {
      setIsLoading(true);
      const { data } = await axios.get<LoginAdvertiseTypes[]>(`${endpointURL}${loginAdsUrl}`, {
        params: { formatCode: defaultFormatCode },
      });
      setAdvertise(data);
    } finally {
      setIsLoading(false);
    }
  };

  const goToPosterLink = () => {
    if (advertise[0]?.MobilePosterLink) {
      window.open(advertise[0]?.MobilePosterLink);
    }
  };

  const goToBannerLink = () => {
    if (advertise[0]?.MobileBannerLink) {
      window.open(advertise[0]?.MobileBannerLink);
    }
  };

  useEffect(() => {
    const defaultFormatCodeStorage = localStorage.getItem('defaultFormatCode');
    fetchLoginAdvertise(defaultFormatCodeStorage ?? TOP40_FORMAT);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <LoadDataGrid />;
  }

  return (
    <Container>
      {advertise?.length && !hasError ? (
        <AdsContainer>
          <AdsImage
            src={`${baseUrl}${advertise[0]?.MobilePoster ?? ''}`}
            onError={() => setError(true)}
            onClick={goToPosterLink}
          />
          <AdsImage
            src={`${baseUrl}${advertise[0]?.MobileBanner ?? ''}`}
            onError={() => setError(true)}
            onClick={goToBannerLink}
          />
        </AdsContainer>
      ) : (
        <AdsContainer>
          <AdsImage src={DEFAULT_BANNER_ADVERTISE} />
          <AdsImage src={DEFAULT_POSTER} />
        </AdsContainer>
      )}
    </Container>
  );
};

export default LoginAdvertiseComponent;
