import React from 'react';
import { v4 } from 'uuid';
import { range } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Day } from 'types/Day';
import InnerTable from '../index';
import { IInnerTableGroup } from '../inner-table-group';

interface DailyTableProps {
  data: Day;
  name: string;
}

function getDays() {
  const days = range(7);
  return days.map(item => {
    return `-${item + 1}`;
  });
}

const DayByDaySpinsDailyInnerTable: React.FC<DailyTableProps> = ({ data, name }: DailyTableProps) => {
  const { t } = useTranslation();

  const innerTableData: IInnerTableGroup[] = [
    {
      title: t(name),
      children: [
        {
          title: '',
          children: getDays(),
        },
        {
          title: '',
          children: [
            data.Day1?.toString(),
            data.Day2?.toString(),
            data.Day3?.toString(),
            data.Day4?.toString(),
            data.Day5?.toString(),
            data.Day6?.toString(),
            data.Day7?.toString(),
          ],
        },
      ],
    },
  ];

  return <InnerTable key={v4()} data={innerTableData} />;
};

export default DayByDaySpinsDailyInnerTable;
