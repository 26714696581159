import { Rank, RankProps } from 'types/Rank';
import { SongInfo } from 'types/SongInfo';

export const ArtistInfoNormalize = (row: SongInfo): SongInfo => {
  return {
    Artist: row.Artist,
    Title: row.Title,
    SongCode: row.SongCode,
    ...(row.Label && { Label: row.Label }),
  };
};

export const RankNormalize = (row: RankProps<number, number>): Rank => {
  return {
    ThisWeek: row.ThisWeek,
    LastWeek: row.LastWeek,
  };
};
