// import {
//   CurrentChartResponse,
//   CurrentChartRow,
//   TransformedCurrentChartRow,
// } from 'pages/Reports/DailyCurrent/Chart/CurrentChartReport/utils/CurrentChartTypes';
// import ReportResult from 'services/ReportResult';
// import { DayByDaySpins } from 'types/DayByDaySpins';
// import { extractThisWeekDayByDaySpinsNonZeroValues } from 'utils/reports';
import {
  PublishedChartResponse,
  PublishedChartRow,
  TransformedPublishedChartResponse,
  TransformedPublishedChartRow,
} from './PublishedChartReportTypes';

// const convertArrayToObject = (array: number[], propertyKey = 'ThisWeek') => {
//   const initialValue = {};
//   return array.reduce((obj: any, item: number, idx) => {
//     return {
//       ...obj,
//       [idx]: { [propertyKey]: item },
//     };
//   }, initialValue);
// };

// const normalizeDayByDaySpins = (dayByDay: DayByDaySpins) => {
//   const filter = extractThisWeekDayByDaySpinsNonZeroValues(dayByDay);
//   return convertArrayToObject(filter);
// };

export const PublishedChartTransformer = (response: PublishedChartResponse): TransformedPublishedChartResponse => {
  const transformItems = (row: PublishedChartRow): TransformedPublishedChartRow => {
    return {
      ...row,
      SongInfo: {
        Artist: row.Artist,
        Label: row.Label,
        Title: row.Title,
        SongCode: row.SongCode,
      },
      Rank: {
        ThisWeek: row.RankThisWeek,
        LastWeek: row.RankLastWeek,
      },
      AverageStationRotation: {
        StationsPlayingTitle: row.StationsInfo.StationsPlayingTitle,
        ThisWeek: row.AveragePlayInfo.ThisWeek,
        LastWeek: row.AveragePlayInfo.LastWeek,
        Move: row.AveragePlayInfo.Move,
      },
    };
  };
  return {
    ...response,
    Items: response.Items.map(transformItems),
  };
};
