import useRemoteApi from 'hooks/useApi/useRemoteApi';
import { isArray } from 'lodash';
import { Dispatch } from 'react';
import { PanelCodeEnum } from 'types/Panel';
import { Station } from 'types/Station';

export interface SearchStationData {
  PanelCode: PanelCodeEnum;
  FormatId?: number;
  SearchType: number;
  SearchTerm: string;
  Year?: number;
}

export interface StationResolvers {
  searchStations: (params: SearchStationData, resolve: Dispatch<Station[]>) => Station[] | undefined;
  findByCallLetters: (callLetters: string, resolve: Dispatch<Station[]>) => void;
}

const url = '/stations/search';
const searchByCallLettersUrl = '/stations/search-by-call-letters';
function useStationResolver(): StationResolvers {
  const { data: searchedSongs, setSettings } = useRemoteApi<Station[]>(url, {
    method: 'POST',
    enabled: false,
  });
  const { setSettings: setSettingsByCallLetters } = useRemoteApi<Station[]>(searchByCallLettersUrl, {
    enabled: false,
    method: 'POST',
  });

  const findByCallLetters = (callLetters: string, onSuccess: Dispatch<Station[]>) => {
    setSettingsByCallLetters({
      enabled: true,
      data: { searchTerm: callLetters },
      transformResponse: data => {
        if (data && !isArray(data)) {
          return [data];
        }
        return data;
      },
      onSuccess,
    });
  };

  const searchStations = (data: SearchStationData, onSuccess: Dispatch<Station[]>) => {
    setSettings({
      enabled: true,
      data,
      onSuccess,
    });
    return searchedSongs;
  };

  return { findByCallLetters, searchStations };
}

export default useStationResolver;
