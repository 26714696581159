import { createStyles, Theme } from '@material-ui/core/styles';
import { Grid, makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Grid)`
  flex: 1;
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filter: {
      marginLeft: '5px',
      borderRadius: '0px',
      borderBottomWidth: '1px',
      backgroundColor: '#ffffff',
      width: '40px',
      minWidth: '40px',
      minHeight: '100%',
      '&:hover': {
        backgroundColor: '#ffffff',
      },
    },
    active: {
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    icon: {
      color: theme.palette.primary.main,
      right: 12,
      userSelect: 'none',
      pointerEvents: 'none',
    },
  }),
);
