import { Box, ListItem, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

export interface Props {
  id?: number | string;
  label?: number | string;
  selected?: boolean;
  onClick?: (selectedId?: number | string) => void;
}

const SelectableListItem: React.FC<Props> = ({ id = undefined, label = '', onClick = null, selected = false }) => {
  const { t } = useTranslation();
  const clickHandler = () => {
    if (onClick) onClick(id);
  };

  return (
    <>
      <ListItem key={uuidv4()} data-id={id} onClick={clickHandler}>
        <Typography key={uuidv4()} variant="body1">
          {t(`filter.label.${label}`)}
        </Typography>
        <Box key={uuidv4()}>{selected && <CheckIcon key={uuidv4()} color="primary" visibility="0" />}</Box>
      </ListItem>
    </>
  );
};

export default SelectableListItem;
