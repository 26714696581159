import { STATION_PORTFOLIO, STATION_PORTFOLIO_GROUP } from 'utils/Constants';
import { SongTrackingReportItem } from 'pages/Reports/7Day/Songs/SongTracking/types';
import { FormatInfo, FormatInfoChecked } from 'types/Format';
import { SongInfo } from 'types/SongInfo';
import { SongPortfolio } from 'types/SongPortfolio';
import { StationPortfolio } from 'types/StationPortfolio';
import { Weekly } from 'types/Weekly';
import { SongTracking } from 'types/SongTracking';

export interface SongTrackingReportFilter {
  Cycle?: number;
  FormatInfo?: FormatInfoChecked;
  Formats?: FormatInfo[];
  StationPortfolio?: StationPortfolio | null;
  StationPortfolioGroup?: StationPortfolio | null;
  IsPublishedStationsOnly?: boolean;
  SongInfo?: SongInfo;
  SpinMode?: number;
}
export interface ExtendedSongTrackingReportFilter extends SongTrackingReportFilter {
  stationGroup?: string;
  SongPortfolio?: SongPortfolio;
  FormatInfos?: FormatInfo[];
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface SongTrackingResult {
  Items?: any[];
  Count: number;
}

const normalizeSpinTrend = (spinTrends: Weekly | undefined, spinToDate: number | undefined) => {
  if (spinTrends) {
    const week1 = typeof spinTrends.Week1 === 'string' ? parseInt(spinTrends.Week1, 4) : spinTrends.Week1;
    const week2 = typeof spinTrends.Week2 === 'string' ? parseInt(spinTrends.Week2, 4) : spinTrends.Week2;
    return {
      ...spinTrends,
      SpinsToDate: spinToDate,
      Move: week1 - week2,
    };
  }
  return {};
};

const convertToNumber = (value: string | number) => {
  return typeof value === 'string' ? parseInt(value, 4) : value;
};
const subtractTwoValues = (first: string | number = 0, second: string | number = 0) => {
  const normalizeFirst = convertToNumber(first);
  const normalizeSecond = convertToNumber(second);
  return normalizeFirst - normalizeSecond;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const normalizeSongTracking = (reportData: SongTrackingReportItem): SongTrackingResult => {
  if (reportData.TotalInfo) {
    const StationPlaying = {
      PlayingThisWeek: reportData.TotalInfo?.PlayingThisWeek,
      PlayingLastWeek: reportData.TotalInfo?.PlayingLastWeek,
      PlayingMove: subtractTwoValues(reportData.TotalInfo?.PlayingThisWeek, reportData.TotalInfo?.PlayingLastWeek),
    };
    const NotPlaying = {
      NotPlayingLastWeek: reportData.TotalInfo?.NotPlayingLastWeek,
      NotPlayingThisWeek: reportData.TotalInfo?.NotPlayingThisWeek,
      NotPlayingMove: subtractTwoValues(
        reportData.TotalInfo?.NotPlayingThisWeek,
        reportData.TotalInfo?.NotPlayingLastWeek,
      ),
    };

    const ImpressionsTotalInfo = {
      ImpressionsThisWeek: reportData.TotalInfo?.ImpressionsThisWeek,
      ImpressionsLastWeek: reportData.TotalInfo?.ImpressionsLastWeek,
      ImpressionsMove: reportData.TotalInfo?.ImpressionsMove,
    };
    const Impression = {
      ImpressionsTotalInfo,
      SpinTrends: normalizeSpinTrend(reportData.TotalInfo?.SpinTrends, reportData.TotalInfo.SpinsToDate),
      StationPlaying,
      NotPlaying,
    };

    return { Items: [Impression], Count: 1 };
  }
  return { Items: [], Count: 0 };
};

export const normalizeDetailsGrid = (reportData: SongTrackingReportItem): SongTrackingResult => {
  return {
    Count: reportData.Count,
    Items: reportData.Items.map((item: SongTracking) => {
      return {
        ...item,
        SpinTrends: { ...item.SpinTrends, Move: item.Move },
      };
    }),
  };
};

export const transformSongTrackingFilters = (filter: ExtendedSongTrackingReportFilter | undefined): unknown => {
  return {
    ...filter,
    ...{
      StationPortfolio: filter?.stationGroup === STATION_PORTFOLIO ? filter?.StationPortfolio : undefined,
      StationPortfolioGroup:
        filter?.stationGroup === STATION_PORTFOLIO_GROUP ? filter?.StationPortfolioGroup : undefined,
    },
  };
};
