import { Column } from '@ag-grid-enterprise/all-modules';
import { format, subDays } from 'date-fns';
import React from 'react';
import { v4 } from 'uuid';
import InnerTable from '..';
import { IInnerTableGroup } from '../inner-table-group';

export interface LastWeekTrendsData {
  YesterdayLastWeek: number;
  LastWeekMove: number;
}

interface LastWeekTrendsProps {
  data: LastWeekTrendsData;
  invisibleColumns: Array<Column>;
}

const LastWeekTrendsInnerTable: React.FC<LastWeekTrendsProps> = ({ data, invisibleColumns }: LastWeekTrendsProps) => {
  const composeHeader = () => {
    return invisibleColumns[0].getColDef().headerName || '';
  };

  const innerTableData: IInnerTableGroup[] = [
    {
      title: composeHeader(),
      children: [
        {
          title: '',
          children: [`Last ${format(subDays(new Date(), 1), 'EEE').toUpperCase()}`, '+/-'],
        },
        {
          title: '',
          children: [data.YesterdayLastWeek.toString(), data.LastWeekMove.toString()],
        },
      ],
    },
  ];

  return <InnerTable key={v4()} data={innerTableData} />;
};

export default LastWeekTrendsInnerTable;
