import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => {
  return createStyles({
    'spot-break-time': {
      color: '#c6002b',
    },
    'spot-break-title': {
      '& p': {
        fontWeight: 'bolder',
      },
    },
  });
});
