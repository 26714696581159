/* eslint-disable @typescript-eslint/no-explicit-any */
import FormatFilter from 'components/Filter/components/FormatFilter';
import Grc from 'components/Filter/components/Grc';
import ShowTopSelect from 'components/Filter/components/ShowTopSelect';
import FilterBase from 'components/FilterBase';
import useStickyFilter from 'hooks/useStickyFilter/useStickyFilter';
import React from 'react';
import { useLocation } from 'react-router-dom';
import FilterDataEvent from 'types/FilterDataEvent';
import { FilterParams } from 'types/FilterParams';
import { PanelCodeEnum } from 'types/Panel';
import DateRange from 'components/Filter/components/CalendarFilter';
import InputNumberFilter from 'components/InputNumber';
import { useTranslation } from 'react-i18next';

interface FilterActivactorChartProps {
  changeFilter: { (filters: any): void };
  isOpened: boolean;
}

interface MinSpins {
  MinSpins: number;
}

const Filter: React.FC<FilterActivactorChartProps> = ({ changeFilter, isOpened }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { savedFilters: filter, saveFilter } = useStickyFilter<FilterParams>(pathname);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleFilterChange = (event: FilterDataEvent<any | FilterParams>) => {
    const { value } = event;
    saveFilter({ ...filter, ...value } as FilterParams);
  };

  const handleOnSubmit = () => {
    if (filter) {
      changeFilter(filter);
    }
  };

  return (
    <FilterBase onSubmit={handleOnSubmit} isOpened={isOpened}>
      <DateRange onChange={handleFilterChange} dateOptionsParameters="weeklyDaySunday" getDateFromApi />
      <FormatFilter
        panelCode={PanelCodeEnum.ActivatorPanel}
        onChange={handleFilterChange}
        hasAllOption={false}
        initialValue={filter?.FormatInfo}
      />
      <Grc onChange={handleFilterChange} initialValue={filter?.GrcInfo} />
      <ShowTopSelect onChange={handleFilterChange} apiPropertyName="TopX" initialValue={filter?.TopX} />
      <InputNumberFilter<MinSpins>
        minValue={1}
        onChange={handleFilterChange}
        name={t('grid.header.minSpinsCuttOff')}
        valueToApi="MinSpins"
        initialValue={filter?.MinSpins}
      />
    </FilterBase>
  );
};

export default Filter;
