import FormatFilter from 'components/Filter/components/FormatFilter';
import PanelFilter from 'components/Filter/components/PanelFilter';
import InputNumber from 'components/InputNumber';
import FilterBase from 'components/FilterBase';
import useStickyFilter from 'hooks/useStickyFilter/useStickyFilter';
import React from 'react';
import FilterDataEvent from 'types/FilterDataEvent';
import { PanelInfo, PanelCodeEnum } from 'types/Panel';
import { useTranslation } from 'react-i18next';
import { FormatInfo } from 'types/Format';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import { MinSpins } from '../PublishedChartReport/utils/PublishedChartReportTypes';
import { ArtistChartFilter } from './utils/ArtistChartTypes';

interface FilterCurrentChartProps {
  changeFilter: { (filters: ArtistChartFilter): void };
  isOpened: boolean;
}

interface FilterParams {
  PanelInfo?: PanelInfo;
  FormatInfo?: FormatInfo;
}

const Filter: React.FC<FilterCurrentChartProps> = ({ changeFilter, isOpened }) => {
  const pathname = getReportSpec(ReportBaseEnum.ArtistChartReport).stickyKey;
  const { t } = useTranslation();
  const { savedFilters: filter, saveFilter } = useStickyFilter<ArtistChartFilter>(pathname);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleFilterChange = (event: FilterDataEvent<FilterParams | MinSpins>) => {
    const { value } = event as FilterDataEvent<FilterParams>;
    saveFilter({ ...filter, ...value } as ArtistChartFilter);
  };

  const handleOnSubmit = () => {
    if (filter) {
      changeFilter(filter);
    }
  };

  return (
    <FilterBase onSubmit={handleOnSubmit} isOpened={isOpened}>
      <PanelFilter onChange={handleFilterChange} initialValue={filter?.PanelInfo} />
      <FormatFilter
        onChange={handleFilterChange}
        panelCode={filter?.PanelInfo?.PanelCode ?? PanelCodeEnum.AllUsStations}
        hasAllOption={false}
        initialValue={filter?.FormatInfo}
      />
      <InputNumber<MinSpins>
        onChange={handleFilterChange}
        minValue={1}
        name={t('grid.header.minSpinsCuttOff')}
        valueToApi="MinSpins"
        initialValue={filter?.MinSpins || 1}
      />
    </FilterBase>
  );
};

export default Filter;
