import { AgGridColumn } from '@ag-grid-community/react';
import DayPartsCellRenderer from 'components/DetailGrid/CellRenderers/DayPartsCellRenderer';
import DetailGrid from 'components/DetailGrid';
import DayPartsHeaderComponent from 'components/DetailGrid/HeaderComponents/DayPartsHeaderComponent';
import React, { Dispatch, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import { GridOptions, ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import { UseApiSettings } from 'hooks/useApi/ApiTypes';
import ReportResult from 'services/ReportResult';
import StationRankMarketFormatHeaderComponent from 'components/DetailGrid/HeaderComponents/StationRankMarketOwnerHeaderComponent';
import FilterDataEvent from 'types/FilterDataEvent';
import { formatDate } from 'utils/dates';
import { numberFormat } from 'utils/currency';
import FormatSelection from 'components/Filter/components/FormatSelection';
import { Button } from '@material-ui/core';
import { SevenDayChartUrl } from 'enums/urls';
import StationRankMarketFormatCellRenderer from '../../../../../components/DetailGrid/CellRenderers/StationRankMarketFormatCellRenderer';
import { ExtendedSongAnalysisFilter } from './utils/SongAnalysisTypes';
import { FilterGridContainer, FormatContainer } from './styles';
import {} from './utils/SongAnalysisTransformer';
import { SevenDaySongHistoryResponseTransformed } from './utils/SongHistoryTypes';
import { SongHistoryRequestTransformer, SongHistoryTransformerResponse } from './utils/SongHistoryTransformer';
import SongHistoryInnerGrid from './SongHistoryInnerGrid';
import { SongHistoryFilter } from './utils/FormatHistoryTypes';

export interface SongAnalysisGridProps {
  onChangeFilter: { (value: SongHistoryFilter): void };
  filters: ExtendedSongAnalysisFilter;
}

const url = SevenDayChartUrl.songAnalysisHistoryReports;
const SongHistoryGrid: React.FC<SongAnalysisGridProps> = ({ filters, onChangeFilter }) => {
  const [songHistoryFilter, setSongHistoryFilter] = useState<SongHistoryFilter | undefined>();

  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({ sortPath: 'SpinsToDate', sort: 'asc' });
  const { data: reportData, isLoading, settings, setSettings, isError } = useRemoteApi<
    SevenDaySongHistoryResponseTransformed
  >(url, {
    data: filters,
    method: 'POST',
    enabled: false,
    transformRequest: SongHistoryRequestTransformer,
    transformResponse: SongHistoryTransformerResponse,
  });
  const TopOptions: GridOptions = {
    alignedGrids: [],
    defaultColDef: {
      editable: true,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 100,
    },
    suppressHorizontalScroll: true,
  };
  const BottomOptions: GridOptions = {
    alignedGrids: [],
    defaultColDef: {
      editable: true,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 100,
    },
  };
  ((TopOptions as GridOptions).alignedGrids ?? []).push(BottomOptions);
  ((BottomOptions as GridOptions).alignedGrids ?? []).push(TopOptions);

  const onChangeSongHistoryFilter = (event: FilterDataEvent<SongHistoryFilter>) => {
    const { value } = event;
    setSongHistoryFilter({
      ...songHistoryFilter,
      ...value,
    });
  };

  const songsHistoryGrid = <SongHistoryInnerGrid filters={filters} />;
  return (
    <>
      <FilterGridContainer>
        <FormatContainer>
          <FormatSelection
            onChange={onChangeSongHistoryFilter}
            panelCode={filters?.PanelInfo?.PanelCode}
            apiPropertyName="FormatInfo"
            isArray={false}
          />
        </FormatContainer>
        <Button
          style={{ justifyContent: 'flex-end', fontSize: '10px' }}
          color="primary"
          onClick={() => onChangeFilter(songHistoryFilter as SongHistoryFilter)}
        >
          {t('filter.title.apply')}
        </Button>
      </FilterGridContainer>
      <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
        <ServerSideReportGrid
          sortModel={sortModel}
          settings={(settings as unknown) as UseApiSettings<ReportResult<unknown>>}
          setSettings={(setSettings as unknown) as Dispatch<UseApiSettings<ReportResult<unknown>>>}
          filters={filters}
          expandCellRendererProps={{
            footerElement: songsHistoryGrid,
          }}
          detailProps={{
            gridOptions: TopOptions,
            containerStyle: {
              height: '40vh',
            },
          }}
        >
          <AgGridColumn
            field="StationDisplayName"
            minWidth={150}
            cellRendererFramework={StationRankMarketFormatCellRenderer}
            headerComponentFramework={StationRankMarketFormatHeaderComponent}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="GroupOwnerName"
            minWidth={140}
            width={140}
            headerName={t(`grid.header.GroupOwner`)}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="FirstPlayedDate"
            valueFormatter={({ value }: ValueFormatterParams) => formatDate(value)}
            headerName={t(`grid.header.FirstPlayedDate`)}
            minWidth={130}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="SpinsToDate"
            minWidth={80}
            width={80}
            headerName={t(`grid.header.TD`)}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            headerName="Day Parts"
            field="DaypartsInfo"
            width={400}
            minWidth={400}
            headerComponentFramework={DayPartsHeaderComponent}
            headerComponentParams={{ sortModel, onSortChange }}
            cellRendererFramework={DayPartsCellRenderer}
          />
          <AgGridColumn
            field="TotalImpressions"
            minWidth={100}
            width={100}
            valueFormatter={({ value }: ValueFormatterParams) => numberFormat(value)}
            headerName={t(`grid.header.TotalImpressions`)}
            headerComponentParams={{ sortModel, onSortChange }}
          />
        </ServerSideReportGrid>
        <DetailGrid
          reportData={[reportData?.TotalItems] ?? []}
          headerHeight={0}
          gridOptions={BottomOptions}
          domLayout="autoHeight"
          containerStyle={{ height: '100%' }}
          rowStyle={{ fontWeight: 'bold' }}
        >
          <AgGridColumn
            field="StationDisplayName"
            minWidth={150}
            headerName={t(`grid.header.StationName`)}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="GroupOwnerName"
            minWidth={140}
            width={140}
            headerName={t(`grid.header.Group`)}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="FirstPlayedDate"
            valueFormatter={({ value }: ValueFormatterParams) => formatDate(value)}
            headerName={t(`grid.header.FirstPlayedDate`)}
            minWidth={130}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="SpinsToDate"
            minWidth={80}
            width={80}
            headerName={t(`grid.header.TD`)}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            headerName="Day Parts"
            field="DaypartsInfo"
            width={400}
            minWidth={400}
            headerComponentFramework={DayPartsHeaderComponent}
            headerComponentParams={{ sortModel, onSortChange }}
            cellRendererFramework={DayPartsCellRenderer}
          />
          <AgGridColumn
            field="TotalImpressions"
            minWidth={100}
            width={100}
            valueFormatter={({ value }: ValueFormatterParams) => numberFormat(value)}
            headerName={t(`grid.header.TotalImpressions`)}
            headerComponentParams={{ sortModel, onSortChange }}
          />
        </DetailGrid>
      </GridVisibilityWrapper>
    </>
  );
};

export default SongHistoryGrid;
