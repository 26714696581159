import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import defaultTheme from '../../../themes/index';

const Container = styled.div`
  width: 100%;
  line-height: 1.5em;
`;

/* eslint-disable @typescript-eslint/no-explicit-any */
interface ThisLastMoveCellRendererProps {
  value?: {
    ThisWeek: number;
    LastWeek: number;
    Move?: number;
  };
  data?: any;
  reportQuery: any;
  thisWeekClickHandler: (data?: any) => void;
  showMove: boolean;
}

const ThisLastMoveCellRenderer: React.FC<ThisLastMoveCellRendererProps> = ({
  value,
  data,
  thisWeekClickHandler,
  reportQuery,
  showMove = true,
}: ThisLastMoveCellRendererProps) => {
  const getColor = (baseValue: number, actualValue: number) => {
    if (baseValue > actualValue) return defaultTheme.palette.error.main;
    if (baseValue < actualValue) return defaultTheme.palette.success.main;
    return defaultTheme.palette.text.primary;
  };

  const createSpan = (baseValue: number, actualValue: number, addPlus = false) => {
    return (
      <span style={{ color: getColor(baseValue, actualValue) }}>
        {(addPlus && actualValue > 0 ? ' +' : ' ') + actualValue}
      </span>
    );
  };

  const getLink = (el: JSX.Element, onClick?: (rowData?: any, reportFilters?: any) => void) => {
    if (!onClick) return el;
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Link
        to="#"
        onClick={evt => {
          evt.preventDefault();
          onClick(data, reportQuery);
        }}
      >
        {el}
      </Link>
    );
  };

  const getElements = (): JSX.Element => {
    if (!value) return <></>;
    return (
      <Container>
        {getLink(createSpan(value.LastWeek, value.ThisWeek), thisWeekClickHandler)}
        <br />
        {createSpan(value.ThisWeek, value.LastWeek)}
        {showMove && !!value.Move && createSpan(0, value.Move, true)}
      </Container>
    );
  };

  return getElements();
};

ThisLastMoveCellRenderer.defaultProps = { value: undefined };

export default ThisLastMoveCellRenderer;
