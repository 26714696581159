import useRemoteApi from 'hooks/useApi/useRemoteApi';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CarouselContainer, Title, Description, Label, useStyles } from './styles';
import { AnnouncementItem } from './utils/AnnouncementTypes';

const announcementsUrl = '/announcements/dashboard';
const AnnouncementCard: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { data: announcimentData } = useRemoteApi<AnnouncementItem[]>(announcementsUrl, {
    method: 'GET',
    enabled: true,
  });

  if (!announcimentData?.length) {
    return <></>;
  }
  return (
    <CarouselContainer autoPlay={false}>
      {announcimentData.map((item, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Card key={i}>
          <Label>{t('page.dashboard.announcement')}</Label>
          <br />
          <Title className={classes.title} dangerouslySetInnerHTML={{ __html: item?.ContentHeader ?? '' }} />
          <Description className={classes.description} dangerouslySetInnerHTML={{ __html: item?.ContentText ?? '' }} />
        </Card>
      ))}
    </CarouselContainer>
  );
};

export default AnnouncementCard;
