import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      textTransform: 'none',
      fontWeight: 'normal',
      color: 'none',
      fontSize: '1em',
      width: 'content',
      '& div': {
        whiteSpace: 'normal',
      },

      '& .enabled:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
      },

      '& .disabled:hover': {
        cursor: 'pointer',
        textDecoration: 'none',
      },
    },
    icon: {
      fontSize: '12px',
    },
  }),
);
