/* eslint-disable no-param-reassign */
import { AgGridColumn } from '@ag-grid-community/react';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import BooleanCanadaCheckMarkCellRenderer from 'components/DetailGrid/CellRenderers/BooleanCanadaCheckMarkCellRenderer';
import DayPartsCellRenderer from 'components/DetailGrid/CellRenderers/DayPartsCellRenderer';
import FormatMoveCellRenderer from 'components/DetailGrid/CellRenderers/FormatMoveCellRenderer';
import MultiColumnsCellRenderer from 'components/DetailGrid/CellRenderers/MultiColumnsCellRenderer/MultiColumnsCellRenderer';
import RankInfoCellRendererRec from 'components/DetailGrid/CellRenderers/RankInfoCellRendererRec/RankInfoCellRendererRec';
import SongCellRenderer from 'components/DetailGrid/CellRenderers/SongCellRenderer/SongCellRenderer';
import ThisLastMoveCellRenderer from 'components/DetailGrid/CellRenderers/ThisLastMoveCellRenderer';
import DayPartsHeaderComponent from 'components/DetailGrid/HeaderComponents/DayPartsHeaderComponent';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import ThisLastMoveHeaderComponent from 'components/DetailGrid/HeaderComponents/ThisLastMoveHeaderComponent';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import { SevenDayChartUrl } from 'enums/urls';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import { UseApiSettings } from 'hooks/useApi/ApiTypes';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import queryString from 'query-string';
import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import { GrcInfoEnum } from 'types/GrcInfo';
import { ReportCycleIdEnum } from 'types/ReportCycle';
import { formatDate, formatDateTypes } from 'utils/dates';
import { isCountryFilter, showCanconGrid } from 'utils/reports';
import _isInteger from 'lodash/isInteger';
import { PublishedChartFilter, PublishedChartResponse } from './utils/PublishedChartReportTypes';
import { PublishedChartTransformer } from './utils/PublishedChartTransformer';

interface PublishedChartProps {
  filters: PublishedChartFilter | undefined;
}

const url = SevenDayChartUrl.publishedChartReports;

const PublishedChartGrid: React.FC<PublishedChartProps> = ({ filters }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({
    sortPath: 'RankThisWeek',
    sort: 'asc',
  });

  const { setSettings, settings, data: reportData, isLoading, isError } = useRemoteApi<PublishedChartResponse>(url, {
    data: filters,
    method: 'POST',
    enabled: false,
    transformRequest: data => {
      data.IsArchived = isArchived(data);
      data.DateRange.Start = formatDate(data.DateRange.Start, formatDateTypes.yyyyMMdd);
      data.DateRange.End = formatDate(data.DateRange.End, formatDateTypes.yyyyMMdd);
      if (data.IsArchived || typeof data.GrcInfo === 'undefined') {
        data.GrcInfo = {
          GrcCode: GrcInfoEnum.CurrentsOnly,
          Name: 'Currents Only',
        };
      }
      return data;
    },
    transformResponse: PublishedChartTransformer,
  });

  const isArchived = (chartFilters: PublishedChartFilter | undefined): boolean => {
    let result = false;
    if (chartFilters) {
      const endFormatted = formatDate(chartFilters.DateRange.End, formatDateTypes.yyyyMMdd);

      const maxDate = chartFilters?.MaxDate ? chartFilters.MaxDate : new Date();
      const maxDateFormatted = formatDate(maxDate, formatDateTypes.yyyyMMdd);

      result = endFormatted !== maxDateFormatted;
    }
    return result;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const songClickHandler = (data: any, reportQuery: PublishedChartFilter | undefined) => {
    const href = getReportSpec(ReportBaseEnum.SongAnalysisReport).path;
    const params = {
      songCode: data.SongInfo?.SongCode,
      'Formats.FormatCode': [reportQuery?.FormatInfo?.FormatCode],
      'PanelInfo.Id': reportQuery?.PanelInfo.Id,
      ReportCycle: ReportCycleIdEnum.published,
      deepLink: true,
    };
    window.open(`${href}?${queryString.stringify(params)}`);
  };

  const normalizeImpressionInfo = (value: string): string => {
    const parseValue = parseFloat(value);
    // eslint-disable-next-line no-nested-ternary
    return parseValue === 9999 ? 'R' : _isInteger(parseValue) ? value : parseValue.toFixed(3);
  };

  const getImpressionsHeaders = () => {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    const secondLines: any[] = [{ label: `grid.header.TW`, sortPath: 'ImpressionsInfo/SpinsThisWeek' }];
    if (isArchived(filters) === false) {
      secondLines.push({ label: `grid.header.LW`, sortPath: 'ImpressionsInfo/SpinsLastWeek' });
      secondLines.push({ label: `grid.header.plusMinus`, sortPath: 'ImpressionsInfo/Move' });
      secondLines.push({ label: `grid.header.RTW`, sortPath: 'ImpressionsInfo/RankThisWeek' });
      secondLines.push({ label: `grid.header.RLW`, sortPath: 'ImpressionsInfo/RankLastWeek' });
    }
    return secondLines;
  };

  const getImpressionsFields = () => {
    const fields: string[] = ['ImpressionsInfo.SpinsThisWeek'];
    if (isArchived(filters) === false) {
      fields.push('ImpressionsInfo.SpinsLastWeek');
      fields.push('ImpressionsInfo.Move');
      fields.push('ImpressionsInfo.RankThisWeek');
      fields.push('ImpressionsInfo.RankLastWeek');
    }
    return fields;
  };

  return (
    <>
      <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
        <ServerSideReportGrid
          setSettings={(setSettings as unknown) as Dispatch<UseApiSettings<ReportResult<unknown>>>}
          settings={(settings as unknown) as UseApiSettings<ReportResult<unknown>>}
          filters={filters}
          sortModel={sortModel}
        >
          <AgGridColumn field="PeakRank" headerName={t(`grid.header.PeakRank`)} hide={isArchived(filters)} />
          <AgGridColumn
            headerName={t('grid.header.Rank')}
            minWidth={60}
            width={60}
            field="Rank"
            headerComponentParams={{
              sortModel,
              onSortChange,
              disposition: 'row',
              secondLine: [
                { label: 'grid.header.TW', sortPath: 'RankThisWeek' },
                { label: 'grid.header.LW', sortPath: 'RankLastWeek' },
              ],
            }}
            cellRendererParams={{
              fields: ['Rank.ThisWeek', 'Rank.LastWeek'],
            }}
            cellRendererFramework={RankInfoCellRendererRec}
            headerComponentFramework={MultiColumnsHeaderComponent}
          />
          <AgGridColumn
            field="SongInfo"
            headerName={t(`grid.header.Title`)}
            minWidth={150}
            maxWidth={240}
            pinned
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              sortPath: 'Title',
              disposition: 'row',
              secondLine: [
                { label: 'grid.header.Artist', sortPath: 'Artist' },
                { label: 'grid.header.Label', sortPath: 'Label' },
              ],
            }}
            cellRendererFramework={SongCellRenderer}
            cellRendererParams={{
              reportQuery: filters,
              /* eslint-disable @typescript-eslint/no-explicit-any */
              onClickHandler: (data: any, reportQuery: any) => songClickHandler(data, reportQuery),
            }}
          />
          <AgGridColumn
            field="WeeksOnChart"
            minWidth={80}
            width={80}
            headerName={t(`grid.header.WeeksOnChart`)}
            headerComponentParams={{ sortModel, onSortChange }}
            valueFormatter={({ value }: ValueFormatterParams) => (value === 0 ? '--' : value)}
            hide={isArchived(filters)}
          />
          <AgGridColumn
            field="HasCanadianContent"
            headerName="CanCon"
            minWidth={70}
            cellRendererFramework={BooleanCanadaCheckMarkCellRenderer}
            headerComponentParams={{ sortModel, onSortChange, sortPath: 'HasCanadianContent' }}
            hide={!showCanconGrid(filters)}
          />
          <AgGridColumn
            field="PointsInfo"
            minWidth={95}
            headerName={t(`grid.header.PointsInfoTW`)}
            cellRendererFramework={ThisLastMoveCellRenderer}
            headerComponentFramework={ThisLastMoveHeaderComponent}
            headerComponentParams={{ sortModel, onSortChange }}
            hide={!isCountryFilter(filters) || isArchived(filters)}
          />
          <AgGridColumn
            field="SpinsInfo"
            minWidth={95}
            headerName={t(`grid.header.SpinsInfoTW`)}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              sortPath: 'SpinsInfo/ThisWeek',
              secondLine: [
                { label: 'grid.header.LW', sortPath: 'SpinsInfo/LastWeek' },
                { label: 'grid.header.plusMinus', sortPath: 'SpinsInfo/Move' },
              ],
            }}
            cellRendererFramework={ThisLastMoveCellRenderer}
          />
          <AgGridColumn
            field="DaypartsInfo"
            headerName={t(`grid.header.DaypartsInfo`)}
            width={230}
            minWidth={230}
            headerComponentFramework={DayPartsHeaderComponent}
            headerComponentParams={{ sortModel, onSortChange }}
            cellRendererFramework={DayPartsCellRenderer}
          />
          <AgGridColumn
            field="ImpressionsInfo"
            colId="ImpressionsInfoForChart"
            headerName={t('grid.header.ImpressionsInfo')}
            minWidth={260}
            width={260}
            maxWidth={260}
            valueFormatter={({ value }: ValueFormatterParams) => (value ? normalizeImpressionInfo(value) : '--')}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              secondLine: getImpressionsHeaders(),
            }}
            cellRendererFramework={MultiColumnsCellRenderer}
            cellRendererParams={{
              fields: getImpressionsFields(),
            }}
          />
          <AgGridColumn
            headerName={t('grid.header.Stations')}
            minWidth={90}
            field="StationsInfo"
            /* eslint-disable @typescript-eslint/no-explicit-any */
            valueGetter={({ data }: any) => {
              return data && `${data.StationsInfo.StationsPlayingTitle} / ${data.StationsInfo.OnNew}`;
            }}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              secondLine: [{ label: 'grid.header.OnNew', sortPath: 'StationsInfo/OnNew' }],
            }}
          />
          <AgGridColumn
            field="AverageStationRotation"
            headerName={t('grid.header.AvgStationRotations')}
            width={400}
            minWidth={400}
            valueFormatter={({ value }: ValueFormatterParams) => (value ? `${+parseFloat(value).toFixed(1)}` : '--')}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              secondLine: [
                { label: `grid.header.On`, sortPath: 'StationsInfo/StationsPlayingTitle' },
                { label: `grid.header.TW`, sortPath: 'AveragePlayInfo/ThisWeek' },
                { label: `grid.header.LW`, sortPath: 'AveragePlayInfo/LastWeek' },
                { label: `grid.header.plusMinus`, sortPath: 'AveragePlayInfo/Move' },
              ],
            }}
            cellRendererFramework={MultiColumnsCellRenderer}
            cellRendererParams={{
              fields: [
                'AverageStationRotation.StationsPlayingTitle',
                'AverageStationRotation.ThisWeek',
                'AverageStationRotation.LastWeek',
                'AverageStationRotation.Move',
              ],
            }}
          />
          <AgGridColumn
            field="WithoutOvernight"
            headerName={t('grid.header.WithoutOvernight')}
            minWidth={120}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              secondLine: [
                { label: `grid.header.Rank`, sortPath: 'WithoutOvernight/Rank' },
                { label: `grid.header.TW`, sortPath: 'WithoutOvernight/SpinsThisWeek' },
              ],
            }}
            cellRendererFramework={MultiColumnsCellRenderer}
            cellRendererParams={{
              fields: ['WithoutOvernight.Rank', 'WithoutOvernight.SpinsThisWeek'],
            }}
            hide={isArchived(filters)}
          />
          <AgGridColumn
            field="AmToPmInfo"
            minWidth={150}
            width={150}
            headerName={t(`grid.header.H6amTo7pm`)}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              disposition: 'row',
              secondLine: [
                { label: ['grid.header.Rank'], sortPath: 'AmToPmInfo/Rank' },
                { label: ['grid.header.TW'], sortPath: 'AmToPmInfo/Spins' },
              ],
            }}
            cellRendererParams={{
              fields: ['AmToPmInfo.Rank', 'AmToPmInfo.Spins'],
            }}
            cellRendererFramework={MultiColumnsCellRenderer}
            hide={isArchived(filters)}
          />
          {(reportData?.FormatByFormatColumns ?? []).map(FormatByFormatColumn => (
            <AgGridColumn
              key={FormatByFormatColumn.FormatIndex}
              field={`FormatByFormatRanks.${FormatByFormatColumn.FormatIndex}`}
              minWidth={80}
              width={80}
              headerName={FormatByFormatColumn.DisplayName}
              headerComponentFramework={MultiColumnsHeaderComponent}
              headerComponentParams={{
                sortModel,
                onSortChange,
                disposition: 'row',
                secondLine: [
                  {
                    sortPath: `FormatByFormatRanks/${FormatByFormatColumn.FormatIndex}/FormatValue`,
                    label: ['grid.header.Rank'],
                  },
                ],
              }}
              cellRendererFramework={FormatMoveCellRenderer}
              valueFormatter={({ value }) => (value === 0 ? '-' : value ?? '')}
              hide={isArchived(filters)}
            />
          ))}
        </ServerSideReportGrid>
      </GridVisibilityWrapper>
    </>
  );
};

export default PublishedChartGrid;
