import { AgGridColumn } from '@ag-grid-community/react';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import DayPartsInfoColumn from 'components/ColumnsGrid/DayPartsInfoColumn';
import RankInfoCellRenderer from 'components/DetailGrid/CellRenderers/RankInfoCellRenderer/RankInfoCellRenderer';
import ThisLastMoveCellRenderer from 'components/DetailGrid/CellRenderers/ThisLastMoveCellRenderer';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import ArtistSongChartGrid from './ArtistSongGrid';
import { SevenDayArtistChartTransformer } from './utils/ArtistChartTransformer';
import { ArtistChartFilter, SevenDayArtistChartItem } from './utils/ArtistChartTypes';

export interface ArtistChartGridProps {
  filters: ArtistChartFilter | undefined;
}

const url = 'seven-day-artist-chart-reports';
const ArtistChartGrid: React.FC<ArtistChartGridProps> = ({ filters }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({ sortPath: 'RankThisWeek', sort: 'asc' });
  const { data: reportData, isLoading, settings, setSettings, isError } = useRemoteApi<
    ReportResult<SevenDayArtistChartItem>
  >(url, {
    data: filters,
    method: 'POST',
    enabled: false,
    transformResponse: SevenDayArtistChartTransformer,
    transformRequest: data => {
      return { ...data, TopX: 150, MinSpins: data?.MinSpins ?? 1 };
    },
  });

  const songsGrid = <ArtistSongChartGrid filters={filters} />;
  return (
    <>
      <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
        <ServerSideReportGrid
          sortModel={sortModel}
          settings={settings}
          setSettings={setSettings}
          filters={filters}
          expandCellRendererProps={{
            footerElement: songsGrid,
          }}
          detailProps={{
            containerStyle: {
              height: '60vh',
            },
          }}
        >
          <AgGridColumn
            headerName={t('grid.header.Rank')}
            minWidth={90}
            maxWidth={90}
            field="RankInfo"
            headerComponentParams={{
              sortModel,
              onSortChange,
              disposition: 'row',
              secondLine: [
                { label: 'grid.header.TW', sortPath: 'RankThisWeek' },
                { label: 'grid.header.LW', sortPath: 'RankLastWeek' },
              ],
            }}
            cellRendererParams={{
              fields: ['RankInfo.LastWeek', 'RankInfo.ThisWeek'],
            }}
            cellRendererFramework={RankInfoCellRenderer}
            headerComponentFramework={MultiColumnsHeaderComponent}
          />
          <AgGridColumn
            headerName={t('grid.header.Artist')}
            minWidth={120}
            pinned
            field="Artist"
            headerComponentParams={{
              sortModel,
              onSortChange,
              sortPath: 'Artist',
            }}
          />
          <AgGridColumn
            field="SpinsInfo"
            minWidth={85}
            width={85}
            headerName={t(`grid.header.SpinsInfoTW`)}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              disposition: 'row',
              onSortChange,
              sortPath: 'SpinsInfo/ThisWeek',
              secondLine: [
                { label: 'grid.header.LW', sortPath: 'SpinsInfo/LastWeek' },
                { label: 'grid.header.plusMinus', sortPath: 'SpinsInfo/Move' },
              ],
            }}
            cellRendererFramework={ThisLastMoveCellRenderer}
          />
          {DayPartsInfoColumn({ sortModel, onSortChange })}
          <AgGridColumn
            field="ImpressionsThisWeek"
            minWidth={100}
            width={100}
            valueFormatter={({ value }: ValueFormatterParams) => `${+parseFloat(value).toFixed(1)}`}
            headerName={t(`grid.header.ImpressionsInfo`)}
            headerComponentParams={{ sortModel, onSortChange, sortPath: 'ImpressionsInfo/SpinsThisWeek' }}
          />
        </ServerSideReportGrid>
      </GridVisibilityWrapper>
    </>
  );
};

export default ArtistChartGrid;
