import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
    },
    logoContainer: {
      width: `400px`,
      minWidth: '220px',
      margin: 'auto',
      textAlign: 'center',
      maxWidth: '50%',
      maxHeight: '70%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: 'grey',
      fontSize: '0.8rem',
      "@media (max-width: 319px)": {
        minWidth: '185px'
      }
    },
    logo:{
      width: '200px',
      marginTop: '10px',
      marginBottom: '10px'
    },
    hide: {
      display: 'none',
    },
  }),
);

export default useStyles;
