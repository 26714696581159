import { ReportResultItem, BigPictureResponse } from './BigPictureChartTypes';

export const BigPictureChartTransformer = (response: ReportResultItem): ReportResultItem => {
  const transformItems = (row: BigPictureResponse) => {
    return {
      ...row,
      SongInfo: {
        Artist: row.Artist,
        Label: row.Label,
        Title: row.Title,
      },
      RankInfo: {
        ThisWeek: row.RankThisWeek,
        LastWeek: row.RankLastWeek,
        Move: 0,
      },
      ImpressionsSingle: row?.ImpressionsInfo?.SpinsThisWeek,
    };
  };

  return {
    Count: response.Count,
    FormatByFormatColumns: response.FormatByFormatColumns,
    Items: response.Items.map(transformItems),
  };
};
