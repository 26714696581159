import React, { useState } from 'react';
import ReportHeader from 'components/ReportHeader';
import SiblingReportsSelect from 'components/SiblingReportsSelect/SiblingReportsSelect';
import FilterButton from 'components/FilterButton';
import { useTranslation } from 'react-i18next';
import { Container, ReportContainer, GridContainer } from './styles';
import MarketChartReportGrid from './MarketChartReportGrid';
import MarketChartReportFilter from './MarketChartReportFilter';
import { MarketChartFilter } from './utils/MarketChartReportInterfaces';

const MarketChartReport: React.FC = () => {
  const { t } = useTranslation();
  const [filterOpened, setFilterOpened] = useState(false);
  const [filters, setFilters] = useState<MarketChartFilter>();

  const onChangeFilter = (filtersParams: MarketChartFilter) => {
    setFilterOpened(false);
    setFilters(filtersParams);
  };

  return (
    <Container container direction="column" spacing={0}>
      <ReportHeader title={t('report.tab.chart')} subtitle={t('report.tab.sevenDay')} />
      <ReportContainer>
        <SiblingReportsSelect />
        <FilterButton onClickFilter={() => setFilterOpened(!filterOpened)} isOpened={filterOpened} />
      </ReportContainer>
      <MarketChartReportFilter isOpened={filterOpened} changeFilter={onChangeFilter} />
      <GridContainer>
        <MarketChartReportGrid filters={filters} />
      </GridContainer>
    </Container>
  );
};

export default MarketChartReport;
