/* eslint-disable no-param-reassign */
import { AgGridColumn } from '@ag-grid-community/react/lib/agGridColumn';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import SongCellRenderer from 'components/DetailGrid/CellRenderers/SongCellRenderer/SongCellRenderer';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import { UseApiSettings } from 'hooks/useApi/ApiTypes';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import { formatTime } from 'utils/dates';
import { StationCurrentDailyTransformer } from './utils/CurrentDayMonitorTransformer';
import { CurrentDayMonitorsFilter, StationCurrentDailyRow } from './utils/CurrentDayMonitorTypes';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface StationCurrentDailyProps {
  filters: CurrentDayMonitorsFilter | undefined;
  songClickHandler: (data: any, reportQuery: CurrentDayMonitorsFilter | undefined) => void;
}

const url = 'station-current-day-daily-reports';

const StationCurrentDailyGrid: React.FC<StationCurrentDailyProps> = ({ filters, songClickHandler }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({
    sortPath: 'TimePlayed',
    sort: 'desc',
  });

  const { setSettings, settings, data: reportData, isLoading, isError } = useRemoteApi<
    ReportResult<StationCurrentDailyRow>
  >(url, {
    data: filters,
    method: 'POST',
    enabled: !!filters,
    transformRequest: data => {
      delete data.Stations;
      data.PanelInfo = {};
      delete data.NumDays;
      delete data.GRCInfo;
      return data;
    },
    transformResponse: StationCurrentDailyTransformer,
  });

  return (
    <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
      <ServerSideReportGrid
        setSettings={(setSettings as unknown) as Dispatch<UseApiSettings<ReportResult<unknown>>>}
        settings={(settings as unknown) as UseApiSettings<ReportResult<unknown>>}
        filters={filters}
        sortModel={sortModel}
      >
        <AgGridColumn
          field="TimePlayed"
          valueFormatter={({ value }: ValueFormatterParams) => formatTime(value)}
          minWidth={80}
          width={80}
          headerName={t(`grid.header.TimePlayed`)}
          headerComponentParams={{ sortModel, onSortChange }}
        />
        <AgGridColumn
          field="SongInfo"
          pinned
          headerName={t(`grid.header.Title`)}
          minWidth={150}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            sortPath: 'Title',
            disposition: 'row',
            secondLine: [
              { label: 'grid.header.Artist', sortPath: 'Artist' },
              { label: 'grid.header.ReleaseYear', sortPath: 'ReleaseYear' },
            ],
          }}
          cellRendererFramework={SongCellRenderer}
          cellRendererParams={{
            reportQuery: filters,
            onClickHandler: (data: any, reportQuery: any) => songClickHandler(data, reportQuery),
          }}
        />
        <AgGridColumn
          field="GrcCode"
          minWidth={80}
          width={80}
          headerName={t(`grid.header.Grc`)}
          headerComponentParams={{ sortModel, onSortChange }}
        />
      </ServerSideReportGrid>
    </GridVisibilityWrapper>
  );
};

export default StationCurrentDailyGrid;
