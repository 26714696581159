import React from 'react';
import { v4 } from 'uuid';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import InnerTable from '..';
import { IInnerTableGroup } from '../inner-table-group';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface DynamicDataInnerTableProps {
  data: any;
  fieldHeaders: string[];
  fieldNames: string[];
  tableHeader: string;
}

const DynamicDataInnerTable: React.FC<DynamicDataInnerTableProps> = ({
  data,
  fieldHeaders,
  fieldNames,
  tableHeader,
}: DynamicDataInnerTableProps) => {
  const { t } = useTranslation();

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const formatValue = (val: any) => {
    const output = val === 0 ? '--' : val;
    return output;
  };

  const values = fieldNames.map((item: string) => {
    const result = get(data, item);
    const output = formatValue(result);
    return output;
  });

  const headers = fieldHeaders.map(header => {
    return t(header);
  });

  const innerTableData: IInnerTableGroup[] = [
    {
      title: t(tableHeader),
      children: [
        {
          title: '',
          children: headers,
        },
        {
          title: '',
          children: values,
        },
      ],
    },
  ];

  return <InnerTable key={v4()} data={innerTableData} />;
};

export default DynamicDataInnerTable;
