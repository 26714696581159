import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const drawerWidth = 240;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    title: {
      flexGrow: 1,
    },
    primaryItemText: {
      fontSize: 24,
    },
    secondaryItemText: {
      fontSize: 16,
    },
    internalCollapse: {
      margin: '10px 10px 10px 10px',
      border: '1px solid #d5d5d5',
    },
    itemMenu: {
      color: theme.palette.secondary.main,
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      backgroundColor: theme.palette.background.default,
      boxShadow: 'none',
      paddingTop: '15px',
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    logoContainer: {
      width: `400px`,
      minWidth: '220px',
      margin: 'auto',
      textAlign: 'center',
      maxWidth: '50%',
      maxHeight: '70%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: 'grey',
      fontSize: '0.8rem',
      "@media (max-width: 319px)": {
        minWidth: '185px'
      }
    },
    logo:{
      width: '200px',
      marginTop: '10px',
      marginBottom: '10px'
    },
    menuButton: {
      color: theme.palette.primary.main,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: '100%',
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      flexShrink: 1,
      fontWeight: 300,
      padding: theme.spacing(1, 1),
      // necessary for content to be below app bar
      // ...theme.mixins.toolbar,
      justifyContent: 'space-between',
      borderBottomWidth: 'thin',
      cursor: 'pointer',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    nested: {
      paddingLeft: theme.spacing(4),
      fontSize: '16px',
    },
  }),
);

export default useStyles;
