import React from 'react';
import { useStationPorfolioGroupGetById } from 'hooks/stationPortfolioGroupHooks/stationPortfolioGroupHooks';
import { StationPortfolioGroup } from 'types/StationPortfolio';

const formatStationPortfolioGroup = (stationPortfolioGroup: StationPortfolioGroup | undefined): string => {
  if (stationPortfolioGroup) {
    return `${stationPortfolioGroup.Name}`;
  }
  return '';
};

export type StationPorfolioGroupDescriptionProps = { portfolioGroupId: number };

export const StationPorfolioGroupDescription = (args: StationPorfolioGroupDescriptionProps): JSX.Element => {
  const { data: stationPortfolioGroup } = useStationPorfolioGroupGetById(args.portfolioGroupId);
  if (stationPortfolioGroup) {
    return <>{`Station Portfolio Group: ${formatStationPortfolioGroup(stationPortfolioGroup)}`}</>;
  }
  return <></>;
};
