import { isSameDay } from 'date-fns';
import { Range } from 'react-date-range';

function compareDays(d1?: Date, d2?: Date) {
  if (!d1 || !d2) {
    return false;
  }

  return isSameDay(d1, d2);
}

export function isSameDayRange(r1?: Range, r2?: Range): boolean {
  if (!r1 || !r2) {
    return false;
  }

  return compareDays(r1.startDate, r2.startDate) && compareDays(r1.endDate, r2.endDate);
}
