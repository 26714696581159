import { AxiosError } from 'axios';
import AppSettings from 'contexts/AppSettingsContext';
import React, { useContext, useEffect, useState } from 'react';
import { useAppApi } from 'services/api';
import AppProblem from 'types/AppProblem';
import { QueryClient, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import ApiStatus from '../ApiStatus';
import { DayByDaySpins } from 'types/DayByDaySpins';
import { Dictionary } from 'lodash';
import { DayByDayColumn, DateDataColumn } from 'types/Midnite';
import PointsInfo from 'types/PointsInfo';
import SpinsInfo from 'types/SpinsInfo';
import { PanelLocalData } from 'types/Panel';
import '../ClassicView.css';
import { ClassicFormat, ClassicGrc, ClassicPanel } from '../ClassicTypes';
import ClassicViewHeader from '../ClassicViewHeader/ClassicViewHeader';
import { format } from 'date-fns';
import { useFormatsSearch } from 'hooks/formatHooks/formatHooks';
import { FormatSearchRequest } from 'types/FormatSearchRequest';
import { ReportTypeEnum } from 'types/ReportType';
import { formatDateTypes } from 'utils/dates';

type ClassicViewCurrentDayChartsQuery = {
  PanelInfo: ClassicPanel;
  FormatInfo: ClassicFormat;
  GrcInfo: ClassicGrc;
  NumDays: number;
  TopX: number;
};

interface CurrentChartDayReport {
  Count: number;
  Items: Array<CurrentChartDayRow>;
  DayByDayColumns: Array<DayByDayColumn>;
  DateData?: Array<DateDataColumn>;
}

interface CurrentChartDayRow {
  RankLastWeek: number;
  RankThisWeek: number;
  Artist: string;
  Title: string;
  Label: string;
  SongCode: string;
  HasCanadianContent: boolean;
  SongCardAudioType: number;
  SpinsInfo: SpinsInfo;
  DayByDaySpins: Dictionary<DayByDaySpins>;
  ImpressionsInfo: PointsInfo;
  PointsInfo: PointsInfo;
  isError?: boolean;
}

type ClassicViewCurrentDayChartsTableProps = {
  reportQuery: ClassicViewCurrentDayChartsQuery;
};

const useGetReportData = (reportQuery: ClassicViewCurrentDayChartsQuery) => {
  const queryClient = new QueryClient();
  const api = useAppApi();
  const url = '/real-time-current-charts-reports';
  const cacheKey = 'real-time-current-charts-reports';
  return useQuery<CurrentChartDayReport, AxiosError<AppProblem>>(cacheKey, () => {
    return api.post(`${url}`, reportQuery).then(resp => {
      queryClient.invalidateQueries(cacheKey);
      return resp.data;
    });
  });
};

const ClassicViewCurrentDayChartsTable = (args: ClassicViewCurrentDayChartsTableProps) => {
  const { t } = useTranslation();
  const { data, status, isSuccess } = useGetReportData(args.reportQuery);
  if (!isSuccess) return <ApiStatus status={status} />;

  return (
    <table className={`classic-table`}>
      <thead>
        <tr key={uuidv4()}>
          <th key={uuidv4()} colSpan={5} rowSpan={1}>
            &nbsp;
          </th>
          <th key={uuidv4()} colSpan={3} rowSpan={1}>
            {t(`grid.header.Spins`)}
          </th>
          <th key={uuidv4()} colSpan={(data?.DayByDayColumns.length || 0) * 3} rowSpan={1}>
            {t(`grid.header.DayByDaySpins`)}
          </th>
          <th key={uuidv4()} colSpan={3} rowSpan={1}>
            &nbsp;
          </th>
          <th key={uuidv4()} colSpan={3} rowSpan={1}>
            {t(`grid.header.Impressions`)}
          </th>
        </tr>
        <tr key={uuidv4()}>
          <th key={uuidv4()} colSpan={2} rowSpan={1}>
            {t(`grid.header.Rank`)}
          </th>
          <th key={uuidv4()} colSpan={1} rowSpan={2}>
            {t(`grid.header.Artist`)}
          </th>
          <th key={uuidv4()} colSpan={1} rowSpan={2}>
            {t(`grid.header.Title`)}
          </th>
          <th key={uuidv4()} colSpan={1} rowSpan={2}>
            {t(`grid.header.Label`)}
          </th>
          <th key={uuidv4()} colSpan={3} rowSpan={1}>
            {t(`grid.header.Total`)}
          </th>
          {(data?.DayByDayColumns ?? []).map(dayBayDayColumn => (
            <th key={uuidv4()} colSpan={3} rowSpan={1}>
              {dayBayDayColumn.DayName}
            </th>
          ))}
          <th key={uuidv4()} colSpan={3} rowSpan={1}>
            {t(`grid.header.Points`)}
          </th>
          <th key={uuidv4()} colSpan={3} rowSpan={1}>
            {t(`grid.header.Total`)}
          </th>
        </tr>
        <tr key={uuidv4()}>
          <th key={uuidv4()}>{t(`grid.header.LW`)}</th>
          <th key={uuidv4()}>{t(`grid.header.TW`)}</th>

          {/* spings */}
          <th key={uuidv4()}>{t(`grid.header.TW`)}</th>
          <th key={uuidv4()}>{t(`grid.header.LW`)}</th>
          <th key={uuidv4()}>{t(`grid.header.Move`)}</th>

          {/* day by day spins */}
          {(data?.DayByDayColumns ?? []).map(() => [
            <th key={uuidv4()}>{t(`grid.header.TW`)}</th>,
            <th key={uuidv4()}>{t(`grid.header.LW`)}</th>,
            <th key={uuidv4()}>{t(`grid.header.Move`)}</th>,
          ])}

          {/* points */}
          <th key={uuidv4()}>{t(`grid.header.TW`)}</th>
          <th key={uuidv4()}>{t(`grid.header.LW`)}</th>
          <th key={uuidv4()}>{t(`grid.header.Move`)}</th>

          {/* impressions */}
          <th key={uuidv4()}>{t(`grid.header.TW`)}</th>
          <th key={uuidv4()}>{t(`grid.header.LW`)}</th>
          <th key={uuidv4()}>{t(`grid.header.Move`)}</th>
        </tr>
      </thead>
      <tbody>
        {data &&
          data.Items.map((item: CurrentChartDayRow) => (
            <tr key={uuidv4()}>
              <td key={uuidv4()}>{item.RankLastWeek}</td>
              <td key={uuidv4()}>{item.RankThisWeek}</td>

              <td key={uuidv4()}>{item.Artist}</td>
              <td key={uuidv4()}>{item.Title}</td>
              <td key={uuidv4()}>{item.Label}</td>

              <td key={uuidv4()}>{item.SpinsInfo.ThisWeek}</td>
              <td key={uuidv4()}>{item.SpinsInfo.LastWeek}</td>
              <td key={uuidv4()}>{item.SpinsInfo.Move}</td>

              {(data?.DayByDayColumns ?? []).map(dayBayDayColumn => [
                <td key={uuidv4()}>{item.DayByDaySpins[dayBayDayColumn.DayIndex].ThisWeek}</td>,
                <td key={uuidv4()}>{item.DayByDaySpins[dayBayDayColumn.DayIndex].LastWeek}</td>,
                <td key={uuidv4()}>{item.DayByDaySpins[dayBayDayColumn.DayIndex].Move}</td>,
              ])}

              <td key={uuidv4()}>{item.PointsInfo.ThisWeek}</td>
              <td key={uuidv4()}>{item.PointsInfo.LastWeek}</td>
              <td key={uuidv4()}>{item.PointsInfo.Move}</td>

              <td key={uuidv4()}>{item.ImpressionsInfo.ThisWeek}</td>
              <td key={uuidv4()}>{item.ImpressionsInfo.LastWeek}</td>
              <td key={uuidv4()}>{item.ImpressionsInfo.Move}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

type ReportDescriptionProps = {
  reportQuery: ClassicViewCurrentDayChartsQuery;
};

const ClassicViewReportDescriptions = (args: ReportDescriptionProps) => {
  const panelInfo = PanelLocalData.find(args.reportQuery.PanelInfo?.PanelCode || '');

  const formatSearchRequest: FormatSearchRequest = {
    Year: 0,
    OwnerId: 0,
    ReportType: ReportTypeEnum.CurrentChart,
    PanelCode: panelInfo?.PanelCode || 'R',
  };
  const { data: formats } = useFormatsSearch(formatSearchRequest);
  const formatInfo = formats?.find(f => f.FormatCode?.trim() === args.reportQuery.FormatInfo?.FormatCode?.trim());

  return (
    <>
      <div>{`Panel: ${panelInfo?.Name}`}</div>
      <div>{`Format: ${formatInfo?.Name || 'All'}`}</div>
      <div>{`Past Days: ${args.reportQuery.NumDays}`}</div>
      <div>{`GRC: ${args.reportQuery.GrcInfo?.GrcCode}`}</div>
      <div>{`Top: ${args.reportQuery.TopX}`}</div>
    </>
  );
};

const ClassicViewCurrentDayCharts: React.FC = () => {
  const [asOfNowValue, setAsOfNowValue] = useState(new Date());
  const { reportRefreshTimeout } = useContext(AppSettings);

  useEffect(() => {
    const interval = setInterval(() => {
      setAsOfNowValue(new Date());
    }, reportRefreshTimeout);

    return () => {
      clearInterval(interval);
    };
  }, [reportRefreshTimeout]);

  const sp = new URLSearchParams(window.location.search);

  const panelCode = sp.get('panelCode');
  if (!panelCode) return <div>No panel info provided</div>;

  const formatCode = sp.get('formatCode');
  if (!formatCode) return <div>No format info provided</div>;

  const grc = sp.get('grc');
  if (!grc) return <div>No grc info provided</div>;

  const numDays = sp.get('numDays');
  if (!numDays) return <div>Number of days missing</div>;

  const topX = sp.get('topX');
  if (!topX) return <div>Number of days missing</div>;

  const query: ClassicViewCurrentDayChartsQuery = {
    PanelInfo: { PanelCode: panelCode },
    FormatInfo: { FormatCode: formatCode },
    GrcInfo: { GrcCode: grc },
    NumDays: parseInt(numDays, 10),
    TopX: parseInt(topX, 10),
  };
  return (
    <>
      <ClassicViewHeader />
      <div className={`classic-view-report-header`}>
        <h1>Realtime Charts</h1>
        <div>{`As of: ${format(asOfNowValue, formatDateTypes.MMddyyyyhhmma)}`}</div>
      </div>
      <div className={`classic-view-report-descriptions`}>
        <ClassicViewReportDescriptions reportQuery={query} />
      </div>
      <div className={`classic-view-report-data`}>
        <ClassicViewCurrentDayChartsTable reportQuery={query} />
      </div>
    </>
  );
};

export default ClassicViewCurrentDayCharts;
