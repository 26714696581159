/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { addDays, differenceInDays, isSameDay, subDays } from 'date-fns';
import { noop } from 'lodash';
import React from 'react';
import { DateRange, Range } from 'react-date-range';
import defaultTheme from 'themes/index';

interface DateRangeSelectorProps {
  anchoredWeekDay?: boolean;
  dates?: Range;
  onChange?: (range: Range) => void;
  maxDate?: Date | undefined;
}

const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({
  anchoredWeekDay,
  dates,
  onChange = noop,
  maxDate = undefined,
}) => {
  const rangeColor = defaultTheme.palette.primary.main;

  // this "any" is due to buggy typing on react-date-range
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDateClick = (range: any) => {
    if (!dates || (isSameDay(range.startDate, dates.startDate!) && isSameDay(range.endDate, dates.endDate!))) {
      return;
    }

    const rangeAmount = differenceInDays(dates.endDate!, dates.startDate!);
    const daySelected = (range.range1.startDate as Date).getDay();
    const endDate = anchoredWeekDay ? addDays(range.range1.startDate as Date, 6 - daySelected) : range.range1.startDate;
    const startDate = subDays(endDate, rangeAmount);

    onChange({ startDate, endDate, color: rangeColor } as Range);
  };

  return (
    <>
      <DateRange
        ranges={dates ? [dates] : undefined}
        moveRangeOnFirstSelection={false}
        onChange={handleDateClick}
        showDateDisplay={false}
        focusedRange={[0, 0]}
        maxDate={maxDate}
      />
    </>
  );
};

export default DateRangeSelector;
