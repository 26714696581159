import React from 'react';
import { PeakInfo } from 'types/PeakInfo';
import { formatDate } from 'utils/dates';
import { ContainerGrid, ItemGrid } from '../globalStylesCellRenderers';

interface PeakCellRendererProps {
  value?: PeakInfo;
}

const PeakCellRenderer: React.FC<PeakCellRendererProps> = ({ value = null }) => {
  if (!value) {
    return <></>;
  }

  return (
    <ContainerGrid>
      <ItemGrid width="25%">{formatDate(value.PeakSpinsDate)}</ItemGrid>
      <ItemGrid width="25%">{value.PeakSpins}</ItemGrid>
      <ItemGrid width="20%">{value.PeakChart}</ItemGrid>
      <ItemGrid width="25%">{formatDate(value.PeakChartDate)}</ItemGrid>
    </ContainerGrid>
  );
};

export default PeakCellRenderer;
