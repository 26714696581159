import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Grid)`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  padding: 5px 12px;
`;
export const Title = styled.span`
  font-size: 32px;
  color: black;
  margin-right: 14px;
  font-family: Inter, sans-serif;
  @media (max-width: 368px) {
    font-size: 26px;
  }
`;
export const Subtitle = styled.span`
  font-size: 32px;
  color: black;
  font-weight: 100;
  @media (max-width: 368px) {
    font-size: 26px;
  }
`;
