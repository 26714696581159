import React from 'react';
import { HeaderComponentParams } from 'types/HeaderComponentParams';
import MultiColumnsHeaderComponent from './MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';

interface DayPartsHeaderComponentProps extends HeaderComponentParams {
  displayName: string;
}

const DayPartsHeaderComponent: React.FC<DayPartsHeaderComponentProps> = props => {
  const { displayName, column } = props;
  const { field } = column.getColDef();

  const secondLine = ['Overnight', 'AmDrive', 'Midday', 'PmDrive', 'Evening'].map(v => ({
    label: `grid.header.${v}`,
    sortPath: `${field}/${v}`,
  }));
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MultiColumnsHeaderComponent {...props} displayName={displayName} secondLine={secondLine} />;
};

export default DayPartsHeaderComponent;
