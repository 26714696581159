import { AxiosError } from 'axios';
import AppSettings from 'contexts/AppSettingsContext';
import React, { useContext, useEffect, useState } from 'react';
import { useAppApi } from 'services/api';
import AppProblem from 'types/AppProblem';
import { QueryClient, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import ApiStatus from '../ApiStatus';
import { format } from 'date-fns';
import '../ClassicView.css';
import { ClassicStation, ClassicGrc, ClassicPanel } from '../ClassicTypes';
import {
  StationCurrentBuildingReport,
  StationCurrentDayBuildingRow,
} from 'pages/Reports/DailyCurrent/StationMonitor/CurrentDayMonitorsReport/utils/CurrentDayMonitorTypes';
import ClassicViewHeader from '../ClassicViewHeader/ClassicViewHeader';
import { formatDateTypes } from 'utils/dates';

type ClassicViewCurrentDayMonitorsBuildingQuery = {
  PanelInfo: ClassicPanel;
  StationInfo: ClassicStation;
  GrcInfo: ClassicGrc;
  NumDays: number;
};

type ClassicViewCurrentDayMonitorsBuildingTableProps = {
  reportQuery: ClassicViewCurrentDayMonitorsBuildingQuery;
};

const useGetReportData = (reportQuery: ClassicViewCurrentDayMonitorsBuildingQuery) => {
  const queryClient = new QueryClient();
  const api = useAppApi();
  const url = '/station-current-day-building-reports';
  const cacheKey = 'station-current-day-building-reports';
  return useQuery<StationCurrentBuildingReport, AxiosError<AppProblem>>(cacheKey, () => {
    return api.post(`${url}`, reportQuery).then(resp => {
      queryClient.invalidateQueries(cacheKey);
      return resp.data;
    });
  });
};

const ClassicViewCurrentDayMonitorsBuildingTable = (args: ClassicViewCurrentDayMonitorsBuildingTableProps) => {
  const { t } = useTranslation();
  const { data, status, isSuccess } = useGetReportData(args.reportQuery);
  if (!isSuccess) return <ApiStatus status={status} />;

  return (
    <table className={`classic-table`}>
      <thead>
        <tr>
          <th colSpan={2} rowSpan={1}>
            {t(`grid.header.Rank`)}
          </th>

          <th colSpan={1} rowSpan={2}>
            {t(`grid.header.Artist`)}
          </th>
          <th colSpan={1} rowSpan={2}>
            {t(`grid.header.Title`)}
          </th>
          <th colSpan={1} rowSpan={2}>
            {t(`grid.header.Label`)}
          </th>
          <th colSpan={1} rowSpan={2}>
            {t(`grid.header.Year`)}
          </th>

          <th colSpan={3} rowSpan={1}>
            {t(`grid.header.Spins`)}
          </th>
          <th colSpan={5} rowSpan={1}>
            {t(`grid.header.DaypartsInfo`)}
          </th>

          <th colSpan={1} rowSpan={2}>
            {t(`grid.header.historicalData.HistSpins`)}
          </th>
          <th colSpan={1} rowSpan={2}>
            {t(`grid.header.historicalData.FirstPlayed`)}
          </th>
        </tr>
        <tr>
          <th>{t(`grid.header.LW`)}</th>
          <th>{t(`grid.header.TW`)}</th>

          <th>{t(`grid.header.TW`)}</th>
          <th>{t(`grid.header.LW`)}</th>
          <th>{t(`grid.header.Move`)}</th>

          <th>{t(`grid.header.Overnight`)}</th>
          <th>{t(`grid.header.AmDrive`)}</th>
          <th>{t(`grid.header.Midday`)}</th>
          <th>{t(`grid.header.PmDrive`)}</th>
          <th>{t(`grid.header.Evening`)}</th>
        </tr>
      </thead>
      <tbody>
        {data &&
          data.Items.map((item: StationCurrentDayBuildingRow) => (
            <tr key={uuidv4()}>
              <td>{item.RankLastWeek}</td>
              <td>{item.RankThisWeek}</td>

              <td>{item.Artist}</td>
              <td>{item.Title}</td>
              <td>{item.Label}</td>
              <td>{item.ReleaseYear}</td>

              <td>{item.SpinsInfo.ThisWeek}</td>
              <td>{item.SpinsInfo.LastWeek}</td>
              <td>{item.SpinsInfo.Move}</td>

              <td>{item.DaypartsInfo.Overnight}</td>
              <td>{item.DaypartsInfo.AmDrive}</td>
              <td>{item.DaypartsInfo.Midday}</td>
              <td>{item.DaypartsInfo.PmDrive}</td>
              <td>{item.DaypartsInfo.Evening}</td>

              <td>{item.SpinsToDate}</td>
              <td>{format(new Date(item.FirstPlayDate), 'MM/dd/yyyy hh:mm a')}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

type ReportDescriptionProps = {
  reportQuery: ClassicViewCurrentDayMonitorsBuildingQuery;
};

const ClassicViewReportDescriptions = (args: ReportDescriptionProps) => {
  return (
    <>
      <div>{`Station: ${args.reportQuery.StationInfo?.Name}`}</div>
      <div>{`Past Days: ${args.reportQuery.NumDays}`}</div>
      <div>{`GRC: ${args.reportQuery.GrcInfo?.GrcCode}`}</div>
    </>
  );
};

const ClassicViewCurrentDayMonitorsBuilding: React.FC = () => {
  const [asOfNowValue, setAsOfNowValue] = useState(new Date());
  const { reportRefreshTimeout } = useContext(AppSettings);
  useEffect(() => {
    const interval = setInterval(() => {
      setAsOfNowValue(new Date());
    }, reportRefreshTimeout);

    return () => {
      clearInterval(interval);
    };
  }, [reportRefreshTimeout]);

  const sp = new URLSearchParams(window.location.search);

  const station = sp.get('station');
  if (!station) return <div>No station info provided</div>;

  const panelCode = sp.get('panelCode');
  if (!panelCode) return <div>No panel info provided</div>;

  const grc = sp.get('grc');
  if (!grc) return <div>No grc info provided</div>;

  const numDays = sp.get('numDays');
  if (!numDays) return <div>Number of days missing</div>;

  const query: ClassicViewCurrentDayMonitorsBuildingQuery = {
    StationInfo: { Name: station },
    PanelInfo: { PanelCode: panelCode },
    GrcInfo: { GrcCode: grc },
    NumDays: parseInt(numDays, 10),
  };
  return (
    <>
      <ClassicViewHeader />
      <div className={`classic-view-report-header`}>
        <h1>Realtime Monitors - Building</h1>
        <div>{`As of: ${format(asOfNowValue, formatDateTypes.MMddyyyyhhmma)}`}</div>
      </div>
      <div className={`classic-view-report-descriptions`}>
        <ClassicViewReportDescriptions reportQuery={query} />
      </div>
      <div className={`classic-view-report-data`}>
        <ClassicViewCurrentDayMonitorsBuildingTable reportQuery={query} />
      </div>
    </>
  );
};

export default ClassicViewCurrentDayMonitorsBuilding;
