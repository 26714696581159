import { ICellRendererParams } from '@ag-grid-enterprise/all-modules';
import { Box, Grid } from '@material-ui/core';
import { get } from 'lodash';
import React from 'react';
import { SpinsSpan } from './styles';

const CELL_WIDTH_FIX = 40;

const HourlySpinsCellRenderer: React.FC<ICellRendererParams> = ({ data, column, colDef: { field } }) => {
  const hourlySpinsColumns = [...Array(24).keys()].map(i => `${field}.${i}`);

  const fullSize = column.getActualWidth() - CELL_WIDTH_FIX;

  const colWidth = fullSize / hourlySpinsColumns.length;

  return (
    <Grid container justify="flex-start">
      <Grid item xs>
        <Box style={{ width: fullSize }}>
          {hourlySpinsColumns.map((spinsField, index) => {
            return (
              <SpinsSpan key={spinsField} width={colWidth} hourIndex={index}>
                {get(data, spinsField)}
              </SpinsSpan>
            );
          })}
        </Box>
      </Grid>
    </Grid>
  );
};

export default HourlySpinsCellRenderer;
