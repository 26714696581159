import { differenceInDays, format } from 'date-fns';
import { Range } from 'react-date-range';
import { formatDateTypes } from 'utils/dates';

export function labelResolver(dates?: Range, dateFormat: string = formatDateTypes.MMddyyyy): string {
  const { startDate, endDate } = dates ?? {};
  if (!startDate || !endDate) {
    return '';
  }

  if (differenceInDays(endDate, startDate) < 1) {
    return format(startDate, dateFormat);
  }

  return `${format(startDate, dateFormat)} - ${format(endDate, dateFormat)}`;
};