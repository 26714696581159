import axios from 'axios';
import React, { createContext, FC, useEffect, useState } from 'react';
import { PanelInfo } from 'types/Panel';
import { AppSettingsContext } from './types';

const settingsFile = '/settings.json';

const AppSettings = createContext<AppSettingsContext>({
  baseUrl: '',
  endpointURL: '',
  forgoPaswordURL: '',
  faqsURL: '',
  imagesURL: '',
  logoURL: '',
  reportRefreshTimeout: 300000,
  maxSelectHeight: 390,
  spotBreakTitlePattern: '',
  allPanel: {} as PanelInfo,
  desktopLink: '',
  termsOfUseLink: '',
  privacyPolicyLink: '',
  phoneNumber: '',
  email: '',
});

const AppSettingsProvider: FC = ({ children }) => {
  const [settings, setSettings] = useState<AppSettingsContext>();

  const fetchSettings = async () => {
    const { data } = await axios.get<AppSettingsContext>(settingsFile);
    setSettings(data);
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  return settings ? <AppSettings.Provider value={settings}>{children}</AppSettings.Provider> : null;
};

export { AppSettings as default, AppSettingsProvider };
