import { AgGridColumn } from '@ag-grid-community/react';
import MultiLineCellRenderer from 'components/DetailGrid/CellRenderers/MultiLineCellRenderer/MultiLineCellRenderer';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import SpinDifferentialCellRenderer from 'components/DetailGrid/CellRenderers/SpinDifferentialCellRenderer';
import { SongComparisonReportFilter } from './Filter';
import { filterRequestTransformer } from './utils/filterRequestTransformer';
import { stationSummaryResponseTransformer } from './utils/responseTransformers';

export interface OverallGridProps {
  filters?: SongComparisonReportFilter;
}

const url = `/seven-day-song-comparison-chart-reports`;

const OverallGrid: React.FC<OverallGridProps> = ({ filters }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({ sortPath: 'SpinDifferential', sort: 'desc' });
  const { data: reportData, isLoading, setSettings, settings, isError } = useRemoteApi<ReportResult<unknown>>(url, {
    method: 'POST',
    enabled: false,
    transformResponse: stationSummaryResponseTransformer,
    transformRequest: filterRequestTransformer,
  });

  return (
    <>
      <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
        <ServerSideReportGrid setSettings={setSettings} settings={settings} filters={filters} sortModel={sortModel}>
          <AgGridColumn
            field="StationDisplayName"
            headerName={t('grid.header.StationName')}
            minWidth={100}
            headerComponentFramework={MultiColumnsHeaderComponent}
            pinned
            headerComponentParams={{
              sortModel,
              onSortChange,
              sortPath: 'StationDisplayName',
              disposition: 'row',
              secondLine: [
                { sortPath: 'MarketName', label: 'grid.header.Market' },
                { sortPath: 'FormatName', label: 'grid.header.FormatName' },
              ],
            }}
            cellRendererFramework={MultiLineCellRenderer}
            cellRendererParams={{ fields: ['MarketName', 'FormatName'] }}
          />
          <AgGridColumn
            field="FirstSongPlays"
            headerName={`${filters?.FirstSongInfo?.Title} - ${filters?.FirstSongInfo?.Artist}`}
            minWidth={80}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              disposition: 'row',
              secondLine: [
                { sortPath: 'FirstSongPlays', label: 'grid.header.Plays' },
                { sortPath: 'FirstSongImpressionsInfo/SpinsThisWeek', label: 'grid.header.ImpressionsM' },
              ],
            }}
            cellRendererFramework={MultiLineCellRenderer}
            cellRendererParams={{ fields: ['FirstSongImpressionsInfo.SpinsThisWeek'] }}
          />
          <AgGridColumn
            field="SpinDifferential"
            headerName={t('grid.header.SpinDifferential')}
            minWidth={170}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              disposition: 'row',
              secondLine: [
                { sortPath: 'LeftSpinDifferential', label: 'grid.header.SpinsDifferential.FirstSong' },
                { sortPath: 'SpinDifferential', label: 'grid.header.Spins' },
                { sortPath: 'RightSpinDifferential', label: 'grid.header.SpinsDifferential.SecondSong' },
              ],
            }}
            cellRendererFramework={SpinDifferentialCellRenderer}
            cellRendererParams={{ fields: ['FirstSongImpressionsInfo.SpinsThisWeek'] }}
          />
          <AgGridColumn
            field="SecondSongPlays"
            headerName={`${filters?.SecondSongInfo?.Title} - ${filters?.SecondSongInfo?.Artist}`}
            minWidth={80}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              disposition: 'row',
              secondLine: [
                { sortPath: 'SecondSongPlays', label: 'grid.header.Plays' },
                { sortPath: 'SecondSongImpressionsInfo/SpinsThisWeek', label: 'grid.header.ImpressionsM' },
              ],
            }}
            cellRendererFramework={MultiLineCellRenderer}
            cellRendererParams={{ fields: ['SecondSongImpressionsInfo.SpinsThisWeek'] }}
          />
        </ServerSideReportGrid>
      </GridVisibilityWrapper>
    </>
  );
};

export default OverallGrid;
