import FilterButton from 'components/FilterButton';
import ReportHeader from 'components/ReportHeader';
import SiblingReportsSelect from 'components/SiblingReportsSelect/SiblingReportsSelect';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReportCycleIdEnum } from 'types/ReportCycle';
import Filter, { ReportModeIdIndieEnum } from './Filter';
import SevenDayIndieChartBigPictureGrid from './SevenDayIndieChartBigPictureGrid';
import SevenDayIndieChartBuildingGrid from './SevenDayIndieChartBuildingGrid';
import SevenDayIndieChartRollingGrid from './SevenDayIndieChartRollingGrid';
import { Container, ReportContainer, GridContainer } from './styles';
import { IndieChartFilter } from './utils/IndieChartReportTypes';

const IndieChartReport: React.FC = () => {
  const [filterOpened, setFilterOpened] = useState(false);
  const [filters, setFilters] = useState<IndieChartFilter | undefined>();
  const { t } = useTranslation();
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const onChangeFilter = (filtersParams: any | null) => {
    setFilterOpened(false);
    setFilters(filtersParams);
  };

  return (
    <Container container direction="column" spacing={0}>
      <ReportHeader title={t('report.tab.chart')} subtitle={t('report.tab.sevenDay')} />
      <ReportContainer>
        <SiblingReportsSelect />
        <FilterButton onClickFilter={() => setFilterOpened(!filterOpened)} isOpened={filterOpened} />
      </ReportContainer>
      <Filter isOpened={filterOpened} changeFilter={onChangeFilter} />
      <GridContainer>
        {filters?.ReportMode === ReportModeIdIndieEnum.bigPicture && (
          <SevenDayIndieChartBigPictureGrid filters={filters} />
        )}
        {filters?.ReportMode === ReportModeIdIndieEnum.defaultMode &&
          filters?.ReportCycle === ReportCycleIdEnum.rolling && <SevenDayIndieChartRollingGrid filters={filters} />}
        {filters?.ReportMode === ReportModeIdIndieEnum.defaultMode &&
          filters?.ReportCycle === ReportCycleIdEnum.building && <SevenDayIndieChartBuildingGrid filters={filters} />}
      </GridContainer>
    </Container>
  );
};

export default IndieChartReport;
