/* eslint-disable react/require-default-props */
import NavigationMenu from 'contexts/NavigationMenuContext';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import { FilterState } from 'hooks/useFilterState/useFilterState';
import { noop, toUpper } from 'lodash';
import { filterSearchAllFormat } from 'pages/Reports/Report';
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import FilterDataProps from 'types/FilterDataProps';
import { FormatInfo } from 'types/Format';
import { PanelCodeEnum } from 'types/Panel';
import GenericSelect from '../GenericSelect';

interface Props<T extends FormatFilterData> extends FilterDataProps<T> {
  panelCode?: PanelCodeEnum;
  disabled?: boolean;
  title?: string;
  hasAllOption?: boolean;
  searchWithoutReportType?: boolean;
  required?: boolean;
  filterKey?: string;
  changeState?: (key: string, state: FilterState) => void;
}
export interface FormatFilterData {
  FormatInfo: FormatInfo;
}

export const ALL_OPTION: FormatInfo = {
  Id: 0,
  Name: 'All',
  FormatCode: 'XX',
  IsGold: false,
};

const url = `/formats`;
const searchUrl = `${url}/search`;

/* eslint-disable @typescript-eslint/no-explicit-any */
const FormatFilter = <T extends FormatFilterData>({
  onChange,
  panelCode = PanelCodeEnum.AllUsStations,
  disabled = false,
  title = 'filter.title.titleFormats',
  hasAllOption = true,
  initialValue,
  searchWithoutReportType = false,
  required = false,
  filterKey = 'FormatInfo',
  changeState = noop,
}: Props<T>): ReactElement => {
  const { t } = useTranslation();
  const [formatInfo, setFormatInfo] = useState<FormatInfo>(initialValue);
  const [formatItems, setFormatItems] = useState<Array<FormatInfo>>([]);

  const { pathname } = useLocation();
  const { currentNavigationMenu } = useContext(NavigationMenu);
  const getAllFormats = filterSearchAllFormat(currentNavigationMenu?.ReportType ?? 0);
  const { data: reportData } = useRemoteApi<Array<FormatInfo>>(getAllFormats ? url : searchUrl, {
    data: !getAllFormats
      ? {
          ReportType: currentNavigationMenu?.ReportType,
          PanelCode: panelCode,
          Year: '0',
        }
      : null,
    method: getAllFormats ? 'GET' : 'POST',
    enabled: pathname === currentNavigationMenu?.Url,
    transformResponse: (data: any[]) => {
      const dataSort = data
        .map(v => ({ ...v, FormatCode: toUpper(v.FormatCode) }))
        .sort((a: FormatInfo, b: FormatInfo) => (a.Name > b.Name ? 1 : -1));
      return hasAllOption ? [ALL_OPTION, ...dataSort] : dataSort;
    },
    transformRequest: data => {
      if (searchWithoutReportType) {
        // eslint-disable-next-line no-param-reassign
        delete data.ReportType;
      }
      return data;
    },
  });

  const handleChange = (event: { value: unknown }) => {
    let format = formatItems[0];
    if (event != null) {
      const value = event.value as FormatInfo;
      format = formatItems.filter(item => item.FormatCode === value.FormatCode)[0];
    }
    updateState(format);
  };

  const translate = (tValue: string) => {
    return tValue === 'filter.title.titleFormats' ? `${t(tValue)}: ` : tValue;
  };

  const updateState = (value: FormatInfo) => {
    setFormatInfo(value);
    onChange({ value: { FormatInfo: value } as T });
  };

  const getDefaultFormat = (data: Array<FormatInfo>) => {
    let defaultFormat = data[0];
    if (formatInfo != null && data.some(item => item.FormatCode === formatInfo.FormatCode)) {
      defaultFormat = formatInfo;
    }
    return defaultFormat;
  };

  useEffect(() => {
    if (reportData?.length) {
      setFormatItems(reportData);

      const defaultFormat = getDefaultFormat(reportData);
      updateState(defaultFormat);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportData]);

  return (
    <GenericSelect
      title={translate(title)}
      data={formatItems}
      onChange={handleChange}
      disabled={disabled}
      initialValue={formatInfo}
      required={required}
      idProperty="FormatCode"
      changeState={(state: FilterState) => changeState(filterKey, state)}
      comparator={(v1: string, v2: string) => {
        return `${v1}`.substring(0, 2) === `${v2}`.substring(0, 2) ? 0 : -1;
      }}
    />
  );
};

export default FormatFilter;
