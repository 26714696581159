import { Grid, Divider as MUIDivider } from '@material-ui/core';
import styled from 'styled-components';

export const InnerGrid = styled(Grid)`
  padding-left: 20px;
  width: 100%;
`;

export const Divider = styled(MUIDivider)`
  margin-top: 24px;
`;
