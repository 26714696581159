import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Station } from 'types/Station';
import { getStationDisplayName } from 'utils/reports/GetStationDisplayName';

import { useStyles } from './styles';

interface ListStationProps {
  stationList: { (): Station[] };
  selectStation: { (station: Station): void };
}

const ListStations: React.FC<ListStationProps> = ({ stationList, selectStation }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TableContainer className={classes.tableContainer} component={Paper} data-testid="list-station">
      <Table className={classes.table} size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.labelText} align="left">
              {t('filter.title.stationMarket')}
            </TableCell>
            <TableCell className={classes.labelText} align="right">
              {t('filter.title.format')}
            </TableCell>
            <TableCell align="right" />
          </TableRow>
        </TableHead>
        <TableBody>
          {stationList().map(station => (
            <TableRow key={station.Id} data-testid={`station-row-${station.Id?.toString()}`}>
              <TableCell size="small" align="left">
                <Typography className={classes.primaryText}>{getStationDisplayName(station)}</Typography>
                <Typography className={classes.labelText}>{station?.MarketInfo?.Name}</Typography>
              </TableCell>
              <TableCell className={classes.valueText} size="small" align="right">
                {station?.FormatInfo?.Name ?? ''}
              </TableCell>
              <TableCell size="small" align="right" className={classes.link} onClick={() => selectStation(station)}>
                {t('filter.title.add')}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ListStations;
