import { Typography } from '@material-ui/core';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { useTranslation } from 'react-i18next';
import { Container } from './styles';

const NoDataGrid: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <FontAwesomeIcon icon={faExclamationTriangle} size="lg" color="#c6002b" />
      <Typography>{t('filter.title.noDataFound')}</Typography>
    </Container>
  );
};

export default NoDataGrid;
