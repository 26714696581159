import { Divider, List } from '@material-ui/core';
import { noop } from 'lodash';
import React from 'react';
import { Range } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { v4 as uuidv4 } from 'uuid';
import { DateOptionParameters } from './DefaultDateOptions';
import SelectableListItem from './SelectableListItem';
import { optionFromDateRangeResolver } from './utils/optionFromDateRangeResolver';

interface Props {
  dateOptions: DateOptionParameters[];
  dates?: Range;
  onChange?: (id: string) => void;
}

const DateOptions: React.FC<Props> = ({ dateOptions, dates, onChange = noop }) => {
  return (
    <React.Fragment key={uuidv4()}>
      <List aria-label="weekly shortcut options" key={uuidv4()}>
        {dateOptions.map(option => {
          return (
            <React.Fragment key={uuidv4()}>
              <SelectableListItem
                key={uuidv4()}
                id={option.label}
                label={option.label}
                selected={option.label === optionFromDateRangeResolver(dates, dateOptions)?.label}
                onClick={() => onChange(option.label)}
              />
              {option.label !== 'custom' && <Divider key={uuidv4()} />}
            </React.Fragment>
          );
        })}
      </List>
    </React.Fragment>
  );
};

export default DateOptions;
