/* eslint-disable react-hooks/exhaustive-deps */
import { usePrevious } from 'hooks/usePrevious/usePrevious';
import { isEqual, range } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilterDataProps from 'types/FilterDataProps';
import GenericSelect from '../GenericSelect';

interface Props extends FilterDataProps<FilterData> {
  lowerBound?: number;
  upperBound?: number;
  hasTodayOption?: boolean;
  disabled?: boolean;
}

interface FilterData {
  NumDays: number | undefined;
}
interface NumberedDay {
  Id: number;
  Name: string;
}

const TODAY_VALUE = 0;
const LOWER_BOUND = 1;
const UPPER_BOUND = 7;
const GET_DAY = new Date().getDay();

export const getCurrentBuildingCycle = (): number => {
  const result = GET_DAY === 0 ? UPPER_BOUND : GET_DAY;
  return result;
};

const PastDays: React.FC<Props> = ({
  lowerBound = LOWER_BOUND,
  upperBound = UPPER_BOUND,
  onChange,
  initialValue,
  hasTodayOption = false,
  disabled = false,
}) => {
  const [initialValueData, setInitialValueData] = useState<NumberedDay>();
  const previousValue = usePrevious(initialValueData);
  const { t } = useTranslation();
  const [availableData] = useState(() => [
    {
      Id: getCurrentBuildingCycle(),
      Name: t('filter.label.useCurrentBuildingCycle'),
    },
    ...(hasTodayOption
      ? [
          {
            Id: TODAY_VALUE,
            Name: t('filter.label.today'),
          },
        ]
      : []),
    ...range(lowerBound, upperBound + 1).map(n => {
      return {
        Id: n,
        Name: `${n} ${n === 1 ? 'Day' : 'Days'}`,
      };
    }),
  ]);

  useEffect(() => {
    if (initialValue && initialValueData?.Id !== initialValue) {
      setInitialValueData({ Id: initialValue } as NumberedDay);
    }
  }, [initialValue]);

  const handleChange = (event: { value: unknown }) => {
    const value = event.value as NumberedDay;
    if (!isEqual(value, previousValue)) {
      onChange({ value: { NumDays: value.Id } });
    }
  };

  return (
    <GenericSelect
      title={t('filter.title.PastDays')}
      data={availableData}
      onChange={handleChange}
      initialValue={initialValueData}
      disabled={disabled}
    />
  );
};

export default PastDays;
