import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import AppSettings from 'contexts/AppSettingsContext';
import { createBrowserHistory } from 'history';
import { useContext } from 'react';

const getAuthToken = () => {
  const locaStorageData = localStorage.getItem('authData');
  if (locaStorageData) {
    const { token } = JSON.parse(locaStorageData);
    return token ? `Bearer ${token}` : null;
  }
  return null;
};

const getConfig = () => {
  return {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      Authorization: getAuthToken(),
    },
  };
};

export const apiWithContext = (baseURLUpdated: string): AxiosInstance => {
  const baseURL = baseURLUpdated;
  return axios.create({
    ...getConfig(),
    baseURL,
  });
};

export const createAppApi = (requestConfig?: AxiosRequestConfig | undefined): AxiosInstance => 
{  
  const config: AxiosRequestConfig = {...requestConfig};

  // update headers with auth headers if these exist
  if(config.headers == null)
  {
      config.headers = getConfig().headers;
  }

  // create an instance using the config defaults provided by the library
  const axiosInstance = axios.create(config);

  // add handler for unauthorized users
  axiosInstance.interceptors.response.use((response) => response, (error) => 
  {
    if (error.response.status === 401) 
    {
      localStorage.removeItem('authData');
      const history = createBrowserHistory();
      history.push('/login');
    }
  });

  return axiosInstance;
};

export const useAppApi = (requestConfig?: AxiosRequestConfig | undefined): AxiosInstance => 
{
  const { endpointURL } = useContext(AppSettings);
  const config: AxiosRequestConfig = {...requestConfig};
  config.baseURL = endpointURL;
  return createAppApi(config);
};