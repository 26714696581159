import React from 'react';
import { Container, Subtitle, Title } from './styles';

interface ReportHeaderProps {
  title: string;
  subtitle: string;
}

const ReportHeader: React.FC<ReportHeaderProps> = ({ title, subtitle }: ReportHeaderProps) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
    </Container>
  );
};

export default ReportHeader;
