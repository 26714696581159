import { Typography } from '@material-ui/core';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { Container } from './styles';

const LoadDataGrid: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <FontAwesomeIcon icon={faHourglassHalf} size="lg" />
      <Typography>{t('filter.title.loadingData')}</Typography>
    </Container>
  );
};

export default LoadDataGrid;
