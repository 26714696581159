import { AgGridColumn } from '@ag-grid-community/react';
import BooleanCanadaCheckMarkCellRenderer from 'components/DetailGrid/CellRenderers/BooleanCanadaCheckMarkCellRenderer';
import DayPartsCellRenderer from 'components/DetailGrid/CellRenderers/DayPartsCellRenderer';
import MultiColumnsCellRenderer from 'components/DetailGrid/CellRenderers/MultiColumnsCellRenderer/MultiColumnsCellRenderer';
import NewThisWeekCellRenderer from 'components/DetailGrid/CellRenderers/NewThisWeekCellRenderer';
import RankInfoCellRenderer from 'components/DetailGrid/CellRenderers/RankInfoCellRenderer/RankInfoCellRenderer';
import SongCellRenderer from 'components/DetailGrid/CellRenderers/SongCellRenderer/SongCellRenderer';
import ThisLastMoveCellRenderer from 'components/DetailGrid/CellRenderers/ThisLastMoveCellRenderer';
import DayPartsHeaderComponent from 'components/DetailGrid/HeaderComponents/DayPartsHeaderComponent';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import ThisLastMoveHeaderComponent from 'components/DetailGrid/HeaderComponents/ThisLastMoveHeaderComponent';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import { UseApiSettings } from 'hooks/useApi/ApiTypes';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import queryString from 'query-string';
import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import { GrcInfoEnum } from 'types/GrcInfo';
import { PanelCodeEnum } from 'types/Panel';
import { v4 as uuidv4 } from 'uuid';
import { ReportCycleIdEnum } from 'types/ReportCycle';
import { daysMinus, formatDate, formatDateTypes } from 'utils/dates';
import { formatComparisonInfoRank, formatComparisonInfoDeviations } from 'utils/reports/FormatComparisonInfoFormatters';
import { getStationDisplayName } from 'utils/reports/GetStationDisplayName';
import { StationPlaylistsSevenDayTransformer } from './utils/StationPlaylistsTransformer';
import { StationPlaylistsFilter } from './utils/StationPlaylistsTypes';
import { StationPlaylistSevenDayReport } from './utils/StationPlaylistReport';

interface StationPlaylistProps {
  filters: StationPlaylistsFilter | undefined;
}

const urls = new Map([
  [ReportCycleIdEnum.published, 'stations-playlist-published-reports'],
  [ReportCycleIdEnum.rolling, 'stations-playlist-seven-day-reports'],
  [ReportCycleIdEnum.building, 'stations-playlist-building-reports'],
  [ReportCycleIdEnum.FiveDay, 'stations-playlist-five-day-reports'],
]);
const COUNTRY_FORMAT_ID = 1003;

const StationPlaylistsGrid: React.FC<StationPlaylistProps> = ({ filters }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({
    sortPath: 'RankThisWeek',
    sort: 'asc',
  });

  const cycle = filters?.ReportCycle ?? ReportCycleIdEnum.published;
  const url = urls.get(cycle) || '';
  const { setSettings, settings, data: reportData, isLoading, isError } = useRemoteApi<StationPlaylistSevenDayReport>(
    url,
    {
      data: filters,
      method: 'POST',
      enabled: false,
      transformResponse: StationPlaylistsSevenDayTransformer,
    },
  );

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const songClickHandler = (data: any, reportQuery: StationPlaylistsFilter | undefined) => {
    const href = getReportSpec(ReportBaseEnum.SongAnalysisReport).path;
    const params = {
      ReportCycle: reportQuery?.ReportCycle,
      songCode: data.SongInfo?.SongCode,
      'Formats.FormatCode': [reportQuery?.StationInfo?.FormatInfo?.FormatCode],
      'PanelInfo.Id': reportQuery?.PanelInfo?.Id,
      deepLink: true,
    };
    window.open(`${href}?${queryString.stringify(params)}`);
  };

  const spinsThisWeekClickHandler = (data: any, reportQuery: StationPlaylistsFilter | undefined) => {
    const href = getReportSpec(ReportBaseEnum.StationSpinGridReport).path;
    const dt = daysMinus(new Date(), 1);
    const endDate = formatDate(dt, formatDateTypes.yyyyMMdd);
    const params = {
      songCode: data.SongInfo?.SongCode,
      callLetters: reportQuery?.StationInfo?.Name,
      EndDate: endDate,
      MaxWeeks: 4,
      deepLink: true,
    };
    window.open(`${href}?${queryString.stringify(params)}`);
  };

  const hasCanadianPanel = (panelCode: string | undefined) => {
    return panelCode === PanelCodeEnum.AllCanadianStations || panelCode === PanelCodeEnum.PublishedCanadaPanel;
  };

  const isCycle = (reportCycle: ReportCycleIdEnum) => {
    return filters?.ReportCycle === reportCycle;
  };

  const getStationName = () => {
    const name = getStationDisplayName(reportData?.StationInfo);
    if (name.length > 0) {
      return name.split('-')[0];
    }
    return '';
  };

  const renderCommonColumns = () => {
    return [
      <AgGridColumn
        field="NewThisWeek"
        key={uuidv4()}
        headerName={t('grid.header.NewThisWeek')}
        minWidth={42}
        width={42}
        wrapText
        cellRendererFramework={NewThisWeekCellRenderer}
        headerComponentParams={{ sortModel, onSortChange }}
        hide={filters?.GrcInfo?.GrcCode === GrcInfoEnum.Gold}
      />,
      <AgGridColumn
        headerName={t('grid.header.Rank')}
        key={uuidv4()}
        minWidth={60}
        width={60}
        field="Rank"
        headerComponentParams={{
          sortModel,
          onSortChange,
          disposition: 'row',
          secondLine: [
            { label: 'grid.header.TW', sortPath: 'RankThisWeek' },
            { label: 'grid.header.LW', sortPath: 'RankLastWeek' },
          ],
        }}
        cellRendererParams={{
          fields: ['Rank.ThisWeek', 'Rank.LastWeek'],
        }}
        cellRendererFramework={RankInfoCellRenderer}
        headerComponentFramework={MultiColumnsHeaderComponent}
      />,
      <AgGridColumn
        field="SongInfo"
        key={uuidv4()}
        pinned
        headerName={t(`grid.header.Title`)}
        minWidth={180}
        headerComponentFramework={MultiColumnsHeaderComponent}
        headerComponentParams={{
          sortModel,
          sortPath: 'Title',
          onSortChange,
          disposition: 'row',
          secondLine: [
            { sortPath: 'Artist', label: 'grid.header.Artist' },
            { sortPath: 'Label', label: 'grid.header.Label' },
          ],
        }}
        cellRendererFramework={SongCellRenderer}
        cellRendererParams={{
          reportQuery: filters,
          onClickHandler: (data: any, reportQuery: any) => songClickHandler(data, reportQuery),
        }}
      />,
      <AgGridColumn
        field="HasCanadianContent"
        key={uuidv4()}
        headerName="CanCon"
        minWidth={70}
        cellRendererFramework={BooleanCanadaCheckMarkCellRenderer}
        headerComponentParams={{ sortModel, onSortChange, sortPath: 'HasCanadianContent' }}
        hide={!hasCanadianPanel(filters?.PanelInfo?.PanelCode)}
      />,
      <AgGridColumn
        field="ReleaseYear"
        key={uuidv4()}
        minWidth={80}
        width={80}
        headerName={t(`grid.header.Year`)}
        headerComponentParams={{ sortModel, onSortChange }}
        hide={!(filters?.GrcInfo?.GrcCode === GrcInfoEnum.All || filters?.GrcInfo?.GrcCode === GrcInfoEnum.Gold)}
      />,
      <AgGridColumn
        field="PointsInfo"
        key={uuidv4()}
        minWidth={95}
        headerName={t(`grid.header.PointsInfoTW`)}
        cellRendererFramework={ThisLastMoveCellRenderer}
        headerComponentFramework={ThisLastMoveHeaderComponent}
        headerComponentParams={{ sortModel, onSortChange }}
        hide={!(filters?.StationInfo?.FormatInfo?.Id === COUNTRY_FORMAT_ID)}
      />,
      <AgGridColumn
        field="SpinsInfo"
        key={uuidv4()}
        minWidth={80}
        width={80}
        headerName={t(`grid.header.SpinsInfoTotal`)}
        headerComponentFramework={MultiColumnsHeaderComponent}
        headerComponentParams={{
          sortModel,
          onSortChange,
          disposition: 'row',
          secondLine: [
            { sortPath: 'SpinsInfo/ThisWeek', label: ['grid.header.TW'] },
            { sortPath: 'SpinsInfo/LastWeek', label: ['grid.header.LW'] },
            { sortPath: 'SpinsInfo/Move', label: ['grid.header.Move'] },
          ],
        }}
        cellRendererFramework={ThisLastMoveCellRenderer}
        cellRendererParams={{
          reportQuery: filters,
          thisWeekClickHandler: spinsThisWeekClickHandler,
        }}
      />,
      <AgGridColumn
        field="DaypartsInfo"
        key={uuidv4()}
        headerName={t(`grid.header.DaypartsInfo`)}
        width={230}
        minWidth={230}
        headerComponentFramework={DayPartsHeaderComponent}
        headerComponentParams={{ sortModel, onSortChange }}
        cellRendererFramework={DayPartsCellRenderer}
      />,
    ];
  };

  // published, rolling and five day
  const renderSevenDayColumns = () => {
    return [
      renderCommonColumns(),
      <AgGridColumn
        field="MarketInfo"
        key={uuidv4()}
        minWidth={250}
        width={250}
        headerName={`${t(`grid.header.MarketInfo`)}`}
        headerComponentFramework={MultiColumnsHeaderComponent}
        headerComponentParams={{
          sortModel,
          onSortChange,
          sortPath: 'Title',
          disposition: 'row',
          secondLine: [
            { label: `${getStationDisplayName(reportData?.StationInfo)} Share(%)`, sortPath: 'MarketSpinsShare' },
            { label: `Market Spins TW`, sortPath: 'MarketSpinsThisWeek' },
          ],
        }}
        cellRendererParams={{
          fields: ['MarketInfo.Share', 'MarketInfo.MarketSpinsThisWeek'],
        }}
        cellRendererFramework={MultiColumnsCellRenderer}
        hide={isCycle(ReportCycleIdEnum.FiveDay)}
      />,
      <AgGridColumn
        field="ImpressionCustomInfo"
        key={uuidv4()}
        minWidth={150}
        width={150}
        headerName={t(`grid.header.Impressions`)}
        headerComponentFramework={MultiColumnsHeaderComponent}
        headerComponentParams={{
          sortModel,
          onSortChange,
          disposition: 'row',
          secondLine: [
            { label: ['grid.header.impression.ReachMill'], sortPath: 'ImpressionsInfo/SpinsThisWeek' },
            { label: ['grid.header.impression.ImpressionRank'], sortPath: 'ImpressionsInfo/RankThisWeek' },
          ],
        }}
        cellRendererParams={{
          fields: ['ImpressionCustomInfo.ReachMill', 'ImpressionCustomInfo.ImpRank'],
        }}
        cellRendererFramework={MultiColumnsCellRenderer}
      />,
      <AgGridColumn
        field="HistoricalInfo"
        key={uuidv4()}
        minWidth={200}
        width={200}
        headerName={`Historical Data Since: ${formatDate(
          reportData?.FirstMonitoredDate || '',
          formatDateTypes.MMddyyyy,
        )}`}
        headerComponentFramework={MultiColumnsHeaderComponent}
        headerComponentParams={{
          sortModel,
          onSortChange,
          disposition: 'row',
          secondLine: [
            { label: ['grid.header.historicalData.FirstPlayed'], sortPath: 'FirstPlayDate' },
            { label: ['grid.header.historicalData.HistSpins'], sortPath: 'HistoricalSpins' },
          ],
        }}
        cellRendererParams={{
          fields: ['HistoricalInfo.FirstPlayDate', 'HistoricalInfo.HistoricalSpins'],
        }}
        cellRendererFramework={MultiColumnsCellRenderer}
      />,
      <AgGridColumn
        field="StationFormatComparisonInfo"
        key={uuidv4()}
        minWidth={200}
        width={200}
        headerName={t(`grid.header.FormatComparison`)}
        headerComponentFramework={MultiColumnsHeaderComponent}
        valueFormatter={({ value }) => ((value ?? 0) === 1000000 ? 'N/A' : `${value}`)}
        headerComponentParams={{
          sortModel,
          onSortChange,
          disposition: 'row',
          secondLine: [
            { label: ['grid.header.Rank'], sortPath: 'ChartRankThisWeek' },
            { label: ['grid.header.formatComparison.Avg'], sortPath: 'AveragePlays' },
            { label: getStationName(), sortPath: 'Deviations' },
          ],
        }}
        cellRendererParams={{
          fields: [
            'FormatComparisonInfo.ChartRankThisWeek',
            'FormatComparisonInfo.AveragePlays',
            'FormatComparisonInfo.Deviations',
          ],
          filters,
          fieldCellRenderers: [
            {
              field: 'FormatComparisonInfo.ChartRankThisWeek',
              fieldCellFormatter: formatComparisonInfoRank,
            },
            {
              field: 'FormatComparisonInfo.Deviations',
              fieldCellFormatter: formatComparisonInfoDeviations,
            },
          ],
        }}
        cellRendererFramework={MultiColumnsCellRenderer}
        hide={isCycle(ReportCycleIdEnum.FiveDay)}
      />,
      <AgGridColumn
        field="AmToPmInfo"
        key={uuidv4()}
        minWidth={150}
        width={150}
        headerName={t(`grid.header.H6amTo7pm`)}
        headerComponentFramework={MultiColumnsHeaderComponent}
        headerComponentParams={{
          sortModel,
          onSortChange,
          disposition: 'row',
          secondLine: [
            { label: ['grid.header.Rank'], sortPath: 'AmToPmInfo/Rank' },
            { label: ['grid.header.TW'], sortPath: 'AmToPmInfo/Spins' },
          ],
        }}
        cellRendererParams={{
          fields: ['AmToPmInfo.Rank', 'AmToPmInfo.Spins'],
        }}
        cellRendererFramework={MultiColumnsCellRenderer}
        hide={isCycle(ReportCycleIdEnum.FiveDay)}
      />,
      <AgGridColumn
        field="WithoutOvernight"
        key={uuidv4()}
        minWidth={150}
        width={150}
        headerName={t(`grid.header.WithoutOvernight`)}
        headerComponentFramework={MultiColumnsHeaderComponent}
        headerComponentParams={{
          sortModel,
          onSortChange,
          disposition: 'row',
          secondLine: [
            { label: ['grid.header.Rank'], sortPath: 'AmToPmInfo/Rank' },
            { label: ['grid.header.TW'], sortPath: 'AmToPmInfo/Spins' },
          ],
        }}
        cellRendererParams={{
          fields: ['WithoutOvernight.Rank', 'WithoutOvernight.SpinsThisWeek'],
        }}
        cellRendererFramework={MultiColumnsCellRenderer}
      />,
    ];
  };

  return (
    <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
      <ServerSideReportGrid
        setSettings={(setSettings as unknown) as Dispatch<UseApiSettings<ReportResult<unknown>>>}
        settings={(settings as unknown) as UseApiSettings<ReportResult<unknown>>}
        filters={filters}
        sortModel={sortModel}
      >
        {renderSevenDayColumns()}
      </ServerSideReportGrid>
    </GridVisibilityWrapper>
  );
};

export default StationPlaylistsGrid;
