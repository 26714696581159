/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
import { Column, ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import React from 'react';
import { ContainerGrid, ItemGrid } from './globalStylesCellRenderers';

interface DayOfTheWeekRendererProps {
  value?: {
    Daily7: number;
    Daily6: number;
    Daily5: number;
    Daily4: number;
    Daily3: number;
    Daily2: number;
    Daily1: number;
  };
}

interface Props extends DayOfTheWeekRendererProps {
  column: Column;
}

const DayOfTheWeekCellRenderer: React.FC<Props> = ({ value, column }: Props) => {
  const colWidth = 100 / 7;

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const valueGetter = (v: any) => {
    const colDef = column.getColDef();
    const { valueFormatter } = colDef;

    if (valueFormatter instanceof Function) {
      return valueFormatter({ ...colDef, value: v } as ValueFormatterParams);
    }
    return v;
  };

  const formatValue = (v: any) => {
    const result = valueGetter(v);
    const output = result === 0 ? '--' : result;
    return output;
  };

  const renderCell = (daily: number, idx: number, colWidth: number) => {
    return (
      <ItemGrid style={{ width: `${colWidth}%` }} key={idx}>
        {formatValue(daily)}
      </ItemGrid>
    );
  };
  const getContent = () => {
    if (!value) {
      return <></>;
    }
    const arrayItens = Object.values(value);
    const reverseOrder = arrayItens.reverse();
    return (
      <ContainerGrid aria-label="Day Parts">
        {reverseOrder.map((item, idx) => renderCell(item, idx, colWidth))}
      </ContainerGrid>
    );
  };

  return getContent();
};

DayOfTheWeekCellRenderer.defaultProps = { value: undefined };
export default DayOfTheWeekCellRenderer;
