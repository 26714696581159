/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import CheckboxFilter from 'components/Filter/components/Checkbox/index';
import ComponentGroup from 'components/Filter/components/ComponentGroup';
import ComponentGroupItem from 'components/Filter/components/ComponentGroup/ComponentGroupItem';
import FormatMultipleFilter from 'components/Filter/components/FormatMultipleFilter';
import PanelFilter from 'components/Filter/components/PanelFilter';
import PastDays, { getCurrentBuildingCycle } from 'components/Filter/components/PastDays';
import ReportCycle from 'components/Filter/components/ReportCycle';
import SongPortfolioSelect from 'components/Filter/components/SongPortfolioSelect';
import SongSelection from 'components/Filter/components/SongSelection';
import StationPortfolioGroupSelect from 'components/Filter/components/StationPorfolioGroup';
import StationPortfolioSelect from 'components/Filter/components/StationPortfolioSelect';
import StationSelect from 'components/Filter/components/StationSelect/SelectedStation';
import FilterBase from 'components/FilterBase';
import InputNumber from 'components/InputNumber';
import useDeepLink from 'hooks/useDeepLink/useDeepLink';
import { defaultFilterTransformer } from 'hooks/useDeepLink/utils/defaultFilterTransformer';
import useFilterState from 'hooks/useFilterState/useFilterState';
import useStickyFilter from 'hooks/useStickyFilter/useStickyFilter';
import { get } from 'lodash';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SongSelectionType, ReportCycleEnum } from 'types/Enums';
import FilterDataEvent from 'types/FilterDataEvent';
import { FormatInfo, FormatInfoChecked } from 'types/Format';
import { ReportCycleIdEnum, ReportCycleNameEnum } from 'types/ReportCycle';
import { SongInfo } from 'types/SongInfo';
import { Station } from 'types/Station';
import {
  SONG_PORTFOLIO,
  SONG_SELECTION,
  STATION_BY_MULTIPLE_FORMATS,
  STATION_PORTFOLIO,
  STATION_PORTFOLIO_GROUP,
  STATION_SELECTION,
} from 'utils/Constants';

import { getStationDisplayName } from 'utils/reports/GetStationDisplayName';
import { searchModeResolver } from './utils/searchModeResolver';
import { ExtendedSongAnalysisFilter } from './utils/SongAnalysisTypes';

interface FilterSevenDaysSongAnalysis {
  changeFilter: { (filters?: ExtendedSongAnalysisFilter | undefined): any };
  isOpened: boolean;
}
interface MinSpins {
  MinSpins: number;
}

const REPORTS_CYCLE = [
  { Name: ReportCycleNameEnum.published, Id: ReportCycleIdEnum.published },
  { Name: ReportCycleNameEnum.rolling, Id: ReportCycleIdEnum.rolling },
  { Name: ReportCycleNameEnum.building, Id: ReportCycleIdEnum.building },
];
const FilterSongAnalysis: React.FC<FilterSevenDaysSongAnalysis> = ({ changeFilter, isOpened }) => {
  const { t } = useTranslation();
  const pathname = getReportSpec(ReportBaseEnum.SongAnalysisReport).stickyKey;
  const { savedFilters: filter, saveFilter } = useStickyFilter<ExtendedSongAnalysisFilter>(pathname);
  const { isReady: isFilterReady, changeState } = useFilterState();

  // eslint-disable-next-line no-shadow
  const { hasDeepLinkInfo } = useDeepLink(filter, saveFilter, isFilterReady, {
    onReady: changeFilter,
    // eslint-disable-next-line no-shadow
    filterTransformer: (queryString: Map<string, string>, filter: ExtendedSongAnalysisFilter) => {
      const mapFormats = (formats: string): FormatInfoChecked[] => {
        return formats.split(',').map(v => ({ FormatCode: v.substring(0, 2) })) as FormatInfoChecked[];
      };
      const newFilter = defaultFilterTransformer(queryString, filter);
      const formats = mapFormats(queryString.get('Formats.FormatCode') ?? '');
      newFilter.Formats = formats;
      const finalFilter = {
        ...newFilter,
        ...searchModeResolver(STATION_BY_MULTIPLE_FORMATS),
        stationGroup: STATION_BY_MULTIPLE_FORMATS,
        songGroup: SONG_SELECTION,
        songCode: newFilter?.songCode,
      };
      return finalFilter;
    },
  });

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleFilterChange = (event: FilterDataEvent<object>) => {
    const { value } = event;
    saveFilter({ ...value });
  };

  const getSelectedSongsText = ({ value }: FilterDataEvent<unknown>) => {
    const songInfos = get(value, 'songInfos') as SongInfo[] | undefined;
    if (songInfos && songInfos.length) {
      return songInfos.length === 1 ? songInfos[0]?.Title : t('filter.label.various');
    }
    return '';
  };

  const getStationsLabel = ({ value }: FilterDataEvent<unknown>): string => {
    const stations = get(value, 'Stations') as Station[] | undefined;
    if (stations && stations.length) {
      return stations.length === 1 ? getStationDisplayName(stations[0]) ?? '' : t('filter.label.various');
    }
    return '';
  };

  const getFormatsLabel = ({ value }: FilterDataEvent<unknown>): string => {
    const formats = get(value, 'Formats') as FormatInfo[] | undefined;
    if (formats && formats.length) {
      return formats.length === 1 ? formats[0]?.Name ?? '' : t('filter.label.various');
    }
    return '';
  };

  const handleFilterChangeStationType = ({ value }: FilterDataEvent<string>) => {
    if (value) {
      const newValues = searchModeResolver(value);
      handleFilterChange({ value: { ...newValues, ...{ stationGroup: value } } });
    }
  };

  const handleFilterChangeSongGroup = (event: FilterDataEvent<string>) => {
    const { value } = event;
    if (value) {
      handleFilterChange({
        value: {
          SongSelectionType:
            value === SONG_PORTFOLIO ? SongSelectionType.SongPortfolio : SongSelectionType.SongSelection,
          songGroup: value,
        },
      });
    }
  };

  const handleSongSelectionChange = (event: FilterDataEvent<object>) => {
    const { value } = event;
    const result: ExtendedSongAnalysisFilter = { ...value };
    result.songSelectionSongInfos = result.songInfos;
    handleFilterChange({ value: result });
  };

  const handleSongPortfolioSelectionChange = (event: FilterDataEvent<object>) => {
    const { value } = event;
    const result: ExtendedSongAnalysisFilter = { ...value };
    result.songPortfolioSongInfos = result.songInfos;
    handleFilterChange({ value: result });
  };

  const getSearchByLabel = (): string => {
    const searchByText = t('filter.title.SearchBy');
    const result =
      filter?.stationGroup === STATION_BY_MULTIPLE_FORMATS
        ? `${searchByText} ${t('filter.title.formats')}`
        : `${searchByText} ${t('filter.title.stations')}`;
    return result;
  };

  return (
    <>
      <FilterBase onSubmit={() => changeFilter(filter)} isOpened={isOpened}>
        <ReportCycle
          apiPropertyName="ReportCycle"
          onChange={handleFilterChange}
          options={REPORTS_CYCLE}
          initialValue={filter?.ReportCycle}
        />
        <PastDays
          onChange={handleFilterChange}
          hasTodayOption={false}
          disabled={filter?.ReportCycle !== ReportCycleEnum.Building}
          initialValue={getCurrentBuildingCycle()}
        />
        <PanelFilter
          onChange={handleFilterChange}
          initialValue={filter?.PanelInfo}
          changeState={changeState}
          filterKey="PanelInfo"
          required
        />
        <ComponentGroup
          title={getSearchByLabel()}
          onChange={handleFilterChangeStationType}
          initialValue={filter?.stationGroup ?? STATION_BY_MULTIPLE_FORMATS}
        >
          <ComponentGroupItem
            label={t('filter.title.formats')}
            getLabel={getFormatsLabel}
            labelValue={STATION_BY_MULTIPLE_FORMATS}
          >
            <FormatMultipleFilter
              panelCode={filter?.PanelInfo?.PanelCode}
              onChange={handleFilterChange}
              values={filter?.Formats || []}
              apiPropertyName="Formats"
              changeState={changeState}
              filterKey="Formats"
              required={filter?.stationGroup === STATION_BY_MULTIPLE_FORMATS}
            />
          </ComponentGroupItem>
          <ComponentGroupItem
            label={t('filter.title.stations')}
            getLabel={getStationsLabel}
            labelValue={STATION_SELECTION}
          >
            <StationSelect title="Station Select" onChange={handleFilterChange} initialValue={filter?.Stations} />
          </ComponentGroupItem>
          <ComponentGroupItem
            label={t('filter.title.stationPortfolio')}
            getLabel={({ value }) => get(value, 'StationPortfolio.Name')}
            labelValue={STATION_PORTFOLIO}
          >
            <StationPortfolioSelect onChange={handleFilterChange} initialValue={filter?.StationPortfolio} />
          </ComponentGroupItem>
          <ComponentGroupItem
            label={t('filter.title.stationPortfolioGroup')}
            getLabel={({ value }) => get(value, 'StationPortfolioGroup.Name')}
            labelValue={STATION_PORTFOLIO_GROUP}
          >
            <StationPortfolioGroupSelect onChange={handleFilterChange} initialValue={filter?.StationPortfolioGroup} />
          </ComponentGroupItem>
        </ComponentGroup>
        <ComponentGroup
          initialValue={filter?.songGroup ?? SONG_SELECTION}
          title={t('filter.title.songs')}
          onChange={handleFilterChangeSongGroup}
        >
          <ComponentGroupItem
            label={t('filter.title.selectedSongs')}
            getLabel={getSelectedSongsText}
            labelValue={SONG_SELECTION}
          >
            <SongSelection
              onChange={handleSongSelectionChange}
              initialValue={filter?.songSelectionSongInfos || []}
              changeState={changeState}
              required
              songCode={hasDeepLinkInfo ? filter?.songCode : undefined}
            />
          </ComponentGroupItem>
          <ComponentGroupItem
            label={t('filter.title.songPortfolios')}
            getLabel={({ value }) => get(value, 'SongPortfolio.Name') ?? ''}
            labelValue={SONG_PORTFOLIO}
          >
            <SongPortfolioSelect
              onChange={handleSongPortfolioSelectionChange}
              initialValue={filter?.SongPortfolio}
              stateControl={changeState}
            />
          </ComponentGroupItem>
        </ComponentGroup>
        <InputNumber<MinSpins>
          minValue={1}
          disabled={!!filter?.ShowAllStationsInPanel}
          onChange={handleFilterChange}
          name={t('grid.header.minSpinsCuttOff')}
          valueToApi="MinSpins"
          initialValue={filter?.MinSpins || 1}
        />
        <CheckboxFilter
          onChange={handleFilterChange}
          name={t('filter.title.showAllStationsInPanel')}
          valueToApi="ShowAllStationsInPanel"
          initialValue={filter?.ShowAllStationsInPanel || false}
        />
      </FilterBase>
    </>
  );
};

export default FilterSongAnalysis;
