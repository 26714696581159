import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: calc(75% + 62px);
`;

export const AdsImage = styled.img`
  width: 100%;
`;

export const AdsContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
