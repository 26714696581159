/* eslint-disable @typescript-eslint/no-explicit-any */
import DateRange from 'components/Filter/components/CalendarFilter';
import { DatesUrlEnum } from 'components/Filter/components/CalendarFilter/utils';
import CheckBoxFilter from 'components/Filter/components/Checkbox';
import FormatFilter from 'components/Filter/components/FormatFilter';
import GRCFilter from 'components/Filter/components/Grc';
import PanelFilter from 'components/Filter/components/PanelFilter';
import ShowTopSelect from 'components/Filter/components/ShowTopSelect';
import FilterBase from 'components/FilterBase';
import InputNumberFilter from 'components/InputNumber';
import useStickyFilter from 'hooks/useStickyFilter/useStickyFilter';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateRangeGroup } from 'types/DateRange';
import FilterDataEvent from 'types/FilterDataEvent';
import { PanelCodeEnum } from 'types/Panel';
import { RollingFilterParams, MinSpins } from './utils/types';

interface FilterRollingChartReports {
  changeFilter: { (filters: RollingFilterParams): any };
  PreviousNextFilterDate?: DateRangeGroup;
  isOpened: boolean;
}

const Filter: React.FC<FilterRollingChartReports> = ({ changeFilter, isOpened, PreviousNextFilterDate }) => {
  const [showFrench, setShowFrench] = useState(true);
  const pathname = getReportSpec(ReportBaseEnum.RollingChartReport).stickyKey;
  const { savedFilters: filter, saveFilter } = useStickyFilter<RollingFilterParams>(pathname);
  const { t } = useTranslation();

  useEffect(() => {
    if (filter?.PanelInfo) {
      setShowFrench(
        !(
          filter?.PanelInfo?.PanelCode === PanelCodeEnum.AllCanadianStations ||
          filter?.PanelInfo?.PanelCode === PanelCodeEnum.PublishedCanadaPanel
        ),
      );
    }
  }, [filter]);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleFilterChange = (event: FilterDataEvent<object>) => {
    const { value } = event;
    saveFilter({ ...value } as RollingFilterParams);
  };

  const removeEmpyPropertiesInObject = (obj: RollingFilterParams) => {
    return Object.fromEntries(Object.entries(obj).filter(([, v]) => v != null));
  };

  const isReportValid = (filters: RollingFilterParams) => {
    const onlyValidFilters = removeEmpyPropertiesInObject(filters);
    const requiredFilters = ['PanelInfo', 'FormatInfo'];
    return requiredFilters.every(item => Object.prototype.hasOwnProperty.call(onlyValidFilters, item));
  };

  // One of the filters in FormatAndSation Portifolio is required
  return (
    <FilterBase
      onSubmit={() => changeFilter(filter as RollingFilterParams)}
      isOpened={isOpened}
      isFilterReady={isReportValid(filter as RollingFilterParams)}
    >
      <DateRange
        initialValue={PreviousNextFilterDate}
        getDateFromApi
        url={DatesUrlEnum.ROLLING_AVAILABILITY}
        onChange={handleFilterChange}
      />
      <PanelFilter onChange={handleFilterChange} initialValue={filter?.PanelInfo} />
      <FormatFilter
        hasAllOption={false}
        panelCode={filter?.PanelInfo?.PanelCode ?? PanelCodeEnum.AllUsStations}
        onChange={handleFilterChange}
        initialValue={filter?.FormatInfo}
      />
      <GRCFilter
        disabled={filter?.IsLastWeek || filter?.IsCustom}
        onChange={handleFilterChange}
        initialValue={filter?.GrcInfo}
      />
      <InputNumberFilter<MinSpins>
        minValue={1}
        onChange={handleFilterChange}
        name={t('grid.header.minSpinsCuttOff')}
        valueToApi="MinSpins"
        initialValue={filter?.MinSpins || 1}
      />
      <ShowTopSelect
        disabled={filter?.IsLastWeek || filter?.IsCustom}
        apiPropertyName="TopX"
        onChange={handleFilterChange}
        initialValue={filter?.ShowTop || 50}
      />
      <CheckBoxFilter
        name={t('filter.title.hideFrenchSongs')}
        onChange={handleFilterChange}
        valueToApi="HideFrenchSongs"
        hide={showFrench}
      />
    </FilterBase>
  );
};

export default Filter;
