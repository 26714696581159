/* eslint-disable no-param-reassign */
import { AgGridColumn } from '@ag-grid-community/react/lib/agGridColumn';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import DayPartsCellRenderer from 'components/DetailGrid/CellRenderers/DayPartsCellRenderer';
import RankInfoCellRenderer from 'components/DetailGrid/CellRenderers/RankInfoCellRenderer/RankInfoCellRenderer';
import SongCellRenderer from 'components/DetailGrid/CellRenderers/SongCellRenderer/SongCellRenderer';
import ThisLastMoveCellRenderer from 'components/DetailGrid/CellRenderers/ThisLastMoveCellRenderer';
import DayPartsHeaderComponent from 'components/DetailGrid/HeaderComponents/DayPartsHeaderComponent';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import ThisLastMoveHeaderComponent from 'components/DetailGrid/HeaderComponents/ThisLastMoveHeaderComponent';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import { UseApiSettings } from 'hooks/useApi/ApiTypes';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import { formatDate } from 'utils/dates';
import { StationCurrentDayBuildingTransformer } from './utils/CurrentDayMonitorTransformer';
import { CurrentDayMonitorsFilter, StationCurrentDailyRow } from './utils/CurrentDayMonitorTypes';

/* eslint-disable @typescript-eslint/no-explicit-any */
interface StationCurrentDayBuildingGridProps {
  filters: CurrentDayMonitorsFilter | undefined;
  songClickHandler: (data: any, reportQuery: CurrentDayMonitorsFilter | undefined) => void;
}

const url = 'station-current-day-building-reports';
const StationCurrentDayBuildingGrid: React.FC<StationCurrentDayBuildingGridProps> = ({ filters, songClickHandler }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({
    sortPath: 'RankThisWeek',
    sort: 'asc',
  });

  const { setSettings, settings, data: reportData, isLoading, isError } = useRemoteApi<
    ReportResult<StationCurrentDailyRow>
  >(url, {
    data: filters,
    method: 'POST',
    enabled: !!filters,
    transformRequest: data => {
      delete data.Stations;
      data.PanelInfo = {};
      return data;
    },
    transformResponse: StationCurrentDayBuildingTransformer,
  });

  return (
    <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
      <ServerSideReportGrid
        setSettings={(setSettings as unknown) as Dispatch<UseApiSettings<ReportResult<unknown>>>}
        settings={(settings as unknown) as UseApiSettings<ReportResult<unknown>>}
        filters={filters}
        sortModel={sortModel}
      >
        <AgGridColumn
          headerName={t('grid.header.Rank')}
          minWidth={60}
          width={60}
          field="Rank"
          headerComponentParams={{
            sortModel,
            onSortChange,
            disposition: 'row',
            secondLine: [
              { label: 'grid.header.TW', sortPath: 'RankThisWeek' },
              { label: 'grid.header.LW', sortPath: 'RankLastWeek' },
            ],
          }}
          cellRendererParams={{
            fields: ['Rank.ThisWeek', 'Rank.LastWeek'],
          }}
          cellRendererFramework={RankInfoCellRenderer}
          headerComponentFramework={MultiColumnsHeaderComponent}
        />
        <AgGridColumn
          field="SongInfo"
          pinned
          headerName={t(`grid.header.Title`)}
          minWidth={150}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            sortPath: 'Title',
            disposition: 'row',
            secondLine: [
              { label: 'grid.header.Artist', sortPath: 'Artist' },
              { label: 'grid.header.Label', sortPath: 'Label' },
            ],
          }}
          cellRendererFramework={SongCellRenderer}
          cellRendererParams={{
            reportQuery: filters,
            /* eslint-disable @typescript-eslint/no-explicit-any */
            onClickHandler: (data: any, reportQuery: any) => songClickHandler(data, reportQuery),
          }}
        />
        <AgGridColumn
          field="ReleaseYear"
          minWidth={80}
          width={80}
          headerName={t(`grid.header.Year`)}
          headerComponentParams={{ sortModel, onSortChange }}
        />
        <AgGridColumn
          field="SpinsInfo"
          minWidth={95}
          headerName={t(`grid.header.SpinsInfoTW`)}
          headerComponentFramework={MultiColumnsHeaderComponent}
          headerComponentParams={{
            sortModel,
            onSortChange,
            sortPath: 'SpinsInfo/ThisWeek',
            secondLine: [{ label: 'grid.header.LW', sortPath: 'SpinsInfo/LastWeek' }],
          }}
          cellRendererFramework={ThisLastMoveCellRenderer}
        />
        <AgGridColumn
          field="DaypartsInfo"
          headerName={t(`grid.header.DaypartsInfo`)}
          width={230}
          minWidth={230}
          headerComponentFramework={DayPartsHeaderComponent}
          headerComponentParams={{ sortModel, onSortChange }}
          cellRendererFramework={DayPartsCellRenderer}
        />
        <AgGridColumn
          field="PointsInfo"
          minWidth={95}
          headerName={t(`grid.header.PointsInfoTW`)}
          cellRendererFramework={ThisLastMoveCellRenderer}
          headerComponentFramework={ThisLastMoveHeaderComponent}
          headerComponentParams={{ sortModel, onSortChange }}
          hide={!(filters?.StationInfo?.FormatInfo?.Name === 'Country')}
        />
        <AgGridColumn
          field="SpinsToDate"
          minWidth={80}
          width={80}
          headerName={t(`grid.header.historicalData.HistSpins`)}
          headerComponentParams={{ sortModel, onSortChange }}
        />
        <AgGridColumn
          field="FirstPlayDate"
          valueFormatter={({ value }: ValueFormatterParams) => formatDate(value)}
          minWidth={80}
          width={80}
          headerName={t(`grid.header.historicalData.FirstPlayed`)}
          headerComponentParams={{ sortModel, onSortChange }}
        />
      </ServerSideReportGrid>
    </GridVisibilityWrapper>
  );
};

export default StationCurrentDayBuildingGrid;
