import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import Carousel from 'react-material-ui-carousel';
import { makeStyles, createStyles } from '@material-ui/core/styles';

export const CarouselContainer = styled(Carousel)`
  margin-top: 20px;
  background-color: white;
  padding: 16px;
  width: 100%;
`;

export const Card = styled(Grid)`
  background-color: white;
  padding: 16px;
`;

export const Label = styled(Typography)`
  font-size: 20px;
  color: #111b30;
  margin-bottom: 4px;
  font-weight: 600;
`;

export const Title = styled(Typography)`
  font-weight: 600;
`;

export const Description = styled(Typography)`
  font-size: 12px;
  color: #111b30;
`;

export const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontSize: '12px',
      fontWeight: 600,
    },
    description: {
      fontSize: '12px',
    },
  }),
);
