import React, { useEffect } from 'react';
import FullStory, { FullStoryAPI } from 'react-fullstory';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import { UserProfile } from 'types/UserProfile';

function AppFullStory(): JSX.Element {
  const ORG_ID = '118H54';

  const { data: profile } = useRemoteApi<UserProfile>('/user-profile', {
    method: 'GET',
    enabled: true,
  });

  useEffect(() => {
    if (profile?.UserId) {
      FullStoryAPI('identify', profile?.UserId?.toString(), {
        displayName: profile?.Fullname,
        companyId: profile?.CompanyId,
        companyName: profile?.CompanyName,
        userCategory: profile?.UserCategory,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);

  return (
    <div>
      <FullStory org={ORG_ID} debug />
    </div>
  );
}

export default AppFullStory;
