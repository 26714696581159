import React, { useState } from 'react';
import ReportHeader from 'components/ReportHeader';
import FilterButton from 'components/FilterButton';
import SiblingReportsSelect from 'components/SiblingReportsSelect/SiblingReportsSelect';
import { Container, ReportContainer, GridContainer } from './styles';
import { BuildingChartFilter } from './utils/BuildingChartReportTypes';
import Filter from './Filter';
import BuildingChartGrid from './BuildingChartGrid';

const BuildingChartReport: React.FC = () => {
  const [filterOpened, setFilterOpened] = useState(false);
  const [filters, setFilters] = useState<BuildingChartFilter | undefined>();

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const onChangeFilter = (filtersParams: any | null) => {
    setFilterOpened(false);
    setFilters(filtersParams);
  };

  return (
    <Container container direction="column" spacing={0}>
      <ReportHeader title="Chart" subtitle="7-Day" />
      <ReportContainer>
        <SiblingReportsSelect />
        <FilterButton onClickFilter={() => setFilterOpened(!filterOpened)} isOpened={filterOpened} />
      </ReportContainer>
      <Filter isOpened={filterOpened} changeFilter={onChangeFilter} />
      <GridContainer>
        <BuildingChartGrid filters={filters} />
      </GridContainer>
    </Container>
  );
};

export default BuildingChartReport;
