import { MenuItem, TextField } from '@material-ui/core';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import { usePrevious } from 'hooks/usePrevious/usePrevious';
import { isEqual, noop } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import FilterDataProps from 'types/FilterDataProps';
import { StationPortfolio } from 'types/StationPortfolio';
import { useStyles } from './styles';

interface Props extends FilterDataProps<FilterData> {
  disabled?: boolean;
  label?: string;
}

interface FilterData {
  StationPortfolioGroup: StationPortfolio | undefined;
}

const url = '/station-portfolio-groups';

const StationPortfolioGroupSelect: React.FC<Props> = ({
  disabled = false,
  onChange = noop,
  label = '',
  initialValue,
}) => {
  const { t } = useTranslation();
  const nameSelect = t('fields.Select');
  const classes = useStyles();
  const [availableValues, setAvailableValues] = useState<StationPortfolio[]>([]);
  const [selectedValue, setSelectedValue] = useState<StationPortfolio>();
  const previousSelectedValue = usePrevious(selectedValue);

  const { data: reportData } = useRemoteApi<ReportResult<unknown>>(url, {
    method: 'GET',
    enabled: true,
  });

  useEffect(() => {
    if (!isEqual(initialValue, selectedValue)) {
      setSelectedValue(initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  useEffect(() => {
    if (!isEqual(selectedValue, previousSelectedValue)) {
      onChange({ value: { StationPortfolioGroup: selectedValue } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  useEffect(() => {
    const data = (reportData || []) as StationPortfolio[];
    setAvailableValues(data);
  }, [reportData]);

  const handleChange = (ev: React.ChangeEvent<{ value: unknown }>): void => {
    const { value } = ev.target;
    setSelectedValue(availableValues.find(v => v.Id === value));
  };

  return (
    <TextField
      select
      label={label}
      value={selectedValue?.Id ?? ''}
      onChange={handleChange}
      disabled={disabled}
      aria-label={t('filter.title.stationPortfolioGroup')}
      className={classes.root}
    >
      <MenuItem key="1" value="1">
        {nameSelect}
      </MenuItem>
      {availableValues.map(item => {
        return (
          <MenuItem key={item.Id} value={item.Id}>
            {item.Name}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default StationPortfolioGroupSelect;
