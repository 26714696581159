import React from 'react';
import { DayPartInfo } from 'types/DayPart';
import { v4 } from 'uuid';
import InnerTable from '..';
import { IInnerTableGroup } from '../inner-table-group';

export interface DayPartsData {
  DaypartsInfo: DayPartInfo;
}

interface DayPartsInnerTableProps {
  data: DayPartsData;
  name?: string;
}

const DayPartsInnerTable: React.FC<DayPartsInnerTableProps> = ({
  data,
  name = 'Dayparts',
}: DayPartsInnerTableProps) => {
  const innerTableData: IInnerTableGroup[] = [
    {
      title: name,
      children: [
        {
          title: '',
          children: ['OVN', 'AMD', 'MID', 'PMD', 'EVE'],
        },
        {
          title: '',
          children: [
            data.DaypartsInfo.Overnight as string,
            data.DaypartsInfo.AmDrive as string,
            data.DaypartsInfo.Midday as string,
            data.DaypartsInfo.PmDrive as string,
            data.DaypartsInfo.Evening as string,
          ],
        },
      ],
    },
  ];

  return <InnerTable key={v4()} data={innerTableData} />;
};

DayPartsInnerTable.defaultProps = { name: 'Dayparts' };

export default DayPartsInnerTable;
