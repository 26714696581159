import { Column } from '@ag-grid-enterprise/all-modules';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DayPartInfo } from 'types/DayPart';
import { v4 } from 'uuid';
import InnerTable from '..';
import { IInnerTableGroup } from '../inner-table-group';
import { Container } from './styles';

export interface DayPartsData {
  DaypartsPercentageInfo: DayPartInfo;
  DaypartsInfoToDate: DayPartInfo;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
interface DayPartsInnerTableProps {
  data: any;
  invisibleColumns: Array<Column>;
  title?: string;
  field: keyof DayPartsData;
  totalField: string;
}

const DayPartsWithPercentageInnerTable: React.FC<DayPartsInnerTableProps> = ({
  data,
  invisibleColumns,
  field,
  totalField = 'SpinsToDate',
  title = 'Dayparts',
}: DayPartsInnerTableProps) => {
  const { t } = useTranslation();
  const totalValue = data[totalField];
  const createSpan = (item: string): JSX.Element => {
    // eslint-disable-next-line react/jsx-one-expression-per-line
    return <Container key={v4()}>{item} </Container>;
  };

  const composeHeader = () => {
    return invisibleColumns[0]
      .getColDef()
      .headerComponentParams.secondLine.map((x: { label: string; sortPath: string }) => t(x.label));
  };

  const composeTitle = () => {
    const { headerName } = invisibleColumns[0].getColDef();
    return headerName === undefined ? t(title) : headerName;
  };

  const getPercentValue = (valueParam: number) => {
    return `${((valueParam * 100) / totalValue).toFixed(1)}%`;
  };

  const getValues = (): JSX.Element[] => {
    return Object.values(data[field] as DayPartInfo).map(v => createSpan(`${v} | ${getPercentValue(v)}`));
  };

  const innerTableData: IInnerTableGroup[] = [
    {
      title: composeTitle(),
      children: [
        {
          title: '',
          children: composeHeader(),
        },
        {
          title: '',
          children: [totalValue, ...getValues()],
        },
      ],
    },
  ];

  return <InnerTable key={v4()} data={innerTableData} />;
};

DayPartsWithPercentageInnerTable.defaultProps = { title: 'Dayparts' };

export default DayPartsWithPercentageInnerTable;
