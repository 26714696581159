import React, { useContext, useState } from 'react';
import UserPreferences from 'contexts/UserPreferencesContext';
import AppSettings from 'contexts/AppSettingsContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';

import useRemoteApi from 'hooks/useApi/useRemoteApi';
import LoadDataGrid from 'components/LoadDataGrid';
import DEFAULT_BANNER_ADVERTISE from '../../../../images/default_banner_advertise.jpg';
import DEFAULT_POSTER from '../../../../images/default_poster.gif';
import { AdsImage, Container, AdsContainer, AdsFooter, PdfLink, PdfButton, useStyles } from './styles';
import { PageAdvertisementsItem } from './utils/AdvertiseTypes';

interface AdvertiseProps {
  isDashboard?: boolean;
}

const adsUrl = '/page-advertisements/dashboard';
const AdvertiseComponent: React.FC<AdvertiseProps> = ({ isDashboard = true }) => {
  const { baseUrl } = useContext(AppSettings);
  const { DefaultFormatCode } = useContext(UserPreferences);
  const classes = useStyles();

  const [hasError, setHasError] = useState(false);
  const { data: adsData, isLoading } = useRemoteApi<PageAdvertisementsItem[]>(adsUrl, {
    method: 'GET',
    enabled: !!DefaultFormatCode,
    params: { formatCode: DefaultFormatCode },
  });

  const { data } = useRemoteApi<string>('/pdf-chart/location', {
    method: 'GET',
    enabled: true,
  });

  const openPdf = () => {
    window.open(data);
  };

  if (isLoading || !DefaultFormatCode) {
    return <LoadDataGrid />;
  }

  return (
    <Container>
      {adsData?.length && !hasError ? (
        <AdsContainer>
          <AdsImage
            src={`${baseUrl}${adsData[0][isDashboard ? 'BannerImage' : 'PosterImage']}`}
            onError={() => setHasError(true)}
          />
          <AdsImage
            src={`${baseUrl}${adsData[0][isDashboard ? 'PosterImage' : 'BannerImage']}`}
            onError={() => setHasError(true)}
          />
        </AdsContainer>
      ) : (
        <AdsContainer>
          <AdsImage src={DEFAULT_BANNER_ADVERTISE} />
          <AdsImage src={DEFAULT_POSTER} />
        </AdsContainer>
      )}
      <AdsFooter>
        <PdfButton onClick={openPdf}>
          <FontAwesomeIcon icon={faFilePdf} size="1x" />
          <PdfLink className={classes.pdfLink}>Download</PdfLink>
        </PdfButton>
      </AdsFooter>
    </Container>
  );
};

export default AdvertiseComponent;
