import { AgGridColumn } from '@ag-grid-community/react';
import React, { Dispatch, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import { GridOptions, ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import { UseApiSettings } from 'hooks/useApi/ApiTypes';
import ReportResult from 'services/ReportResult';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import MultiColumnsCellRenderer from 'components/DetailGrid/CellRenderers/MultiColumnsCellRenderer/MultiColumnsCellRenderer';
import DetailGrid from 'components/DetailGrid';
import DayPartsHeaderComponent from 'components/DetailGrid/HeaderComponents/DayPartsHeaderComponent';
import DayPartsCellRenderer from 'components/DetailGrid/CellRenderers/DayPartsCellRenderer';
import { Button } from '@material-ui/core';
import OwnerSelection from 'components/Filter/components/OwnerSelection';
import FormatSelection from 'components/Filter/components/FormatSelection';
import FilterDataEvent from 'types/FilterDataEvent';
import { numberFormat } from 'utils/currency';
import { SongAnalysisFilter } from './utils/SongAnalysisTypes';
import {
  SongAnalysisGroupOwnerSummaryRequestTransformer,
  SongAnalysisGroupOwnerSummaryTransformer,
} from './utils/SongAnalysisTransformer';
import { SevenDaySongAnalysisExecutiveSummaryTransformedResponse } from './utils/SongAnalysisExecutiveSummaryTypes';
import { FilterGridContainer, FormatContainer, OwnerContainer } from './styles';
import { GroupOwnerFilter } from './utils/SongAnalysisGroupOwnerSummaryTypes';

export interface GroupOwnerSummaryGridProps {
  onChangeFilter: { (value: GroupOwnerFilter): void };
  filters: SongAnalysisFilter;
}

const url = '/seven-day-song-analysis-group-owner-reports';
const GroupOwnerSummaryGrid: React.FC<GroupOwnerSummaryGridProps> = ({ filters, onChangeFilter }) => {
  const { t } = useTranslation();
  const [groupOwnerFilter, setGroupOwnerFilter] = useState<GroupOwnerFilter | undefined>();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({ sortPath: 'TotalSpinsPercent', sort: 'asc' });
  const { data: reportData, isLoading, settings, setSettings, isError } = useRemoteApi<
    SevenDaySongAnalysisExecutiveSummaryTransformedResponse
  >(url, {
    data: filters,
    method: 'POST',
    enabled: false,
    transformRequest: SongAnalysisGroupOwnerSummaryRequestTransformer,
    transformResponse: SongAnalysisGroupOwnerSummaryTransformer,
  });

  const TopOptions: GridOptions = {
    alignedGrids: [],
    defaultColDef: {
      editable: true,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 100,
    },
    suppressHorizontalScroll: true,
  };
  const BottomOptions: GridOptions = {
    alignedGrids: [],
    defaultColDef: {
      editable: true,
      sortable: true,
      resizable: true,
      filter: true,
      flex: 1,
      minWidth: 100,
    },
  };
  ((TopOptions as GridOptions).alignedGrids ?? []).push(BottomOptions);
  ((BottomOptions as GridOptions).alignedGrids ?? []).push(TopOptions);

  const onChangeStationDetailFilter = (event: FilterDataEvent<GroupOwnerFilter>) => {
    const { value } = event;
    setGroupOwnerFilter({
      ...groupOwnerFilter,
      ...value,
    });
  };

  return (
    <>
      <FilterGridContainer>
        <OwnerContainer>
          <OwnerSelection onChange={onChangeStationDetailFilter} />
        </OwnerContainer>
        <FormatContainer>
          <FormatSelection
            onChange={onChangeStationDetailFilter}
            isArray={false}
            apiPropertyName="FormatInfo"
            panelCode={filters?.PanelInfo?.PanelCode}
          />
        </FormatContainer>
        <Button
          style={{ justifyContent: 'flex-end', fontSize: '10px' }}
          color="primary"
          onClick={() => onChangeFilter(groupOwnerFilter as GroupOwnerFilter)}
        >
          {t('filter.title.apply')}
        </Button>
      </FilterGridContainer>
      <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
        <ServerSideReportGrid
          sortModel={sortModel}
          settings={(settings as unknown) as UseApiSettings<ReportResult<unknown>>}
          setSettings={(setSettings as unknown) as Dispatch<UseApiSettings<ReportResult<unknown>>>}
          filters={filters}
          detailProps={{
            gridOptions: TopOptions,
            containerStyle: {
              height: '40vh',
            },
          }}
        >
          <AgGridColumn
            field="TotalSpinsPercent"
            minWidth={100}
            width={100}
            valueFormatter={({ value }: ValueFormatterParams) => numberFormat(value)}
            headerName={t(`grid.header.PercentOfTotalSpins`)}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="TotalImpressionsPercent"
            minWidth={100}
            width={100}
            valueFormatter={({ value }: ValueFormatterParams) => numberFormat(value)}
            headerName={t(`grid.header.PercentOfTotalImpressions`)}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="GroupOwnerName"
            minWidth={140}
            width={140}
            headerName={t(`grid.header.Group`)}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="FormatName"
            minWidth={100}
            width={100}
            headerName={t(`grid.header.FormatName`)}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="SpinsInfo"
            minWidth={150}
            headerName={t(`grid.header.Spins`)}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              sortPath: 'SpinsInfo/ThisWeek',
              secondLine: [
                { label: 'grid.header.TW', sortPath: 'SpinsInfo/ThisWeek' },
                { label: 'grid.header.LW', sortPath: 'SpinsInfo/LastWeek' },
                { label: 'grid.header.Move', sortPath: 'ImpressionsInfo/Move' },
              ],
            }}
            cellRendererParams={{
              fields: ['SpinsInfo.ThisWeek', 'SpinsInfo.LastWeek', 'SpinsInfo.Move'],
            }}
            cellRendererFramework={MultiColumnsCellRenderer}
          />
          <AgGridColumn
            field="DaypartsInfo"
            headerName={t(`grid.header.DaypartsInfo`)}
            width={230}
            minWidth={230}
            headerComponentFramework={DayPartsHeaderComponent}
            headerComponentParams={{ sortModel, onSortChange }}
            cellRendererFramework={DayPartsCellRenderer}
          />
          <AgGridColumn
            headerName={t('grid.header.StationPlaying')}
            width={80}
            minWidth={80}
            field="StationPlayingInfo"
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              sortPath: 'StationsPlayingInfo/StationsPlayingTitle',
            }}
          />
          <AgGridColumn
            field="AverageSpinsPerStation"
            minWidth={100}
            valueFormatter={({ value }: ValueFormatterParams) => numberFormat(value)}
            width={100}
            headerName={t(`grid.header.AverageSpinsPerStation`)}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="ImpressionsThisWeek"
            minWidth={100}
            width={100}
            valueFormatter={({ value }: ValueFormatterParams) => numberFormat(value)}
            headerName={t(`grid.header.ImpressionsInfo`)}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="SpinsToDate"
            minWidth={100}
            width={100}
            headerName={t(`grid.header.SpinsToDay`)}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="ImpressionsToDate"
            minWidth={100}
            width={100}
            valueFormatter={({ value }: ValueFormatterParams) => numberFormat(value, 'en-US', 4)}
            headerName={t(`grid.header.ImpressionsToDate`)}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="DayByDaySpins"
            colId="DayByDaySpinsSevenDay"
            minWidth={350}
            width={350}
            headerName={t(`grid.header.DayByDaySpins`)}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              secondLine: [
                { label: ['grid.header.dayByDaySpinsDaily.Day1'], sortPath: 'DayByDaySpins/1/ThisWeek' },
                { label: ['grid.header.dayByDaySpinsDaily.Day2'], sortPath: 'DayByDaySpins/2/ThisWeek' },
                { label: ['grid.header.dayByDaySpinsDaily.Day3'], sortPath: 'DayByDaySpins/3/ThisWeek' },
                { label: ['grid.header.dayByDaySpinsDaily.Day4'], sortPath: 'DayByDaySpins/4/ThisWeek' },
                { label: ['grid.header.dayByDaySpinsDaily.Day5'], sortPath: 'DayByDaySpins/5/ThisWeek' },
                { label: ['grid.header.dayByDaySpinsDaily.Day6'], sortPath: 'DayByDaySpins/6/ThisWeek' },
                { label: ['grid.header.dayByDaySpinsDaily.Day7'], sortPath: 'DayByDaySpins/7/ThisWeek' },
              ],
            }}
            cellRendererParams={{
              fields: [
                'DayByDaySpins.1.ThisWeek',
                'DayByDaySpins.2.ThisWeek',
                'DayByDaySpins.3.ThisWeek',
                'DayByDaySpins.4.ThisWeek',
                'DayByDaySpins.5.ThisWeek',
                'DayByDaySpins.6.ThisWeek',
                'DayByDaySpins.7.ThisWeek',
              ],
            }}
            cellRendererFramework={MultiColumnsCellRenderer}
          />
        </ServerSideReportGrid>
        <DetailGrid
          reportData={[reportData?.TotalItems] ?? []}
          headerHeight={0}
          gridOptions={BottomOptions}
          domLayout="autoHeight"
          containerStyle={{ height: '100%' }}
          rowStyle={{ fontWeight: 'bold' }}
        >
          <AgGridColumn
            field="TotalSpinsPercent"
            minWidth={100}
            width={100}
            valueFormatter={({ value }: ValueFormatterParams) => numberFormat(value)}
            headerName={t(`grid.header.PercentOfTotalSpins`)}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="TotalImpressionsPercent"
            minWidth={100}
            width={100}
            valueFormatter={({ value }: ValueFormatterParams) => numberFormat(value)}
            headerName={t(`grid.header.PercentOfTotalImpressions`)}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="GroupOwnerName"
            minWidth={140}
            width={140}
            headerName={t(`grid.header.Group`)}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="FormatName"
            minWidth={100}
            width={100}
            headerName={t(`grid.header.FormatName`)}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="SpinsInfo"
            minWidth={150}
            headerName={t(`grid.header.Spins`)}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              sortPath: 'SpinsInfo/ThisWeek',
              secondLine: [
                { label: 'grid.header.TW', sortPath: 'SpinsInfo/ThisWeek' },
                { label: 'grid.header.LW', sortPath: 'SpinsInfo/LastWeek' },
                { label: 'grid.header.Move', sortPath: 'ImpressionsInfo/Move' },
              ],
            }}
            cellRendererParams={{
              fields: ['SpinsInfo.ThisWeek', 'SpinsInfo.LastWeek', 'SpinsInfo.Move'],
            }}
            cellRendererFramework={MultiColumnsCellRenderer}
          />
          <AgGridColumn
            field="DaypartsInfo"
            headerName={t(`grid.header.DaypartsInfo`)}
            width={230}
            minWidth={230}
            headerComponentFramework={DayPartsHeaderComponent}
            headerComponentParams={{ sortModel, onSortChange }}
            cellRendererFramework={DayPartsCellRenderer}
          />
          <AgGridColumn
            headerName={t('grid.header.StationPlaying')}
            width={80}
            minWidth={80}
            field="StationPlayingInfo"
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              sortPath: 'StationsPlayingInfo/StationsPlayingTitle',
            }}
          />
          <AgGridColumn
            field="AverageSpinsPerStation"
            minWidth={100}
            valueFormatter={({ value }: ValueFormatterParams) => numberFormat(value)}
            width={100}
            headerName={t(`grid.header.AverageSpinsPerStation`)}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="ImpressionsThisWeek"
            minWidth={100}
            width={100}
            valueFormatter={({ value }: ValueFormatterParams) => numberFormat(value)}
            headerName={t(`grid.header.ImpressionsInfo`)}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="SpinsToDate"
            minWidth={100}
            width={100}
            headerName={t(`grid.header.SpinsToDay`)}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="ImpressionsToDate"
            minWidth={100}
            width={100}
            valueFormatter={({ value }: ValueFormatterParams) => numberFormat(value, 'en-US', 4)}
            headerName={t(`grid.header.ImpressionsToDate`)}
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="DayByDaySpins"
            colId="DayByDaySpinsSevenDay"
            minWidth={350}
            width={350}
            headerName={t(`grid.header.DayByDaySpins`)}
            headerComponentFramework={MultiColumnsHeaderComponent}
            headerComponentParams={{
              sortModel,
              onSortChange,
              secondLine: [
                { label: ['grid.header.dayByDaySpinsDaily.Day1'], sortPath: 'DayByDaySpins/1/ThisWeek' },
                { label: ['grid.header.dayByDaySpinsDaily.Day2'], sortPath: 'DayByDaySpins/2/ThisWeek' },
                { label: ['grid.header.dayByDaySpinsDaily.Day3'], sortPath: 'DayByDaySpins/3/ThisWeek' },
                { label: ['grid.header.dayByDaySpinsDaily.Day4'], sortPath: 'DayByDaySpins/4/ThisWeek' },
                { label: ['grid.header.dayByDaySpinsDaily.Day5'], sortPath: 'DayByDaySpins/5/ThisWeek' },
                { label: ['grid.header.dayByDaySpinsDaily.Day6'], sortPath: 'DayByDaySpins/6/ThisWeek' },
                { label: ['grid.header.dayByDaySpinsDaily.Day7'], sortPath: 'DayByDaySpins/7/ThisWeek' },
              ],
            }}
            cellRendererParams={{
              fields: [
                'DayByDaySpins.1.ThisWeek',
                'DayByDaySpins.2.ThisWeek',
                'DayByDaySpins.3.ThisWeek',
                'DayByDaySpins.4.ThisWeek',
                'DayByDaySpins.5.ThisWeek',
                'DayByDaySpins.6.ThisWeek',
                'DayByDaySpins.7.ThisWeek',
              ],
            }}
            cellRendererFramework={MultiColumnsCellRenderer}
          />
        </DetailGrid>
      </GridVisibilityWrapper>
    </>
  );
};

export default GroupOwnerSummaryGrid;
