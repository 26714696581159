import { AgGridColumn } from '@ag-grid-community/react';
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import { format, getMonth } from 'date-fns';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import { range } from 'lodash';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import ReportResult from 'services/ReportResult';
import { SevenDayArtistAnalysisReport } from './utils/SevenDayArtistAnalysisReports';

interface YearToDateSongReportGridProps {
  rowData?: SevenDayArtistAnalysisReport;
}

const url = `/year-to-date-artists-by-station-songs-reports`;
const DEFAULT_PANEL_CODE = 'M';
const YearToDateSongReportGrid: FC<YearToDateSongReportGridProps> = ({ rowData: filters }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({ sortPath: 'Rank', sort: 'asc' });
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const { setSettings, settings, data: reportData, isLoading, isError } = useRemoteApi<ReportResult<any>>(url, {
    method: 'POST',
    enabled: false,
    transformRequest: (data: SevenDayArtistAnalysisReport) => {
      return {
        StationInfo: {
          Name: data.StationName,
        },
        PanelInfo: {
          PanelCode: DEFAULT_PANEL_CODE,
        },
        Artist: data.ArtistName,
      };
    },
  });

  const getBaseDatesToMonth = (): Date[] => {
    const today = new Date();
    return range(0, getMonth(today) + 1).map(m => new Date(2020, m, 1));
  };

  return (
    <>
      <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
        <ServerSideReportGrid setSettings={setSettings} settings={settings} filters={filters} sortModel={sortModel}>
          <AgGridColumn
            field="Rank"
            headerName={t('grid.header.Rank')}
            minWidth={60}
            maxWidth={60}
            wrapText
            headerComponentParams={{ sortModel, onSortChange }}
          />
          <AgGridColumn
            field="Title"
            headerName={t('grid.header.Title')}
            minWidth={240}
            wrapText
            headerComponentParams={{
              sortModel,
              onSortChange,
            }}
          />

          <AgGridColumn
            field="TotalSpins"
            headerName={t('grid.header.Spins')}
            minWidth={60}
            maxWidth={60}
            wrapText
            headerComponentParams={{
              sortModel,
              onSortChange,
            }}
          />

          {getBaseDatesToMonth().map(m => {
            return (
              <AgGridColumn
                field={`YearDataInfo.${format(m, 'MMMM')}`}
                headerName={format(m, 'MMM')}
                maxWidth={60}
                minWidth={60}
                headerComponentParams={{ sortModel, onSortChange }}
              />
            );
          })}
        </ServerSideReportGrid>
      </GridVisibilityWrapper>
    </>
  );
};

export default YearToDateSongReportGrid;
