import ReportResult from 'services/ReportResult';
import { RollingAvailable } from './types';
import RollingChartReport from './RollingChartReport';
import { TransformedRollingChartReport } from './TransformedRollingChartReport';

export const RollingChartReportTransformer = (
  v: ReportResult<RollingChartReport>,
): ReportResult<TransformedRollingChartReport> => {
  const transformItems = (i: RollingChartReport): TransformedRollingChartReport => {
    return {
      ...i,
      RankInfo: { ThisWeek: i.RankThisWeek, LastWeek: i.RankLastWeek },
      SongInfo: {
        Artist: i.Artist,
        ArtistByLastname: i.ArtistByLastname,
        DisplayOrder: 0,
        HasCanadianContent: i.HasCanadianContent,
        Id: 0,
        Label: i.Label,
        SongCardAudioType: i.SongCardAudioType,
        SongCode: i.SongCode,
        Title: i.Title,
        Year: i.ReleaseYear,
      },
      Impressions: {
        SpinsLastWeek: i.ImpressionsInfo.SpinsLastWeek,
        SpinsThisWeek: i.ImpressionsInfo.SpinsThisWeek,
        Move: i.ImpressionsInfo.Move,
        RankLastWeek: i.ImpressionsInfo.RankLastWeek,
        RankThisWeek: i.ImpressionsInfo.RankThisWeek,
      },
      AveragePlay: {
        LastWeek: i.AveragePlayInfo.LastWeek,
        Move: i.AveragePlayInfo.Move,
        Peak: i.AveragePlayInfo.Peak,
        ThisWeek: i.AveragePlayInfo.ThisWeek,
        StationsPlayingTitle: i.StationsInfo.StationsPlayingTitle,
      },
    };
  };
  return { ...v, Items: v.Items.map(transformItems) };
};

export const RollingAvailableTransformer = (v: RollingAvailable): RollingAvailable => {
  const convertDates = {
    LastWeekRange: { Start: new Date(v.LastWeekRange.Start), End: new Date(v.LastWeekRange.End) },
    ThisWeekRange: { Start: new Date(v.ThisWeekRange.Start), End: new Date(v.ThisWeekRange.End) },
  };
  return convertDates;
};
