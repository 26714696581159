/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import MenuItem from '@material-ui/core/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import defaultTheme from 'themes';
import AuthSettings from 'contexts/AuthContext';

const MenuProfile: React.FC = () => {
  const { onLogout } = useContext(AuthSettings);
  const itemMenus = [
    {
      name: 'Logout',
      route: '/login',
    },
    {
      name: 'Help',
      route: '/support',
    },
  ];
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const selectItemMenu = (event: any, idx: any) => {
    if (idx === 0) {
      onLogout();
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="primary"
      >
        <FontAwesomeIcon icon={faUser} color={defaultTheme.palette.primary.main} size="1x" />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        {itemMenus.map((item, index) => {
          return (
            <MenuItem key={item.name} onClick={event => selectItemMenu(event, index)}>
              {item.name}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
};

export default MenuProfile;
