import { ColumnApi, ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Impressions from 'types/Impressions';
import { v4 } from 'uuid';
import { isSameDay } from 'date-fns';
import InnerTable from '..';
import { IInnerTableGroup } from '../inner-table-group';

export interface ImpressionsInfoGroupData {
  Impressions?: Impressions;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
interface ImpressionsInfoProps {
  data: ImpressionsInfoGroupData;
  fieldName: string;
  colId: string;
  name: string;
  columnApi: ColumnApi;
  showRanking: boolean;
  reportQuery?: any;
}

const ImpressionsInnerTable: React.FC<ImpressionsInfoProps> = ({
  data,
  fieldName,
  colId,
  name,
  columnApi,
  showRanking,
  reportQuery,
}: ImpressionsInfoProps) => {
  const { t } = useTranslation();
  const normalizeField = fieldName as keyof ImpressionsInfoGroupData;

  const formatValue = (value: number | string): string => {
    const col = columnApi.getColumn(colId);

    if (col != null) {
      const colDef = col.getColDef();
      const { valueFormatter } = colDef;

      if (valueFormatter instanceof Function) {
        return valueFormatter({ ...colDef, value } as ValueFormatterParams);
      }
    }

    return value as string;
  };

  const isArchived = (): boolean => {
    let result = false;
    if (reportQuery != null && reportQuery.MaxDate != null && reportQuery.DateRange != null) {
      const maxDate = reportQuery.MaxDate;
      const endDate = reportQuery.DateRange.End;
      result = isSameDay(endDate, maxDate) === false;
    }
    return result;
  };

  const getHeaders = () => {
    let headers = [t('grid.header.TW'), t('grid.header.LW'), t('grid.header.plusMinus')];
    if (showRanking === true) {
      headers.push(t('grid.header.RTW'));
      headers.push(t('grid.header.RLW'));
    }

    // if we need to show only archived data
    // only show the fields for which we have data
    if (isArchived()) {
      headers = [t('grid.header.TW')];
    }

    return headers;
  };

  const children = (groupElements: ImpressionsInfoGroupData) => {
    let array = ['SpinsThisWeek', 'SpinsLastWeek', 'Move'];
    if (showRanking === true) {
      array.push('RankThisWeek');
      array.push('RankLastWeek');
    }

    // if we need to show only archived data
    // only show the fields for which we have data
    if (isArchived()) {
      array = ['SpinsThisWeek'];
    }

    const getItemValue = (itemValue: string) => {
      const elm = groupElements[normalizeField];
      const dataValue = _.get(elm, itemValue, '');
      const result = formatValue(dataValue);
      return result;
    };
    return array.map(item => <span key={v4()}>{getItemValue(item)}</span>);
  };

  const innerTableData: IInnerTableGroup[] = [
    {
      title: t(name),
      children: [
        {
          title: '',
          children: getHeaders(),
        },
        {
          title: '',
          children: children(data),
        },
      ],
    },
  ];

  return <InnerTable key={v4()} data={innerTableData} />;
};

ImpressionsInnerTable.defaultProps = {
  reportQuery: null,
};

export default ImpressionsInnerTable;
