import { useAppApi } from "services/api";
import { useQuery, QueryClient, UseQueryResult } from "react-query";
import { AxiosError } from "axios";
import AppProblem from "types/AppProblem";
import { FormatInfo } from "types/Format";
import { FormatSearchRequest } from "types/FormatSearchRequest";

const useFormatsLoad = ():UseQueryResult<Array<FormatInfo>, AxiosError<AppProblem>> =>{
    const api = useAppApi();   
    const url = '/formats';    
    const cacheKey = 'formats-all-cache';
    return useQuery<Array<FormatInfo>, AxiosError<AppProblem>>(cacheKey, () => {
        return api.get(url)
            .then((resp)=>
            { 
                return resp.data 
            });
    });
};

const useFormatsSearch = (searchRequest: FormatSearchRequest):UseQueryResult<Array<FormatInfo>, AxiosError<AppProblem>> =>
{
    const queryClient = new QueryClient();
    
    if (searchRequest == null)
    {
        throw new Error("A valid search request is required to get formats");
    }

    const api = useAppApi();   
    const url = '/formats/search';    
    const cacheKey = 'formats-search-cache';

    const request:FormatSearchRequest = {
        ReportType: searchRequest.ReportType,
        PanelCode: searchRequest.PanelCode,
        OwnerId: searchRequest.OwnerId,
        Year: searchRequest.Year == null ? 0 : searchRequest.Year
    };

    return useQuery<Array<FormatInfo>, AxiosError<AppProblem>>(cacheKey, () => {
        return api.post(url, request)
            .then((resp)=>
            { 
                queryClient.invalidateQueries(cacheKey);
                return resp.data 
            });
    });    
};

export {useFormatsLoad, useFormatsSearch};