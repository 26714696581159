import useRemoteApi from 'hooks/useApi/useRemoteApi';
import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import AuthSettings from 'contexts/AuthContext';
import { UserPreferencesResponse } from 'pages/Dashboard/UserPreferenceTypes';
import { UserPreferencesContext } from './types';

const UserPreference = createContext<UserPreferencesContext>({} as UserPreferencesContext);
const userPreferencesUrl = '/user-preferences';

const UserPreferencesProvider: FC = ({ children }) => {
  const { token } = useContext(AuthSettings);
  const { data: userPreferencesData } = useRemoteApi<UserPreferencesResponse>(userPreferencesUrl, {
    enabled: true,
    data: { token },
    method: 'GET',
  });

  const [userPreferences, setUserPreferences] = useState<UserPreferencesContext>({} as UserPreferencesContext);

  useEffect(() => {
    if (userPreferencesData?.DefaultFormatCode) {
      const defaultFormatCodeStorage = localStorage.getItem('defaultFormatCode');
      if (defaultFormatCodeStorage !== userPreferencesData.DefaultFormatCode) {
        localStorage.setItem('defaultFormatCode', userPreferencesData.DefaultFormatCode);
      }
      setUserPreferences(userPreferencesData);
    }
  }, [userPreferencesData]);

  return <UserPreference.Provider value={userPreferences}>{children}</UserPreference.Provider>;
};

export { UserPreference as default, UserPreferencesProvider };
