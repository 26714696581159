/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import FilterBase from 'components/FilterBase';
import React, { useState } from 'react';
import DateRange from 'components/Filter/components/CalendarFilter';
import FilterDataEvent from 'types/FilterDataEvent';
import PanelFilter from 'components/Filter/components/PanelFilter';
import FormatFilter from 'components/Filter/components/FormatFilter';
import GRCFilter from 'components/Filter/components/Grc';
import ShowTopSelect from 'components/Filter/components/ShowTopSelect';
import InputNumberFilter from 'components/InputNumber';
import useStickyFilter from 'hooks/useStickyFilter/useStickyFilter';
import { useTranslation } from 'react-i18next';
import { PanelCodeEnum } from 'types/Panel';
import { FilterParams } from 'types/FilterParams';
import { getReportSpec, ReportBaseEnum } from 'pages/Reports/Report';
import { DateRangeGroup } from 'types/DateRange';
import { MinSpins, PublishedChartFilter } from './utils/PublishedChartReportTypes';

interface PublishedChartReportProps {
  changeFilter: { (filters: PublishedChartFilter): any };
  PreviousNextFilterDate?: DateRangeGroup;
  isOpened: boolean;
}

const Filter: React.FC<PublishedChartReportProps> = ({ changeFilter, isOpened, PreviousNextFilterDate }) => {
  const pathname = getReportSpec(ReportBaseEnum.PublishedChartReport).stickyKey;
  const { savedFilters: filter, saveFilter } = useStickyFilter<PublishedChartFilter>(pathname);
  const { t } = useTranslation();
  const [thisWeek, setThisWeek] = useState(false);

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleFilterChange = (event: FilterDataEvent<object>) => {
    const { value } = event;
    saveFilter({ ...filter, ...value } as PublishedChartFilter);
  };

  const handleFilterPanelChange = (event: FilterDataEvent<object>) => {
    handleFilterChange(event);
  };

  // eslint-disable-next-line @typescript-eslint/ban-types
  const handleFilterChangeByDateRange = (event: FilterDataEvent<FilterParams>) => {
    const { value } = event as FilterDataEvent<FilterParams>;
    setThisWeek((value as any).IsThisWeek || false);
    handleFilterChange(event);
  };

  return (
    <FilterBase onSubmit={() => changeFilter(filter as PublishedChartFilter)} isOpened={isOpened}>
      <DateRange
        initialValue={PreviousNextFilterDate}
        onChange={handleFilterChangeByDateRange}
        dateOptionsParameters="weeklyDaySunday"
        getDateFromApi
      />
      <PanelFilter onChange={handleFilterPanelChange} initialValue={filter?.PanelInfo} />
      <FormatFilter
        hasAllOption={false}
        panelCode={filter?.PanelInfo?.PanelCode ?? PanelCodeEnum.PublishedPanel}
        onChange={handleFilterChange}
        initialValue={filter?.FormatInfo}
      />
      <GRCFilter onChange={handleFilterChange} initialValue={filter?.GrcInfo} disabled={!thisWeek} />
      <InputNumberFilter<MinSpins>
        minValue={1}
        onChange={handleFilterChange}
        name={t('grid.header.minSpinsCuttOff')}
        valueToApi="MinSpins"
        initialValue={filter?.MinSpins}
      />
      <ShowTopSelect
        onChange={handleFilterChange}
        apiPropertyName="TopX"
        initialValue={filter?.TopX}
        disabled={!thisWeek}
      />
    </FilterBase>
  );
};

export default Filter;
