import { Box, Button, InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import AccordionReports from 'components/AccordionReports';
import { usePrevious } from 'hooks/usePrevious/usePrevious';
import { isEqual, noop } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArtistInfo } from 'types/ArtistInfo';
import FilterDataProps from 'types/FilterDataProps';
import SearchTable from './SearchTable';

interface ArtistSelectionProps extends FilterDataProps<{ ArtistInfo: ArtistInfo }> {
  disabled?: boolean;
}

const ArtistSection: FC<ArtistSelectionProps> = ({ disabled = false, initialValue, onChange = noop }) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState(''); // holds the input value
  const [enabledSearchTerm, setEnabledSearchTerm] = useState(''); // enables the remote artist search
  const [expanded, setExpanded] = useState(false);
  const [selectedArtist, setSelectedArtist] = useState<ArtistInfo>();
  const previousSelectedArtist = usePrevious(selectedArtist);

  const handleSelection = (value: ArtistInfo) => {
    setSelectedArtist(value);
    setExpanded(false);
  };

  useEffect(() => {
    setSelectedArtist(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (!isEqual(previousSelectedArtist, selectedArtist)) {
      onChange(selectedArtist);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedArtist]);

  return (
    <>
      <AccordionReports
        firstTitle={t('filter.title.Artist')}
        secondTitle={selectedArtist?.Name ?? ''}
        expanded={expanded}
        onChangeAccordion={() => setExpanded(!expanded)}
      >
        <Box display="flex" flexDirection="column" width="100%">
          <Box>
            <TextField
              placeholder={t('filter.label.search')}
              fullWidth
              value={searchTerm}
              onChange={(ev: React.ChangeEvent<{ value: unknown }>) => setSearchTerm(ev.target.value as string)}
              disabled={disabled}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button onClick={() => setEnabledSearchTerm(searchTerm)} disabled={disabled}>
                      <SearchIcon color="primary" />
                    </Button>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box>
            <SearchTable searchTerm={enabledSearchTerm} onChange={handleSelection} />
          </Box>
        </Box>
      </AccordionReports>
    </>
  );
};

export default ArtistSection;
