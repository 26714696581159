import { AgGridColumn } from '@ag-grid-community/react';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import MultiColumnsCellRenderer from 'components/DetailGrid/CellRenderers/MultiColumnsCellRenderer/MultiColumnsCellRenderer';
import MultiColumnsHeaderComponent from 'components/DetailGrid/HeaderComponents/MultiColumnsHeaderComponent/MultiColumnsHeaderComponent';
import ServerSideReportGrid from 'components/ServerSideReportGrid/ServerSideReportGrid';
import useSortColumn from 'hooks/useSortColumn/useSortColumn';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SongTrackingPropsGrid } from '.';
import { normalizeDetailsGrid } from './utils/SongTrackingReportTransform';

const Details: React.FC<SongTrackingPropsGrid> = ({ settings, setSettings, filters }) => {
  const { t } = useTranslation();
  const { sortModel, setSortModel: onSortChange } = useSortColumn({ sortPath: 'ReportName', sort: 'asc' });
  return (
    <>
      <ServerSideReportGrid
        sortModel={sortModel}
        settings={settings}
        setSettings={setSettings}
        filters={filters}
        transformResponse={normalizeDetailsGrid}
      >
        <AgGridColumn
          field="StationPortfolioName"
          headerName={t('grid.header.StationPortfolioName')}
          minWidth={100}
          maxWidth={100}
          headerComponentParams={{
            sortModel,
            onSortChange,
          }}
        />
        <AgGridColumn
          pinned
          headerName={t('grid.header.StationName')}
          field="StationDisplayName"
          minWidth={100}
          maxWidth={100}
          wrapText
          headerComponentParams={{
            sortModel,
            onSortChange,
          }}
        />
        <AgGridColumn
          headerName={t('grid.header.MarketName')}
          field="MarketName"
          minWidth={100}
          maxWidth={100}
          wrapText
          headerComponentParams={{
            sortModel,
            onSortChange,
          }}
        />
        <AgGridColumn
          headerName={t('grid.header.MarketRank')}
          field="MarketRank"
          minWidth={100}
          maxWidth={100}
          wrapText
          headerComponentParams={{
            sortModel,
            onSortChange,
          }}
        />
        <AgGridColumn
          headerName={t('grid.header.FormatName')}
          field="FormatShort"
          minWidth={100}
          maxWidth={100}
          wrapText
          headerComponentParams={{
            sortModel,
            onSortChange,
          }}
        />
        <AgGridColumn
          headerName={t('grid.header.Add')}
          field="IsSongAdd"
          minWidth={100}
          maxWidth={100}
          wrapText
          headerComponentParams={{
            sortModel,
            onSortChange,
          }}
          valueFormatter={({ value }: ValueFormatterParams) => (value ? 'A' : '--')}
        />
        <AgGridColumn
          headerName={t('grid.header.Date')}
          field="DateAdded"
          minWidth={100}
          maxWidth={100}
          wrapText
          headerComponentParams={{
            sortModel,
            onSortChange,
          }}
          valueFormatter={({ value }: ValueFormatterParams) => value || '--'}
        />
        <AgGridColumn
          headerName={t('grid.header.Rank')}
          field="ThisWeekRank"
          minWidth={100}
          maxWidth={100}
          wrapText
          headerComponentParams={{
            sortModel,
            sortPath: 'SpinsInfo/ThisWeek',
            onSortChange,
          }}
        />
        <AgGridColumn
          field="SpinTrends"
          colId="ThreeWeekTrend"
          headerName={t('grid.header.Spins')}
          minWidth={300}
          maxWidth={320}
          valueFormatter={({ value }: ValueFormatterParams) => (value ? `${+parseFloat(value).toFixed(4)}` : '--')}
          headerComponentParams={{
            sortModel,
            onSortChange,
            secondLine: [
              { label: t(`grid.header.totalPlays.Week1`), sortPath: 'SpinTrends/Week1' },
              { label: t(`grid.header.totalPlays.Week2`), sortPath: 'SpinTrends/Week2' },
              { label: t(`grid.header.totalPlays.Move`), sortPath: 'SpinTrends/Move' },
              { label: t(`grid.header.totalPlays.Week3`), sortPath: 'SpinTrends/Week3' },
            ],
          }}
          cellRendererParams={{
            fields: ['SpinTrends.Week1', 'SpinTrends.Week2', 'SpinTrends.Move', 'SpinTrends.Week3'],
          }}
          cellRendererFramework={MultiColumnsCellRenderer}
          headerComponentFramework={MultiColumnsHeaderComponent}
        />
        <AgGridColumn
          headerName={t('grid.header.PointsThisWeek')}
          field="PointsThisWeek"
          minWidth={80}
          maxWidth={140}
          headerComponentParams={{
            sortModel,
            onSortChange,
          }}
          wrapText
        />
        <AgGridColumn
          headerName={t('grid.header.PointsLastWeek')}
          field="PointsLastWeek"
          minWidth={80}
          maxWidth={140}
          headerComponentParams={{
            sortModel,
            onSortChange,
          }}
          wrapText
        />
        <AgGridColumn
          headerName={t('grid.header.PointsMove')}
          field="PointsMove"
          minWidth={80}
          maxWidth={140}
          wrapText
          headerComponentParams={{
            sortModel,
            onSortChange,
          }}
        />
        <AgGridColumn
          headerName={t('grid.header.TD')}
          field="SpinsToDate"
          minWidth={100}
          maxWidth={100}
          wrapText
          headerComponentParams={{
            sortModel,
            onSortChange,
          }}
        />
      </ServerSideReportGrid>
    </>
  );
};

export default Details;
