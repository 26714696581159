import React from 'react';
import styled from 'styled-components';
import defaultTheme from '../../../../themes/index';

interface Props {
  thisWeek: number;
  lastWeek: number;
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  line-height: 15px;
  width: 100%;
  align-items: center;
`;

const ContainerArrow = styled.div<Props>`
  color: ${props => getColor(props.thisWeek, props.lastWeek)};
  font-size: 1.2rem;
  font-weight: bold;
  align-self: flex-start;
  margin-right: 3px;
  width: 11px;
`;

const BlankArrow = styled.span`
  font-size: 1.2em;
  font-weight: bold;
  align-self: flex-start;
  margin-right: 3px;
  width: 11px;
`;

function getColor(baseValue: number, actualValue: number) {
  if (baseValue > actualValue) return defaultTheme.palette.error.main;
  if (baseValue < actualValue) return defaultTheme.palette.success.main;
  return defaultTheme.palette.text.primary;
}

interface Obj {
  value: string;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const RankInfoCellRendererRec: React.FC<Obj> = ({ value }: any) => {
  const createSpan = (thisWeek: number, lastWeek: number) => {
    const getResult =
      // eslint-disable-next-line no-nested-ternary
      lastWeek > thisWeek ? (
        <ContainerArrow thisWeek={thisWeek} lastWeek={lastWeek}>
          &uarr;
          {/* ArrowUp */}
        </ContainerArrow>
      ) : thisWeek === lastWeek ? (
        <BlankArrow>&nbsp;</BlankArrow>
      ) : (
        <ContainerArrow thisWeek={thisWeek} lastWeek={lastWeek}>
          &darr;
          {/* ArrowDown */}
        </ContainerArrow>
      );
    return getResult;
  };

  const formatRec = (baseValue: number, compareValue: number) => {
    // eslint-disable-next-line no-nested-ternary
    return baseValue > 0 || baseValue < 0 ? baseValue : baseValue === compareValue ? '-Rec-' : '-';
  };

  const getElements = (): JSX.Element => {
    if (!value) return <></>;
    const obj = Object.values(value) as Array<number>;
    return (
      <Container>
        {createSpan(obj[0], obj[1])}
        <div>
          <span>{formatRec(obj[0], obj[1])}</span>
          <br />
          <span>{formatRec(obj[1], obj[0])}</span>
        </div>
      </Container>
    );
  };

  return getElements();
};

RankInfoCellRendererRec.defaultProps = { value: undefined };

export default RankInfoCellRendererRec;
