/* eslint-disable @typescript-eslint/no-explicit-any */
import GenericSelect from 'components/Filter/components/GenericSelect';
import React, { useEffect, useState } from 'react';
import FilterDataProps from 'types/FilterDataProps';
import { ReportCycle } from 'types/ReportCycle';
import { useTranslation } from 'react-i18next';

interface Props extends FilterDataProps<FilterData> {
  options?: Array<unknown>;
  apiPropertyName?: string;
  reportLabel?: string;
  disabled?: boolean;
}
interface FilterData {
  ReportMode?: number;
}

const ReportModeComponent: React.FC<Props> = ({
  onChange,
  apiPropertyName = 'ReportMode',
  options = [
    { Name: 'Big Picture', Id: 1 },
    { Name: 'Big Picture with History ', Id: 2 },
  ],
  reportLabel = 'filter.title.ReportMode',
  disabled = false,
  initialValue,
}) => {
  const [items, setItems] = useState(options);
  const { t } = useTranslation();

  useEffect(() => {
    const removeEmpyObjects = options.filter(value => JSON.stringify(value) !== '{}');
    setItems(removeEmpyObjects);
  }, [options]);

  const handleSelect = (event: { value: unknown }) => {
    const value = event.value as ReportCycle;
    onChange({ value: { [apiPropertyName]: value.Id } });
  };

  const selectedValue = () => {
    let val = options.find((v: any) => v.Id === initialValue);
    if (!val) {
      val = options[0];
    }
    return val;
  };

  return (
    <GenericSelect
      disabled={disabled}
      title={`${t(reportLabel)}:`}
      data={items}
      onChange={handleSelect}
      initialValue={selectedValue()}
    />
  );
};

export default ReportModeComponent;
