/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import AuthSettings from 'contexts/AuthContext';
import React, { useContext } from 'react';
import { Redirect, Route, RouteComponentProps } from 'react-router-dom';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const PrivateRoute = ({ component: Component, container: Wrapper, ...rest }: any): any => {
  const { token } = useContext(AuthSettings);

  const renderRoute: React.ReactNode = (props: RouteComponentProps<any>) => {
    if (token) {
      if (Wrapper) {
        return (
          <Wrapper>
            <Component {...props} />
          </Wrapper>
        );
      }
      return <Component {...props} />;
    }

    return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
  };

  return <Route {...rest} render={renderRoute} />;
};
