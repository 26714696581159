import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const Container = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
`;
export const ReportContainer = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
`;
export const FilterContainer = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
`;
export const GridContainer = styled(Grid)`
  width: 100%;
`;

export const StationContainer = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
`;

export const FilterGridContainer = styled(Grid)`
  display: flex;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px;
`;

export const FormatContainer = styled(Grid)`
  display: flex;
  justify-content: flex-start;
  width: 33%;
  max-width: 33%;
`;

export const MinSpinsContainer = styled(Grid)`
  display: flex;
  justify-content: flex-start;
  width: 33%;
  max-width: 33%;
`;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filter: {
      marginLeft: '5px',
      borderRadius: '0px',
      borderBottomWidth: '1px',
      backgroundColor: '#ffffff',
      width: '40px',
      minWidth: '40px',
      minHeight: '100%',
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      '&:hover': {
        backgroundColor: '#ffffff',
      },
      '& $label': {
        transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
      },
    },
    icon: {
      color: theme.palette.primary.main,
      right: 12,
      userSelect: 'none',
      pointerEvents: 'none',
    },
  }),
);
