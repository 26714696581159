/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
import GridVisibilityWrapper from 'components/GridVisibilityWrapper/GridVisibilityWrapper';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate, formatDateTypes } from 'utils/dates';
import _ from 'lodash';
import { SpinsGridProps, StationSpinGridResponse } from './utils/StationSpinGridTypes';
import CreateDetailGrid from './SpinGridHelper';

const url = 'station-song-hour-spin-reports';

const SpinsGrid: React.FC<SpinsGridProps> = ({ filters, onSpinDateClickHandler }) => {
  const { t } = useTranslation();

  const [gridLoaded, setGridLoaded] = useState(false);
  const [filterApi, setFilterApi] = useState({});
  useEffect(() => {
    setFilterApi({
      ...filterApi,
      ...filters,
    });
  }, [filters]);

  useEffect(() => {
    setGridLoaded(true);
  }, []);

  const { data: reportData, isLoading, isError } = useRemoteApi<StationSpinGridResponse>(url, {
    data: filterApi,
    method: 'POST',
    enabled: !!filterApi && gridLoaded,
    transformRequest: data => {
      data.MaxWeeks = Number(data.MaxWeeks);
      data.EndDate = formatDate(data.EndDate, formatDateTypes.yyyyMMdd);

      return data;
    },
  });

  return (
    <GridVisibilityWrapper filters={filters} isLoading={isLoading} reportData={reportData} isError={isError}>
      {(_.chunk(reportData?.Items, 7) ?? []).map((chunkReportDataItem, index) =>
        CreateDetailGrid(index, chunkReportDataItem, t, onSpinDateClickHandler),
      )}
    </GridVisibilityWrapper>
  );
};

export default SpinsGrid;
