/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box, ListItem, ListItemText } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import AppSettings from 'contexts/AppSettingsContext';
import { FilterState } from 'hooks/useFilterState/useFilterState';
import { usePrevious } from 'hooks/usePrevious/usePrevious';
import { get, isEqual, noop } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FixedSizeList as List, ListChildComponentProps } from 'react-window';
import FilterDataProps from 'types/FilterDataProps';
import AccordionReports from '../../../AccordionReports/index';
import { useStyles } from './styles';

interface FilterData {
  value: any | undefined;
}

interface GenericSelect extends FilterDataProps<FilterData> {
  title?: string;
  subtitle?: string;
  data?: any[];
  idProperty?: string;
  disabled?: boolean;
  initialValue?: any;
  changeState?: (state: FilterState) => void;
  required?: boolean;
  comparator?: (v1: any, v2: any) => number;
}

const GenericSelect: React.FC<GenericSelect> = props => {
  const { maxSelectHeight } = useContext(AppSettings);
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    title = 'Title',
    data = [],
    idProperty = 'Id',
    onChange = noop,
    disabled = false,
    initialValue,
    changeState = noop,
    required = false,
    comparator,
  } = props;

  const [expanded, setExpanded] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>();
  const previousSelectedItem = usePrevious(selectedItem);
  const previousData = usePrevious(data);

  const getIdValue = (v: any): string | number => {
    return get(v, idProperty);
  };

  const compareValues = (v1: any, v2: any) => {
    if (comparator) {
      return comparator(v1, v2) === 0;
    }
    return v1 === v2;
  };

  useEffect(() => {
    setExpanded(false);
    changeState(selectedItem || !required ? FilterState.VALID : FilterState.INVALID);
    if (selectedItem && !isEqual(selectedItem, previousSelectedItem)) {
      onChange({ value: selectedItem });
    }
  }, [selectedItem]);

  useEffect(() => {
    changeState(selectedItem || !required ? FilterState.VALID : FilterState.INVALID);
    if (!isEqual(data, previousData)) {
      changeState(FilterState.INVALID);
    }

    if (data?.length) {
      const find = data.find(v => compareValues(getIdValue(v), getIdValue(initialValue)));
      setSelectedItem(find);
    }
  }, [initialValue, data]);

  const renderRow = ({ index, style }: ListChildComponentProps) => {
    const item = data[index];
    return (
      <ListItem
        style={style}
        key={getIdValue(selectedItem) || index}
        button
        divider
        onClick={() => setSelectedItem(item)}
      >
        <ListItemText
          className={classes.itemsList}
          primary={t(item.Name ?? '')}
          primaryTypographyProps={{ noWrap: true }}
        />
        {selectedItem?.Id === item.Id ? (
          <CheckIcon color="primary" fontSize="small" />
        ) : (
          <Box className={classes.emptyBox} />
        )}
      </ListItem>
    );
  };

  const selectHeight = data.length < maxSelectHeight / 40 ? data.length * 46 : maxSelectHeight;

  return (
    <AccordionReports
      expanded={expanded}
      onChangeAccordion={() => setExpanded(!expanded)}
      firstTitle={title}
      secondTitle={t(selectedItem?.Name ?? '')}
      isDisabled={disabled}
      unmountOnExit
    >
      <List height={selectHeight} width="100%" itemSize={46} itemCount={data.length}>
        {renderRow}
      </List>
    </AccordionReports>
  );
};

export default GenericSelect;
