/* eslint-disable react/jsx-one-expression-per-line */
import { DatesUrlEnum } from 'components/Filter/components/CalendarFilter/utils';
import { parseISO } from 'date-fns';
import useRemoteApi from 'hooks/useApi/useRemoteApi';
import useRefreshInterval from 'hooks/useRefreshInterval/useRefreshInterval';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { formatDate, formatDateTypes } from 'utils/dates';
import './styles.css';

const LastSiteUpdate: React.FC = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { lastRefreshTimestamp } = useRefreshInterval();

  const { data: apiData } = useRemoteApi<string>(DatesUrlEnum.LAST_UPDATED, {
    method: 'GET',
    enabled: true,
    params: { lastRefreshTimestamp },
    transformResponse: (value: string) => {
      const parseToDate = parseISO(value);
      return formatDate(parseToDate, formatDateTypes.EMMMdYhhmma);
    },
  });

  useEffect(() => {
    const elm = document.getElementById('mb-app-last-update');
    if (elm) {
      const urlsToHideSideUpdate = [
        '/mbapp/CurrentDayMonitorsReport',
        '/mbapp/CurrentChartReport',
        '/mbapp/CurrentSongsReport',
      ];
      const isSideUpdateHidden = urlsToHideSideUpdate.indexOf(pathname) !== -1;
      if (isSideUpdateHidden) {
        elm.style.display = 'none';
      } else {
        elm.style.display = 'block';
      }
    }
  }, [pathname]);

  return (
    <>
      <span id="mb-app-last-update">
        {t('filter.label.updated')}:{apiData}
      </span>
    </>
  );
};

export default LastSiteUpdate;
