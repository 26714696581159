import React, { useState } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Box } from '@material-ui/core';
import { useStyles } from './styles';

interface AccordionGridProps {
  title?: string;
  complement?: string;
  children?: JSX.Element | JSX.Element[];
  expanded: boolean;
  unmountOnExit?: boolean;
  isDisabled?: boolean;
}

const AccordionGrid: React.FC<AccordionGridProps> = ({
  title = 'Title',
  isDisabled = false,
  expanded = false,
  unmountOnExit = false,
  complement = '',
  children,
}) => {
  const classes = useStyles();

  const [accordionStatus, setAccordionStatus] = useState(expanded);

  const handleChange = () => {
    // eslint-disable-next-line no-param-reassign
    setAccordionStatus(!accordionStatus);
  };

  return (
    <Accordion
      disabled={isDisabled}
      expanded={accordionStatus}
      onChange={handleChange}
      TransitionProps={{ unmountOnExit }}
      className={classes.root}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className={classes.icon} />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Box width="20%">
          <Typography data-testid="firstTitle">{title}</Typography>
        </Box>

        <Box width="80%">
          <Typography data-testid="complement" align="right">
            {complement}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>{accordionStatus && children}</AccordionDetails>
    </Accordion>
  );
};

export default AccordionGrid;
