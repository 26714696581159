import { Column, ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DayPartInfo } from 'types/DayPart';
import { v4 } from 'uuid';
import InnerTable from '..';
import { IInnerTableGroup } from '../inner-table-group';

export interface DayPartsImpressionsData {
  DailyImpressionsTrends: DayPartInfo;
}

interface DayPartsImpressionsType {
  DailyImpressionsTrends?: DayPartInfo;
  DaypartImpressions?: DayPartInfo;
  DaypartSpins?: DayPartInfo;
}

interface DayPartsImpressionsInnerTableProps {
  data: DayPartsImpressionsType;
  name: string;
  field: string;
  columns?: Column[];
}

const DayPartsImpressionsInnerTable: React.FC<DayPartsImpressionsInnerTableProps> = ({
  data,
  name,
  field,
  columns,
}: DayPartsImpressionsInnerTableProps) => {
  const { t } = useTranslation();

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const valueGetter = (v: any) => {
    if (columns && columns.length > 0 && columns[0].getColDef()) {
      const colDef = columns[0].getColDef();
      const { valueFormatter } = colDef;

      if (valueFormatter instanceof Function) {
        return valueFormatter({ ...colDef, value: v } as ValueFormatterParams);
      }
    }
    return v;
  };

  const fields = ['Overnight', 'AmDrive', 'Midday', 'PmDrive', 'Evening'];
  const values = fields.map((item: string) => {
    const val = (data[field as keyof DayPartsImpressionsType] as DayPartInfo)[item as keyof DayPartInfo] as string;
    const result = valueGetter(val);
    const output = result === 0 ? '--' : result;
    return output;
  });

  const innerTableData: IInnerTableGroup[] = [
    {
      title: t(name),
      children: [
        {
          title: '',
          children: ['OVN', 'AMD', 'MID', 'PMD', 'EVE'],
        },
        {
          title: '',
          children: values,
        },
      ],
    },
  ];

  return <InnerTable key={v4()} data={innerTableData} />;
};

DayPartsImpressionsInnerTable.defaultProps = {
  columns: [],
};

export default DayPartsImpressionsInnerTable;
